import { FC } from 'react';
import styled from 'styled-components/macro';
import { FileIcon } from 'ui/icons/File';

interface Props {}

export const ProgressLegend: FC<Props> = () => {
  return (
    <Container>
      <LegendItem>
        <DoneDot />
        <small>Fait</small>
      </LegendItem>

      <LegendItem>
        <NotDoneDot />
        <small>À faire</small>
      </LegendItem>

      <LegendItem>
        <File />
        <small>Dernière copie</small>
      </LegendItem>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  color: ${({ theme }) => theme.colors.inactive};
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Dot = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 100%;
`;

const DoneDot = styled(Dot)`
  --color: ${({ theme }) => theme.colors.primary.light};
  border: 1px solid var(--color);
  background-color: var(--color);
`;

const NotDoneDot = styled(Dot)`
  --color: ${({ theme }) => theme.colors.primary.light};
  border: 1px solid var(--color);
`;

const File = styled(FileIcon)`
  height: 8px;
  width: 8px;
`;
