import { FC, useMemo } from 'react';

import { DPBasicInformations } from 'modules/progress/general/dp/domain/entities/DPBasicInformations';
import { useIsDPAlreadySelected } from '../hooks/useIsDPAlreadySelected';
import { useAddToLocalCartContext } from '../contexts/addToLocalCartContext';
import { GenericCartCheckbox } from '../../components/GenericCartCheckbox';

interface Props {
  group: DPBasicInformations;
}

export const CartCheckbox: FC<Props> = ({ group }) => {
  const isSelected = useIsDPAlreadySelected({
    id: group.id,
    type: 'DP',
  });

  const {
    isQuestionInLocalSelection: isQuestionInSelection,
    toggleInLocalCart: toggleToCart,
  } = useAddToLocalCartContext();

  const isInLocalSelection = useMemo(
    () => isQuestionInSelection(group.id),
    [isQuestionInSelection, group.id]
  );

  return (
    <GenericCartCheckbox
      isFree={group.isFree}
      checked={isSelected || isInLocalSelection}
      disabled={isSelected}
      onChange={() => toggleToCart(group.id)}
    />
  );
};
