import { FC } from 'react';
import { QuoteType } from 'types/correction.type';
import WhiteCard from 'ui/Cards/WhiteCard';
import { useTheme } from 'styled-components/macro';
import { Quote } from './Quote';

interface Props {
  quotes: QuoteType[];
}

export const Quotes: FC<Props> = ({ quotes }) => {
  const theme = useTheme();

  return (
    <WhiteCard color={`${theme.colors.compass.dark}`} hasMargin={false}>
      {quotes.map((quote) => (
        <Quote
          quote={quote.quote}
          paragraph={quote.paragraph}
          key={quote.quote}
        />
      ))}
    </WhiteCard>
  );
};
