import { patchAPI } from 'auth';
import { SaveQuestionTCSRequestType } from './types';

export const saveQuestionTCS = async (params: SaveQuestionTCSRequestType) => {
  const response = await patchAPI<void, SaveQuestionTCSRequestType>(
    '/training/save_question_tcs',
    params
  );

  return response.data;
};
