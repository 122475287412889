import { getAPI } from 'auth';

import { GroupThumbnailParams, GroupThumbnailResponse } from './types';

export const getGroupThumbnail = async (params: GroupThumbnailParams) => {
  const response = await getAPI<GroupThumbnailResponse>(
    '/selection/group_thumbnail/',
    {
      params,
    }
  );
  return response.data;
};
