import { isDevelopement } from 'helpers/config';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';
import { selectDevMode, setIsDevMode } from 'store/slices/appSlice';

export const useDevMode = () => {
  const isDevMode = useSelector(selectDevMode);
  const dispatch = useDispatch();

  useHotkeys(
    'shift+d',
    () => {
      if (!isDevelopement) return;

      dispatch(setIsDevMode(!isDevMode));
    },
    [isDevMode, dispatch]
  );
};
