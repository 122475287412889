import { FC } from 'react';
import { MenuIconProps } from './types';

export const MenuSelection: FC<MenuIconProps> = ({ isFilled }) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.395 1.73 15.3 6.922a1.5 1.5 0 0 0 .928.719l6.036 1.587a.5.5 0 0 1 .24.823l-4.617 4.998a1.5 1.5 0 0 0-.374 1.287l1.013 5.563a.5.5 0 0 1-.752.517l-4.993-3.04a1.5 1.5 0 0 0-1.56 0l-5.106 3.108a.5.5 0 0 1-.756-.493l.769-5.723a1.5 1.5 0 0 0-.352-1.18l-4.363-5.05a.5.5 0 0 1 .245-.81l5.76-1.592a1.5 1.5 0 0 0 .878-.66l3.238-5.262a.5.5 0 0 1 .862.018Z"
        stroke="currentColor"
        strokeWidth={isFilled ? 2 : 1}
        {...(isFilled ? { fill: 'currentColor' } : {})}
      />
    </svg>
  );
};
