import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SelectionGroupResult } from 'http/selection-group/types';
import { SortOptions } from 'components/Filters/constants';
import { areItemInfosEqual } from 'pages/selection/TableHeaders/utils';
import { SortColumns, SortByPayload } from 'store/types';

export interface LCATableState {
  sortOrder?: SortOptions;
  sortColumn?: SortColumns;
  pageSize: number;
  page: number;
  selectedItemsInfos: SelectionGroupResult[];
  openedRowId?: number;
}

const initialState: LCATableState = {
  pageSize: 20,
  page: 0,
  selectedItemsInfos: [],
  sortOrder: 'DESCENDING',
  sortColumn: 'collection',
};

export const lcaTableSlice = createSlice({
  name: 'lcaTable',
  initialState,
  reducers: {
    sortBy: (state, action: PayloadAction<SortByPayload>) => {
      state.sortOrder = action.payload.order;
      state.sortColumn = action.payload.sortColumn;
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    toggleItemSelection: (
      state,
      action: PayloadAction<SelectionGroupResult>
    ) => {
      const itemInfos = action.payload;
      const isItemAlreadySelected = state.selectedItemsInfos.some(
        (selectedItem) => areItemInfosEqual(selectedItem, itemInfos)
      );

      if (isItemAlreadySelected) {
        const selectedItemsWithoutToggledItem = state.selectedItemsInfos.filter(
          (item) => !areItemInfosEqual(item, itemInfos)
        );
        state.selectedItemsInfos = selectedItemsWithoutToggledItem;
      } else {
        state.selectedItemsInfos.push(itemInfos);
      }
    },
    updateItemSelection: (
      state,
      action: PayloadAction<SelectionGroupResult[]>
    ) => {
      const itemInfos = action.payload;
      state.selectedItemsInfos = itemInfos;
    },
    openRow: (state, action: PayloadAction<number | undefined>) => {
      state.openedRowId = action.payload;
    },
    reset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  sortBy,
  setPageSize,
  toggleItemSelection,
  updateItemSelection,
  openRow,
  setPage,
  reset,
} = lcaTableSlice.actions;

export const lcaTableReducer = lcaTableSlice.reducer;
