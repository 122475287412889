import styled, { css } from 'styled-components/macro';
import { FC, useState } from 'react';
import { device } from 'helpers/css/responsive';

interface Props {
  option1Text: string;
  onclickOption1: () => void;
  option2Text: string;
  onclickOption2: () => void;
}

export const BlackButtonSwitch: FC<Props> = ({
  option1Text,
  onclickOption1,
  option2Text,
  onclickOption2,
}) => {
  const [isActiveOption, setIsActiveOption] = useState<string>(option1Text);

  const handleClick = (option: string, callback: () => void) => {
    if (option !== isActiveOption) {
      callback();
      setIsActiveOption(option);
    }
  };

  return (
    <Switch>
      <Option
        onClick={() => handleClick(option1Text, onclickOption1)}
        isActive={isActiveOption === option1Text}
      >
        {option1Text}
      </Option>
      <Option
        onClick={() => handleClick(option2Text, onclickOption2)}
        isActive={isActiveOption === option2Text}
      >
        {option2Text}
      </Option>
    </Switch>
  );
};

const Switch = styled.div`
  display: flex;
  height: fit-content;
  border-radius: 16px;
  font-size: ${({ theme }) => theme.typography.small};
  border: 1px solid ${({ theme }) => theme.colors.primary.dark};
  padding: 1px;
  gap: 2px;
  width: fit-content;
`;

const Option = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  padding: 1px 8px;
  border-radius: 16px;
  width: fit-content;
  white-space: nowrap;
  @media ${device.mobile} {
    white-space: wrap;
  }
  ${({ isActive, theme }) =>
    isActive
      ? css`
          color: ${theme.colors.primary.light};
          background-color: ${theme.colors.primary.dark};
        `
      : css`
          color: ${theme.colors.primary.dark};
          background-color: transparent;
        `};
`;
