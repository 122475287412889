import { FC } from 'react';
import styled from 'styled-components/macro';
import { CorrectionThemeProvider } from 'components/CorrectionThemeProvider';
import { buttonReset } from 'helpers/css/button-reset';
import { CloseIcon } from 'ui/icons/Close';

interface Props {
  onCloseButtonClick: () => void;
  children?: React.ReactNode;
}

export const ModalHeader: FC<Props> = ({ onCloseButtonClick, children }) => {
  return (
    <CorrectionThemeProvider>
      <Container>
        <div>{children}</div>
        <CloseButton onClick={onCloseButtonClick}>
          <CloseIcon />
        </CloseButton>
      </Container>
    </CorrectionThemeProvider>
  );
};
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${({ theme }) => theme.typography.h2}
`;

const CloseButton = styled.button`
  ${buttonReset}
  padding: 4px;
  width: 22px;
  height: 22px;
  display: grid;
  place-items: center;
  position: relative;
  top: -16px;
  right: -16px;
`;
