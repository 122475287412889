import { RecapRepository } from '../domain/RecapRepository.interface';
import {
  getAnnalsSessionRecapHttp,
  getSessionRecapHttp,
  getSpecialtyRecapHttp,
} from './api';
import { SessionRequestType, SpecialtyRequestType } from './types';

export const getSessionRecapRepository = (): RecapRepository =>
  SessionRecapRepositoryImpl;

const SessionRecapRepositoryImpl: RecapRepository = {
  async getSessionrecap(body: SessionRequestType) {
    const httpSessionRecap = await getSessionRecapHttp(body);

    return {
      totalDp: httpSessionRecap.total_dp,
      doneDp: httpSessionRecap.done_dp,
      totalQi: httpSessionRecap.total_qi,
      doneQi: httpSessionRecap.done_qi,
      totalLca: httpSessionRecap.total_lca,
      doneLca: httpSessionRecap.done_lca,
      denom: httpSessionRecap.denom,
      doneDenom: httpSessionRecap.done_denom,
      grade: httpSessionRecap.grade,
      numerator:
        httpSessionRecap.done_dp * 420 +
        httpSessionRecap.done_lca * 540 +
        httpSessionRecap.done_qi * 18,
    };
  },
  async getSpecialtyRecap(body: SpecialtyRequestType) {
    const httpSpecialtyRecap = await getSpecialtyRecapHttp(body);

    return {
      totalDp: httpSpecialtyRecap.total_dp,
      doneDp: httpSpecialtyRecap.done_dp,
      totalQi: httpSpecialtyRecap.total_qi,
      doneQi: httpSpecialtyRecap.done_qi,
      totalLca: httpSpecialtyRecap.total_lca,
      doneLca: httpSpecialtyRecap.done_lca,
      denom: httpSpecialtyRecap.denom,
      grade: httpSpecialtyRecap.grade,
      numerator:
        httpSpecialtyRecap.done_dp * 420 +
        httpSpecialtyRecap.done_lca * 540 +
        httpSpecialtyRecap.done_qi * 18,
    };
  },
  async getAnnalsSessionRecap() {
    const httpAnnalsSessionRecap = await getAnnalsSessionRecapHttp();

    return {
      totalDp: httpAnnalsSessionRecap.total_dp,
      doneDp: httpAnnalsSessionRecap.done_dp,
      totalQi: httpAnnalsSessionRecap.total_qi,
      doneQi: httpAnnalsSessionRecap.done_qi,
      totalLca: httpAnnalsSessionRecap.total_lca,
      doneLca: httpAnnalsSessionRecap.done_lca,
      denom: httpAnnalsSessionRecap.denom,
      doneDenom: httpAnnalsSessionRecap.done_denom,
      grade: httpAnnalsSessionRecap.grade,
      numerator:
        httpAnnalsSessionRecap.done_dp * 420 +
        httpAnnalsSessionRecap.done_lca * 540 +
        httpAnnalsSessionRecap.done_qi * 18,
    };
  },
};
