import { useGetEstimatedDurations } from 'modules/user-options/hooks/useGetEstimatedDurations';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { SelectionTables } from '../Selection';

export const useGetCartEstimatedDuration = () => {
  const dpSelectedItems = useSelector((state: RootState) => {
    return state.dpTable.selectedItemsInfos.reduce(
      (accumulator, currentValue) => {
        if (currentValue && currentValue.number_of_questions >= 13) {
          accumulator.long += 1;
        } else {
          accumulator.short += currentValue.number_of_questions || 0;
        }
        return accumulator;
      },
      { short: 0, long: 0 }
    );
  });

  const qiSelectedItems = useSelector(
    (state: RootState) => state.qiTable.selectedItemsInfos.length
  );

  const lcaSelectedItems = useSelector(
    (state: RootState) => state.lcaTable.selectedItemsInfos
  );

  const estimatedDurations = useGetEstimatedDurations();

  const getCartEstimatedDuration = (selectedTable: SelectionTables) => {
    const durationInMinutes = getCartEstimatedMinuteDuration(selectedTable);
    return formatDurationInMinutes(durationInMinutes);
  };

  // return duration of session of DP + QI
  const getCartEstimatedMinuteDuration = (selectedTable: SelectionTables) => {
    if (selectedTable === 'DP' || selectedTable === 'QI') {
      return estimateDPQIDuration();
    }
    return estimateLCADuration();
  };

  const estimateDPQIDuration = () =>
    dpSelectedItems.short * estimatedDurations.dpLessThan13 +
    dpSelectedItems.long * estimatedDurations.dpMoreThan13 +
    qiSelectedItems * estimatedDurations.qi;

  const estimateLCADuration = () =>
    lcaSelectedItems.length * estimatedDurations.lca;

  return getCartEstimatedDuration;
};

export const formatDurationInMinutes = (durationInMinutes: number) => {
  const hours = Math.floor(durationInMinutes / 60);
  const minutesWithoutHours = durationInMinutes - hours * 60;
  const minutes = Math.floor(minutesWithoutHours);

  const formattedHours = hours.toString();
  const formattedMinutes = minutes.toString().padStart(2, '0');

  return `${formattedHours}h ${formattedMinutes}m`;
};
