import { popSlideInAnimation } from 'helpers/css/animations/popSlideIn';
import { device } from 'helpers/css/responsive';
import styled, { css } from 'styled-components/macro';

export const HeaderCell = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.primary.light};
  padding: 8px 12px;

  @media ${device.desktop} {
    padding: 14px 16px;
  }
`;

export const FirstHeaderCell = styled(HeaderCell)`
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const LastHeaderCell = styled(HeaderCell)`
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const Cell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;

  padding: 8px 12px;

  @media ${device.desktop} {
    padding: 12px 28px;
  }
`;

export const SubRow = styled.span`
  grid-column: 1/-1;
  overflow: hidden;
`;

export const SubRowContent = styled.div<{ isColored?: boolean }>`
  margin: 2px var(--content-side-margin) 0;
  padding: 16px 24px;
  border-radius: 8px;

  ${popSlideInAnimation}

  ${({ isColored }) =>
    isColored &&
    css`
      background-color: ${({ theme }) => theme.colors.primary.light};
    `}
`;

export const HeaderCellText = styled.span`
  display: inline-block;
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary.dark};
  font-weight: 500;
  @media ${device.desktop} {
    padding: 4px 16px;
  }
`;

export const PendingTableContainer = styled.div<{ rowSup?: boolean }>`
  display: grid;
  grid-template-columns:
    minmax(50px, 3fr) minmax(50px, 3fr) minmax(50px, 3fr) minmax(50px, 3fr)
    minmax(50px, 3fr) ${({ rowSup }) => rowSup && 'minmax(50px, 3fr)'};

  width: 100%;

  border: 4px solid ${({ theme }) => theme.colors.primary.light};
  border-radius: 14px;

  --content-side-margin: 8px;
  @media ${device.desktop} {
    --content-side-margin: 20px;
  }

  // Alternated row colors

  ${Cell}:nth-of-type(${({ rowSup }) =>
    rowSup
      ? '10n+11'
      : '10n+6'}), ${Cell}:nth-of-type(10n+7), ${Cell}:nth-of-type(10n+8), ${Cell}:nth-of-type(10n+9), ${Cell}:nth-of-type(10n+10) {
    background-color: ${({ theme }) => theme.colors.primary.light};
  }

  // Rows border radius
  ${Cell}:nth-of-type(
  ${({ rowSup }) => (rowSup ? '6n+1' : '5n+1')}
  ) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    @media ${device.desktop} {
      padding-left: 28px;
    }
  }
  ${Cell}:nth-of-type(
  ${({ rowSup }) => (rowSup ? '5n+6' : '5n+5')}
  ) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    @media ${device.desktop} {
      padding-left: 14px;
    }
  }
`;

export const DoneTableContainer = styled.div`
  display: grid;
  grid-template-columns:
    minmax(50px, 3fr) minmax(50px, 3fr) minmax(50px, 3fr) minmax(50px, 3fr)
    minmax(50px, 3fr) minmax(50px, 3fr);
  width: 100%;

  border: 4px solid ${({ theme }) => theme.colors.primary.light};
  border-radius: 14px;

  --content-side-margin: 8px;
  @media ${device.desktop} {
    --content-side-margin: 20px;
  }

  // Alternated row colors
  ${Cell}:nth-of-type(12n+7), ${Cell}:nth-of-type(12n+8), ${Cell}:nth-of-type(12n+9), ${Cell}:nth-of-type(12n+10), ${Cell}:nth-of-type(12n+11), ${Cell}:nth-of-type(12n+12) {
    background-color: ${({ theme }) => theme.colors.primary.light};
  }

  // Rows border radius
  ${Cell}:nth-of-type(6n+1) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    @media ${device.desktop} {
      padding-left: 28px;
    }
  }
  ${Cell}:nth-of-type(6n+6) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    @media ${device.desktop} {
      padding-left: 14px;
    }
  }
`;

export const CCBRankingContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(50px, 5fr) minmax(50px, 2fr) minmax(50px, 2fr) minmax(
      50px,
      3fr
    );
  width: 100%;

  border: 4px solid ${({ theme }) => theme.colors.primary.light};
  border-radius: 14px;

  --content-side-margin: 8px;
  @media ${device.desktop} {
    --content-side-margin: 20px;
  }

  // Alternated row colors
  ${Cell}:nth-of-type(8n+1), ${Cell}:nth-of-type(8n+2), ${Cell}:nth-of-type(8n+3), ${Cell}:nth-of-type(8n+4) {
    background-color: ${({ theme }) => theme.colors.primary.light};
  }

  // First cell row space with header
  ${Cell}:nth-of-type(1), ${Cell}:nth-of-type(2), ${Cell}:nth-of-type(3) , ${Cell}:nth-of-type(4) {
    margin-top: 12px;
  }

  // Rows border radius
  ${Cell}:nth-of-type(4n+1) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    @media ${device.desktop} {
      padding-left: 28px;
    }
  }
  ${Cell}:nth-of-type(4n+4) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    @media ${device.desktop} {
      padding-left: 14px;
    }
  }
`;
