import { FC } from 'react';
import styled from 'styled-components/macro';

interface Props {}

export const GenericErrorPlaceholder: FC<Props> = () => {
  return (
    <Container>
      <h2>Une erreur est survenue ❌</h2>
      <span>Si l'erreur persiste veuillez réessayer plus tard</span>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  min-height: 300px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;
