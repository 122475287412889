import { postAPI } from 'auth';
import { StartTimerResponseType } from './types';
import { TaskRequestType } from 'types/task.type';

export const startTimer = async (taskId: number) => {
  const response = await postAPI<StartTimerResponseType, TaskRequestType>(
    '/training/start_timer',
    { task_id: taskId }
  );
  return response.data;
};
