import { FC, SVGAttributes } from 'react';
import { useTheme } from 'styled-components/macro';

type SVGNativeProps = SVGAttributes<SVGElement>;

export const CrossFull: FC<SVGNativeProps> = (props) => {
  const theme = useTheme();

  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="10"
        cy="10"
        r="9"
        fill={theme.colors.error.main}
        stroke={theme.colors.error.main}
        strokeWidth="2"
      />
      <path
        d="M14 6L6 14"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 6L14 14"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
