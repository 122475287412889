import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SelectionTables = 'DP' | 'QI' | 'LCA';

export interface SelectionState {
  selectedTable: SelectionTables;
}

const initialState: SelectionState = {
  selectedTable: 'DP',
};

export const selectionSlice = createSlice({
  name: 'selection',
  initialState,
  reducers: {
    updateSelectedTable(state, action: PayloadAction<SelectionTables>) {
      state.selectedTable = action.payload;
    },
    reset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { updateSelectedTable, reset } = selectionSlice.actions;

export const selectionReducer = selectionSlice.reducer;
