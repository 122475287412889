import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { updateTypeFilter } from 'store/slices/selectionFiltersSlice';

import { GenericSortFilterHeader } from './GenericSortFilterHeader';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';
import { Options } from 'hooks/useTableFilter';

interface Props {
  isSub?: boolean;
}

export const TypeHeader: FC<Props> = ({ isSub }) => {
  const filterOptions: Options[] = [
    {
      label: 'QRPL',
      value: 'QRPL',
    },
    {
      label: 'QROC',
      value: 'QROC',
    },
    {
      label: 'QRM',
      value: 'QRM',
    },
    {
      label: 'QRU',
      value: 'QRU',
    },
    {
      label: 'QRP',
      value: 'QRP',
    },
    {
      label: 'TCS',
      value: 'TCS',
    },
    {
      label: 'Zone à pointer',
      value: 'QZP',
    },
  ];

  const dispatch = useDispatch();

  const isUserFree = useIsUserFree();

  const selectedFilters = useSelector(
    (state: RootState) => state.selectionFilters.type
  );

  const setSelectedFilters = (items: string[]) => {
    dispatch(updateTypeFilter(items));
  };

  return (
    <GenericSortFilterHeader
      columnName="Type"
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      filterOptions={filterOptions}
      isSub={isSub}
      disableSort
      sortColumn="type"
      disabled={isUserFree}
    />
  );
};
