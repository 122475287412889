import { CSSProperties, useState } from 'react';
// import { SingleAnswer } from 'pages/training/components/QZP/SingleAnswer';
import { SingleAnswer } from './SingleAnswer';
import { Props as QZPProps } from './QZP';
import LibModal from 'react-modal';
import { useTheme } from 'styled-components/macro';
import { ModalHeader } from '../ui/ModalHeader';

interface Props {
  onDragStop: (
    index: number,
    relativeDistance: { x: number; y: number }
  ) => void;
  answer: QZPProps['answer'];
  saved: QZPProps['saved'];
  isQI: QZPProps['isQI'];
  shouldUpdateArrowsPositions?: QZPProps['shouldUpdateArrowsPositions'];
  onClose: () => void;
}

const QZPModal = (props: Props) => {
  const [answer] = useState<Props['answer']>(props.answer);
  const theme = useTheme();

  return (
    <LibModal
      isOpen
      onRequestClose={props.onClose}
      style={{
        overlay: {
          backgroundColor: theme.colors.background.modalOverlay,
        },
        content: contentStyle,
      }}
    >
      <ModalHeader
        onCrossClick={props.onClose}
        title="Question zone à pointer"
      />
      <SingleAnswer
        image={props.answer.question_item.image}
        items={answer.question_item.labels}
        answer={props.answer.answer}
        onDragStop={props.onDragStop}
        onClose={props.onClose}
        disabled={!props.isQI && props.saved}
        shouldUpdateArrowsPositions={props.shouldUpdateArrowsPositions}
      />
    </LibModal>
  );
};

const contentStyle: CSSProperties = {
  backgroundColor: 'white',
  maxWidth: '700px',
  width: '100%',
  boxSizing: 'content-box',
  margin: 'auto',
  padding: 0,
  inset: '1rem',
  height: 'fit-content',
  maxHeight: '90%',
};

export default QZPModal;
