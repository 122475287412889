import { FC } from 'react';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';
import styled, { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { Loader } from 'components/Loader/Loader';

export interface PieChartData {
  name: string;
  value: number;
  percent: number;
}

export interface TagPieChartData extends PieChartData {
  shortName: string;
}

const r2cColors = ['#377B2B', '#ED9900', '#C23705'];

export const customLabel = (props: any) => {
  if (props.payload.payload.value === 0) {
    return null;
  }

  const leftCoef = props.direction === 'left' ? -1 : 1;

  const RADIAN = Math.PI / 180;
  const radius =
    12 + props.innerRadius + (props.outerRadius - props.innerRadius);
  let x = props.cx + radius * Math.cos(-props.midAngle * RADIAN) * leftCoef;
  const y = props.cy + radius * Math.sin(-props.midAngle * RADIAN) - 12;

  x = x < props.cx ? x - 25 : y < props.cy ? x + 35 : x + 25;

  const textA = x > props.cx ? 'start' : 'end';

  return (
    <>
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={textA}
        dominantBaseline="central"
      >
        <SubTitleText grey>{Math.round(props.percent * 100)}%</SubTitleText>
      </text>
      <text
        x={x}
        y={y + 20}
        fill="black"
        textAnchor={textA}
        dominantBaseline="central"
      >
        <SubTitleText grey>{props.value} Q°</SubTitleText>
      </text>
    </>
  );
};

export const customLabelLine = (props: any) => {
  if (props.payload.payload.value === 0) {
    return <></>;
  }

  const isLeft = props.direction === 'left';

  const RADIAN = Math.PI / 180;
  const radius = props.outerRadius - 8;
  const x = props.cx + radius * Math.cos(-props.midAngle * RADIAN);
  const y = props.cy + radius * Math.sin(-props.midAngle * RADIAN);
  var x2 =
    x +
    18.5 * Math.cos(-props.midAngle * RADIAN) +
    2 * Math.sin(props.midAngle * RADIAN) -
    1.5;
  const y2 =
    y +
    (18.5 * Math.sin(-props.midAngle * RADIAN) +
      2 * Math.cos(props.midAngle * RADIAN) -
      2);

  return (
    <g>
      <g
        fill={props.payload.fill}
        transform={`rotate(${-props.midAngle})`}
        transform-origin={`${x} ${y}`}
      >
        <rect x={x} y={y} width="20" height="4" rx="3" ry="3" />
      </g>
      <g
        fill={props.payload.fill}
        transform={x2 < props.cx || isLeft ? 'rotate(180)' : undefined}
        transform-origin={`${x2 + 1.5} ${y2 + 2}`}
      >
        <rect x={x2} y={y2} width="20" height="4" rx="3" ry="3" />
      </g>
    </g>
  );
};

export const StatPieChart: FC = () => {
  const theme = useTheme();
  const { ccbStats } = useSelector((state: any) => state.ccb);

  const customData = [
    {
      name: 'Pleinement réussie',
      value: ccbStats?.lca_total_pass,
    },
    {
      name: 'En partie réussie',
      value: ccbStats?.lca_partial_pass,
    },
    {
      name: 'Non maitrisée',
      value: ccbStats?.lca_fail,
    },
  ];

  if (!ccbStats) {
    return <Loader />;
  }

  return (
    <ResponsiveContainer width="100%">
      <PieChart id="tag-pie-chart">
        <text x={'50%'} y={'48%'} textAnchor="middle" dominantBaseline="middle">
          <TitleText fill={theme.colors.primary.dark}>LCA</TitleText>
        </text>
        <text x={'50%'} y={'55%'} textAnchor="middle" dominantBaseline="middle">
          <SubTitleText>{ccbStats.user_LCA_grade}/20</SubTitleText>
        </text>
        <Pie
          data={customData}
          dataKey="value"
          cx="50%"
          cy="50%"
          outerRadius={'55%'}
          innerRadius={'35%'}
          label={customLabel}
          labelLine={customLabelLine}
          stroke="none"
        >
          {customData.map((entry, index) => (
            <Cell key={index} fill={r2cColors[index % r2cColors.length]} />
          ))}
        </Pie>

        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};

const TitleText = styled.tspan`
  font-family: 'Urbanist';
  font-weight: 600;
  font-size: 1.5rem;
`;

const SubTitleText = styled.tspan<{ grey?: boolean }>`
  font-family: 'Urbanist';
  font-weight: 400;
  font-size: 1rem;
  fill: ${({ grey }) =>
    grey ? ({ theme }) => theme.colors.inactive : 'black'};
`;
