import { keyframes, css } from 'styled-components/macro';

const popSlideIn = keyframes`
  0% {
      transform: translateY(-100%);
      opacity: 0;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const popSlideInAnimation = css`
  animation: ${popSlideIn} 0.1s ease-out;
`;
