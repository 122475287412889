import * as React from 'react';

interface Props {
  className?: string;
}

export const StudentLogo = (props: Props) => (
  <svg
    version="1.1"
    id="Calque_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}
  >
    <g>
      <g>
        <path d="M9.9,10.9c-2.9-0.1-5-2.3-5-5.2c0-2.8,2.3-5,5.2-5c2.9,0.1,5,2.3,5,5.2C15,8.8,12.8,11,9.9,10.9z" />
      </g>
      <g>
        <path d="M19.5,18.7c0-5-4.3-6.7-9.5-6.7s-9.5,1.7-9.5,6.7c0,0.4,0,0.9,0.1,1.3h18.8C19.5,19.6,19.5,19.1,19.5,18.7z" />
      </g>
    </g>
  </svg>
);
