import { ItemCard } from './ItemCard';
import { NewRecapItem, PaginatedResponse } from 'http/new-home/types';
import { CardsContainer } from 'pages/newHome/ui/Global';
import { FC } from 'react';

interface ItemsCardsProps {
  items: PaginatedResponse<NewRecapItem>;
}

export const ItemsCards: FC<ItemsCardsProps> = ({ items }) => {
  return (
    <>
      <CardsContainer>
        {items &&
          items.results.length > 0 &&
          items.results
            .filter((item) => ![0, 368, 369].includes(item.item.item))
            .map((item: NewRecapItem, index: number) => (
              <ItemCard key={`${item.item.item}+${index}`} item={item} />
            ))}
      </CardsContainer>
    </>
  );
};
