import { useAtom } from 'jotai';
import {
  greyScaleAtom,
  panelRightAtom,
  sidebarAtom,
} from 'pages/new_training/atoms';
import { FC } from 'react';
import styled from 'styled-components';
import { QuestionListItem } from './QuestionListItem';
import { ToggleSwitch } from '../ui/ToggleSwitch';
import { GreyScaleIcon } from 'ui/icons/GreyScaleIcon';
import { TrainingType } from '../TrainingBody';

export interface QuestionGroupType {
  name: string;
  nbQuestions: number;
  currentQuestion: number;
  isQI: boolean;
  savedQuestions: Array<boolean>;
  modifiedQuestionIndex: number | null;
}
type Point = {
  x: number;
  y: number;
};
interface Props {
  questionGroups: Array<QuestionGroupType> | undefined;
  selectedQuestionGroup: number;
  onQuestionGroupChange(questionGroupIndex: number): void;
  onQuestionChange(questionIndex: number): void;
  question: Omit<TrainingType, 'onSave' | 'onModification'> | undefined;
}

export const QuestionsList: FC<Props> = (props) => {
  const [panelRight, setPanelRight] = useAtom(panelRightAtom); // Is the panel on the right or on the left
  const [sidebar] = useAtom(sidebarAtom); // Is the sidebar open or closed
  const [greyScale, setGreyScale] = useAtom(greyScaleAtom);
  const createQuestionsList = (
    savedQuestions: Array<boolean>,

    currentQuestion: number,
    isQI: boolean,
    modifiedQuestionIndex: number | null
  ) => {
    const questions = [];
    const current = props.question?.questions[currentQuestion];

    let shouldColorInRose: boolean = false;

    if (
      current &&
      (current.type === 'QRM' ||
        current.type === 'QRU' ||
        current.type === 'QRP')
    ) {
      if (current.training_items_qcm.some((el) => el.answer === true)) {
        shouldColorInRose = true;
      }
    }

    if (current && current.type === 'QZP') {
      const isEmpty = Object.keys(current.training_item_qzp).length === 0;
      const areAllAtInitialValue = Object.values(
        current.training_item_qzp['answer']
      ).every((el) => {
        const point = el as Point;
        return point.x === -1 && point.y === -1;
      });

      if (!isEmpty && !areAllAtInitialValue) {
        shouldColorInRose = true;
      }
    }

    if (current && current.type === 'QROC') {
      if (
        current.training_item_qroc.answer !== '' &&
        current.training_item_qroc.answer !== null
      ) {
        shouldColorInRose = true;
      }
    }

    if (current && current.type === 'QRPL') {
      if (current.training_items_qrpl.some((el) => el.answer === true)) {
        shouldColorInRose = true;
      }
    }

    for (let index = 0; index < savedQuestions.length; index++) {
      questions.push(
        <QuestionListItemContainer
          key={index}
          onClick={() =>
            isQI || index <= currentQuestion
              ? props.onQuestionChange(index)
              : null
          }
        >
          <QuestionListItem
            isFilled={savedQuestions[index]}
            isCurrent={index === currentQuestion}
            isBeingModified={modifiedQuestionIndex === index}
            shouldColorInRose={shouldColorInRose}
            isQI={isQI}
            isFirstQuestion={index === 0}
          >
            {index + 1}
          </QuestionListItem>
        </QuestionListItemContainer>
      );
    }
    return questions;
  };

  const toggleGreyScale = () => {
    setGreyScale((prev) => !prev);
  };
  return (
    <Container sidebar={sidebar}>
      <ToggleSwitchContainer>
        <SwitchFlexContainer>
          <LeftRightLabel>Gauche</LeftRightLabel>
          <StyledToggleSwitch
            checked={panelRight}
            onChange={() => setPanelRight(!panelRight)}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor="#183876"
            offColor="#183876"
            onHandleColor="#fff"
            offHandleColor="#fff"
            borderRadius={15}
            height={30}
            width={60}
          />
          <LeftRightLabel>Droite</LeftRightLabel>
        </SwitchFlexContainer>

        <div onClick={toggleGreyScale}>
          <StyledGreyScaleIcon
            fill={greyScale ? 'white' : 'black'}
            style={{
              background: greyScale ? 'black' : 'white',
            }}
          />
        </div>
      </ToggleSwitchContainer>

      {props.questionGroups ? (
        <div>
          {props.questionGroups.map((el, trialIndex) => {
            return (
              <TrialContainer
                key={trialIndex}
                onClick={() => props.onQuestionGroupChange(trialIndex)}
              >
                <TrialNameContainer>
                  <TrialName>{el.name}</TrialName>
                </TrialNameContainer>
                {props.selectedQuestionGroup === trialIndex ? (
                  <QuestionListWrapper>
                    {createQuestionsList(
                      el.savedQuestions,
                      el.currentQuestion,
                      el.isQI,
                      el.modifiedQuestionIndex
                    )}
                  </QuestionListWrapper>
                ) : null}
                <Separator />
              </TrialContainer>
            );
          })}
        </div>
      ) : (
        <TrialNameContainer>
          <TrialName>Loading...</TrialName>
          <TrialQuestionNumber />
        </TrialNameContainer>
      )}
    </Container>
  );
};

const QuestionListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  padding: 1rem 0.5rem;
`;
const Container = styled.div<{ sidebar: boolean }>`
  position: relative;
  height: 100%;
  border-radius: 4px;
  margin-top: 18px;
  overflow-y: auto;
`;

const TrialContainer = styled.div`
  cursor: pointer;
`;

const TrialNameContainer = styled.div`
  height: 46px;
  background-color: #183876;
  margin: 0;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 0 13px;
`;

const TrialName = styled.label`
  font-family: 'FreeSans';
  color: white;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  width: 100%;
`;

const TrialQuestionNumber = styled.label`
  font-family: 'FreeSans';
  color: white;
  font-size: 14px;
  cursor: pointer;
`;

const QuestionListItemContainer = styled.div`
  border: 1px solid #d6dbe0;
`;

const ToggleSwitchContainer = styled.div`
  margin-bottom: 1rem;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledGreyScaleIcon = styled(GreyScaleIcon)`
  width: 46px;
  height: 46px;
  padding: 4px;
  border: 2px solid #183876;
`;

const Separator = styled.div`
  margin-top: 8px;
`;

const SwitchFlexContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 3;
  grid-gap: 0.5rem;
  grid-row: 1;
  margin-right: 16px;
`;

const StyledToggleSwitch = styled(ToggleSwitch)`
  border: 4px solid #183876;
  border-radius: 99px !important;
  grid-column: 2;
  grid-row: 1;
`;

const LeftRightLabel = styled.label`
  font-size: 16px;
  font-family: 'FreeSans';
  font-weight: normal;
`;
