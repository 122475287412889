import { createContext, FC, useContext, useState } from 'react';

import { CorrectionModal } from '../components/CorrectionModal';

interface CorrectionModalContext {
  openModal: (values: {
    correctionId: number;
    qiIdInGroupCorrection: number;
    qiId: number;
  }) => void;
}

const contextDefaultValue: CorrectionModalContext = {
  openModal: () => {},
};

const correctionModalContext = createContext(contextDefaultValue);

export const CorrectionModalProvider: FC = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupCorrectionId, setGroupCorrectionId] = useState<number>();
  const [qiId, setQIId] = useState<number>();
  const [qiIdInGroupCorrection, setQIIdInGroupCorrection] = useState<number>();

  const openModal = ({
    correctionId,
    qiId,
    qiIdInGroupCorrection,
  }: {
    correctionId: number;
    qiIdInGroupCorrection: number;
    qiId: number;
  }) => {
    setGroupCorrectionId(correctionId);
    setQIId(qiId);
    setQIIdInGroupCorrection(qiIdInGroupCorrection);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <correctionModalContext.Provider value={{ openModal }}>
      {groupCorrectionId && qiId && qiIdInGroupCorrection && (
        <CorrectionModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          correctionGroupId={groupCorrectionId}
          qiIdInCorrection={qiIdInGroupCorrection}
          qiId={qiId}
        />
      )}
      {children}
    </correctionModalContext.Provider>
  );
};

export const useCorrectionModalContext = () =>
  useContext(correctionModalContext);
