import styled from 'styled-components';
import { device } from 'helpers/css/responsive';
import { CCBCopiesTable } from '../../TrainingTable/CCBCopiesTable';
import FadeIn from 'ui/Animations/FadeIn';

export const CorrectedCopy = () => {
  return (
    <>
      <FadeIn>
        <StatContainer>
          <h3>Mes notes</h3>
          <Styledtext>
            Les rangs des notes par UC ne sont pas pondérés par les coefficients
            des DES.
          </Styledtext>
          <CCBCopiesTable />
        </StatContainer>
      </FadeIn>
    </>
  );
};

export const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  gap: 1rem;
`;

export const StatCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;
  @media ${device.mobile} {
    flex-direction: column;
    gap: 2rem;
  }
`;

const Styledtext = styled.div`
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.inactive};
`;
