import { device } from 'helpers/css/responsive';
import { useDeleteFQDeck } from 'http/flash-questions/delete-deck';
import React from 'react';
import { FC } from 'react';
import styled from 'styled-components';
import { Button } from 'ui/Button';
import { Modal } from 'ui/Modal';

interface Props {
  isOpen: boolean;
  close: () => void;
  deckName: string;
}

export const DeleteModal: FC<Props> = ({ deckName, isOpen, close }) => {
  const deleteFQDeckMutation = useDeleteFQDeck();

  const handleDelete = () => {
    deleteFQDeckMutation.mutate({ label: deckName });
    close();
  };

  return (
    <Modal isOpen={isOpen}>
      <Container>
        <h4>
          Voulez-vous vraiment supprimer le paquet {<br />}
          <strong>"{deckName}"</strong> ?
        </h4>
        <Buttons>
          <Button onClick={close}>Annuler</Button>
          <Button onClick={handleDelete} className="yes">
            Oui
          </Button>
        </Buttons>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

const Buttons = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  flex-direction: column;

  & .yes {
    margin-top: 20px;
  }
  @media ${device.desktop} {
    flex-direction: row;
    padding: 16px 20px;
    padding-bottom: 0;
    margin-top: 0;
    & .yes {
      margin-top: 0;
    }
  }
`;
