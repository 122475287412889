import { ProgressBarStat } from 'components/StatsElements/ProgressBarStats';
import { FC } from 'react';
import styled, { useTheme } from 'styled-components/macro';

interface Props {
  percentage: number;
}

export const ProgressBar: FC<Props> = ({ percentage }) => {
  const theme = useTheme();

  return (
    <Container>
      <label>{percentage ? percentage.toFixed(0) : 0}%</label>
      <BarContainer>
        <ProgressBarStat
          fillingColor={theme.colors.secondary.dark}
          backgroundFillingColor={theme.colors.secondary.light}
          percentage={percentage}
          height={12}
        />
      </BarContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 4px;

  @media (min-height: 1000px) {
    margin-top: 20px;
  }
`;

const BarContainer = styled.div`
  flex-grow: 1;
`;
