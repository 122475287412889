import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { ItemInfos } from 'store/types';
import { Checkbox } from 'ui/Checkbox';
import { useTableConfigContext } from '../contexts/tableConfigContext';

interface Props {
  itemInfo: ItemInfos;
  isFree: boolean;
}

export const CheckboxCell: FC<Props> = ({ itemInfo, isFree }) => {
  const dispatch = useDispatch();
  //const canAddToCart = useCanAddToCart();
  const isUserFree = useIsUserFree();

  const { tableStateSelector, toggleSelectionCreator } =
    useTableConfigContext();

  const selectedItems = useSelector(
    (state: RootState) => tableStateSelector(state).selectedItemsInfos
  );

  const [isSelected, setIsSelected] = useState<boolean>(
    selectedItems.map((item) => item.id).includes(itemInfo.id)
  );

  useEffect(() => {
    setIsSelected(selectedItems.map((item) => item.id).includes(itemInfo.id));
  }, [itemInfo, selectedItems]);

  const toggleSelection = () => {
    // if (!isSelected) {
    //   const canAdd = canAddToCart(tableType);
    //   if (!canAdd) return;
    // }

    dispatch(toggleSelectionCreator(itemInfo));
  };

  return (
    <Checkbox
      checked={isSelected}
      onChange={toggleSelection}
      disabled={!isFree && isUserFree}
    />
  );
};
