import { FC } from 'react';
import styled from 'styled-components/macro';
import { useResetSelection } from 'hooks/useResetSelection';
import { Button } from 'ui/Button';

export const ResetSelectionButton: FC = () => {
  const { resetAll } = useResetSelection();

  return (
    <LightButton small onClick={resetAll}>
      Réinitialiser la sélection
    </LightButton>
  );
};

const LightButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.primary.main};
`;
