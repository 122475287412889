import { forwardRef, useState } from 'react';
import styled from 'styled-components/macro';

import { device } from 'helpers/css/responsive';

import { TextInput, TextInputProps } from 'ui/TextInput';
import { PasswordEyeClosed } from 'ui/icons/PasswordEyeClosed';
import { PasswordEyeOpen } from 'ui/icons/PasswordEyeOpen';

type Props = Omit<TextInputProps, 'type'>;

export const PasswordInput = forwardRef<HTMLInputElement, Props>(
  ({ className, ...inputProps }, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

    const handleClick = () => setIsPasswordVisible(!isPasswordVisible);

    return (
      <Container className={className}>
        <TextInput
          type={isPasswordVisible ? 'text' : 'password'}
          paddingInnerRight={
            window.matchMedia && window.matchMedia(device.desktop).matches
              ? TEXTINPUT_PADDING_RIGHT.desktop * 2 + BUTTON_WIDTH
              : TEXTINPUT_PADDING_RIGHT.mobile * 2 + BUTTON_WIDTH
          }
          ref={ref}
          {...inputProps}
        >
          <VisibilityToggleButton onClick={handleClick} type="button">
            {isPasswordVisible ? <PasswordEyeOpen /> : <PasswordEyeClosed />}
          </VisibilityToggleButton>
        </TextInput>
      </Container>
    );
  }
);

const TEXTINPUT_PADDING_RIGHT = {
  desktop: 24,
  mobile: 16,
};

const ICON_WIDTH = {
  desktop: 26,
  mobile: 20,
};

const BUTTON_WIDTH = 32;

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const VisibilityToggleButton = styled.button`
  background: none;
  border: none;
  padding: 0;

  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary.dark};

  position: absolute;
  right: ${TEXTINPUT_PADDING_RIGHT.mobile}px;
  // Align button vertically in the input
  top: 0;
  bottom: 0;
  margin: auto;

  height: ${BUTTON_WIDTH}px;
  width: ${BUTTON_WIDTH}px;

  // Align icon vertically in the button
  display: grid;
  place-items: center;

  @media ${device.desktop} {
    right: ${TEXTINPUT_PADDING_RIGHT.desktop}px;
  }

  & svg {
    height: 16px;
    width: ${ICON_WIDTH.mobile}px;

    @media ${device.desktop} {
      height: 16px;
      width: ${ICON_WIDTH.desktop}px;
    }
  }
`;
