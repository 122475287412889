import { SessionDetailedProgressRepository } from '../domain/SessionDetailedProgress.repository';
import { adaptSessionDetailedProgress } from './adapter';
import { getSessionDetailedProgressHttp } from './api';

export const getSessionDetailedProgressRepository =
  (): SessionDetailedProgressRepository =>
    sessionDetailedProgressRepositoryImpl;

const sessionDetailedProgressRepositoryImpl: SessionDetailedProgressRepository =
  {
    getSessionDetailedProgress: async (params) => {
      const httpResponse = await getSessionDetailedProgressHttp(params);

      return adaptSessionDetailedProgress(httpResponse);
    },
  };
