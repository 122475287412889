import { useTheme } from 'styled-components/macro';
import { MutatingDots } from 'react-loader-spinner';
import { FC, Fragment } from 'react';

interface Props {
  height?: string;
  width?: string;
  children?: React.ReactNode;
  Container?: React.ComponentType<any>;
}

export const Loader: FC<Props> = ({
  height = '90px',
  width = '90px',
  children,
  Container = Fragment,
}) => {
  const theme = useTheme();

  return (
    <Container>
      <MutatingDots
        color={theme.colors.primary.dark}
        secondaryColor={theme.colors.secondary.main}
        height={height}
        width={width}
      />
      {children}
    </Container>
  );
};
