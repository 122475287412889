import styled from 'styled-components/macro';
import { device } from 'helpers/css/responsive';

export const Chip = styled.div`
  ${({ theme }) => theme.typography.label};

  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.primary.contrastText};
  padding: 2px 4px;
  border-radius: 8px;

  @media ${device.desktop} {
    font-size: 20px;
    line-height: 24px;
    font-size: 20px;
    line-height: 20px;
  }

  min-width: 26px;
  flex-shrink: 0;
  display: grid;
  place-items: center;

  cursor: default;
  transition: background-color 100ms ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.dark};
  }
`;
