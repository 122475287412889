import { Link } from 'react-router-dom';
import { routes } from 'routing/constants';
import styled from 'styled-components/macro';

export const NotFound404 = () => {
  return (
    <Container>
      <Content>
        <h1>404: Page not found</h1>
        <Link to={routes.NEW_HOME}>Retourner à ma page d'accueil</Link>
      </Content>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;

  display: grid;
  place-items: center;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
