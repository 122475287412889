import { useEffect, useState } from 'react';
import { getPrices } from 'http/payments/getPrices/api';
import { PriceInfos } from '../../../http/payments/getPrices/types';
import { sortDataObject } from 'http/payments/getPrices/utils';
import { formatDate } from 'helpers/dates/utils';

export const useGetPrice = () => {
  const [prices, setPrices] = useState<PriceInfos[]>([]);

  useEffect(() => {
    async function getAvailablePrices() {
      const pricesData = await getPrices();
      const sortedPrices = pricesData.sort(
        (a, b) =>
          new Date(a.end_subscription).getTime() -
          new Date(b.end_subscription).getTime()
      );
      if (pricesData) {
        setPrices(
          sortedPrices.map((price) => ({
            id: price.id,
            label: price.label,
            end_subscription: formatDate(price.end_subscription),
            amount: price.amount,
            session: sortDataObject(price.session),
          }))
        );
      }
    }
    getAvailablePrices();
  }, []);

  return prices;
};
