import { useState, useMemo, useEffect } from 'react';
import { getCompassItems } from 'http/compass/api';
import { ItemKnowledgesBooks } from 'http/compass/types';
import { toast } from 'react-toastify';
import { getItemsListHttp } from 'http/selection/get_item/api';
import { RecapItemsParams } from 'http/new-home/types';
import { useQuery } from 'react-query';
import { useHomeItemsFiltersContext } from '../context/homeItemsFiltersContext';
import { MS_IN_AN_HOUR } from 'constants/date';
import { getHomeItems } from 'http/new-home/api';
import { Options } from 'types/options.type';

export const useGetHomeItems = () => {
  const {
    itemPageSize,
    currentItemPage,
    setCurrentItemPage,
    itemsSearchedBooks,
    itemsSearchedSpe,
    debouncedSearchTerm,
    itemStudyTours,
    isStudyTourLoading,
    // setIsStudyTourLoading,
  } = useHomeItemsFiltersContext();
  const [params, setParams] = useState<RecapItemsParams>({
    search: debouncedSearchTerm,
    rounds: itemStudyTours.map((tour) => Number(tour.value)),
    books: itemsSearchedBooks.map((book) => book.label),
    des_groups: itemsSearchedSpe.map((des) => des.label),
    page_size: itemPageSize,
    page: currentItemPage + 1,
  });

  const getParams = () => ({
    ...params,
    search: debouncedSearchTerm,
    rounds: itemStudyTours.map((tour) => Number(tour.value)),
    books: itemsSearchedBooks.map((book) => book.value),
    des_groups: itemsSearchedSpe.map((des) => des.value),
    page_size: itemPageSize,
    page: currentItemPage + 1,
  });

  useEffect(() => {
    setCurrentItemPage(0);
    setParams({
      ...getParams(),
      // We reset the page to the first one when the filters are updated
      page: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    itemsSearchedBooks,
    itemsSearchedSpe,
    itemPageSize,
    debouncedSearchTerm,
    itemStudyTours,
    isStudyTourLoading,
  ]);

  useEffect(() => {
    setParams(getParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItemPage]);

  const { data, isLoading, isError, refetch } = useQuery(
    ['getHomeItems', params],
    () => getHomeItems(params),
    {
      staleTime: 0,
      cacheTime: MS_IN_AN_HOUR,
    }
  );

  return { data, isLoading, isError, refetch };
};

// request with filters, favorites = true and no pagination
export const useGetHomeFavoriteItems = () => {
  const {
    setCurrentItemPage,
    itemsSearchedBooks,
    itemsSearchedSpe,
    debouncedSearchTerm,
    itemStudyTours,
    isStudyTourLoading,
  } = useHomeItemsFiltersContext();
  const [params, setParams] = useState<RecapItemsParams>({
    search: debouncedSearchTerm,
    rounds: itemStudyTours.map((tour) => Number(tour.value)),
    books: itemsSearchedBooks.map((book) => book.label),
    des_groups: itemsSearchedSpe.map((des) => des.label),
    favorite: true,
  });

  const getParams = () => ({
    ...params,
    search: debouncedSearchTerm,
    rounds: itemStudyTours.map((tour) => Number(tour.value)),
    books: itemsSearchedBooks.map((book) => book.value),
    des_groups: itemsSearchedSpe.map((des) => des.value),
    favorite: true,
  });

  useEffect(() => {
    setCurrentItemPage(0);
    setParams({
      ...getParams(),
      // We reset the page to the first one when the filters are updated
      page: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    itemsSearchedBooks,
    itemsSearchedSpe,
    debouncedSearchTerm,
    itemStudyTours,
    isStudyTourLoading,
  ]);

  const { data, isLoading, isError, refetch } = useQuery(
    ['getHomeFavoriteItems', params],
    () => getHomeItems(params),
    {
      staleTime: 0,
      cacheTime: MS_IN_AN_HOUR,
    }
  );

  return { data, isLoading, isError, refetch };
};

export const useGetItems = () => {
  const getItemsList = async () => {
    const query = await getItemsListHttp();
    return query;
  };

  const { data: items } = useQuery(['getItemsList'], () => getItemsList(), {
    staleTime: MS_IN_AN_HOUR,
    cacheTime: MS_IN_AN_HOUR,
  });
  return items;
};

export const useGetItemsOptions = () => {
  const items = useGetItems();

  const itemsOptions: Options = useMemo(() => {
    return (
      items
        ?.filter(
          (currentValue) =>
            currentValue.item !== 0 &&
            currentValue.item !== 368 &&
            currentValue.item !== 369
        )
        .filter(
          (currentValue) =>
            currentValue.title !== '' && currentValue.item !== null
        )
        .map((currentValue) => ({
          label: `${currentValue.item} - ${currentValue.short_title}`,
          value: `${currentValue.item}`,
        }))
        .sort((a, b) => Number(a.value) - Number(b.value)) || []
    );
  }, [items]);
  return { itemsOptions };
};

export const useGetItem = (itemId: number | undefined) => {
  const [item, setItem] = useState<ItemKnowledgesBooks>();

  useMemo(() => {
    const fetchData = async () => {
      try {
        if (itemId || itemId === 0) {
          const itemData = await getCompassItems(itemId);
          setItem(itemData);
        }
      } catch (error) {
        toast.error('Cet item est introuvable.');
      }
    };
    fetchData();
  }, [itemId]);
  return { item };
};
