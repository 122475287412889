import { FC } from 'react';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';

import { requestResendVerificationEmail } from 'http/verify-email/api';

interface Props {
  email: string;
}

export const UnverifiedEmailToast: FC<Props> = ({ email }) => {
  const handleResendEmail = async () => {
    toast.promise(requestResendVerificationEmail(email), {
      pending: "Envoi d'un nouvel e-mail...",
      success: 'Un nouvel e-mail vous a été envoyé !',
      error: 'Oups une erreur est arrivée',
    });
  };

  return (
    <Container>
      <div>Vous n'avez pas confirmé votre e-mail {email}</div>
      <LinkButton type="button" onClick={handleResendEmail}>
        Me renvoyer un mail
      </LinkButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const LinkButton = styled.button`
  all: unset;
  color: #0000ee;

  &:hover {
    text-decoration: underline;
  }
`;
