import styled from 'styled-components';
import { SmallDownArrow } from 'ui/icons/SmallDownArrow';

type ArrowDirection = 'up' | 'down' | 'left' | 'right';

interface ArrowProps {
  $open: boolean; // Changed to $open
  $hasTrained?: boolean;
  $defaultDirection?: ArrowDirection; // Changed to $defaultDirection
  $openedDirection?: ArrowDirection; // Changed to $openedDirection
}

const getRotation = (direction: ArrowDirection): string => {
  switch (direction) {
    case 'up':
      return '180deg';
    case 'down':
      return '0deg';
    case 'left':
      return '90deg';
    case 'right':
      return '-90deg';
    default:
      return '0deg';
  }
};

export const SmallArrow = styled(SmallDownArrow)<ArrowProps>`
  width: 12px;
  height: 12px;

  transform: ${(props) => {
    const direction = props.$open
      ? props.$openedDirection
      : props.$defaultDirection;
    return `rotate(${getRotation(direction || 'down')})`;
  }};
  transition: transform 0.2s ease-in-out;
  cursor: pointer;
  opacity: ${(props) => (props.$hasTrained ? 1 : 0)};
`;
