import styled, { css } from 'styled-components/macro';

import { device } from 'helpers/css/responsive';

export const Table = styled.table`
  --table-border-radius: 10px;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border-radius: var(--table-border-radius);
`;

export const Thead = styled.thead<{ widthLastCell?: string }>`
  width: 100%;
  th:first-child {
    border-top-left-radius: var(--table-border-radius);
  }

  th:last-child {
    border-top-right-radius: var(--table-border-radius);
  }
`;

export const Th = styled.th`
  padding: 16px 16px;
  background-color: ${({ theme }) => theme.colors.primary.main};
  color: ${({ theme }) => theme.colors.primary.contrastText};
  text-align: start;
  @media (max-width: 1300px) {
    padding: 16px 4px;
  }
`;

export const Tbody = styled.tbody``;

export const Tr = styled.tr<{ isOdd?: boolean }>`
  --row-border-radius: 8px;
  td:first-child {
    border-top-left-radius: var(--row-border-radius);
    border-bottom-left-radius: var(--row-border-radius);
  }
  td:last-child {
    border-top-right-radius: var(--row-border-radius);
    border-bottom-right-radius: var(--row-border-radius);
  }

  ${({ isOdd, theme }) =>
    isOdd &&
    css`
      background-color: ${theme.colors.primary.light};
    `}
`;

export const Td = styled.td<{ isCheckbox?: boolean }>`
  @media ${device.desktop} {
    padding: 16px 20px;
  }

  @media ${device.largeTable} {
    padding: 16px 36px;
  }

  ${({ theme }) => theme.typography.textBody2}

  ${({ isCheckbox }) =>
    isCheckbox &&
    css`
      padding: 16px 32px;
    `}
`;
