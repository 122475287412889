import { forwardRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

import { SingleAnswer } from './SingleAnswer';
import { ValidateButton } from '../ui/ValidateButton';
import { ValidationModal } from '../ValidationModal/ValidationModal';
import { StyledMathJax } from 'ui/MathJax';
import { NoAnswerModal } from '../NoAnswerModal/NoAnswerModal';
import { toast } from 'react-toastify';
import { useSaveQuestionQROC } from 'pages/new_training/hooks/saveHooks/useSaveQROC';

interface Props {
  question: string;
  questionNumber: number;
  questionId: number;
  answer: {
    id: number;
    answer: string;
  };
  className?: string;
  saved: boolean;
  show: boolean;
  onSave(): void;
  isQI: boolean;
  onModification?(): void;
  anonymous: boolean;
  collection: string | undefined;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const QROC = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [answer, setAnswer] = useState<string>(props.answer?.answer);
  const answerRef = useRef<string>(props.answer?.answer);

  const [validationModal, setValidationModal] = useState<boolean>(false);
  const [noAnswerModal, setNoAnswerModal] = useState<boolean>(false);

  const saveQuestion = useSaveQuestionQROC(props.onSave);

  const handleSaveQuestion = async () => {
    props.setIsLoading(true);
    const params = {
      id: props.questionId,
      answers: {
        id: props.answer.id,
        answer: answer || '',
      },
    };

    try {
      await saveQuestion(params);
      answerRef.current = answer;
    } catch (err) {
      toast.error(
        'Erreur lors de la sauvegarde de la question, veuillez réessayer'
      );
    }

    props.setIsLoading(false);
  };

  //   useEffect(() => {
  //     props.onModification && props.onModification();
  //   }, [answer]);

  const handleChange = (value: string) => {
    const newAnswers = props.answer;
    newAnswers.answer = value;

    setAnswer(value);
  };

  useEffect(() => {
    if (props.answer.answer !== answer) {
      setAnswer(props.answer.answer);
    }
    // eslint-disable-next-line
  }, [props.answer.answer]);

  useEffect(() => {
    if (props.show && !props.isQI) {
      titleRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line
  }, [props.show]);

  const handleValidationClick = () => {
    if (props.isQI) {
      handleSaveQuestion();
      return;
    }

    if (!answer || answer.trim() === '') {
      setNoAnswerModal(true);
    } else {
      setValidationModal(true);
    }
  };

  useEffect(() => {
    if (answerRef.current !== answer) {
      //   props.isQI && props.onModification && props.onModification();
      props.onModification && props.onModification();
    }
    // eslint-disable-next-line
  }, [answer, answerRef]);

  const getTitle = () => {
    // if (props.anonymous) {
    //     return '';
    // }
    // if (props.isQI) {
    //     return props.session + ' ' + props.year + ' ';
    // }
    // return '';

    return (
      'Question ' +
      props.questionNumber +
      ' - Question à réponse ouverte et courte'
    );
  };

  return (
    <Container ref={ref} show={props.show}>
      <Title ref={titleRef}>{getTitle()}</Title>
      <Statement>
        <StyledMathJax inline>{parse(props.question)}</StyledMathJax>
      </Statement>
      <StyledSingleAnswer
        answer={answer || ''}
        onChange={handleChange}
        disabled={!props.isQI && props.saved}
      ></StyledSingleAnswer>
      <ValidateButton
        show={props.isQI || !props.saved}
        onClick={handleValidationClick}
        isLoading={props.isLoading}
      />
      <NoAnswerModal
        isOpen={noAnswerModal}
        onCancel={() => setNoAnswerModal(false)}
        onAccept={() => {
          setNoAnswerModal(false);
          setValidationModal(true);
        }}
        title="Réponse définitive"
        //TODO: remove
        text={
          `<b style="color:#F3002C; font-family:freesans;">Attention, vous n'avez pas répondu à la question</b>` +
          `<br /> En cliquant sur le bouton OK vous confirmez votre choix.` +
          `<br /><b style="color:#F3002C; font-family:freesans;">Votre réponse ne sera plus modifiable</b>`
        }
        confirmationButtonColor="#192942"
        confirmationButtonHoverColor="#56595e"
      />
      <ValidationModal
        isOpen={validationModal}
        onCancel={() => setValidationModal(false)}
        onAccept={() => {
          setValidationModal(false);
          handleSaveQuestion();
        }}
        title="Réponse définitive"
        text={
          `En cliquant sur le bouton OK vous confirmez vos réponses.` +
          `<br /><b style="color:#F3002C; font-family:freesans;">Elles ne seront plus modifiables.</b>`
        }
      />
    </Container>
  );
});

const Container = styled.div<{ show?: boolean }>`
  margin-bottom: 50px;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

const Title = styled.div`
  display: block;
  font-family: 'FreeSans';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;

  color: #222529;
`;

const Statement = styled.label`
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  font-family: 'FreeSans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  sup {
    font-size: 0.83em;
  }

  p,
  span {
    font-family: 'FreeSans' !important;
  }

  img {
    max-width: 60%;
    height: auto;
  }
`;

const StyledSingleAnswer = styled(SingleAnswer)``;
