import { darkColors } from './darkColors';
import { lightColors } from './lightColors';
import { ThemeColors } from './types';

export type ThemeVariants = 'light' | 'dark';

export const themeColorsMap: { [key in ThemeVariants]: ThemeColors } = {
  light: lightColors,
  dark: darkColors,
};
