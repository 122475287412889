import { createContext, FC, useCallback, useContext, useState } from 'react';

import { useCanAddToCart } from 'pages/selection/hooks/useCanAddToCart';
import { usePrefetchQIDetails } from 'http/progress/detail/qi/connector';

interface AddToLocalCartContext {
  toggleInLocalCart: (qiId: number) => void;
  isQuestionInLocalSelection: (qiId: number) => boolean;
  flushLocalCart: () => void;
  localCart: number[];
  setLocalCart: (newCart: number[]) => void;
}

const contextDefaultValue: AddToLocalCartContext = {
  toggleInLocalCart: () => {},
  isQuestionInLocalSelection: () => true,
  flushLocalCart: () => {},
  localCart: [],
  setLocalCart: () => {},
};

const addToLocalCartContext = createContext(contextDefaultValue);

export const AddToLocalCartProvider: FC = ({ children }) => {
  const [questionsToAddToCart, setQuestionsToAddToCart] = useState<number[]>(
    []
  );

  const prefetchQIDetails = usePrefetchQIDetails();

  const _canAddToCart = useCanAddToCart();
  const canAddToCart = () =>
    _canAddToCart('QI', questionsToAddToCart.length + 1);

  const flushCart = () => setQuestionsToAddToCart([]);

  const isQuestionInSelection = useCallback(
    (qiId) => questionsToAddToCart.includes(qiId),
    [questionsToAddToCart]
  );

  const toggleToCart = (qiId: number) => {
    prefetchQIDetails(qiId);

    if (isQuestionInSelection(qiId)) {
      setQuestionsToAddToCart(questionsToAddToCart.filter((id) => id !== qiId));
    } else {
      if (canAddToCart()) {
        setQuestionsToAddToCart([...questionsToAddToCart, qiId]);
      }
    }
  };

  return (
    <addToLocalCartContext.Provider
      value={{
        toggleInLocalCart: toggleToCart,
        isQuestionInLocalSelection: isQuestionInSelection,
        localCart: questionsToAddToCart,
        flushLocalCart: flushCart,
        setLocalCart: setQuestionsToAddToCart,
      }}
    >
      {children}
    </addToLocalCartContext.Provider>
  );
};

export const useAddToLocalCartContext = () => useContext(addToLocalCartContext);
