import React from 'react';
import styled from 'styled-components/macro';

interface Props {
  end_subscription: string;
  margin?: string;
}

const DateOfValidity: React.FC<Props> = ({ end_subscription, margin }) => {
  return (
    <DateOfValidityContainer margin={margin}>
      <span>Date de fin</span>
      <span>{end_subscription}</span>
    </DateOfValidityContainer>
  );
};

const DateOfValidityContainer = styled.div<{ margin?: string }>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: ${({ margin }) => margin || '0'};
`;

export default DateOfValidity;
