import { FC } from 'react';
import { MenuIconProps } from './types';

export const MenuFaq: FC<MenuIconProps> = ({ isFilled }) => {
  return (
    <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={8} cy={8} r={7.5} stroke="currentColor" />
      <path
        d="M5.333 5.877c0-1.06.376-2.544 2.757-2.544 2.332 0 2.998 2.44 2.332 3.604-.848 1.485-2.332 0-2.332 3.817"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse cx={8} cy={13.072} rx={0.889} ry={0.928} fill="currentColor" />
    </svg>
  );
};
