import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { ItemInfos } from 'store/types';
import { Checkbox } from 'ui/Checkbox';
import { useCanAddToCart } from '../hooks/useCanAddToCart';
import { useTableConfigContext } from '../tableConfigContext';

interface Props {
  itemInfos: ItemInfos;
  isFree: boolean;
}

export const CheckboxCell: FC<Props> = ({ itemInfos, isFree }) => {
  const dispatch = useDispatch();
  const canAddToCart = useCanAddToCart();
  const isUserFree = useIsUserFree();

  const { tableStateSelector, toggleSelectionCreator, tableType } =
    useTableConfigContext();

  const selectedItems = useSelector(
    (state: RootState) => tableStateSelector(state).selectedItemsInfos
  );

  const [isSelected, setIsSelected] = useState<boolean>(
    selectedItems.map((item) => item.id).includes(itemInfos.id)
  );

  useEffect(() => {
    setIsSelected(selectedItems.map((item) => item.id).includes(itemInfos.id));
  }, [itemInfos, selectedItems, canAddToCart]);

  const toggleSelection = () => {
    if (!isSelected) {
      if (itemInfos && itemInfos.number_of_questions) {
        const toAdd =
          itemInfos.number_of_questions >= 13
            ? { short: 0, long: 1 }
            : { short: 1, long: 0 };
        const canAdd = canAddToCart(tableType, 1, toAdd);
        if (!canAdd) return;
      } else {
        const canAdd = canAddToCart(tableType);
        if (!canAdd) return;
      }
    }

    dispatch(toggleSelectionCreator(itemInfos));
  };

  return (
    <Checkbox
      checked={isSelected}
      onChange={toggleSelection}
      disabled={!isFree && isUserFree}
    />
  );
};
