import styled from 'styled-components/macro';
import { HomeInfoProvider } from './context/homeInfoContext';
import { WholePageContent } from './WholePageContent';

export const NewHome = () => {
  return (
    <Layout>
      <HomeInfoProvider>
        <WholePageContent />
      </HomeInfoProvider>
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  padding: 48px 32px;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 2rem;
`;
