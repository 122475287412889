import { useGetEstimatedDurations } from 'modules/user-options/hooks/useGetEstimatedDurations';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { RootState } from 'store';

export const useCanAddToCart = () => {
  const dpSelectedItems = useSelector((state: RootState) =>
    state.dpTable.selectedItemsInfos.reduce(
      (accumulator, currentValue) => {
        if (currentValue && currentValue.number_of_questions >= 13) {
          accumulator.long += 1;
        } else {
          accumulator.short += currentValue.number_of_questions;
        }
        return accumulator;
      },
      { short: 0, long: 0 }
    )
  );

  const qiSelectedItems = useSelector(
    (state: RootState) => state.qiTable.selectedItemsInfos.length
  );

  const lcaSelectedItems = useSelector(
    (state: RootState) => state.lcaTable.selectedItemsInfos
  );

  const estimatedDurations = useGetEstimatedDurations();

  const fullCartMessage = `Vous ne pouvez pas ajouter cet item car votre sélection dépasserait ${
    estimatedDurations.maxCartDuration / 60
  }h`;

  const canAddToCart = (
    tableType?: 'LCA' | 'DP' | 'QI' | 'DP_QI',
    additionalItems: number = 1,
    toAddDps?: { short: number; long: number }
  ): boolean => {
    if (tableType === 'LCA') {
      // check LCA duration + new item
      const lcaNumber = lcaSelectedItems.length + additionalItems;

      if (
        // calculate duration from LCA side
        lcaNumber * estimatedDurations.lca >
        estimatedDurations.maxCartDuration
      ) {
        toast.error(fullCartMessage);
        return false;
      }
    } else {
      let qiNumber = qiSelectedItems;
      // Take into account the items trying to be selected
      if ((tableType === 'DP' || tableType === 'DP_QI') && toAddDps) {
        dpSelectedItems.long += toAddDps.long;
        dpSelectedItems.short += toAddDps.short;
      }
      if (tableType === 'QI' || tableType === 'DP_QI') {
        qiNumber += additionalItems;
      }

      const totalDuration =
        // calculate duration from DP/QI side
        dpSelectedItems.short * estimatedDurations.dpLessThan13 +
        dpSelectedItems.long * estimatedDurations.dpMoreThan13 +
        qiNumber * estimatedDurations.qi;

      if (totalDuration > estimatedDurations.maxCartDuration) {
        toast.error(fullCartMessage);
        return false;
      }
    }

    return true;
  };

  return canAddToCart;
};
