import { FC, useEffect } from 'react';
import { device } from 'helpers/css/responsive';
import styled, { css } from 'styled-components/macro';

import { Table, Thead, Th, Tbody, Tr } from 'ui/TableElements';

import { TableLoading } from 'pages/selection/components/TableLoading';
import { TableNoResults } from 'pages/selection/components/TableNoResults';
import { FQTableRow } from './FQTableRows/FQTableRow';
import { useGetFQTableValues } from './useGetFQTableValues';
import { TablePagination } from 'pages/selection/components/TablePagination';
import {
  openRow,
  sortBy,
  toggleItemSelection,
  updateItemSelection,
  setPageSize,
  setPage,
} from 'store/slices/fqTableSlice';
import { RootState } from 'store';
import { ReadonlyFQTable } from './ReadonlyFQTable';
import { TableError } from 'pages/selection/components/TableError';
import { CheckboxHeader } from './TableHeaders/CheckboxHeader';
import { SessionHeader } from './TableHeaders/SessionHeader';
import { GenericSortHeader } from './TableHeaders/GenericSortHeader';
import { SpecialitiesHeader } from './TableHeaders/SpecialitiesHeader';
import { RanksHeader } from './TableHeaders/RanksHeader';
import { ItemsHeader } from './TableHeaders/ItemsHeader';
// import { DueDateHeader } from './TableHeaders/DueDateHeader';
import { CategoriesHeader } from './TableHeaders/CategoriesHeader';
import { TypeHeader } from './TableHeaders/TypeHeader';
import { TableConfigContextProvider } from './contexts/tableConfigContext';
import { useShowSelectedItemsContext } from './contexts/showSelectedItemsContext';
import { useTableConfigContext } from './contexts/tableConfigContext';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeck } from 'store/slices/fqCurrentSlice';
import { partition } from 'helpers/application/partition';
import { FQResultsType } from 'http/flash-questions/fqdeckcreation/types';

const COL_NUMBER = 9;

const tableStateSelector = (state: RootState) => state.fqTable;

const FQTable: FC = () => {
  const {
    data,
    formatedData,
    isLoading,
    isError,
    refetch,
    currentPage,
    handlePageClick,
    pageSize,
    handleNewPageSize,
  } = useGetFQTableValues();

  const { updateSelectionCreator } = useTableConfigContext();
  const deck = useSelector(selectDeck);
  const dispatch = useDispatch();

  const alreadySelectedIds = () => {
    if (!deck) {
      return [];
    }
    return deck.cards.map((card) => Number(card.question));
  };

  const { shouldShowSelectedItems } = useShowSelectedItemsContext();

  useEffect(() => {
    if (!data) return;

    const result = partition(
      data.results,
      (row) => alreadySelectedIds().indexOf(row.id) !== -1
    );

    dispatch(updateSelectionCreator(result[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, deck]);

  return (
    <Container>
      <TableContainer>
        {shouldShowSelectedItems ? (
          <ReadonlyFQTable />
        ) : (
          <Table>
            <Thead>
              <Tr>
                <Th>
                  <CheckboxHeader pageItemInfos={data?.results ?? []} />
                </Th>
                <Th>
                  <SessionHeader />
                </Th>
                <Th>
                  <GenericSortHeader columnName="N°" sortColumn="num" />
                </Th>
                <Th>
                  <SpecialitiesHeader />
                </Th>
                <Th>
                  <ItemsHeader />
                </Th>
                <Th>
                  <CategoriesHeader />
                </Th>
                <Th>
                  <RanksHeader />
                </Th>
                <Th>
                  <TypeHeader />
                </Th>
                <Th>
                  {/* <DueDateHeader /> */}
                  <HeaderContainer>Prochaine révision</HeaderContainer>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading && <TableLoading colNumber={COL_NUMBER} />}
              {formatedData &&
                formatedData.length > 0 &&
                formatedData.map((rowData: FQResultsType, index: number) => (
                  <FQTableRow
                    rowData={rowData}
                    index={index}
                    key={rowData.id}
                  />
                ))}
              {formatedData && formatedData.length === 0 && (
                <TableNoResults colNumber={COL_NUMBER} />
              )}
              {isError && <TableError colNumber={COL_NUMBER} retry={refetch} />}
            </Tbody>
          </Table>
        )}
      </TableContainer>

      {!shouldShowSelectedItems && (
        <TablePagination
          totalCount={data?.count ?? 0}
          currentPage={currentPage}
          handlePageClick={handlePageClick}
          pageSize={pageSize}
          handleNewPageSize={handleNewPageSize}
        />
      )}
    </Container>
  );
};

const withContext = (Component: React.FC) => {
  const MemoComponent = React.memo(Component);

  const Wrapper = () => {
    return (
      <TableConfigContextProvider
        value={{
          sortByCreator: sortBy,
          tableStateSelector: tableStateSelector,
          updateSelectionCreator: updateItemSelection,
          toggleSelectionCreator: toggleItemSelection,
          openRowCreator: openRow,
          setPageSizeCreator: setPageSize,
          setPageCreator: setPage,
        }}
      >
        <MemoComponent />
      </TableConfigContextProvider>
    );
  };

  return React.memo(Wrapper);
};

export default withContext(FQTable);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: auto;
`;

const TableContainer = styled.div`
  border-left: ${({ theme }) => theme.colors.primary.main} 2px solid;
  /* select the last tr element and apply a border to his children */
  & tr:last-child td {
    border-bottom: ${({ theme }) => theme.colors.primary.main} 2px solid;
  }

  /* select the last td element of each tr */
  & tr td:last-child {
    border-right: ${({ theme }) => theme.colors.primary.main} 2px solid;
    border-radius: 0;
  }

  & th {
    border-top: ${({ theme }) => theme.colors.primary.main} 2px solid;
  }
  border-right: none;
  border-radius: 12px;
  width: 100%;
`;

// TO REMOVE AFTER UDUEDATE HEADER IS SET
const HeaderContainer = styled.div<{
  isCheckbox?: boolean;
  isSub?: boolean;
  isReadOnly?: boolean;
}>`
  padding: 4px 8px;
  background-color: ${({ theme, isSub }) =>
    isSub ? theme.colors.primary.light : theme.colors.background.paper};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary.dark};
  cursor: ${({ isReadOnly }) => (isReadOnly ? 'default' : 'pointer')};

  ${({ isSub, theme }) =>
    isSub
      ? css`
          ${theme.typography.textBody2}
        `
      : css`
          ${theme.typography.label}
        `}

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  @media ${device.largeTable} {
    padding: 4px 16px;
    gap: 28px;

    ${({ isCheckbox }) =>
      isCheckbox &&
      css`
        padding: 6px 12px;
        gap: 8px;
        width: 60px;
      `}

    ${({ isSub }) =>
      isSub &&
      css`
        gap: 4px;
      `}
  }

  ${({ isCheckbox }) =>
    isCheckbox &&
    css`
      padding: 6px 12px;
      gap: 8px;
      width: 60px;
    `}
`;
