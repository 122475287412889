import { useMemo } from 'react';
import { useGetItemsList } from './useGetItemsList';
import { formatItemsListAsOptions } from '../domain/useCases/formatItemsListAsOptions';

export const useGetItemsListAsOptions = () => {
  const query = useGetItemsList();

  const res = useMemo(() => {
    return {
      ...query,
      data: formatItemsListAsOptions(query),
    };
  }, [query]);

  return res;
};
