import styled from 'styled-components/macro';

export const trainingModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    position: 'fixed' as const,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px 0px',
  },
  content: {},
};

export const SubmsissionButton = styled.button<{ isChecked: boolean }>`
  background: ${({ isChecked }) => (isChecked ? '#ef9198' : '#d3d3d3')};
  color: ${({ isChecked }) => (isChecked ? '#fff' : '#898787')};
  cursor: ${({ isChecked }) => (isChecked ? 'pointer' : 'not-allowed')};
  border: 0;
  padding: 10px 30px;
  font-weight: bold;
  font-family: 'Freesans';
  font-size: 14px;
`;
