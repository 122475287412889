import styled from 'styled-components/macro';

export const Footer = styled.div`
  height: 12px;
  width: 100%;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.colors.primary.main};
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
`;
