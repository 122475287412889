import { PreviousQuestionTraining } from 'modules/progress/detailed/shared/entities/PreviousTraining';
import { GradeData } from 'pages/correction/components/CorrectionGroupHeader/points-formatter';
import { PastCorrectionButton } from 'pages/progress/GeneralProgress/components/PastCorrectionButton';
import { useCorrectionModalContext } from 'pages/progress/GeneralProgress/QIProgress/contexts/correctionModalContext';
import { FormattedDate } from 'pages/selection/components/FormattedDate';
import { formatGradeOutOfTwenty } from 'pages/correction/components/CorrectionGroupHeader/points-formatter';
import { FC } from 'react';
import styled from 'styled-components/macro';

interface Props {
  training: PreviousQuestionTraining;
}

export const HistoryListRow: FC<Props> = ({ training }) => {
  const gradeData: GradeData = {
    grade: training.grade ?? null,
    totalCoeff: training.denom,
  };

  const { openModal } = useCorrectionModalContext();
  const goToCorrection = () => {
    openModal({
      correctionId: training.groupCorrectionId,
      qiId: training.id,
      qiIdInGroupCorrection: training.qiIdInGroupCorrection,
    });
  };

  return (
    <>
      <small>
        <FormattedDate date={training.date} />
      </small>
      <small>{formatGradeOutOfTwenty(gradeData)}</small>
      <small>
        <PastCorrectionButton onClick={goToCorrection} />
      </small>
      <PushCell />
    </>
  );
};

const PushCell = styled.div``;
