import { Loader } from 'components/Loader/Loader';
import { Proposition } from 'pages/correction/active-correction-group/questions-correction-blocks/QCM/Proposition';
import { getCorrectionWithUserReponse } from 'pages/flash-questions/helpers';
import { useSelector } from 'react-redux';
import {
  selectCorrection,
  selectCorrectionStatus,
  selectSelectedAnswers,
} from 'store/slices/fqCurrentSlice';
import { Status } from 'store/types';
import styled from 'styled-components/macro';

// TODO : externalise the correction wrapper between the "correction" page and the "flash question" page
export const FQQCMCorrectionMapper = () => {
  const userResponse = useSelector(selectSelectedAnswers);
  const correction = useSelector(selectCorrection);
  const correctionStatus = useSelector(selectCorrectionStatus);

  if (correctionStatus === Status.PENDING) {
    return (
      <Container>
        <Loader>Patience, ça charge...</Loader>
      </Container>
    );
  }

  if (correctionStatus === Status.REJECTED) {
    return (
      <Container>
        <p>Une erreur est survenue</p>
      </Container>
    );
  }

  if (correctionStatus === Status.RESOLVED && correction) {
    const correctionWithUserResponse = getCorrectionWithUserReponse({
      correctionItems: correction.correction_question.correction_items_qcm,
      userResponse,
    });

    return (
      <Container>
        {correctionWithUserResponse.map((itemCorrection) => (
          <Proposition
            key={itemCorrection.id}
            hasUserAnswered
            itemCorrection={itemCorrection}
          />
        ))}
      </Container>
    );
  }

  return null;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1em;
  width: 100%;

  & > *:not(:first-child) {
    margin-top: 1em;
  }
`;
