import styled from 'styled-components/macro';

export const ModalCheckbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  font-family: 'Freesans';
  font-size: 20px;
  margin: 1rem 0 0.5rem 0;
  color: black;

  .container {
    margin-right: 10px;
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  label {
    height: 100%;
  }
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
    border: 2px #b1b0b2 solid;
    border-radius: 4px;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #183876;
    border-color: #183876;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 0px;
    width: 12px;
    height: 25px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(43deg);
    -ms-transform: rotate(43deg);
    -webkit-transform: rotate(43deg);
    -ms-transform: rotate(43deg);
    transform: rotate(43deg);
  }
`;
