import { SessionRecap } from '../entities/entity';
import { SessionProgressPercentage } from '../entities/SessionProgressPercentage';

export const getSessionProgressPercentage = (
  sessionRecap: SessionRecap
): SessionProgressPercentage => {
  const qiPercentage = (sessionRecap.doneQi / sessionRecap.totalQi) * 100;
  const dpPercentage = (sessionRecap.doneDp / sessionRecap.totalDp) * 100;
  const lcaPercentage = (sessionRecap.doneLca / sessionRecap.totalLca) * 100;

  return {
    qi: qiPercentage,
    dp: dpPercentage,
    lca: lcaPercentage,
  };
};
