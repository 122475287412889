import { css } from 'styled-components/macro';

export const buttonReset = css`
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  padding: 0;
  text-align: inherit;

  &:disabled {
    cursor: default;
  }
`;
