import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useGoToAuthConfirmation } from 'pages/auth-confirmation/hooks/useGoToAuthConfirmation';
import { passwordRegex } from 'pages/sign-up/passwordRegex';
import { requestChangePassword } from 'http/change-password/api';

interface FormInputs {
  password: string;
  passwordConfirmation: string;
}

const formSchema = yup.object({
  password: yup
    .string()
    .required('Veuillez renseigner un mot de passe')
    .min(8, 'Le mot de passe doit faire plus de 8 charactères')
    .matches(
      passwordRegex,
      'Le mot de passe doit contenir au moins un chiffre, une lettre majuscule et une lettre minuscule'
    ),
  passwordConfirmation: yup
    .string()
    .required('Veuillez confirmer votre mot de passe')
    .oneOf(
      [yup.ref('password')],
      'Les deux mots de passe ne sont pas identiques'
    ),
});

export const useChangePasswordForm = () => {
  const rhf = useForm<FormInputs>({
    resolver: yupResolver(formSchema),
  });

  return {
    ...rhf,
  };
};

export const useChangePasswordFormSubmit = ({
  token,
  onFetch,
  afterFetch,
}: {
  token: string;
  onFetch: () => void;
  afterFetch: () => void;
}) => {
  const goToAuthConfirmation = useGoToAuthConfirmation();

  const onChangePasswordFormSubmit: SubmitHandler<FormInputs> = async (
    data
  ) => {
    try {
      onFetch();
      await requestChangePassword(data.password, token);
      goToAuthConfirmation({
        title: 'Votre mot de passe a bien été réinitialisé !',
        subtitle: `Bon retour parmi nous 😉`,
      });
    } finally {
      afterFetch();
    }
  };

  return onChangePasswordFormSubmit;
};
