import { FC } from 'react';
import { MenuIconProps } from './types';

export const MenuBook: FC<MenuIconProps> = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.96273 12.1628V2.95347C4.94983 0.497653 2.89822 0.999979 2.12402 1.55812V11.0465C4.13693 10.6 6.18854 11.6046 6.96273 12.1628Z"
        stroke="#021427"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.96273 12.1628V2.95347C4.94983 0.497653 2.89822 0.999979 2.12402 1.55812V11.0465C4.13693 10.6 6.18854 11.6046 6.96273 12.1628Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.96273 12.1628V2.95347C4.94983 0.497653 2.89822 0.999979 2.12402 1.55812V11.0465C4.13693 10.6 6.18854 11.6046 6.96273 12.1628Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.96305 12.1628V2.87071C8.97595 0.392817 11.0276 0.899658 11.8018 1.46282V11.0365C9.78885 10.586 7.73724 11.5996 6.96305 12.1628Z"
        stroke="#021427"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.96305 12.1628V2.87071C8.97595 0.392817 11.0276 0.899658 11.8018 1.46282V11.0365C9.78885 10.586 7.73724 11.5996 6.96305 12.1628Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.96305 12.1628V2.87071C8.97595 0.392817 11.0276 0.899658 11.8018 1.46282V11.0365C9.78885 10.586 7.73724 11.5996 6.96305 12.1628Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.96289 12.4418C5.86612 11.9767 3.13063 11.4372 0.962891 13V2.11627H1.93063"
        stroke="#021427"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.96289 12.4418C5.86612 11.9767 3.13063 11.4372 0.962891 13V2.11627H1.93063"
        stroke="black"
        strokeOpacity="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.96289 12.4418C5.86612 11.9767 3.13063 11.4372 0.962891 13V2.11627H1.93063"
        stroke="black"
        strokeOpacity="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.96289 12.4419C8.05966 11.9767 10.7951 11.4372 12.9629 13V2.11627H11.9951"
        stroke="#021427"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.96289 12.4419C8.05966 11.9767 10.7951 11.4372 12.9629 13V2.11627H11.9951"
        stroke="black"
        strokeOpacity="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.96289 12.4419C8.05966 11.9767 10.7951 11.4372 12.9629 13V2.11627H11.9951"
        stroke="black"
        strokeOpacity="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
