import { StepType } from '@reactour/tour';

export const homeSteps: StepType[] = [
  {
    selector: '#root',
    content: `Bienvenue sur la page d'accueil d'asclepia.io. Nous allons te montrer comment profiter de manière optimale de la plateforme ! Tu es ici sur la page d'accueil du site où tu pourras suivre en 1 coup d'œil ta progression.`,
  },
  {
    selector: '#menu-item-Astrolabe',
    content: `Découvrons tout d'abord l'astrolabe. Il te permettra d'organiser ton apprentissage avec une flexibilité totale en visualisant les connaissances au programme par livre ou par item.`,
    actionAfter: () => {
      const astrolabe = document.getElementById('menu-item-Astrolabe');
      astrolabe?.click();
    },
  },
];

export const astrolabeSteps: StepType[] = [
  {
    selector: '#astrolabe-switcher',
    content: `Ici, tu peux accéder aux différents objectifs de connaissance par livre ou par item`,
    position: 'right',
    mutationObservables: ['#astrolabe-switcher'],
    highlightedSelectors: ['#astrolabe-switcher'],
  },
  {
    selector: '#astrolabe-book-65',
    content: `Regardons par exemple le référentiel de "cardiologie"`,
    mutationObservables: ['#astrolabe-book-65'],
    highlightedSelectors: ['#astrolabe-book-65'],
  },
  {
    selector: '#book-infos-top',
    content: `On retrouve ici toutes informations sur le livre médecine cardiovasculaire`,
    position: 'left',
    highlightedSelectors: ['#book-infos-top'],
    mutationObservables: ['#book-infos-top'],
  },
  {
    selector: '#book-infos-items',
    content: `On retrouve ici tous les items qui se rapportent au référentiel de médecine cardiovasculaire`,
    position: 'left',
  },
  {
    selector: '#book-infos-item-226',
    content: `Cliquons sur l'item 226`,
    position: 'left',
    highlightedSelectors: ['#book-infos-item-226'],
    mutationObservables: ['#book-infos-items', '#book-infos-item-226'],
    resizeObservables: ['#book-infos-item-226'],
  },
  {
    selector: '#astrolabe-knowledge-N226N01',
    content: `Tu peux voir ici les différents objectifs de connaissance avec leur rang (A ou B), tels que définis dans l'arrêté du 24 juillet 2023.`,
    highlightedSelectors: [
      '#astrolabe-knowledge-N226N01',
      '#astrolabe-knowledge-N226N02',
    ],
    mutationObservables: ['#astrolabe-knowledge-N226N02'],
    position: 'right',
  },
  {
    selector: '#astrolabe-knowledge-N226N02 .subject-block-body-container',
    content: `Tu peux avoir accès aux différents tiroirs de cours en rapport avec l'objectif de connaissance.`,
    resizeObservables: [
      '#astrolabe-knowledge-N226N02 .subject-block-body-container',
    ],
    mutationObservables: [
      '#astrolabe-knowledge-N226N02 .subject-block-content',
      '#astrolabe-knowledge-N226N02 .subject-block-body-container',
    ],
    highlightedSelectors: [
      '#astrolabe-knowledge-N226N02 .subject-block-body-container',
    ],
    position: 'right',
  },
  {
    selector: '.available-questions',
    content: `Tu peux également voir en un coup d'œil toutes les questions d'annales (DP ou QI) qui se rapportent à la connaissance !`,
    mutationObservables: [
      '.available-questions',
      '#knowledge-question-modal',
      '.ReactModalPortal',
    ],
    highlightedSelectors: ['.available-questions', '#knowledge-question-modal'],
    resizeObservables: [
      '.available-questions',
      '#knowledge-question-modal',
      '.ReactModalPortal',
    ],
  },
  {
    selector: '#menu-item-Sélection',
    content: `Accédons maintenant à l'onglet sélection, qui te permet de choisir tes entrainements.`,
    actionAfter: () => {
      const selection = document.getElementById('menu-item-Sélection');
      selection?.click();
    },
  },
];
export const selectionSteps: StepType[] = [
  {
    selector: '#selection-header',
    content: `Ici, tu pourras choisir de trier tes entrainements par sessions ou par spécialité`,
    position: 'right',
  },
  {
    selector: '#selection-subheader',
    content:
      'Tu peux également choisir de trier tes questions par rang A ou B, par item, par catégorie (ECG, imagerie etc...) et par modalité docimologique.',
  },
  {
    selector: '#selection-options',
    content:
      'Enfin, tu peux changer en un clic le type de questions affiché : DP et KFP, QI ou LCA',
  },
  {
    selector: '#menu-item-Avancement',
    content: "Rendez-vous maintenant sur l'onglet avancement.",
    actionAfter: () => {
      const avancement = document.getElementById('menu-item-Avancement');
      avancement?.click();
    },
  },
];

export const avancementSteps: StepType[] = [
  {
    selector: '#past-correction-button-532',
    content:
      "Accède en un coup d'oeil à ta dernière copie corrigée de la question isolée ou du DP/KFP correspondant !",
    stepInteraction: false,
    mutationObservables: ['#loadind-container'],
    highlightedSelectors: ['#past-correction-button-532'],
  },
  {
    selector: '#add-selection-button-532',
    content:
      'Tu peux ajouter un ou plusieurs DP à ta sélection en cochant la case correspondante.',
  },
  {
    selector: '#add-local-selection-to-cart',
    content: 'Puis en appuyant sur ce bouton',
    stepInteraction: false,
  },
  {
    selector: '#progress-title-container',
    content:
      "Tu peux choisir d'afficher ton avancement par session ou par spécialité",
    mutationObservables: ['#progress-selector-menu'],
    highlightedSelectors: ['#progress-selector-menu > div'],
  },
  {
    selector: '#menu-item-Démarrer',
    content: "Pour démarrer ton entrainement, rends-toi sur l'écran démarrer",
    actionAfter: () => {
      const start = document.getElementById('menu-item-Démarrer');
      start?.click();
    },
  },
];

export const startSteps: StepType[] = [
  {
    selector: '#start-page',
    content:
      "Ici, tu as accès aux différentes informations relatives à la sélection que tu as choisie : nombre de questions, spécialités qui s'y rapportent, items, modalités docimologiques, tag et rang (A ou B).",
    position: 'top',
  },
  {
    selector: '#menu-item-Moncompte',
    content: `Tu peux choisir de masquer les différentes informations si tu ne veux pas te spoiler avant l'épreuve. Rends-toi dans le menu "Mon Compte" pour modifier ce paramètre.`,
    stepInteraction: false,
  },
  {
    selector: '.start-button',
    content: `Tu n'as plus qu'a cliquer sur ce bouton pour démarrer ton entrainement. L'interface de composition est en tout point similaire avec celle du CNG, sur laquelle tu composeras le jour J.`,
    stepInteraction: false,
  },
  {
    selector: '#menu-item-Interface',
    content: `Accédons maintenant à la correction de l'épreuve, via l'onglet interface`,
    actionAfter: () => {
      const interfaceMenu = document.getElementById('menu-item-Interface');
      interfaceMenu?.click();
    },
  },
];

export const interfaceSteps: StepType[] = [
  {
    selector: '#correction-global-grade',
    content: `Tu retrouves ici ta note globale pour tout le dossier ainsi que la médiane au niveau national, quand cette information est disponible.`,
    mutationObservables: ['#correction-card-group'],
    highlightedSelectors: ['#correction-global-grade'],
  },
  {
    selector: '#correction-rank-grade',
    content:
      'Tu as ici accès à ta note correspondant aux questions de rang A validantes.',
  },
  {
    selector: '#question-row-22035289',
    content: `Ici, tu as accès au rang codé pour chaque question ainsi que l'item du programme auquel elle est rattachée. En passant ta souris au dessus, tu verras l'intitulé de la connaissance évaluée et de l'item en question.`,
    position: 'bottom',
  },
  {
    selector: '#correction-infos-1-1736448',
    content: `Tu trouveras ici une courte vignette qui synthétise le déroulé du dossier, puis des commentaires par le correcteur. Tu as ensuite accès aux différentes ressources en rapport avec le dossier.`,
    position: 'bottom',
    stepInteraction: false,
  },
  {
    selector: '#corrections-questions-2',
    content: `La correction de chaque proposition est détaillée pour te donner toutes les connaissances et clés de compréhension nécessaires à ta progression !`,
    position: 'bottom',
    stepInteraction: false,
  },
  {
    selector: '#related-lesson-souffle_benin',
    content: `Tu trouveras sous chaque question les tiroirs de cours qui s'y rapportent et les questions similaires déjà tombées par le passé.`,
    position: 'top',
    mutationObservables: ['.lesson-body'],
  },
  {
    selector: '#menu-item-Questionsflash',
    content: `Découvrons maintenant les Questions Flashs. C'est une méthode de révision basée sur sur notre variante de l’algorithme SuperMemo (popularisée par le logiciel Anki) ; elles intègrent le rappel actif et la répétition espacée.`,
    actionAfter: () => {
      const QuestionsflashMenu = document.getElementById(
        'menu-item-Questionsflash'
      );
      QuestionsflashMenu?.click();
    },
  },
];

export const questionsflashSteps: StepType[] = [
  {
    selector: '#fq-question-button',
    content: `Il te suffit de créer un paquet (attention à bien lui donner un nom), puis laisse toi guider pour apprendre et revoir les différentes notions qui tombent aux EDN.`,
    position: 'right',
  },
  {
    selector: '#menu-item-Easy-Dispo',
    content: `Découvrons maintenant la fonctionnalité easy dispo, qui te permettra d'envisager quelles spécialités te seront probablement accessibles dans quelles villes avec un classement donné.`,
    stepInteraction: false,
  },
  {
    selector: '#menu-item-Tiroirdecours',
    content: `Poursuivons notre découverte des différentes fonctionnalités : dans cet onglet, tu auras accès aux différents tiroirs de cours que tu pourras trier comme tu veux (par matière, par item, par mot clé, par rang, etc.)`,
    stepInteraction: false,
  },
  {
    selector: '#menu-item-Ressources',
    content: `Enfin, dans cet onglet tu trouveras toutes les ressources qui peuvent t'être nécessaires : textes de lois, liens vers nos conférences, sites web d'intérêt, etc.`,
    stepInteraction: false,
  },
  {
    selector: '#root',
    content: `C'est la fin de notre visite guidée. Si malgré nos explications, tu as encore des questions, n'hésite pas à jeter un oeil à la FAQ ou à nous contacter sur contact@asclepia.io`,
    stepInteraction: false,
  },
];
