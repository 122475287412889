import styled from 'styled-components/macro';
import { useEasyDispoContext } from '../../context/easydispoContext';
import { Plus } from '../icons/Plus';
import { SingleFilterLine } from './SingleFilterLine';

export const SingleFiltersContainer = () => {
  const { affectationFilters, setAffectationFilters } = useEasyDispoContext();

  const removeLineAtIndex = (indexToRemove: number) => {
    const newAffectation = [...affectationFilters].filter(
      (_, index) => index !== indexToRemove
    );
    setAffectationFilters(newAffectation);
  };

  return (
    <>
      {affectationFilters.map((filter, index) => (
        <SingleFilterLine
          key={`${filter.chuCondensedLabel}-${filter.speCondensedLabel} -${index}`}
          removeLineAtIndex={removeLineAtIndex}
          index={index}
          controlledValue={filter}
        />
      ))}
      <AddOptionLine
        onClick={() =>
          setAffectationFilters((prev) => [
            ...prev,
            {
              speLabel: '',
              chuLabel: '',
              speCondensedLabel: '',
              chuCondensedLabel: '',
            },
          ])
        }
      >
        <Plus />
        Ajouter une combinaison DES / Subdivision
      </AddOptionLine>
    </>
  );
};

const AddOptionLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.inactive};
  margin-bottom: 8px;
`;
