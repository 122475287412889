import { DetailQIResponse } from 'http/progress/detail/qi/types';
import { SelectionQIResult } from 'http/selection-qi/types';
import { AddToCartSuccessToast } from 'pages/progress/GeneralProgress/components/AddToCartSuccessToast';
import { getItemsNotAlreadyInOtherItems } from 'pages/selection/TableHeaders/utils';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { updateItemSelection } from 'store/slices/qiTableSlice';
import { useAddToLocalCartContext } from '../../contexts/addToLocalCartContext';
import { adaptDetailQIToQIQuestion } from '../../hooks/useToggleQIInCart';

export const useAddLocalCartToGlobalCart = () => {
  const dispatch = useDispatch();
  const selectedItemsInfos = useSelector(
    (state: RootState) => state.qiTable.selectedItemsInfos
  );
  const { flushLocalCart } = useAddToLocalCartContext();

  return (results: DetailQIResponse[]) => {
    const localCart = results.map((qiDetail) =>
      adaptDetailQIToQIQuestion(qiDetail)
    );

    const pageItemsWithoutAlreadySelected = getItemsNotAlreadyInOtherItems(
      localCart,
      selectedItemsInfos
    );

    const newItemsWithAllCurrent = [
      ...selectedItemsInfos,
      ...pageItemsWithoutAlreadySelected,
    ] as SelectionQIResult[];

    dispatch(updateItemSelection(newItemsWithAllCurrent));

    if (localCart.length > 0) {
      flushLocalCart();
      toast.success(<AddToCartSuccessToast name="QIs" cartType="DPQI" />);
    } else {
      toast.info("Vous n'avez sélectionné aucune question");
    }
  };
};
