import { FC } from 'react';
import styled from 'styled-components/macro';

interface Props {
  children: React.ReactNode;
  big?: boolean;
}

export const IconContainer: FC<Props> = ({ children, big }) => {
  return <Container big={big}>{children}</Container>;
};

const Container = styled.div<{ big?: boolean }>`
  height: ${({ big }) => (big ? '24px' : '20px')};
  width: ${({ big }) => (big ? '24px' : '20px')};
  flex-shrink: 0;
`;
