import styled from 'styled-components';
import { device } from 'helpers/css/responsive';
import { MyGrades } from '../components/MyGrades';
import FadeIn from 'ui/Animations/FadeIn';
import { PromotionFilters } from '../components/PromotionFilters';
import { StatCardsContainer, StatContainer } from '../Ranking/CCBRanking';
import { MyStatsPeriod } from '../components/MyStatsPeriod';
import { MyStatsGlobal } from '../components/MyStatsGlobal';
import { UnlockCard } from 'ui/Cards/UnlockCard';
import { RepartitionLca } from './RepartitionLca/RepartitionLca';
import { RepartitionSpe } from './RepartitionSpe/RepartitionSpe';
import { GlobalGrades } from './GlobalGrades/GlobalGrades';
import { LCAGrades } from './RepartitionLca/LCAGrades';
import { UCGrades } from './RepartitionUC/UCGrades';

export const CCBStatsAndGrades = () => {
  return (
    <>
      <FadeIn>
        <StatContainer>
          <Section vertical>
            <Section>
              <h3>Mes statistiques</h3>
            </Section>
            <StatCardsContainer>
              <LeftCardsContainer>
                <LeftCardsTopContainer>
                  <MyGrades link={false} />
                </LeftCardsTopContainer>
                <RepartitionLca />
              </LeftCardsContainer>
              <RepartitionSpe />
            </StatCardsContainer>
          </Section>
          <Section>
            <UnlockCard />
          </Section>
          <Headers>
            <h3>Les statistiques globales des participants</h3>
            <PromotionFilters isShown={true} />
          </Headers>
          <Section gap="3rem">
            <MyStatsPeriod />
            <MyStatsGlobal />
          </Section>
          <Section>
            <GlobalGrades />
          </Section>
          <Section gap="2rem">
            <LCAGrades />
            <UCGrades />
          </Section>
        </StatContainer>
      </FadeIn>
    </>
  );
};

export const Section = styled.div<{ vertical?: boolean; gap?: string }>`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  gap: ${({ gap }) => gap};
  gap: 1rem;
  justify-content: start;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const LeftCardsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const LeftCardsTopContainer = styled.div`
  display: flex;
  gap: 1rem;
  width: 100%;
`;

export const SubTitle = styled.h4`
  font-weight: 900;
  display: inline;
`;

const Headers = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 2rem;
  align-items: center;
  width: 100%;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;
