import { InputHTMLAttributes, forwardRef } from 'react';
import styled from 'styled-components/macro';
import { NumberInput } from '../NumberInput';

type NativeInputProps = InputHTMLAttributes<HTMLInputElement>;

interface Props extends NativeInputProps {
  tableName: string;
}

export const TableSelectionInput = forwardRef<HTMLInputElement, Props>(
  ({ tableName, ...inputProps }, ref) => {
    return (
      <Container>
        <h5>{tableName}</h5>
        <NumberInput ref={ref} {...inputProps} />
      </Container>
    );
  }
);

export const Container = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;
