import { MenuSubscribe } from 'ui/icons/MenuIcons/MenuSubscribe';
import { MenuAccount } from 'ui/icons/MenuIcons/MenuAccount';
import { MenuHistory } from 'ui/icons/MenuIcons/MenuHistory';
import { MenuParameter } from 'ui/icons/MenuIcons/MenuParameter';
import { MyInformationsOptions } from './MyInformationsOptions/MyInformationsOptions';
import { GroupHistory } from './GroupHistory/GroupHistory';
import { GeneralOptions } from './GeneralOptions/GeneralOptions';
import { SubscriptionInfo } from './SubscriptionInfo/SubscriptionInfo';

export const MENUCONTENT: Array<{
  icon: any;
  title: string;
  infoToDisplay: any;
}> = [
  {
    icon: MenuAccount,
    title: 'Mes informations',
    infoToDisplay: MyInformationsOptions,
  },
  {
    icon: MenuParameter,
    title: 'Paramètres du compte',
    infoToDisplay: GeneralOptions,
  },
  {
    icon: MenuHistory,
    title: 'Historique',
    infoToDisplay: GroupHistory,
  },
  {
    icon: MenuSubscribe,
    title: 'Mon abonnement',
    infoToDisplay: SubscriptionInfo,
  },
];
