import { NewRecapSpecialty } from 'http/new-home/types';
import { useAccount } from 'pages/account/useAccount';
import { StatCard } from 'pages/newHome/ui/StatCard';
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'helpers/dates/utils';

interface Props {
  specialty: NewRecapSpecialty;
}

export const SpecialtyCard: React.FC<Props> = ({ specialty }) => {
  const { userOptions } = useAccount();

  const navigate = useNavigate();

  const DoneRepartitionDP = [
    {
      id: 'done',
      name: 'correctes',
      value: specialty.group_success,
      color: 'green',
    },
    {
      id: 'incorrect',
      name: 'erronées',
      value: specialty.group_fail,
      color: '#A22F2B',
    },
    {
      id: 'not_done',
      name: 'non réalisées',
      value: specialty.group_not_done,
      color: '#A5C7EF',
    },
  ];

  const DoneRepartitionQI = [
    {
      id: 'done',
      name: 'correctes',
      value: specialty.qi_success,
      color: 'green',
    },
    {
      id: 'incorrect',
      name: 'erronées',
      value: specialty.qi_fail,
      color: '#A22F2B',
    },
    {
      id: 'not_done',
      name: 'non réalisées',
      value: specialty.qi_not_done,
      color: '#A5C7EF',
    },
  ];

  const DoneRepartitionQF = [
    {
      id: 'done',
      name: 'Done',
      value: specialty.flash_done,
      color: 'black',
    },
    {
      id: 'not_done',
      name: 'Not done',
      value: specialty.flash_not_done,
      color: '#A5C7EF',
    },
  ];

  return (
    <StatCard
      onCardClick={() => {
        navigate(
          `/?selected=specialties&activeSpeciality=${specialty.specialty.trigram}`
        );
      }}
      cardType="specialty"
      id={specialty.specialty.trigram}
      title={`${specialty.specialty.name}`}
      seeGrade={userOptions?.home_see_grades ?? true}
      grade={specialty.grade}
      DoneRepartitionDP={DoneRepartitionDP}
      DoneRepartitionQI={DoneRepartitionQI}
      DoneRepartitionQF={DoneRepartitionQF}
      numItems={specialty.num_items}
      numLessons={specialty.num_groups}
      numDP={specialty.num_groups}
      numQI={specialty.num_qis}
      numQF={specialty.num_flash}
      lastRevisionDate={
        specialty.last_try ? formatDate(specialty.last_try) : ''
      }
      isFav={specialty.favorite}
      studyTour={specialty.round}
    />
  );
};
