import { useEffect, useState } from 'react';
import { ActionMeta } from 'react-select';

import { Option } from 'ui/UnderlinedDropdown';

import { useGetPromotions } from './useGetPromotions';
import { useGetUniversities } from 'pages/sign-up/hooks/useGetUniversities';
import { useAccount } from '../useAccount';

export const useGetParams = () => {
  const [readOnly, setReadOnly] = useState<boolean>(true);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [promotion, setPromotion] = useState<string>('');
  const [university, setUniversity] = useState<string>('');
  const [username, setUsername] = useState<string>('');

  const promotions = useGetPromotions();
  const universities = useGetUniversities();
  const { updateUserOptions, userOptions } = useAccount();

  useEffect(() => {
    setFirstName(userOptions?.firstName || '');
    setLastName(userOptions?.lastName || '');
    setPromotion(userOptions?.promotion || '');
    setUniversity(userOptions?.university || '');
  }, [userOptions, readOnly]);

  const handleChangeFirstName = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFirstName(event.target.value);
  };

  const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastName(event.target.value);
  };

  const handleChangePromotion = (
    option: Option | null,
    actionMeta: ActionMeta<Option>
  ) => {
    if (actionMeta.action === 'select-option') {
      setPromotion(option?.value || '');
    }
  };

  const handleChangeUniversity = (
    option: Option | null,
    actionMeta: ActionMeta<Option>
  ) => {
    if (actionMeta.action === 'select-option') {
      // Check if the action is a selection of an option in react-select
      setUniversity(option?.value || '');
    }
  };

  const handleChangeUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  };

  const submit = async () => {
    if (!userOptions) {
      return;
    }
    if (!(firstName && lastName && promotion && university)) {
      return;
    }

    await updateUserOptions({
      firstName,
      lastName,
      promotion,
      university,
    });
    setReadOnly(true);
  };

  return {
    readOnly,
    setReadOnly,
    firstName,
    lastName,
    promotion,
    university,
    username,
    universities,
    promotions,
    preferences: userOptions,
    handleChangeFirstName,
    handleChangeLastName,
    handleChangePromotion,
    handleChangeUniversity,
    handleChangeUsername,
    submit,
  };
};
