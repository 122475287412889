import { getQZPColors } from 'pages/new_training/components/QZP/qzpColors';
import { formatQZPImageUrl } from 'pages/new_training/components/QZP/SingleAnswer';
import { PropositionCorrection, QZPCorrectionData } from './types';
import { CorrectionQuestion } from 'types/correction.type';

export const adaptQZPCorrectionData = (
  questionData: CorrectionQuestion
): QZPCorrectionData => {
  const answers = questionData.correction_item_qzp?.answer ?? {};
  const polygons = questionData.correction_item_qzp?.question_item.polygons;

  const labels = polygons ? Object.keys(polygons) : [];

  const colors = getQZPColors(labels.length);

  return {
    imageUrl: formatQZPImageUrl(
      questionData.correction_item_qzp?.question_item.image ?? ''
    ),
    propositionsCorrections: labels.map((label, index) => {
      const color = colors[index % colors.length];

      const proposition: PropositionCorrection = {
        text: label,
        correctionShapes:
          polygons?.[label].map((vertices) => ({
            vertices,
            color,
          })) ?? [],
        id: label,
        isUserRight: answers[label]?.correct ?? false,
        userInput: {
          x: answers[label]?.x ?? index * 0.1 + 0.05,
          y: answers[label]?.y ?? 1.08,
          text: label,
          color,
        },
        commentary: 'Commentaire pas encore implémenté',
      };

      return proposition;
    }),
  };
};
