import { forwardRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

import { SingleAnswer } from './SingleAnswer';
import { ValidateButton } from '../ui/ValidateButton';
import { ValidationModal } from '../ValidationModal/ValidationModal';
import { useDidUpdateEffect } from 'hooks/useDidUpdate';
import { StyledMathJax } from 'ui/MathJax';
import { NoAnswerModal } from '../NoAnswerModal/NoAnswerModal';
import { toast } from 'react-toastify';
import { useSaveQuestionQCM } from 'pages/new_training/hooks/saveHooks/useSaveQCM';

interface Props {
  question: string;
  questionId: number;
  images?: Array<string>;
  saved: boolean;
  answers: Array<{
    answer: boolean;
    id: number;
    question_item: {
      num: number;
      statement: string;
    };
  }>;
  questionNumber: number;
  show: boolean;
  isQI: boolean;
  onSave(): void;
  onModification?(): void;
  anonymous: boolean;
  collection: string | undefined;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

const alphabet = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
]; // Yeah that's bruteforce

export const MultipleChoicesQuestion = forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const [answers, setAnswers] = useState<Props['answers']>(props.answers);
    const answersRef = useRef<Props['answers']>(props.answers);
    const titleRef = useRef<HTMLDivElement>(null);
    const [validationModal, setValidationModal] = useState<boolean>(false);
    const [noAnswerModal, setNoAnswerModal] = useState<boolean>(false);
    const saveQuestion = useSaveQuestionQCM(props.onSave);

    const handleChange = (answerIndex: number, checked: boolean) => {
      const newAnswers = [...answers];
      newAnswers[answerIndex].answer = checked;
      setAnswers(newAnswers);
    };

    const handleSaveQuestion = async () => {
      props.setIsLoading(true);

      const params = {
        id: props.questionId,
        answers: answers.map((el) => ({ id: el.id, answer: el.answer })),
      };
      try {
        await saveQuestion(params);
        answersRef.current = answers;
      } catch (err) {
        toast.error(
          'Erreur lors de la sauvegarde de la question, veuillez réessayer'
        );
      }

      props.setIsLoading(false);
    };

    const handleValidationClick = () => {
      if (props.isQI) {
        handleSaveQuestion();
        return;
      }

      if (answers.every((el) => el.answer === false)) {
        setNoAnswerModal(true);
      } else {
        setValidationModal(true);
      }
    };

    // Reset the state of answers when the props change

    useDidUpdateEffect(() => {
      if (props.answers !== answers) {
        setAnswers(props.answers);
      }
      // eslint-disable-next-line
    }, [props.answers]);

    // Scroll when the new question is displayed

    useEffect(() => {
      if (props.show && !props.isQI) {
        titleRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
      // eslint-disable-next-line
    }, [props.show]);

    useEffect(() => {
      if (answersRef.current !== answers) {
        props.onModification && props.onModification();
      }
      // eslint-disable-next-line
    }, [answers]);

    const getTitle = () => {
      // if (props.anonymous) {
      //     return '';
      // }
      // if (props.isQI) {
      //     return props.session + ' ' + props.year + ' ';
      // }
      // return '';

      return (
        'Question ' + props.questionNumber + ' - Question à choix multiple'
      );
    };
    return (
      <Container displayComponent={props.show} ref={ref}>
        <Title ref={titleRef}>
          <TitleLeftSide>{getTitle()}</TitleLeftSide>
          <TitleRightSide>
            {answers.length} propositions de réponses
          </TitleRightSide>
        </Title>

        <Statement>
          <StyledMathJax inline>{parse(props.question)}</StyledMathJax>
        </Statement>
        {props.images &&
          props.images.map((el, index) => <Image key={index} src={el} />)}
        {answers.map((el, index) => (
          <StyledSingleAnswer
            title={'Réponse ' + alphabet[index]}
            text={el.question_item.statement}
            key={index}
            onChange={(checked) => handleChange(index, checked)}
            checked={el.answer}
            disabled={!props.isQI && props.saved}
          />
        ))}
        <ValidateButton
          show={props.isQI || !props.saved}
          onClick={handleValidationClick}
          isLoading={props.isLoading}
        />
        <NoAnswerModal
          isOpen={noAnswerModal}
          onCancel={() => setNoAnswerModal(false)}
          onAccept={() => {
            setNoAnswerModal(false);
            handleSaveQuestion();
          }}
          title="Réponse définitive"
          text={
            `<b style="color:#F3002C; font-family:freesans;">Attention, vous n'avez pas répondu à la question</b>` +
            `<br /> En cliquant sur le bouton OK vous confirmez votre choix.` +
            `<br /><b style="color:#F3002C; font-family:freesans;">Votre réponse ne sera plus modifiable</b>`
          }
          confirmationButtonColor="#192942"
          confirmationButtonHoverColor="#56595e"
        />
        <ValidationModal
          isOpen={validationModal}
          onCancel={() => setValidationModal(false)}
          onAccept={() => {
            setValidationModal(false);
            handleSaveQuestion();
          }}
          title="Réponse définitive"
          text={
            `En cliquant sur le bouton OK vous confirmez vos réponses.` +
            `<br /><b style="color:#F3002C; font-family:freesans;">Elles ne seront plus modifiables.</b>`
          }
        />
      </Container>
    );
  }
);

const Container = styled.div<{ displayComponent?: boolean }>`
  margin-bottom: 50px;
  display: ${({ displayComponent }) => (!displayComponent ? 'none' : 'block')};
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: bold;
`;

const TitleLeftSide = styled.span`
  font-family: 'FreeSans';
  font-size: 16px;
  color: #222529;
`;
const TitleRightSide = styled.span`
  font-family: 'FreeSans';
  font-weight: 100;
  font-size: 14px;
  color: #383b3f;
`;

// TODO: common
const Statement = styled.label`
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  font-family: 'FreeSans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  sup {
    font-size: 0.83em;
  }

  p,
  span {
    font-family: 'FreeSans' !important;
  }

  img {
    max-width: 60%;
    height: auto;
  }
`;

const Image = styled.img`
  width: 70%;
  height: auto;
  margin-top: 10px;
`;

const StyledSingleAnswer = styled(SingleAnswer)`
  margin-top: 4px;
`;
