import { getAPI } from 'auth/authentifiedHttpCalls';
import { Item } from 'types/item.type';

export const getItemsListHttp = async () => {
  const response = await getAPI<Item[]>('/common/items/');
  return response.data;
};

export const getItemHttp = async (itemIds: string[]) => {
  const response = await getAPI<Item[]>(`/common/items/`, {
    params: {
      item: itemIds,
    },
  });
  return response.data;
};
