export const shouldShowBanner = ({
  answers,
  limit,
}: {
  answers: boolean[];
  limit: number;
}) => {
  const answersLength = answers.filter((item) => item === true).length;

  if (answersLength >= limit) {
    return true;
  }

  return false;
};
