import { ShouldShowSpecialty } from '../domain/entities/UserOptions';
import { useGetUserOptions } from './useGetUserOptions';

export const useShouldShowSpecialties = (hasItemBeenDone: boolean) => {
  const { data: userOptions } = useGetUserOptions();
  const shouldShowSpecialtyPreference: ShouldShowSpecialty =
    userOptions?.shouldShowSpecialty ?? 'never';

  const shouldShowSpecialty =
    shouldShowSpecialtyPreference === 'always' ||
    (shouldShowSpecialtyPreference === 'whenDone' && hasItemBeenDone);

  return shouldShowSpecialty;
};
