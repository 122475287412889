import { FC, useMemo } from 'react';

import { LCABasicInformations } from 'modules/progress/general/lca/domain/entities/LCABasicInformations';
import { useIsLCAAlreadySelected } from './useIsLCAAlreadySelected';
import { useAddToLocalCartContext } from '../../contexts/addToLocalCartContext';
import { GenericCartCheckbox } from 'pages/progress/GeneralProgress/components/GenericCartCheckbox';

interface Props {
  group: LCABasicInformations;
}

export const CartCheckbox: FC<Props> = ({ group }) => {
  const isSelected = useIsLCAAlreadySelected({
    id: group.id,
    type: 'LCA',
  });

  const {
    isQuestionInLocalSelection: isQuestionInSelection,
    toggleInLocalCart: toggleToCart,
  } = useAddToLocalCartContext();

  const isInLocalSelection = useMemo(
    () => isQuestionInSelection(group.id),
    [isQuestionInSelection, group.id]
  );

  return (
    <GenericCartCheckbox
      isFree={group.isFree}
      checked={isSelected || isInLocalSelection}
      disabled={isSelected}
      onChange={() => toggleToCart(group.id)}
    />
  );
};
