import React, { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';
import { buildURL } from 'auth/authentifiedHttpCalls';
import { getQZPColors } from './qzpColors';
import { OutlinedButton } from 'ui/Buttons/OutlinedButton';
import useMeasure from 'react-use-measure';

export const HALF_DOT_WIDTH = 12;

interface Props {
  className?: string;
  image: string;
  items: Array<string>;
  answer: {
    [key: string]: {
      x: number;
      y: number;
    };
  };
  disabled: boolean;
  shouldUpdateArrowsPositions?: boolean;
  onDragStop?(index: number, relativeDistance: { x: number; y: number }): void;
  onClose: () => void;
}

export const formatQZPImageUrl = (relativePath: string) => {
  return buildURL('/media/' + relativePath);
};

export const SingleAnswer: FC<Props> = (props) => {
  const bottomBlock = useRef<HTMLDivElement>(null);
  // Ref array of ref, to allow draggable to follow its child in strict mode.
  const draggableRef = useRef<
    Array<React.MutableRefObject<HTMLDivElement | null>>
  >(props.items.map((_) => React.createRef()));

  const containerRef = useRef<HTMLDivElement | null>(null);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  //Provides the size of image marked by the ref
  const [imageRef, { height: imageHeight, width: imageWidth }] = useMeasure();

  const [selected, setSelected] = useState<number | null>(null);

  //State bound to limit the draggable surface
  const [bounds, setBounds] = useState({
    left: 50,
    right: 50,
    top: 50,
    bottom: 50,
  });

  const [positions, setPositions] = useState<{ x: number; y: number }[]>(
    Array(props.items.length).fill({ x: 0, y: 0 })
  );

  useEffect(() => {
    draggableRef.current = props.items.map((_) => React.createRef());
    setPositions(
      props.answer && Object.keys(props.answer).length === props.items.length
        ? Object.keys(props.answer).map((key) => ({
            x: props.answer[key].x,
            y: props.answer[key].y,
          }))
        : Array(props.items.length).fill({ x: 0, y: 0 })
    );
    // eslint-disable-next-line
  }, [props.items]);

  useEffect(() => {
    if (props.shouldUpdateArrowsPositions) {
      positionArrows();
    }
    // eslint-disable-next-line
  }, [props.shouldUpdateArrowsPositions, draggableRef.current, imageHeight]);

  const handleStop = (
    index: number,
    e: DraggableEvent,
    data: DraggableData
  ) => {
    const newPositions = [...positions];
    newPositions[index] = {
      x: data.x,
      y: data.y,
    };
    setPositions(newPositions);
    // const image = imageRef.current?.getBoundingClientRect();
    const draggable =
      draggableRef.current[index].current?.getBoundingClientRect();
    const container = containerRef.current?.getBoundingClientRect();

    if (draggable && container) {
      const relativeDistances = {
        x: (draggable.x - container.x + HALF_DOT_WIDTH) / imageWidth,
        y: (draggable.y - container.y + HALF_DOT_WIDTH) / imageHeight,
      };
      props.onDragStop && props.onDragStop(index, relativeDistances);
    }
  };

  const handleMouseDown = (index: number, e: MouseEvent) => {
    if (props.disabled) {
      return;
    }
    const container = containerRef.current?.getBoundingClientRect();
    const draggable =
      draggableRef.current[index].current?.getBoundingClientRect();

    if (container && draggable) {
      setBounds({
        left: -(-positions[index].x + draggable.x - container.x),
        right:
          positions[index].x -
          draggable.x +
          container.x +
          container.width -
          HALF_DOT_WIDTH * 2,
        top: -(-positions[index].y + draggable.y - container.y),
        bottom:
          positions[index].y -
          draggable.y +
          container.y +
          container.height -
          HALF_DOT_WIDTH * 2,
      });
      // for some reason the right and bottom bounds are defined by last pixel of the dot in contact with it.
    }
  };

  const positionArrows = () => {
    const newPositions = [...positions];
    const container = containerRef.current?.getBoundingClientRect();
    if (!container) return;
    Object.keys(props.answer).forEach((label) => {
      const position = props.answer[label];
      if (position.x > -1 && position.y > -1) {
        const index = props.items.findIndex((el) => el.trim() === label.trim());
        if (index > -1) {
          const draggable =
            draggableRef.current[index].current?.getBoundingClientRect();

          if (imageHeight !== 0 && draggable) {
            newPositions[index] = {
              x:
                position.x * imageWidth +
                container.x -
                draggable.x -
                HALF_DOT_WIDTH,
              y:
                position.y * (imageHeight || 0) +
                container.y -
                draggable.y -
                HALF_DOT_WIDTH,
            };

            setPositions(newPositions);
          }
        }
      }
    });
  };

  const colors = getQZPColors(props.items.length);

  const handleSelect = (
    e: React.MouseEvent<HTMLDivElement>,
    index: number,
    item: string
  ) => {
    if (index === selected) {
      setSelected(null);
      return;
    }
    if (hasMovedDot(item)) return;

    setSelected(index);
  };

  const calculateRelativePositionOnClick = (
    e: React.MouseEvent<HTMLImageElement>
  ) => {
    // const image = imageRef.current?.getBoundingClientRect();
    if (!imageRef) return;

    const container = containerRef.current?.getBoundingClientRect();
    if (!container) return;

    const x = e.clientX - container.x;
    const y = e.clientY - container.y;

    return {
      x: x / imageWidth,
      y: y / imageHeight,
    };
  };

  const handleClick = (e: React.MouseEvent<HTMLImageElement>) => {
    setSelected(null);

    const relativePosition = calculateRelativePositionOnClick(e);
    if (!relativePosition || selected === null) return;

    const newPositions = [...positions];

    const draggable =
      draggableRef.current[selected].current?.getBoundingClientRect();
    const container = containerRef.current?.getBoundingClientRect();

    if (draggable && container) {
      newPositions[selected] = {
        x:
          relativePosition.x * imageWidth +
          container.x -
          draggable.x -
          HALF_DOT_WIDTH,
        y:
          relativePosition.y * imageHeight +
          container.y -
          draggable.y -
          HALF_DOT_WIDTH,
      };

      setPositions(newPositions);
      props.onDragStop && props.onDragStop(selected, relativePosition);
    }
  };

  const hasMovedDot = (item: string) => {
    return (
      props.answer &&
      props.answer[item] &&
      props.answer[item].x > -1 &&
      props.answer[item].y > -1
    );
  };

  return (
    <Container ref={scrollContainerRef}>
      <ImageContainer ref={containerRef} id="arrowBoundaries">
        <Image
          ref={imageRef}
          src={formatQZPImageUrl(props.image)}
          onClick={handleClick}
          alt="qzp-ref"
          style={{
            maxHeight: '100%',
            width: '100%',
          }}
        />
      </ImageContainer>
      <BottomRow ref={bottomBlock}>
        <ArrowsContainer>
          <ArrowInstructions>Indiquez où se trouvent :</ArrowInstructions>
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <Flex>
                {props.items.map((item, index) => (
                  <Line
                    onClick={(e) => handleSelect(e, index, item)}
                    selected={selected === index}
                    key={index}
                  >
                    <Index selected={selected === index}>{index + 1}</Index>
                    <Draggable
                      bounds={bounds}
                      nodeRef={draggableRef.current[index]}
                      onMouseDown={(e) => handleMouseDown(index, e)}
                      onStop={(e, data) => handleStop(index, e, data)}
                      position={positions[index]}
                      scale={1}
                      handle=".handle"
                      disabled={props.disabled || !hasMovedDot(item)}
                    >
                      <div
                        ref={(ref) =>
                          (draggableRef.current[index].current = ref)
                        }
                        className="handle"
                        id="handle"
                        style={{ width: '0px' }}
                      >
                        <StyledArrow fillColor={colors[index % 5]}>
                          {hasMovedDot(item) && (
                            <DotIndex>{index + 1}</DotIndex>
                          )}
                        </StyledArrow>
                      </div>
                    </Draggable>
                    <StyledArrow fillColor={colors[index % 5]} />
                    <div>
                      <ArrowWithText>
                        <Text
                          selected={index === selected}
                          color={colors[index % 5]}
                        >
                          {item}
                        </Text>
                      </ArrowWithText>
                    </div>
                  </Line>
                ))}
              </Flex>
            </div>
            <Buttons>
              <OutlinedButton
                onClick={() => {
                  setSelected(null);
                  setPositions(Array(props.items.length).fill({ x: 0, y: 0 }));
                  props.items.forEach(
                    (_, index) =>
                      props.onDragStop &&
                      props.onDragStop(index, { x: -1, y: -1 })
                  );
                }}
                disabled={props.disabled}
              >
                Réinitialiser
              </OutlinedButton>
              <OKButton onClick={props.onClose}>OK</OKButton>
            </Buttons>
          </div>
        </ArrowsContainer>
      </BottomRow>
    </Container>
  );
};

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const Index = styled.div<{ selected: boolean }>`
  margin-right: 5px;
  width: 10px;
  font-size: 14px;
  font-family: 'FreeSans';
  color: ${(props) => (props.selected ? '#fff' : '#01162d')};
`;
const Flex = styled.div`
  display: inline-flex;
  flex-direction: column;
`;
const Line = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 5px 10px;
  min-width: 300px;
  ${(props) =>
    props.selected &&
    `
        background-color: #183a78;
    `}
`;
const BottomRow = styled.div`
  margin-top: 20px;
`;

const Container = styled.div`
  padding: 20px;
  box-sizing: border-box;
`;

// the container sets the image size and define bounds of draggable
const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90%;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const ArrowsContainer = styled.div`
  /* width: 100%; */
`;

const ArrowInstructions = styled.p`
  font-family: 'FreeSans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  margin-bottom: 0.5rem;
`;

const ArrowWithText = styled.div`
  position: relative;
  cursor: pointer;
  z-index: 99;
  margin-left: 5px;
  font-family: 'FreeSans';
`;

const StyledArrow = styled.div<{ fillColor: string }>`
  width: ${HALF_DOT_WIDTH * 2}px;
  height: ${HALF_DOT_WIDTH * 2}px;
  border-radius: 50%;
  background: ${({ fillColor }) => fillColor};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const DotIndex = styled.span`
  color: white;
  text-align: center;
  font-weight: bold;
`;

const Text = styled.p<{ color?: string; selected?: boolean }>`
  font-family: 'FreeSans';
  font-style: normal;
  font-weight: 500;
  bottom: 0;
  margin: 0;
  color: ${({ selected }) => (selected ? 'white' : '#01162d')};
  font-size: 14px;
`;

const OKButton = styled(OutlinedButton)`
  background: #ef9198;
  color: white;
  border: 0;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  font-weight: bold;
  margin-top: 1em;
`;
