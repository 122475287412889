import { useGetCartEstimatedDuration } from 'pages/selection/hooks/useGetCartEstimatedDuration';
import { useStartLcaTask } from 'pages/start/hooks/useStartLcaTask';
import { LCASelectionCount } from './LCASelectionCount';
import { StartButton } from '../StartButton';

export const LCAView = () => {
  const { startLCATask, isLoading } = useStartLcaTask();

  const getCartEstimatedDuration = useGetCartEstimatedDuration();

  return (
    <>
      <LCASelectionCount />

      <h4>Temps total de l'épreuve : {getCartEstimatedDuration('LCA')}</h4>

      <StartButton type="button" onClick={startLCATask} isLoading={isLoading}>
        Démarrer l'entraînement LCA
      </StartButton>
    </>
  );
};
