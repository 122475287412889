export enum FQQuality {
  Redo,
  Hard,
  Acquired,
  Easy,
}
export enum FQMode {
  DECKLIST = 'DECKLIST',
  DECKCREATION = 'DECKCREATION',
  DECKEDIT = 'DECKEDIT',
  DECKLAUNCH = 'DECKLAUNCH',
  DECKSTARTED = 'DECKSTARTED',
}

export interface QualityLabel {
  label: string;
  quality: number | null;
}
