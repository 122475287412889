import styled from 'styled-components/macro';
import { ColumnHeaderWrapper } from './ColumnHeaderWrapper';

export const DailyCardsHeader = () => {
  return (
    <StyledColumnHeaderWrapper isReadOnly>
      Cartes du jour
    </StyledColumnHeaderWrapper>
  );
};

const StyledColumnHeaderWrapper = styled(ColumnHeaderWrapper)`
  background-color: transparent;

  & > div {
    justify-content: center;
  }
`;
