import styled from 'styled-components';
import { device } from 'helpers/css/responsive';

import { CCBRankingTable } from '../../TrainingTable/CCBRankingTable';
import { MyGrades } from '../components/MyGrades';
import { MySummary } from '../components/MySummary';
import FadeIn from 'ui/Animations/FadeIn';
import { UnlockCard } from 'ui/Cards/UnlockCard';

export const CCBRanking = () => {
  return (
    <>
      <FadeIn>
        <StatContainer>
          <TopContainer>
            <StatCardsContainer>
              <MyGrades title="Mes notes" link />
              <MySummary title="En résumé" />
            </StatCardsContainer>
          </TopContainer>
          <UnlockCard />
          <TableContainer>
            <CCBRankingTable />
          </TableContainer>
        </StatContainer>
      </FadeIn>
    </>
  );
};

export const StatContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 3rem;
`;

export const StatCardsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;
  @media ${device.mobile} {
    flex-direction: column;
    gap: 2rem;
  }
`;

const TableContainer = styled.div`
  margin-top: 40px;
`;

const TopContainer = styled.div``;
