import { FC } from 'react';
import {
  Menu,
  MenuItem,
  FocusableItem,
  SubMenu,
  MenuHeader,
  MenuDivider,
  MenuRadioGroup,
} from '@szhsin/react-menu';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { RootState } from 'store';

import { renderRadios } from 'helpers/ReactMenu/renderRadio';
import { Options, useTableFilters } from 'hooks/useTableFilter';
import { ColumnHeaderWrapper } from '../../../components/Filters/ColumnHeaderWrapper';
import {
  SortOptions,
  sortOptions,
} from '../../../components/Filters/constants';
import { FilterInput } from '../../../components/Filters/FilterInput';
import { useTableConfigContext } from '../tableConfigContext';
import { SortColumns } from 'store/types';

interface Props {
  columnName: string;
  sortColumn: SortColumns;
  filterOptions?: Options[];
  selectedFilters: string[];
  setSelectedFilters: (items: string[]) => void;
  isSub?: boolean;
  disableSort?: boolean;
  disabled?: boolean;
}

export const GenericSortFilterHeader: FC<Props> = ({
  columnName,
  sortColumn,
  filterOptions,
  selectedFilters,
  setSelectedFilters,
  isSub,
  disableSort,
  disabled,
}) => {
  const dispatch = useDispatch();
  const { tableStateSelector, sortByCreator } = useTableConfigContext();

  const sortOrder = useSelector((state: RootState) => {
    const sortState = tableStateSelector(state);
    const isCurrentSortColumn = sortState.sortColumn === sortColumn;

    if (isCurrentSortColumn) return sortState.sortOrder;

    return undefined;
  });

  const setFilterColumn = (sortOrder: SortOptions) => {
    dispatch(sortByCreator({ sortColumn, order: sortOrder }));
  };

  const {
    filteredOptions,
    isFilterSelected,
    searchInput,
    setSearchInput,
    toggleFilter,
    isFiltering,
  } = useTableFilters(filterOptions ?? [], selectedFilters, setSelectedFilters);

  if (disabled) {
    return (
      <ColumnHeaderWrapper isSub={isSub} isReadOnly>
        {columnName}
      </ColumnHeaderWrapper>
    );
  }

  return (
    <Menu
      menuButton={
        <ColumnHeaderWrapper
          isSub={isSub}
          isFiltering={isFiltering}
          sortOrder={sortOrder}
        >
          {columnName}
        </ColumnHeaderWrapper>
      }
      transition
    >
      {!isSub && !disableSort && (
        <>
          <MenuHeader>Trier</MenuHeader>
          <MenuRadioGroup
            value={sortOrder}
            onRadioChange={(e) => setFilterColumn(e.value)}
          >
            {renderRadios([
              { value: sortOptions.ascending, label: 'Croissant' },
              { value: sortOptions.descending, label: 'Décroissant' },
            ])}
          </MenuRadioGroup>
          <MenuDivider />
        </>
      )}
      <MenuHeader>Filtre</MenuHeader>
      <SubMenu
        label="Filtrer par"
        menuStyles={{ maxHeight: 500, overflow: 'auto', paddingTop: 0 }}
      >
        <StickyFocusableItem>
          {({ ref }) => (
            <FullWidthInput
              ref={ref}
              type="text"
              placeholder="Filtrer"
              value={searchInput}
              onChange={(e) => setSearchInput(e.currentTarget.value)}
            />
          )}
        </StickyFocusableItem>
        {filteredOptions.map((option) => (
          <MenuItemStyled
            type="checkbox"
            value={option.value}
            key={option.value}
            checked={isFilterSelected(option.value)}
            onClick={(e) => {
              e.keepOpen = true;
              toggleFilter(option.value);
            }}
          >
            {option.label}
          </MenuItemStyled>
        ))}
      </SubMenu>
    </Menu>
  );
};

const StickyFocusableItem = styled(FocusableItem)`
  position: sticky;
  top: 0px;
  z-index: 1;
`;

const FullWidthInput = styled(FilterInput)`
  width: 100%;
`;
const MenuItemStyled = styled(MenuItem)`
  max-width: 600px;
`;
