import styled from 'styled-components';
import { FC } from 'react';
import {
  RepartitionBar,
  RepartitionBarData,
} from 'components/StatsElements/RepartitionBar';

interface Props {
  icon: React.ReactNode;
  title: string;
  value?: number;
  title2?: string;
  value2?: number;
  progressionBarData?: RepartitionBarData;
}

export const SpeProgressionContainer: FC<Props> = ({
  icon,
  title,
  value,
  title2,
  value2,
  progressionBarData,
}) => {
  return (
    <Container>
      <IconContainer>{icon}</IconContainer>
      <ValueContainer>
        {value || 0} {title}
      </ValueContainer>
      {title2 !== undefined && value2 !== undefined && (
        <ValueContainer>
          {value2} {title2}
        </ValueContainer>
      )}
      <ProgressContainer>
        {progressionBarData && (
          <RepartitionBar repartition={progressionBarData} big={false} />
        )}
      </ProgressContainer>
    </Container>
  );
};

const ValueContainer = styled.div`
  text-align: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  width: fit-content;
  color: ${({ theme }) => theme.colors.primary.dark};
  ${({ theme }) => theme.typography.small};
`;

const IconContainer = styled.div`
  height: 12px;
  width: 12px;
`;

const ProgressContainer = styled.div<{ $noBorder?: boolean }>`
  width: 100%;
  border-radius: 8px;
`;
