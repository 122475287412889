import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { MyGradeCard } from './GradeCard';
import { ParticipantsCard } from './ParticipantsCard';
import { formatDateRange, formatTodayDate } from 'helpers/dates/utils';
import { updateStatsActiveTab } from 'store/slices/ccbSlice';
import { ButtonBase } from 'ui/Buttons/ButtonBase';
import { BigArrowRight } from 'ui/icons/Arrows/BigArrowRight';
import { device } from 'helpers/css/responsive';

interface Props {
  title?: string;
}

export const MySummary: React.FC<Props> = ({ title }) => {
  const { ccbRanking, CCB_start_date, CCB_end_date } = useSelector(
    (state: any) => state.ccb
  );
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(updateStatsActiveTab('Statistique'));
  };

  return (
    <Container>
      {title && <h3>{title}</h3>}
      <CardContainer>
        <ParticipantsCard
          grade={`${ccbRanking?.period_participants} participants sur la période`}
          text={`${formatDateRange(CCB_start_date, CCB_end_date, 'entre')}`}
        />
        <MyGradeCard
          ranks={['A']}
          grade={ccbRanking?.period_passed_rank_A_percentage}
          text={
            'des étudiants ont validé les questions de rang A - sur la période'
          }
          percentage={true}
        />
        <MyGradeCard
          ranks={['A', 'B']}
          grade={ccbRanking?.period_average_global_grade}
          text={
            'Note globale moyenne avec coefficient 1 pour le rang B - sur la période'
          }
        />
        <ParticipantsCard
          grade={`${ccbRanking?.total_participants} participants au total`}
          text={`Au ${formatTodayDate()}`}
        />
      </CardContainer>
      <ButtonBase
        styled={'link-red'}
        size={'medium'}
        onClick={handleClick}
        disabled={false}
        hasPadding={false}
      >
        Voir plus de statistiques <BigArrowRight />
      </ButtonBase>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: inherit;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  @media ${device.desktop} {
    & > :last-child {
      margin-left: 2rem;
    }
  }
`;
