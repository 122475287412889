import { getAPI } from 'auth';
import { KnowledgesList, KnowledgesParams } from 'types/knowledge.type';

// export const getKnowledgesHttp = async () => {
//   const response = await getAPI<KnowledgesList>('/selection/knowledges/');
//   return response.data;
// };

export const getKnowledgesListHttp = async (params?: KnowledgesParams) => {
  const response = await getAPI<KnowledgesList>('/common/knowledges/', {
    params,
  });
  return response.data;
};

export const getKnowledgeHttp = async (knowledgeId: string) => {
  if (!knowledgeId || knowledgeId === '') return null;
  const response = await getAPI<KnowledgesList>('/common/knowledges', {
    params: {
      knowledge: knowledgeId,
    },
  });
  if (response.data.length === 0) {
    return null;
  }
  return response.data[0];
};
