import React from 'react';
import styled from 'styled-components/macro';

interface Props {
  amount: number;
  small?: boolean;
  endSubscription?: string;
  onlyText?: boolean;
}

const getDiffMonth = (amount: number, endSubscription: string) => {
  if (!endSubscription) return <span>{`${amount / 100}€ / mois`}</span>;

  const endYear = parseInt(endSubscription?.split('/')[2]);
  const endMonth = parseInt(endSubscription?.split('/')[1]) - 1;
  const endDay = parseInt(endSubscription?.split('/')[0]);

  const date = new Date();

  // If the end date is before the current date, we return the amount as is
  const isEndDateBeforeToday =
    endYear < date.getFullYear() ||
    (endYear === date.getFullYear() && endMonth < date.getMonth()) ||
    (endYear === date.getFullYear() &&
      endMonth === date.getMonth() &&
      endDay < date.getDate());

  const diffInMonths = isEndDateBeforeToday
    ? 1
    : (endYear - date.getFullYear()) * 12 +
      (endMonth - date.getMonth()) +
      (endDay - date.getDate() <= -15
        ? -1
        : endDay - date.getDate() >= 15
        ? 1
        : 0);

  return <span>{`${Math.round(amount / diffInMonths) / 100}€ / mois`}</span>;
};

const PriceByMonth: React.FC<Props> = ({
  amount,
  small,
  endSubscription,
  onlyText,
}) => {
  const Price = getDiffMonth(amount, endSubscription || '');

  return onlyText ? (
    Price
  ) : small ? (
    <BoldText>{Price}</BoldText>
  ) : (
    <h2>{Price}</h2>
  );
};

const BoldText = styled.h4`
  font-weight: 700;
`;

export default PriceByMonth;
