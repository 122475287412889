import { Fragment, useEffect } from 'react';
import { FQDeckList } from './components/FQDeckList/FQDeckList';
import { FQDeckManage } from './components/FQDeckManage/FQDeckManage';
import { useDispatch, useSelector } from 'react-redux';
import { reset, selectMode } from 'store/slices/fqCurrentSlice';
import { FQMode } from './types';
import { FQProcessPage } from './components/FQProcessPage/FQProcessPage';
import { FQLaunchPage } from './components/FQLaunchPage/FQLaunchPage';
import { PremiumLockedFlash } from 'components/PremiumLockedFlash/PremiumLockedFlash';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';
import styled from 'styled-components';

export const FlashQuestions = () => {
  const mode = useSelector(selectMode);
  const dispatch = useDispatch();
  const { isUserPremium } = useIsUserPremium();

  useEffect(
    () => () => {
      dispatch(reset());
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Layout>
      {isUserPremium ? (
        <Fragment>
          {mode === FQMode.DECKLIST && <FQDeckList />}
          {mode === FQMode.DECKCREATION && <FQDeckManage />}
          {mode === FQMode.DECKEDIT && <FQDeckManage />}
          {mode === FQMode.DECKLAUNCH && <FQLaunchPage />}
          {mode === FQMode.DECKSTARTED && <FQProcessPage />}
        </Fragment>
      ) : (
        <Fragment>
          <TitleContainer>
            <h1>Questions Flash</h1>
          </TitleContainer>
          <PremiumLockedFlash />
        </Fragment>
      )}
    </Layout>
  );
};

const Layout = styled.div`
  width: 100%;
  padding: 48px 32px;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
`;
