import { postAPI } from 'auth/authentifiedHttpCalls';
import { ItemInfos } from 'store/types';

import { CreateTaskBody, CreateTaskReponse } from './types';

export const postCreateTask = async (
  groupItems: ItemInfos[],
  qiItems: ItemInfos[]
) => {
  const response = await postAPI<CreateTaskReponse, CreateTaskBody>(
    '/training/create_task/',
    {
      group_ids: groupItems.map((item) => ({
        group_id: item.id,
      })),
      qi_ids: qiItems.map((item) => item.id),
    }
  );
  return response.data;
};
