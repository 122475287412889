import { deleteAPI } from 'auth';
import { TaskRequestType } from 'types/task.type';

export const deleteTrainingTask = async (taskId: number) => {
  const response = await deleteAPI<TaskRequestType>(
    '/training/delete_task/' + taskId
  );

  return response.data;
};
