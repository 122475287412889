import { useState, useMemo, useCallback } from 'react';

export interface Options {
  label: string;
  value: string;
}

export function useTableFilters(
  filterOptions: Options[],
  selectedFilters: string[],
  setSelectedFilters: (newVal: string[]) => void
) {
  const [searchInput, setSearchInput] = useState<string>('');

  const filteredOptions = useMemo(() => {
    return filterOptions.filter(
      (option) =>
        typeof option.label === 'string' &&
        option.label.toLowerCase().includes(searchInput.toLowerCase())
    );
  }, [filterOptions, searchInput]);

  const toggleFilter = (filterValue: string) => {
    if (selectedFilters.includes(filterValue)) {
      setSelectedFilters(
        selectedFilters.filter((value) => value !== filterValue)
      );
    } else {
      // We sort the data to optimize caching, this way react query deps
      // are the same no matter in which order we select the filters
      setSelectedFilters([...selectedFilters, filterValue].sort());
    }
  };

  const isFilterSelected = useCallback(
    (filterValue: string) => selectedFilters.includes(filterValue),
    [selectedFilters]
  );

  const isFiltering = useMemo(
    () => selectedFilters.length > 0,
    [selectedFilters]
  );

  return {
    searchInput,
    setSearchInput,
    toggleFilter,
    filteredOptions,
    isFilterSelected,
    isFiltering,
  };
}
