import { ThemeColors } from './colors/types';
import { DefaultTheme } from 'styled-components/macro';
import { ThemeVariants, themeColorsMap } from './colors';
import { themeBorderRadiuses, ThemeBorderRadiuses } from './borderRadius';
import { ThemeZIndexes, zIndexes } from './zIndex/zIndexes';
import { typographies, Typographies } from './typography';
import { correctionTypographies } from './typography/correction';
import { compassTypographies } from './typography/compass';

// Declaration merging to get proprer autocomplete on the theme
declare module 'styled-components' {
  export interface DefaultTheme {
    colors: ThemeColors;
    borderRadius: ThemeBorderRadiuses;
    zIndex: ThemeZIndexes;
    typography: Typographies;
  }
}

export const getTheme = (themeVariant: ThemeVariants): DefaultTheme => ({
  colors: themeColorsMap[themeVariant],
  borderRadius: themeBorderRadiuses,
  zIndex: zIndexes,
  typography: typographies,
});

export const getCorrectionTheme = (
  themeVariant: ThemeVariants
): DefaultTheme => ({
  colors: themeColorsMap[themeVariant],
  borderRadius: themeBorderRadiuses,
  zIndex: zIndexes,
  typography: correctionTypographies,
});

export const getCompassTheme = (themeVariant: ThemeVariants): DefaultTheme => ({
  colors: themeColorsMap[themeVariant],
  borderRadius: themeBorderRadiuses,
  zIndex: zIndexes,
  typography: compassTypographies,
});

export type { ThemeVariants };
