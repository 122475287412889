import { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import parse from 'html-react-parser';
import { Checkbox } from 'ui/Checkbox';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectSelectedAnswerById,
  toggleAnswer,
} from 'store/slices/fqCurrentSlice';
import { RootState } from 'store';

const A_CHAR_CODE = 'A'.charCodeAt(0);

const capitalizeFirstLetterAndParse = (string: string) => {
  return parse(string.charAt(0).toUpperCase() + string.slice(1) ?? '');
};

interface Props {
  itemData: {
    id: number;
    question_item: {
      num: number;
      statement: string;
    };
    answer: boolean;
  };
  index: number;
}

export const FQQCMPropositionItem: FC<Props> = ({ itemData, index }) => {
  const selectedAnswer = useSelector(({ fqCurrent }: RootState) =>
    selectSelectedAnswerById(fqCurrent, { id: itemData.id })
  );

  const dispatch = useDispatch();
  const toggleCheckBox = () => {
    dispatch(toggleAnswer({ id: itemData.id }));
  };

  return (
    <Container>
      <QuestionContainer>
        <AnswerBlock>
          <PropositionBlock onClick={toggleCheckBox}>
            <CheckAndPropBlock>
              <Checkbox
                onChange={toggleCheckBox}
                checked={selectedAnswer || false}
              />
              <Proposition>
                Proposition {String.fromCharCode(A_CHAR_CODE + index)}
              </Proposition>
            </CheckAndPropBlock>
            <FQAnswer>
              {capitalizeFirstLetterAndParse(itemData.question_item.statement)}
            </FQAnswer>
          </PropositionBlock>
        </AnswerBlock>
      </QuestionContainer>
    </Container>
  );
};

export const QuestionContainer = styled.div`
  ${({ theme }) =>
    css`
      background-color: ${theme.colors.primary.light};
    `};
  border-radius: 8px;
  margin-top: 24px;
  padding: 0.5em;
  width: 100%;
  display: flex;
`;

const Container = styled.div`
  display: flex;
`;

const CheckAndPropBlock = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
`;

const PropositionBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1%;
  flex: 10;
  cursor: pointer;
`;

const Proposition = styled.div`
  margin-left: 0.9em;
`;

const FQAnswer = styled.div`
  text-align: justify;
  text-justify: inter-word;
  font-size: 0.8em;
`;

const AnswerBlock = styled.div`
  flex: 16;
  display: flex;
  justify-content: space-between;
`;
