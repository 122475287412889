import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';

import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { getAnnalsSessionRecap } from '../domain/useCases/getAnnalsSessionRecap';
import { getSessionRecap } from '../domain/useCases/getSessionRecap';
import { SessionRequestType } from '../http/types';
import { MS_IN_AN_HOUR } from 'constants/date';

export const useGetSessionRecap = (body: SessionRequestType | undefined) => {
  return useQuery(
    ['sessionRecap', body],
    () => {
      if (body!.collection === 'Annales') return getAnnalsSessionRecap();
      else {
        return getSessionRecap(body!);
      }
    },
    {
      onError: (e) => {
        toast.error(
          "Nous n'avons pas réussi à récupérer vos données. Veuillez réessayer plus tard."
        );
        reportErrorToSentry(e);
      },
      cacheTime: MS_IN_AN_HOUR,
      staleTime: MS_IN_AN_HOUR,
      enabled: !!body,
    }
  );
};
