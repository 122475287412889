import { HALF_DOT_WIDTH } from 'pages/new_training/components/QZP/SingleAnswer';
import { FC } from 'react';
import styled, { css } from 'styled-components/macro';

interface Props {
  color: string;
  index?: number;
  isSelected?: boolean;
}

export const DotPointer: FC<Props> = ({ color, index, isSelected }) => {
  return (
    <Container>
      <StyledDot fillColor={color} isSelected={isSelected ?? false}>
        {index !== undefined ? index + 1 : ''}
      </StyledDot>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  z-index: 2;
`;

const StyledDot = styled.div<{ fillColor: string; isSelected: boolean }>`
  width: ${HALF_DOT_WIDTH * 2}px;
  height: ${HALF_DOT_WIDTH * 2}px;
  background: ${({ fillColor }) => fillColor};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  border: 2px solid transparent;

  :hover {
    border-color: ${({ theme }) => theme.colors.primary.main};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-color: ${({ theme }) => theme.colors.primary.dark};
    `}
`;
