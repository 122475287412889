import { FC, useMemo } from 'react';

import { QIBasicInformations } from 'modules/progress/general/qi/domain/entities/QIBasicInformations';

import { useIsQIAlreadySelected } from '../hooks/useIsQIAlreadySelected';
import { useAddToLocalCartContext } from '../contexts/addToLocalCartContext';
import { GenericCartCheckbox } from '../../components/GenericCartCheckbox';

interface Props {
  question: QIBasicInformations;
}

export const ChartCheckbox: FC<Props> = ({ question }) => {
  const isSelected = useIsQIAlreadySelected({
    id: question.id,
    type: 'QI',
  });

  const {
    isQuestionInLocalSelection: isQuestionInSelection,
    toggleInLocalCart: toggleToCart,
  } = useAddToLocalCartContext();

  const isInLocalSelection = useMemo(
    () => isQuestionInSelection(question.id),
    [isQuestionInSelection, question.id]
  );

  return (
    <GenericCartCheckbox
      isFree={question.isFree}
      checked={isSelected || isInLocalSelection}
      disabled={isSelected}
      onChange={() => toggleToCart(question.id)}
    />
  );
};
