import { FC } from 'react';
import styled from 'styled-components/macro';
import parse from 'html-react-parser';

import { ModalHeader, Modal } from 'ui/Modal';
import { Loader } from 'components/Loader/Loader';
import { useGetGroupDetail } from 'http/progress/detail/group/connector';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';

import { AddDPToSelectionButton } from './AddDPToSelectionButton';
import { GoToSubscribeButton } from '../../components/GoToSubscribeButton';
import { StyledMathJax } from 'ui/MathJax';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  groupId: number;
}

export const PreviewModal: FC<Props> = ({
  isModalOpen,
  closeModal,
  groupId,
}) => {
  const { data: group } = useGetGroupDetail(groupId);
  const { isUserPremium } = useIsUserPremium();

  const shouldShowContent = isUserPremium || group?.is_free;
  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      {group ? (
        <>
          <ModalHeader onCloseButtonClick={closeModal}>
            <h4>{`${group.collection} - DP${group.num}`}</h4>
          </ModalHeader>
          <ModalContent>
            {shouldShowContent ? (
              <>
                <ScrollableContainer>
                  <Statement>
                    <StyledMathJax inline>
                      {parse(group.thumbnail ?? '')}
                    </StyledMathJax>
                  </Statement>
                </ScrollableContainer>
                <AddDPToSelectionButton group={group} />
              </>
            ) : (
              <>
                <p>
                  Vous devez vous abonner pour accéder à la vignette de ce DP.
                </p>
                <GoToSubscribeButton />
              </>
            )}
          </ModalContent>
        </>
      ) : (
        <>
          <ModalHeader onCloseButtonClick={closeModal}>
            Chargement...
          </ModalHeader>
          <ModalContent>
            <Loader />
          </ModalContent>
        </>
      )}
    </Modal>
  );
};

const ModalContent = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const Statement = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

const ScrollableContainer = styled.div`
  overflow: auto;
  padding-right: 4px;
`;
