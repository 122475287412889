import styled, { useTheme } from 'styled-components';
import { useEffect, useMemo, useState } from 'react';
import { thinScrollbarStyle } from 'helpers/css/thin-scrollbar';
import { device } from 'helpers/css/responsive';
import { ArrowSquareOut } from 'ui/icons/ArrowSquareOut';
import { MathJax } from 'better-react-mathjax';
import { useTour } from '@reactour/tour';
import { useHomeInfoContext } from '../../context/homeInfoContext';
import { TabCards } from 'ui/Cards/TabCards';
import { RepartitionBar } from 'components/StatsElements/RepartitionBar';
import { useNavigate } from 'react-router-dom';
import { Section, SectionTitle } from '../../ui/Global';
import { useGetHomeSpecialty } from 'http/new-home/connector';

export const BookInfosContainer = () => {
  const { activeSpecialtyId } = useHomeInfoContext();
  const { data } = useGetHomeSpecialty(activeSpecialtyId);

  const navigate = useNavigate();
  const [selectedBookId, setSelectedBookId] = useState<number>(0);

  const { isOpen } = useTour();
  const theme = useTheme();

  const navigateToItems = (itemId: number) => {
    navigate(`/?selected=items&activeItem=${itemId}`);
  };

  // to delete after data formating following back-end modifications

  useEffect(() => {
    if (data?.books && data?.books.length > 0) {
      setSelectedBookId(data?.books[0].id);
    }
  }, [data?.books]);

  const selectedBook = useMemo(
    () => data?.books.find((book) => book.id === selectedBookId),
    [data?.books, selectedBookId]
  );

  if (!data?.books || data?.books.length === 0) {
    return null;
  }

  return (
    <Section>
      <SectionTitle>Livres et items</SectionTitle>
      <Container id={'book-infos-container'}>
        <TabCards
          tabs={data.books.slice(0, 2)}
          setSelectedBookId={setSelectedBookId}
          selectedBookId={selectedBookId}
          width="33%"
        />
        <ItemsRelatedToBook id="book-infos-items">
          {selectedBook?.items && selectedBook.items.length > 1 ? (
            <ScrollContainer>
              <Table>
                <tbody id="books-items-container">
                  {selectedBook.items
                    .sort((a, b) => a.item - b.item)
                    .map((item, index) => {
                      if (isOpen && index < 4) return null;
                      const progressionBarData = [
                        {
                          id: 'correctes',
                          name: 'correctes',
                          value: item.questions?.success || 0,
                          color: theme.colors.success.main,
                        },
                        {
                          id: 'erronées',
                          name: 'erronées',
                          value: item.questions?.fail || 0,
                          color: theme.colors.secondary.main,
                        },
                        {
                          id: 'non réalisées',
                          name: 'non réalisées',
                          value: item.questions?.not_done || 0,
                          color: theme.colors.primary.main,
                        },
                      ];

                      return (
                        <Row
                          key={item.item}
                          onClick={() => {
                            navigateToItems(item.item);
                          }}
                          id={`book-infos-item-${item.item}`}
                        >
                          <ItemNumber>Item {item.item}</ItemNumber>
                          <ItemTitleContainer>
                            <ItemTitle>
                              <StyledMathJax>{item.title}</StyledMathJax>
                              <IconContainer>
                                <ArrowSquareOut />
                              </IconContainer>
                            </ItemTitle>
                          </ItemTitleContainer>
                          <ItemTitleContainer>
                            <BarContainer>
                              <BarLabel>
                                <BarLabel>
                                  {item.questions?.total || 0} question
                                  {item.questions?.total === 1 ||
                                  item.questions?.total === 0
                                    ? ''
                                    : 's'}
                                </BarLabel>
                              </BarLabel>
                              <ProgressContainer>
                                {progressionBarData && (
                                  <RepartitionBar
                                    repartition={progressionBarData}
                                    big={false}
                                  />
                                )}
                              </ProgressContainer>
                            </BarContainer>
                          </ItemTitleContainer>
                        </Row>
                      );
                    })}
                </tbody>
              </Table>
            </ScrollContainer>
          ) : (
            'Pas de données disponibles'
          )}
        </ItemsRelatedToBook>
      </Container>
    </Section>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const Table = styled.table`
  border-spacing: 4px;
`;

const ItemsRelatedToBook = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;

  ${thinScrollbarStyle}
`;

const ScrollContainer = styled.div`
  border: 3px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 12px;
  overflow-y: auto;
  flex-grow: 1;
  scroll-behavior: smooth;
  max-height: 600px;
`;

const ItemNumber = styled.td`
  background-color: ${({ theme }) => theme.colors.primary.lighter};
  padding: 0.875rem 1rem;
  border-radius: 8px;
  min-width: 100px;
  font-size: 1rem;
  justify-content: center;
  text-align: center;
`;

const ItemTitleContainer = styled.td`
  background-color: ${({ theme }) => theme.colors.primary.lighter};
  padding: 0.875rem 1rem;
  border-radius: 8px;
  width: 100%;
  max-width: 70%;
`;

const Row = styled.tr`
  gap: 4px;
  margin-bottom: 4px;
  transition: background-color 0.3s;

  &:hover {
    cursor: pointer;
    ${ItemTitleContainer}, ${ItemNumber} {
      background-color: ${({ theme }) => theme.colors.primary.light};
    }
  }
`;

const BarContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  ${({ theme }) => theme.typography.body}
  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const BarLabel = styled.span`
  min-width: max-content;
  font-size: 0.875rem;
`;

const ItemTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${({ theme }) => theme.typography.body}
  font-size: 0.875rem;
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
`;

export const StyledMathJax = styled(MathJax)`
  display: flex;
  font-size: 1rem;
  line-height: 1.25rem;
`;

const ProgressContainer = styled.div<{ $noBorder?: boolean }>`
  width: 100%;
  border-radius: 8px;
`;
