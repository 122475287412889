import { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import { DotPointer } from 'ui/DotWithText';
import { CheckDefault } from 'pages/correction/components/icons/CheckDefault';
import { CrossDefault } from 'pages/correction/components/icons/CrossDefault';
import { buttonReset } from 'helpers/css/button-reset';
import { ExclamationPointSquare } from 'ui/ExclamationPointSquare';

interface Props {
  label: string;
  hasUserAnswered: boolean;
  dotIndex: number;
  dotColor: string;
  isUserRight: boolean;
  commentary: string;
  toggleArrow: () => void;
  toggleShape: () => void;
  isArrowSelected: boolean;
  isShapeSelected: boolean;
}

export const QZPProposition: FC<Props> = ({
  label,
  hasUserAnswered,
  dotIndex,
  dotColor,
  isUserRight,
  toggleArrow,
  toggleShape,
  isArrowSelected,
  isShapeSelected,
}) => {
  return (
    <Container>
      <Proposition>
        <LabelContainer isUserRight={isUserRight}>{label}</LabelContainer>

        <ActionsContainer>
          {dotIndex}
          {hasUserAnswered ? (
            <TogglePointer
              onClick={(e) => {
                e.stopPropagation();
                toggleArrow();
              }}
            >
              <DotPointer isSelected={isArrowSelected} color={dotColor} />
            </TogglePointer>
          ) : (
            <ExclamationPointSquare text={'Point non placé'} />
          )}
          <Toggle
            onClick={(e) => {
              e.stopPropagation();
              toggleShape();
            }}
            isSelected={isShapeSelected}
          >
            ▨
          </Toggle>
        </ActionsContainer>
        <IconContainer>
          {isUserRight ? <CheckDefault /> : <CrossDefault />}
        </IconContainer>
      </Proposition>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Proposition = styled.div`
  border-radius: 8px;
  padding: 8px 16px;
  width: 100%;

  border: 2px solid ${({ theme }) => theme.colors.primary.light};
  transition: background-color ease-out 0.1s;
  background-color: ${({ theme }) => theme.colors.background.paper};

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  z-index: 1;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const LabelContainer = styled.div<{ isUserRight: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  ${({ theme }) => theme.typography.label}

  color: ${({ theme, isUserRight }) =>
    isUserRight ? theme.colors.success.main : theme.colors.error.main};
`;

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

const Toggle = styled.button<{ isSelected: boolean }>`
  ${buttonReset}
  height: 24px;
  width: 24px;
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary.dark};
  text-align: center;

  transition-property: background-color;
  transition-timing-function: ease-in-out;
  transition-duration: 100ms;

  &:hover {
    outline: 1px solid ${({ theme }) => theme.colors.primary.main};
  }

  &:active {
    transform: scaleX(1.05);
  }

  ${({ isSelected, theme }) =>
    isSelected &&
    css`
      background-color: ${theme.colors.primary.main};
    `}
`;

const TogglePointer = styled.button`
  ${buttonReset}
  height: 24px;
  width: 24px;
  text-align: center;
`;
