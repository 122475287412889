import { MAX_CANDIDATES } from 'pages/easy-dispo/EasyDispo';
import {
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  Line,
  Label,
  ResponsiveContainer,
  Scatter,
  ComposedChart,
  Dot,
  Area,
} from 'recharts';
import { useTheme } from 'styled-components';
import { LooseObject } from './GraphContainer';

interface Props {
  scatterData: LooseObject[];
  numOfRanks: number;
  centered?: boolean;
}

//interface

export const ScatterPlot = (props: Props) => {
  const theme = useTheme();

  return (
    <ResponsiveContainer>
      <ComposedChart
        width={600}
        height={350}
        data={props.scatterData}
        margin={{ top: 50, right: 30, left: 20, bottom: 30 }}
      >
        <CartesianGrid
          strokeDasharray="3 3"
          stroke={theme.colors.primary.main}
        />
        <XAxis
          dataKey="name"
          orientation="top"
          stroke={theme.colors.primary.dark}
          tick={{ fontSize: '0.7em' }}
          label={{
            value: 'Années',
            fill: theme.colors.primary.dark,
            position: 'top',
          }}
        />
        <YAxis
          reversed
          yAxisId={0}
          stroke={theme.colors.primary.dark}
          tick={{ fontSize: '0.7em' }}
          label={
            <Label
              value="Rangs"
              fill={theme.colors.primary.dark}
              position="insideLeft"
              angle={-90}
            />
          }
          domain={props.centered ? [0, 'dataMax'] : [0, MAX_CANDIDATES]}
        />
        <YAxis
          dataKey="bottomXAxis"
          yAxisId={1}
          domain={props.centered ? [0, 'dataMax'] : [0, MAX_CANDIDATES]}
          hide
        />
        <Tooltip />
        <Legend wrapperStyle={{ top: '95%' }} />
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop
              offset="5%"
              stopColor={theme.colors.primary.main}
              stopOpacity={0.8}
            />
            <stop
              offset="95%"
              stopColor={theme.colors.primary.main}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="numOfCandidates"
          stroke={theme.colors.primary.main}
          fillOpacity={1}
          fill="url(#colorUv)"
          yAxisId={1}
          legendType="none"
          tooltipType="none"
        />

        {Array(props.numOfRanks)
          .fill(0)
          .map((_, index) => (
            <Scatter
              key={`scatter-${index}`}
              dataKey={`s${index}`}
              name={index === 0 ? 'Affectations' : `Affectations${index}`}
              fill={theme.colors.secondary.dark}
              shape={<Dot r={4} />}
              legendType={index === 0 ? 'circle' : 'none'}
              tooltipType="none"
            ></Scatter>
          ))}
        <Line
          type="monotone"
          dataKey="worstRank"
          name="Rang limite"
          stroke={theme.colors.secondary.main}
          connectNulls
        />
        <Line
          type="monotone"
          dataKey="yourRank"
          name="Votre rang"
          stroke={theme.colors.primary.dark}
          connectNulls
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
