import { FC, LabelHTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  Props,
} from 'react-select';

import { device } from 'helpers/css/responsive';
import { DownChevron } from 'ui/icons/DownChevron';
import { getDropdownStyle } from 'ui/Dropdown/dropdownStyles';
import { Option, IsMulti } from 'ui/Dropdown/dropdownStyles';
import { TextBody2 } from 'ui/TextBody2';

type NativeLabelProps = LabelHTMLAttributes<HTMLLabelElement>;
interface SelectProps extends Props<Option, IsMulti, GroupBase<Option>> {
  label?: string;
  placeholder?: string;
  className?: string;
  labelProps?: NativeLabelProps;
  errorMessage?: string;
  isMandatory?: boolean;
}

export const Dropdown: FC<SelectProps> = (props) => {
  const theme = useTheme();
  const selectStyles = getDropdownStyle(theme);
  const {
    options,
    label,
    placeholder,
    className,
    labelProps,
    errorMessage,
    isMandatory,
    ...selectProps
  } = props;

  return (
    <Container className={className}>
      <LabelLine>
        {label ? (
          <LabelSubLine>
            <Label {...labelProps} htmlFor={selectProps.name}>
              {label}
            </Label>
            {isMandatory && (
              <div
                style={{
                  color: 'red',
                }}
              >
                *
              </div>
            )}
          </LabelSubLine>
        ) : null}
        <ErrorMessageDesktop>{errorMessage}</ErrorMessageDesktop>
      </LabelLine>
      <SelectContainer>
        <ReactSelect<Option>
          options={options ? options : []}
          placeholder={placeholder ? placeholder : ''}
          inputId={selectProps.name}
          styles={selectStyles}
          components={{ DropdownIndicator: DropdownIndicator }}
          className={errorMessage ? 'isNotValid' : 'isValid'}
          value={selectProps.value}
          {...selectProps}
        />
      </SelectContainer>
      <ErrorMessageMobile>{errorMessage}</ErrorMessageMobile>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.primary.dark};
  width: 100%;
  @media ${device.desktop} {
    font-size: 20px;
  }
`;

const SelectContainer = styled.div`
  display: block;
  height: 36px;
  @media ${device.desktop} {
    height: 48px;
  }
`;

const DropdownIndicator: FC<DropdownIndicatorProps<Option>> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <StyledDownArrow />
    </components.DropdownIndicator>
  );
};

const Label = styled.label``;

const StyledDownArrow = styled(DownChevron)`
  color: ${({ theme }) => theme.colors.primary.dark};
  object-fit: fill;
  width: 8px;
  height: 4px;
  @media ${device.desktop} {
    width: 16px;
    height: 8px;
  }
`;

const LabelLine = styled.div`
  padding: 0 16px;

  @media ${device.desktop} {
    padding: 0 24px;
  }

  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const ErrorMessageMobile = styled(TextBody2)`
  display: block;
  margin-left: auto;
  margin-right: 16px;
  padding-left: 16px;
  color: ${({ theme }) => theme.colors.error.main};

  @media ${device.desktop} {
    display: none;
  }
`;

const ErrorMessageDesktop = styled(TextBody2)`
  display: none;

  @media ${device.desktop} {
    color: ${({ theme }) => theme.colors.error.main};
    display: inline;
  }
`;

const LabelSubLine = styled.div`
  display: flex;
  flex-direction: row;
`;
