import { SpeDetailedProgressRepository } from '../domain/SpeDetailedProgress.repository';
import { adaptSpeDetailedProgress } from './adapter';
import { getSpeDetailedProgressHttp } from './api';

export const getSpeDetailedProgressRepository =
  (): SpeDetailedProgressRepository => speDetailedProgressRepositoryImpl;

const speDetailedProgressRepositoryImpl: SpeDetailedProgressRepository = {
  getSpeDetailedProgress: async (params) => {
    const httpResponse = await getSpeDetailedProgressHttp(params);

    return adaptSpeDetailedProgress(httpResponse);
  },
};
