import { FC } from 'react';
import styled from 'styled-components/macro';
import Tippy from '@tippyjs/react';

import { LockIcon } from 'ui/icons/Lock';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';

interface Props {
  ressourceName: string;
  isFree?: boolean;
}

export const PremiumLockedRessource: FC<Props> = ({
  isFree,
  ressourceName,
  children,
}) => {
  const { isUserPremium } = useIsUserPremium();

  const shouldShowRessource = isUserPremium || isFree;

  if (shouldShowRessource) {
    return <>{children}</>;
  }

  return (
    <Container>
      {ressourceName}
      <Tippy content="Contenu abonné">
        <div>
          <Lock />
        </div>
      </Tippy>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: baseline;
`;

const Lock = styled(LockIcon)`
  height: 16px;
  width: 16px;
`;
