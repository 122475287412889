import { createSelector, createSlice } from '@reduxjs/toolkit';

import { Popup } from 'http/popup/types';

import { RootState } from 'store';
import { fetchPopups, postSeenPopup } from 'store/actions/popupActions';

import { Status } from 'store/types';

export const HTTP_KEYS = ['getPopup', 'postPopup'] as const;
export interface PopupState {
  popup: Popup | null;
  status: Record<typeof HTTP_KEYS[number], Status>;
  errors: Record<typeof HTTP_KEYS[number], string>;
}

const initialState: PopupState = {
  popup: null,
  status: {
    getPopup: Status.IDLE,
    postPopup: Status.IDLE,
  },
  errors: {
    getPopup: '',
    postPopup: '',
  },
};

export const popupSlice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    closePopup(state) {
      state.popup = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchPopups.pending, (state) => {
      state.status.getPopup = Status.PENDING;
    });

    builder.addCase(fetchPopups.fulfilled, (state, action) => {
      state.status.getPopup = Status.RESOLVED;

      if (action.payload.length > 0) {
        const last = action.payload.length - 1;
        state.popup = action.payload[last];
      } else {
        state.popup = null;
      }
    });

    builder.addCase(fetchPopups.rejected, (state, action) => {
      state.status.getPopup = Status.REJECTED;
    });

    builder.addCase(postSeenPopup.pending, (state) => {
      state.status.postPopup = Status.PENDING;
    });

    builder.addCase(postSeenPopup.fulfilled, (state) => {
      state.status.postPopup = Status.RESOLVED;
      state.popup = null;
    });

    builder.addCase(postSeenPopup.rejected, (state) => {
      state.status.postPopup = Status.REJECTED;
    });
  },
});

const selectPopupState = (state: RootState) => state.popup;
export const selectPopupStatus = createSelector(selectPopupState, (state) => {
  if (state.popup) {
    return state.popup.status;
  }

  return null;
});
export const { closePopup } = popupSlice.actions;
export const popupReducer = popupSlice.reducer;
