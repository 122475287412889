import {
  PreviousGroupTraining,
  PreviousQuestionTraining,
} from '../../shared/entities/PreviousTraining';

import { SpeDetailedProgress } from '../domain/entities/SpeDetailedProgress';

import {
  DetailedGroupHttp,
  DetailedQIHttp,
  GroupTrainingHttp,
  ProgressDetailedSpeResponseHttp,
  QITrainingHttp,
} from './types';
import { SpeQuestionGroup } from '../domain/entities/SpeQuestionGroup';
import { SpeQI } from '../domain/entities/SpeQI';

export const adaptSpeDetailedProgress = (
  progressHttp: ProgressDetailedSpeResponseHttp
): SpeDetailedProgress => {
  try {
    return {
      dps: progressHttp.dps.map((dp) => adaptSpeQuestionGroup(dp)),
      qis: progressHttp.qis.map((qi) => adaptSpeQi(qi)),
    };
  } catch (e) {
    throw new Error(
      "Error in detailed progress by spe mapping, unexpected response from '/progress/detailed/specialty':\n " +
        e
    );
  }
};

function adaptSpeQuestionGroup(dp: DetailedGroupHttp): SpeQuestionGroup {
  return {
    num: dp.num,
    composition_unit: dp.composition_unit,
    specialtyTrigram1: dp.specialty1 ?? undefined,
    specialtyTrigram2: dp.specialty2 ?? undefined,
    denom: dp.denom ?? dp.total_coefficient,
    lastGrade: dp.last_grade ?? undefined,
    median: dp.median,
    number_of_questions: dp.number_of_questions,
    previousTrainings: dp.trainings.map((training) =>
      adaptPreviousGroupTraining(training, dp.id)
    ),
    thumbnail: dp.thumbnail,
    collection: dp.collection,

    isFree: dp.is_free,
    id: dp.id,
  };
}

function adaptPreviousGroupTraining(
  training: GroupTrainingHttp,
  groupId: number
): PreviousGroupTraining {
  return {
    id: groupId,
    date: new Date(training.last_training_date),
    denom: training.total_coefficient,
    grade: training.grade ?? undefined,
    groupCorrectionId: training.id,
  };
}

function adaptSpeQi(qi: DetailedQIHttp): SpeQI {
  return {
    gradeDenom: qi.coefficient,
    lastGrade: qi.last_grade ?? undefined,
    num: qi.num,
    composition_unit: qi.composition_unit,
    previousTrainings: qi.trainings.map((training) =>
      adaptPreviousQuestionTraining(training, qi.id)
    ),
    specialtyTrigram: qi.specialty1 ?? undefined,
    collection: qi.collection,
    isFree: qi.is_free,
    mode: qi.mode,
    id: qi.id,
    item: qi.knowledge?.item.short_title ?? undefined,
    knowledge: qi.knowledge,
  };
}

function adaptPreviousQuestionTraining(
  training: QITrainingHttp,
  qiId: number
): PreviousQuestionTraining {
  return {
    id: qiId,
    date: new Date(training.last_training_date),
    denom: training.coefficient,
    grade: training.grade ?? undefined,
    groupCorrectionId: training.id,
    qiIdInGroupCorrection: training.correction_question_id,
  };
}
