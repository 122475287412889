import { useRef, useEffect, FC } from 'react';
import { Polygon } from '../types';
import styled from 'styled-components/macro';
import { drawPolygon } from './drawPolygon';

interface Props {
  polygonsGroups: Polygon[][];
}

export const ShapeCanvas: FC<Props> = ({ polygonsGroups }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas === null) return;

    // Resize the canvas to make it fit the image
    // we always update the dimensions of the canvas
    // in order not to get blurry images if the windows
    // started as narrow then got bigger, which would result
    // in a low canvas resolution but big screen
    // Another wanted side effect is that updating the
    // canvas dimensions clears the content of the canvas
    // and we want to clear then redraw the canvas everytime
    // the polygons passed in props change
    canvas.width = canvas.offsetWidth;
    canvas.height = canvas.offsetHeight;

    const ctx = canvas.getContext('2d');
    if (ctx === null) return;

    const width = canvas.width;
    const height = canvas.height;

    polygonsGroups.forEach((polygonGroup) =>
      polygonGroup.forEach((polygon) => {
        drawPolygon(polygon, { ctx, width, height });
      })
    );
  }, [polygonsGroups]);

  return <Canvas ref={canvasRef} />;
};

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;
