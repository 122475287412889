import { getAPI } from 'auth';
import { SimulatedRankParams, SimulatedRankResponse } from './types';

export const getSimulatedRank = async (params: SimulatedRankParams) => {
  const response = await getAPI<SimulatedRankResponse>(
    '/rankinfo/rang_limier/',
    {
      params,
    }
  );
  return response.data;
};
