import { FC } from 'react';
import { MenuIconProps } from './types';

export const MenuFlashQuestions: FC<MenuIconProps> = ({ isFilled }) => {
  return (
    <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 12.875 8.535 1H20.08l-4.64 7.806H25L7.767 25l3.489-12.125H1Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...(isFilled ? { fill: 'currentColor' } : {})}
      />
    </svg>
  );
};
