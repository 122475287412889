import { FC, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { CorrectionQuestion } from 'types/correction.type';
import { QuestionHeader } from './components/QuestionHeader/QuestionHeader';
import { useClickedQuestionContext } from '../../clickedQuestionContext';
import { getQuestionCorrectionComponent } from './helpers/getQuestionCorrectionComponent';
import { RelatedLessons } from './components/RelatedLessons';
import { RelatedQuestions } from './components/RelatedQuestions';
import { useGetNumberQueryParams } from 'hooks/useGetNumberQueryParam';

interface Props {
  questionData: CorrectionQuestion;
}

export const QuestionCorrectionBlock: FC<Props> = ({ questionData }) => {
  const CorrectionBlock = getQuestionCorrectionComponent(
    questionData.question.type
  );

  const ref = useRef<HTMLDivElement>(null);
  const { clickedQuestion } = useClickedQuestionContext();

  useEffect(() => {
    if (clickedQuestion === questionData.id) {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [clickedQuestion, questionData.id]);

  const initialQuestionId = useGetNumberQueryParams('questionid');
  useEffect(() => {
    if (initialQuestionId === questionData.id) {
      setTimeout(() => {
        ref.current?.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container ref={ref}>
      <QuestionHeader questionData={questionData} />
      <CorrectionBlock questionData={questionData} />
      <RelatedLessonsContainer>
        {questionData.question.lessons.length !== 0 && (
          <RelatedLessons lessonIds={questionData.question.lessons} />
        )}
        <RelatedQuestions
          relatedQuestions={questionData.question.similar_questions}
        />
      </RelatedLessonsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const RelatedLessonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 0 16px;
`;
