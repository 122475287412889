import styled from 'styled-components/macro';
import { useShouldShowSpecialties } from 'modules/user-options/hooks/useShouldShowSpecialties';
import { useGetCartEstimatedDuration } from 'pages/selection/hooks/useGetCartEstimatedDuration';
import { useStartDpQITask } from '../../hooks/useStartDpQiTask';
import { DpQiSelectionCount } from './DpQiSelectionCount';
import { ItemsList } from '../../../../components/Item/ItemsList';
import { SpecialitiesBarChart } from './SpecialitiesBarChart';
import { TagsTypePieCharts } from './TagsTypePieCharts';
import { useShouldShowItems } from 'modules/user-options/hooks/useShouldShowItems';
import { StartButton } from '../StartButton';
import { KnowledgeRankPercentageBar } from 'components/Knowledge/KnowledgeRankPercentageBar';
import { useAdaptCounterToKnowledgeListData } from 'pages/start/hooks/useStartChartsData/useAdaptCounterToKnowledgeListData';
import { useGetDpQiSelectionStats } from 'pages/start/hooks/useStartChartsData/useGetDpQiSelectionStats';
import { Item } from 'types/item.type';
import { useMemo } from 'react';
import { itemsMocks } from '../../mocks/startMocks';
import { useTour } from '@reactour/tour';

export const DPQIView = () => {
  const { startDpQiTask, isLoading } = useStartDpQITask();
  const { isOpen } = useTour();

  const getCartEstimatedDuration = useGetCartEstimatedDuration();

  const shouldShowSpecialties = useShouldShowSpecialties(false);
  const shouldShowItems = useShouldShowItems(false);

  const selectionStats = useGetDpQiSelectionStats();

  const knowledges = useAdaptCounterToKnowledgeListData(
    selectionStats.knowledges
  );

  const items = useMemo(() => {
    return knowledges.reduce((acc: Item[], knowledge) => {
      if (knowledge && !acc.some((item) => item.item === knowledge.item.item)) {
        acc.push(knowledge.item);
      }
      return acc;
    }, []);
  }, [knowledges]);

  return (
    <>
      <DpQiSelectionCount />
      <GraphsContainer>
        <LeftSection>
          {shouldShowSpecialties ? (
            <SpecialitiesBarChart />
          ) : (
            <h4>Spécialités des épreuves masquées</h4>
          )}

          {shouldShowItems ? (
            <ItemsList items={isOpen ? itemsMocks : items} />
          ) : (
            <h4>Items des épreuves masqués</h4>
          )}

          <KnowledgeRankPercentageBar knowledges={knowledges} />
        </LeftSection>

        <PieSection>
          <TagsTypePieCharts />
        </PieSection>
      </GraphsContainer>

      <h4>Temps total de l'épreuve : {getCartEstimatedDuration('DP')}</h4>

      <StartButton
        type="button"
        onClick={startDpQiTask}
        isLoading={isLoading}
        className="start-button"
      >
        Démarrer l'entraînement DP/QI
      </StartButton>
    </>
  );
};

const GraphsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
  align-items: center;
`;

const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
  max-width: 600px;
`;

const PieSection = styled.div`
  height: 500px;
  flex-basis: 600px;
  width: 100%;
`;
