import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';
import { submitTask } from 'http/training/submit-task/api';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

export const useSubmitTask = () => {
  const mutation = useMutation(submitTask);

  const saveQuestion = async (taskId: number, onSuccess: () => void) => {
    mutation.mutate(taskId, {
      onSuccess,
      onError: (e) => {
        toast.error('Une erreur est survenue, veuillez réessayer');
        reportErrorToSentry(e);
      },
    });
  };

  return saveQuestion;
};
