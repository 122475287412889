import { QIProgressBySession } from '../entities/QIProgressBySession';
import { QIProgressBySpecialty } from '../entities/QIProgressBySpecialty';

export const getQIProgressBySpecialty = (
  progressBySession: QIProgressBySession[] | undefined,
  isUserPremium: boolean
): QIProgressBySpecialty[] | undefined => {
  if (progressBySession === undefined) {
    return undefined;
  }

  const progressBySpecialtyMap = new Map<
    string,
    Map<string, QIProgressBySession>
  >();

  for (let sessionProgress of progressBySession) {
    const sessionId = sessionProgress.collection;
    for (let question of sessionProgress.questions) {
      const spe = question.speTrigram;
      if (spe === undefined) continue;

      if (!question.isFree && !isUserPremium) {
        if (!progressBySpecialtyMap.has(spe)) {
          progressBySpecialtyMap.set(
            spe,
            new Map<string, QIProgressBySession>()
          );
        }
        continue;
      }

      if (progressBySpecialtyMap.has(spe)) {
        const speSessionMap = progressBySpecialtyMap.get(spe)!;
        if (speSessionMap.has(sessionId)) {
          const previousSessionData = speSessionMap.get(sessionId)!;
          previousSessionData.questions = [
            ...previousSessionData.questions,
            question,
          ];
          speSessionMap.set(sessionId, previousSessionData);
        } else {
          speSessionMap.set(sessionId, {
            collection: sessionProgress.collection,
            questions: [question],
          });
        }
      } else {
        progressBySpecialtyMap.set(
          spe,
          new Map<string, QIProgressBySession>().set(sessionId, {
            collection: sessionProgress.collection,
            questions: [question],
          })
        );
      }
    }
  }

  const res: QIProgressBySpecialty[] = [];

  progressBySpecialtyMap.forEach((sessionsMap, spe) => {
    res.push({
      speTrigram: spe,
      sessions: Array.from(sessionsMap.values()),
    });
  });

  return res;
};
