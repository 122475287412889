import { useItemfromKnowledgeId } from 'http/selection/get_knowledge/connector';
import { useShouldShowItems } from 'modules/user-options/hooks/useShouldShowItems';
import { FC } from 'react';
import { Item } from './Item';

interface Props {
  knowledgeId: string;
  hasBeenDone?: boolean;
}

export const ItemFromKnowledge: FC<Props> = ({ knowledgeId, hasBeenDone }) => {
  const item = useItemfromKnowledgeId(knowledgeId);
  const shouldShowItem = useShouldShowItems(hasBeenDone);

  if (!shouldShowItem || item === undefined) {
    return null;
  }

  return <Item item={item} />;
};
