import { FC, ReactNode, useEffect } from 'react';

interface ScrollToTopProps {
  children?: ReactNode;
  trigger?: string;
}

export const ScrollToTop: FC<ScrollToTopProps> = ({
  children,
  trigger,
}): JSX.Element | null => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [trigger]);

  return children as JSX.Element | null;
};

export default ScrollToTop;
