import { postAPI } from 'auth';
import { TaskRequestType } from 'types/task.type';

export const submitTask = async (taskId: number) => {
  const response = await postAPI<void, TaskRequestType>(
    '/training/submit_task',
    { task_id: taskId }
  );
  return response.data;
};
