import { Loader } from 'components/Loader/Loader';
import { useAccount } from 'pages/account/useAccount';
import {
  customLabel,
  customLabelLine,
} from 'pages/concours-blanc/components/StatisticsPage/Stats/GlobalGrades/StatPieChart';
import React from 'react';
import { ResponsiveContainer, Pie, Cell, Tooltip, PieChart } from 'recharts';
import styled, { useTheme } from 'styled-components';
import {
  StyledTooltip,
  TooltipLine,
  TooltipText,
} from 'ui/Graph/CustomTooltip';

export type SpeStatPieData = {
  id: string;
  name: string;
  value: number;
  color: string;
  order?: number;
};

export interface PieChartProps {
  data: SpeStatPieData[];
  grade: string;
}

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <StyledTooltip>
        <TooltipLine>
          <TooltipText>{`${payload[0].payload.name} : ${payload[0].payload.value} questions`}</TooltipText>
        </TooltipLine>
      </StyledTooltip>
    );
  }
  return null;
};

export const HomePieChart: React.FC<PieChartProps> = ({ data, grade }) => {
  const theme = useTheme();
  const { userOptions } = useAccount();

  if (!data) {
    return <Loader />;
  }

  const total = data.reduce((acc, curr) => acc + curr.value, 0);
  const notDone = data.find((d) => d.id === 'not_done')?.value || 0;

  const percent = 100 - Math.round((notDone / total) * 100);

  return (
    <PieSection>
      <ResponsiveContainer width="100%">
        <PieChart id="tag-pie-chart">
          <text
            x={'50%'}
            y={'47%'}
            textAnchor="middle"
            dominantBaseline="middle"
          >
            <TitleText fill={theme.colors.primary.dark}>{percent}%</TitleText>
          </text>
          <text
            x={'50%'}
            y={'55%'}
            textAnchor="middle"
            dominantBaseline="middle"
          >
            <SubTitleText blur={!userOptions?.home_see_grades}>
              {grade}/20
            </SubTitleText>
          </text>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={'47%'}
            innerRadius={'34%'}
            label={customLabel}
            labelLine={customLabelLine}
            stroke="none"
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={entry.color} />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />

          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </PieSection>
  );
};
const PieSection = styled.div`
  width: 100%;
  aspect-ratio: 1;
`;

const TitleText = styled.tspan`
  font-family: 'Urbanist';
  font-weight: 600;
  font-size: 1.25rem;
`;

const SubTitleText = styled.tspan<{ grey?: boolean; blur?: boolean }>`
  font-family: 'Urbanist';
  font-weight: 400;
  font-size: 0.875rem;
  fill: ${({ grey }) =>
    grey ? ({ theme }) => theme.colors.inactive : 'black'};
  filter: ${(props) => (props.blur ? 'blur(5px)' : 'none')};
`;
