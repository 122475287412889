import styled from 'styled-components/macro';

import { FC } from 'react';
import { CheckFull } from 'pages/correction/components/icons/CheckFull';
import { CheckDefault } from 'pages/correction/components/icons/CheckDefault';
import { CrossFull } from 'pages/correction/components/icons/CrossFull';
import { CrossDefault } from 'pages/correction/components/icons/CrossDefault';
import { QCMPropositionState } from 'types/correction.type';

interface Props {
  answer: boolean;
  state: QCMPropositionState;
  hasUserAnswered: boolean;
}

export const PropositionIcon: FC<Props> = ({
  answer,
  state,
  hasUserAnswered,
}) => {
  if (state === 'ANN' || !hasUserAnswered) {
    return <IconContainer></IconContainer>;
  }

  if (['MZ', 'PMZ'].includes(state)) {
    const isUserRight =
      (state === 'MZ' && answer === false) ||
      (state === 'PMZ' && answer === true);

    if (isUserRight) {
      return (
        <IconContainer>
          <CheckFull />
        </IconContainer>
      );
    }

    return (
      <IconContainer>
        <CrossFull />
      </IconContainer>
    );
  }

  if (['V', 'F'].includes(state)) {
    const isUserRight: boolean =
      (state === 'V' && answer === true) || (state === 'F' && answer === false);

    if (isUserRight) {
      return (
        <IconContainer>
          <CheckDefault />
        </IconContainer>
      );
    }

    return (
      <IconContainer>
        <CrossDefault />
      </IconContainer>
    );
  }

  return <IconContainer></IconContainer>;
};

const IconContainer = styled.div`
  --size: 20px;
  height: var(--size);
  width: var(--size);
  margin: 12px 8px;
  flex-shrink: 0;
`;
