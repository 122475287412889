import { CorrectionItemsQcm } from 'types/correction.type';

export const isAnswerCorrect = (
  itemCorrection: CorrectionItemsQcm,
  hasUserAnswered: boolean
): boolean => {
  const state = itemCorrection.question_item.state;
  const answer = itemCorrection.answer;

  if (!hasUserAnswered) return false;

  if (state === 'ANN') return true;

  if (answer === true) {
    if (['V', 'PMZ'].includes(state)) {
      return true;
    }
  }

  if (answer === false) {
    if (['F', 'MZ'].includes(state)) {
      return true;
    }
  }

  return false;
};
