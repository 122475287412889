import { forwardRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { SingleAnswer } from 'pages/new_training/components/QROC/SingleAnswer';
import { useDispatch } from 'react-redux';
import { setSingleAnswer } from 'store/slices/fqCurrentSlice';

interface Props {
  question: string;
  questionNumber: number;
  questionId: number;
  answer: {
    id: number;
    answer: string;
  };
  className?: string;
  anonymous: boolean;
  collection: string | undefined;
}

export const FQQROC = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const [answer, setAnswer] = useState<string>(props.answer?.answer);
  const dispatch = useDispatch();

  const handleChange = (value: string) => {
    const newAnswers = { ...props.answer, answer: value };
    setAnswer(newAnswers.answer);
    dispatch(setSingleAnswer(newAnswers));
  };

  useEffect(() => {
    if (props.answer.answer !== answer) {
      setAnswer(props.answer.answer);
    }
    // eslint-disable-next-line
  }, [props.answer.answer]);

  useEffect(() => {
    if (props.answer.answer !== answer) {
      setAnswer(props.answer.answer);
    }
    // eslint-disable-next-line
  }, [props.answer.answer]);

  useEffect(() => {
    if (props.answer.answer !== answer) {
      setAnswer(props.answer.answer);
    }
    // eslint-disable-next-line
  }, [props.answer.answer]);

  return (
    <Container>
      <StyledSingleAnswer
        answer={answer || ''}
        onChange={handleChange}
        disabled={false}
      ></StyledSingleAnswer>
    </Container>
  );
});

const Container = styled.div`
  margin-bottom: 50px;
  display: 'block';
`;

const StyledSingleAnswer = styled(SingleAnswer)``;
