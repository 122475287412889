import { FC } from 'react';
import styled from 'styled-components/macro';

import { useGetLessons } from 'modules/lessons/hooks/useGetLessons';
import { useLessonsQueryParamsContext } from 'pages/lessons/contexts/LessonsQueryParamsContext';

import { LessonsPagination } from '../LessonsPagination';
import { Error } from './TableStates/Error';
import { Header } from './Header';
import { Loading } from './TableStates/Loading';
import { Row } from './Row';
import { Cell } from './uiElements';
import { NoResults } from './TableStates/NoResults';
import { device } from 'helpers/css/responsive';
import { CorrectionThemeProvider } from 'components/CorrectionThemeProvider';

const LessonsTable: FC = () => {
  const {
    itemIds,
    knowledgesRank,
    sortOrder,
    currentPage,
    pageSize,
    debouncedSearchTerm,
  } = useLessonsQueryParamsContext();

  const {
    data: lessonsPage,
    isLoading,
    isError,
  } = useGetLessons({
    page: currentPage,
    pageSize: pageSize,
    knowledgesRank,
    itemIds,
    sortOrder,
    searchTerm: debouncedSearchTerm,
  });

  return (
    <Container>
      <CorrectionThemeProvider>
        <TableContainer>
          <Header />

          {isLoading ? (
            <Loading />
          ) : isError ? (
            <Error />
          ) : lessonsPage && lessonsPage.lessons.length === 0 ? (
            <NoResults />
          ) : (
            lessonsPage &&
            lessonsPage.lessons.length > 0 &&
            lessonsPage.lessons?.map((lesson, index) => (
              <Row lesson={lesson} index={index} key={index} />
            ))
          )}
        </TableContainer>
      </CorrectionThemeProvider>

      <LessonsPagination numberOfPages={lessonsPage?.numberOfPages} />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 10fr) minmax(90px, 3fr) minmax(90px, 3fr) minmax(
      50px,
      1fr
    );
  width: 100%;

  border: 4px solid ${({ theme }) => theme.colors.primary.light};
  border-radius: 14px;
  padding-bottom: 12px;

  --content-side-margin: 8px;
  @media ${device.desktop} {
    --content-side-margin: 20px;
  }

  // Alternated row colors

  ${Cell}:nth-of-type(8n+1), ${Cell}:nth-of-type(8n+2), ${Cell}:nth-of-type(8n+3), ${Cell}:nth-of-type(8n+4) {
    background-color: ${({ theme }) => theme.colors.primary.light};
  }

  // First cell row space with header
  ${Cell}:nth-of-type(1), ${Cell}:nth-of-type(2), ${Cell}:nth-of-type(3) , ${Cell}:nth-of-type(4) {
    margin-top: 12px;
  }

  // Rows border radius
  ${Cell}:nth-of-type(4n+1) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    margin-left: var(--content-side-margin);

    @media ${device.desktop} {
      padding-left: 28px;
    }
  }
  ${Cell}:nth-of-type(4n+4) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    margin-right: var(--content-side-margin);

    @media ${device.desktop} {
      padding-left: 14px;
    }
  }
`;

export default LessonsTable;
