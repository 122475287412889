import { FC, SVGAttributes } from 'react';
import { useTheme } from 'styled-components/macro';

type SVGNativeProps = SVGAttributes<SVGElement>;

export const CrossDefault: FC<SVGNativeProps> = (props) => {
  const theme = useTheme();

  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={10}
        cy={10}
        r={9}
        stroke={theme.colors.error.main}
        strokeWidth={2}
      />
      <path
        d="m14 6-8 8M6 6l8 8"
        stroke={theme.colors.error.main}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
