import { ItemInfos } from 'store/types';

export function areItemInfosEqual(itemInfo1: ItemInfos, itemInfo2: ItemInfos) {
  return itemInfo1.id === itemInfo2.id;
}

export function checkIfAllItemsAreSelected(
  pageItems: ItemInfos[],
  selectedItems: ItemInfos[]
): boolean {
  const areAllPageItemsSelected = pageItems.every((pageItem) =>
    selectedItems?.some((selectedItem) =>
      areItemInfosEqual(pageItem, selectedItem)
    )
  );

  return areAllPageItemsSelected;
}

export function getSelectedItemsWithoutPageItems(
  pageItems: ItemInfos[],
  selectedItems: ItemInfos[]
): ItemInfos[] {
  return selectedItems.filter((selectedItem) => {
    const isItemInCurrent = pageItems.some((pageItem) =>
      areItemInfosEqual(pageItem, selectedItem)
    );

    return !isItemInCurrent;
  });
}

export function getItemsNotAlreadyInOtherItems<TData extends ItemInfos>(
  items: TData[],
  otherItems: TData[]
): TData[] {
  return items.filter((item) => {
    const isItemNotAlreadyInOtherItems = otherItems.some((otherItem) =>
      areItemInfosEqual(item, otherItem)
    );

    return !isItemNotAlreadyInOtherItems;
  });
}
