import { FC } from 'react';
import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';

import { routes } from 'routing/constants';
import { DPBasicInformations } from 'modules/progress/general/dp/domain/entities/DPBasicInformations';

import { PastCorrectionButton } from '../../components/PastCorrectionButton';
import { usePreviewModalContext } from '../contexts/previewModalContext';
import { CartCheckbox } from '../components/CartCheckbox';
import { SpeIcon } from '../../components/SpeIcon';

interface Props {
  question: DPBasicInformations;
}

export const DPProgressRowBySession: FC<Props> = ({ question: group }) => {
  const { openModal: openPreviewModal } = usePreviewModalContext();

  const navigate = useNavigate();
  const goToCorrection = () => {
    if (group.lastCorrectionId) {
      navigate({
        pathname: routes.GROUP_CORRECTION,
        search: `?id=${group.lastCorrectionId}`,
      });
    }
  };

  return (
    <>
      <Row
        isHighlighted={group.hasBeenDone}
        onClick={() => openPreviewModal(group.id)}
      >
        <NumberCell>{group.num}</NumberCell>
        <Cell>
          <Centered>
            <SpeIcon speTrigram={group.speTrigram1} isFree={group.isFree} />
          </Centered>
        </Cell>
        <Cell>
          <Centered>
            <SpeIcon
              speTrigram={group.speTrigram2}
              isFree={group.isFree}
              shouldHideIfLocked
            />
          </Centered>
        </Cell>
        <Cell id={`past-correction-button-${group.id}`}>
          {group.hasBeenDone && (
            <PastCorrectionButton
              isFree={group.isFree}
              onClick={goToCorrection}
            />
          )}
        </Cell>
        <Cell id={`add-selection-button-${group.id}`}>
          <CartCheckbox group={group} />
        </Cell>
      </Row>
    </>
  );
};

const Row = styled.tr<{ isHighlighted: boolean }>`
  td {
    background-color: ${({ theme, isHighlighted }) =>
      isHighlighted ? theme.colors.primary.light : 'transparent'};
  }

  &:hover {
    cursor: pointer;

    --border-color: ${({ theme }) => theme.colors.primary.main};

    /*
      To draw the borders we use box-shadows because regular borders
      modify the row height and make everything move
    */
    td {
      box-shadow: inset 0 1px 0 var(--border-color),
        inset 0 -1px 0 var(--border-color);
    }

    td:first-child {
      box-shadow: inset 0 1px 0 var(--border-color),
        inset 0 -1px 0 var(--border-color), inset 1px 0 0 var(--border-color);
    }

    td:last-child {
      box-shadow: inset 0 1px 0 var(--border-color),
        inset 0 -1px 0 var(--border-color), inset -1px 0 0 var(--border-color);
    }
  }
`;

const Cell = styled.td`
  padding: 4px 12px;
  text-align: center;
  vertical-align: middle;
`;

const NumberCell = styled(Cell)`
  ${({ theme }) => theme.typography.label}
`;

const Centered = styled.div`
  display: grid;
  place-items: center;
`;
