import { useState, useEffect } from 'react';
import { RecapSpecialtiesParams } from 'http/new-home/types';
import { useQuery } from 'react-query';
import { useHomeSpecialtiesFiltersContext } from '../context/homeSpecialtiesFiltersContext';
import { MS_IN_AN_HOUR } from 'constants/date';
import { getHomeSpecialties } from 'http/new-home/api';
import { toast } from 'react-toastify';
import { getSpecialitiesList } from 'http/selection/specialities-list/api';
import { SpecialitiesListResponse } from 'http/selection/specialities-list/types';
import { useGetBooks } from './useGetBooks';
import { Options } from 'types/options.type';

export const useGetHomeSpecialties = () => {
  const {
    specialtyPageSize,
    currentSpecialtyPage,
    specialtiesSearchedItems,
    specialtyStudyTours,
    debouncedSearchTerm,
  } = useHomeSpecialtiesFiltersContext();

  const [params, setParams] = useState<RecapSpecialtiesParams>({
    search: debouncedSearchTerm,
    rounds: specialtyStudyTours.map((tour) => Number(tour.value)),
    items: specialtiesSearchedItems.map((item) => Number(item.value)),
    page_size: specialtyPageSize,
    page: currentSpecialtyPage + 1,
  });

  const getParams = () => ({
    ...params,
    search: debouncedSearchTerm,
    rounds: specialtyStudyTours.map((tour) => Number(tour.value)),
    items: specialtiesSearchedItems.map((item) => Number(item.value)),
    page_size: specialtyPageSize,
    page: currentSpecialtyPage + 1,
  });

  useEffect(() => {
    // setCurrentSpecialtyPage(0);
    setParams({
      ...getParams(),
      // We reset the page to the first one when the filters are updated
      page: 1,
    });
    // TODO add filters var
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // specialtyPageSize,
    specialtyStudyTours,
    specialtiesSearchedItems,
    debouncedSearchTerm,
    specialtyStudyTours,
  ]);

  useEffect(() => {
    setParams(getParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSpecialtyPage]);

  const { data, isLoading, isError, refetch } = useQuery(
    ['getHomeSpecialties', params],
    () => getHomeSpecialties(params),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }
  );

  return { data, isLoading, isError, refetch };
};

// request with filters, favorites = true and no pagination
export const useGetHomeFavoriteSpecialties = () => {
  const {
    // setCurrentSpecialtyPage,
    specialtiesSearchedItems,
    specialtyStudyTours,
    debouncedSearchTerm,
  } = useHomeSpecialtiesFiltersContext();

  const [params, setParams] = useState<RecapSpecialtiesParams>({
    search: debouncedSearchTerm,
    rounds: specialtyStudyTours.map((tour) => Number(tour.value)),
    items: specialtiesSearchedItems.map((item) => Number(item.value)),
    favorite: true,
  });

  const getParams = () => ({
    ...params,
    search: debouncedSearchTerm,
    rounds: specialtyStudyTours.map((tour) => Number(tour.value)),
    items: specialtiesSearchedItems.map((item) => Number(item.value)),
    favorite: true,
  });

  useEffect(() => {
    // setCurrentSpecialtyPage(0);
    setParams({
      ...getParams(),
      // We reset the page to the first one when the filters are updated
      page: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    specialtyStudyTours,
    specialtiesSearchedItems,
    debouncedSearchTerm,
    specialtyStudyTours,
  ]);

  const { data, isLoading, isError, refetch } = useQuery(
    ['getRecapFavoriteSpecialties', params],
    () => getHomeSpecialties(params),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }
  );

  return { data, isLoading, isError, refetch };
};

export const useGetSpecialties = () => {
  const fetchData = async () => {
    try {
      const results: SpecialitiesListResponse = await getSpecialitiesList();
      const promises: Options = results
        .map((currentValue) => ({
          label: `${currentValue.name}`,
          value: `${currentValue.trigram}`,
        }))
        .sort((a, b) => Number(a.value) - Number(b.value));

      const specialtiesOptions = await Promise.all(promises);
      return specialtiesOptions;
    } catch (error) {
      toast.error('Une erreur est survenue. Veuillez réessayer plus tard.');
    }
  };

  const { data: specialties } = useQuery('sorted_specialties', fetchData);
  return { specialties };
};

export const useSpecialtiesSearchBarOptions = () => {
  const { specialties } = useGetSpecialties() || {};
  const { booksOptions } = useGetBooks() || {};

  let specialtiesSearchBarOptions = [
    ...(specialties || []),
    ...(booksOptions || []),
  ];

  specialtiesSearchBarOptions = Array.from(
    new Map(
      specialtiesSearchBarOptions.map((option) => [option.label, option])
    ).values()
  );

  specialtiesSearchBarOptions.sort((a, b) => a.label.localeCompare(b.label));

  return { specialtiesSearchBarOptions };
};
