export type AdressElement = {
  city: string;
  citycode: string;
  id: string;
  label: string;
  name: string;
  postcode: string;
  street: string;
  type: string;
};

export const getGeoCodeAdress = async (
  query: string,
  type: string = 'housenumber',
  autocomplete: number = 1
): Promise<any> => {
  const url = `https://api-adresse.data.gouv.fr/search/?q=${query}&type=${type}&autocomplete=${autocomplete}`;

  const options: RequestInit = {
    method: 'GET',
    //mode: 'no-cors',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const response = await fetch(url, options);

  if (response.status !== 200) {
    throw new Error(
      `Error: ${response.status} - ${response.statusText} - ${response}`
    );
  }

  const responseData = await response.json();

  return responseData.features.map((feature: any) => {
    return {
      city: feature.properties.city,
      citycode: feature.properties.citycode,
      id: feature.properties.id,
      label: feature.properties.label,
      name: feature.properties.name,
      postcode: feature.properties.postcode ?? '',
      street: feature.properties.street,
      type: feature.properties.type,
    };
  });
};
