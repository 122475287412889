import styled from 'styled-components';
import BlueCard from 'ui/Cards/BlueCard';
import { IconContainer } from 'ui/icons/IconContainer';
import { EmptyTrophee } from 'ui/icons/EmptyTrophee';

export const WhyParticipate = () => {
  return (
    <StyledBlueCard>
      <TitleContainer>
        <IconContainer big={true}>
          <EmptyTrophee />
        </IconContainer>
        <h4>Pourquoi participer ?</h4>
      </TitleContainer>
      <TextContainer>
        <strong>Évaluez-vous</strong> à deux mois des EDN pour vérifier vos
        connaissances actuelles.
      </TextContainer>
      <TextContainer>
        <strong>Classez-vous</strong> parmi les étudiants pour chacun des 13
        groupes de spécialités.
      </TextContainer>
      <TextContainer>
        <strong>Améliorez-vous</strong> en identifiant vos points faibles et
        consolidez vos acquis.
      </TextContainer>
      <TextContainer>
        <strong>Gagnez en confiance</strong> : composez sur une plateforme
        identique à celle du CNG et au plus proche des conditions réelles
        d'examen
      </TextContainer>
    </StyledBlueCard>
  );
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

const TextContainer = styled.div``;

const StyledBlueCard = styled(BlueCard)`
  gap: 28px;
  height: fit-content;
`;
