import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import BlueCard from 'ui/Cards/BlueCard';
import { Section, SubTitle } from '../CCBStatsAndGrades';
import { GlobalGradesChart } from './GlobalGradesChart';
import { BlackButtonSwitch } from 'ui/switches/BlackButtonSwitch';
import { useSelector } from 'react-redux';

function formatData(data: number[]) {
  return data.map((item, index) => {
    return {
      rank: index + 1,
      grade: item,
    };
  });
}

export const GlobalGrades = () => {
  const { ccbStats } = useSelector((state: any) => state.ccb);

  const periodData = useMemo(() => {
    const formatedData = ccbStats && formatData(ccbStats.period_grades_global);
    return formatedData;
  }, [ccbStats]);
  const GeneralData = useMemo(() => {
    const formatedData = ccbStats && formatData(ccbStats.total_grades_global);
    return formatedData;
  }, [ccbStats]);

  const [activeDataSet, setActiveDataSet] = useState<any>(periodData);

  const handleDataSwitch = (clickedOption: string) => {
    if (clickedOption === 'period') {
      setActiveDataSet(periodData);
    }
    if (clickedOption === 'general') {
      setActiveDataSet(GeneralData);
    }
  };

  return (
    <BlueCard $bigPadding fullWidth>
      <Section vertical>
        <TopContainer>
          <SubTitle>
            Répartition des notes des participants en fonction de leur rang
          </SubTitle>
          <BlackButtonSwitch
            option1Text="Sur la période"
            onclickOption1={() => handleDataSwitch('period')}
            option2Text="Global"
            onclickOption2={() => handleDataSwitch('general')}
          />
        </TopContainer>
        <GlobalGradesChart data={activeDataSet} />
      </Section>
    </BlueCard>
  );
};

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;
