import styled, { useTheme } from 'styled-components/macro';
import { FQGeneralStatus } from './FQGeneralStatus';
import { device } from 'helpers/css/responsive';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import FQCounter from './FQCounter';
import { selectDevMode } from 'store/slices/appSlice';
import ReactJson from '@dinuac/react-json-view';
import { FlashCard, DoFQCardType } from 'types/flash.type';
import { QuestionHeaders } from 'ui/Headers/QuestionHeaders';
import { ProgressBarStat } from 'components/StatsElements/ProgressBarStats';
import {
  // selectDeck,
  selectChosenDeckInfos,
} from 'store/slices/fqCurrentSlice';
import { FQDecksInfo } from 'http/flash-questions/decks/types';

interface Props {
  card: FlashCard & DoFQCardType;
  deck: FQDecksInfo | null;
}

export const FQHeader: FC<Props> = ({ card, deck }) => {
  // TODO: rework on denominator
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const chosenDeckInfos = useSelector(selectChosenDeckInfos);
  // const deckInfo = useSelector(selectDeck);
  const theme = useTheme();
  const [clock, setClock] = useState<number>(0);
  const isDevMode = useSelector(selectDevMode);

  useEffect(() => {
    const timer = setTimeout(() => setClock((clock) => clock + 1), 1000);
    return () => clearTimeout(timer);
  }, [clock]);

  const {
    collection,
    num,
    group_type,
    type,
    specialty1,
    item,
    knowledge,
    categories,
    composition_unit,
  } = card.training_question.question;

  useEffect(() => {
    if (chosenDeckInfos && deck) {
      const numerator =
        chosenDeckInfos?.dailyCards.newCards +
        chosenDeckInfos?.dailyCards?.toRedoCards +
        chosenDeckInfos?.dailyCards.toReviewCards;
      const denominator = deck.toStudyCards + deck.newCards;
      const percentage = denominator > 0 ? (numerator / denominator) * 100 : 0;
      setProgressPercentage(percentage);
    }
  }, [chosenDeckInfos, deck]);

  return (
    <>
      <Container>
        <Left>
          <ProgressBarContainer>
            <div>Progression</div>
            <ProgressBarStat
              fillingColor={'#325397'}
              percentage={progressPercentage}
              backgroundFillingColor={theme.colors.primary.light}
              height={10}
            />
          </ProgressBarContainer>
          <FQGeneralStatus />
        </Left>
        <FQCounter />
      </Container>
      <InsideContainer>
        <QuestionHeaders
          collection={collection}
          type={group_type}
          questionNum={num}
          specialty={specialty1}
          item={item}
          knowledge={knowledge}
          category={categories[0]}
          group_type={type}
          composition={composition_unit}
        />
      </InsideContainer>
      {isDevMode && (
        <DevModeDetails>
          <ReactJson src={card} theme="monokai" />
          <summary>Details (DEV ONLY)</summary>
        </DevModeDetails>
      )}
    </>
  );
};

const DevModeDetails = styled.details`
  border: 1px solid #aaa;
  margin-top: 10px;
  border-radius: 4px;
  padding: 0.5em 0.5em 0;

  summary {
    font-weight: bold;
    color: red;
    text-transform: uppercase;
    margin: -0.5em -0.5em 0;
    padding: 0.5em;
  }

  &[open] {
    padding: 0.5em;
  }

  &[open] summary {
    border-bottom: 1px solid #aaa;
    margin-bottom: 0.5em;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;

  @media ${device.desktop} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const InsideContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  @media ${device.desktop} {
    flex-direction: row;
    gap: 0;
  }
  flex: 3;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 12px;
  margin-right: 24px;
  margin-bottom: 32px;
  min-width: 245px;
  @media ${device.desktop} {
    min-width: 475px;
  }
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media ${device.desktop} {
    flex-direction: row;
    gap: 0;
  }
`;
