import { getSessionDetailedProgressRepository } from '../../http/repository';
import { SessionDetailedProgressParams } from '../entities/SessionDetailedProgressParams';

export const getSessionDetailedProgress = (
  params: SessionDetailedProgressParams
) => {
  const repo = getSessionDetailedProgressRepository();

  return repo.getSessionDetailedProgress(params);
};
