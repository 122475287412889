import { FC, useMemo } from 'react';
import styled from 'styled-components/macro';
import { adaptQRPLCorrectionData } from './qrplCorrectionDataAdapter';
import { CrossDefault } from 'pages/correction/components/icons/CrossDefault';
import { CheckDefault } from 'pages/correction/components/icons/CheckDefault';
import { CorrectionQuestion } from 'types/correction.type';
import { Checkbox } from 'ui/Checkbox';
import { ALPHABET } from '../../../../../constants/alphabetic-order';

interface Props {
  questionData: CorrectionQuestion;
}
export const QRPLCorrectionBlock: FC<Props> = ({ questionData }) => {
  const correctionData = useMemo(
    () => adaptQRPLCorrectionData(questionData),
    [questionData]
  );

  return (
    <Container>
      {correctionData.correctedPropositions.map((proposition, index) => (
        <PropositionContainer key={index}>
          <IconContainer>
            {proposition.wasRightProposition ? (
              <CheckDefault />
            ) : (
              <CrossDefault />
            )}
          </IconContainer>
          <SubPropositionContainer
            wasRightProposition={proposition.wasRightProposition}
          >
            <RightSide>
              <CheckboxContainer>
                <Checkbox checked={proposition.answer} disabled={true} />
              </CheckboxContainer>
              <RightSideTitle>
                <div>{'Réponse ' + ALPHABET[index]}</div>
                <div>{proposition.text}</div>
              </RightSideTitle>
            </RightSide>
            <RightWrongContainer>
              {proposition.correctedAnswer === true ? 'Vrai' : 'Faux'}
            </RightWrongContainer>
          </SubPropositionContainer>
        </PropositionContainer>
      ))}
    </Container>
  );
};

const Container = styled.div`
  margin-right: 26px;
  margin-left: 26px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`;

const PropositionContainer = styled.div`
  display: flex;
  min-width: 50%;
`;

const RightSide = styled.div`
  display: flex;
`;

const RightSideTitle = styled.div`
  ${({ theme }) => theme.typography.textBody2};
  margin: auto 0px;
`;

const SubPropositionContainer = styled.div<{ wasRightProposition: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 12px 12px 12px 16px;
  width: 100%;
  min-height: 56px;
  border-radius: 8px;
  margin: auto 8px;
  background-color: ${({ theme, wasRightProposition }) =>
    wasRightProposition
      ? theme.colors.correctionCard.successMain
      : theme.colors.correctionCard.failedMain};
`;

const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 8px;
  flex-shrink: 0;
  margin: auto 0;
`;

const CheckboxContainer = styled.div`
  margin: auto 12px auto 0;
  ${({ theme }) => theme.typography.h2};
`;

const RightWrongContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background.main};
  ${({ theme }) => theme.typography.textBody2};
  width: 68px;
  height: 24px;
  border-radius: 8px;
  margin: auto 0 auto 8px;
  text-align: center;
  padding: 4px 12px;
`;
