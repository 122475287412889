import { getAPI, postAPI } from 'auth';
import { Popup } from './types';

export const getPopups = async () => {
  const response = await getAPI<Popup[]>('/popup/get');
  return response.data;
};

export const postPopup = async (popup_id: number) => {
  const response = await postAPI<void>('/popup/set_read', { popup_id });
  return response.data;
};
