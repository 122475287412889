import { AxiosError } from 'axios';
import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';
import { saveQuestionQRPL } from 'http/training/save-question-qrpl/api';
import { SaveQuestionQRPLRequestType } from 'http/training/save-question-qrpl/types';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

export const useSaveQuestionQRPL = (onSuccess: () => void) => {
  const mutation = useMutation(saveQuestionQRPL, {
    onSuccess,
  });

  const saveQuestion = async (params: SaveQuestionQRPLRequestType) => {
    await mutation.mutateAsync(params, {
      onSuccess,
      onError: (e) => {
        const error = e as AxiosError;
        if (
          error.response?.status === 401 &&
          error.response?.data === 'The question has already been saved.'
        ) {
          onSuccess();
        } else {
          toast.error('Une erreur est survenue, veuillez réessayer');
          reportErrorToSentry(e);
        }
      },
    });
  };

  return saveQuestion;
};
