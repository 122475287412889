import { FC } from 'react';

import { ContainedLoader } from 'components/ContainedLoader';

import { QIProgressYearBySession } from './QIProgressYearBySession';
import { useGetQIProgressByCompositionUnits } from 'modules/progress/general/qi/hooks/useGetQIProgressByCompositionUnits';
import { GenericErrorPlaceholder } from 'components/GenericErrorPlaceholder';

export const QIProgressBySession: FC = () => {
  const { data, isLoading, error } = useGetQIProgressByCompositionUnits();

  if (isLoading) {
    return <ContainedLoader loadingText="Veuillez patienter..." />;
  }

  if (error) {
    return <GenericErrorPlaceholder />;
  }

  if (data) {
    return (
      <>
        {data.map((progress) => {
          if (Object.keys(progress.compositionUnits).length === 0) {
            return null;
          }
          return (
            <QIProgressYearBySession progress={progress} key={progress.name} />
          );
        })}
      </>
    );
  }

  return <div>Un problème est survenu</div>;
};
