import { useDispatch } from 'react-redux';

import { updateItemSelection as updateDPItems } from 'store/slices/dpTableSlice';
import { updateItemSelection as updateLCAItems } from 'store/slices/lcaTableSlice';
import { updateItemSelection as updateQIItems } from 'store/slices/qiTableSlice';

export const useResetSelection = () => {
  const dispatch = useDispatch();

  const resetAll = () => {
    dispatch(updateDPItems([]));
    dispatch(updateLCAItems([]));
    dispatch(updateQIItems([]));
  };

  const resetDPQI = () => {
    dispatch(updateDPItems([]));
    dispatch(updateQIItems([]));
  };

  const resetLCA = () => {
    dispatch(updateLCAItems([]));
  };

  return { resetAll, resetDPQI, resetLCA };
};
