import { FC } from 'react';
import styled from 'styled-components/macro';

import { useShouldShowSpecialties } from 'modules/user-options/hooks/useShouldShowSpecialties';

import { Specialty } from './Specialty';

interface Props {
  trigram1: string | null;
  trigram2?: string | null;
  hasItemBeenDone: boolean;
}

export const Specialities: FC<Props> = ({
  trigram1,
  trigram2,
  hasItemBeenDone,
}) => {
  const hasTwoSpe = !!(trigram1 && trigram2);

  const shouldShowSpecialties = useShouldShowSpecialties(hasItemBeenDone);

  if (!shouldShowSpecialties) {
    return null;
  }
  return (
    <Container>
      {trigram1 && <Specialty trigram={trigram1} forceSmall={hasTwoSpe} />}
      {hasTwoSpe && <Specialty trigram={trigram2} forceSmall />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 14px;
`;
