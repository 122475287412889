import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Options } from 'types/options.type';
import { updateTypeFilter } from 'store/slices/selectionFiltersSlice';
import { RootState } from 'store';

import { GenericSortFilterHeader } from './GenericSortFilterHeader';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';
import typeFilterOptions from 'constants/filters';

interface Props {
  isSub?: boolean;
}

export const TypeHeader: FC<Props> = ({ isSub }) => {
  const filterOptions: Options = typeFilterOptions;

  const dispatch = useDispatch();

  // TODO: uncomment this when the user will be able to use this feature
  const isUserFree = useIsUserFree();

  // TODO: uncomment this when the user will be able to use this feature
  const selectedFilters = useSelector(
    (state: RootState) => state.selectionFilters.type
  );

  const setSelectedFilters = (items: string[]) => {
    dispatch(updateTypeFilter(items));
  };

  return (
    <GenericSortFilterHeader
      columnName="Type"
      // TODO: uncomment this when the user will be able to use this feature
      selectedFilters={selectedFilters}
      // selectedFilters={['QCM']}
      setSelectedFilters={setSelectedFilters}
      filterOptions={filterOptions}
      isSub={isSub}
      disableSort
      sortColumn="type"
      // TODO: uncomment this when the user will be able to use this feature
      disabled={isUserFree}
    />
  );
};
