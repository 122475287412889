import { FC } from 'react';
import styled from 'styled-components/macro';
import { Tooltip } from 'components/Tooltips/Tooltip';

interface Props {
  text: string;
}

export const ExclamationPointSquare: FC<Props> = ({ text }) => {
  return (
    <Tooltip content={text}>
      <WarningSign>!</WarningSign>
    </Tooltip>
  );
};

const WarningSign = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.error.main};
  color: ${({ theme }) => theme.colors.error.main};
`;
