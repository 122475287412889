import { Dispatch } from '@reduxjs/toolkit';

import { reset as resetDPTableSelection } from 'store/slices/dpTableSlice';
import { reset as resetQITableSelection } from 'store/slices/qiTableSlice';
import { reset as resetLCATableSelection } from 'store/slices/lcaTableSlice';
import { reset as resetProgressSelection } from 'store/slices/progressSlice';
import { resetFilters } from 'store/slices/selectionFiltersSlice';
import { reset as resetSelection } from 'store/slices/selectionSlice';
import { reset as resetStartCart } from 'store/slices/startCartSlice';
import { reset as resetDecksTable } from 'store/slices/decksTableSlice';
import { reset as resetFQTable } from 'store/slices/fqTableSlice';
import { reset as resetFQCurrent } from 'store/slices/fqTableSlice';
import { reset as resetApp } from 'store/slices/appSlice';

export const resetAllReducers = (dispatch: Dispatch<any>) => {
  dispatch(resetDPTableSelection());
  dispatch(resetQITableSelection());
  dispatch(resetLCATableSelection());
  dispatch(resetProgressSelection());
  dispatch(resetFilters());
  dispatch(resetSelection());
  dispatch(resetStartCart());
  dispatch(resetDecksTable());
  dispatch(resetFQTable());
  dispatch(resetFQCurrent());
  dispatch(resetApp());
};
