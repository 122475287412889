import { StylesConfig } from 'react-select';
import { useTheme } from 'styled-components';
import { IsMulti } from 'ui/Dropdown/dropdownStyles';

import { Option } from './ViewDropdown';

export const useStyles = () => {
  const theme = useTheme();

  const selectStyles: StylesConfig<Option, IsMulti> = {
    option: (provided, state) => {
      const style = {
        ...provided,
        color: theme.colors.primary.dark,
        backgroundColor: 'transparent',
        padding: '8px',
        '&:hover': {
          backgroundColor: theme.colors.primary.light,
        },
      };
      if (state.isSelected) {
        style.backgroundColor = theme.colors.primary.main;
      }
      if (state.isFocused) {
        style.backgroundColor = theme.colors.primary.light;
      }
      return style;
    },
    indicatorsContainer: (provided, _) => {
      const style = {
        ...provided,
        color: theme.colors.primary.dark,
      };
      return style;
    },
    indicatorSeparator: (_, __) => ({}),
    control: (provided, state) => {
      const style = {
        ...provided,
        height: '100%',
        backgroundColor: theme.colors.background.paper,
        boxShadow: state.isFocused ? 'none' : 'none',
        '&:hover': {},
        paddingLeft: '5px',
        borderRadius: '8px',
        minWidth: '150px',
        borderColor: theme.colors.primary.dark,
      };

      return style;
    },
    dropdownIndicator: (provided, state) => {
      const style = {
        ...provided,
        transition: 'all .2s ease',
        marginRight: '8px',
        marginLeft: '8px',
      };
      if (state.selectProps.menuIsOpen) {
        style.transform = 'rotate(180deg)';
      }

      return style;
    },
    menu: (provided, state) => {
      const style = {
        ...provided,
        padding: '8px',
        backgroundColor: theme.colors.background.paper,
        borderRadius: '8px',
      };
      return style;
    },
    container: (provided, _) => {
      const style = {
        ...provided,
        height: '100%',
        cursor: 'pointer',
      };
      return style;
    },
    placeholder: (provided, _) => {
      const style = {
        ...provided,
        fontStyle: 'italic',
        fontWeight: 300,
      };
      return style;
    },
    singleValue: (provided, _) => ({
      ...provided,
      color: theme.colors.primary.dark,
    }),
  };

  return selectStyles;
};
