import { getAPI, patchAPI } from 'auth';
import {
  NewRecapItem,
  NewRecapSpecialty,
  PaginatedResponse,
  RecapGrades,
  RecapItemsParams,
  RecapSpecialtiesParams,
  patchHomeSpecialtyParams,
  patchHomeItemParams,
  RecapSpecialtyDetail,
  RecapItemDetail,
} from './types';

export const patchHomeItem = async (params: patchHomeItemParams) => {
  const response = await patchAPI<NewRecapItem>('/recap/item/update', params);
  return response.data;
};

export const getHomeItems = async (
  params: RecapItemsParams
): Promise<PaginatedResponse<NewRecapItem>> => {
  const response = await getAPI<PaginatedResponse<NewRecapItem>>(
    '/recap/items',
    {
      params,
    }
  );
  return response.data;
};

export const getHomeItem = async (item: string) => {
  const response = await getAPI<RecapItemDetail>('/recap/item/detail', {
    params: {
      item,
    },
  });
  return response.data;
};

export const getRecapSession = async (collection: string) => {
  const response = await getAPI<RecapGrades>('/recap/session', {
    params: { collection },
  });
  return response.data;
};

export const getRecapSessionAnnals = async () => {
  const response = await getAPI<RecapGrades>('/recap/session/annals', {});
  return response.data;
};

export const getHomeSpecialties = async (
  params: RecapSpecialtiesParams
): Promise<PaginatedResponse<NewRecapSpecialty>> => {
  const response = await getAPI<PaginatedResponse<NewRecapSpecialty>>(
    '/recap/specialties',
    {
      params,
    }
  );
  return response.data;
};

export const getHomeSpecialty = async (specialty: string) => {
  const response = await getAPI<RecapSpecialtyDetail>(
    '/recap/specialty/detail',
    {
      params: { specialty },
    }
  );
  return response.data;
};

export const patchHomeSpecialty = async (params: patchHomeSpecialtyParams) => {
  const response = await patchAPI<RecapGrades>(
    '/recap/specialty/update',
    params
  );
  return response.data;
};
