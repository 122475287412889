import { adaptCounterToTypeChartData } from 'pages/start/helpers/adaptCounterToTypeChartData';
import { useAdaptCounterToTagChartData } from 'pages/start/hooks/useStartChartsData/useAdaptCounterToTagChartData';
import { useGetDpQiSelectionStats } from 'pages/start/hooks/useStartChartsData/useGetDpQiSelectionStats';
import { FC, useMemo } from 'react';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts';

export interface PieChartData {
  name: string;
  value: number;
}

export interface TagPieChartData extends PieChartData {
  shortName: string;
}

interface Props {}

const r2cColors = [
  '#F47A1F',
  '#FDBB2F',
  '#377B2B',
  '#7AC142',
  '#007CC3',
  '#00529B',
];

const tagsColors = [
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#ffa600',
];

export const TagsTypePieCharts: FC<Props> = () => {
  const selectionStats = useGetDpQiSelectionStats();
  const adaptCounterToTagChartData = useAdaptCounterToTagChartData();
  const tagData = useMemo(
    () => adaptCounterToTagChartData(selectionStats.tags),
    [adaptCounterToTagChartData, selectionStats.tags]
  );
  const r2cData = useMemo(
    () => adaptCounterToTypeChartData(selectionStats.types),
    [selectionStats.types]
  );

  return (
    <ResponsiveContainer width="100%">
      <PieChart width={600} height={500} id="tag-pie-chart">
        {/* <text
          x={295}
          y={140}
          textAnchor="middle"
          dominantBaseline="middle"
          fontWeight={600}
          fontSize={25}
          fill={theme.colors.primary.dark}
        >
          R2C
        </text> */}
        <Pie
          data={r2cData}
          dataKey="value"
          cx="50%"
          cy="50%"
          outerRadius={70}
          label={(entry) => entry.name}
        >
          {r2cData.map((entry, index) => (
            <Cell key={index} fill={r2cColors[index % r2cColors.length]} />
          ))}
        </Pie>

        {/* <text
          x={295}
          y={15}
          textAnchor="middle"
          dominantBaseline="middle"
          fontWeight={600}
          fontSize={26}
          fill={theme.colors.primary.dark}
        >
          TAG
        </text> */}
        <Pie
          data={tagData}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={150}
          outerRadius={210}
          label={(entry) => entry.shortName}
          startAngle={90}
          endAngle={90 - 360}
        >
          {tagData.map((entry, index) => (
            <Cell
              key={index}
              fill={
                entry.name === 'Pas de tag'
                  ? 'transparent'
                  : tagsColors[index % tagsColors.length]
              }
            />
          ))}
        </Pie>
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  );
};
