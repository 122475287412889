import { FC, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { DotPointer } from 'ui/DotWithText';
import { QZPProposition } from './QZPProposition';
import { ShapeCanvas } from './ShapeCanvas/ShapeCanvas';
import { PropositionCorrection } from './types';
import { adaptQZPCorrectionData } from './qzpCorrectionDataAdapter';
import { device } from 'helpers/css/responsive';
import { HALF_DOT_WIDTH } from 'pages/new_training/components/QZP/SingleAnswer';
import { CorrectionQuestion } from 'types/correction.type';

const computeUpdatedCorrectionSelection = (
  list: PropositionCorrection[],
  toggledCorrection: PropositionCorrection
) => {
  if (list.map((correction) => correction.id).includes(toggledCorrection.id)) {
    return list.filter((correction) => correction.id !== toggledCorrection.id);
  } else {
    return [...list, toggledCorrection];
  }
};

interface Props {
  questionData: CorrectionQuestion;
}

export const QZPCorrectionBlock: FC<Props> = ({ questionData }) => {
  const correctionData = useMemo(
    () => adaptQZPCorrectionData(questionData),
    [questionData]
  );

  const [displayedDotsData, setDisplayedArrowData] = useState<
    PropositionCorrection[]
  >(correctionData.propositionsCorrections);
  const [displayedShapesData, setDisplayedShapesData] = useState<
    PropositionCorrection[]
  >(correctionData.propositionsCorrections);

  const toggleDisplayedDots = (toggledCorrection: PropositionCorrection) => {
    setDisplayedArrowData(
      computeUpdatedCorrectionSelection(displayedDotsData, toggledCorrection)
    );
  };

  const toggleDisplayedShapes = (toggledCorrection: PropositionCorrection) => {
    setDisplayedShapesData(
      computeUpdatedCorrectionSelection(displayedShapesData, toggledCorrection)
    );
  };

  const displayedDots = useMemo(
    () => displayedDotsData.map((correction) => correction.userInput),
    [displayedDotsData]
  );

  const displayedShapes = useMemo(
    () => displayedShapesData.map((correction) => correction.correctionShapes),
    [displayedShapesData]
  );

  return (
    <Container>
      <ImageArrowsContainer>
        <ImageContainer>
          <Image src={correctionData.imageUrl} />
          {displayedDots.map((dot, index) => (
            <ArrowContainer
              relativeX={dot.x * 100}
              relativeY={dot.y * 100}
              key={dot.text}
            >
              {dot.y !== 1.08 && <DotPointer color={dot.color} index={index} />}
            </ArrowContainer>
          ))}
          <ShapeCanvas polygonsGroups={displayedShapes} />
        </ImageContainer>
      </ImageArrowsContainer>

      <PropositionsContainer>
        {correctionData.propositionsCorrections.map((correction, index) => (
          <QZPProposition
            commentary={correction.commentary}
            dotIndex={index + 1}
            dotColor={correction.userInput.color}
            hasUserAnswered={correction.userInput.y !== 1.08}
            isUserRight={correction.isUserRight}
            label={correction.text}
            toggleArrow={() => toggleDisplayedDots(correction)}
            toggleShape={() => toggleDisplayedShapes(correction)}
            isArrowSelected={displayedDotsData
              .map((c) => c.id)
              .includes(correction.id)}
            isShapeSelected={displayedShapesData
              .map((c) => c.id)
              .includes(correction.id)}
            key={correction.id}
          />
        ))}
      </PropositionsContainer>
    </Container>
  );
};

const Container = styled.div`
  margin: 0 28px;
  padding: 16px;

  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media ${device.desktop} {
    flex-wrap: nowrap;
  }
`;

const ImageArrowsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: start;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
`;

const ArrowContainer = styled.div<{ relativeX: number; relativeY: number }>`
  position: absolute;
  top: calc(${({ relativeY }) => relativeY}% - ${HALF_DOT_WIDTH}px);
  left: calc(${({ relativeX }) => relativeX}% - ${HALF_DOT_WIDTH}px);
`;

const PropositionsContainer = styled.div`
  display: flex;
  flex: 0.5;
  height: 100%;
  overflow-y: auto;
  gap: 16px;
  flex-direction: row;
  flex-wrap: wrap;

  @media ${device.desktop} {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`;
