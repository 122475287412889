import styled from 'styled-components/macro';

import { useThemeVariants } from 'hooks/useThemeVariants';
import { ThemeSwitchSun } from 'ui/icons/ThemeSwitchSun';

export const ThemeToggler = () => {
  const { themeToggler } = useThemeVariants();

  return (
    <ThemeSwitchButton onClick={themeToggler}>
      <ThemeSwitchSun />
    </ThemeSwitchButton>
  );
};

const ThemeSwitchButton = styled.button`
  background: none;
  border: none;
  padding: 0;

  cursor: pointer;

  color: ${({ theme }) => theme.colors.primary.dark};

  height: 36px;
  width: 36px;
`;
