import { FC, useState, useMemo } from 'react';
import { KnowledgeRank } from 'components/Knowledge/KnowledgeRank';
import { SubjectBlock } from './SubjectBlock';
import styled from 'styled-components';
import { QuestionModal } from '../QuestionModal';
import { KnowledgeCompass } from 'http/compass/types';
import { useGetKnowledgeQuestions } from '../../../newHome/hooks/useGetKnowledgeQuestions';
import { SimilarQuestion } from 'types/correction.type';

interface KnowledgeBlockProps {
  knowledgedata: KnowledgeCompass;
}

export const KnowledgeBlock: FC<KnowledgeBlockProps> = ({ knowledgedata }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shownQuestion, setShownQuestion] = useState<
    SimilarQuestion | undefined
  >();
  const { relatedQuestions } = useGetKnowledgeQuestions(
    knowledgedata.knowledge
  );

  const currentQuestionIndex = useMemo(() => {
    const currentQuestionIndex = relatedQuestions?.findIndex(
      (q) => q.id === shownQuestion?.id
    );
    if (!currentQuestionIndex) return 0;
    return currentQuestionIndex;
  }, [relatedQuestions, shownQuestion]);

  const canNextQuestion = useMemo(() => {
    if (relatedQuestions)
      return currentQuestionIndex < relatedQuestions?.length - 1;
  }, [currentQuestionIndex, relatedQuestions]);

  const canPreviousQuestion = useMemo(() => {
    return currentQuestionIndex > 0;
  }, [currentQuestionIndex]);

  const nextQuestion = () => {
    if (canNextQuestion) {
      if (relatedQuestions)
        setShownQuestion(relatedQuestions[currentQuestionIndex + 1]);
    }
  };

  const previousQuestion = () => {
    if (canPreviousQuestion) {
      if (relatedQuestions)
        setShownQuestion(relatedQuestions[currentQuestionIndex - 1]);
    }
  };

  const openModal = () => setIsModalOpen(true);
  // eslint-disable-next-line
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <KnowledgeBlockContainer
        id={`astrolabe-knowledge-${knowledgedata.knowledge}`}
      >
        <KnowledgeInfoHeader>
          <KnowledgeTitle>{knowledgedata.type}</KnowledgeTitle>
          <KnowledgeRank
            rank={knowledgedata.rank}
            label={knowledgedata.label}
          />
        </KnowledgeInfoHeader>
        <BodyContainer className="knowledge-body">
          <p>{knowledgedata.label}</p>
          {knowledgedata.lessons.map(
            (lesson) =>
              lesson.title &&
              lesson && <SubjectBlock subject={lesson} key={lesson.title} />
          )}
        </BodyContainer>
        {relatedQuestions && relatedQuestions.length > 0 && (
          <AvailableQuestion
            isActive={!!(relatedQuestions && relatedQuestions.length > 0)}
            onClick={() => {
              setShownQuestion(
                relatedQuestions ? relatedQuestions[0] : undefined
              );
              openModal();
            }}
            className="available-questions"
          >
            {`Voir les ${relatedQuestions.length} questions`}
          </AvailableQuestion>
        )}
      </KnowledgeBlockContainer>

      {shownQuestion && (
        <QuestionModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          question={shownQuestion}
          canNextQuestion={canNextQuestion ?? false}
          canPreviousQuestion={canPreviousQuestion}
          nextQuestion={nextQuestion}
          previousQuestion={previousQuestion}
        />
      )}
    </>
  );
};

const KnowledgeBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.primary.light};
  margin-top: 4px;
`;

const KnowledgeInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const KnowledgeTitle = styled.div`
  text-decoration: underline;
  text-decoration-thickness: 1px;
`;

const AvailableQuestion = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 28px;
  border-radius: 12px;
  font-size: 16px;
  background-color: ${({ isActive, theme }) =>
    isActive
      ? theme.colors.compass.dark
      : ({ theme }) => theme.colors.inactive};
  color: ${({ theme }) => theme.colors.compass.light};
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
  font-size: 16px; ;
`;
