import { FC } from 'react';
import styled from 'styled-components/macro';

import { RadioTexts } from 'ui/RadioTexts/RadioTexts';
import { TextBody2 } from 'ui/TextBody2';
import { Paginator as Pagination } from 'components/Pagination/Paginator';

import { useLessonsQueryParamsContext } from 'pages/lessons/contexts/LessonsQueryParamsContext';

interface Props {
  numberOfPages?: number;
}

export const LessonsPagination: FC<Props> = ({ numberOfPages }) => {
  const { currentPage, pageSize, setCurrentPage, setPageSize } =
    useLessonsQueryParamsContext();

  return (
    <OptionsBottomLine>
      <Pagination
        pageCount={numberOfPages ?? 0}
        forcePage={numberOfPages ? currentPage : -1}
        onPageChange={(p) => setCurrentPage(p.selected)}
      />

      <PageSizeContainer>
        <TextBody2>Nombre d'éléments par page :</TextBody2>
        <RadioTexts
          small
          options={[
            { label: '20', value: 20 },
            { label: '50', value: 50 },
            { label: '100', value: 100 },
          ]}
          value={pageSize}
          onChange={setPageSize}
        />
      </PageSizeContainer>
    </OptionsBottomLine>
  );
};

const OptionsBottomLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
  padding: 12px 4px;
`;

const PageSizeContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: baseline;
`;
