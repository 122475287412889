export interface ContactConfirmationRoutingState {
  title: string;
  subtitle: string;
}

export const isContactConfirmationRoutingState = (
  state: unknown
): state is ContactConfirmationRoutingState => {
  return (
    typeof (state as ContactConfirmationRoutingState)?.title === 'string' &&
    typeof (state as ContactConfirmationRoutingState)?.subtitle === 'string'
  );
};
