import { FC, useState } from 'react';
import styled from 'styled-components/macro';
import { ArrowUp } from 'pages/correction/components/icons/ArrowUp';
import { ArrowDown } from 'pages/correction/components/icons/ArrowDown';

interface Props {
  header: number;
  rows: string[][];
  index: number;
}

export const QrocAnswerContainer: FC<Props> = ({ header, rows, index }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ColumnHeader
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <div>Categorie {index + 1}</div>
        <Left>
          ({header} point)
          <ArrowContainer>
            {isOpen ? <ArrowUp /> : <ArrowDown />}
          </ArrowContainer>
        </Left>
      </ColumnHeader>
      {!isOpen && (
        <AnswerContainer>{rows[index].length} réponses</AnswerContainer>
      )}
      {isOpen &&
        rows[index].map((keyword) => (
          <AnswerContainer key={keyword}>{keyword}</AnswerContainer>
        ))}
    </>
  );
};

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const ArrowContainer = styled.div`
  height: 8px;
  width: 16px;
  margin: 8px 16px;
  flex-shrink: 0;
`;

const ColumnHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const AnswerContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.light};
  border-radius: 8px;
  padding: 12px 16px;
  min-height: 44px;
`;
