import { DeckSortColumns } from 'http/flash-questions/decks/types';
import { GroupSortColumns } from 'http/selection-group/types';
import { QISortColumns } from 'http/selection-qi/types';
import { SortOptions } from 'components/Filters/constants';
import { CCBStatsColumns } from 'http/ccb/type';

export interface SortableState {
  sortOrder?: SortOptions;
  sortColumn?: string;
}

export interface ItemInfos {
  id: number;
  type: string;
  number_of_questions?: number;
}

export enum Status {
  IDLE = 'idle',
  PENDING = 'pending',
  RESOLVED = 'resolved',
  REJECTED = 'rejected',
}

export interface SelectableState {
  selectedItemsInfos: ItemInfos[];
}

export interface OpenableRowState {
  openedRowId?: number;
}

export type SortColumns =
  | QISortColumns
  | GroupSortColumns
  | DeckSortColumns
  | CCBStatsColumns;

export interface SortByPayload {
  sortColumn: SortColumns;
  order: SortOptions;
}
