import { DPProgressBySession } from '../domain/entities/DPProgressBySession';
import { ProgressGeneralDPResponseHttp } from './types';

export const mapGeneralProgressDPHttpToDomain = (
  progress: ProgressGeneralDPResponseHttp[]
): DPProgressBySession[] => {
  try {
    return progress.map((session) => ({
      collection: session.collection,
      groups: session.groups.map((rawQuestion) => ({
        id: rawQuestion.id,
        mode: rawQuestion.mode,
        num: rawQuestion.num,
        compositionUnit: rawQuestion.composition_unit,
        speTrigram1: rawQuestion.specialty1 ?? undefined,
        speTrigram2: rawQuestion.specialty2 ?? undefined,
        lastCorrectionId: rawQuestion.last_training ?? undefined,
        hasBeenDone: Boolean(rawQuestion.last_training),
        isFree: rawQuestion.is_free,
      })),
    }));
  } catch (e) {
    throw new Error(
      "Error in general progress dp mapping, unexpected response from '/progress/general/dp':\n " +
        e
    );
  }
};
