import { getAPI } from 'auth';
import { Book, ItemKnowledgesBooks } from './types';
import { SimilarQuestion } from 'types/correction.type';

export const getCompassBooks = async () => {
  const response = await getAPI<Book[]>('/compass/books', {});
  return response.data;
};

export const getCompassItems = async (itemId: number) => {
  const response = await getAPI<ItemKnowledgesBooks>('/compass/items', {
    params: { item: itemId },
  });
  return response.data;
};

export const getKnowledgeQuestions = async (knowledgeId: string) => {
  const response = await getAPI<SimilarQuestion[] | []>(
    '/compass/knowledge_questions/',
    { params: { knowledge: knowledgeId } }
  );
  return response.data;
};
