import { NumberCounter } from './types';
import { useGetKnowledgeMapById } from 'modules/knowledge/hooks/useGetKnowledgeMapById';

export const useAdaptCounterToKnowledgeListData = (
  knowledgeCounter: NumberCounter
) => {
  const knowledgeIds = Object.keys(knowledgeCounter);
  const mapKnowledgeIdToKnowledge = useGetKnowledgeMapById(knowledgeIds);

  if (mapKnowledgeIdToKnowledge === undefined || knowledgeIds.length === 0) {
    return [];
  }

  const supportedKnowledge = Object.keys(mapKnowledgeIdToKnowledge);

  const selectedItems1 = knowledgeIds.filter((knowledgeIds) =>
    supportedKnowledge.includes(knowledgeIds)
  );

  const selectedItems = selectedItems1.map(
    (itemId) => mapKnowledgeIdToKnowledge[itemId]
  );

  return selectedItems;
};
