import { forwardRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { DotPointer } from 'ui/DotWithText';
import { setQZPAnswer } from 'store/slices/fqCurrentSlice';
import { buildURL } from 'auth/authentifiedHttpCalls';
import QZPModal from 'pages/new_training/components/QZP/QZPModal';
import { useAtom } from 'jotai';
import { isQZPModalOpenAtom } from 'pages/new_training/atoms';
import { getQZPColors } from 'pages/new_training/components/QZP/qzpColors';
import { HALF_DOT_WIDTH } from 'pages/new_training/components/QZP/SingleAnswer';
import { SaveQuestionQZPRequestType } from 'http/training/save-question-qzp/types';
import { useSaveQuestionQZP } from 'pages/new_training/hooks/saveHooks/useSaveQZP';

export interface Props {
  questionId: number;
  questionNumber: number;
  answer: {
    id: number;
    question_item: {
      image: string;
      labels: Array<string>;
    };
    answer: {
      [key: string]: {
        x: number;
        y: number;
      };
    };
  };
  saved: boolean;
  show: boolean;
  isQI: boolean;
  shouldUpdateArrowsPositions?: boolean;
  anonymous: boolean;
  collection: string | undefined;
  onSave(): void;
}

export const FQQZPItem = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const dispatch = useDispatch();
  const [answer, setAnswer] = useState<Props['answer']>(props.answer);
  const answerRef = useRef<Props['answer']>(props.answer);
  const [responseModal, setResponseModal] = useAtom(
    isQZPModalOpenAtom(props.questionId)
  );

  const saveQuestion = useSaveQuestionQZP(props.onSave);

  const onDragStop = (
    index: number,
    relativeDistances: { x: number; y: number }
  ) => {
    // Create a shallow copy of the answer object
    const newAnswer = { ...answer };

    // Create a shallow copy of the nested answer object
    const newAnswerAnswer = { ...newAnswer.answer };

    // Update the nested answer object with the new relative distances
    newAnswerAnswer[newAnswer.question_item.labels[index]] = relativeDistances;

    // Update the newAnswer object with the modified nested answer object
    newAnswer.answer = newAnswerAnswer;

    // Set the state with the new answer object
    setAnswer(newAnswer);
  };

  useEffect(() => {
    setAnswer(props.answer);
  }, [props.answer]);

  const handleSaveQuestion = () => {
    if (responseModal) {
      const params: SaveQuestionQZPRequestType = {
        id: props.questionId,
        answers: {
          id: answer.id,
          answer: answer.answer,
        },
      };
      saveQuestion(params);
      dispatch(setQZPAnswer(params));
      answerRef.current = answer;
    }
    answerRef.current = answer;
  };

  if (!answer) return null;

  const colors = getQZPColors(answer.question_item?.labels.length);

  return (
    <Container ref={ref} show={props.show}>
      <ImageContainer>
        <PreviewImage
          src={buildURL('/media/' + props.answer.question_item.image)}
          alt="Question zone à pointer"
          onClick={() => setResponseModal(true)}
        />
        {Object.keys(answer.answer).map((key) => (
          <ArrowContainer
            relativeX={answer.answer[key].x * 100}
            relativeY={answer.answer[key].y * 100}
            key={key}
          >
            {answer.answer[key].y !== 1.08 && (
              <StyledDotPointer
                color={colors[props.answer.question_item.labels.indexOf(key)]}
                index={props.answer.question_item.labels.indexOf(key)}
              />
            )}
          </ArrowContainer>
        ))}
        {responseModal && (
          <QZPModal
            onClose={() => {
              handleSaveQuestion();
              setResponseModal(false);
            }}
            answer={answer}
            onDragStop={onDragStop}
            shouldUpdateArrowsPositions={props.shouldUpdateArrowsPositions}
            isQI={props.isQI}
            saved={props.saved}
          />
        )}
      </ImageContainer>
      <ImageCallToAction>(Cliquez sur l'image pour répondre)</ImageCallToAction>
    </Container>
  );
});

const Container = styled.div<{ show?: boolean }>`
  margin-bottom: 50px;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;
const PreviewImage = styled.img`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  margin: 2rem auto 0 auto;
`;
const ImageContainer = styled.div`
  position: relative;
  padding: 20px;
`;

const ImageCallToAction = styled.p`
  margin: 0;
  font-style: italic;
  text-align: center;
  font-size: 0.9rem;
`;
const ArrowContainer = styled.div<{ relativeX: number; relativeY: number }>`
  position: absolute;
  top: calc(${({ relativeY }) => relativeY}% - ${HALF_DOT_WIDTH}px);
  left: calc(${({ relativeX }) => relativeX}% - ${HALF_DOT_WIDTH}px);
`;

const StyledDotPointer = styled(DotPointer)`
  z-index: 1;
`;
