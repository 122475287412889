import { SubmitHandler, useForm } from 'react-hook-form';

import { useGoToAuthConfirmation } from 'pages/auth-confirmation/hooks/useGoToAuthConfirmation';
import { requestDeleteAccount } from 'http/delete-account/api';

export const useDeleteAccountForm = () => {
  const rhf = useForm();

  return {
    ...rhf,
  };
};

export const useDeleteAccountFormSubmit = ({
  onFetch,
  afterFetch,
}: {
  onFetch: () => void;
  afterFetch: () => void;
}) => {
  const goToAuthConfirmation = useGoToAuthConfirmation();

  const onChangeEmailFormSubmit: SubmitHandler<never> = async () => {
    try {
      onFetch();
      await requestDeleteAccount();
      goToAuthConfirmation({
        title: 'Votre demande a bien été envoyée',
        subtitle:
          "Notez qu'il y a toujours un délai entre votre demande et la réelle suppression de votre compte",
      });
    } finally {
      afterFetch();
    }
  };

  return onChangeEmailFormSubmit;
};
