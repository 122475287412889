import { useMemo } from 'react';

import { useGetSpecialtiesRankInfos } from 'http/selection/specialities-list/connectors';

import { getQIProgressBySpecialty } from '../domain/useCases/getQIProgressBySpecialty';
import { useGetQIProgressBySession } from './useGetQIProgressBySession';
import { sortProgressBySpecialty } from '../../shared/useCases/sortProgressBySpecialty';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';

export const useGetQIProgressBySpecialty = () => {
  const progressBySessionQuery = useGetQIProgressBySession();
  const speRankInfos = useGetSpecialtiesRankInfos();
  const { isUserPremium } = useIsUserPremium();

  const progressBySpe = useMemo(() => {
    const progressBySpe = getQIProgressBySpecialty(
      progressBySessionQuery.data,
      isUserPremium
    );
    return sortProgressBySpecialty(progressBySpe, speRankInfos);
  }, [isUserPremium, progressBySessionQuery.data, speRankInfos]);

  return {
    ...progressBySessionQuery,
    data: progressBySpe,
  };
};
