import { ExtendedLessonPage, LessonPage } from 'types/lesson.type';
import { ExtendedLesson } from 'types/lesson.type';
import { Item } from 'types/item.type';
import { Knowledge } from 'types/knowledge.type';

export const getLessonPage = (
  lessonPage: LessonPage
): ExtendedLessonPage | undefined => {
  if (lessonPage === undefined) {
    return undefined;
  }

  const reformatedLesson: ExtendedLesson[] = lessonPage.lessons.map(
    (lesson) => {
      const formattedItem: Item[] = lesson.knowledges.reduce(
        (acc: Item[], knowledge: Knowledge) => {
          const isItemAlreadyIn = acc.some(
            (accItem) => accItem.item === knowledge.item.item
          );
          if (!isItemAlreadyIn) {
            return [...acc, knowledge.item];
          }

          return acc;
        },
        []
      );

      return {
        title: lesson.title,
        content: lesson.content,
        lesson: lesson.lesson,
        isFree: lesson.isFree,
        sources: lesson.sources,
        books: lesson.books,
        items: formattedItem,
        knowledges: lesson.knowledges,
      };
    }
  );

  const formattedLessonPage = {
    ...lessonPage,

    lessons: reformatedLesson,
  };

  return formattedLessonPage;
};
