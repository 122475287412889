import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';

import { device } from 'helpers/css/responsive';
import { routes } from 'routing/constants';

import { Card as RawCard } from 'ui/Card';
import { Button as RawButton } from 'ui/Button';
import { OutlinedCheck } from 'ui/icons/OutlinedCheck';
import { LoginRelatedBackground } from 'components/LoginRelatedBackground';

import { useGetAuthConfirmationState } from './hooks/useGetAuthConfirmationState';

export const AuthConfirmation = () => {
  const state = useGetAuthConfirmationState();
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate(routes.LOGIN);
  };

  return (
    <LoginRelatedBackground>
      <Container>
        <Card>
          <Icon>
            <OutlinedCheck />
          </Icon>
          <Title>{state?.title}</Title>
          <SubTitle>{state?.subtitle}</SubTitle>

          <Button type="button" onClick={goToLogin}>
            Retour à la connexion
          </Button>
        </Card>
      </Container>
    </LoginRelatedBackground>
  );
};

const Container = styled.div`
  height: 100%;
  min-height: 100vh;

  display: grid;
  place-items: center;

  padding: 16px;
`;

const Card = styled(RawCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.div`
  height: 100px;
  width: 100px;

  color: ${({ theme }) => theme.colors.success.main};

  @media ${device.desktop} {
    height: 144px;
    width: 144px;
  }
`;

const Title = styled.h1`
  margin-top: 16px;
  text-align: center;

  @media ${device.desktop} {
    margin-top: 32px;
  }
`;

const SubTitle = styled.h3`
  margin-top: 16px;
  text-align: center;

  @media ${device.desktop} {
    margin-top: 28px;
  }
`;

const Button = styled(RawButton)`
  margin-top: 28px;

  @media ${device.desktop} {
    margin-top: 32px;
  }
`;
