import { useMemo } from 'react';

import { useGetSpecialtiesRankInfos } from 'http/selection/specialities-list/connectors';
import { sortProgressBySpecialty } from '../../shared/useCases/sortProgressBySpecialty';
import { getDPProgressBySpecialty } from '../domain/useCases/getDPProgressBySpecialty';
import { useGetDPProgressBySession } from './useGetDPProgressBySession';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';

export const useGetDPProgressBySpecialty = () => {
  const progressBySessionQuery = useGetDPProgressBySession();
  const speRankInfos = useGetSpecialtiesRankInfos();
  const { isUserPremium } = useIsUserPremium();

  const progressBySpe = useMemo(() => {
    const progressBySpe = getDPProgressBySpecialty(
      progressBySessionQuery.data,
      isUserPremium
    );
    return sortProgressBySpecialty(progressBySpe, speRankInfos);
  }, [isUserPremium, progressBySessionQuery.data, speRankInfos]);

  return {
    ...progressBySessionQuery,
    data: progressBySpe,
  };
};
