import { useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  useGetAccountPayments,
  useGetAccountSubscription,
} from 'http/account/connector';

export const useSubscriptionInfo = () => {
  const { data: userPayments, isError: hasPaymentFetchFailed } =
    useGetAccountPayments();
  const { data: userSubscription, isError: hasSubcriptionFetchFailed } =
    useGetAccountSubscription();

  useEffect(() => {
    if (hasPaymentFetchFailed) {
      toast.error(
        'Impossible de récupérer vos informations de payment pour le moment, veuillez réessayer plus tard'
      );
    }
  }, [hasPaymentFetchFailed]);

  useEffect(() => {
    if (hasSubcriptionFetchFailed) {
      toast.error(
        'Impossible de récupérer les informations de votre abonnement pour le moment, veuillez réessayer plus tard'
      );
    }
  }, [hasSubcriptionFetchFailed]);

  return { userPayments, userSubscription };
};
