import { menuOpenTransitionConfig } from 'components/MainLayout/cssTransitions';
import { useMenuContext } from 'components/MainLayout/menuContext';
import styled, { css } from 'styled-components/macro';

export const Burger = () => {
  const { isMenuExpanded } = useMenuContext();
  return (
    <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Line1 fill="currentColor" isCross={isMenuExpanded} />
      <Line2 y={9.167} fill="currentColor" isCross={isMenuExpanded} />
      <Line3 y={18.333} fill="currentColor" isCross={isMenuExpanded} />
    </svg>
  );
};

const linesCommonStyle = css`
  width: 20px;
  height: 1.667px;
  border-radius: 0.833px;

  transition-property: transform, opacity;
  ${menuOpenTransitionConfig}
`;

const Line1 = styled.rect<{ isCross: boolean }>`
  ${linesCommonStyle}

  ${({ isCross }) =>
    isCross &&
    css`
      transform: rotate(45deg) translate(4px, -1px);
    `}
`;

const Line2 = styled.rect<{ isCross: boolean }>`
  ${linesCommonStyle}

  ${({ isCross }) =>
    isCross &&
    css`
      transform: translateX(100%);
      opacity: 0;
    `}
`;

const Line3 = styled.rect<{ isCross: boolean }>`
  ${linesCommonStyle}

  ${({ isCross }) =>
    isCross &&
    css`
      transform: rotate(-45deg) translate(-10px, -5px);
    `}
`;
