import { MS_IN_AN_HOUR } from 'constants/date';
import { useQuery } from 'react-query';
import { getAccountPayments, getAccountSubscription } from './api';
import { UserAccountHttpResponse } from 'types/user.types';
import { useState, useEffect } from 'react';
import { getUserAccountPreferencesHttp } from 'modules/user-options/http/api';

export const useGetAccountPayments = () => {
  return useQuery(['getAccountPayments'], () => getAccountPayments(), {
    staleTime: MS_IN_AN_HOUR,
    cacheTime: MS_IN_AN_HOUR,
  });
};

export const useGetAccountSubscription = () => {
  return useQuery(['getAccountSubscription'], () => getAccountSubscription(), {
    staleTime: MS_IN_AN_HOUR,
    cacheTime: MS_IN_AN_HOUR,
  });
};

export const useGetFastUserOptions = () => {
  const [user, setUser] = useState<UserAccountHttpResponse>();

  useEffect(() => {
    async function getUser() {
      const userFromAPI = await getUserAccountPreferencesHttp();
      if (userFromAPI) {
        setUser({
          pk: userFromAPI.pk,
          username: userFromAPI.username,
          email: userFromAPI.email,
          first_name: userFromAPI.first_name,
          last_name: userFromAPI.last_name,
          promotion: userFromAPI.promotion,
          university: userFromAPI.university,
          premium: userFromAPI.premium,
          end_premium: userFromAPI.end_premium,
          is_superuser: userFromAPI.is_superuser,
          randomized_training_order: userFromAPI.randomized_training_order,
          limited_time: userFromAPI.limited_time,
          show_specialty: userFromAPI.show_specialty,
          show_item: userFromAPI.show_item,
          show_median: userFromAPI.show_median,
          show_comment: userFromAPI.show_comment,
          accept_condition: userFromAPI.accept_condition,
          // question_grade_display_style: QuestionGradeDisplayStyleHttp; TODO: check if var still exist in API
          correction_explicit_display: userFromAPI.correction_explicit_display,
          flash_max_new: userFromAPI.flash_max_new,
          flash_max_review: userFromAPI.flash_max_review,
          newsletter: userFromAPI.newsletter,
          needs_newsletter_prompt: userFromAPI.needs_newsletter_prompt,
        });
      } else return;
    }
    getUser();
  }, []);
  return user;
};
