import { refreshToken } from 'auth/loginFunctions';
import { useEffect, useRef } from 'react';

type ExecutionTimeoutType = ReturnType<typeof setTimeout>;

const REFRESH_TOKEN_INTERVAL_MS = 5 * 60 * 1000;

export const useRefreshToken = () => {
  const executionTimeout = useRef<ExecutionTimeoutType>();

  useEffect(() => {
    executionTimeout.current = setInterval(
      refreshToken,
      REFRESH_TOKEN_INTERVAL_MS
    );

    return () => clearInterval(executionTimeout.current!);
  });
};
