import { device } from 'helpers/css/responsive';
import { FC } from 'react';
import styled from 'styled-components/macro';

import ItemFilterHeader from '../Filters/ItemFilterHeader/ItemFilterHeader';
import RankFilterHeader from '../Filters/RankFilterHeader/RankFilterHeader';
import { FirstHeaderCell, HeaderCell, LastHeaderCell } from './uiElements';

export const Header: FC = () => {
  return (
    <>
      <FirstHeaderCell>
        <HeaderCellText>Intitulé</HeaderCellText>
      </FirstHeaderCell>
      <HeaderCell>
        <div style={{ display: 'flex' }}>
          <ItemFilterHeader />
          <div style={{ width: '100%' }} />
        </div>
      </HeaderCell>
      <HeaderCell>
        <div style={{ display: 'flex' }}>
          <RankFilterHeader />
          <div style={{ width: '100%' }} />
        </div>
      </HeaderCell>
      <LastHeaderCell></LastHeaderCell>
    </>
  );
};

const HeaderCellText = styled.span`
  display: inline-block;
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: 8px;
  color: ${({ theme }) => theme.colors.primary.dark};

  @media ${device.desktop} {
    padding: 4px 16px;
  }
`;
