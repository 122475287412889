import { FC } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { routes } from 'routing/constants';
import { LockIcon } from 'ui/icons/Lock';

interface Props {
  colSpan: number;
}

export const RestrictedPremiumDataRow: FC<Props> = ({ colSpan }) => {
  return (
    <tr>
      <Cell colSpan={colSpan}>
        <Link to={routes.SUBSCRIBE}>
          <Container>
            <small>Contenu abonné</small> <Lock />
          </Container>
        </Link>
      </Cell>
    </tr>
  );
};

const Cell = styled.td`
  max-width: 200px;
  padding: 4px 12px;
`;

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: baseline;
  color: ${({ theme }) => theme.colors.secondary.main};
`;

const Lock = styled(LockIcon)`
  height: 12px;
  width: 12px;
`;
