import { useQuery } from 'react-query';
import { getTaskHistory } from './api';

export const useGetFinishedTasks = () => {
  const query = useQuery(['taskHistory_training', true], () =>
    getTaskHistory({ finished: true, is_ccb: false })
  );
  return query;
};

export const useGetUnfinishedTasks = () => {
  const query = useQuery(['taskHistory_training', false], () =>
    getTaskHistory({ finished: false, is_ccb: false })
  );
  return query;
};

export const useGetCCBUnfinishedTasks = () => {
  const query = useQuery(['taskHistory_ccb', false], () =>
    getTaskHistory({ finished: false, is_ccb: true })
  );
  return query;
};
