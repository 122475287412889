import React from 'react';
import { SubscribeButton } from 'ui/Buttons/SubscribeButton';
import BlueCard from './BlueCard';
import styled from 'styled-components';
import { device } from 'helpers/css/responsive';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';

export const UnlockCard = () => {
  const { isUserPremium } = useIsUserPremium();

  if (!isUserPremium) {
    return (
      <BlueCard gap="1rem">
        <Section>
          <SubTitle>Débloquez l’accès complet à asclepia</SubTitle>
        </Section>
        <Section>
          <Text>
            Abonnez vous pour débloquer plus d'annales, des rappels de cours
            complets, et des fonctionnalités telles que l'Astrolabe ou les
            Questions flash. Optimisez votre préparation dès maintenant !
          </Text>
          <SubscribeButton />
        </Section>
      </BlueCard>
    );
  }
  return null;
};

const Section = styled.div<{ vertical?: boolean }>`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  gap: 1rem;
  @media ${device.mobile} {
    justify-content: center;
    align-items: center;
    flex-direction: column;
  } ;
`;

const SubTitle = styled.h4`
  font-weight: 900;
  display: inline;
`;

const Text = styled.p`
  margin: 0;
`;
