import { FC, SVGAttributes } from 'react';

type SVGNativeProps = SVGAttributes<SVGElement>;

export const ArrowDown: FC<SVGNativeProps> = () => {
  return (
    <svg
      width="18"
      height="11"
      viewBox="0 0 18 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.999999 1L9 9L17 1"
        stroke="#01162D"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
