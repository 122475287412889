import { FC } from 'react';
import styled, { css, useTheme } from 'styled-components/macro';
import { CUSTOM_BLUE_COLOR } from '../../FQDeckList/components/FQRow/FQDecksRow';
import { FlashDeck } from 'types/flash.type';

interface Props {
  deck: FlashDeck;
}

export const FQStats: FC<Props> = ({ deck }) => {
  const theme = useTheme();
  return (
    <Container>
      <Duo fill={CUSTOM_BLUE_COLOR}>
        <T>Nouvelles</T>
        <D>{deck.day_new_cards_count}</D>
      </Duo>
      <Duo fill={theme.colors.secondary.dark}>
        <T>A revoir</T>
        <D>{deck.day_to_redo_cards_count}</D>
      </Duo>
      <Duo fill={theme.colors.success.dark}>
        <T>A réviser</T>
        <D>{deck.day_to_review_cards_count}</D>
      </Duo>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 300px;
  height: 250px;
  border-radius: 12px;
  align-self: center;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.primary.light};
    `};
`;

const Duo = styled.div<{ fill: string }>`
  display: flex;
  margin-left: 15%;
  margin-right: 20%;
  justify-content: space-between;
  color: ${(props) => props.fill};
`;

const T = styled.div`
  font-weight: 600;
`;

const D = styled.div``;
