import { createContext, FC, useContext, useState } from 'react';

import { PreviewModal } from '../components/PreviewModal';

interface PreviewModalContext {
  openModal: (qiId: number) => void;
}

const contextDefaultValue: PreviewModalContext = {
  openModal: () => {},
};

const previewModalContext = createContext(contextDefaultValue);

export const PreviewModalProvider: FC = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [qiId, setQIId] = useState<number>();

  const openModal = (newQiId: number) => {
    setQIId(newQiId);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <previewModalContext.Provider value={{ openModal }}>
      {qiId && (
        <PreviewModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          qiId={qiId}
        />
      )}
      {children}
    </previewModalContext.Provider>
  );
};

export const usePreviewModalContext = () => useContext(previewModalContext);
