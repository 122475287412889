import styled from 'styled-components/macro';

import { device } from 'helpers/css/responsive';

import backgroundDesktop from 'assets/backgrounds/login-desktop.svg';
import backgroundMobile from 'assets/backgrounds/login-mobile.svg';

export const LoginRelatedBackground = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.main};
  background-image: url(${backgroundMobile});
  background-size: cover;
  background-position: center;

  @media ${device.desktop} {
    background-image: url(${backgroundDesktop});
  }
`;
