import { UserOptionsRepository } from '../domain/UserOptionsRepository.interface';
import {
  mapPartialUserOptionsDomainToHttp,
  mapUserOptionsHttpToDomain,
} from './adapter';
import {
  getUserAccountPreferencesHttp,
  patchUserAccountPreferencesHttp,
} from './api';

export const getUserOptionsRepository = (): UserOptionsRepository =>
  userOptionsRepositoryImpl;

const userOptionsRepositoryImpl: UserOptionsRepository = {
  async getUserOptions() {
    const httpUserOptions = await getUserAccountPreferencesHttp();

    return mapUserOptionsHttpToDomain(httpUserOptions);
  },
  async updateUserOptions(modifiedOptions) {
    const httpModifiedOptions =
      mapPartialUserOptionsDomainToHttp(modifiedOptions);
    const updatedHttpUserOptions = await patchUserAccountPreferencesHttp(
      httpModifiedOptions
    );

    return mapUserOptionsHttpToDomain(updatedHttpUserOptions);
  },
};
