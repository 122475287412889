import { buttonReset } from 'helpers/css/button-reset';
import { useCanAddToCart } from 'pages/selection/hooks/useCanAddToCart';
import { FC, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';

import { Button } from 'ui/Button';
import { CloseIcon } from 'ui/icons/Close';
import { Modal } from 'ui/Modal';
import { TableSelectionInput } from './components/TableSelectionInput';
import { RandomSelectionInputs, useRandomSelectionForm } from './form';
import { updateItemSelection as updateDpSelection } from 'store/slices/dpTableSlice';
import { updateItemSelection as updateQiSelection } from 'store/slices/qiTableSlice';
import { updateItemSelection as updateLcaSelection } from 'store/slices/lcaTableSlice';
import { RootState } from 'store';
import { getRandomGroupSample } from 'http/selection-group/api';
import { getRandomQISample } from 'http/selection-qi/api';
import { getItemsNotAlreadyInOtherItems } from 'pages/selection/TableHeaders/utils';
import { useIsLoading } from 'hooks/useIsLoading';
import { useGetEstimatedDurations } from 'modules/user-options/hooks/useGetEstimatedDurations';
import { formatDurationInMinutes } from 'pages/selection/hooks/useGetCartEstimatedDuration';

interface Props {}

export const GenerateRandomSelectionButton: FC<Props> = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const closeModal = () => setIsModalOpen(false);

  const { register, handleSubmit, watch } = useRandomSelectionForm();
  const canAddToCart = useCanAddToCart();

  const { isLoading, startLoading, stopLoading } = useIsLoading();

  const dispatch = useDispatch();
  const estimatedDurations = useGetEstimatedDurations();

  const dpShort = watch('dp');
  const dpLong = watch('dp');

  const dp = watch('dp');
  const qi = watch('qi');
  const lca = watch('lca');

  const dpNumberShort = isNaN(dp) ? 0 : dpShort;
  const dpNumberLong = isNaN(dp) ? 0 : dpLong;

  const dpNumber = isNaN(dp) ? 0 : dp;
  const qiNumber = isNaN(qi) ? 0 : qi;
  const lcaNumber = isNaN(lca) ? 0 : lca;

  const hasNoLcaValue = !lca;
  const hasNoDpQiValues = !qi && !dp;

  const dpSelection = useSelector((state: RootState) => {
    return state.dpTable.selectedItemsInfos;
  });
  const qiSelection = useSelector((state: RootState) => {
    return state.qiTable.selectedItemsInfos;
  });
  const lcaSelection = useSelector((state: RootState) => {
    return state.lcaTable.selectedItemsInfos;
  });

  const onSubmit: SubmitHandler<RandomSelectionInputs> = async (formData) => {
    if (isNaN(formData.dp) && isNaN(formData.qi) && isNaN(formData.lca)) {
      toast.warn('Veuillez rentrer une valeur');
      return;
    }

    if (isNaN(formData.lca)) {
      const dpNumber = isNaN(formData.dp) ? 0 : formData.dp;
      const qiNumber = isNaN(formData.qi) ? 0 : formData.qi;
      if (dpNumber < 0 || qiNumber < 0) {
        toast.warn('Vous ne pouvez rentrer que des valeurs positives');
        return;
      }
      // TODO à réactiver après refonte design
      if (
        canAddToCart('DP', qiNumber, {
          short: dpNumberShort,
          long: dpNumberLong,
        })
      ) {
        startLoading();
        let dpSample;
        let qiSample;
        try {
          dpSample = await getRandomGroupSample('DP', dpNumber);
          qiSample = await getRandomQISample(qiNumber);
        } catch (err) {
          stopLoading();
          toast.error('Une erreur est survenue, veuillez réessayer');
          return;
        }

        const dpsToAdd = getItemsNotAlreadyInOtherItems(
          dpSample.results,
          dpSelection
        );

        const qisToAdd = getItemsNotAlreadyInOtherItems(
          qiSample.results,
          qiSelection
        );
        dispatch(updateDpSelection(dpsToAdd));
        dispatch(updateQiSelection(qisToAdd));
        if (dpsToAdd.length < dpNumber) {
          toast.warn(
            `${
              dpNumber - dpsToAdd.length
            } DPs générés aléatoirement n'ont pas été ajoutés à votre sélection car ils y étaient déjà`
          );
        }
        if (qisToAdd.length < qiNumber) {
          toast.warn(
            `${
              qiNumber - qisToAdd.length
            } QIs générés aléatoirement n'ont pas été ajoutés à votre sélection car ils y étaient déjà`
          );
        }
        toast.info(
          `${dpsToAdd.length} DPs et ${qisToAdd.length} QIs ont été ajoutés à votre sélection`
        );
        stopLoading();
        closeModal();
      }
    } else {
      if (formData.lca < 0) {
        toast.warn('Vous ne pouvez rentrer que des valeurs positives');
        return;
      }

      if (canAddToCart('LCA', formData.lca)) {
        startLoading();
        const lcaSample = await getRandomGroupSample('DP', formData.lca);
        const lcasToAdd = getItemsNotAlreadyInOtherItems(
          lcaSample.results,
          lcaSelection
        );
        dispatch(updateLcaSelection(lcasToAdd));
        if (lcasToAdd.length < formData.lca) {
          toast.warn(
            `${
              formData.lca - lcasToAdd.length
            } LCAs générés aléatoirement n'ont pas été ajoutés à votre sélection car ils y étaient déjà`
          );
        }
        toast.info(
          `${lcasToAdd.length} LCAs ont été ajoutés à votre sélection`
        );
        stopLoading();
        closeModal();
      }
    }
  };

  return (
    <>
      {/* TODO reactivate after feature redefinition <Button
        type="button"
        onClick={() => {
          setIsModalOpen(true);
        }}
      >
        Me générer une sélection
      </Button> */}

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <TopCloseRow>
          <CloseButton onClick={closeModal}>
            <CloseIcon />
          </CloseButton>
        </TopCloseRow>

        <ContentContainer>
          <Title>
            Choisissez le nombre d’épreuve.s que vous souhaitez réaliser
          </Title>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <NumberPickerContainer>
              {hasNoLcaValue && (
                <>
                  <TableSelectionInput
                    tableName="DP"
                    type="number"
                    {...register('dp', { valueAsNumber: true })}
                  />
                  <TableSelectionInput
                    tableName="QI"
                    type="number"
                    {...register('qi', { valueAsNumber: true })}
                  />
                </>
              )}

              {hasNoDpQiValues && (
                <>
                  <TableSelectionInput
                    tableName="LCA"
                    type="number"
                    {...register('lca', { valueAsNumber: true })}
                  />
                </>
              )}
            </NumberPickerContainer>

            <EstimatedTimeContainer>
              Temps d’entraînement estimé :{' '}
              {formatDurationInMinutes(
                lcaNumber * estimatedDurations.lca +
                  qiNumber * estimatedDurations.qi +
                  dpNumberShort * estimatedDurations.dpLessThan13 +
                  dpNumberLong * estimatedDurations.dpMoreThan13 +
                  dpNumber
              )}
            </EstimatedTimeContainer>

            <GenerateButton type="submit" isLoading={isLoading}>
              Générer la sélection
            </GenerateButton>
          </Form>
          <InfoText>
            Une sélection aléatoire sera générée selon les choix
          </InfoText>
        </ContentContainer>
      </Modal>
    </>
  );
};

const TopCloseRow = styled.div`
  display: flex;
  justify-content: end;
`;

const CloseButton = styled.div`
  ${buttonReset}
  padding: 4px;
  --size: 20px;
  height: var(--size);
  width: var(--size);

  display: grid;
  place-items: center;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h5`
  margin-top: 16px;
`;

const NumberPickerContainer = styled.div`
  margin-top: 40px;
  width: 100%;

  display: flex;
  justify-content: center;
  gap: max(8px, 10%);
`;

const EstimatedTimeContainer = styled.h5`
  margin-top: 64px;
`;

const GenerateButton = styled(Button)`
  margin-top: 56px;
`;

const InfoText = styled.small`
  margin-top: 12px;
  color: ${({ theme }) => theme.colors.inactive};
`;
