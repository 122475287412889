import { EditLogo } from 'ui/icons/EditLogo';
import { ValidateIcon } from 'ui/icons/ValidateIcon';
import { CancelIcon } from 'ui/icons/CancelIcon';
import styled from 'styled-components/macro';
import { UnderlinedTextInput } from 'ui/UnderlinedTextInput';
import { Link } from 'react-router-dom';
import { routes } from 'routing/constants';
import { useTheme } from 'styled-components';
import { device } from 'helpers/css/responsive';
import { UnderlinedDropdown } from 'ui/UnderlinedDropdown';
import { useGetParams } from './useGetParams';

const LoadingMessage = 'Loading...';

export const MyInformationsOptions = () => {
  const theme = useTheme(); // Only to style Link components, that do not work well with styledComponents

  const {
    readOnly,
    setReadOnly,
    firstName,
    lastName,
    promotion,
    university,
    username,
    preferences,
    promotions,
    universities,
    handleChangeFirstName,
    handleChangeLastName,
    handleChangePromotion,
    handleChangeUniversity,
    handleChangeUsername,
    submit,
  } = useGetParams();

  // Allow or not the modification of the form
  const toggleSaveCancel = () => {
    setReadOnly(!readOnly);
  };

  return (
    <Container>
      <MyInfosSection>
        <MyInformationsContainer>
          <h2>Mes informations</h2>
          <IconContainer>
            {!readOnly ? (
              <ValidateButton onClick={submit}>
                <ValidateIcon />
              </ValidateButton>
            ) : null}
            <ToggleSaveCancelButton type="button" onClick={toggleSaveCancel}>
              {readOnly ? <EditLogo /> : <CancelIcon />}
            </ToggleSaveCancelButton>
          </IconContainer>
        </MyInformationsContainer>
        {preferences !== undefined && universities && promotions ? (
          <div>
            <StyledUnderlinedTextInput
              label="Prénom"
              name="firstName"
              value={firstName}
              onChange={handleChangeFirstName}
              readOnly={readOnly}
            ></StyledUnderlinedTextInput>
            <StyledUnderlinedTextInput
              label="Nom"
              value={lastName}
              onChange={handleChangeLastName}
              readOnly={readOnly}
            ></StyledUnderlinedTextInput>
            <StyledUnderlinedDropdown
              label="Année du concours"
              isDisabled={readOnly}
              options={promotions}
              onChange={handleChangePromotion}
              value={promotions.find((el) => el.value === promotion)}
            ></StyledUnderlinedDropdown>
            <StyledUnderlinedDropdown
              label="Faculté"
              isDisabled={readOnly}
              options={universities}
              onChange={handleChangeUniversity}
              value={universities.find((el) => el.value === university)}
            ></StyledUnderlinedDropdown>
          </div>
        ) : (
          LoadingMessage
        )}
      </MyInfosSection>
      <MyInfosSection>
        <h2>Authentification</h2>
        <Line>
          <StyledUnderlinedTextInput
            label="Adresse email"
            value={username}
            onChange={handleChangeUsername}
            readOnly={readOnly}
          />
          <ChangeEmailContainer>
            <Link to={routes.CHANGE_EMAIL}>Modifier</Link>
          </ChangeEmailContainer>
        </Line>
        <Line>
          Mot de passe
          <ChangePasswordContainer>
            <Link to={routes.CHANGE_PASSWORD_CONNECTED_USER}>Modifier</Link>
          </ChangePasswordContainer>
        </Line>
      </MyInfosSection>
      <MyInfosSection>
        <WarningSticker>Danger zone</WarningSticker>
        <h2>Supprimer mon compte</h2>
        <p>
          Votre compte vous permet notamment de consulter votre historique de
          réalisation des annales. La suppression du compte entrainement la
          suppression de toutes ces données.
        </p>
        <DeleteAccountContainer>
          <Link
            style={{ color: theme.colors.error.main }}
            to={routes.DELETE_ACCOUNT}
          >
            Supprimer
          </Link>
        </DeleteAccountContainer>
      </MyInfosSection>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media ${device.desktop} {
    gap: 32px;
  }
`;
const MyInfosSection = styled.form`
  width: 100%;
  padding: 16px;

  @media ${device.desktop} {
    border-radius: 16px;
    padding: 32px 32px 43px 32px;
    max-width: 760px;
    flex-grow: 1;
    flex-basis: 100%;
    flex-shrink: 0;
    border: 2px solid ${({ theme }) => theme.colors.primary.dark};
  }
`;

const MyInformationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  line-height: 28px;
  height: 28px;

  @media ${device.desktop} {
    line-height: 36px;
    height: 36px;
    gap: 16px;
  }
`;

const IconContainer = styled.div`
  height: 28px;
  line-height: 28px;
  @media ${device.desktop} {
    height: 36px;
    line-height: 36px;
  }
`;

const Line = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  flex-direction: row;
  gap: 14px;
`;

const ValidateButton = styled.button`
  width: 16px;
  height: 16px;
  line-height: 16px;
  padding: 8px 0;
  margin-right: 8px;
  object-fit: fill;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.primary.dark};
  &:hover {
    cursor: pointer;
  }

  @media ${device.desktop} {
    width: 24px;
    height: 36px;
    line-height: 36px;
  }
`;

const ToggleSaveCancelButton = styled.button`
  width: 16px;
  height: 16px;
  line-height: 16px;
  padding: 8px 0;
  object-fit: fill;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.primary.dark};
  &:hover {
    cursor: pointer;
  }

  @media ${device.desktop} {
    width: 24px;
    height: 36px;
    line-height: 36px;
    padding: 8px 0;
  }
`;

const StyledUnderlinedTextInput = styled(UnderlinedTextInput)`
  margin-top: 16px;
  @media ${device.desktop} {
    margin-top: 24px;
  }
`;

const StyledUnderlinedDropdown = styled(UnderlinedDropdown)`
  margin-top: 16px;
  @media ${device.desktop} {
    margin-top: 24px;
  }
`;

const ChangeEmailContainer = styled.div`
  margin-top: 16px;
  justify-content: end;

  @media ${device.desktop} {
    margin-top: 20px;
  }
`;

const ChangePasswordContainer = styled.div`
  margin-top: 24px;
  @media ${device.desktop} {
    margin-top: 32px;
  }
`;

const DeleteAccountContainer = styled.div`
  margin-top: 25px;
  @media ${device.desktop} {
    margin-top: 29px;
  }
`;

const WarningSticker = styled.div`
  padding: 6px 10px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.error.main};
  color: ${({ theme }) => theme.colors.error.main};
  margin-top: -10px;
  margin-bottom: 24px;
  width: fit-content;

  @media ${device.desktop} {
    margin-bottom: 32px;
  }
`;
