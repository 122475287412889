import { useDispatch } from 'react-redux';
import { resetFilters as resetFiltersAction } from 'store/slices/selectionFiltersSlice';

export const useResetFilters = () => {
  const dispatch = useDispatch();

  const resetFilters = () => {
    dispatch(resetFiltersAction());
  };

  return { resetFilters };
};
