import { FC } from 'react';
import styled, { css } from 'styled-components/macro';

import { SmallDownArrow } from 'ui/icons/SmallDownArrow';
import { Td } from 'ui/TableElements';

interface Props {
  isOpen: boolean;
  setIsOpen: (newVal: boolean) => void;
}

export const ExpandCell: FC<Props> = ({ isOpen, setIsOpen }) => {
  return (
    <ExpandTd onClick={() => setIsOpen(!isOpen)}>
      <Arrow isOpen={isOpen} />
    </ExpandTd>
  );
};

const ExpandTd = styled(Td)`
  padding: 16px 4px;
  cursor: pointer;
`;

const Arrow = styled(({ isOpen, ...props }: { isOpen: boolean }) => (
  <SmallDownArrow {...props} />
))`
  height: 12px;
  width: 12px;
  color: ${({ theme }) => theme.colors.primary.dark};

  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: scaleY(-1);
    `}
`;
