import { getAPI } from 'auth';
import { DetailQIQuery, DetailQIResponse } from './types';

export const getQIDetail = async (params: DetailQIQuery) => {
  const response = await getAPI<DetailQIResponse>('/progress/detail/qi', {
    params,
  });

  return response.data;
};
