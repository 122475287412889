import { getAPI } from 'auth';
import {
  SelectionGroupTrainingsResponse,
  SelectionGroupTrainingsParams,
} from './types';

export const getGroupLastTrainings = async (
  params: SelectionGroupTrainingsParams
) => {
  const response = await getAPI<SelectionGroupTrainingsResponse>(
    '/selection/group_trainings/',
    {
      params,
    }
  );
  return response.data;
};
