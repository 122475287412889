import { PreviousGroupTraining } from 'modules/progress/detailed/shared/entities/PreviousTraining';
import { GroupType } from 'modules/shared/entities/GroupType';
import { GradeData } from 'pages/correction/components/CorrectionGroupHeader/points-formatter';
import { PastCorrectionButton } from 'pages/progress/GeneralProgress/components/PastCorrectionButton';
import { FormattedDate } from 'pages/selection/components/FormattedDate';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routing/constants';
import styled from 'styled-components/macro';
import { formatGradeOutOfTwenty } from 'pages/correction/components/CorrectionGroupHeader/points-formatter';

interface Props {
  training: PreviousGroupTraining;
  groupType: GroupType;
}

export const HistoryListRow: FC<Props> = ({ training, groupType }) => {
  const gradeData: GradeData = {
    grade: training.grade ?? null,
    totalCoeff: training.denom,
  };

  const navigate = useNavigate();
  const goToCorrection = () => {
    if (training.groupCorrectionId) {
      navigate({
        pathname: routes.GROUP_CORRECTION,
        search: `?id=${training.groupCorrectionId}`,
      });
    }
  };

  return (
    <>
      <small>
        <FormattedDate date={training.date} />
      </small>
      <small>{formatGradeOutOfTwenty(gradeData)}</small>
      <small>
        <PastCorrectionButton onClick={goToCorrection} />
      </small>
      <PushCell />
    </>
  );
};

const PushCell = styled.div``;
