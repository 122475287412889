import { postAPI } from 'auth';
import { PauseTimerResponseType } from './types';
import { TaskRequestType } from 'types/task.type';

export const pauseTimer = async (taskId: number) => {
  const response = await postAPI<PauseTimerResponseType, TaskRequestType>(
    '/training/pause_timer',
    { task_id: taskId }
  );
  return response.data;
};
