import { getLessonHttp, getLessonsHttp } from 'http/lessons/api';
import { useQuery } from 'react-query';
import { MS_IN_AN_HOUR } from 'constants/date';

export const useGetLessons = () => {
  const getLessons = async () => {
    const query = await getLessonsHttp();
    return query;
  };

  const { data: lessonInfos } = useQuery(['getLessons'], () => getLessons(), {
    staleTime: MS_IN_AN_HOUR,
    cacheTime: MS_IN_AN_HOUR,
  });
  return lessonInfos;
};

export const useGetLessonsByIds = (lessonIds: string[]) => {
  const getLessons = async (lessonIds: string[]) => {
    if (!lessonIds || lessonIds.length === 0) return null;
    const query = await getLessonHttp(lessonIds);
    return query;
  };

  const { data: lessonInfos } = useQuery(
    ['getLessons', lessonIds || 'none'],
    () => getLessons(lessonIds),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }
  );
  return lessonInfos;
};
