import { PreviousGroupTraining } from 'modules/progress/detailed/shared/entities/PreviousTraining';
import { GroupType } from 'modules/shared/entities/GroupType';
import { FC } from 'react';
import styled from 'styled-components/macro';
import { HistoryListRow } from './HistoryListRow';

interface Props {
  history: PreviousGroupTraining[];
  groupType: GroupType;
}

export const HistoryList: FC<Props> = ({ history, groupType }) => {
  return (
    <Container>
      {history.map((training) => (
        <HistoryListRow
          training={training}
          groupType={groupType}
          key={training.groupCorrectionId}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  column-gap: 24px;
  row-gap: 12px;
  place-items: center;
  overflow-y: auto;
`;
