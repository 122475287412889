import { LCABasicInformations } from '../domain/entities/LCABasicInformations';
import { ProgressGeneralLCAResponseHttp } from './types';

export const mapGeneralProgressDPHttpToDomain = (
  progress: ProgressGeneralLCAResponseHttp[]
): LCABasicInformations[] => {
  try {
    const adaptedProgress: LCABasicInformations[] = progress.flatMap(
      (session) =>
        session.groups.map((group) => ({
          id: group.id,
          collection: session.collection,
          num: group.num,
          speTrigram: group.specialty1 ?? undefined,
          articleTitle: group.title,
          lastCorrectionId: group.last_training ?? undefined,
          lastGrade: group.grade ?? undefined,
          median: group.median ?? undefined,
          denom: group.denom ?? undefined,
          hasBeenDone: !!group.last_training,
          isFree: group.is_free,
        }))
    );

    return adaptedProgress;
  } catch (e) {
    throw new Error(
      "Error in general progress lca mapping, unexpected response from '/progress/general/lca':\n " +
        e
    );
  }
};
