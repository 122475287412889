import { GroupHistoryParams } from '../domain/entities/GroupHistoryParams';
import { GroupHistoryHttpParams } from './types';

export const adaptParamsToHttp = (
  params: GroupHistoryParams
): GroupHistoryHttpParams => ({
  finished: params.shouldRetrieveFinishedGroups,
  page: params.pageNumber,
  page_size: params.pageSize,
});
