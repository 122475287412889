import { FC } from 'react';
import { GenericSortHeader } from './GenericSortHeader';

interface Props {
  isSub?: boolean;
}

export const DeckHeader: FC<Props> = ({ isSub }) => {
  return (
    <GenericSortHeader columnName="Paquet" sortColumn="label" isSub={isSub} />
  );
};
