import { useMemo } from 'react';
import { useGetSpecialities } from 'http/selection/specialities-list/connectors';
import { SpecialtyChartData } from 'pages/start/components/DPQIView/SpecialitiesBarChart';
import { StringCounter } from './types';

export const useAdaptCounterToSpecialtyChartData = () => {
  const specialtiesQuery = useGetSpecialities();

  const mapTrigramToDetails = useMemo(() => {
    const spes = specialtiesQuery.data;
    if (!spes) return {};

    return spes.reduce<{
      [trigram: string]: {
        color: string;
        name: string;
      };
    }>(
      (prev, spe) => ({
        ...prev,
        [spe.trigram]: {
          color: spe.color,
          name: spe.name,
        },
      }),
      {}
    );
  }, [specialtiesQuery.data]);

  return function (speCounter: StringCounter): SpecialtyChartData[] {
    const trigrams = Object.keys(speCounter);
    return trigrams
      .map((trigram) => ({
        trigram: trigram,
        name: mapTrigramToDetails[trigram]?.name,
        color: mapTrigramToDetails[trigram]?.color,
        count: speCounter[trigram],
      }))
      .sort((a, b) => b.count - a.count);
  };
};
