import styled from 'styled-components';
import { PageContent } from './PageContent';
import { useSelector } from 'react-redux';

export const StatisticsPage = () => {
  const { collection } = useSelector((state: any) => state.ccb);

  return (
    <>
      <TitleContainer>
        <h1>{collection?.label}</h1>
      </TitleContainer>
      <PageContent />
    </>
  );
};

const TitleContainer = styled.div`
  display: flex;
  margin-bottom: 40px;
`;
