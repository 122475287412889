import { device } from 'helpers/css/responsive';
import { useSelector } from 'react-redux';
import {
  selectCard,
  selectCardStatus,
  selectCorrectionStatus,
} from 'store/slices/fqCurrentSlice';
import { Status } from 'store/types';
import styled from 'styled-components/macro';
import {
  FQQCMPropositionItem,
  QuestionContainer,
} from './FQQCM/FQQCMPropositionItem';
import { FQQRPLPropositionItem } from './FQQRPL/FQQRPLPropositionItem';

export const FQPropositionMapper = () => {
  const card = useSelector(selectCard);
  const cardStatus = useSelector(selectCardStatus);
  const correctionStatus = useSelector(selectCorrectionStatus);

  if (correctionStatus === Status.PENDING) {
    return (
      <PropositionSkeletonContainer>
        {new Array(5).fill(true).map((_, i) => (
          <PropositionSkeleton className="skeleton" key={i} />
        ))}
      </PropositionSkeletonContainer>
    );
  }

  if (cardStatus === Status.RESOLVED && card) {
    return (
      <>
        {card.training_question.question.type !== 'QRPL' ? (
          card.training_question.training_items_qcm?.map((item, index) => (
            <FQQCMPropositionItem key={item.id} itemData={item} index={index} />
          ))
        ) : (
          <Container>
            {card.training_question.training_items_qrpl?.map((item, index) => (
              <FQQRPLPropositionItem
                key={item.id}
                itemData={item}
                index={index}
              />
            ))}
          </Container>
        )}
      </>
    );
  }

  return null;
};

const PropositionSkeletonContainer = styled(QuestionContainer)`
  flex-direction: column;
  background-color: transparent;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  gap: 8px;
`;

const PropositionSkeleton = styled.div`
  min-height: 60px;
  width: 100%;
  //select all except the last one
  &:not(:last-child) {
    margin-bottom: 1em;
    border-radius: 5px;
  }

  @media ${device.desktop} {
    min-height: 70px;
  }
`;
