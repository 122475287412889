import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import { Toast403 } from './Toast403';

const onResponseSuccess = (reponse: AxiosResponse) => {
  return reponse;
};

const onResponseError = (error: AxiosError) => {
  if (error.response?.status === 401) {
    toast.info(
      'Votre session a expirée, veuillez vous reconnecter pour sauvegarder vos changements',
      { toastId: 'offline-toast' }
    );
  }

  if (error.response?.status === 403) {
    toast.warn(<Toast403 />, {
      toastId: '403-toast',
    });
  }

  return Promise.reject(error);
};

axios.interceptors.response.use(onResponseSuccess, onResponseError);
