import { useQuery } from 'react-query';
import { LessonPageParams } from '../domain/entities/LessonPageParams';
import { getRawLessonPage } from '../domain/useCases/getRawLessonPage';
import { MS_IN_AN_HOUR } from 'constants/date';

export const USE_GET_RAW_LESSONS_PAGE_KEY = 'useGetRawLessonsPageKey';

export const useGetRawLessons = (params: LessonPageParams) => {
  return useQuery(
    [USE_GET_RAW_LESSONS_PAGE_KEY, params],
    () => getRawLessonPage(params),
    { cacheTime: MS_IN_AN_HOUR, staleTime: MS_IN_AN_HOUR }
  );
};
