import styled from 'styled-components/macro';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import { selectCorrection } from 'store/slices/fqCurrentSlice';
import { TextBody2 } from 'ui/TextBody2';
import { FC, useState } from 'react';
import { FQPropositionMapper } from './FQPropositions/FQPropositionMapper';
import { FQQCMCorrection } from './FQCorrection/FQQCM/FQQCMCorrection';
import { FlashCard, DoFQCardType } from 'types/flash.type';
import { getQuestionCorrectionComponent } from 'pages/correction/active-correction-group/questions-correction-blocks/helpers/getQuestionCorrectionComponent';
import { FQQROC } from './FQPropositions/FQQROC/FQQROC';
import { FQQZPItem } from './FQPropositions/FQQZP/FQQZPItem';

interface FQuestionStatementProps {
  card: FlashCard & DoFQCardType;
  isCorrectionRevealed: boolean;
}

export const FQuestion: FC<FQuestionStatementProps> = ({
  card,
  isCorrectionRevealed,
}) => {
  const correction = useSelector(selectCorrection);
  const [isBodyShown, setIsBodyShown] = useState(false);
  const CorrectionBlock = getQuestionCorrectionComponent(
    card.training_question.question.type
  );

  const renderStatements = () => {
    if (
      card.training_question.question.type === 'QRM' ||
      card.training_question.question.type === 'QRU' ||
      card.training_question.question.type === 'QRP' ||
      card.training_question.question.type === 'QRPL'
    ) {
      return <FQPropositionMapper />;
    }
    if (card.training_question.question.type === 'QROC') {
      return (
        <FQQROC
          question={card.training_question.question.statement}
          questionId={card.training_question.id}
          questionNumber={card.training_question.question.num}
          answer={card.training_question.training_item_qroc}
          anonymous={false}
          collection={card.training_question.question.collection}
        />
      );
    }

    if (card.training_question.question.type === 'QZP') {
      return (
        <FQQZPItem
          isQI={true}
          questionId={card.training_question.id}
          questionNumber={card.training_question.num}
          answer={{
            id: card.training_question.training_item_qzp.id,
            question_item: {
              image:
                card.training_question.training_item_qzp.question_item.image,
              labels:
                card.training_question.training_item_qzp.question_item.labels,
            },
            answer: card.training_question.training_item_qzp.answer,
          }}
          saved={false}
          show={true}
          shouldUpdateArrowsPositions={true}
          anonymous={false}
          collection={card.training_question.question.collection}
          onSave={() => {}}
        />
      );
    }

    if (card.training_question.question.type === 'TCS') {
      return (
        <ErrorMessage>
          Pour le moment, ce type n'est pas pris en charge.
        </ErrorMessage>
      );
    }
  };

  const renderCorrections = () => {
    if (
      card.training_question.question.type === 'QRM' ||
      card.training_question.question.type === 'QRU' ||
      card.training_question.question.type === 'QRP'
    ) {
      return (
        <FQQCMCorrection
          card={card}
          correction={correction}
          isBodyShown={isBodyShown}
          setIsBodyShown={setIsBodyShown}
        />
      );
    } else {
      return (
        <>
          {correction && (
            <CorrectionBlock questionData={correction?.correction_question} />
          )}
        </>
      );
    }
  };
  return (
    <Container>
      <FQQuestion>
        {parse(card.training_question.question.statement)}
      </FQQuestion>
      {!isCorrectionRevealed ? renderStatements() : renderCorrections()}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  overflow: hidden;
`;

const FQQuestion = styled.div`
  width: 90%;
  text-align: justify;
  text-justify: inter-word;
  font-weight: 400;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
`;

const ErrorMessage = styled(TextBody2)`
  text-align: center;
  margin: 1rem;
  color: ${({ theme }) => theme.colors.error.main};
  display: inline;
`;
