import { SectionTitle } from 'pages/newHome/ui/Global';
import React from 'react';
import styled from 'styled-components';
import BlueCard from 'ui/Cards/BlueCard';
import { CollectionRow } from './CollectionRow';
import {
  SpecialityProgressCollectionDPs,
  SpecialityProgressCollectionQIs,
} from 'modules/progress/detailed/session/http/types';

interface Props {
  type: 'QI' | 'DP';
  data: (SpecialityProgressCollectionDPs | SpecialityProgressCollectionQIs)[];
  title: string;
}

export const SpecialtyProgressCard: React.FC<Props> = ({
  type,
  title,
  data,
}) => {
  return (
    <BlueCard fullWidth $mediumPadding gap="1rem" noMargin>
      <ScrollContainer>
        <SectionTitle bold>{title}</SectionTitle>
        {data.map((row) => (
          <CollectionsContainer key={row.collection}>
            <CollectionTitle>{row.collection}</CollectionTitle>
            <QuestionsContainer>
              {row.questions.map((question) => (
                <CollectionRow
                  question={question}
                  type={type}
                  key={question.id}
                  collection={row.collection}
                />
              ))}
            </QuestionsContainer>
          </CollectionsContainer>
        ))}
      </ScrollContainer>
    </BlueCard>
  );
};

const ScrollContainer = styled.div`
  overflow-y: auto;
  max-height: 600px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CollectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CollectionTitle = styled.h4<{ fontWeight?: string }>`
  font-size: 1.25rem;
  font-weight: ${(props) => props.fontWeight || '600'};
  margin: 0;
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
