import { useTour } from '@reactour/tour';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import styled from 'styled-components/macro';
import { DotSeparator } from 'ui/DotSeparator';

export const DpQiSelectionCount = () => {
  const { isOpen } = useTour();
  const dpSelectedItems = useSelector(
    (state: RootState) => state.dpTable.selectedItemsInfos.length
  );
  const qiSelectedItems = useSelector(
    (state: RootState) => state.qiTable.selectedItemsInfos.length
  );

  return (
    <Container>
      Ma sélection : {isOpen ? 5 : dpSelectedItems} DP <DotSeparator />{' '}
      {isOpen ? 0 : qiSelectedItems} QI
    </Container>
  );
};

const Container = styled.h2`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
`;
