import { patchAPI } from 'auth';
import { SaveQuestionQCMRequestType } from './types';

export const saveQuestionQCM = async (params: SaveQuestionQCMRequestType) => {
  const response = await patchAPI<void, SaveQuestionQCMRequestType>(
    '/training/save_question_qcm',
    params
  );

  return response.data;
};
