import { FC } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

interface Props {
  className?: string;
  legend?: string;
}

export const Help: FC<Props> = (props) => {
  return (
    <Container>
      {props.legend ? (
        parse(props.legend)
      ) : (
        <Text>
          Cliquez sur la proposition qui vous semble adéquate :<br />{' '}
          <strong>-2</strong> L’hypothèse est pratiquement éliminée <br />{' '}
          <strong>-1</strong> L’hypothèse devient moins probable <br /> &nbsp;
          <strong>0</strong> L’information n’a aucun effet sur l’hypothèse{' '}
          <br /> <strong>+1</strong> L’hypothèse devient plus probable <br />{' '}
          <strong>+2</strong> Il ne peut s’agir pratiquement que de cette
          hypothèse
        </Text>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding: 1rem;
  margin: 2rem 0;
  gap: 4px;
  background: #cde0f6;
  border-radius: 4px;

  p {
    display: 'block';
    font-family: 'FreeSans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #01162d;
    margin: 0;
    width: 100%;
    strong {
      font-family: 'FreeSans';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
    }
  }
`;

const Text = styled.p`
  display: 'block';
  font-family: 'FreeSans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #01162d;
  margin: 0;
  width: 100%;
  strong {
    font-family: 'FreeSans';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
  }
`;
