import { DPProgressBySession } from '../entities/DPProgressBySession';
import { DPProgressBySpecialty } from '../entities/DPProgressBySpecialty';

export const getDPProgressBySpecialty = (
  progressBySession: DPProgressBySession[] | undefined,
  isUserPremium: boolean
): DPProgressBySpecialty[] | undefined => {
  if (progressBySession === undefined) {
    return undefined;
  }

  const progressBySpecialtyMap = new Map<
    string,
    Map<string, DPProgressBySession>
  >();

  for (let sessionProgress of progressBySession) {
    const sessionId = sessionProgress.collection;
    for (let question of sessionProgress.groups) {
      const spe = question.speTrigram1;
      if (spe === undefined) continue;

      if (!question.isFree && !isUserPremium) {
        if (!progressBySpecialtyMap.has(spe)) {
          progressBySpecialtyMap.set(
            spe,
            new Map<string, DPProgressBySession>()
          );
        }
        continue;
      }

      if (progressBySpecialtyMap.has(spe)) {
        const speSessionMap = progressBySpecialtyMap.get(spe)!;
        if (speSessionMap.has(sessionId)) {
          const previousSessionData = speSessionMap.get(sessionId)!;
          previousSessionData.groups = [
            ...previousSessionData.groups,
            question,
          ];
          speSessionMap.set(sessionId, previousSessionData);
        } else {
          speSessionMap.set(sessionId, {
            collection: sessionProgress.collection,
            groups: [question],
          });
        }
      } else {
        progressBySpecialtyMap.set(
          spe,
          new Map<string, DPProgressBySession>().set(sessionId, {
            collection: sessionProgress.collection,
            groups: [question],
          })
        );
      }
    }
  }

  const res: DPProgressBySpecialty[] = [];

  progressBySpecialtyMap.forEach((sessionsMap, spe) => {
    res.push({
      speTrigram: spe,
      sessions: Array.from(sessionsMap.values()),
    });
  });

  return res;
};
