export const ArrowRight = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.96289 5L8.96289 5M8.96289 5L4.96289 9M8.96289 5L4.96289 1"
        stroke="#021427"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.96289 5L8.96289 5M8.96289 5L4.96289 9M8.96289 5L4.96289 1"
        stroke="black"
        strokeOpacity="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.96289 5L8.96289 5M8.96289 5L4.96289 9M8.96289 5L4.96289 1"
        stroke="black"
        strokeOpacity="0.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
