import { FC } from 'react';
import { CorrectionQuestion } from 'types/correction.type';
import { QCMCorrectionBlock } from '../QCM/QCMCorrectionBlock';
import { QRPLCorrectionBlock } from '../QRPL/QRPLCorrectionBlock';
import { TCSCorrectionBlock } from '../TCS/TCSCorrectionBlock';
import { QROCCorrectionBlock } from '../QROC/QROCCorrectionBlock';
import { QZPCorrectionBlock } from '../QZP/QZPCorrectionBlock';
import { Type } from 'types/correction.type';

export const getQuestionCorrectionComponent = (
  type: Type
): FC<{ questionData: CorrectionQuestion }> => {
  switch (type) {
    case 'QRM':
      return QCMCorrectionBlock;

    case 'QRU':
      return QCMCorrectionBlock;

    case 'QRP':
      return QCMCorrectionBlock;

    case 'QRPL':
      return QRPLCorrectionBlock;

    case 'TCS':
      return TCSCorrectionBlock;

    case 'QROC':
      return QROCCorrectionBlock;

    case 'QZP':
      return QZPCorrectionBlock;

    default:
      return (questionData) => (
        <div style={{ padding: 16 }}>
          Correction non implémenté pour{' '}
          {questionData.questionData.question.num}
        </div>
      );
  }
};
