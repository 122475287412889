import { useEffect, useState } from 'react';

import { getPromotions } from 'http/promotions-list/api';
import { Option } from 'ui/Dropdown';

export const useGetPromotions = () => {
  const [promotions, setPromotions] = useState<Option[]>([]);

  useEffect(() => {
    async function getPromotionsFromAPI() {
      const promos = await getPromotions();
      if (promos) {
        setPromotions(
          promos.map((el) => ({
            label: el.name,
            value: el.code,
          }))
        );
      }
    }

    getPromotionsFromAPI();
  }, []);

  return promotions;
};
