import { FC, SVGAttributes } from 'react';

type SVGNativeProps = SVGAttributes<SVGElement>;

export const LockIcon: FC<SVGNativeProps> = (props) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.504 6.37c-.022-.815.055-1.532.269-2.09.162-.42.385-.705.68-.899.3-.198.8-.381 1.645-.381.847 0 1.323.184 1.596.369.263.178.464.444.604.856.193.567.237 1.303.178 2.144H7.504Zm-3.002 0c-.021-1.066.09-2.173.47-3.166.344-.894.921-1.73 1.832-2.33C7.707.282 8.818 0 10.098 0c1.277 0 2.387.28 3.281.887.904.614 1.452 1.47 1.76 2.373.332.978.4 2.064.343 3.11H17a3 3 0 0 1 3 3v7.633a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V9.369a3 3 0 0 1 3-3h1.502Zm6.44 6.587A1.941 1.941 0 0 0 12 11.238c0-1.075-.895-1.947-2-1.947s-2 .872-2 1.947c0 .745.429 1.391 1.059 1.72L8 17.08h4l-1.059-4.124Z"
        fill="currentColor"
      />
    </svg>
  );
};
