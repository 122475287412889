import { FC, SVGAttributes } from 'react';

type SVGNativeProps = SVGAttributes<SVGElement>;

export const FileIcon: FC<SVGNativeProps> = (props) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      {...props}
    >
      <path
        d="M1 6.007 4.49 1v5.007H1Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 13V6.007h3.49V1H13v12H1Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        d="M6 4.25h5M6 6.5h5M6 8.75h5M6 11h5"
      />
    </svg>
  );
};
