export const EyeOpen = () => {
  return (
    <svg
      width="30"
      height="17"
      viewBox="0 0 30 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.08187 12.952C5.80117 11.8092 5.07895 11.3598 2 8.90074C5.07895 5.38774 9.93684 1.52344 15.6842 1.52344C16.8246 1.52344 19.4854 1.75201 21.0058 2.66629"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8.08187 12.952C5.80117 11.8092 5.07895 11.3598 2 8.90074C5.07895 5.38774 9.93684 1.52344 15.6842 1.52344C16.8246 1.52344 19.4854 1.75201 21.0058 2.66629"
        stroke="currentColor"
        strokeOpacity="0.2"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8.08187 12.952C5.80117 11.8092 5.07895 11.3598 2 8.90074C5.07895 5.38774 9.93684 1.52344 15.6842 1.52344C16.8246 1.52344 19.4854 1.75201 21.0058 2.66629"
        stroke="currentColor"
        strokeOpacity="0.2"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21.006 2.66699C25.1873 5.33366 25.9475 6.47652 28.0002 8.90144C25.9475 11.3605 20.6107 14.9438 15.6844 15.2248C14.2906 15.2294 10.8189 14.7813 8.08203 12.9527"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21.006 2.66699C25.1873 5.33366 25.9475 6.47652 28.0002 8.90144C25.9475 11.3605 20.6107 14.9438 15.6844 15.2248C14.2906 15.2294 10.8189 14.7813 8.08203 12.9527"
        stroke="currentColor"
        strokeOpacity="0.2"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21.006 2.66699C25.1873 5.33366 25.9475 6.47652 28.0002 8.90144C25.9475 11.3605 20.6107 14.9438 15.6844 15.2248C14.2906 15.2294 10.8189 14.7813 8.08203 12.9527"
        stroke="currentColor"
        strokeOpacity="0.2"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <ellipse
        cx="15.4562"
        cy="7.23851"
        rx="4.94152"
        ry="4.95238"
        fill="currentColor"
      />
      <ellipse
        cx="15.4562"
        cy="7.23851"
        rx="4.94152"
        ry="4.95238"
        fill="black"
        fillOpacity="0.2"
      />
      <ellipse
        cx="15.4562"
        cy="7.23851"
        rx="4.94152"
        ry="4.95238"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M15.4561 12.1904C18.1852 12.1904 20.3976 9.97317 20.3976 7.23805C20.3976 5.40948 19.5361 4.4444 19.1053 4.19043L11.5029 9.90472C12.1111 11.7333 14.3918 12.1904 15.4561 12.1904Z"
        fill="currentColor"
      />
      <path
        d="M15.4561 12.1904C18.1852 12.1904 20.3976 9.97317 20.3976 7.23805C20.3976 5.40948 19.5361 4.4444 19.1053 4.19043L11.5029 9.90472C12.1111 11.7333 14.3918 12.1904 15.4561 12.1904Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M15.4561 12.1904C18.1852 12.1904 20.3976 9.97317 20.3976 7.23805C20.3976 5.40948 19.5361 4.4444 19.1053 4.19043L11.5029 9.90472C12.1111 11.7333 14.3918 12.1904 15.4561 12.1904Z"
        fill="currentColor"
        fillOpacity="0.2"
      />
    </svg>
  );
};
