import { FC } from 'react';
import styled from 'styled-components/macro';

import { thinScrollbarStyleColored } from 'helpers/css/thin-scrollbar';

import { useDisplayModeContext } from 'pages/progress/displayModeContext';

import { DPProgressBySession } from './BySession/DPProgressBySession';
import { DPProgressBySpecialty } from './BySpecialty/DPProgressBySpecialty';
import { PreviewModalProvider } from './contexts/previewModalContext';
import { AddLocalSelectionToCartButton } from './components/AddLocalSelectionToCartButton';
import { AddToLocalCartProvider } from './contexts/addToLocalCartContext';
import { FlushLocalSelectionButton } from './components/FlushLocalSelectionButton';
import { device } from 'helpers/css/responsive';

export const DPProgress: FC = () => {
  const { displayMode } = useDisplayModeContext();

  return (
    <AddToLocalCartProvider>
      <PreviewModalProvider>
        <AddLocalSelectionToCartButton />
        <FlushLocalSelectionButton />
        <Container>
          {displayMode === 'collection' && <DPProgressBySession />}
          {displayMode === 'specialty' && <DPProgressBySpecialty />}
        </Container>
      </PreviewModalProvider>
    </AddToLocalCartProvider>
  );
};

const Container = styled.div`
  display: flex;
  gap: 16px;
  height: 100%;
  overflow-x: auto;

  padding: 0 var(--horizontal-padding) 4px;
  @media ${device.desktop} {
    padding: 0 var(--horizontal-padding) 32px;
  }

  ${thinScrollbarStyleColored}
`;
