import Tippy, { TippyProps } from '@tippyjs/react';
import { FC, ReactElement } from 'react';

type TooltipContent = string | string[];

interface TooltipProps extends Omit<TippyProps, 'content' | 'children'> {
  children: ReactElement;
  content?: TooltipContent;
  tooltipType?: 'simple' | 'list';
}

export const Tooltip: FC<TooltipProps> = ({
  children,
  content,
  arrow = true,
  tooltipType = 'simple',
  placement = 'top',
}) => {
  const createTooltipContent = (content: string | string[]) => {
    if (typeof content === 'string' && tooltipType === 'simple') {
      return content;
    } else if (Array.isArray(content) && tooltipType === 'list') {
      if (content.length > 1) {
        return (
          <ul>
            {content.map((label: string) => (
              <li key={label}>{label}</li>
            ))}
          </ul>
        );
      } else {
        return content[0];
      }
    }
    return null;
  };

  if (!content) {
    return <div>{children}</div>;
  }

  return (
    <Tippy
      content={createTooltipContent(content)}
      arrow={arrow}
      placement={placement}
      maxWidth={'200px'}
    >
      <div>{children}</div>
    </Tippy>
  );
};
