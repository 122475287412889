import { isAnswerCorrect } from 'pages/correction/active-correction-group/questions-correction-blocks/helpers/isAnswerCorrect';
import {
  CorrectionItemsQcm,
  CorrectionItemsQroc,
  CorrectionItemsQrpl,
  CorrectionItemsZap,
  CorrectionItemsTcs,
  FQCardCorrection,
  Type,
} from 'types/correction.type';

interface GetCorrectionWithUserReponse {
  correctionItems: CorrectionItemsQcm[];
  userResponse: Record<number, boolean>;
}
export const getCorrectionWithUserReponse = ({
  correctionItems,
  userResponse,
}: GetCorrectionWithUserReponse) => {
  return correctionItems.map((item) => ({
    ...item,
    answer: userResponse[item.id] || false,
  }));
};

export const getFQQCMQuality = ({
  correctionItems,
  userResponse,
  firstTryOfTheDay,
}: GetCorrectionWithUserReponse & { firstTryOfTheDay: boolean }) => {
  const correctionWithUserResponse = getCorrectionWithUserReponse({
    correctionItems,
    userResponse,
  });

  const boolArray = correctionWithUserResponse.map((i) =>
    isAnswerCorrect(i, true)
  );

  const hasAllRight = boolArray.every((i) => i === true);

  if (hasAllRight) {
    return [
      {
        label: 'Difficile',
        quality: firstTryOfTheDay ? 3 : null,
      },
      {
        label: 'Acquise',
        quality: firstTryOfTheDay ? 4 : null,
      },
      {
        label: 'Facile',
        quality: firstTryOfTheDay ? 5 : null,
      },
    ];
  }

  return [
    {
      label: 'À revoir',
      quality: firstTryOfTheDay ? 0 : null,
    },
    {
      label: 'Oubli',
      quality: firstTryOfTheDay ? 1 : null,
    },
    {
      label: 'Inattention',
      quality: firstTryOfTheDay ? 2 : null,
    },
  ];
};

export const getQuestionCorrectionFromType = (
  questionType: Type,
  correction: FQCardCorrection
):
  | CorrectionItemsQcm[]
  | CorrectionItemsQroc
  | CorrectionItemsQrpl[]
  | CorrectionItemsZap
  | CorrectionItemsTcs[]
  | null => {
  switch (questionType) {
    case 'QRU':
      return correction.correction_question.correction_items_qcm;
    case 'QRM':
      return correction.correction_question.correction_items_qcm;
    case 'QRP':
      return correction.correction_question.correction_items_qcm;
    case 'QROC':
      return correction.correction_question.correction_item_qroc;
    case 'QRPL':
      return correction.correction_question.correction_items_qrpl;
    case 'QZP':
      return correction.correction_question.correction_item_qzp;
    case 'TCS':
      return correction.correction_question.correction_items_tcs;
    default:
      return null;
  }
};
