import { FC } from 'react';
import styled from 'styled-components/macro';

import { device } from 'helpers/css/responsive';
import { SessionBlocks } from './SessionBlocks';
import { SessionProgress } from './SessionProgress';
import { SessionProvider } from './sessionContext';

interface Props {}

export const DetailBySession: FC<Props> = () => {
  return (
    <Container>
      <SessionProvider>
        <SessionProgress />
        <SessionBlocks />
      </SessionProvider>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  margin-top: 0px;
  overflow: hidden;

  @media ${device.desktop} {
    margin-top: 8px;
  }
`;
