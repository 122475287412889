import { useSearchParams } from 'react-router-dom';

export const useGetNumberQueryParams = (queryParam: string) => {
  const [searchParams] = useSearchParams();
  const taskId = searchParams.get(queryParam);

  if (taskId === null) return undefined;

  const taskIdAsNumber = parseInt(taskId);

  if (isNaN(taskIdAsNumber)) {
    return undefined;
  }

  return taskIdAsNumber;
};
