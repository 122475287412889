import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getSpecialitiesList } from './api';

import { SpeRankInfo } from 'modules/progress/general/shared/useCases/sortProgressBySpecialty';
import { MS_IN_AN_HOUR } from 'constants/date';
import { SpecialitiesListResponse } from 'http/selection/specialities-list/types';

export const useGetSpecialities = () => {
  const query = useQuery('selectionSpecialitiesList', getSpecialitiesList, {
    staleTime: MS_IN_AN_HOUR,
    cacheTime: MS_IN_AN_HOUR,
  });
  const currentCCB = useMemo<SpecialitiesListResponse | undefined>(
    () => query.data,
    [query.data]
  );
  return {
    ...query,
    currentCCB,
  };
};

export const useGetSpecialityInfos = (speTrigram: string | undefined) => {
  const { data: specialties } = useGetSpecialities();

  const spe = specialties?.find((spe) => spe.trigram === speTrigram);

  return spe;
};

export const useGetSpecialityTrigramByName = (name: string | undefined) => {
  const { data: specialties } = useGetSpecialities();

  const spe = specialties?.find((spe) => spe.name === name);

  return spe?.trigram;
};

export const useGetSpecialitiesList = () => {
  const query = useGetSpecialities();

  const mappedOptions = useMemo(
    () =>
      query.data?.map((specialities) => ({
        label: specialities.name,
        value: specialities.trigram,
      })),
    [query.data]
  );

  if (query.data === undefined) return query;

  return {
    ...query,
    data: {
      options: mappedOptions,
    },
  };
};

export const useGetSpecialitiesListSorted = () => {
  const query = useGetSpecialities();

  const mappedOptions = useMemo(
    () =>
      query.data
        ?.sort((spe1, spe2) => {
          return spe1.rank - spe2.rank;
        })
        .map((specialities) => ({
          label: specialities.name,
          value: specialities.trigram,
        })),
    [query.data]
  );

  if (query.data === undefined) return query;

  return {
    ...query,
    data: {
      options: mappedOptions,
    },
  };
};

export const useGetSpecialtiesRankInfos = () => {
  const query = useGetSpecialities();

  const rankInfos = useMemo(
    () =>
      query.data?.map<SpeRankInfo>((spe) => ({
        rank: spe.rank,
        trigram: spe.trigram,
      })) ?? [],
    [query.data]
  );

  return rankInfos;
};
