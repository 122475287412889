import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { RootState } from 'store';
import { device } from 'helpers/css/responsive';
import { Radio } from 'ui/Radio';

import { GoBackToSelectionLink } from './components/GoBackToSelectionLink';
import { DPQIView } from './components/DPQIView';
import { LCAView } from './components/LCAView';
import {
  StartQuestionsCart,
  updateSelectedCart,
} from 'store/slices/startCartSlice';
import { useEffect } from 'react';

export const START_QUERY_PARAM = 'type';

export type StartQueryParam = 'LCA' | 'DPQI';

export const Start = () => {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const type = searchParams.get(START_QUERY_PARAM);

  useEffect(() => {
    if (type) {
      if (type === 'LCA') {
        setSelectedCart('lca');
      }
      if (type === 'DPQI') {
        setSelectedCart('dpqi');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const selectedCart = useSelector(
    (state: RootState) => state.startCart.selectedCart
  );

  const setSelectedCart = (cart: StartQuestionsCart) => {
    dispatch(updateSelectedCart(cart));
  };

  return (
    <Container id="start-page">
      <Layout>
        <TitleRow>
          <h1>Démarrer</h1>
          <GoBackToSelectionLink />
        </TitleRow>

        <TableOptionsContainer>
          <TableOptionRow>
            <Radio
              checked={selectedCart === 'dpqi'}
              onChange={() => setSelectedCart('dpqi')}
              id="dpqi"
            />
            <label htmlFor="dpqi">DP/QI</label>
          </TableOptionRow>
          <TableOptionRow>
            <Radio
              checked={selectedCart === 'lca'}
              onChange={() => setSelectedCart('lca')}
              id="lca"
            />
            <label htmlFor="lca">LCA</label>
          </TableOptionRow>
        </TableOptionsContainer>

        {selectedCart === 'dpqi' && <DPQIView />}

        {selectedCart === 'lca' && <LCAView />}
      </Layout>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Layout = styled.div`
  width: 100%;
  max-width: 1300px;
  padding: 12px 8px 8px;

  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;

  @media ${device.desktop} {
    padding: 32px;
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  gap: 64px;
  width: 100%;
`;

const TableOptionsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
`;

const TableOptionRow = styled.div`
  display: flex;
  gap: 8px;
`;
