import { useQuery } from 'react-query';
import { useGetKnowledgeList } from './useGetKnowledgesList';
import { Knowledge } from 'types/knowledge.type';
import { createMapIdToObject } from 'modules/shared/createMapIdToObject';
import { MS_IN_AN_HOUR } from 'constants/date';

export type knowledgeMapById =
  | {
      [knowledgeId: string]: Knowledge;
    }
  | undefined;

export const useGetKnowledgeMapById = (
  knowledgesIds: string[]
): knowledgeMapById => {
  const knowledgeQuery = useGetKnowledgeList(knowledgesIds);

  const { data } = useQuery(
    ['knowledgeQuery', knowledgeQuery],
    () => {
      const knowledges = knowledgeQuery;
      return createMapIdToObject(knowledges, 'knowledge') as {
        [knowledge: string]: Knowledge;
      };
    },
    { staleTime: MS_IN_AN_HOUR, cacheTime: MS_IN_AN_HOUR }
  );

  return data;
};
