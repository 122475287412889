import { FC } from 'react';
import styled from 'styled-components/macro';

import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';
import { SpecialtyIcon } from 'ui/icons/SpecialtyIcon/SpecialtyIcon';
import { LockIcon } from 'ui/icons/Lock';
import { Tooltip } from 'components/Tooltips/Tooltip';

interface Props {
  speTrigram?: string;
  isFree: boolean;
  shouldHideIfLocked?: boolean;
}

export const SpeIcon: FC<Props> = ({
  speTrigram,
  isFree,
  shouldHideIfLocked,
}) => {
  const isUserFree = useIsUserFree();

  const isDataLocked = isUserFree && !isFree;

  if (isDataLocked && shouldHideIfLocked) {
    return null;
  }

  if (speTrigram) {
    if (isDataLocked) {
      return (
        <Tooltip content="Vous devez être un utilisateur premium pour voir la spécialité">
          <LockContainer>
            <LockIcon />
          </LockContainer>
        </Tooltip>
      );
    }

    return <StyledSpecialtyIcon trigram={speTrigram} />;
  }

  return <div></div>;
};

const LockContainer = styled.div`
  height: 20px;
  width: 20px;
`;

const StyledSpecialtyIcon = styled(SpecialtyIcon)`
  height: 24px;
  width: 24px;
`;
