import { FC } from 'react';
import styled from 'styled-components/macro';

import { thinScrollbarStyleColored } from 'helpers/css/thin-scrollbar';

import { useDisplayModeContext } from 'pages/progress/displayModeContext';
import { CorrectionModalProvider } from './contexts/correctionModalContext';
import { PreviewModalProvider } from './contexts/previewModalContext';
import { AddToLocalCartProvider } from './contexts/addToLocalCartContext';

import { QIProgressBySession } from './BySession/QIProgressBySession';
import { QIProgressBySpecialty } from './BySpecialty/QIProgressBySpecialty';
import { AddLocalSelectionToCartButton } from './components/AddLocalSelectionToCartButton';
import { FlushLocalSelectionButton } from './components/FlushLocalSelectionButton';
import { device } from 'helpers/css/responsive';

export const QIProgress: FC = () => {
  const { displayMode } = useDisplayModeContext();

  return (
    <AddToLocalCartProvider>
      <PreviewModalProvider>
        <CorrectionModalProvider>
          <AddLocalSelectionToCartButton />
          <FlushLocalSelectionButton />
          <Container>
            {displayMode === 'collection' && <QIProgressBySession />}
            {displayMode === 'specialty' && <QIProgressBySpecialty />}
          </Container>
        </CorrectionModalProvider>
      </PreviewModalProvider>
    </AddToLocalCartProvider>
  );
};

const Container = styled.div`
  display: flex;
  gap: 16px;
  height: 100%;
  overflow-x: auto;

  padding: 0 var(--horizontal-padding) 4px;
  @media ${device.desktop} {
    padding: 0 var(--horizontal-padding) 32px;
  }

  ${thinScrollbarStyleColored}
`;
