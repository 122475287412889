import { FC, useEffect } from 'react';
import styled from 'styled-components';
// TODO: remove
// import { QRPLDropDown } from './QRPLDropdown';
// import { getDropdownStyle, Option } from './dropdownStyled';
// import { SingleValue } from 'react-select';
import { Checkbox } from '../ui/Checkbox';
import { alphabet } from 'pages/new_training/constants';

export interface Props {
  answers: Array<{
    id: number;
    answer: boolean;
    question_item: {
      text: string;
    };
  }>;
  disabled: boolean;
  handleChange(index: number): void;
}

// const NoValueOption = {
//   label: 'Sans réponse',
//   value: -1,
// };

export const NewSingleAnswer: FC<Props> = (props) => {
  //   const [options, setOptions] = useState<Array<Option>>(
  //     props.answers
  //       .map((answer, index) => ({
  //         label: answer.question_item.text,
  //         value: index,
  //       }))
  //       .concat([NoValueOption])
  //   );

  //   const [valueIndex, setValueIndex] = useState<number>(
  //     props.answers.findIndex((el) => el.answer)
  //   );

  useEffect(() => {}, [props.answers]);

  //   useEffect(() => {
  //     setOptions(
  //       props.answers
  //         .map((answer, index) => ({
  //           label: answer.question_item.text,
  //           value: index,
  //         }))
  //         .concat([NoValueOption])
  //     );
  //     setValueIndex(props.answers.findIndex((el) => el.answer));
  //     // eslint-disable-next-line
  //   }, [props.answers[0]?.question_item.text]);

  //   TODO: remove
  //   const handleChange = (newValue: SingleValue<Option>) => {
  //     if (newValue?.value !== undefined) {
  //       setValueIndex(newValue.value);
  //       if (newValue?.value > -1) {
  //         props.handleChange(newValue.value);
  //       }
  //     }
  //   };

  const newHandleChange = (index: number) => () => {
    props.handleChange(index);
  };

  return (
    <Container>
      {props.answers.map((answer, index) => {
        return (
          <Wrapper key={index}>
            <StyledCheckbox
              checked={props.answers[index].answer}
              disabled={props.disabled}
              onChange={newHandleChange(index)}
            />
            <div>
              <Title>Réponse {alphabet[index]}</Title>
              <Response>{answer.question_item.text}</Response>
            </div>
            {/* TODO: remove */}
            {/* <QRPLDropDown
              options={options}
              onChange={handleChange}
              value={valueIndex > -1 ? options[valueIndex] : null}
              placeholder="Déroulez pour voir les propositions"
              styles={getDropdownStyle()}
              isDisabled={props.disabled}
            /> */}
          </Wrapper>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
`;

const Response = styled.div`
  font-size: 0.875rem;
  color: grey;
  font-family: 'Freesans';
`;
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 38px auto;
  gap: 1rem;
  align-items: center;
`;
const StyledCheckbox = styled(Checkbox)`
  input {
    width: 38px;
    height: 38px;
  }
`;
const Title = styled.div`
  font-weight: bold;
  font-family: 'Freesans';
  font-size: 1rem;
`;
