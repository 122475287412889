import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { getCategoriesList } from './api';
import { MS_IN_AN_HOUR } from 'constants/date';

export const useGetCategories = () => {
  return useQuery('selectionCategoriesList', getCategoriesList, {
    staleTime: MS_IN_AN_HOUR,
    cacheTime: MS_IN_AN_HOUR,
  });
};

export const useGetCategoriesList = () => {
  const query = useGetCategories();

  const mappedOptions = useMemo(
    () =>
      query.data?.map((item) => ({
        label: `${item.code} - ${item.name}`,
        name: item.name,
        value: item.code,
      })),
    [query.data]
  );

  if (query.data === undefined) return query;

  return {
    ...query,
    data: {
      options: mappedOptions,
    },
  };
};
