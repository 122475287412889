import { useQuery } from 'react-query';
import { SessionDetailedProgressParams } from '../domain/entities/SessionDetailedProgressParams';

import { getSessionDetailedProgress } from '../domain/useCases/getSessionDetailedProgress';
import { MS_IN_AN_HOUR } from 'constants/date';

export const USE_GET_SESSION_DETAILED_PROGRESS_KEY =
  'useGetSessionDetailedProgress';

export const useGetSessionDetailedProgress = (
  params?: SessionDetailedProgressParams
) => {
  return useQuery(
    [USE_GET_SESSION_DETAILED_PROGRESS_KEY, params],
    () => getSessionDetailedProgress(params!),
    {
      cacheTime: MS_IN_AN_HOUR,
      staleTime: MS_IN_AN_HOUR,
      enabled: params !== undefined,
    }
  );
};
