import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';
import { pauseTimer } from 'http/training/pause-timer/api';
import { toast } from 'react-toastify';

export const pauseTimerFromAPI = async (
  taskId: number,
  onSuccess: () => void,
  onError: () => void
) => {
  try {
    const timer = await pauseTimer(taskId);
    onSuccess();
    return timer;
  } catch (e) {
    onError();
    toast.error(
      "Nous n'avons pas réussi à stopper le timer côté serveur. Vérifiez votre connection."
    );
    reportErrorToSentry(e);
  }
};
