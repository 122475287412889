import { FC } from 'react';
import styled from 'styled-components/macro';

import { Loader } from 'components/Loader/Loader';

interface Props {
  loadingText?: string;
}

export const ContainedLoader: FC<Props> = ({ loadingText }) => {
  return (
    <Container id="loadind-container">
      <LoadingContent>
        <Loader />
        <h2>{loadingText ?? 'Chargement...'}</h2>
      </LoadingContent>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
`;

const LoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;
