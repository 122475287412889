import { forwardRef, InputHTMLAttributes, LabelHTMLAttributes } from 'react';
import styled from 'styled-components';

type NativeInputProps = InputHTMLAttributes<HTMLInputElement>;
type NativeLabelProps = LabelHTMLAttributes<HTMLLabelElement>;

export interface UnderlinedTextInputProps extends NativeInputProps {
  label?: string;
  labelProps?: NativeLabelProps;
}

export const UnderlinedTextInput = forwardRef<
  HTMLInputElement,
  UnderlinedTextInputProps
>(({ children, label, labelProps, className, ...restOfProps }, ref) => {
  return (
    <Container className={className}>
      <StyledLabel>{label}</StyledLabel>
      <TextInput type="text" {...restOfProps} ref={ref}>
        {children}
      </TextInput>
    </Container>
  );
});

const TextInput = styled.input`
  border: none;
  border-bottom: 2px solid ${({ theme }) => theme.colors.primary.dark};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary.dark};
  overflow: hidden;

  &:focus-visible,
  &:focus {
    outline: none;
  }

  padding: none;
  padding-bottom: 8px;
  width: 100%;
  ${({ theme }) => theme.typography.body}
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledLabel = styled.label`
  font-weight: 600;
  overflow: hidden;
`;
