import { useQuery } from 'react-query';
import { getCorrectionGroup } from './api';

export const useGetCorrectionGroup = (correctionId: number) => {
  return useQuery(['correctionGroup', correctionId], () =>
    getCorrectionGroup(correctionId)
  );
};

export const useGetQICorrection = (
  correctionGroupId: number,
  qiIdInCorrection: number
) => {
  return useQuery(
    ['correctionQI', correctionGroupId, qiIdInCorrection],
    async () => {
      const qiCorrection = (
        await getCorrectionGroup(correctionGroupId)
      ).correction_questions.find(
        (correction) => correction.id === qiIdInCorrection
      );

      if (qiCorrection) return qiCorrection;

      throw Error(
        "Couldn't find the QI correction in the given group corection"
      );
    },
    { enabled: correctionGroupId !== undefined }
  );
};
