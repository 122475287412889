/**
 * Returns an array with two arrays at index 0 and 1.
 * The array at index 0 is all the items in arr that passed the predicate truth test by returning a truthy value.
 * The array at index 1 is all the items in arr that failed the predicate truth test by returning a falsy value.
 * @param array
 * @param predicate
 */
export const partition = <T>(
  array: T[],
  predicate: (value: T) => boolean
): T[][] => {
  const filterTrue: T[] = [];
  const filterFalse: T[] = [];

  array.forEach((value) => {
    if (predicate(value)) {
      filterTrue.push(value);
    } else {
      filterFalse.push(value);
    }
  });

  return [filterTrue, filterFalse];
};
