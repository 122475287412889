import styled from 'styled-components';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { PageTitle } from 'ui/Titles/PageTitle';
import { ButtonBase } from 'ui/Buttons/ButtonBase';
import { HelpTooltip } from 'ui/icons/HelpTooltip';
import { InfoModal } from '../Modales/InfoModal';
import { TrainingLaunch } from './TrainingBlocks/TrainingLaunch';
import { TrainingList } from './TrainingBlocks/TrainingList';

export const LauchingPage = () => {
  const { collection } = useSelector((state: any) => state.ccb);

  const [knowmoreModalOpen, setKnowmoreModalOpen] = useState<boolean>(false);

  const handleClick = () => {
    setKnowmoreModalOpen(!knowmoreModalOpen);
  };

  const closeModal = () => setKnowmoreModalOpen(false);

  return (
    <Container>
      <Head>
        <PageTitle>{collection?.label}</PageTitle>
        <ButtonBase
          size={'large'}
          disabled={false}
          styled={'secondary'}
          iconOnly={false}
          onClick={handleClick}
          hasPadding={true}
        >
          En savoir plus
          <IconContainer>
            <HelpTooltip />
          </IconContainer>
        </ButtonBase>
        <InfoModal isOpen={knowmoreModalOpen} closeModal={closeModal} />
      </Head>
      <TrainingLaunch />
      <TrainingList />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const Head = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  max-width: 100%;
  margin-right: 3rem;
`;

const IconContainer = styled.div`
  height: 16px;
  width: 16px;
  flex-shrink: 0;
  margin-top: -12px;
`;
