import styled, { css } from 'styled-components';
import { FC } from 'react';
import { createNumberArray } from 'helpers/utils';

interface StudyToursProps {
  activeTourNumber: number;
  big?: boolean;
  handleClick: (tour: number) => void;
}

export const StudyTours: FC<StudyToursProps> = ({
  activeTourNumber,
  big,
  handleClick,
}) => {
  return (
    <Container big={big}>
      Tour de révision :
      {createNumberArray(5).map((tour: number, index: number) => (
        <Tour
          big={big}
          key={`${tour}-${index}`}
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            handleClick(tour);
          }}
          isSelected={tour === activeTourNumber}
        >
          {tour}
        </Tour>
      ))}
    </Container>
  );
};

const Container = styled.div<{ big?: boolean }>`
  ${({ theme, big }) => (big ? theme.typography.body : theme.typography.small)}
  font-weight: bold;

  color: ${({ theme }) => theme.colors.inactive};
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  z-index: 10;
`;

const Tour = styled.div<{ isSelected: boolean; big?: boolean }>`
  ${({ theme, big }) => (big ? theme.typography.body : theme.typography.small)}
  font-weight: bold;

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.colors.secondary.main};
      font-weight: bold;
      font-size: 2px;
      line-height: 28px;
    `}
  &:hover {
    cursor: pointer;
    font-weight: bold;
    transition: transform 0.2s ease-in-out;
    color: ${({ theme }) => theme.colors.primary.dark};
  }
`;
