import styled from 'styled-components/macro';
import { device } from 'helpers/css/responsive';
import { AccountMenu } from './AccountMenu';
import { InfoDisplay } from './InfoDisplay';
import { AccountNavigationProvider } from './context/accountNavigationContext';

export const Account = () => {
  return (
    <AccountNavigationProvider>
      <Layout>
        <h1>Mon compte</h1>
        <Container>
          <Left>
            <AccountMenu />
          </Left>
          <Right>
            <InfoDisplay />
          </Right>
        </Container>
      </Layout>
    </AccountNavigationProvider>
  );
};

const Layout = styled.div`
  width: 100%;
  padding: 48px 32px;
  display: flex;
  flex-direction: column;
  align-items: start;
`;

const Container = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  width: 100%;

  @media ${device.desktop} {
    padding: 32px;
    flex-direction: row;
  }
`;

const Left = styled.div`
  @media ${device.desktop} {
    width: 30%;
    max-width: 380px;
  }
`;

const Right = styled.div`
  @media ${device.desktop} {
    width: 60%;
  }
`;
