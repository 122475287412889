import { FC, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { UseQueryResult } from 'react-query';
import styled from 'styled-components/macro';
import { toast } from 'react-toastify';

import { Button } from 'ui/Button';
import { ADD_LOCAL_SELECTION_TO_CART_BUTTON_ID } from 'pages/progress/GeneralProgress/GeneralProgress';

interface Props {
  results: UseQueryResult<any, unknown>[];
  handleClick: () => void;
}

export const AddLocalSelectionToCartGenericButton: FC<Props> = ({
  results,
  handleClick,
}) => {
  const isLoading = useMemo(
    () => results.some((query) => query.isLoading),
    [results]
  );

  const hasError = useMemo(
    () => results.some((query) => query.isError),
    [results]
  );

  useEffect(() => {
    if (hasError && !isLoading) {
      const refetches = results
        .filter((result) => result.isError)
        .map((result) => result.refetch({ throwOnError: true }));
      const refetchesPromise = Promise.all(refetches);

      toast.promise(refetchesPromise, {
        pending:
          "Toutes les informations de votre sélection n'ont pas pu être récupérées, nous réessayons",
        success: 'Vous pouvez ajouter votre sélection !',
        error:
          "Impossible de récupérer les informations pour le moment, vérifiez votre connexion ou passez par l'écran de sélection",
      });
    }
  }, [hasError, isLoading, results]);

  const [buttonPortalNode, setButtonPortalNode] = useState(
    document.getElementById(ADD_LOCAL_SELECTION_TO_CART_BUTTON_ID)
  );
  useEffect(() => {
    setButtonPortalNode(
      document.getElementById(ADD_LOCAL_SELECTION_TO_CART_BUTTON_ID)
    );
  }, []);

  if (!buttonPortalNode) {
    return null;
  }

  return createPortal(
    <StyledButton
      small
      disabled={isLoading}
      isLoading={isLoading}
      onClick={handleClick}
      id="add-local-selection-to-cart"
    >
      Ajouter les cases cochées à la sélection
    </StyledButton>,
    buttonPortalNode
  );
};

const StyledButton = styled(Button)`
  padding: 8px 16px;
`;
