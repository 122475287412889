import { FC } from 'react';
import styled from 'styled-components/macro';
import { Outlet, useLocation } from 'react-router-dom';

import { Menu } from './Menu';
import { Header } from './Header';
import { MenuContextProvider } from './menuContext';
import { OngoingTraining } from 'components/OngoingTraining/OngoingTraining';
import { OngoingCCBTraining } from 'components/OngoingTraining/OngoingCCB';
import Copyrights from 'components/Copyrights';
import BackendPopup from 'ui/BackendPopup';
import { useTour } from '@reactour/tour';
import React from 'react';
import {
  astrolabeSteps,
  homeSteps,
  selectionSteps,
  startSteps,
  avancementSteps,
  interfaceSteps,
  questionsflashSteps,
} from 'utils/tourSteps';

// import { RedirectionModal } from 'pages/concours-blanc/components/Modales/RedirectionModal';
import { NewsletterModal } from 'components/Newsletter/NewsletterModal';

export const MainLayout: FC = () => {
  const { setSteps, setCurrentStep } = useTour();
  const location = useLocation();

  React.useEffect(() => {
    if (setSteps) {
      setCurrentStep(0);

      switch (location.pathname) {
        case '/':
          setSteps(homeSteps);
          break;
        case '/astrolabe':
          setSteps(astrolabeSteps);
          break;
        case '/selection':
          setSteps(selectionSteps);
          break;
        case '/progress':
          setSteps(avancementSteps);
          break;
        case '/start':
          setSteps(startSteps);
          break;
        case '/correction':
          setSteps(interfaceSteps);
          break;
        case '/flash-questions':
          setSteps(questionsflashSteps);
          break;
        default:
          setSteps([]);
          break;
      }
    }
  }, [location.pathname, setCurrentStep, setSteps]);

  return (
    <>
      <NewsletterModal />
      {/* <RedirectionModal /> */}
      <BackendPopup />
      <MenuContextProvider>
        <MainContainer>
          <Header />
          <MenuAndContent>
            <Menu />
            <Content id="scrollable-container">
              <Outlet />
              <Copyrights />
            </Content>
          </MenuAndContent>
        </MainContainer>
        <OngoingTraining />
        <OngoingCCBTraining />
      </MenuContextProvider>
    </>
  );
};

const MainContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const MenuAndContent = styled.div`
  display: flex;
  flex-grow: 1;

  position: relative;
  overflow: hidden;
`;

const Content = styled.div`
  flex-grow: 1;
  overflow: auto;
  scroll-behavior: smooth;
`;
