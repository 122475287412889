import {
  POPUP,
  remove,
  VIEWED_POPUP_AT,
} from 'helpers/application/localStorage';

import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';

import { getSession, setSession } from './authCore';
import { APIError, postAPI } from './authentifiedHttpCalls';

interface LoginReturnType {
  token: string;
  session_token: string;
}

interface LoginCredentials {
  email: string;
  password: string;
}

export async function login(email: string, password: string) {
  const body = { email, password };
  try {
    const response = await postAPI<LoginReturnType, LoginCredentials>(
      '/auth/login',
      body
    );

    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.token
    ) {
      const jwt_token = response.data.token;
      const session_token = response.data.session_token;
      setSession(jwt_token, session_token);
    } else {
      throw Error('Unable to retrieve login token');
    }
  } catch (error: unknown) {
    if (error instanceof Error) {
      const err = error as APIError;
      err.errorMessages = err.response?.data.global;
    }
    throw error;
  }
}

export function logout() {
  localStorage.removeItem('jwt_token');
  localStorage.removeItem('session_token');
  localStorage.removeItem('expiry');
  remove(POPUP);
  remove(VIEWED_POPUP_AT);
}

export function isLoggedIn() {
  const { expiry, jwt_token, session_token } = getSession();
  if (expiry && jwt_token && session_token) {
    const expiry_date = new Date(parseInt(expiry) * 1000);
    const now = new Date();
    if (expiry_date.getTime() < now.getTime()) {
      logout();
      return false;
    }

    return true;
  } else {
    return false;
  }
}

interface RefreshTokenReturnType {
  token: string;
}

interface RefreshTokenRequestType {
  token: string;
}

export async function refreshToken() {
  try {
    const currentSession = getSession();
    if (!currentSession.jwt_token) {
      return;
    }
    const response = await postAPI<
      RefreshTokenReturnType,
      RefreshTokenRequestType
    >('/auth/refresh-token', {
      token: currentSession.jwt_token,
    });

    if (
      response &&
      response.status === 200 &&
      response.data.token &&
      currentSession.session_token
    ) {
      setSession(response.data.token, currentSession.session_token);
    }
  } catch (e) {
    reportErrorToSentry(e);
  }
}
