import { FC } from 'react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

import { Fallback } from './Fallback';

interface Props {}

export const ErrorBoundary: FC<Props> = ({ children }) => {
  return (
    <SentryErrorBoundary showDialog fallback={<Fallback />}>
      {children}
    </SentryErrorBoundary>
  );
};
