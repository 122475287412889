import {
  CCBRankingContainer,
  FirstHeaderCell,
  HeaderCell,
  HeaderCellText,
  LastHeaderCell,
  Cell,
} from './uiElements';
import { useSelector } from 'react-redux';
import React from 'react';
import styled from 'styled-components';
import { formatDateRange, formatTodayDate } from 'helpers/dates/utils';
import Tippy from '@tippyjs/react';
import { DESGroupRanking } from 'http/ccb/type';
import { formatGrade } from 'helpers/grades/utils';

export const CCBRankingTable = () => {
  const { CCB_start_date, CCB_end_date, ccbRanking } = useSelector(
    (state: any) => state.ccb
  );

  return (
    <Container>
      <h3>Mon classement par groupe de spécialité</h3>
      <CCBRankingContainer>
        <FirstHeaderCell>
          <CellContent>
            <HeaderCellText>Groupe de spécialité</HeaderCellText>
          </CellContent>
        </FirstHeaderCell>
        <HeaderCell>
          <HeaderCellText>Ma note </HeaderCellText>
        </HeaderCell>
        <HeaderCell>
          <Tippy
            content={`Classement incluant uniquement les participants de la période du ${formatDateRange(
              CCB_start_date,
              CCB_end_date,
              'du'
            )}`}
            arrow={false}
            placement="bottom"
          >
            <HeaderCellText>Rang sur la période </HeaderCellText>
          </Tippy>
        </HeaderCell>
        <LastHeaderCell>
          <Tippy
            content={`Classement général incluant l’ensemble des participants.(Maj ${formatTodayDate()})`}
            arrow={false}
            placement="bottom"
          >
            <HeaderCellText>Rang général</HeaderCellText>
          </Tippy>
        </LastHeaderCell>
        {ccbRanking?.des_group_ranking?.map((des_ranking: DESGroupRanking) => (
          <React.Fragment key={des_ranking.des_group}>
            <Cell>
              <CellContent>{des_ranking.des_group}</CellContent>
            </Cell>
            <Cell>{formatGrade(des_ranking.grade)}/20</Cell>
            <Cell>
              {des_ranking.period_rank !== null ? des_ranking.period_rank : '-'}
            </Cell>
            <Cell>{des_ranking.global_rank}</Cell>
          </React.Fragment>
        ))}
      </CCBRankingContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const CellContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  width: 100%;
`;
