import { YearData } from 'http/rank-info/easy-dispo/types';
import { DefaultTheme } from 'styled-components';

export interface SpecialtyDataType {
  // In typescript, if you define dynamic type, you cannot add a different type like total_accepted here, hence the | number to allow the named parameter total_accepted type
  [yearOrTotalAccepted: string]: YearData | number | string;
  total_accepted: number;
  label: string;
}

export const getStyle = (data: SpecialtyDataType, theme: DefaultTheme) => {
  const totalAccepted = data.total_accepted;

  const totalYears = Object.keys(data)
    .filter(
      (keyValue) => !(keyValue === 'total_accepted' || keyValue === 'label')
    )
    .map((year, index) => {
      const typedYear = data[year] as YearData;
      return typedYear.n_positions === 0 ? 0 : 1;
    })
    .reduce((total: number, curr: number) => total + curr, 0);

  return {
    background:
      totalYears === 0
        ? theme.colors.primary.light
        : totalAccepted === 0
        ? theme.colors.easyDispoSquares.red
        : totalAccepted === totalYears
        ? theme.colors.easyDispoSquares.green
        : theme.colors.easyDispoSquares.orange,

    '&:hover': {},
  };
};

export const getCellContent = (data: SpecialtyDataType) => {
  return Object.keys(data)
    .filter(
      (keyValue) => !(keyValue === 'total_accepted' || keyValue === 'label')
    )
    .map((year, index) => {
      const typedYear = data[year] as YearData;
      return typedYear.n_positions === 0 ? 0 : 1;
    })
    .reduce((total: number, curr: number) => total + curr, 0) === 0
    ? ''
    : Object.keys(data).filter((yearOrTotalAccepted) =>
        typeof data[yearOrTotalAccepted] === 'number'
          ? false
          : (data[yearOrTotalAccepted] as YearData).accepted
      ).length;
};
