import { FC } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components/macro';

import { useGetSimulatedRank } from 'http/rank-info/rang-limier/connector';
import { SessionRecap } from 'modules/recap/domain/entities/entity';
import { isSessionCompleted } from '../../../helpers/isSessionCompleted';
import { CopyIcon } from 'ui/icons/Copy';

interface Props {
  sessionRecap?: SessionRecap;
  collection: string | undefined;
}

export const Rank: FC<Props> = ({ sessionRecap, collection }) => {
  const isCompleted = isSessionCompleted(sessionRecap);
  const shouldComputeSimulatedRank = !!(
    sessionRecap !== undefined &&
    isCompleted &&
    collection === 'ECNi 2021'
  );

  const { data: rankInfos } = useGetSimulatedRank(
    {
      grade: sessionRecap?.grade ?? 0,
      year: Number(collection?.split(' ').pop()) ?? 2021,
    },
    shouldComputeSimulatedRank
  );

  const copyRankToClipboard = () => {
    if (rankInfos && navigator.clipboard) {
      navigator.clipboard.writeText(rankInfos?.rank.toString());
      toast.info('Votre rang a été copié dans le presse-papier');
    }
  };

  if (sessionRecap === undefined) {
    return null;
  }

  if (collection !== undefined && collection !== 'ECNi 2021') {
    return null;
  }

  if (shouldComputeSimulatedRank) {
    return (
      <RankContainer onClick={copyRankToClipboard}>
        <Copy /> Rang : {rankInfos?.rank}
      </RankContainer>
    );
  }

  return <>Rang disponible quand 100% effectué</>;
};

const Copy = styled(CopyIcon)`
  --size: 16px;
  height: var(--size);
  width: var(--size);
`;

const RankContainer = styled.span`
  cursor: pointer;
`;
