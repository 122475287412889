import { FC } from 'react';

import { RadioTexts } from 'ui/RadioTexts/RadioTexts';

import { RadioTextOptions } from 'ui/RadioTexts/types';
import { OptionLayout } from './OptionLayout';
import { Props as OptionLayoutProps } from './OptionLayout';
import { UserOptions } from 'modules/user-options/domain/entities/UserOptions';

interface Props extends OptionLayoutProps {
  options: RadioTextOptions[];
  preferences: UserOptions;
  field: keyof UserOptions;
  updateUserPreference: (modifiedOption: Partial<UserOptions>) => void;
}

export const RadioOption: FC<Props> = ({
  options,
  preferences,
  field,
  updateUserPreference,
  ...optionLayoutProps
}) => {
  return (
    <OptionLayout {...optionLayoutProps} canWrap={true}>
      <RadioTexts
        options={options}
        value={preferences[field] as string}
        onChange={(newValue) => updateUserPreference({ [field]: newValue })}
      />
    </OptionLayout>
  );
};
