import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';
import { useCanAddToCart } from 'pages/selection/hooks/useCanAddToCart';
import { RootState } from 'store';
import { SelectionGroupResult } from 'http/selection-group/types';
import { toggleItemSelection as toggleItemSelectionDP } from 'store/slices/dpTableSlice';
import { toggleItemSelection as toggleItemSelectionLCA } from 'store/slices/lcaTableSlice';
import { Checkbox } from 'ui/Checkbox';
import { GroupType } from 'modules/shared/entities/GroupType';
import { SpeQuestionGroup } from 'modules/progress/detailed/spe/domain/entities/SpeQuestionGroup';

interface Props {
  group: SpeQuestionGroup;
  groupType: GroupType;
}

export const CheckboxCell: FC<Props> = ({ group, groupType }) => {
  const dispatch = useDispatch();
  const canAddToCart = useCanAddToCart();
  const isUserFree = useIsUserFree();

  const selectedItems = useSelector((state: RootState) =>
    groupType === 'LCA'
      ? state.lcaTable.selectedItemsInfos
      : state.dpTable.selectedItemsInfos
  );

  const [isSelected, setIsSelected] = useState<boolean>(
    selectedItems.map((item) => item.id).includes(group.id)
  );

  useEffect(() => {
    setIsSelected(selectedItems.map((item) => item.id).includes(group.id));
    const toAdd =
      group.number_of_questions >= 13
        ? { short: 0, long: 1 }
        : { short: 1, long: 0 };
    canAddToCart(groupType, 1, toAdd);
  }, [group, selectedItems, canAddToCart, groupType]);

  const toggleSelection = () => {
    if (!isSelected) {
      const canAdd = canAddToCart(groupType);
      if (!canAdd) return;
    }
    dispatch(
      groupType === 'LCA'
        ? toggleItemSelectionLCA(
            adaptGroupToSelectionGroupResult(group, groupType)
          )
        : toggleItemSelectionDP(
            adaptGroupToSelectionGroupResult(group, groupType)
          )
    );
  };

  return (
    <Checkbox
      checked={isSelected}
      onChange={toggleSelection}
      disabled={!group.isFree && isUserFree}
    />
  );
};

export const adaptGroupToSelectionGroupResult = (
  group: SpeQuestionGroup,
  type: GroupType
): SelectionGroupResult => {
  return {
    grade: group.lastGrade ?? null,
    id: group.id,
    is_free: group.isFree,
    last_training: group.previousTrainings?.[0]?.groupCorrectionId ?? null,
    last_training_date:
      group.previousTrainings?.[0]?.date.toISOString() ?? null,
    num: group.num,
    composition_unit: group.composition_unit,
    number_of_questions: group.number_of_questions,
    collection: group.collection,
    thumbnail: group.thumbnail,
    specialty1: group.specialtyTrigram1 ?? null,
    specialty2: group.specialtyTrigram2 ?? null,
    total_coefficient: 1,
    type: type,
    denom: group.denom,
    median: group.median,
  };
};
