import { FC, SVGAttributes } from 'react';

type SVGNativeProps = SVGAttributes<SVGElement>;

export const CopyLined: FC<SVGNativeProps> = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7.38277L5.65455 0.706299V7.38277H1Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 16.7063V7.38277H5.65455V0.706299H17V16.7063H1Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="7.5"
        y1="5.2063"
        x2="14.5"
        y2="5.2063"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="7.5"
        y1="8.2063"
        x2="14.5"
        y2="8.2063"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="7.5"
        y1="11.2063"
        x2="14.5"
        y2="11.2063"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="7.5"
        y1="14.2063"
        x2="14.5"
        y2="14.2063"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </svg>
  );
};
