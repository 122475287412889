import styled from 'styled-components';
import { SearchDropdown } from '../components/SearchDropdown';
import { useHomeInfoContext } from '../context/homeInfoContext';
import { device } from 'helpers/css/responsive';
import { ItemsFilters } from '../components/ItemsFilters';
import { SpecialtiesFilters } from '../components/SpecialtiesFilters';
import { useHomeSearchContext } from '../context/homeSearchContext';

export const ResearchOptions = () => {
  const { activeTab } = useHomeInfoContext();
  const { searchedValue, searchDropdownOptions, handleSearch } =
    useHomeSearchContext();

  return (
    <Container>
      <SearchDropdown
        options={searchDropdownOptions}
        onChange={handleSearch}
        value={searchedValue}
        placeholder={
          activeTab === 'items'
            ? 'Rechercher par n° ou par nom d’item'
            : 'Rechercher par spécialité'
        }
      />
      <FilterContainer>
        {activeTab === 'specialties' ? (
          <SpecialtiesFilters />
        ) : (
          <ItemsFilters />
        )}
      </FilterContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 2rem;
  @media ${device.mobile} {
    flex-direction: column;
    justify-content: start;
  }
`;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: fit-content;
  flex-wrap: wrap;
  width: 100%;
  gap: 1rem;
  @media ${device.mobile} {
    justify-content: start;
    gap: 0.5rem;
    max-width: 100%;
  }
`;
