import { createGlobalStyle } from 'styled-components/macro';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background-color: ${({ theme }) => theme.colors.background.main};
    transition: background-color 200ms;
    color: ${({ theme }) => theme.colors.primary.dark};

    ${({ theme }) => theme.typography.body}
  }

  #root, html, body {
    height: 100%;
  }

  *, *:before, *:after {
    box-sizing: border-box;
    ${({ theme }) => theme.typography.fontFamily}

  // Prevent text selection
   -moz-user-select: -moz-none;
   -khtml-user-select: none;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
  }

  input {
    user-select: text;
  }

  h1, h2, h3, h4, h5 {
    margin: 0;
  }

  h1 {
    ${({ theme }) => theme.typography.h1}
  }

  h2 {
    ${({ theme }) => theme.typography.h2}
  }

  h3 {
    ${({ theme }) => theme.typography.h3}
  }

  h4 {
    ${({ theme }) => theme.typography.h4}
  }

  h5 {
    ${({ theme }) => theme.typography.h5}
  }

  label {
    ${({ theme }) => theme.typography.label}
  }

  button {
    ${({ theme }) => theme.typography.button}
  }

  a {
    ${({ theme }) => theme.typography.a}
  }

  small {
    ${({ theme }) => theme.typography.small};
  }

  .skeleton {
    @keyframes skeleton-loading {
      0% {
        background-color: rgba(0, 0, 0, 0.1);
      }

      100% {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    opacity: 0.7;
    animation: skeleton-loading 1s linear infinite alternate;
  }
  
  /* React Menu styling */
  .szh-menu {
    background-color: ${({ theme }) => theme.colors.background.paper};
    color: ${({ theme }) => theme.colors.background.contrastText};
  }

  .szh-menu__item {
    ${({ theme }) => theme.typography.body}
  }

  .szh-menu__item--hover {
    background-color: ${({ theme }) => theme.colors.primary.light};
  }
  
  .szh-menu__item--active {
    color: ${({ theme }) => theme.colors.primary.dark};
  }

  // Prevent page printing
  @media print {
  html, body {
    display: none;  /* hide whole page */
  }

}
`;
