import styled from 'styled-components';
import { useHomeSpecialtiesFiltersContext } from '../context/homeSpecialtiesFiltersContext';
import { useGetItemsOptions } from '../hooks/useGetItems';
import { MutlipleOptionSearchFilter } from 'components/Filters/MutlipleOptionSearchFilter';
import { studyTourOptions } from 'constants/filters';

export const SpecialtiesFilters = () => {
  const {
    specialtyStudyTours,
    setSpecialtyStudyTours,
    specialtiesSearchedItems,
    setSpecialtiesSearchedItems,
    handleResetSpecialtiesFilters,
  } = useHomeSpecialtiesFiltersContext();
  const { itemsOptions } = useGetItemsOptions();

  const handleItemFilter = (selectedFilters: string[]) => {
    setSpecialtiesSearchedItems(
      itemsOptions.filter((option) => selectedFilters.includes(option.value))
    );
  };

  const handleStudyToursFilter = (selectedFilters: string[]) => {
    setSpecialtyStudyTours(
      studyTourOptions.filter((option) =>
        selectedFilters.includes(option.value)
      )
    );
  };

  return (
    <>
      <MutlipleOptionSearchFilter
        columnName={'Item'}
        selectedFilters={specialtiesSearchedItems.map((item) => item.value)}
        setSelectedFilters={handleItemFilter}
        filterOptions={itemsOptions}
        hasResearch
      />
      <MutlipleOptionSearchFilter
        columnName={'Tour de révision'}
        selectedFilters={specialtyStudyTours.map((tour) => tour.value)}
        setSelectedFilters={handleStudyToursFilter}
        filterOptions={studyTourOptions}
      />
      <ResetButton onClick={handleResetSpecialtiesFilters}>Effacer</ResetButton>
    </>
  );
};

const ResetButton = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
