import { useState, useEffect } from 'react';
import { getKnowledgeQuestions } from 'http/compass/api';
import { toast } from 'react-toastify';
import { SimilarQuestion } from 'types/correction.type';

export const useGetKnowledgeQuestions = (knowledgeId: string | undefined) => {
  const [relatedQuestions, setRelatedQuestions] = useState<SimilarQuestion[]>();
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!knowledgeId) {
          setRelatedQuestions([]);
        } else {
          const knowledgeQuestions = await getKnowledgeQuestions(knowledgeId);
          setRelatedQuestions(knowledgeQuestions);
        }
      } catch (error) {
        toast.error('Une erreur est survenue. Veuillez réessayer plus tard.');
      }
    };
    fetchData();
  }, [knowledgeId]);
  return { relatedQuestions };
};
