interface Props {
  className?: string;
  fillColor?: string;
}

export const DownArrow = (props: Props) => (
  <svg
    fill="none"
    viewBox="0 0 8 8"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.433 7.25a.5.5 0 0 1-.866 0L.969 2.75A.5.5 0 0 1 1.402 2h5.196a.5.5 0 0 1 .433.75l-2.598 4.5Z"
      fill={props.fillColor || '#01162D'}
    />
  </svg>
);
