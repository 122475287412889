import styled from 'styled-components/macro';

import { device } from 'helpers/css/responsive';

import { TextInput } from 'ui/TextInput';
import { PasswordInput } from 'ui/PasswordInput';
import { Button } from 'ui/Button';
import { CapsLockWarning } from 'components/CapsLockWarning';

import { useOnLoginFormSubmit, useLoginForm } from '../loginForm';
import { LinkToLostPassword } from './LinkToLostPassword';
import { useIsLoading } from 'hooks/useIsLoading';

export const LoginForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useLoginForm();

  const { isLoading, startLoading, stopLoading } = useIsLoading();

  const onLoginFormSubmit = useOnLoginFormSubmit({
    onFetch: startLoading,
    afterFetch: stopLoading,
  });

  return (
    <Form onSubmit={handleSubmit(onLoginFormSubmit)}>
      <EmailInput
        label="E-mail"
        type="text"
        isMandatory={true}
        autoComplete="email"
        errorMessage={errors.email?.message}
        {...register('email')}
      />
      <Password
        label="Mot de passe"
        autoComplete="current-password"
        isMandatory={true}
        errorMessage={errors.password?.message}
        {...register('password')}
      />

      <InfoLine>
        <LostPasswordLinkDesktop>
          <LinkToLostPassword />
        </LostPasswordLinkDesktop>

        <CapsLockWarning />
      </InfoLine>
      <InfoLine>
        <LegalText>
          <span style={{ color: 'red' }}>*</span>Les informations recueillies
          font l'objet d'un traitement informatique. Pour en savoir plus sur la
          collecte, nous vous invitons à lire notre{' '}
          <a
            href={'https://asclepia.io/confidentiality'}
            target="_blank"
            rel="noreferrer"
          >
            politique de confidentialité de données
          </a>
          .
        </LegalText>{' '}
      </InfoLine>

      <SubmitButton type="submit" isLoading={isLoading}>
        Se connecter
      </SubmitButton>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
`;

const EmailInput = styled(TextInput)`
  margin-top: 16px;

  @media ${device.desktop} {
    margin-top: 34px;
  }
`;

const Password = styled(PasswordInput)`
  margin-top: 20px;

  @media ${device.desktop} {
    margin-top: 24px;
  }
`;

const InfoLine = styled.div`
  width: 100%;
  padding: 0 16px;

  display: flex;
  justify-content: flex-end;
  align-items: baseline;

  @media ${device.desktop} {
    padding: 0 24px;
    justify-content: space-between;
  }
`;

const LostPasswordLinkDesktop = styled.span`
  display: none;

  @media ${device.desktop} {
    display: block;
    margin-top: 12px;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 36px;

  @media ${device.desktop} {
    margin-top: 40px;
  }
`;
const LegalText = styled.div`
  margin-top: 16px;
  ${({ theme }) => theme.typography.small}
`;
