import { getAPI } from 'auth';
import { UserPaymentInfo, UserCurrentSubscription } from './types';

export const getAccountPayments = async () => {
  const response = await getAPI<UserPaymentInfo[]>('/accounts/payments', {});
  return response.data;
};

export const getAccountSubscription = async () => {
  const response = await getAPI<UserCurrentSubscription>(
    '/accounts/subscription',
    {}
  );
  return response.data;
};
