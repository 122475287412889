import { FC } from 'react';

import { ContainedLoader } from 'components/ContainedLoader';
import { useGetDPProgressBySpecialty } from 'modules/progress/general/dp/hooks/useGetDPProgressBySpecialty';

import { DPProgressYearBySpecialty } from './DPProgressYearBySpecialty';
import { GenericErrorPlaceholder } from 'components/GenericErrorPlaceholder';

export const DPProgressBySpecialty: FC = () => {
  const { data, isLoading, error } = useGetDPProgressBySpecialty();

  if (isLoading) {
    return <ContainedLoader loadingText="Veuillez patienter..." />;
  }

  if (error) {
    return <GenericErrorPlaceholder />;
  }

  if (data) {
    return (
      <>
        {data.map((progress) => (
          <DPProgressYearBySpecialty
            progress={progress}
            key={progress.speTrigram}
          />
        ))}
      </>
    );
  }

  return <div>Un problème est survenu</div>;
};
