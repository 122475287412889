import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';

import { useQueries } from 'react-query';
import { toast } from 'react-toastify';
import { getSessionRecap } from '../domain/useCases/getSessionRecap';
import { SessionRequestType } from '../http/types';
import { MS_IN_AN_HOUR } from 'constants/date';

export const useGetMultipleSessionRecap = (bodies: SessionRequestType[]) => {
  return useQueries(
    bodies.map((body) => {
      return {
        queryKey: ['sessionRecap_' + body.collection, body],
        queryFn: () => getSessionRecap(body),
        cacheTime: MS_IN_AN_HOUR,
        staleTime: MS_IN_AN_HOUR,
        onError: (e: any) => {
          toast.error("Nous n'avons pas réussi à récupérer vos données");
          reportErrorToSentry(e);
        },
      };
    })
  );
};
