import { getAPI } from 'auth';

import { SelectionQIParams, SelectionQIResponse } from './types';

export const getSelectionQI = async (params: SelectionQIParams) => {
  const response = await getAPI<SelectionQIResponse>('/selection/filterqi', {
    params,
  });
  return response.data;
};

export const getRandomQISample = (number: number) => {
  return getSelectionQI({ n_samples: number, page_size: 120 });
};
