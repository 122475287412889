import { FC, SVGAttributes } from 'react';

type SVGNativeProps = SVGAttributes<SVGElement>;

export const UnlockIcon: FC<SVGNativeProps> = (props) => {
  return (
    <svg
      width="24"
      height="22"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.46289 8.3678C1.80604 8.3678 0.462891 9.71094 0.462891 11.3678V19.0014C0.462891 20.6582 1.80604 22.0014 3.46289 22.0014H17.4629C19.1197 22.0014 20.4629 20.6583 20.4629 19.0014V11.3678C20.4629 9.71094 19.1197 8.3678 17.4629 8.3678H3.46289ZM12.4629 13.2369C12.4629 13.9812 12.0342 14.628 11.4042 14.9558L12.4629 19.0799H8.46289L9.52161 14.9558C8.89158 14.628 8.46289 13.9812 8.46289 13.2369C8.46289 12.1613 9.35832 11.2893 10.4629 11.2893C11.5675 11.2893 12.4629 12.1613 12.4629 13.2369Z"
        fill="#021427"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.46289 8.3678C1.80604 8.3678 0.462891 9.71094 0.462891 11.3678V19.0014C0.462891 20.6582 1.80604 22.0014 3.46289 22.0014H17.4629C19.1197 22.0014 20.4629 20.6583 20.4629 19.0014V11.3678C20.4629 9.71094 19.1197 8.3678 17.4629 8.3678H3.46289ZM12.4629 13.2369C12.4629 13.9812 12.0342 14.628 11.4042 14.9558L12.4629 19.0799H8.46289L9.52161 14.9558C8.89158 14.628 8.46289 13.9812 8.46289 13.2369C8.46289 12.1613 9.35832 11.2893 10.4629 11.2893C11.5675 11.2893 12.4629 12.1613 12.4629 13.2369Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.46289 8.3678C1.80604 8.3678 0.462891 9.71094 0.462891 11.3678V19.0014C0.462891 20.6582 1.80604 22.0014 3.46289 22.0014H17.4629C19.1197 22.0014 20.4629 20.6583 20.4629 19.0014V11.3678C20.4629 9.71094 19.1197 8.3678 17.4629 8.3678H3.46289ZM12.4629 13.2369C12.4629 13.9812 12.0342 14.628 11.4042 14.9558L12.4629 19.0799H8.46289L9.52161 14.9558C8.89158 14.628 8.46289 13.9812 8.46289 13.2369C8.46289 12.1613 9.35832 11.2893 10.4629 11.2893C11.5675 11.2893 12.4629 12.1613 12.4629 13.2369Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M13.4629 9.99866C13.4629 9.99866 13.4629 7.332 13.4629 5.04625C13.4629 2.7367 15.018 1.99866 18.2276 1.99866C20.3452 1.99866 22.4629 4.47485 22.4629 5.99866"
        stroke="#021427"
        strokeWidth="3"
      />
      <path
        d="M13.4629 9.99866C13.4629 9.99866 13.4629 7.332 13.4629 5.04625C13.4629 2.7367 15.018 1.99866 18.2276 1.99866C20.3452 1.99866 22.4629 4.47485 22.4629 5.99866"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="3"
      />
      <path
        d="M13.4629 9.99866C13.4629 9.99866 13.4629 7.332 13.4629 5.04625C13.4629 2.7367 15.018 1.99866 18.2276 1.99866C20.3452 1.99866 22.4629 4.47485 22.4629 5.99866"
        stroke="black"
        strokeOpacity="0.2"
        strokeWidth="3"
      />
    </svg>
  );
};
