import { FC } from 'react';
import styled from 'styled-components/macro';
import parse from 'html-react-parser';

import { propositionNumToLetter } from 'pages/correction/active-correction-group/questions-correction-blocks/QCM/utils';
import { PreviewBlockProps } from '../../types';
import { StyledMathJax } from 'ui/MathJax';

export const QCMPreviewBlock: FC<PreviewBlockProps> = ({ questionData }) => {
  return (
    <PropositionsContainer>
      {questionData.items_qcm.map((item, index) => (
        <Proposition key={item.num}>
          <span>Proposition {propositionNumToLetter(index + 1)}</span>
          <label>
            <StyledMathJax inline>{parse(item.statement)}</StyledMathJax>
          </label>
        </Proposition>
      ))}
    </PropositionsContainer>
  );
};

const PropositionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin-top: 32px;
`;

const Proposition = styled.div`
  padding: 12px 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  background: ${({ theme }) => theme.colors.primary.light};
  border-radius: 8px;
`;
