import { useQuery } from 'react-query';
import { getCorrectionTask } from './api';

export const useGetCorrectionTask = (
  taskId: number,
  enabled: boolean = true
) => {
  return useQuery(
    ['correctionTask', taskId],
    async () => {
      const correctionTask = await getCorrectionTask(taskId);

      correctionTask.correction_groups.forEach(
        (group) =>
          !group.custom_qi &&
          group.correction_questions.sort((q1, q2) => q1.num - q2.num)
      );

      return correctionTask;
    },
    { enabled }
  );
};
