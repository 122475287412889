import { getAPI } from 'auth';
import { DetailGroupParams, DetailGroupResponse } from './types';

export const getGroupDetail = async (params: DetailGroupParams) => {
  const response = await getAPI<DetailGroupResponse>('/progress/detail/group', {
    params,
  });

  return response.data;
};
