import { FC } from 'react';
import parse from 'html-react-parser';
import styled, { ThemeProvider } from 'styled-components/macro';

import { getTheme } from 'theme';
import { GlobalStyle } from 'globalStyles';

import { device } from 'helpers/css/responsive';
import { useGetStaticPage } from 'http/static-pages/connector';
import { StaticPageLabel } from 'http/static-pages/types';
import { StyledMathJax } from 'ui/MathJax';

interface Props {
  page: StaticPageLabel;
}

export const StaticPage: FC<Props> = ({ page }) => {
  const { data } = useGetStaticPage(page);

  if (!data) {
    return null;
  }
  return (
    <ThemeProvider theme={getTheme('light')}>
      <GlobalStyle />
      <Container>
        <StyledMathJax inline>{parse(data?.page)}</StyledMathJax>
      </Container>
    </ThemeProvider>
  );
};

const Container = styled.div`
  padding: 12px;

  @media ${device.desktop} {
    padding: 32px;
  }
`;
