import { createContext, FC, useContext, useState } from 'react';

import { PreviewModal } from '../components/PreviewModal';

interface PreviewModalContext {
  openModal: (groupId: number) => void;
}

const contextDefaultValue: PreviewModalContext = {
  openModal: () => {},
};

const previewModalContext = createContext(contextDefaultValue);

export const PreviewModalProvider: FC = ({ children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupId, setGroupId] = useState<number>();

  const openModal = (newId: number) => {
    setGroupId(newId);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <previewModalContext.Provider value={{ openModal }}>
      {groupId && (
        <PreviewModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          groupId={groupId}
        />
      )}
      {children}
    </previewModalContext.Provider>
  );
};

export const usePreviewModalContext = () => useContext(previewModalContext);
