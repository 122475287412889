import { getAPI } from 'auth';
import { Book } from 'http/compass/types';

export const getBooksHttp = async (codes: string[]) => {
  const response = await getAPI<Book[]>('/common/books/', {
    params: {
      code: codes,
    },
  });
  return response.data;
};
