import styled from 'styled-components/macro';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  show: boolean;
}
export const LimitBanner = ({ show, ...rest }: Props) => {
  if (!show) return null;

  return (
    <Container {...rest}>
      Vous avez coché le maximum de réponse(s) possible(s)
    </Container>
  );
};

const Container = styled.div`
  margin: 0.5rem 0;
  background-color: #f4b560;
  color: white;
  font-weight: bold;
  font-family: 'Freesans';
  width: 100%;
  text-align: center;
  font-size: 14px;
  padding: 0.35rem 0;
  border-radius: 4px;
`;
