import { FC, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import PricingPage from './PricingPage/PricingPage';
import FacturationPage from './FacturationPage/FacturationPage';
import { PriceInfos } from 'http/payments/getPrices/types';
import FadeIn from 'ui/Animations/FadeIn';

export const Subscribe: FC = () => {
  const [step, setStep] = useState(0);

  const [priceInfos, setPriceInfos] = useState<PriceInfos>();

  const handleChoosePrice = (price: PriceInfos) => {
    setPriceInfos(price);
    handleNextStep();
  };

  const handleNextStep = () => {
    setStep((step) => (step === 1 ? 1 : step + 1));
  };

  const handlePreviousStep = () => {
    setStep((step) => (step === 0 ? 0 : step - 1));
  };

  useEffect(() => {
    if (step === 1 && !priceInfos) {
      setStep(0);
    }
  }, [step, priceInfos]);

  return (
    <FadeIn>
      <Container>
        <h1>S'abonner</h1>

        {step === 0 ? (
          <PricingPage handleChoosePrice={handleChoosePrice} />
        ) : (
          <FacturationPage
            handlePreviousStep={handlePreviousStep}
            priceInfos={priceInfos}
          />
        )}
      </Container>
    </FadeIn>
  );
};

const Container = styled.div`
  padding: 32px;
`;
