import React from 'react';
import styled from 'styled-components/macro';
import { PaymentMethodType, PaymentProvider } from '../PaymentMethod.type';
import { Checkbox } from 'ui/Checkbox';
import moment from 'moment';
import 'moment/locale/fr';

interface Props extends PaymentMethodType {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isSelected: boolean;
  name: PaymentProvider;
  amount: number;
}

const PaymentMethodCard: React.FC<Props> = ({
  label,
  value,
  onChange,
  icon,
  description,
  isSelected,
  payInFourTimes,
  name,
  amount,
}) => {
  const amountInFourTimes = amount / 4 / 100;
  const paymentSchedule =
    payInFourTimes ?? amount
      ? [
          {
            date: "Aujourd'hui",
            amount: amountInFourTimes,
          },
          {
            date: moment().locale('fr').add(30, 'days').format('LL'),
            amount: amountInFourTimes,
          },
          {
            date: moment().locale('fr').add(60, 'days').format('LL'),
            amount: amountInFourTimes,
          },
          {
            date: moment().locale('fr').add(90, 'days').format('LL'),
            amount: amountInFourTimes,
          },
        ]
      : [];

  return (
    <MethodCard
      isSelected={isSelected}
      onClick={() =>
        onChange({
          target: {
            name: name,
            value: value,
            checked: !isSelected,
          },
        } as React.ChangeEvent<HTMLInputElement>)
      }
    >
      <InputContainer isSelected={isSelected}>
        <LabelContainer>
          <Checkbox
            checked={isSelected}
            onChange={onChange}
            value={value}
            rounded
            name={name}
            style={{
              background: 'white',
            }}
          />
          <Label>{label}</Label>
        </LabelContainer>
        {/* <Icon src={icon} alt={label} /> */}
      </InputContainer>
      {payInFourTimes && paymentSchedule && (
        <TimeLine isSelected={isSelected}>
          {paymentSchedule?.map((schedule) => (
            <TimeLineRow
              key={`paypal_schedule_${schedule.date}_${schedule.amount}`}
            >
              <TimeLineRowItem>
                <Description>{schedule.date}</Description>
                <Description>{schedule.amount}€</Description>
              </TimeLineRowItem>
            </TimeLineRow>
          ))}
        </TimeLine>
      )}

      <DescriptionContainer isSelected={isSelected}>
        {description && <Description>{description}</Description>}
      </DescriptionContainer>
    </MethodCard>
  );
};

const MethodCard = styled.div<{ isSelected?: boolean }>`
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in-out;
  width: 100%;
  flex-direction: column;
  cursor: pointer;
  margin-bottom: 1rem;
`;

const InputContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  padding: 1rem;
  width: 100%;
  align-items: center;
  transition: all 0.2s ease-in-out;
  border-radius: ${({ theme, isSelected }) =>
    isSelected
      ? `${theme.borderRadius.cta.desktop} ${theme.borderRadius.cta.desktop} 0 0`
      : theme.borderRadius.cta.desktop};
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.colors.primary.main : theme.colors.primary.light};
`;

const DescriptionContainer = styled.div<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ isSelected }) => (isSelected ? '1rem' : '0')};
  padding-left: 1rem;
  border-radius: ${({ theme }) =>
    `0 0 ${theme.borderRadius.cta.desktop} ${theme.borderRadius.cta.desktop}`};
  max-height: ${({ isSelected }) => (isSelected ? '150px' : '0')};
  transition: all 0.2s ease-in-out;
  overflow: ${({ isSelected }) => (isSelected ? 'visible' : 'hidden')};
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.primary.main : 'transparent'};
  width: 100%;
  text-align: left;
`;

const TimeLine = styled.ul<{ isSelected?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ isSelected }) => (isSelected ? '1rem 2rem' : '0 2rem')};
  max-height: ${({ isSelected }) => (isSelected ? '200px' : '0')};
  transition: all 0.2s ease-in-out;
  overflow: ${({ isSelected }) => (isSelected ? 'visible' : 'hidden')};
  border-left: 1px solid ${({ theme }) => theme.colors.primary.main};
  border-right: 1px solid ${({ theme }) => theme.colors.primary.main};
  width: 100%;
  text-align: left;
  margin: 0;
`;

const TimeLineRow = styled.li`
  ${({ theme }) => theme.typography.textBody2};
  margin: 0;
  margin-bottom: 0.5rem;
  width: 100%;
  &::marker {
    color: ${({ theme }) => theme.colors.primary.main};
  }
`;

const TimeLineRowItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Description = styled.p`
  ${({ theme }) => theme.typography.textBody2};
  font-weight: 500;
  margin: 0;
`;

const Label = styled.h4`
  ${({ theme }) => theme.typography.body};
  font-weight: 500;
  margin-left: 1rem;
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

// const Icon = styled.img`
//   width: auto;
//   max-height: 1.25rem;
//   margin-left: auto;
// `;

export default PaymentMethodCard;
