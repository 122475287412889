import React from 'react';

interface Props {
  amount: number;
}

const PriceByYear: React.FC<Props> = ({ amount }) => {
  return <span>{`${amount / 100} €`}</span>;
};

export default PriceByYear;
