import styled from 'styled-components';
import { PageTitle } from 'ui/Titles/PageTitle';
import { device } from 'helpers/css/responsive';
import { WhyParticipate } from './InfoBlocks/WhyParticipateInfo';
import { Knowmore } from './InfoBlocks/Knowmore';
import CCBIntroImage from 'assets/images/CCB-intro.png';
import { useEffect } from 'react';
import { useSubscribedNewsletter } from 'modules/user-options/hooks/useSubscribedNewsletter';
import { NewsletterBlock } from 'components/Newsletter/NewsletterBlock';

export const InfoPage = () => {
  const newsletter = useSubscribedNewsletter();

  useEffect(() => {}, []);

  return (
    <Container withButton={newsletter}>
      <InfoContainer>
        <Right>
          <CommentContainer>A venir...</CommentContainer>
          <PageTitle>
            Testez-vous gratuitement avec le 1er EDN blanc asclepia !
          </PageTitle>
          <p>
            Nous sommes actuellement en train de préparer le premier concours
            blanc d’asclepia.io qui sera composé sur la même plateforme que
            celle du CNG ! Restez à l'affût pour connaître tous les détails à
            venir.
          </p>
          <NewsletterBlock />
        </Right>
        <ImageContainer>
          <StyledImage src={CCBIntroImage} alt={'Concours blanc'} />
        </ImageContainer>
      </InfoContainer>
      <InfoContainer>
        <Right>
          <Knowmore />
        </Right>
        <WhyParticipate />
      </InfoContainer>
    </Container>
  );
};

const Container = styled.div<{ withButton: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ withButton }) => (withButton ? '0' : '4rem')};
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  height: 100%;
  gap: 40px;
  @media ${device.mobile} {
    flex-direction: column;
    min-width: 100%;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 60%;

  @media ${device.mobile} {
    min-width: 100%;
  }
`;

const CommentContainer = styled.div`
  color: ${({ theme }) => theme.colors.error.main};
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 400px;
  height: 100%;

  @media ${device.mobile} {
    display: 'none';
    width: 0%;
    height: 0%;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
`;
