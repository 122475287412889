import { createContext, FC, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { SessionOrSpe, toggleSessionsOrSpe } from 'store/slices/progressSlice';

interface DisplayModeContext {
  displayMode: SessionOrSpe;
  toggleSwitch: () => void;
  isSwitchOn: boolean;
}

const contextDefaultValue: DisplayModeContext = {
  displayMode: 'collection',
  toggleSwitch: () => {},
  isSwitchOn: false,
};

const displayModeContext = createContext(contextDefaultValue);

export const DisplayModeProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const sessionOrSpe = useSelector(
    (state: RootState) => state.progress.sessionOrSpe
  );

  const toggleSwitch = () => dispatch(toggleSessionsOrSpe());

  const isSwitchOn = useMemo(
    () => (sessionOrSpe === 'collection' ? false : true),
    [sessionOrSpe]
  );

  return (
    <displayModeContext.Provider
      value={{ displayMode: sessionOrSpe, toggleSwitch, isSwitchOn }}
    >
      {children}
    </displayModeContext.Provider>
  );
};

export const useDisplayModeContext = () => useContext(displayModeContext);
