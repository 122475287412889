import { css } from 'styled-components/macro';

export const customizableThinScrollBarStyle = css`
  /* Works on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--scroll-handle-color) var(--scroll-background-color);
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  *::-webkit-scrollbar-track {
    background: var(--scroll-background-color);
  }

  *::-webkit-scrollbar-thumb {
    background-color: var(--scroll-handle-color);
    border-radius: 20px;
  }
`;

export const thinScrollbarStyle = css`
  --scroll-handle-color: ${({ theme }) => theme.colors.inactive};
  --scroll-background-color: ${({ theme }) => theme.colors.background.paper};

  ${customizableThinScrollBarStyle}
`;

export const thinScrollbarStyleColored = css`
  --scroll-handle-color: ${({ theme }) => theme.colors.inactive};
  --scroll-background-color: ${({ theme }) => theme.colors.primary.main};

  ${customizableThinScrollBarStyle}
`;
