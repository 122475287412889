import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { SortableState } from 'store/types';
import { sortOptions } from '../../../components/Filters/constants';

export function useSortOptions(
  stateSelector: (state: RootState) => SortableState
) {
  const sortOrder = useSelector(
    (state: RootState) => stateSelector(state).sortOrder
  );
  const sortColumn = useSelector(
    (state: RootState) => stateSelector(state).sortColumn
  );

  return {
    isAscending:
      sortOrder === undefined
        ? undefined
        : sortOrder === sortOptions.ascending
        ? true
        : false,
    sortColumn: sortColumn,
  };
}
