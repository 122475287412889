import styled from 'styled-components/macro';
import { zIndexes } from 'theme/zIndex/zIndexes';

const Copyrights = () => (
  <LegalMentions>
    Copyright ©️ 2021-2024 M2HV SAS. Tous droits réservés.
  </LegalMentions>
);

export const COPYRIGHTS_HEIGHT = 30;

const LegalMentions = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  bottom: 0;
  width: 100%;
  height: ${COPYRIGHTS_HEIGHT}px;
  z-index: ${zIndexes.copyright};
  text-align: center;
  background-color: ${({ theme }) => theme.colors.primary.dark};
  color: ${({ theme }) => theme.colors.primary.contrastText};
  padding: 4px;
  ${({ theme }) => theme.typography.textBody2}
`;

export default Copyrights;
