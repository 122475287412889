export function formatGrade(grade: number): string {
  const roundedGrade = Math.round(grade * 100) / 100;

  return roundedGrade % 1 === 0
    ? roundedGrade.toFixed(0)
    : roundedGrade.toFixed(2);
}

export function roundGrade(grade: number): number {
  const decimalPart = grade % 1;
  if (decimalPart >= 0.5) {
    return Math.ceil(grade);
  } else {
    return Math.floor(grade);
  }
}
