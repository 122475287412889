import { FC, InputHTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import LibSwitch, { ReactSwitchProps } from 'react-switch';
import { useScreenMatchesMediaQuery } from 'hooks/useScreenMatchesMediaQuery';
import { device } from 'helpers/css/responsive';

type NativeInputProps = InputHTMLAttributes<HTMLInputElement>;
type Props = Omit<NativeInputProps, 'type' | 'onChange'> & ReactSwitchProps;

export const Switch: FC<Props> = (props) => {
  const theme = useTheme();
  const isOnDesktop = useScreenMatchesMediaQuery(device.desktop);

  return (
    <StyledLibSwitch
      checkedIcon={false}
      uncheckedIcon={false}
      onColor={theme.colors.primary.dark}
      offColor={theme.colors.background.paper}
      offHandleColor={theme.colors.primary.dark}
      onHandleColor={theme.colors.background.paper}
      width={isOnDesktop ? 48 : 44}
      height={isOnDesktop ? 24 : 20}
      handleDiameter={isOnDesktop ? 20 : 16}
      {...props}
    />
  );
};

const StyledLibSwitch = styled(LibSwitch)`
  .react-switch-bg {
    border: 1px ${({ theme }) => theme.colors.primary.dark} solid;
  }
`;
