import styled from 'styled-components/macro';

export const AdCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding: 0.85rem;
  border-radius: 8px;

  gap: 32px;
  color: ${({ theme }) => theme.colors.background.paper};
  background-color: ${({ theme }) => theme.colors.advertisement};
  overflow: hidden;
  img {
    max-width: 100%;
    height: auto;
  }
`;
