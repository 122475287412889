import { Paginated } from 'modules/shared/entities/Paginated';
import { computeNumberOfPages } from 'modules/shared/useCases/computeNumberOfPages';
import { HistoryGroup } from '../domain/entities/HistoryGroup';
import { GroupHistoryRepository } from '../domain/GroupHistoryRepository';
import { adaptParamsToHttp } from './adapter';
import { getGroupHistoryHttp } from './api';

export const getGroupHistoryRepository = (): GroupHistoryRepository =>
  GroupHistoryRepositoryImpl;

const GroupHistoryRepositoryImpl: GroupHistoryRepository = {
  async getPaginatedGroupHistory(params) {
    const groupHistory = await getGroupHistoryHttp(adaptParamsToHttp(params));

    const paginatedGroupHistory: Paginated<HistoryGroup[]> = {
      currentPage: params.pageNumber,
      numberOfPages: computeNumberOfPages({
        pageCount: groupHistory.count,
        pageSize: params.pageSize,
      }),
      data: groupHistory.results.map((group) => ({
        date: new Date(group.date_creation),
        grade: group.grade,
        groupCorrectionId: group.id,
        taskCorrectionId: group.task_id,
        totalCoefficient: group.total_coefficient,
        type: group.type === 'QI' ? 'QI' : group.type === 'LCA' ? 'LCA' : 'DP',
      })),
    };

    return paginatedGroupHistory;
  },
};
