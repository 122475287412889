export const countries: {
  label: string;
  value: string;
}[] = [
  { label: 'Afghanistan', value: 'Afghanistan' },
  { label: 'Afrique du Sud', value: 'Afrique du Sud' },
  { label: 'Albanie', value: 'Albanie' },
  { label: 'Algérie', value: 'Algérie' },
  { label: 'Allemagne', value: 'Allemagne' },
  { label: 'Andorre', value: 'Andorre' },
  { label: 'Angola', value: 'Angola' },
  { label: 'Antigua-et-Barbuda', value: 'Antigua-et-Barbuda' },
  { label: 'Arabie saoudite', value: 'Arabie saoudite' },
  { label: 'Argentine', value: 'Argentine' },
  { label: 'Arménie', value: 'Arménie' },
  { label: 'Australie', value: 'Australie' },
  { label: 'Autriche', value: 'Autriche' },
  { label: 'Azerbaïdjan', value: 'Azerbaïdjan' },
  { label: 'Bahamas', value: 'Bahamas' },
  { label: 'Bahreïn', value: 'Bahreïn' },
  { label: 'Bangladesh', value: 'Bangladesh' },
  { label: 'Barbade', value: 'Barbade' },
  { label: 'Biélorussie', value: 'Biélorussie' },
  { label: 'Belgique', value: 'Belgique' },
  { label: 'Belize', value: 'Belize' },
  { label: 'Bénin', value: 'Bénin' },
  { label: 'Bhoutan', value: 'Bhoutan' },
  { label: 'Bolivie', value: 'Bolivie' },
  { label: 'Bosnie-Herzégovine', value: 'Bosnie-Herzégovine' },
  { label: 'Botswana', value: 'Botswana' },
  { label: 'Brésil', value: 'Brésil' },
  { label: 'Brunei', value: 'Brunei' },
  { label: 'Bulgarie', value: 'Bulgarie' },
  { label: 'Burkina Faso', value: 'Burkina Faso' },
  { label: 'Burundi', value: 'Burundi' },
  { label: 'Cambodge', value: 'Cambodge' },
  { label: 'Cameroun', value: 'Cameroun' },
  { label: 'Canada', value: 'Canada' },
  { label: 'Cap-Vert', value: 'Cap-Vert' },
  { label: 'République centrafricaine', value: 'République centrafricaine' },
  { label: 'Chili', value: 'Chili' },
  { label: 'Chine', value: 'Chine' },
  { label: 'Chypre', value: 'Chypre' },
  { label: 'Colombie', value: 'Colombie' },
  { label: 'Comores', value: 'Comores' },
  { label: 'République du Congo', value: 'République du Congo' },
  {
    label: 'République démocratique du Congo',
    value: 'République démocratique du Congo',
  },
  { label: 'Corée du Sud', value: 'Corée du Sud' },
  { label: 'Corée du Nord', value: 'Corée du Nord' },
  { label: 'Costa Rica', value: 'Costa Rica' },
  { label: "Côted'Ivoire", value: "Côte d'Ivoire" },
  { label: 'Croatie', value: 'Croatie' },
  { label: 'Cuba', value: 'Cuba' },
  { label: 'Danemark', value: 'Danemark' },
  { label: 'Djibouti', value: 'Djibouti' },
  { label: 'République dominicaine', value: 'République dominicaine' },
  { label: 'Dominique', value: 'Dominique' },
  { label: 'Égypte', value: 'Égypte' },
  { label: 'Émirats arabes unis', value: 'Émirats arabes unis' },
  { label: 'Équateur', value: 'Équateur' },
  { label: 'Érythrée', value: 'Érythrée' },
  { label: 'Espagne', value: 'Espagne' },
  { label: 'Estonie', value: 'Estonie' },
  { label: 'États-Unis', value: 'États-Unis' },
  { label: 'Éthiopie', value: 'Éthiopie' },
  { label: 'Fidji', value: 'Fidji' },
  { label: 'Finlande', value: 'Finlande' },
  { label: 'France', value: 'France' },
  { label: 'Gabon', value: 'Gabon' },
  { label: 'Gambie', value: 'Gambie' },
  { label: 'Géorgie', value: 'Géorgie' },
  { label: 'Ghana', value: 'Ghana' },
  { label: 'Grèce', value: 'Grèce' },
  { label: 'Grenade', value: 'Grenade' },
  { label: 'Guatemala', value: 'Guatemala' },
  { label: 'Guinée', value: 'Guinée' },
  { label: 'Guinée-Bissau', value: 'Guinée-Bissau' },
  { label: 'Guinée équatoriale', value: 'Guinée équatoriale' },
  { label: 'Guyana', value: 'Guyana' },
  { label: 'Haïti', value: 'Haïti' },
  { label: 'Honduras', value: 'Honduras' },
  { label: 'Hongrie', value: 'Hongrie' },
  { label: 'Inde', value: 'Inde' },
  { label: 'Indonésie', value: 'Indonésie' },
  { label: 'Iran', value: 'Iran' },
  { label: 'Irak', value: 'Irak' },
  { label: 'Irlande', value: 'Irlande' },
  { label: 'Islande', value: 'Islande' },
  { label: 'Israël', value: 'Israël' },
  { label: 'Italie', value: 'Italie' },
  { label: 'Jamaïque', value: 'Jamaïque' },
  { label: 'Japon', value: 'Japon' },
  { label: 'Jordanie', value: 'Jordanie' },
  { label: 'Kazakhstan', value: 'Kazakhstan' },
  { label: 'Kenya', value: 'Kenya' },
  { label: 'Kirghizistan', value: 'Kirghizistan' },
  { label: 'Kiribati', value: 'Kiribati' },
  { label: 'Koweït', value: 'Koweït' },
  { label: 'Laos', value: 'Laos' },
  { label: 'Lesotho', value: 'Lesotho' },
  { label: 'Lettonie', value: 'Lettonie' },
  { label: 'Liban', value: 'Liban' },
  { label: 'Liberia', value: 'Liberia' },
  { label: 'Libye', value: 'Libye' },
  { label: 'Liechtenstein', value: 'Liechtenstein' },
  { label: 'Lituanie', value: 'Lituanie' },
  { label: 'Luxembourg', value: 'Luxembourg' },
  { label: 'Macédoine du Nord', value: 'Macédoine du Nord' },
  { label: 'Madagascar', value: 'Madagascar' },
  { label: 'Malaisie', value: 'Malaisie' },
  { label: 'Malawi', value: 'Malawi' },
  { label: 'Maldives', value: 'Maldives' },
  { label: 'Mali', value: 'Mali' },
  { label: 'Malte', value: 'Malte' },
  { label: 'Maroc', value: 'Maroc' },
  { label: 'Îles Marshall', value: 'Îles Marshall' },
  { label: 'Maurice', value: 'Maurice' },
  { label: 'Mauritanie', value: 'Mauritanie' },
  { label: 'Mexique', value: 'Mexique' },
  {
    label: 'États fédérés de Micronésie',
    value: 'États fédérés de Micronésie',
  },
  { label: 'Moldavie', value: 'Moldavie' },
  { label: 'Monaco', value: 'Monaco' },
  { label: 'Mongolie', value: 'Mongolie' },
  { label: 'Monténégro', value: 'Monténégro' },
  { label: 'Mozambique', value: 'Mozambique' },
  { label: 'Birmanie', value: 'Birmanie' },
  { label: 'Namibie', value: 'Namibie' },
  { label: 'Nauru', value: 'Nauru' },
  { label: 'Népal', value: 'Népal' },
  { label: 'Nicaragua', value: 'Nicaragua' },
  { label: 'Niger', value: 'Niger' },
  { label: 'Nigeria', value: 'Nigeria' },
  { label: 'Norvège', value: 'Norvège' },
  { label: 'Nouvelle-Zélande', value: 'Nouvelle-Zélande' },
  { label: 'Oman', value: 'Oman' },
  { label: 'Ouganda', value: 'Ouganda' },
  { label: 'Ouzbékistan', value: 'Ouzbékistan' },
  { label: 'Pakistan', value: 'Pakistan' },
  { label: 'Palaos', value: 'Palaos' },
  { label: 'Panama', value: 'Panama' },
  { label: 'Papouasie-Nouvelle-Guinée', value: 'Papouasie-Nouvelle-Guinée' },
  { label: 'Paraguay', value: 'Paraguay' },
  { label: 'Pays-Bas', value: 'Pays-Bas' },
  { label: 'Pérou', value: 'Pérou' },
  { label: 'Philippines', value: 'Philippines' },
  { label: 'Pologne', value: 'Pologne' },
  { label: 'Portugal', value: 'Portugal' },
  { label: 'Qatar', value: 'Qatar' },
  { label: 'Roumanie', value: 'Roumanie' },
  { label: 'Royaume-Uni', value: 'Royaume-Uni' },
  { label: 'Russie', value: 'Russie' },
  { label: 'Rwanda', value: 'Rwanda' },
  { label: 'Saint-Christophe-et-Niévès', value: 'Saint-Christophe-et-Niévès' },
  { label: 'Saint-Marin', value: 'Saint-Marin' },
  {
    label: 'Saint-Vincent-et-les-Grenadines',
    value: 'Saint-Vincent-et-les-Grenadines',
  },
  { label: 'Sainte-Lucie', value: 'Sainte-Lucie' },
  { label: 'Îles Salomon', value: 'Îles Salomon' },
  { label: 'Salvador', value: 'Salvador' },
  { label: 'Samoa', value: 'Samoa' },
  { label: 'Sao Tomé-et-Principe', value: 'Sao Tomé-et-Principe' },
  { label: 'Sénégal', value: 'Sénégal' },
  { label: 'Serbie', value: 'Serbie' },
  { label: 'Seychelles', value: 'Seychelles' },
  { label: 'Sierra Leone', value: 'Sierra Leone' },
  { label: 'Singapour', value: 'Singapour' },
  { label: 'Slovaquie', value: 'Slovaquie' },
  { label: 'Slovénie', value: 'Slovénie' },
  { label: 'Somalie', value: 'Somalie' },
  { label: 'Soudan', value: 'Soudan' },
  { label: 'Soudan du Sud', value: 'Soudan du Sud' },
  { label: 'Sri Lanka', value: 'Sri Lanka' },
  { label: 'Suède', value: 'Suède' },
  { label: 'Suisse', value: 'Suisse' },
  { label: 'Suriname', value: 'Suriname' },
  { label: 'Eswatini', value: 'Eswatini' },
  { label: 'Syrie', value: 'Syrie' },
  { label: 'Tadjikistan', value: 'Tadjikistan' },
  { label: 'Tanzanie', value: 'Tanzanie' },
  { label: 'Tchad', value: 'Tchad' },
  { label: 'Tchéquie', value: 'Tchéquie' },
  { label: 'Thaïlande', value: 'Thaïlande' },
  { label: 'Timor oriental', value: 'Timor oriental' },
  { label: 'Togo', value: 'Togo' },
  { label: 'Tonga', value: 'Tonga' },
  { label: 'Trinité-et-Tobago', value: 'Trinité-et-Tobago' },
  { label: 'Tunisie', value: 'Tunisie' },
  { label: 'Turkménistan', value: 'Turkménistan' },
  { label: 'Turquie', value: 'Turquie' },
  { label: 'Tuvalu', value: 'Tuvalu' },
  { label: 'Ukraine', value: 'Ukraine' },
  { label: 'Uruguay', value: 'Uruguay' },
  { label: 'Vanuatu', value: 'Vanuatu' },
  { label: 'Venezuela', value: 'Venezuela' },
  { label: 'Viêt Nam', value: 'Viêt Nam' },
  { label: 'Yémen', value: 'Yémen' },
  { label: 'Zambie', value: 'Zambie' },
  { label: 'Zimbabwe', value: 'Zimbabwe' },
];
