import { useQuery } from 'react-query';
import { getItemsListHttp } from 'http/selection/get_item/api';
import { MS_IN_AN_HOUR } from 'constants/date';

export const useGetItemsList = () => {
  const { data: rawItems } = useQuery('useGetItemsListKey', getItemsListHttp, {
    cacheTime: MS_IN_AN_HOUR,
    staleTime: MS_IN_AN_HOUR,
  });

  if (rawItems === undefined || rawItems === null) {
    return [];
  }

  return rawItems.filter(
    (item) => item.item !== undefined && item.item !== null
  );
};
