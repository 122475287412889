import { Polygon } from '../types';

export const drawPolygon = (
  polygon: Polygon,
  {
    ctx,
    width,
    height,
  }: {
    ctx: CanvasRenderingContext2D;
    width: number;
    height: number;
  }
) => {
  if (polygon.vertices.length === 0) {
    return;
  }

  const scaledToCanvasPolygon = {
    vertices: polygon.vertices.map((vertice) => ({
      x: vertice.x * width,
      y: vertice.y * height,
    })),
  };

  ctx.fillStyle = polygon.color;
  ctx.globalAlpha = 0.6;
  ctx.beginPath();
  const firstPoint = scaledToCanvasPolygon.vertices[0];
  ctx.moveTo(firstPoint.x, firstPoint.y);

  const otherPoints = scaledToCanvasPolygon.vertices.slice(1);
  for (let point of otherPoints) {
    ctx.lineTo(point.x, point.y);
  }
  ctx.closePath();
  ctx.fill();
};
