import { StartQueryParam, START_QUERY_PARAM } from 'pages/start/Start';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routing/constants';
import styled from 'styled-components/macro';

interface Props {
  name: string;
  cartType: StartQueryParam;
  multiple?: boolean;
}

export const AddToCartSuccessToast: FC<Props> = ({
  name,
  cartType,
  multiple = true,
}) => {
  return (
    <Container>
      <span>
        Le{multiple && 's'} {name} sélectionné{multiple && 's'}{' '}
        {multiple ? 'ont' : 'a'} été ajouté{multiple && 's'} à votre sélection
      </span>
      <NavigationRow>
        <Link
          to={{
            pathname: routes.START,
            search: `?${START_QUERY_PARAM}=${cartType}`,
          }}
        >
          Aller à l'écran démarrer
        </Link>
      </NavigationRow>
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const NavigationRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
