import { useState, useEffect } from 'react';
import { ClockIcon } from 'ui/icons/Clock';
import styled from 'styled-components/macro';
import { device } from 'helpers/css/responsive';
import { useSelector } from 'react-redux';
import { selectIsCorrectionRevealed } from 'store/slices/fqCurrentSlice';

const CLOCK_TIMEOUT = 90;
const FQCounter = () => {
  const [clock, setClock] = useState<number>(0);
  const isCorrectionRevealed = useSelector(selectIsCorrectionRevealed);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (!isCorrectionRevealed) {
      timer = setTimeout(() => setClock((clock) => clock + 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [clock, isCorrectionRevealed]);

  return (
    <FQStopWatch clock={clock}>
      <ClockIcon />
      <ClockText>{clock} sec</ClockText>
    </FQStopWatch>
  );
};

const ClockText = styled.span`
  margin-left: 4px;
`;
const FQStopWatch = styled.div<{ clock: number }>`
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 12px;
  border-radius: 8px;
  color: ${({ theme, clock }) =>
    clock > CLOCK_TIMEOUT ? theme.colors.secondary.dark : 'black'};
  background: ${({ theme, clock }) =>
    clock > CLOCK_TIMEOUT
      ? theme.colors.flashQuestions.redLightTranslucent
      : theme.colors.flashQuestions.blueLightTranslucent};

  & svg {
    margin-right: 5px;
    width: 24px;
    height: 24px;
  }
  & svg * {
    fill: ${({ theme, clock }) =>
      clock > CLOCK_TIMEOUT ? theme.colors.secondary.dark : 'black'};
  }

  @media ${device.desktop} {
    & svg {
      flex: none;
    }
  }
  font-weight: 600;
  margin-bottom: 0;
`;

export default FQCounter;
