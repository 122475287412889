import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { useGoToAuthConfirmation } from 'pages/auth-confirmation/hooks/useGoToAuthConfirmation';
import { requestChangeEmail } from 'http/change-email/api';

interface FormInputs {
  newEmail: string;
}

const formSchema = yup.object({
  newEmail: yup.string().email().required(),
});

export const useChangeEmailForm = () => {
  const rhf = useForm<FormInputs>({
    resolver: yupResolver(formSchema),
  });

  return {
    ...rhf,
  };
};

export const useChangeEmailFormSubmit = ({
  onFetch,
  afterFetch,
}: {
  onFetch: () => void;
  afterFetch: () => void;
}) => {
  const goToAuthConfirmation = useGoToAuthConfirmation();

  const onChangeEmailFormSubmit: SubmitHandler<FormInputs> = async (data) => {
    try {
      onFetch();
      const body = {
        email: data.newEmail,
      };
      await requestChangeEmail(body);
      goToAuthConfirmation({
        title: 'Votre adresse email a bien été changée',
        subtitle: 'Votre nouvelle adresse: ' + data.newEmail,
      });
    } finally {
      afterFetch();
    }
  };

  return onChangeEmailFormSubmit;
};
