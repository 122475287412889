import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ccbCreateTask } from 'http/ccb/api';
import { routes } from 'routing/constants';
import { RootState } from 'store';
import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';
import { updateNextTask, updateLastTask } from 'store/slices/ccbSlice';
import { useDispatch } from 'react-redux';
import { CCBTask } from 'http/ccb/type';
import { useState } from 'react';

export const useLauchCCBTask = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false); // Set initial loading state to false
  const nextSession = useSelector(
    (state: RootState) => state.ccb.sessionsLeftToDo[0]
  );

  const startCCBTask = async () => {
    if (!nextSession || !nextSession.ccbId || !nextSession.composition_unit) {
      toast.error("Nous n'avons pas pu récupérer l'épreuve de concours-blanc");
      return;
    }
    setIsLoading(true);
    try {
      const task: CCBTask = await ccbCreateTask(
        nextSession.ccbId,
        nextSession.composition_unit
      );
      dispatch(
        updateNextTask({
          ...task,
          composition_unit: nextSession.composition_unit,
        })
      );
      dispatch(
        updateLastTask({
          ...task,
          composition_unit: nextSession.composition_unit,
        })
      );
      navigate({ pathname: routes.CCBTRAINING, search: `?id=${task.id}` });
      setIsLoading(false);
    } catch (e) {
      toast.error(
        "Nous n'avons pas pu récupérer l'épreuve de concours-blanc, merci de recharger la page et de réessayer."
      );
      reportErrorToSentry(e);
      setIsLoading(false);
    }
  };

  return { startCCBTask, isLoading };
};
