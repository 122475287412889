import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { setFQParameters } from 'store/actions/fqSettingsActions';
import { Status } from 'store/types';

interface FQSettingsState {
  showFlashQuestionSettingsModal: boolean;
  status: Record<typeof HTTP_KEYS[number], Status>;
  errors: Record<typeof HTTP_KEYS[number], string>;
}

export const HTTP_KEYS = ['postParameters'] as const;

const initialState: FQSettingsState = {
  showFlashQuestionSettingsModal: false,
  status: {
    postParameters: Status.IDLE,
  },
  errors: {
    postParameters: '',
  },
};

export const fqSettingsSlice = createSlice({
  name: 'fqSettings',
  initialState,
  reducers: {
    setFlashQuestionSettingsModal: (state, action: PayloadAction<boolean>) => {
      state.showFlashQuestionSettingsModal = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase(setFQParameters.fulfilled, (state) => {
      state.status.postParameters = Status.RESOLVED;
    });
    builder.addCase(setFQParameters.rejected, (state, action) => {
      state.status.postParameters = Status.REJECTED;
    });
    builder.addCase(setFQParameters.pending, (state) => {
      state.status.postParameters = Status.PENDING;
    });
  },
});

export const { setFlashQuestionSettingsModal } = fqSettingsSlice.actions;
export const fqSettingsReducer = fqSettingsSlice.reducer;
