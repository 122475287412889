import { createContext, FC, useContext, useState } from 'react';
import { FormattedDispo } from 'http/rank-info/easy-dispo/connector';

export interface CHUandSpeInfo {
  speLabel?: string;
  chuLabel?: string;
  speCondensedLabel?: string;
  chuCondensedLabel?: string;
}

interface EasyDispoContextProps {
  dispo: FormattedDispo | undefined;
  setDispo: (dispo: FormattedDispo | undefined) => void;
  affectationFilters: CHUandSpeInfo[];
  setAffectationFilters: (value: React.SetStateAction<CHUandSpeInfo[]>) => void;
}

const contextDefaultValue: EasyDispoContextProps = {
  dispo: undefined,
  setDispo: (dispo) => {},
  affectationFilters: [
    {
      speLabel: '',
      chuLabel: '',
      speCondensedLabel: '',
      chuCondensedLabel: '',
    },
  ],
  setAffectationFilters: () => {},
};

const EasyDispoContext = createContext(contextDefaultValue);

export const EasyDispoProvider: FC = ({ children }) => {
  const [dispo, setDispo] = useState<FormattedDispo | undefined>(undefined);
  const [affectationFilters, setAffectationFilters] = useState<CHUandSpeInfo[]>(
    [
      {
        speLabel: '',
        chuLabel: '',
        speCondensedLabel: '',
        chuCondensedLabel: '',
      },
    ]
  );

  // TODO : ajouter le champ 'Toutes' pour les affectations géographiques
  // useEffect(() => {
  //   affectationFilters.push({
  //     speLabel: '',
  //     chuLabel: 'Toutes',
  //     speCondensedLabel: '',
  //     chuCondensedLabel: `$`,
  //   },)
  // }, [])

  return (
    <EasyDispoContext.Provider
      value={{
        dispo,
        setDispo,
        affectationFilters,
        setAffectationFilters,
      }}
    >
      {children}
    </EasyDispoContext.Provider>
  );
};

export const useEasyDispoContext = () => useContext(EasyDispoContext);

export const STATEMENT_ID = -1;
