import { device } from 'helpers/css/responsive';
import { useSetFQQuality } from 'http/flash-questions/set-quality';
import { QualityLabel } from 'pages/flash-questions/types';
import { useDispatch } from 'react-redux';
import { resetForNext } from 'store/slices/fqCurrentSlice';
import styled from 'styled-components/macro';
import { Button } from 'ui/Button';

interface Props {
  buttonsConfig: QualityLabel[];
  flashQuestionId: number;
}
const DifficultyButtons = ({ flashQuestionId, buttonsConfig }: Props) => {
  const mutation = useSetFQQuality();
  const dispatch = useDispatch();

  const handleClick = (quality: number | null) => {
    mutation.mutate({ flashQuestionId, quality });

    dispatch(resetForNext());
  };

  const shouldShowNextQuestionLabel = buttonsConfig.every(
    (item) => item.quality === null
  );

  return (
    <Container>
      {shouldShowNextQuestionLabel ? (
        <Button style={{ maxWidth: '250px' }} onClick={() => handleClick(null)}>
          Question suivante
        </Button>
      ) : (
        <>
          {buttonsConfig.map((p) => (
            <QualityButton
              quality={p.quality}
              onClick={() => handleClick(p.quality)}
              key={p.label}
            >
              {p.label}
            </QualityButton>
          ))}
        </>
      )}
    </Container>
  );
};

const QualityButton = styled(Button)<{ quality: number | null }>`
  background-color: ${({ quality, theme }) => {
    switch (quality) {
      case 0:
      case 4:
        return theme.colors.warning.light;
      case 1:
      case 5:
        return theme.colors.success.light;
      case 2:
      case 6:
        return theme.colors.primary.main;
      case null:
      default:
        return theme.colors.secondary.light;
    }
  }};
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 740px;
  width: 100%;

  & button {
    width: 100%;
    justify-content: center;
  }

  & button:not(:last-child) {
    margin-bottom: 1rem;
  }

  @media ${device.mobile} {
    flex-direction: row;

    & button:not(:last-child) {
      margin-right: 1rem;
      margin-bottom: 0;
    }
  }
`;

export default DifficultyButtons;
