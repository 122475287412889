import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import debounce from 'lodash.debounce';

interface SearchbarContext {
  searchTerm: string;
  setSearchTerm: (val: string) => void;
  debouncedSearchTerm: string;
}

const contextDefaultValue: SearchbarContext = {
  searchTerm: '',
  setSearchTerm: () => {},
  debouncedSearchTerm: '',
};

const searchbarContext = createContext(contextDefaultValue);

export const SearchbarProvider: FC = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');

  const handleNewSearchterm = useMemo(
    () => debounce(setDebouncedSearchTerm, 500),
    [setDebouncedSearchTerm]
  );

  useEffect(() => {
    handleNewSearchterm(searchTerm);
  }, [handleNewSearchterm, searchTerm]);

  return (
    <searchbarContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        debouncedSearchTerm,
      }}
    >
      {children}
    </searchbarContext.Provider>
  );
};

export const useSearchbarContext = () => useContext(searchbarContext);
