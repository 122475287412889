import { FC } from 'react';
import styled, { ThemeProvider } from 'styled-components/macro';

import { useThemeVariants } from 'hooks/useThemeVariants';
import { getCorrectionTheme } from 'theme';

interface Props {}

export const CorrectionThemeProvider: FC<Props> = ({ children }) => {
  const { themeVariant } = useThemeVariants();
  const correctionTheme = getCorrectionTheme(themeVariant);

  return (
    <ThemeProvider theme={correctionTheme}>
      <NewGlobalStyle>{children}</NewGlobalStyle>
    </ThemeProvider>
  );
};

const NewGlobalStyle = styled.div`
  ${({ theme }) => theme.typography.body}

  span {
    ${({ theme }) => theme.typography.body}
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    ${({ theme }) => theme.typography.fontFamily}

    // Prevent text selection
   -moz-user-select: -moz-none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  input {
    user-select: text;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 0;
  }

  h1 {
    ${({ theme }) => theme.typography.h1}
  }

  h2 {
    ${({ theme }) => theme.typography.h2}
  }

  h3 {
    ${({ theme }) => theme.typography.h3}
  }

  h4 {
    ${({ theme }) => theme.typography.h4}
  }

  h5 {
    ${({ theme }) => theme.typography.h5}
  }

  label {
    ${({ theme }) => theme.typography.label}
  }

  button {
    ${({ theme }) => theme.typography.button}
  }

  a {
    ${({ theme }) => theme.typography.a}
  }

  small {
    ${({ theme }) => theme.typography.small};
  }
`;
