import * as React from 'react';

interface InputProps {
  className?: string;
}

export const CancelIcon = (props: InputProps) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    {...props}
  >
    <circle cx={10} cy={10.641} r={9.5} stroke="currentColor" />
    <path
      d="m14 6.641-8 8M6 6.641l8 8"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
