import { ItemsCards } from './ItemsCards';
import { useGetHomeItems } from 'pages/newHome/hooks/useGetItems';
import { Pagination } from 'components/Pagination/Pagination';
import { useHomeItemsFiltersContext } from 'pages/newHome/context/homeItemsFiltersContext';
import { LoaderWithText } from 'components/Loader/LoaderWithText';

export const ItemsView = () => {
  const { data, isLoading } = useGetHomeItems();
  const { itemPageSize, currentItemPage, setCurrentItemPage, setItemPageSize } =
    useHomeItemsFiltersContext();

  if (isLoading || !data) return <LoaderWithText />;

  return (
    <>
      <ItemsCards items={data} />
      <Pagination
        numberOfPages={Math.ceil(data?.count / itemPageSize) ?? 0}
        currentPage={currentItemPage}
        setCurrentPage={setCurrentItemPage}
        pageSize={itemPageSize}
        setPageSize={setItemPageSize}
        options={[
          { label: '20', value: 20 },
          { label: '50', value: 50 },
          { label: '100', value: 100 },
        ]}
      />
    </>
  );
};
