import { QIProgressBySession } from 'modules/progress/general/qi/domain/entities/QIProgressBySession';
import { FC } from 'react';
import styled from 'styled-components/macro';

interface Props {
  progress: QIProgressBySession;
}

const colNumber = 4;

export const SessionHeader: FC<Props> = ({ progress }) => {
  return (
    <tr>
      <HeaderCell colSpan={colNumber}>{progress.collection}</HeaderCell>
    </tr>
  );
};

const HeaderCell = styled.td`
  background-color: ${({ theme }) => theme.colors.primary.main};

  padding: 4px 12px;
  vertical-align: middle;

  ${({ theme }) => theme.typography.label}
`;
