import styled, { css } from 'styled-components/macro';
import { Rank } from 'types/knowledge.type';

const RankLetter = styled.div<{ letter: Rank | undefined }>`
  display: flex;
  justify-content: center;
  align-content: center;
  color: ${({ theme }) => theme.colors.primary.contrastText};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  padding: 3px;
  font-size: 1rem;
  line-height: 1.1;
  font-weight: 700;

  ${({ letter }) => {
    switch (letter) {
      case 'A':
        return css`
          background-color: ${({ theme }) => theme.colors.secondary.main};
        `;
      case 'B':
        return css`
          background-color: #183a78;
        `;
      case 'C':
        return css`
          background-color: ${({ theme }) => theme.colors.success.main};
        `;
      default:
        return '';
    }
  }}
`;

export default RankLetter;
