import styled from 'styled-components/macro';

import { device } from 'helpers/css/responsive';

import {
  limitedTimeOptions,
  showItemsOptions,
  showMedianOptions,
  showSpecialtyOptions,
} from './options';
import { RadioOption } from './components/RadioOption';
import { SwitchOption } from './components/SwitchOption';
import { Divider } from './components/Divider';
import { useAccount } from '../useAccount';

export const GeneralOptions = () => {
  const { updateUserOptions, userOptions } = useAccount();

  if (userOptions === undefined) return <div>Loading ...</div>;

  return (
    <Container>
      <h2>Général</h2>

      <RadioOption
        label="Temps de composition"
        options={limitedTimeOptions}
        field="trainingDuration"
        preferences={userOptions}
        updateUserPreference={updateUserOptions}
      />

      <SwitchOption
        label="Randomisation des épreuves"
        field="shouldRandomizeTrainingOrder"
        tooltip="Au démarrage de l'entraînement, mélange l'ordre des dossiers et des QI, et masque leur numéro"
        preferences={userOptions}
        updateUserPreference={updateUserOptions}
      />

      <Divider />

      <RadioOption
        label="Affichage des spécialités"
        options={showSpecialtyOptions}
        field="shouldShowSpecialty"
        preferences={userOptions}
        updateUserPreference={updateUserOptions}
      />

      <RadioOption
        label="Affichage des items"
        options={showItemsOptions}
        field="shouldShowItems"
        preferences={userOptions}
        updateUserPreference={updateUserOptions}
      />

      <RadioOption
        label="Affichage de la médiane"
        options={showMedianOptions}
        field="shouldShowMedian"
        preferences={userOptions}
        updateUserPreference={updateUserOptions}
      />

      <SwitchOption
        label="Affichage automatique des commentaires de correction"
        field="shouldShowCorrectionCommentary"
        preferences={userOptions}
        updateUserPreference={updateUserOptions}
      />

      <SwitchOption
        label="Affichage des propositions corrigées en couleur"
        field="shouldUseExplicitCorrectionDisplay"
        tooltip="Le code couleur de la correction (rouge et vert) est retranscrit sur le fond des propositions"
        preferences={userOptions}
        updateUserPreference={updateUserOptions}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 28px;
  margin-top: 20px;
  flex-basis: 100%;
  padding: 16px;

  @media ${device.desktop} {
    margin-top: 0px;
    flex-basis: 760px;
    padding: 32px;
    border-radius: 16px;
    border: 2px solid ${({ theme }) => theme.colors.primary.dark};
  }
`;
