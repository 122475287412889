import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { MultipleChoicesQuestion } from './MultipleChoicesQuestion/MultipleChoicesQuestion';
import { TrainingTask } from 'http/training/get-task/types';
import { QROC } from './QROC/QROC';
import { QRPL } from './QRPL/QRPL';
import { TCS } from './TCS/TCS';
import parse from 'html-react-parser';
import { QZP } from './QZP/QZP';
import { useGetUserOptions } from 'modules/user-options/hooks/useGetUserOptions';
import { StyledMathJax } from 'ui/MathJax';
import { QRUandQRP } from './QRUandQRP/QRUandQRP';

export type TrainingType = {
  name: string;
  statement: string;
  questions: Array<{
    id: number;
    collection: string | undefined;
    saved: boolean;
    type: 'QROC' | 'QRM' | 'QRU' | 'QRP' | 'QRPL' | 'TCS' | 'QZP';
    questionStatement: string;
    count_qrp: number;
    training_items_qcm: TrainingTask['training_groups'][0]['training_questions'][0]['training_items_qcm'];
    training_items_qrpl: TrainingTask['training_groups'][0]['training_questions'][0]['training_items_qrpl'];
    training_item_qroc: TrainingTask['training_groups'][0]['training_questions'][0]['training_item_qroc'];
    training_items_tcs: TrainingTask['training_groups'][0]['training_questions'][0]['training_items_tcs'];
    training_item_qzp: TrainingTask['training_groups'][0]['training_questions'][0]['training_item_qzp'];
    tcs_header: TrainingTask['training_groups'][0]['training_questions'][0]['tcs_header'];
  }>;
  focusedQuestionIndex?: number;
  onSave?(saveQuestions: Array<boolean>): void;
  onModification?(questionIndex: number): void;
  isQI: boolean;
};

interface Props extends TrainingType {
  show?: boolean | undefined;
  visible?: boolean | undefined;
}

export const TrainingBody: FC<Props> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [savedQuestions, setSavedQuestions] = useState<Array<boolean>>(
    props.questions.map((question) => question.saved)
  );

  const { data: userOptions } = useGetUserOptions();

  const questionsRefs = useRef<Array<HTMLElement | null>>(
    Array(props.questions.length + 1)
  );

  const wordingRef = useRef<HTMLElement | null>();

  useEffect(() => {
    if (
      props.focusedQuestionIndex !== undefined &&
      props.focusedQuestionIndex < questionsRefs.current.length
    ) {
      if (props.focusedQuestionIndex === -1) {
        wordingRef.current?.scrollIntoView({
          behavior: 'smooth',
        });
      } else {
        questionsRefs.current[props.focusedQuestionIndex]?.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }
  }, [props.focusedQuestionIndex]);

  useEffect(() => {
    questionsRefs.current = Array(props.questions.length);
    setSavedQuestions(props.questions.map((question) => question.saved));
  }, [props.questions]);

  const handleSave = (index: number) => {
    const newSaved = [...savedQuestions];
    newSaved[index] = true;
    setSavedQuestions(newSaved);
    props.onSave && props.onSave(newSaved);
    questionsRefs.current[index + 1]?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <Container displayComponent={props.show}>
      <ScrollContainer visibleComponent={props.visible}>
        <TrialNameContainer>
          <TrialName>{props.name}</TrialName>
        </TrialNameContainer>
        <Wording ref={(ref) => (wordingRef.current = ref)}>
          <StyledMathJax inline>{parse(props.statement || '')}</StyledMathJax>
        </Wording>
        {props.questions &&
          userOptions &&
          props.questions.map((question, index) => {
            if (question.type === 'QRM') {
              return (
                <MultipleChoicesQuestion
                  key={index}
                  question={question.questionStatement}
                  questionId={question.id}
                  questionNumber={index + 1}
                  answers={question.training_items_qcm}
                  saved={savedQuestions[index]}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  show={
                    props.isQI ||
                    index === 0 ||
                    savedQuestions[index - 1] ||
                    savedQuestions[index]
                  }
                  onSave={() => handleSave(index)}
                  onModification={() =>
                    props.onModification && props.onModification(index)
                  }
                  isQI={props.isQI}
                  ref={(el) => (questionsRefs.current[index] = el)}
                  anonymous={userOptions?.shouldRandomizeTrainingOrder}
                  collection={question.collection}
                />
              );
            } else if (question.type === 'QRU') {
              return (
                <QRUandQRP
                  answersLimit={1}
                  key={index}
                  question={question.questionStatement}
                  questionId={question.id}
                  questionNumber={index + 1}
                  answers={question.training_items_qcm}
                  saved={savedQuestions[index]}
                  show={
                    props.isQI ||
                    index === 0 ||
                    savedQuestions[index - 1] ||
                    savedQuestions[index]
                  }
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  onSave={() => handleSave(index)}
                  onModification={() =>
                    props.onModification && props.onModification(index)
                  }
                  isQI={props.isQI}
                  ref={(el) => (questionsRefs.current[index] = el)}
                  anonymous={userOptions?.shouldRandomizeTrainingOrder}
                  collection={question.collection}
                  questionType={question.type}
                />
              );
            } else if (question.type === 'QRP') {
              return (
                <QRUandQRP
                  answersLimit={question.count_qrp}
                  key={index}
                  question={question.questionStatement}
                  questionId={question.id}
                  questionNumber={index + 1}
                  answers={question.training_items_qcm}
                  saved={savedQuestions[index]}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  show={
                    props.isQI ||
                    index === 0 ||
                    savedQuestions[index - 1] ||
                    savedQuestions[index]
                  }
                  onSave={() => handleSave(index)}
                  onModification={() =>
                    props.onModification && props.onModification(index)
                  }
                  isQI={props.isQI}
                  ref={(el) => (questionsRefs.current[index] = el)}
                  anonymous={userOptions?.shouldRandomizeTrainingOrder}
                  collection={question.collection}
                  questionType={question.type}
                />
              );
            } else if (question.type === 'QROC') {
              return (
                <QROC
                  key={index}
                  question={question.questionStatement}
                  questionId={question.id}
                  questionNumber={index + 1}
                  answer={question.training_item_qroc}
                  saved={savedQuestions[index]}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  show={
                    props.isQI ||
                    index === 0 ||
                    savedQuestions[index - 1] ||
                    savedQuestions[index]
                  }
                  onSave={() => handleSave(index)}
                  onModification={() =>
                    props.onModification && props.onModification(index)
                  }
                  isQI={props.isQI}
                  ref={(el) => (questionsRefs.current[index] = el)}
                  anonymous={userOptions?.shouldRandomizeTrainingOrder}
                  collection={question.collection}
                />
              );
            } else if (question.type === 'QRPL') {
              return (
                <QRPL
                  key={index}
                  answersLimit={question.count_qrp}
                  question={question.questionStatement}
                  questionId={question.id}
                  questionNumber={index + 1}
                  answers={question.training_items_qrpl}
                  saved={savedQuestions[index]}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  show={
                    props.isQI ||
                    index === 0 ||
                    savedQuestions[index - 1] ||
                    savedQuestions[index]
                  }
                  onSave={() => handleSave(index)}
                  onModification={() =>
                    props.onModification && props.onModification(index)
                  }
                  isQI={props.isQI}
                  ref={(ref) => (questionsRefs.current[index] = ref)}
                  anonymous={userOptions?.shouldRandomizeTrainingOrder}
                  collection={question.collection}
                />
              );
            } else if (question.type === 'TCS') {
              return (
                <TCS
                  key={index}
                  question={question.questionStatement}
                  questionId={question.id}
                  questionNumber={index + 1}
                  header={question.tcs_header}
                  answers={question.training_items_tcs}
                  saved={savedQuestions[index]}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  show={
                    props.isQI ||
                    index === 0 ||
                    savedQuestions[index - 1] ||
                    savedQuestions[index]
                  }
                  onSave={() => handleSave(index)}
                  onModification={() =>
                    props.onModification && props.onModification(index)
                  }
                  isQI={props.isQI}
                  ref={(ref) => (questionsRefs.current[index] = ref)}
                  anonymous={userOptions?.shouldRandomizeTrainingOrder}
                  collection={question.collection}
                />
              );
            } else if (question.type === 'QZP') {
              return (
                <QZP
                  key={index}
                  question={question.questionStatement}
                  questionId={question.id}
                  questionNumber={index + 1}
                  answer={question.training_item_qzp}
                  saved={savedQuestions[index]}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  show={
                    props.isQI ||
                    index === 0 ||
                    savedQuestions[index - 1] ||
                    savedQuestions[index]
                  }
                  onSave={() => handleSave(index)}
                  onModification={() =>
                    props.onModification && props.onModification(index)
                  }
                  isQI={props.isQI}
                  shouldUpdateArrowsPositions={props.show}
                  ref={(ref) => (questionsRefs.current[index] = ref)}
                  anonymous={userOptions?.shouldRandomizeTrainingOrder}
                  collection={question.collection}
                />
              );
            } else {
              return null;
            }
          })}
      </ScrollContainer>
    </Container>
  );
};

const Container = styled.div<{
  displayComponent?: boolean | undefined;
}>`
  margin-top: 26px;
  margin-right: 1rem;
  box-sizing: border-box;
  display: ${({ displayComponent }) => (displayComponent ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;

const ScrollContainer = styled.div<{ visibleComponent: boolean | undefined }>`
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 20px;
  visibility: ${({ visibleComponent }) =>
    visibleComponent ? 'visible' : 'hidden'};
`;

const TrialNameContainer = styled.div`
  padding: 0px 32px;
  height: 60px;
  display: flex;
  justify-content: start;
  align-items: center;
  background-color: #183876;
`;

const Wording = styled.p`
  font-family: 'FreeSans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: justify;
  sup {
    font-size: 0.83em;
  }
`;

const TrialName = styled.label`
  color: #ffffff;
  font-family: 'FreeSans';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
`;
