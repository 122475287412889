import { FC, LabelHTMLAttributes } from 'react';
import styled, { useTheme } from 'styled-components/macro';
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  Props,
} from 'react-select';

import { device } from 'helpers/css/responsive';
import { DownChevron } from 'ui/icons/DownChevron';
import { getMultiDropdownStyle } from './dropdownStyles';
import { Option, IsMulti } from './dropdownStyles';

type NativeLabelProps = LabelHTMLAttributes<HTMLLabelElement>;
interface SelectProps extends Props<Option, IsMulti, GroupBase<Option>> {
  label?: string;
  placeholder?: string;
  className?: string;
  labelProps?: NativeLabelProps;
  errorMessage?: string;
}

export const MultiDropdown: FC<SelectProps> = (props) => {
  const theme = useTheme();
  const selectStyles = getMultiDropdownStyle(theme);
  const {
    options,
    label,
    placeholder,
    className,
    labelProps,
    errorMessage,
    ...selectProps
  } = props;

  return (
    <Container className={className}>
      <ReactSelect<Option, IsMulti>
        isMulti={true}
        options={options ? options : []}
        placeholder={placeholder ? placeholder : ''}
        styles={selectStyles}
        components={{ DropdownIndicator: DropdownIndicator }}
        className={errorMessage ? 'isNotValid' : 'isValid'}
        {...selectProps}
      />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  @media ${device.desktop} {
    font-size: 20px;
  }
`;

const DropdownIndicator: FC<DropdownIndicatorProps<Option, IsMulti>> = (
  props
) => {
  return (
    <components.DropdownIndicator {...props}>
      <StyledDownArrow />
    </components.DropdownIndicator>
  );
};

const StyledDownArrow = styled(DownChevron)`
  color: ${({ theme }) => theme.colors.primary.dark};
  object-fit: fill;
  width: 8px;
  height: 4px;
  @media ${device.desktop} {
    width: 16px;
    height: 8px;
  }
`;
