import { FC } from 'react';
import { MenuIconProps } from './types';

export const MenuLogout: FC<MenuIconProps> = ({ isFilled }) => {
  return (
    <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 25C6.373 25 1 19.873 1 13.55c0-4.528 2.754-8.442 6.75-10.3M13 25c6.627 0 12-5.127 12-11.45 0-4.528-2.754-8.442-6.75-10.3"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M13 1v14.25"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
