import { getAPI } from 'auth/authentifiedHttpCalls';
import axios from 'axios';
import { toast } from 'react-toastify';
import { CreateCheckoutSessionResponse, ProductIdHttpParams } from './types';

export const createCheckoutSession = async (params: ProductIdHttpParams) => {
  try {
    const response = await getAPI<CreateCheckoutSessionResponse>(
      '/payments/stripe/create_checkout_session',
      {
        params: {
          ...params,
          product_id: params.product_id,
        },
      }
    );
    return response.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response?.status === 406) {
        toast.info('Vous êtes déjà premium !');
        return;
      }
    }

    throw err;
  }
};
