import { thinScrollbarStyle } from 'helpers/css/thin-scrollbar';
import { reactModalContentStyle } from 'helpers/react-modal/contentStyle';
import { FC } from 'react';
import LibModal from 'react-modal';
import styled, { useTheme } from 'styled-components/macro';
import { device } from 'helpers/css/responsive';

// https://reactcommunity.org/react-modal/accessibility/
if (process.env.NODE_ENV !== 'test') LibModal.setAppElement('#root');

interface Props extends LibModal.Props {
  onClose?: () => void;
  bigPadding?: boolean;
  isBig?: boolean;
}

export const Modal: FC<Props> = (props) => {
  const theme = useTheme();
  const { isOpen, onClose, children, bigPadding, isBig, ...libModalProps } =
    props;
  return (
    <LibModal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      style={{
        overlay: {
          zIndex: theme.zIndex.modal,
          backgroundColor: theme.colors.background.modalOverlay,
        },
        content: {
          ...reactModalContentStyle,
        },
      }}
      {...libModalProps}
    >
      <ModalContainer bigPadding={bigPadding ?? true} isBig={isBig ?? false}>
        {children}
      </ModalContainer>
    </LibModal>
  );
};

const ModalContainer = styled.div<{ bigPadding: boolean; isBig: boolean }>`
  border-radius: 12px;
  padding: ${({ bigPadding }) => (bigPadding ? '32px' : '16px')};
  background-color: ${({ theme }) => theme.colors.background.paper};
  overflow: hidden;
  height: 100%;
  width: ${({ isBig }) => (isBig ? '800px' : '100%')};
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  @media ${device.mobile} {
    width: 100%;
  }
  ${thinScrollbarStyle}
`;
