import styled from 'styled-components/macro';
import { ColumnHeaderWrapper } from './ColumnHeaderWrapper';

export const Actions = () => {
  return (
    <StyledColumnHeaderWrapper isReadOnly>Actions</StyledColumnHeaderWrapper>
  );
};

const StyledColumnHeaderWrapper = styled(ColumnHeaderWrapper)`
  background-color: transparent;

  justify-content: center;
`;
