import { useQuery } from 'react-query';
import { getProgressGeneralDPRepository } from '../http/repository';
import { MS_IN_AN_HOUR } from 'constants/date';

export const USE_GET_DP_PROGRESS_BY_SESSION_KEY =
  'useGetDPProgressBySessionKey';

export const useGetDPProgressBySession = () => {
  return useQuery(
    [USE_GET_DP_PROGRESS_BY_SESSION_KEY],
    () => getProgressGeneralDPRepository(),
    {
      cacheTime: MS_IN_AN_HOUR,
      staleTime: MS_IN_AN_HOUR,
    }
  );
};
