import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { createContext, useContext } from 'react';

import { RootState } from 'store';
import { BaseTableState } from 'store/slices/GenericTableSliceCreator';
import {
  sortBy,
  toggleItemSelection,
  updateItemSelection,
  openRow,
  setPageSize,
  setPage,
} from 'store/slices/qiTableSlice';
import { SortByPayload } from 'store/types';

interface TableConfigContext {
  sortByCreator: ActionCreatorWithPayload<SortByPayload, string>;
  tableStateSelector: (state: RootState) => BaseTableState;
  updateSelectionCreator: ActionCreatorWithPayload<any[], string>;
  toggleSelectionCreator: ActionCreatorWithPayload<any, string>;
  openRowCreator: ActionCreatorWithPayload<number | undefined, string>;
  setPageSizeCreator: ActionCreatorWithPayload<number, string>;
  tableType?: 'DP' | 'LCA';
  setPageCreator: ActionCreatorWithPayload<number, string>;
}

const tableStateSelector = (state: RootState) => state.qiTable;

const contextDefaultValue: TableConfigContext = {
  sortByCreator: sortBy,
  tableStateSelector: tableStateSelector,
  updateSelectionCreator: updateItemSelection,
  toggleSelectionCreator: toggleItemSelection,
  openRowCreator: openRow,
  setPageSizeCreator: setPageSize,
  setPageCreator: setPage,
};

const tableConfigContext = createContext(contextDefaultValue);

export const TableConfigContextProvider = tableConfigContext.Provider;

export const useTableConfigContext = () => useContext(tableConfigContext);
