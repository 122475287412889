import { CorrectionQuestion } from 'types/correction.type';
import { QRPLCorrectionData } from './types';

export const adaptQRPLCorrectionData = (
  questionData: CorrectionQuestion
): QRPLCorrectionData => {
  let userProposition: string = "Vous n'avez pas répondu à la question";

  const qrplPropositions = questionData.correction_items_qrpl;
  const correctedPropositions = qrplPropositions.map((proposition) => {
    const wasRightProposition =
      proposition.answer === proposition.question_item.value ? true : false;
    return {
      wasRightProposition,
      answer: proposition.answer,
      correctedAnswer: proposition.question_item.value,
      text: proposition.question_item.text,
    };
  });

  return {
    userProposition,
    correctedPropositions,
  };
};
