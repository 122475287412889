import { getAPI, postAPI } from 'auth';
import { toast } from 'react-toastify';
import {
  DispoByCHUType,
  DispoInputParameters,
  ScatterPlotDataInputType,
  ScatterPlotDataType,
  ChuNSpeInfo,
} from './types';

export const getEasyDispo = async (params: DispoInputParameters) => {
  const response = await getAPI<DispoByCHUType>('/rankinfo/easy_dispo', {
    params,
  });

  return response.data;
};

export const getScatterPlotData = async (params: ScatterPlotDataInputType) => {
  try {
    const response = await postAPI<ScatterPlotDataType[]>(
      '/rankinfo/scatter_plot',
      params
    );
    return response.data;
  } catch (e) {
    toast.error('Une erreur, veuillez réessayer plus tard');
    throw e;
  }
};

export const getCHUInfos = async () => {
  const response = await getAPI<ChuNSpeInfo[]>('/rankinfo/chu', {});
  return response.data;
};

export const getSpecialitiesInfos = async () => {
  const response = await getAPI<ChuNSpeInfo[]>('/rankinfo/specialty', {});
  return response.data;
};
