import { StringCounter } from './types';

export class SelectionStatistics {
  specialties: StringCounter = {};
  items: StringCounter = {};
  tags: StringCounter = {};
  types: StringCounter = {};
  knowledges: StringCounter = {};

  static NO_TAG = 'noTag';

  addSpecialty(newVal?: string | null) {
    if (!newVal) return;
    const isAlreadyRegistered = Object.keys(this.specialties).includes(newVal);
    if (isAlreadyRegistered) {
      this.specialties[newVal] += 1;
    } else {
      this.specialties[newVal] = 1;
    }
  }

  addItems(newVal?: string | null) {
    if (newVal === null || newVal === undefined) return;

    const isAlreadyRegistered = Object.keys(this.items).includes(newVal);
    if (isAlreadyRegistered) {
      this.items[newVal] += 1;
    } else {
      this.items[newVal] = 1;
    }
  }

  addKnowledges(newVal: string) {
    if (newVal === null || newVal === undefined) return;

    const isAlreadyRegistered = Object.keys(this.knowledges).includes(newVal);
    if (isAlreadyRegistered) {
      this.knowledges[newVal] += 1;
    } else {
      this.knowledges[newVal] = 1;
    }
  }

  addTags(newVals?: string[] | null) {
    if (!newVals || newVals?.length === 0) {
      const isAlreadyRegistered = Object.keys(this.tags).includes(
        SelectionStatistics.NO_TAG
      );
      if (isAlreadyRegistered) {
        this.tags[SelectionStatistics.NO_TAG] += 1;
      } else {
        this.tags[SelectionStatistics.NO_TAG] = 1;
      }
      return;
    }

    newVals.forEach((newVal) => {
      const isAlreadyRegistered = Object.keys(this.tags).includes(newVal);
      if (isAlreadyRegistered) {
        this.tags[newVal] += 1;
      } else {
        this.tags[newVal] = 1;
      }
    });
  }

  addTypes(newVal?: string | null) {
    if (!newVal) return;
    const isAlreadyRegistered = Object.keys(this.types).includes(newVal);
    if (isAlreadyRegistered) {
      this.types[newVal] += 1;
    } else {
      this.types[newVal] = 1;
    }
  }
}
