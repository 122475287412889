import { QIBasicInformations } from '../entities/QIBasicInformations';
import { QIProgressBySession } from '../entities/QIProgressBySession';
import {
  CompositionUnits,
  QIProgressByCompositionUnits,
} from '../entities/QIProgressByCompositionUnits';

export const getQIProgressByCompositionUnits = (
  progressBySession: QIProgressBySession[] | undefined,
  isUserPremium: boolean
): QIProgressByCompositionUnits[] | undefined => {
  if (progressBySession === undefined) {
    return undefined;
  }

  const payload = progressBySession.map((session) => {
    const compositionUnits: CompositionUnits = session.questions.reduce(
      (acc, curr) => {
        const composition_unit = curr.compositionUnit ?? 0;

        if (acc[composition_unit]) {
          acc[composition_unit].push(curr);
        } else {
          acc[composition_unit] = [curr];
        }
        return acc;
      },
      {} as { [key: number]: QIBasicInformations[] }
    );

    const name =
      session.collection +
      (Object.keys(compositionUnits).length > 0 &&
      Object.keys(compositionUnits)[0] !== '0'
        ? ` - UC${Object.keys(compositionUnits)[0]}`
        : '');

    return {
      name,
      collection: session.collection,
      compositionUnits,
    };
  });

  return payload;
};
