import { FC, useMemo } from 'react';
import Tippy from '@tippyjs/react';
import { Knowledge } from 'types/knowledge.type';
import { Chip } from 'components/Item/Chip';

interface Props {
  knowledges: Knowledge[];
}

export const ItemsFromKnowledges: FC<Props> = ({ knowledges }) => {
  const items = useMemo(() => {
    return knowledges.map((knowledge: Knowledge) => knowledge.item);
  }, [knowledges]);

  if (!items) return null;

  return (
    <>
      <Tippy content={items[0]?.short_title} arrow={false} placement="bottom">
        <Chip>{items[0]?.item}</Chip>
      </Tippy>
    </>
  );
};
