import { PreviousQuestionTraining } from 'modules/progress/detailed/shared/entities/PreviousTraining';
import { FC } from 'react';
import styled from 'styled-components/macro';
import { TextBody2 } from 'ui/TextBody2';
import { HistoryList } from './HistoryList';

interface Props {
  history: PreviousQuestionTraining[];
}

export const SubRow: FC<Props> = ({ history }) => {
  return (
    <Container>
      <TextBody2>Historique des copies</TextBody2>
      <HistoryList history={history} />
    </Container>
  );
};

const Container = styled.span`
  grid-column: 1/-1;
  background-color: ${({ theme }) => theme.colors.primary.main};

  padding: 8px 16px 16px;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  gap: 12px;

  max-height: 200px;
`;
