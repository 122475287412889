import { useIsLoading } from 'hooks/useIsLoading';
import {
  useCreateDeck,
  useDeleteCards,
  useFillDeck,
} from 'http/flash-questions/fqdeckcreation/connector';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import styled from 'styled-components';
import { Button } from 'ui/Button';
import { TextInput } from 'ui/TextInput';
import { GoBackBlock } from '../local-ui/GoBackBlock';
import { useDeckNameForm, useOnDeckSubmit } from './deckNameForm';
import { ShowSelectedItemsProvider } from './FQTable/contexts/showSelectedItemsContext';
import FQTable from './FQTable/FQDeckManageTable';
import { reset as resetFQTable } from 'store/slices/fqTableSlice';
import { useGetFQDecksTableValues } from '../FQDeckList/hooks/useGetFQDecksTableValues';
import { device } from 'helpers/css/responsive';
import { selectDeck, selectMode, setMode } from 'store/slices/fqCurrentSlice';
import { FQMode } from 'pages/flash-questions/types';
import { resetFilters } from 'store/slices/selectionFiltersSlice';
import { reset as resetQiTable } from 'store/slices/qiTableSlice';
import { useTableConfigContext } from './FQTable/contexts/tableConfigContext';
import { useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';

export const FQDeckManage = () => {
  const {
    register,
    formState: { errors },
    setValue,
    getValues,
    trigger,
  } = useDeckNameForm();

  const selectedFQ = useSelector(
    (state: RootState) => state.fqTable.selectedItemsInfos
  );

  const { isLoading, startLoading, stopLoading } = useIsLoading();

  const { createDeckFromName } = useCreateDeck();
  const { addCardsToDeck, mutation: addMutation } = useFillDeck();
  const { deleteCards, mutation: deleteMutation } = useDeleteCards();

  const deck = useSelector(selectDeck);
  const mode = useSelector(selectMode);

  const dispatch = useDispatch();
  const client = useQueryClient();

  const { updateSelectionCreator } = useTableConfigContext();

  const resetCreationPage = () => {
    dispatch(resetFilters());
    dispatch(resetFQTable());
    dispatch(resetQiTable());
    dispatch(updateSelectionCreator([]));
  };

  const presentFQList = useCallback(() => {
    dispatch(setMode({ mode: FQMode.DECKLIST }));
  }, [dispatch]);

  const { refetch } = useGetFQDecksTableValues();

  const onDeckSubmit = useOnDeckSubmit({
    selectedFQ,
    createDeckFromName,
    resetCreationPage,
    addCardsToDeck,
    onFetch: startLoading,
    afterFetch: stopLoading,
    refetchDeckList: refetch,
  });

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isValid = await trigger();
    if (!isValid) {
      return;
    }
    if (mode === FQMode.DECKEDIT) {
      if (!deck) return;
      const initialDeckCardsId = deck.cards.map((card) => card.question || 0);
      const selected = selectedFQ.map(({ id }) => id);

      if (selected.length === 0) {
        return toast.error('Vous devez sélectionner au moins une carte');
      }

      const deleted = initialDeckCardsId.filter(
        (id) => !selected.includes(Number(id))
      );

      const added = selected.filter(
        (id) => !initialDeckCardsId.includes(Number(id))
      );

      if (deleted.length) {
        deleteCards(deck.label, deleted);
      }

      if (added.length && deck) {
        addCardsToDeck(deck.label, added);
      }
    } else {
      onDeckSubmit(getValues());
    }
  };

  useEffect(() => {
    if (deleteMutation.isSuccess || addMutation.isSuccess) {
      presentFQList();
      toast.success('Le paquet a été mis à jour avec succès');
      client.invalidateQueries('fqDecksData');
    }
  }, [deleteMutation, addMutation, presentFQList, client]);

  useEffect(
    () => () => {
      resetCreationPage();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (deck) {
      setValue('deckName', deck.label);
    }
  }, [deck, setValue]);

  return (
    <Container>
      <Layout>
        <TitleRow>
          {mode === FQMode.DECKEDIT && <h1>Édition de paquet</h1>}
          {mode === FQMode.DECKCREATION && <h1>Création de paquet</h1>}
          <GoBackBlock goBackFunction={presentFQList} />
        </TitleRow>
        <Form onSubmit={submit} noValidate>
          <DeckNameInput
            label={`${
              mode === FQMode.DECKEDIT
                ? 'Nom du paquet (non éditable)'
                : 'Nom du paquet'
            }`}
            type="text"
            disabled={mode === FQMode.DECKEDIT}
            placeholder="Écrivez le nom du paquet..."
            maxLength={30}
            isMandatory
            bottomErrorMessage={errors.deckName?.message}
            {...register('deckName')}
          />

          <ShowSelectedItemsProvider>
            <FQTable />
          </ShowSelectedItemsProvider>
          <CreationButton type="submit" isLoading={isLoading}>
            {mode === FQMode.DECKEDIT && 'Éditer le paquet'}
            {mode === FQMode.DECKCREATION && 'Créer le paquet'}
          </CreationButton>
        </Form>
      </Layout>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;

  padding: 16px;
  @media ${device.desktop} {
    padding: 32px;
  }
`;

const Layout = styled.div`
  width: 100%;
  max-width: 1300px;

  display: flex;
  flex-direction: column;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  margin-bottom: 16px;

  @media ${device.desktop} {
    margin-bottom: 32px;
  }
`;

const Form = styled.form`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
`;

const DeckNameInput = styled(TextInput)`
  max-width: 320px;
  margin-bottom: 3%;
`;

const CreationButton = styled(Button)`
  padding-left: 5%;
  padding-right: 5%;
  align-self: center;
  position: fixed;
  bottom: 50px;
`;
