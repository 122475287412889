import React from 'react';
import styled from 'styled-components';
import { FC } from 'react';
import { Arrow } from 'ui/icons/Arrow';
import { AboutTheItem } from '../../components/AboutTheItem';
import { RelatedKnowledges } from 'pages/compass/component/Itemsview/RelatedKnowledges';
import { RelatedBooks } from './RelatedBooks';
import {
  GlobalContainer,
  Section,
  SectionTitle,
} from 'pages/newHome/ui/Global';
import { device } from 'helpers/css/responsive';
import ItemStats from './ItemStats';
import { LoaderWithText } from 'components/Loader/LoaderWithText';
import { useHomeInfoContext } from 'pages/newHome/context/homeInfoContext';
import { useGetHomeItem } from 'http/new-home/connector';

interface Props {
  itemId?: number;
}

export const SingleItemView: FC<Props> = ({ itemId }) => {
  const { isLoading } = useHomeInfoContext();
  const { data } = useGetHomeItem(itemId?.toString());

  if (isLoading) {
    return <LoaderWithText text="Chargement de l'item..." />;
  }

  return (
    <GlobalContainer maxWidth="1300px" gap="3rem" centered>
      <Section>
        <SectionTitle>Mes statistiques asclepia</SectionTitle>
        <ItemStats />
      </Section>
      <ItemInfosContainer>
        <RelatedKnowledges knowledges={data?.knowledges} />
        <RelatedBooks books={data?.books} />
      </ItemInfosContainer>
      <Section>
        <SectionTitle>À propos de l'item</SectionTitle>
        <AboutTheItem />
      </Section>
    </GlobalContainer>
  );
};

export const BackwardsIcon = styled(Arrow)`
  height: 16px;
  width: 8px;
  cursor: pointer;
`;

const ItemInfosContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 48px;
  width: 100%;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;
