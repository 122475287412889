import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { requestEmailPasswordReset } from 'http/lost-password/api';
import { useGoToAuthConfirmation } from 'pages/auth-confirmation/hooks/useGoToAuthConfirmation';

interface FormInputs {
  email: string;
}

const formSchema = yup.object({
  email: yup
    .string()
    .email('Veuillez rentrer un email valide')
    .required('Veuillez rentrer un email'),
});

export const useLostPasswordForm = () => {
  const rhf = useForm<FormInputs>({
    resolver: yupResolver(formSchema),
  });

  return {
    ...rhf,
  };
};

export const useLostPasswordFormSubmit = ({
  onFetch,
  afterFetch,
}: {
  onFetch: () => void;
  afterFetch: () => void;
}) => {
  const goToAuthConfirmation = useGoToAuthConfirmation();

  const onLostPasswordFormSubmit: SubmitHandler<FormInputs> = async (data) => {
    onFetch();
    try {
      await requestEmailPasswordReset(data.email);
      goToAuthConfirmation({
        title: 'Vous allez bientôt pouvoir réinitialiser votre mot de passe !',
        subtitle: `Nous vous avons envoyé un mail à votre adresse ${data.email}`,
      });
    } finally {
      afterFetch();
    }
  };

  return onLostPasswordFormSubmit;
};
