import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';
import { getTimer } from 'http/training/get-timer/api';
import { toast } from 'react-toastify';

export const getTimerFromAPI = async (taskId: number) => {
  try {
    const timer = await getTimer(taskId);
    return timer;
  } catch (e) {
    toast.error(
      "Nous n'avons pas réussi à récupérer le temps restant. Veuillez réessayer un peu plus tard."
    );
    reportErrorToSentry(e);
  }
};
