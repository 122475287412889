import { FC } from 'react';
import styled from 'styled-components';
import { FilterIcon } from 'ui/icons/Filter';

import { FullWidthRow } from './FullWidthRow';

interface Props {
  colNumber: number;
}

export const TableNoResults: FC<Props> = ({ colNumber }) => (
  <FullWidthRow colNumber={colNumber}>
    <NoResultsContainer>
      Aucun résultat ne correspond à votre recherche, essayez de retirer des
      filtres <Filter />
    </NoResultsContainer>
  </FullWidthRow>
);

const NoResultsContainer = styled.div`
  padding: 16px;
  text-align: center;
`;

const Filter = styled(FilterIcon)`
  height: 12px;
  width: 12px;
`;
