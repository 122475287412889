export const EyeClosed = () => {
  return (
    <svg
      width="30"
      height="18"
      viewBox="0 0 30 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.08187 13.952C5.80117 12.8092 5.07895 12.3598 2 9.90074C5.07895 6.38774 9.93684 2.52344 15.6842 2.52344C16.8246 2.52344 19.4854 2.75201 21.0058 3.66629"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8.08187 13.952C5.80117 12.8092 5.07895 12.3598 2 9.90074C5.07895 6.38774 9.93684 2.52344 15.6842 2.52344C16.8246 2.52344 19.4854 2.75201 21.0058 3.66629"
        stroke="currentColor"
        strokeOpacity="0.2"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M8.08187 13.952C5.80117 12.8092 5.07895 12.3598 2 9.90074C5.07895 6.38774 9.93684 2.52344 15.6842 2.52344C16.8246 2.52344 19.4854 2.75201 21.0058 3.66629"
        stroke="currentColor"
        strokeOpacity="0.2"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21.006 3.66699C25.1873 6.33366 25.9475 7.47652 28.0002 9.90144C25.9475 12.3605 20.6107 15.9438 15.6844 16.2248C14.2906 16.2294 10.8189 15.7813 8.08203 13.9527"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21.006 3.66699C25.1873 6.33366 25.9475 7.47652 28.0002 9.90144C25.9475 12.3605 20.6107 15.9438 15.6844 16.2248C14.2906 16.2294 10.8189 15.7813 8.08203 13.9527"
        stroke="currentColor"
        strokeOpacity="0.2"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M21.006 3.66699C25.1873 6.33366 25.9475 7.47652 28.0002 9.90144C25.9475 12.3605 20.6107 15.9438 15.6844 16.2248C14.2906 16.2294 10.8189 15.7813 8.08203 13.9527"
        stroke="currentColor"
        strokeOpacity="0.2"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <ellipse
        cx="15.4562"
        cy="8.23851"
        rx="4.94152"
        ry="4.95238"
        fill="currentColor"
      />
      <ellipse
        cx="15.4562"
        cy="8.23851"
        rx="4.94152"
        ry="4.95238"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <ellipse
        cx="15.4562"
        cy="8.23851"
        rx="4.94152"
        ry="4.95238"
        fill="currentColor"
        fillOpacity="0.2"
      />
      <path
        d="M24.0464 1L4.28027 17"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M24.0464 1L4.28027 17"
        stroke="currentColor"
        strokeOpacity="0.2"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M24.0464 1L4.28027 17"
        stroke="currentColor"
        strokeOpacity="0.2"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
