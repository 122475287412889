import { DetailGroupResponse } from 'http/progress/detail/group/types';
import { SelectionGroupResult } from 'http/selection-group/types';
import { useCanAddToCart } from 'pages/selection/hooks/useCanAddToCart';
import { useDispatch } from 'react-redux';
import { toggleItemSelection as toggleDPItem } from 'store/slices/dpTableSlice';

export const useToggleDPInCart = () => {
  const dispatch = useDispatch();
  const canAddToCart = useCanAddToCart();

  return function addSimilarQuestionToCart(question: DetailGroupResponse) {
    const toAdd =
      question.number_of_questions >= 13
        ? { short: 0, long: 1 }
        : { short: 1, long: 1 };
    if (!canAddToCart('DP', 1, toAdd)) {
      return;
    }

    const adaptedQuestion = adaptDetailDPToDPQuestion(question);
    dispatch(toggleDPItem(adaptedQuestion));
  };
};

export const adaptDetailDPToDPQuestion = (
  dp: DetailGroupResponse
): SelectionGroupResult => {
  return {
    id: dp.id,
    num: dp.num,
    composition_unit: dp.composition_unit,
    number_of_questions: dp.number_of_questions,
    specialty1: dp.specialty1,
    specialty2: dp.specialty2,
    type: 'DP',
    collection: dp.collection,
    total_coefficient: dp.total_coefficient,
    denom: dp.denom,
    thumbnail: dp.thumbnail,
    median: dp.median,
    is_free: dp.is_free,
    grade: dp.grade,
    last_training: dp.last_training,
    last_training_date: dp.last_training_date,
  };
};
