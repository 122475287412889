import { QCMPropositionState } from 'types/correction.type';

const A_UNICODE_VALUE = 65;

/**
 * Converts the num of a proposition (starting from 1) to A, B, C, D...
 */
export const propositionNumToLetter = (num: number) =>
  String.fromCharCode(A_UNICODE_VALUE - 1 + num);

export const propositionStateToString = (
  state: QCMPropositionState
): string => {
  switch (state) {
    case 'ANN':
      return 'Annulée';
    case 'F':
      return 'Faux';
    case 'V':
      return 'Vrai';
    case 'MZ':
      return 'Mis zéro';
    case 'PMZ':
      return 'Pas mis zéro';
  }
};
