import { device } from 'helpers/css/responsive';
import { forwardRef, InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components/macro';

// Inspired by https://www.w3schools.com/howto/howto_css_custom_checkbox.asp

type NativeInputProps = InputHTMLAttributes<HTMLInputElement>;
interface Props extends Omit<NativeInputProps, 'type'> {}

export const Radio = forwardRef<HTMLInputElement, Props>(
  ({ readOnly, ...nativeInputProps }, ref) => {
    return (
      <Label onClick={(e) => e.stopPropagation()} isReadOnly={readOnly}>
        <Input
          type="radio"
          ref={ref}
          readOnly={readOnly}
          {...nativeInputProps}
        />
        <Checkmark />
      </Label>
    );
  }
);

/**
 * The label acts as the container.
 * We use the label instead of the proper input because we can't
 * put childrens in an input.
 *
 * In this custom checkbox component we exploit the fact
 * that clicking on a label associated to an input is equivalent
 * to clicking on the input itself.
 */
const Label = styled.label<{ isReadOnly?: boolean }>`
  display: block;
  position: relative;

  cursor: pointer;
  ${({ isReadOnly }) =>
    isReadOnly &&
    css`
      cursor: default;
    `}

  height: 16px;
  width: 16px;

  @media ${device.desktop} {
    height: 20px;
    width: 20px;
  }
`;

const Input = styled.input<{ hasError?: boolean }>`
  all: unset;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid
    ${({ theme, hasError }) =>
      hasError ? theme.colors.error.main : theme.colors.primary.dark};
  box-sizing: border-box;

  border-radius: 100%;

  &:focus-visible {
    outline-offset: 3px;
    outline: 2px solid black;
  }
`;

const Checkmark = styled.span<{ hasError?: boolean }>`
  // Defines the checkmark container, the checkmark
  // itself will the :after pseudo element
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    display: none;
    // Center the check mark in the containing label
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  ${Input}:checked ~ &:after {
    display: block;
  }

  // Styles for the actual check marker
  &:after {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: ${({ theme, hasError }) =>
      hasError ? theme.colors.error.main : theme.colors.primary.dark};

    @media ${device.desktop} {
      height: 12px;
      width: 12px;
    }
  }
`;
