import { ProgressGeneralLCARepository } from '../domain/ProgressGeneralLCARepository.interface';
import { mapGeneralProgressDPHttpToDomain } from './adapter';
import { getProgressGeneralLCAHttp } from './api';

export const getProgressGeneralLCARepository =
  (): ProgressGeneralLCARepository => progressGeneralLCAImpl;

const progressGeneralLCAImpl: ProgressGeneralLCARepository = {
  getLCAProgress: async () => {
    const progress = await getProgressGeneralLCAHttp();
    progress.reverse();
    return mapGeneralProgressDPHttpToDomain(progress);
  },
};
