import { FC, SVGAttributes } from 'react';
import { useTheme } from 'styled-components/macro';

type SVGNativeProps = SVGAttributes<SVGElement>;

export const CheckboxError: FC<SVGNativeProps> = (props) => {
  const theme = useTheme();

  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect
        x={0.5}
        y={0.5}
        width={19}
        height={19}
        rx={3.5}
        stroke={theme.colors.error.main}
      />
      <path
        d="M9.01 12.8V3h1.77v9.8H9.01Zm.89 4.46c-.307 0-.567-.107-.78-.32a1.073 1.073 0 0 1-.32-.79c0-.3.107-.553.32-.76.213-.213.473-.32.78-.32.3 0 .553.107.76.32.213.207.32.46.32.76 0 .313-.107.577-.32.79-.207.213-.46.32-.76.32Z"
        fill={theme.colors.error.main}
      />
    </svg>
  );
};
