import {
  ShouldShowItems,
  ShouldShowMedian,
  ShouldShowSpecialty,
  TrainingDuration,
} from 'modules/user-options/domain/entities/UserOptions';
import { RadioTextOptions } from 'ui/RadioTexts/types';

export const limitedTimeOptions: RadioTextOptions<TrainingDuration>[] = [
  {
    label: 'Normal',
    value: 'normal',
  },
  {
    label: 'Tiers temps',
    value: 'extraTime',
  },
  {
    label: 'Illimité',
    value: 'illimited',
  },
];

export const showSpecialtyOptions: RadioTextOptions<ShouldShowSpecialty>[] = [
  {
    label: 'Si déjà fait',
    value: 'whenDone',
  },
  {
    label: 'Oui',
    value: 'always',
  },
  {
    label: 'Non',
    value: 'never',
  },
];

export const showItemsOptions: RadioTextOptions<ShouldShowItems>[] = [
  {
    label: 'Si déjà fait',
    value: 'whenDone',
  },
  {
    label: 'Oui',
    value: 'always',
  },
  {
    label: 'Non',
    value: 'never',
  },
];

export const showMedianOptions: RadioTextOptions<ShouldShowMedian>[] = [
  {
    label: 'Si déjà fait',
    value: 'whenDone',
  },
  {
    label: 'Oui',
    value: 'always',
  },
  {
    label: 'Non',
    value: 'never',
  },
];
