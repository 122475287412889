import { SessionOption } from 'pages/home/components/TrainingStats/SessionDropdown';
import { createContext, FC, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'store';
import { updateSelectedSession } from 'store/slices/progressSlice';

interface SessionContext {
  selectedSession?: SessionOption;
  setSelectedSession: (newVal: SessionOption) => void;
}

const contextDefaultValue: SessionContext = {
  selectedSession: {
    label: 'toto',
    value: {
      collection: 'ECNi 2021',
      completed: false,
    },
  },
  setSelectedSession: () => {},
};

const sessionContext = createContext(contextDefaultValue);

export const SessionProvider: FC = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedSession = useSelector(
    (state: RootState) => state.progress.selectedSession
  );
  const setSelectedSession = (newSession: SessionOption) => {
    navigate(
      `/progress?currentView=sessionDetail&selectedSession=${newSession.label}`
    );
    dispatch(updateSelectedSession(newSession));
  };

  return (
    <sessionContext.Provider value={{ selectedSession, setSelectedSession }}>
      {children}
    </sessionContext.Provider>
  );
};

export const useSessionContext = () => useContext(sessionContext);
