import styled from 'styled-components/macro';

export const ProgressContainer = styled.div`
  border-radius: 15px;
  overflow: hidden;
  width: fit-content;
  min-width: 200px;

  display: flex;
  flex-direction: column;
  flex-shrink: 0;
`;
