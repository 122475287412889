import { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import Tippy from '@tippyjs/react';

interface RepartitionBarProps {
  repartition: Record<string, number>;
  total: number;
}

export const KnowledgeRepartitionBar: FC<RepartitionBarProps> = ({
  repartition,
  total,
}) => {
  return (
    <Container>
      {Object.keys(repartition).map(
        (rank) =>
          repartition[rank] !== 0 && (
            <Tippy
              content={
                <LineReturnContainer>
                  {repartition[rank]} questions
                </LineReturnContainer>
              }
              arrow={false}
              key={rank}
              placement="bottom"
            >
              <Bar
                rank={rank}
                key={rank}
                sizePercentage={(repartition[rank] / total) * 100}
              >
                {rank}
              </Bar>
            </Tippy>
          )
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const Bar = styled.div<{ rank: string; sizePercentage: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary.dark};
  border: 0.5px solid ${({ theme }) => theme.colors.primary.contrastText};
  height: '24px';
  width: ${({ sizePercentage }) => sizePercentage}%;

  ${({ rank }) => {
    switch (rank) {
      case 'A':
        return css`
          background-color: ${({ theme }) => theme.colors.secondary.main};
        `;
      case 'B':
        return css`
          background-color: #183a78;
        `;
      case 'C':
        return css`
          background-color: ${({ theme }) => theme.colors.success.main};
        `;
      default:
        return '';
    }
  }}
`;

const LineReturnContainer = styled.div`
  white-space: pre-line;
`;
