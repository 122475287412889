import { FC, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import { useClickedQuestionContext } from '../clickedQuestionContext';

import { CorrectionGroupHeader } from '../components/CorrectionGroupHeader/CorrectionGroupHeader';
import { CorrectionGroupInfos } from '../components/CorrectionGroupInfos';
import { QuestionCorrectionBlock } from './questions-correction-blocks/QuestionCorrectionBlock';
import { Loader } from 'components/Loader/Loader';
import { ActiveCorrectionProvider } from './questions-correction-blocks/activeCorrectionContext';
import { CorrectionThemeProvider } from 'components/CorrectionThemeProvider';
import { CorrectionGroup } from 'types/correction.type';

interface Props {
  groupData?: CorrectionGroup;
}

export const ActiveCorrectionGroup: FC<Props> = ({ groupData }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { blockToggle } = useClickedQuestionContext();

  useEffect(() => {
    containerRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [groupData, blockToggle]);

  return (
    <CorrectionCard ref={containerRef}>
      {groupData ? (
        <div id="correction-card-group">
          <CorrectionGroupHeader groupData={groupData} />
          <CorrectionBody>
            <CorrectionThemeProvider>
              <CorrectionGroupInfos groupData={groupData} />
              <ActiveCorrectionProvider groupData={groupData}>
                <QuestionsContainer>
                  {groupData.correction_questions.map((questionData) => (
                    <QuestionCorrectionBlock
                      questionData={questionData}
                      key={questionData.id}
                    />
                  ))}
                </QuestionsContainer>
              </ActiveCorrectionProvider>
            </CorrectionThemeProvider>
          </CorrectionBody>
        </div>
      ) : (
        <Loader />
      )}
    </CorrectionCard>
  );
};

const CorrectionCard = styled.div`
  border-radius: 8px;
  border: 2px solid ${({ theme }) => theme.colors.primary.light};

  display: flex;
  flex-direction: column;
  padding: 12px 0;
  width: 100%;

  --horizontal-padding: 28px;
`;

const CorrectionBody = styled.div`
  padding: 12px 0 12px;
  border-top: 2px solid ${({ theme }) => theme.colors.primary.light};
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin-top: 20px;
`;
