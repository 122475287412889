import { FC } from 'react';

import { SessionRecap } from 'modules/recap/domain/entities/entity';
import { isSessionCompleted } from '../../../helpers/isSessionCompleted';

interface Props {
  sessionRecap?: SessionRecap;
}

export const Grade: FC<Props> = ({ sessionRecap }) => {
  const isCompleted = isSessionCompleted(sessionRecap);

  if (sessionRecap === undefined) {
    return null;
  }

  if (isCompleted) {
    return (
      <>
        Note : {Math.floor(sessionRecap?.grade)}/{sessionRecap?.denom} points
      </>
    );
  }

  return <>Note disponible quand 100% effectué</>;
};
