import { useTheme } from 'styled-components';
import { getTheme } from 'theme';

export const AsclepiaLogoWithText = (props: {
  className?: string;
  disableDarkMode?: boolean;
}) => {
  let theme = useTheme();
  if (props.disableDarkMode) {
    theme = getTheme('light');
  }

  return (
    <svg
      viewBox="0 0 132 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M39.4672 23.9871C39.4672 32.4953 32.5673 39.3951 24.0591 39.3951C15.6548 39.3951 8.82025 32.6644 8.65475 24.2984C8.65216 24.1946 8.65112 24.0909 8.65112 23.9871C8.65112 15.492 15.5251 8.60297 24.0145 8.5791H24.0591C32.5673 8.5791 39.4672 15.4769 39.4672 23.9871Z"
        fill={theme.colors.primary.main}
      />
      <path
        d="M27.9561 27.0582C32.6911 27.0582 36.5296 23.2197 36.5296 18.4847C36.5296 13.7496 32.6911 9.91113 27.9561 9.91113C23.2211 9.91113 19.3826 13.7496 19.3826 18.4847C19.3826 23.2197 23.2211 27.0582 27.9561 27.0582Z"
        fill={theme.colors.secondary.main}
      />
      <path
        d="M38.7466 17.7921C37.207 14.135 34.3635 11.1804 30.768 9.50193C27.1725 7.82347 23.0816 7.54093 19.2894 8.70915C15.4973 9.87737 12.2746 12.413 10.2469 15.8238C8.21919 19.2345 7.53121 23.2771 8.31652 27.1666C9.10183 31.0561 11.3044 34.515 14.4966 36.8718C17.6888 39.2287 21.6429 40.3153 25.5912 39.9206C29.5395 39.526 33.2003 37.6784 35.863 34.7364C38.5256 31.7944 40 27.9679 40 24C40.0027 21.8676 39.5765 19.7564 38.7466 17.7921ZM24.0519 8.9338C32.2161 8.9338 38.8846 15.4612 39.1118 23.5714H30.779C29.0023 21.8239 26.6101 20.8446 24.118 20.8446C21.626 20.8446 19.2337 21.8239 17.457 23.5714H8.99196C9.21919 15.4612 15.8877 8.9338 24.0519 8.9338ZM15.5007 30.3458C15.5007 25.5932 19.3672 21.7261 24.1204 21.7261C28.8735 21.7261 32.738 25.5932 32.738 30.3458C32.738 35.0984 28.8709 38.9655 24.1183 38.9655C19.3657 38.9655 15.5007 35.0974 15.5007 30.3458ZM8.99248 24.4529H16.6643C15.2205 26.28 14.4977 28.5736 14.6334 30.8984C14.769 33.2232 15.7535 35.4173 17.4 37.0641C17.7392 37.4033 18.1034 37.7167 18.4894 38.0016C13.0593 35.8372 9.1751 30.6015 8.99248 24.4529ZM29.9049 37.8833C30.2333 37.6305 30.5453 37.3568 30.8387 37.0641C32.4852 35.4173 33.4697 33.2232 33.6053 30.8984C33.7409 28.5736 33.0182 26.28 31.5743 24.4529H39.1113C38.9318 30.4921 35.182 35.6499 29.9049 37.8833Z"
        fill={theme.colors.primary.dark}
      />
      <path
        d="M29.6918 36.1527L24.9526 24.1267L24.0085 24.1064L23.3211 24.1267C23.3211 24.1267 23.1654 25.6898 24.052 27.0236C24.9386 28.3574 28.248 35.0322 28.248 35.0322C28.248 35.0322 28.5406 35.7138 29.6918 36.1527Z"
        fill={theme.colors.primary.dark}
      />
      <path
        d="M12.9919 16.4841L23.3252 24.1269L24.0307 24.1036L24.0079 22.8118C24.0079 22.8118 23.97 22.3106 22.5345 21.5988C21.0991 20.8871 14.8046 16.7331 14.8046 16.7331C14.8046 16.7331 14.2069 16.289 12.9919 16.4841Z"
        fill={theme.colors.primary.dark}
      />
      <path
        d="M31.7793 13.235L24.0546 22.6769C24.01 22.7303 23.9867 22.7983 23.9892 22.8679L24.0297 24.1264H24.9552C24.9552 24.1264 25.8236 24.0969 26.5598 22.6738C27.296 21.2508 31.4987 15.0393 31.4987 15.0393C31.4987 15.0393 31.9537 14.4547 31.7793 13.235Z"
        fill={theme.colors.primary.dark}
      />
      <path
        d="M57.0263 18.9998H58.7814V28.9997H57.0158L56.9528 27.3096C56.6336 27.8818 56.1681 28.3673 55.5997 28.7205C54.99 29.0898 54.2795 29.2776 53.5583 29.26C52.8153 29.2659 52.0791 29.1246 51.396 28.845C50.7347 28.5732 50.1337 28.1831 49.6252 27.6956C49.1159 27.2087 48.7095 26.6323 48.4284 25.998C48.1401 25.3431 47.9943 24.6387 48.0002 23.9281C47.9967 23.2441 48.1348 22.5662 48.4065 21.9336C48.6782 21.301 49.0781 20.7261 49.5831 20.2422C50.0793 19.7667 50.6661 19.3868 51.3119 19.123C51.9759 18.8508 52.6915 18.713 53.4138 18.7181C54.18 18.7018 54.9353 18.8942 55.5918 19.2727C56.2031 19.6336 56.7159 20.1284 57.0881 20.7163L57.0263 18.9998ZM53.599 27.6327C54.2381 27.6482 54.8663 27.4724 55.3961 27.1297C55.9 26.7937 56.2963 26.33 56.5403 25.7905C56.806 25.2049 56.9256 24.5681 56.8897 23.9306C56.8713 23.2993 56.7038 22.6802 56.3997 22.1197C56.1173 21.601 55.6996 21.1609 55.1872 20.8421C54.6571 20.5193 54.0392 20.3539 53.4111 20.3667C52.7474 20.3608 52.0956 20.5353 51.5313 20.8697C50.9745 21.1967 50.5185 21.6593 50.2097 22.2103C49.8898 22.7841 49.7399 23.4307 49.7762 24.0802C49.8051 24.716 50.0065 25.3337 50.3608 25.8722C50.7057 26.4025 51.1811 26.8437 51.7454 27.1574C52.3073 27.4727 52.9477 27.637 53.599 27.6327Z"
        fill={theme.colors.primary.dark}
      />
      <path
        d="M64.5799 29.1897C64.0184 29.1721 63.4631 29.0711 62.9339 28.8904C62.4254 28.7185 61.952 28.4631 61.5349 28.1359C61.1547 27.8452 60.8552 27.4695 60.6626 27.0418L62.1667 26.4219C62.2958 26.6769 62.4895 26.897 62.7303 27.062C63.0153 27.2685 63.3328 27.4302 63.6709 27.5411C64.3663 27.7774 65.1249 27.781 65.8227 27.5511C66.1354 27.4514 66.4171 27.2785 66.6424 27.0481C66.8533 26.8293 66.9677 26.541 66.9616 26.2433C66.9692 26.0954 66.9415 25.9478 66.8808 25.8117C66.82 25.6755 66.7277 25.5543 66.6108 25.4573C66.352 25.2539 66.0538 25.1014 65.7333 25.0084C65.3826 24.8977 65.0253 24.7934 64.6627 24.6928C64.0041 24.5375 63.3609 24.3271 62.7408 24.064C62.2279 23.8566 61.772 23.5386 61.4088 23.1347C61.0734 22.7228 60.9017 22.2103 60.924 21.6885C60.9101 21.1096 61.0993 20.5429 61.4613 20.0789C61.8306 19.6176 62.3185 19.2558 62.8774 19.0289C63.47 18.7808 64.1105 18.6548 64.7573 18.6591C65.5595 18.65 66.3518 18.8288 67.0654 19.1798C67.7211 19.489 68.2637 19.9808 68.6221 20.5907L67.2125 21.3804C67.0847 21.1333 66.8985 20.918 66.6686 20.7517C66.417 20.5638 66.1347 20.4168 65.8332 20.3166C65.5244 20.2104 65.1996 20.153 64.8716 20.1468C64.4877 20.1305 64.104 20.1812 63.7392 20.2964C63.4142 20.393 63.1221 20.5711 62.8932 20.812C62.6779 21.0483 62.5639 21.3538 62.574 21.6672C62.5652 21.8095 62.5912 21.9518 62.6501 22.0828C62.7089 22.2138 62.7989 22.3299 62.9129 22.4217C63.1782 22.6134 63.4798 22.7543 63.8009 22.8367C64.1674 22.9398 64.5655 23.058 64.9977 23.1913C65.6051 23.3749 66.1985 23.5984 66.7737 23.8603C67.282 24.0845 67.7357 24.4079 68.1058 24.8097C68.4484 25.2047 68.6249 25.7078 68.601 26.2207C68.6116 26.8033 68.4085 27.3711 68.027 27.8253C67.6325 28.2898 67.12 28.6496 66.5386 28.8703C65.9179 29.1125 65.2494 29.2216 64.5799 29.1897Z"
        fill={theme.colors.primary.dark}
      />
      <path
        d="M77.6044 26.3605L79.1716 27.1703C78.6999 27.8022 78.0834 28.3222 77.3692 28.6907C76.5969 29.087 75.7296 29.2826 74.8537 29.2579C73.9777 29.2331 73.1239 28.989 72.3773 28.5498C71.5744 28.0671 70.9142 27.3955 70.4598 26.5989C70.0053 25.8024 69.7718 24.9076 69.7815 24.0001C69.7692 22.6132 70.3165 21.2753 71.3093 20.2652C71.7813 19.7867 72.3474 19.402 72.975 19.1334C73.6717 18.8383 74.4295 18.6992 75.1908 18.7269C75.9521 18.7546 76.6967 18.9482 77.3679 19.2932C78.084 19.6634 78.7008 20.1872 79.1703 20.8236L77.6031 21.6334C77.2808 21.2496 76.874 20.9387 76.4116 20.723C75.9733 20.515 75.4912 20.4057 75.002 20.4035C74.377 20.3972 73.7641 20.5692 73.2417 20.8978C72.7161 21.228 72.2867 21.6806 71.9937 22.2131C71.6859 22.7632 71.5278 23.3786 71.534 24.0026C71.5277 24.626 71.6878 25.2406 71.999 25.7883C72.2954 26.3174 72.7264 26.7663 73.2522 27.0936C73.7725 27.418 74.3812 27.5882 75.002 27.5828C75.5076 27.585 76.0061 27.4685 76.4536 27.2432C76.8948 27.0253 77.2861 26.7252 77.6044 26.3605Z"
        fill={theme.colors.primary.dark}
      />
      <path
        d="M81.0947 28.9997V14H82.8393V28.9997H81.0947Z"
        fill={theme.colors.primary.dark}
      />
      <path
        d="M90.1196 29.2603C89.1909 29.2691 88.279 29.0225 87.4923 28.5498C86.6894 28.0672 86.0292 27.3955 85.5747 26.5989C85.1203 25.8024 84.8868 24.9076 84.8965 24.0001C84.8849 22.6129 85.4332 21.2749 86.4269 20.2652C86.8992 19.7864 87.4657 19.4017 88.094 19.1335C88.7306 18.8591 89.4215 18.7193 90.1196 18.7235C90.8689 18.7152 91.6103 18.8709 92.2872 19.1787C92.9396 19.4758 93.5169 19.9048 93.9792 20.4363C94.4559 20.9926 94.8131 21.6336 95.0301 22.3226C95.268 23.064 95.3562 23.842 95.2902 24.615H86.7672C86.8411 25.1537 87.0409 25.6695 87.3518 26.1241C87.6501 26.5605 88.0533 26.9222 88.5275 27.1791C89.0133 27.4417 89.5618 27.5795 90.1196 27.579C90.7241 27.5868 91.3178 27.425 91.8274 27.1137C92.3442 26.7955 92.7686 26.3573 93.0609 25.8399L94.8462 26.2398C94.4368 27.117 93.7827 27.8693 92.9558 28.414C92.1268 28.9744 91.1344 29.2705 90.1196 29.2603ZM86.7146 23.2909H93.526C93.4739 22.742 93.2798 22.2143 92.9611 21.7554C92.6484 21.2994 92.2304 20.9182 91.7394 20.6413C91.2499 20.3679 90.6937 20.2231 90.127 20.2213C89.5602 20.2195 89.0031 20.3609 88.5117 20.6312C88.0243 20.9033 87.6107 21.2816 87.3045 21.7353C86.9859 22.2037 86.7841 22.7359 86.7146 23.2909Z"
        fill={theme.colors.primary.dark}
      />
      <path
        d="M98.8305 34H97.0754V19.0002H98.8305V20.7167C99.1771 20.1388 99.6648 19.65 100.252 19.292C100.885 18.9178 101.618 18.7285 102.362 18.7474C103.095 18.7421 103.823 18.8817 104.498 19.1574C105.152 19.423 105.746 19.8073 106.247 20.2892C106.748 20.7714 107.149 21.3397 107.43 21.9642C107.717 22.6087 107.863 23.3031 107.858 24.0039C107.864 24.7067 107.72 25.4033 107.435 26.0512C107.156 26.6794 106.755 27.2509 106.253 27.735C105.751 28.2172 105.157 28.6016 104.503 28.8668C103.828 29.1419 103.101 29.2814 102.367 29.2767C101.623 29.2951 100.89 29.1059 100.257 28.7322C99.6697 28.374 99.1819 27.8847 98.8357 27.3062L98.8305 34ZM102.341 20.3709C101.711 20.3585 101.091 20.5291 100.565 20.8601C100.054 21.1866 99.6454 21.6394 99.3822 22.1704C99.1103 22.7455 98.9697 23.3695 98.9697 24.0008C98.9697 24.632 99.1103 25.256 99.3822 25.8311C99.6405 26.3641 100.045 26.82 100.553 27.1502C101.08 27.4838 101.702 27.6549 102.334 27.6407C102.989 27.6462 103.632 27.4782 104.193 27.1553C104.754 26.8356 105.221 26.3852 105.551 25.8462C105.885 25.2877 106.062 24.6557 106.064 24.0122C106.065 23.3686 105.892 22.7357 105.562 22.1755C105.237 21.6343 104.773 21.1815 104.214 20.8601C103.649 20.5344 103 20.3651 102.341 20.3709Z"
        fill={theme.colors.primary.dark}
      />
      <path
        d="M110.551 17.1705C110.424 17.1731 110.298 17.1513 110.179 17.1067C110.061 17.062 109.953 16.9952 109.862 16.9102C109.772 16.825 109.702 16.7237 109.653 16.6123C109.605 16.5008 109.58 16.3814 109.58 16.2607C109.58 16.1401 109.605 16.0206 109.653 15.9091C109.702 15.7977 109.772 15.6965 109.862 15.6112C110.049 15.4439 110.295 15.3508 110.551 15.3508C110.807 15.3508 111.054 15.4439 111.241 15.6112C111.33 15.6963 111.401 15.7975 111.45 15.9089C111.498 16.0204 111.523 16.14 111.523 16.2607C111.523 16.3815 111.498 16.501 111.45 16.6125C111.401 16.7239 111.33 16.8251 111.241 16.9102C111.15 16.9954 111.042 17.0624 110.924 17.1071C110.805 17.1518 110.679 17.1733 110.551 17.1705ZM109.669 19.0002H111.424V29.0001H109.669V19.0002Z"
        fill={theme.colors.primary.dark}
      />
      <path
        d="M122.245 18.9999H124V28.9997H122.234L122.171 27.3096C121.852 27.8816 121.387 28.3669 120.82 28.7206C120.209 29.0898 119.498 29.2775 118.777 29.26C118.034 29.266 117.298 29.1247 116.615 28.8451C115.953 28.5729 115.352 28.1829 114.844 27.6957C114.335 27.2095 113.929 26.6339 113.648 26.0005C113.365 25.3681 113.215 24.6883 113.206 24.0003C113.198 23.3122 113.331 22.6292 113.599 21.9906C113.867 21.352 114.264 20.7702 114.767 20.2786C115.27 19.787 115.869 19.3952 116.53 19.1256C117.194 18.8536 117.91 18.7158 118.632 18.7207C119.398 18.7046 120.154 18.8969 120.81 19.2753C121.421 19.636 121.934 20.1298 122.308 20.7164L122.245 18.9999ZM118.819 27.6328C119.458 27.6481 120.086 27.4723 120.616 27.1298C121.119 26.7934 121.515 26.3297 121.759 25.7905C122.025 25.205 122.145 24.5683 122.11 23.9306C122.091 23.2993 121.923 22.6802 121.618 22.1198C121.336 21.6012 120.919 21.1611 120.407 20.8422C119.877 20.5194 119.259 20.354 118.631 20.3668C117.967 20.3609 117.315 20.5353 116.75 20.8698C116.182 21.2039 115.72 21.6787 115.411 22.2438C115.102 22.809 114.959 23.4435 114.996 24.0803C115.023 24.716 115.224 25.3338 115.578 25.8723C115.922 26.4027 116.397 26.8441 116.961 27.1574C117.524 27.4734 118.166 27.6376 118.819 27.6328Z"
        fill={theme.colors.primary.dark}
      />
    </svg>
  );
};
