import styled from 'styled-components';
import { FC } from 'react';
import { Checkbox } from 'ui/Checkbox';

interface Props {
  text: string;
  type?: string;
  checked: boolean;
  onChange?: () => void;
}

export const AuthorisationCheckbox: FC<Props> = ({
  text,
  onChange,
  checked,
}) => {
  return (
    <Container>
      <Checkbox checked={checked} onChange={onChange} />
      <Label>{text}</Label>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 8px;
`;

const Label = styled.div``;
