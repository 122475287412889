import { useMemo } from 'react';

import { getQIProgressByCompositionUnits } from '../domain/useCases/getQIProgressByCompositionUnits';
import { useGetQIProgressBySession } from './useGetQIProgressBySession';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';

export const useGetQIProgressByCompositionUnits = () => {
  const progressBySessionQuery = useGetQIProgressBySession();
  const { isUserPremium } = useIsUserPremium();

  const progressByCompositionUnit = useMemo(() => {
    return getQIProgressByCompositionUnits(
      progressBySessionQuery.data,
      isUserPremium
    );
  }, [isUserPremium, progressBySessionQuery.data]);

  return {
    ...progressBySessionQuery,
    data: progressByCompositionUnit,
  };
};
