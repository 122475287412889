import { forwardRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import parse from 'html-react-parser';

import { Props as SingleAnswerType } from './SingleAnswer';
import { ValidateButton } from '../ui/ValidateButton';
import { ValidationModal } from '../ValidationModal/ValidationModal';
import { StyledMathJax } from 'ui/MathJax';
import { NoAnswerModal } from '../NoAnswerModal/NoAnswerModal';
import { NewSingleAnswer } from './NewSingleAnswer';
import { shouldShowBanner } from 'pages/new_training/helpers';
import { LimitBanner } from '../ui/LimitBanner';
import { toast } from 'react-toastify';
import { useSaveQuestionQRPL } from 'pages/new_training/hooks/saveHooks/useSaveQRPL';

interface Props {
  question: string;
  questionId: number;
  questionNumber: number;
  answers: SingleAnswerType['answers'];
  saved: boolean;
  show: boolean;
  onSave(): void;
  isQI: boolean;
  onModification?(): void;
  anonymous: boolean;
  collection: string | undefined;
  answersLimit: number;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
}

export const QRPL = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [answers, setAnswers] = useState<Props['answers']>(props.answers);
  const answersRef = useRef<Props['answers']>(props.answers);

  const [validationModal, setValidationModal] = useState<boolean>(false);
  const [noAnswerModal, setNoAnswerModal] = useState<boolean>(false);
  const [showBanner, setShowBanner] = useState<boolean>(false);

  const saveQuestion = useSaveQuestionQRPL(props.onSave);

  const shouldDisplayLimitBanner = shouldShowBanner({
    answers: answers.map((i) => i.answer),
    limit: props.answersLimit,
  });

  const handleSaveQuestion = async () => {
    props.setIsLoading(true);
    const params = {
      id: props.questionId,
      answers: answers.map((el) => ({ id: el.id, answer: el.answer })),
    };
    try {
      await saveQuestion(params);
      answersRef.current = answers;
    } catch (err) {
      toast.error(
        'Erreur lors de la sauvegarde de la question, veuillez réessayer'
      );
    }
    props.setIsLoading(false);
  };

  useEffect(() => {
    if (props.answers !== answers) {
      setAnswers(props.answers);
    }
    // eslint-disable-next-line
  }, [props.answers]);

  useEffect(() => {
    if (props.show && !props.isQI) {
      titleRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    // eslint-disable-next-line
  }, [props.show]);

  useEffect(() => {
    if (shouldDisplayLimitBanner) {
      setShowBanner(true);
    } else {
      setShowBanner(false);
    }
    // eslint-disable-next-line
  }, [shouldDisplayLimitBanner]);

  const handleValidationClick = () => {
    if (props.isQI) {
      handleSaveQuestion();
      return;
    }

    if (answers.every((el) => el.answer !== true)) {
      setNoAnswerModal(true);
    } else {
      setValidationModal(true);
    }
  };

  const handleChange = (index: number) => {
    const newAnswers = [...answers].map((el) => {
      el.answer = !!el.answer; //if we don't do this, the answer is null, ?wtf
      return el;
    });

    const hasReachedLimit =
      newAnswers.filter((el) => el.answer).length >= props.answersLimit;

    if (hasReachedLimit) {
      newAnswers[index].answer = false;
      setAnswers(newAnswers);

      return;
    }

    newAnswers[index].answer = !newAnswers[index].answer;
    setAnswers(newAnswers);
  };

  useEffect(() => {
    if (answersRef.current !== answers) {
      // TODO: check if the isQI condition break , ?
      // props.isQI && props.onModification && props.onModification();
      props.onModification && props.onModification();
    }
    // eslint-disable-next-line
  }, [answers]);

  return (
    <Container ref={ref} show={props.show}>
      <Title ref={titleRef}>
        {/* TODO: remove */}
        {/* {(props.anonymous
          ? ''
          : props.isQI
          ? props.session + ' ' + props.year + ' '
          : '') +
          (props.isQI
            ? 'QI ' + props.questionNumber
            : 'Question ' + props.questionNumber) +
          ' - QRPL'} */}

        <TitleLeftSide>
          Question {props.questionNumber} - Question à nombre de réponses
          précisé XL
        </TitleLeftSide>
        <TitleRightSide>
          {answers.length} propositions de réponses / {props.answersLimit}{' '}
          réponse(s) attendue(s)
        </TitleRightSide>
      </Title>
      <Statement>
        <StyledMathJax inline>{parse(props.question)}</StyledMathJax>
      </Statement>

      <LimitBanner show={showBanner} />

      <NewSingleAnswer
        answers={answers}
        handleChange={handleChange}
        disabled={!props.isQI && props.saved}
      />
      <ValidateButton
        show={props.isQI || !props.saved}
        onClick={handleValidationClick}
        isLoading={props.isLoading}
      />
      <NoAnswerModal
        isOpen={noAnswerModal}
        onCancel={() => setNoAnswerModal(false)}
        onAccept={() => {
          setNoAnswerModal(false);
          setValidationModal(true);
        }}
        title="Réponse définitive"
        // TODO: remove prefix
        text={
          `<b style="color:#F3002C; font-family:freesans;">Attention, vous n'avez pas répondu à la question</b>` +
          `<br /> En cliquant sur le bouton OK vous confirmez votre choix.` +
          `<br /><b style="color:#F3002C; font-family:freesans;">Votre réponse ne sera plus modifiable</b>`
        }
        confirmationButtonColor="#192942"
        confirmationButtonHoverColor="#56595e"
      />
      <ValidationModal
        isOpen={validationModal}
        onCancel={() => setValidationModal(false)}
        onAccept={() => {
          setValidationModal(false);
          handleSaveQuestion();
        }}
        title="Réponse définitive"
        text="En cliquant sur le bouton OK vous confirmez vos réponses. Elles ne seront plus modifiables."
      />
    </Container>
  );
});

const TitleLeftSide = styled.span`
  font-family: 'FreeSans';
  font-size: 16px;
  color: #222529;
`;
const TitleRightSide = styled.span`
  font-family: 'FreeSans';
  font-weight: 100;
  font-size: 14px;
  color: #383b3f;
`;

const Container = styled.div<{ show?: boolean }>`
  margin-bottom: 50px;
  display: ${({ show }) => (show ? 'block' : 'none')};
`;

const Title = styled.div`
  display: flex;
  justify-content: space-between;

  font-style: normal;
  font-weight: bold;

  color: #212529;
`;

const Statement = styled.label`
  margin-top: 10px;
  margin-bottom: 10px;
  display: block;
  font-family: 'FreeSans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  sup {
    font-size: 0.83em;
  }

  p,
  span {
    font-family: 'FreeSans' !important;
  }

  img {
    max-width: 60%;
    height: auto;
  }
`;
