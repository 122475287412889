import { atomFamily } from 'jotai/utils';

import { atom } from 'jotai';
export const panelRightAtom = atom(true);
export const sidebarAtom = atom(true);
export const greyScaleAtom = atom(false);

export const isQZPModalOpenAtom = atomFamily(
  (questionId: number) => atom<boolean>(false),
  (a, b) => a === b
);
