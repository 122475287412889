import styled from 'styled-components/macro';
import { device } from 'helpers/css/responsive';
import { useSubscriptionInfo } from '../useSubscription';
import { routes } from 'routing/constants';
import { Link } from 'react-router-dom';
import { formatDate } from 'helpers/dates/utils';

export const SubscriptionInfo = () => {
  const { userPayments, userSubscription } = useSubscriptionInfo();

  if (userPayments === undefined || userSubscription === undefined)
    return <div>Loading ...</div>;

  const SubscriptionInfo = () => {
    if (userPayments && userPayments.length > 0 && userPayments[0].payment_id) {
      return (
        <>
          <Info>
            <Title>
              {userSubscription?.product?.label ??
                `Nous n'avons pas trouvé la référence de votre abonnement`}
            </Title>
            {userSubscription?.product?.end_subscription
              ? `Jusqu'au ${formatDate(
                  userSubscription?.product?.end_subscription
                )}`
              : ''}
          </Info>
          <Link to={routes.SUBSCRIBE}>Renouveler</Link>
        </>
      );
    } else {
      return (
        <>
          <Info>Vous n'avez aucun abonnement en cours</Info>
          <Link to={routes.SUBSCRIBE}>Découvrir nos abonnements</Link>
        </>
      );
    }
  };

  return (
    <MyInfosSection>
      <Section>
        <h2>Mon abonnement</h2>
        <SubscriptionContainer>
          <SubscriptionInfo />
        </SubscriptionContainer>
      </Section>
      {userPayments.length > 0 && (
        <Section>
          <h2>Factures</h2>
          {userPayments.map((userPayment) => (
            <SubscriptionContainer key={userPayment.payment_id}>
              <Info>
                <Title>{formatDate(userPayment.date_creation)}</Title>
                {(userPayment.amount / 100).toFixed(2)}€
              </Info>
              {userPayment.invoice_url && (
                <ReceiptButton href={userPayment.invoice_url} target={'_blank'}>
                  Télécharger
                </ReceiptButton>
              )}
            </SubscriptionContainer>
          ))}
        </Section>
      )}
    </MyInfosSection>
  );
};

const MyInfosSection = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media ${device.desktop} {
    border-radius: 16px;
  }
`;

const Section = styled.div`
  flex-basis: 100%;
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${device.desktop} {
    padding: 32px;
    border-radius: 16px;
    gap: 16px;
    border: 2px solid ${({ theme }) => theme.colors.primary.dark};
  }
`;

const SubscriptionContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;

  @media ${device.desktop} {
    font-size: 20px;
    line-height: 24px;
  }
`;

const Title = styled.div`
  font-weight: 600;
`;

const ReceiptButton = styled.a``;
