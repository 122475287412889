import { FC, useMemo } from 'react';
import styled from 'styled-components/macro';

import { CorrectionQuestion } from 'types/correction.type';
import { Proposition } from './Proposition';

interface Props {
  questionData: CorrectionQuestion;
}

export const QCMCorrectionBlock: FC<Props> = ({ questionData }) => {
  const hasUserAnswered = useMemo(
    () =>
      questionData.correction_items_qcm.some(
        (itemCorrection) => itemCorrection.answer === true
      ),
    [questionData.correction_items_qcm]
  );

  return (
    <Container id={`corrections-questions-${questionData.question.num}`}>
      {questionData.correction_items_qcm
        .sort((i1, i2) => i1.question_item.num - i2.question_item.num)
        .map((itemCorrection) => (
          <Proposition
            itemCorrection={itemCorrection}
            hasUserAnswered={hasUserAnswered}
            key={itemCorrection.id}
          />
        ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
