import { FC, useMemo } from 'react';
import { Tr, Td } from 'ui/TableElements';
import { ProgressBarStat } from 'components/StatsElements/ProgressBarStats';
import styled, { useTheme } from 'styled-components';
import { FQDecksInfo } from 'http/flash-questions/decks/types';
import { DailyCards } from './DailyCards';
import { LeftChevron } from 'ui/icons/LeftChevron';
import { Bin } from 'ui/icons/Bin';
import { EditIcon } from 'ui/icons/Edit';
import { BinHover } from 'ui/icons/BinHover';
import { DeleteModal } from './DeleteModal';
import useBoolean from 'hooks/useBoolean';
import { useDispatch } from 'react-redux';
import { FQMode } from 'pages/flash-questions/types';
import { setChosenDeckInfos, setMode } from 'store/slices/fqCurrentSlice';
import { getDeck } from 'store/actions/fqCurrentActions';

interface Props {
  rowData: FQDecksInfo;
  index: number;
  isGeneral?: boolean;
}

export const CUSTOM_BLUE_COLOR = '#27549c';

export const FQDecksRow: FC<Props> = ({ rowData, index, isGeneral }) => {
  const { toStudyCards, newCards } = rowData;
  const total = toStudyCards + newCards;
  const alreadyDone = total - newCards;
  const percentage = total > 0 ? (alreadyDone / total) * 100 : 0;
  const toFixedSuffix = percentage === 0 || percentage === 100 ? 0 : 1;

  const isOdd = useMemo(() => index % 2 === 1, [index]);
  const theme = useTheme();
  const deleteModal = useBoolean();
  const dispatch = useDispatch();

  const handleRowClick = () => {
    dispatch(setMode({ mode: FQMode.DECKLAUNCH }));
    dispatch(setChosenDeckInfos({ infos: rowData }));
  };

  const handleEditClick = () => {
    dispatch(setMode({ mode: FQMode.DECKEDIT }));
    dispatch(setChosenDeckInfos({ infos: rowData }));
    dispatch(getDeck());
  };

  return (
    <>
      <DeleteModal
        deckName={rowData.name}
        isOpen={deleteModal.value}
        close={deleteModal.setFalse}
      />
      <Tr isOdd={isOdd}>
        <CustomTd>
          <Gotodeck
            style={isGeneral ? { fontWeight: 600 } : {}}
            onClick={handleRowClick}
          >
            {rowData.name} <StyledLeftChevron />
          </Gotodeck>
        </CustomTd>
        <Td>
          <ProgressBarContainer>
            <StyledProgressBar
              fillingColor={CUSTOM_BLUE_COLOR}
              percentage={percentage}
            />
            {/* TODO rm comments */}
            <ProgressionText>
              {`${alreadyDone}/${total}`}
              {' - '}
              {`${percentage.toFixed(toFixedSuffix)} %`}
            </ProgressionText>
          </ProgressBarContainer>
        </Td>
        <Td />
        <Td>
          <DailyCards data={rowData.dailyCards} />
        </Td>
        <Td>
          <Icons>
            {!isGeneral && (
              <BinContainer onClick={deleteModal.setTrue}>
                <SBin color={theme.colors.primary.dark} />
                <SBinHover color={theme.colors.primary.dark} id="hoverBin" />
              </BinContainer>
            )}
            {!isGeneral && (
              <EditContainer onClick={handleEditClick}>
                <StyledEditIcon />
                <SBinHover color={theme.colors.primary.dark} id="hoverBin" />
              </EditContainer>
            )}
          </Icons>
        </Td>
      </Tr>
    </>
  );
};

const Icons = styled.div`
  display: flex;
  justify-content: start;
`;
const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
`;

const StyledProgressBar = styled(ProgressBarStat)`
  min-width: 10em;
  display: inline-block;
`;

const ProgressionText = styled.span`
  width: 8em;
`;

const StyledLeftChevron = styled(LeftChevron)`
  width: 6px;
  height: auto;
  transform: rotate(180deg);
  margin-left: 0.5em;
`;

const Gotodeck = styled.div`
  cursor: pointer;
  display: inline-block;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -3px;
    left: 0;
    background-color: ${({ theme }) => theme.colors.primary.dark};
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
    border-radius: 3px;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
`;

const BinContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

const StyledEditIcon = styled(EditIcon)`
  width: 1.2em;
  height: auto;
`;
const EditContainer = styled.div`
  cursor: pointer;
  margin-left: 10px;

  &:hover {
    ${StyledEditIcon} {
      fill: ${({ theme }) => theme.colors.primary.dark};
    }
  }
`;

const SBin = styled(Bin)`
  width: 1.2em;
  height: auto;
`;

const SBinHover = styled(BinHover)`
  width: 1.2em;
  height: auto;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  z-index: 99;

  &:hover {
    opacity: 1;
  }
`;

const CustomTd = styled(Td)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 4.5em;
`;
