import styled from 'styled-components/macro';

import { device } from 'helpers/css/responsive';
import { Button } from 'ui/Button';

export const StartButton = styled(Button)`
  --shadow-color: 0deg 0% 63%;
  --shadow-elevation-medium: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36),
    0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36),
    2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
    5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36);
  box-shadow: var(--shadow-elevation-medium);

  position: sticky;
  bottom: 8px;

  @media ${device.desktop} {
    bottom: 32px;
  }
`;
