import { CorrectionTaskResponse } from 'types/correction.type';

export const corectionMocks: CorrectionTaskResponse = {
  id: 1307860,
  finished: true,
  timer: {
    total_time: 3600,
    remaining_time: 3596.492318,
    paused: true,
  },
  grade: 0,
  total_coefficient: 0,
  rank_A_grade: 0,
  rank_A_coefficient: 0,
  correction_groups: [
    {
      id: 1736448,
      group: {
        description: '',
        abstract: '',
        article: '',
        composition_unit: undefined,
        collection: 'ECNi 2016',
        type: 'DP',
        num: 1,
        specialty1: 'MCV',
        specialty2: null,
        statement:
          'Un homme de 20 ans, étudiant, vous consulte en médecine générale pour obtenir un certificat de non contre-indication à la pratique du tennis. Il a comme antécédent des crises de migraines traitées par AINS. Il participe à des tournois à un bon niveau et fait 4 heures de sport par semaine. Il fume une dizaine de cigarettes par jour depuis l’âge de 18 ans. Il pèse 81 kg pour 180 cm. A l’interrogatoire, il signale que depuis 5 ans parfois le cœur « s’emballe », rarement au delà d’une minute, sans lien avec l’effort. Il sent alors un poids sur la poitrine qui « serre la gorge », il a du mal à tenir debout… il doit impérativement s’asseoir car « la tête tourne ».',
        thumbnail:
          "Palpitations découvertes dans le cadre d'une consultation pour certificat de non contre-indication à la pratique sportive d'un homme de 20 ans. Exploration aboutissant au diagnostic de communication inter-atriale qui sera fermée. Diagnostic et prise en charge d'une tachycardie jonctionnelle paroxystique, puis d'une HTA à distance.",
        analysis:
          "Dossier de cardiologie pure, assez déroutant de prime abord en raison du début assez atypique avec la communication inter-atriale mais qui reste assez \"basique\", c'est-à-dire faisant la part belle à la sémiologie clinique, l'interprétation d'ECG et des notions générales de thérapeutique en cardiologie.<br />Il permet de confirmer que chaque chapitre est important dans cette matière, des souffles de l'enfant aux palpitations en passant par les incontournables que sont l'HTA et la tachycardie jonctionnelle.<br /><br />Moyenne et médiane nationales étaient à environ 292/420 (13,95/20) avec des notes essentiellement réparties entre 12 et 16/20 (252 à 336 points).",
        median: 10.6,
        denom: 15,
        sources: [],
        books: ['MCA'],
      },
      custom_qi: false,
      num: 2,
      finished: true,
      correction_questions: [
        {
          id: 22035289,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRU',
            num: 1,
            statement:
              'D’après ces données, quel(s) est (sont) le(les) qualificatif(s) approprié(s) pour décrire la symptomatologie ?',
            item: '342',
            knowledge: {
              knowledge: 'N001N01',
              rank: 'A',
              type: 'Définition',
              label: 'Connaître la définition de la relation médecin-malade',
              item: {
                item: 1,
                short_title: 'Relation médecin-malade',
                title:
                  "La relation médecin-malade dans le cadre du colloque singulier ou au sein d'une équipe, le cas échéant pluriprofessionnelle. La communication avec le patient et son entourage. L'annonce d'une maladie grave ou létale ou d'un dommage associé aux soins. La formation du patient. La personnalisation de la prise en charge médicale.",
                tag_and_title:
                  "N1: La relation médecin-malade dans le cadre du colloque singulier ou au sein d'une équipe, le cas échéant pluriprofessionnelle. La communication avec le patient et son entourage. L'annonce d'une maladie grave ou létale ou d'un dommage associé aux soins. La formation du patient. La personnalisation de la prise en charge médicale.",
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['SEM'],
            correction:
              "Comme souvent, un dossier de cardiologie débute par un énoncé riche en sémiologie clinique qu'il faudra interpréter. Il convient de parfaitement maîtriser ces notions de sémiologie de base, et leurs présentations moins typiques.",
            coefficient: 1,
            lessons: [],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 1,
          grade: 1,
          correction_items_qcm: [
            {
              id: 106911981,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement: 'palpitations',
                state: 'V',
                correction:
                  "Il s'agit d'une sensation subjective d'un cœur qui bat trop vite et/ou trop fort, de façon régulière ou non. Le patient \"signale que depuis 5 ans parfois, le cœur qui s'emballe\", ce qui colle.",
              },
              answer: false,
            },
            {
              id: 106911984,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement: 'dyspnée',
                state: 'F',
                correction:
                  "Aucune difficulté respiratoire n'est décrite dans l'énoncé.<br />Le piège a probablement été fait avec la mention du « poids sur la poitrine qui \"serre la gorge\" », qui désigne une oppression thoracique accompagnant des palpitations, ou une douleur angineuse en raison d'une tachycardie trop rapide (angor fonctionnel).",
              },
              answer: false,
            },
            {
              id: 106911982,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement: 'lipothymie',
                state: 'V',
                correction:
                  "Le patient doit impérativement s'asseoir car \"la tête tourne\" d'après l'énoncé, ce qui colle avec des lipothymies. Celles-ci sont fréquentes dans le cadre de palpitations, et elles surviennent en cas de diminution transitoire de la perfusion cérébrale.",
              },
              answer: false,
            },
            {
              id: 106911983,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement: 'syncope',
                state: 'F',
                correction:
                  "Une syncope désigne une perte de connaissance brève survenant en raison d'une diminution transitoire de la perfusion cérébrale. Aucune perte de connaissance n'a été décrite dans l'énoncé.",
              },
              answer: false,
            },
            {
              id: 106911985,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement: 'vertige vrai',
                state: 'F',
                correction:
                  "Dans ce contexte, il n'y a pas d'argument suffisant pour parler de vertige dit \"vrai\". On parle plutôt de \"faux\" vertige en raison de la symptomatologie évocatrice d'une cause cardiaque potentiellement responsable de la lipothymie décrite (impression de tête qui tourne compatible).<br />On notera que les vertiges peuvent donner l'impression d'avoir la tête qui tourne ou de voir l'environnement tourner autour de soi.",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035290,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 2,
            statement:
              'A l’examen clinique vous notez un souffle cardiaque systolique avec dédoublement fixe du B2 au foyer pulmonaire.<br />Parmi les informations suivantes, laquelle (lesquelles) est (sont) en faveur du caractère innocent du souffle ?',
            item: '238',
            knowledge: {
              knowledge: 'N238N02',
              rank: 'A',
              type: 'Définition',
              label:
                "Connaître la définition d'un souffle cardiaque organique et non organique",
              item: {
                item: 238,
                short_title: 'Souffles cardiaques chez l’enfant',
                title: "Souffle cardiaque chez l'enfant.",
                tag_and_title: "N238: Souffle cardiaque chez l'enfant.",
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['SEM'],
            correction:
              "La sémiologie des souffles cardiaques est un incontournable aux ECNi puisque chaque session comporte une voire deux questions à ce sujet. Celle-ci est moins typique puisqu'elle porte sur les caractéristiques d'un souffle innocent, que l'on retrouve souvent chez les enfants.",

            coefficient: 1,
            lessons: ['souffle_benin', 'communication_interatriale'],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 2,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106911987,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement: 'l’irradiation dans l’aisselle',
                state: 'F',
                correction:
                  "L'irradiation d'un souffle évoque son organicité car un souffle innocent n'irradie que très rarement. L'irradiation d'un souffle vers l'aisselle évoque une insuffisance mitrale ou une coarctation de l'aorte.",
              },
              answer: false,
            },
            {
              id: 106911986,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement: 'le caractère systolo-diastolique',
                state: 'F',
                correction:
                  "Un souffle audible en diastole évoque une organicité. En effet, un souffle innocent se produit lors de l'expulsion du sang et est directement lié au débit cardiaque. Le caractère diastolique signifierait la présence d'une fuite, mais certains souffles innocents peuvent être systolo-diastoliques (rare, toujours évoquer une organicité avant).",
              },
              answer: false,
            },
            {
              id: 106911988,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement: 'la variabilité du souffle dans le temps',
                state: 'V',
                correction:
                  'Le souffle varie dans le temps car le débit sanguin cardiaque est variable.',
              },
              answer: false,
            },
            {
              id: 106911989,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement:
                  'la variation du souffle avec la position du patient',
                state: 'V',
                correction:
                  'Un souffle bénin varie avec la position du patient, et il disparaît notamment en orthostatisme.',
              },
              answer: false,
            },
            {
              id: 106911990,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement: 'le dédoublement fixe de B2 avec la respiration',
                state: 'F',
                correction:
                  "Le dédoublement du B2 témoigne d'un retard de la fermeture d'une des deux valves pulmonaire ou aortique par rapport à l'autre. Il est physiologique de retrouver un dédoublement variable à l'inspiration, surtout chez l'enfant, car le retour veineux est augmenté ce qui augmente le remplissage du VD, allonge le temps de systole et retarde la fermeture de la valve pulmonaire.<br />Le dédoublement fixé est pathologique. Il évoque deux pathologies chez un jeune homme :<br />• Une <strong>communication inter-atriale</strong><br />• Un <strong>bloc de branche droit</strong>, puisqu'il y aurait alors un retard de fermeture de la valve pulmonaire par retard de contraction du VD aboutissant à un retard à son éjection.",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035291,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 3,
            statement:
              'Parmi les propositions suivantes concernant la conduite &agrave; tenir, laquelle (lesquelles) retenez-vous &agrave; ce stade de la prise en charge ?',
            item: '256',
            knowledge: {
              knowledge: 'N256N03',
              rank: 'B',
              type: 'Prise en charge',
              label:
                "Connaître les modalités de l'examen médical d'absence de contre-indication à la pratique du sport chez l'adulte",
              item: {
                item: 256,
                short_title: 'Aptitude au sport et nutrition du sportif',
                title:
                  "Aptitude au sport chez l'adulte et l'enfant ; besoins nutritionnels chez le sportif (voir item 80).",
                tag_and_title:
                  "N256: Aptitude au sport chez l'adulte et l'enfant ; besoins nutritionnels chez le sportif (voir item 80).",
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: [],
            correction:
              "Il fallait r&eacute;pondre &agrave; cette question en ayant trois choses &agrave; l'esprit : les acc&egrave;s de palpitations r&eacute;currents ; la pr&eacute;sence d'un souffle, systolique en l'occurrence, chez un jeune adulte qui est suspecte ; et enfin, la pr&eacute;sence d'un d&eacute;doublement <strong>fixe</strong> du B2 qui sugg&egrave;re la pr&eacute;sence d'une cardiopathie sous jacente.",
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            coefficient: 1,
            lessons: [],
            similar_questions: [],

            sources: [],
            books: [],
          },
          saved: true,
          num: 3,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106911992,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement:
                  'vous refusez de signer le certificat de non contre-indication au sport',
                state: 'V',
                correction:
                  "Il n'est pas possible de signer le certificat d'absence de contre-indication &agrave; la pratique sportive (CACI) &agrave; ce stade des explorations puisqu'il faudra documenter les palpitations et les anomalies auscultatoires (ECG, ETT).",
              },
              answer: false,
            },
            {
              id: 106911995,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement: "vous prescrivez une &eacute;preuve d'effort",
                state: 'F',
                correction:
                  "L'&eacute;preuve d'effort, qui d&eacute;signe la r&eacute;alisation d'un ECG sur tapis roulant, n'est pas indiqu&eacute;e dans ce contexte puisqu'elle permet de rechercher une isch&eacute;mie myocardique (symptomatologie clinique ou &eacute;lectrique survenant &agrave; l'effort), ou un r&eacute;tr&eacute;cissement aortique serr&eacute; faussement asymptomatique. On notera qu'elle est indiqu&eacute;e pour le CACI d'un sujet de 35 ans et plus (cf. r&eacute;f&eacute;rentiel de cardiologie). Avant, seul l'ECG est recommand&eacute; (cf. Soci&eacute;t&eacute; Fran&ccedil;aise de Cardiologie) tous les 3 ans de 12 &agrave; 20 ans et tous les 5 ans de 20 &agrave; 35 ans.",
              },
              answer: false,
            },
            {
              id: 106911991,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement: 'vous prescrivez un arr&ecirc;t de travail',
                state: 'F',
                correction:
                  "D'une part, le patient est &eacute;tudiant. D'autre part, un arr&ecirc;t de travail n'aurait pas &eacute;t&eacute; syst&eacute;matique selon celui-ci puisqu'il d&eacute;pend de la pr&eacute;sence d'efforts physiques ou non, le temps des explorations (ECG, ETT)",
              },
              answer: false,
            },
            {
              id: 106911993,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement: 'vous l&rsquo;adressez &agrave; un cardiologue',
                state: 'V',
                correction:
                  'Il faut imp&eacute;rativement un bilan sp&eacute;cialis&eacute; pour le diagnostic &eacute;tiologique des palpitations et des anomalies auscultatoires.',
              },
              answer: false,
            },
            {
              id: 106911994,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement: 'vous l&rsquo;hospitalisez',
                state: 'F',
                correction:
                  "L'hospitalisation est inutile dans ce contexte. Il n'y a pas de signe de gravit&eacute; ou de suspicion d'un trouble du rythme ou de la conduction grave. Les palpitations &eacute;voluent depuis 5 ans d&eacute;j&agrave; en plus. Les explorations cardiologiques peuvent tout &agrave; fait s'envisager en ambulatoire (ECG, ETT).",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035292,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 4,
            statement:
              'Devant un souffle systolique au bord gauche du sternum avec d&eacute;doublement fixe du B2 vous suspectez :',
            item: '238',
            knowledge: {
              knowledge: 'N238N04',
              rank: 'B',
              type: 'Étiologies',
              label:
                "Connaître l'orientation étiologique des souffles cardiaques",
              item: {
                item: 238,
                short_title: 'Souffles cardiaques chez l’enfant',
                title: "Souffle cardiaque chez l'enfant.",
                tag_and_title: "N238: Souffle cardiaque chez l'enfant.",
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['SEM'],
            correction:
              "Question difficile et assez pr&eacute;cise. Il existe deux fa&ccedil;on de r&eacute;pondre &agrave; cette question :<br />\r\n<ul>\r\n<li>Soit on r&eacute;pond par argument de fr&eacute;quence et de plausibilit&eacute; devant des anomalies auscultatoires r&eacute;v&eacute;l&eacute;es seulement &agrave; 20 ans chez un individu en bonne sant&eacute; et sportif et l'on coche la communication inter-atriale.</li>\r\n<li>Soit, de fa&ccedil;on plus s&ucirc;r mais plus compliqu&eacute;e, on conna&icirc;t la s&eacute;miologie clinique des cardiopathies cong&eacute;nitales et r&eacute;pond sans coup de poker</li>\r\n</ul>",
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            coefficient: 1,
            lessons: [
              'drapeau_rouge_souffle_enfant',
              'communication_interventriculaire',
              'communication_interatriale',
              'coarctation_aorte',
              'persistance_canal_arteriel',
            ],
            similar_questions: [],

            sources: [],
            books: [],
          },
          saved: true,
          num: 4,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106911998,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement: 'une communication interventriculaire (CIV)',
                state: 'F',
                correction:
                  "Il s'agit de la plus fr&eacute;quente des cardiopathies cong&eacute;nitales (20-25%). Elle est r&eacute;v&eacute;l&eacute;e dans la petite enfance ou &agrave; la naissance dans la grande majorit&eacute; des cas. Le souffle associ&eacute; est holosystolique, maximal en parasternal gauche, irradiant en rayon de roue.<br />On retrouvera &eacute;galement un bruit B1 fort &agrave; la pointe, et un claquement du B2 en cas d'HTP.",
              },
              answer: false,
            },
            {
              id: 106911996,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement: 'une persistance du canal art&eacute;riel',
                state: 'F',
                correction:
                  'La persistance du canal art&eacute;riel se manifeste par un souffle continu sous-claviculaire gauche.',
              },
              answer: false,
            },
            {
              id: 106912000,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement: 'une t&eacute;tralogie de Fallot',
                state: 'F',
                correction:
                  "La t&eacute;tralogie de Fallot inclut une dextroposition de l'aorte, un st&eacute;nose pulmonaire, une CIV et une HVD. Le souffle est celui de la st&eacute;nose pulmonaire, donc &eacute;jectionnel systolique au 2e espace intercostal gauche. Sa r&eacute;v&eacute;lation se fait g&eacute;n&eacute;ralement <em>in utero</em>, ou &agrave; la naissance par l'auscultation si elle est peu s&eacute;v&egrave;re ou par la pr&eacute;sence de sympt&ocirc;mes d'insuffisance cardiaque si elle est s&eacute;v&egrave;re.",
              },
              answer: false,
            },
            {
              id: 106911997,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement: 'une coarctation aortique',
                state: 'F',
                correction:
                  "Le souffle est systolique, per&ccedil;u dans le dos, parfois en sous-claviculaire gauche et dans l'aisselle gauche. Il n'y a pas de d&eacute;doublement fix&eacute; et constant du B2. On peut retrouver une&nbsp;HTA brachiale avec asym&eacute;trie tensionnelle &gt; 20 mmHg par rapport aux MI, une abolition des pouls f&eacute;moraux et une circulation collat&eacute;rale thoracique.",
              },
              answer: false,
            },
            {
              id: 106911999,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement: 'une communication interatriale (CIA)',
                state: 'V',
                correction:
                  "Tr&egrave;s fr&eacute;quemment diagnostiqu&eacute; &agrave; l'&acirc;ge adulte contrairement aux autres cardiopathies cong&eacute;nitales. On retrouve g&eacute;n&eacute;ralement un souffle systolique au foyer pulmonaire (augmentation du d&eacute;bit) et un d&eacute;doublement fix&eacute; et constant du B2.",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035293,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 5,
            statement:
              'Parmi les propositions suivantes relatives &agrave; la CIA, laquelle (lesquelles) est (sont) exacte(s) ?',
            item: '238',
            knowledge: {
              knowledge: 'N238N04',
              rank: 'B',
              type: 'Étiologies',
              label:
                "Connaître l'orientation étiologique des souffles cardiaques",
              item: {
                item: 238,
                short_title: 'Souffles cardiaques chez l’enfant',
                title: "Souffle cardiaque chez l'enfant.",
                tag_and_title: "N238: Souffle cardiaque chez l'enfant.",
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['PHY'],
            correction:
              "Les dossiers de m&eacute;decine cardiovasculaire comportent souvent au moins une question de physiopathologie, ces derni&egrave;res &eacute;tant assez rares aux ECNi. A noter qu'elle ne rentre pas dans les d&eacute;tails mais fait appel &agrave; des connaissances minimales &agrave; propos des cardiopathies cong&eacute;nitales.",

            coefficient: 1,
            lessons: ['cardiopathie_endocardite', 'communication_interatriale'],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 5,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912002,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement:
                  'c&rsquo;est une cardiopathie habituellement cyanog&egrave;ne',
                state: 'F',
                correction:
                  'Les cardiopathies cong&eacute;nitales cyanog&egrave;nes &agrave; conna&icirc;tre sont la t&eacute;tralogie de Fallot, la st&eacute;nose valvulaire pulmonaire critique et la transposition de gros vaisseaux.',
              },
              answer: false,
            },
            {
              id: 106912003,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement: 'le shunt est gauche droite',
                state: 'V',
                correction:
                  "La pression de l'atrium gauche &eacute;tant sup&eacute;rieure &agrave; celle de l'atrium droit, on retrouve un flux gauche-droite &agrave; ce niveau, responsable ainsi d'un souffle au bord gauche du sternum et d'une augmentation du remplissage du ventricule droit.",
              },
              answer: false,
            },
            {
              id: 106912004,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement:
                  'la d&eacute;couverte &agrave; l&rsquo;&acirc;ge adulte est courante',
                state: 'V',
                correction:
                  "La CIA est tr&egrave;s fr&eacute;quemment asymptomatique et le sujet jeune est en parfait &eacute;tat, sans ant&eacute;c&eacute;dents familiaux particuliers. Elle se r&eacute;v&egrave;le ainsi fr&eacute;quemment &agrave; l'&acirc;ge adulte, parfois par des complications apr&egrave;s 30 ans.",
              },
              answer: false,
            },
            {
              id: 106912005,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement:
                  'le risque d&rsquo;endocardite bact&eacute;rienne est &eacute;lev&eacute;',
                state: 'F',
                correction:
                  "La CIA ne fait ni partie du groupe A (haut risque d'endocardite infectieuse, o&ugrave; figurent les cardiopathies cyanog&egrave;nes non op&eacute;r&eacute;es), ni du groupe B (risque moyen, o&ugrave; figurent les cardiopathies cong&eacute;nitales non cyanog&egrave;ne &agrave; l'exception de la CIA qui n'est pas &agrave; risque) des pathologies &agrave; risque d'endocardite bact&eacute;rienne.",
              },
              answer: false,
            },
            {
              id: 106912001,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement:
                  'le d&eacute;bit art&eacute;riel pulmonaire est diminu&eacute;',
                state: 'F',
                correction:
                  'Le d&eacute;bit pulmonaire est augment&eacute; par augmentation du remplissage des cavit&eacute;s droites en raison du shunt gauche-droite.',
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035294,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 6,
            statement:
              'Parmi les propositions suivantes relatives &agrave; l&rsquo;interpr&eacute;tation de l&rsquo;&eacute;lectrocardiogramme, laquelle (lesquelles) est (sont) exacte(s) ?<br /><br />\r\n<div style="text-align: center;"><span id="docs-internal-guid-d290edd8-7fff-8f01-5c8a-74be628f7b13" style="font-size: 11pt; font-family: Arial; color: #000000; background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><span style="border: none; display: inline-block; overflow: hidden; width: 561px; height: 322px;"><img style="margin-left: auto; margin-top: 0px; display: block; margin-right: auto;" src="https://asclepia.io/img/ecni_2016/DP1_Q6_enonce_250.png" width="560" height="322" /></span></span></div>',
            item: '231',
            knowledge: {
              knowledge: 'N231N08',
              rank: 'B',
              type: 'Diagnostic positif',
              label:
                'Identifier les blocs incomplets de branche et blocs fonctionnels',
              item: {
                item: 231,
                short_title: 'ECG',
                title: 'Électrocardiogramme : indications et interprétations.',
                tag_and_title:
                  'N231: Électrocardiogramme : indications et interprétations.',
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['ECG'],
            correction:
              "ECG pas forc&eacute;ment &eacute;vident car il faut &ecirc;tre parfaitement au clair sur les notions d'h&eacute;mi-bloc et de bloc de branche. Les ECG restent tout de m&ecirc;me assez clairs &agrave; lire aux ECNi, puisqu'ils &eacute;vitent de proposer des formes atypiques ou confusiog&egrave;nes (sauf pour la QI 65 des ECNi 2019).<br /><br /><strong>Analyse de l'ECG :</strong><br />\r\n<ul>\r\n<li>Fr&eacute;quence : 72/min ;</li>\r\n<li>Rythme sinusal ;</li>\r\n<li>Onde P : pas d'hypertrophie atriale visible ;</li>\r\n<li>Espace PR &lt; 200 ms (absence de BAV) ;</li>\r\n<li>QRS : axe normal (-30-90&deg;), dur&eacute;e &gt; 100 ms mais &lt; 120 ms, aspect RsR' en V1 et Rs en V5-V6 permettant de diagnostiquer un <strong>bloc de branche droit incomplet</strong> ;</li>\r\n<li>Pas de trouble de la repolarisation.</li>\r\n</ul>",
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            coefficient: 1,
            lessons: ['lecture_ecg'],
            similar_questions: [
              {
                id: 6,
                group: {
                  id: 1,
                  composition_unit: undefined,
                  collection: 'ECNi 2016',
                  is_free: false,
                  type: 'DP',
                  num: 1,
                  median: 10.6,
                  denom: 15,
                  thumbnail:
                    "Palpitations découvertes dans le cadre d'une consultation pour certificat de non contre-indication à la pratique sportive d'un homme de 20 ans. Exploration aboutissant au diagnostic de communication inter-atriale qui sera fermée. Diagnostic et prise en charge d'une tachycardie jonctionnelle paroxystique, puis d'une HTA à distance.",
                  number_of_questions: 15,
                  specialty1: 'MCV',
                  specialty2: null,
                  last_training: 1736448,
                  last_training_date: '2024-02-09T12:28:11.285664+01:00',
                  grade: 0,
                  total_coefficient: 15,
                },
                type: 'QRM',
                num: 6,
                statement:
                  'Parmi les propositions suivantes relatives &agrave; l&rsquo;interpr&eacute;tation de l&rsquo;&eacute;lectrocardiogramme, laquelle (lesquelles) est (sont) exacte(s) ?<br /><br />\r\n<div style="text-align: center;"><span id="docs-internal-guid-d290edd8-7fff-8f01-5c8a-74be628f7b13" style="font-size: 11pt; font-family: Arial; color: #000000; background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><span style="border: none; display: inline-block; overflow: hidden; width: 561px; height: 322px;"><img style="margin-left: auto; margin-top: 0px; display: block; margin-right: auto;" src="https://asclepia.io/img/ecni_2016/DP1_Q6_enonce_250.png" width="560" height="322" /></span></span></div>',
                item: '231',
                knowledge: {
                  knowledge: 'N231N08',
                  rank: 'B',
                  type: 'Diagnostic positif',
                  label:
                    'Identifier les blocs incomplets de branche et blocs fonctionnels',
                  item: {
                    item: 231,
                    short_title: 'ECG',
                    title:
                      'Électrocardiogramme : indications et interprétations.',
                    tag_and_title:
                      'N231: Électrocardiogramme : indications et interprétations.',
                  },
                  see_also_items: [],
                },
                specialty1: 'MCV',
                specialty2: null,
                categories: ['ECG'],
                items_qcm: [
                  {
                    num: 1,
                    statement: 'hypertrophie atriale gauche',
                  },
                  {
                    num: 3,
                    statement: 'bloc de branche droite incomplet',
                  },
                  {
                    num: 5,
                    statement: 'h&eacute;mibloc ant&eacute;rieur gauche',
                  },
                  {
                    num: 4,
                    statement: 'hypertrophie ventriculaire droite',
                  },
                  {
                    num: 2,
                    statement: 'bloc de branche gauche complet',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 4327,
                group: {
                  id: 108,
                  composition_unit: undefined,
                  collection: 'ECNi 2018',
                  is_free: false,
                  type: 'QI',
                  num: 1,
                  median: null,
                  denom: null,
                  thumbnail: '',
                  number_of_questions: 120,
                  specialty1: null,
                  specialty2: null,
                  last_training: null,
                  last_training_date: null,
                  grade: null,
                  total_coefficient: null,
                },
                type: 'QRM',
                num: 85,
                statement:
                  'Une patiente &acirc;g&eacute;e de 62 ans est admise pour lipothymies.<br /><br />\r\n<div><img style="display: block; margin-left: auto; margin-right: auto;" src="https://asclepia.io/img/ecni_2018/QI_Q85_enonce_250.png" alt="" width="560" height="322" /></div>\r\n<br />Apr&egrave;s avoir interpr&eacute;t&eacute; cet &eacute;lectrocardiogramme, indiquez la (les) proposition(s) exacte(s) ?',
                item: '236',
                knowledge: {
                  knowledge: 'N236N02',
                  rank: 'A',
                  type: 'Diagnostic positif',
                  label:
                    'Savoir évoquer le diagnostic de dysfonction sinusale ou de trouble de conduction (TDC)',
                  item: {
                    item: 236,
                    short_title: 'Troubles conductifs ',
                    title: 'Troubles de la conduction intracardiaque.',
                    tag_and_title:
                      'N236: Troubles de la conduction intracardiaque.',
                  },
                  see_also_items: [],
                },
                specialty1: 'MCV',
                specialty2: null,
                categories: ['ECG'],
                items_qcm: [
                  {
                    num: 2,
                    statement:
                      'Il existe un bloc atrioventriculaire du premier degr&eacute;',
                  },
                  {
                    num: 1,
                    statement: 'Il existe une dysfonction sinusale',
                  },
                  {
                    num: 3,
                    statement: 'Il existe un bloc de branche droite',
                  },
                  {
                    num: 4,
                    statement: 'Il existe un bloc atrioventriculaire 2 pour 1',
                  },
                  {
                    num: 5,
                    statement: 'Il existe un bloc atrioventriculaire complet',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 53,
                group: {
                  id: 4,
                  composition_unit: undefined,
                  collection: 'ECNi 2019',
                  is_free: false,
                  type: 'DP',
                  num: 17,
                  median: 11.1,
                  denom: 17,
                  thumbnail:
                    "Exploration d'une dyspnée chronique à l'effort chez un homme de 66 ans aux multiples facteurs de risque cardiovasculaires, puis exploration diagnostique et prise en charge d'une décompensation intercurrente en œdème aigu du poumon cardiogénique sur insuffisance mitrale ischémique. Diagnostic et prise en charge d'une ischémie aiguë du membre inférieur à distance.",
                  number_of_questions: 17,
                  specialty1: 'MCV',
                  specialty2: null,
                  last_training: 1680418,
                  last_training_date: '2023-10-10T21:12:24.931623+02:00',
                  grade: 13.7,
                  total_coefficient: 17,
                },
                type: 'QRM',
                num: 8,
                statement:
                  'La r&eacute;ponse au traitement est rapidement favorable. Un &eacute;lectrocardiogramme est &agrave; nouveau enregistr&eacute;.<br />Quelle est l&rsquo;interpr&eacute;tation de cet &eacute;lectrocardiogramme ?<br /><br />\r\n<div style="text-align: center;"><img src="https://asclepia.io/img/ecni_2019/DP17_Q8_enonce_300.png" alt="" width="439" height="252" /></div>',
                item: '339',
                knowledge: {
                  knowledge: 'N339N07',
                  rank: 'A',
                  type: 'Examens complémentaires',
                  label:
                    "Connaître les indications de l'électrocardiogramme (ECG) devant toute douleur thoracique ou suspicion de SCA ; connaître les indications et interpréter le dosage de troponine",
                  item: {
                    item: 339,
                    short_title: 'Syndromes coronariens aigus',
                    title: 'Syndromes coronariens aigus.',
                    tag_and_title: 'N339: Syndromes coronariens aigus.',
                  },
                  see_also_items: [],
                },
                specialty1: 'MCV',
                specialty2: null,
                categories: ['ECG'],
                items_qcm: [
                  {
                    num: 1,
                    statement: '&eacute;lectrocardiogramme normal',
                  },
                  {
                    num: 3,
                    statement: 'bloc complet de branche droite',
                  },
                  {
                    num: 2,
                    statement: 's&eacute;quelle d&rsquo;infarctus du myocarde',
                  },
                  {
                    num: 4,
                    statement: 'rythme sinusal',
                  },
                  {
                    num: 5,
                    statement:
                      'h&eacute;mibloc (bloc fasciculaire) post&eacute;rieur gauche',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
            ],

            sources: [],
            books: [],
          },
          saved: true,
          num: 6,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912006,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement: 'hypertrophie atriale gauche',
                state: 'F',
                correction:
                  "Une hypertrophie atriale gauche est diagnostiqu&eacute;e par une onde P &gt; 120 ms, avec une composante n&eacute;gative &gt; 40 ms en V1 (en raison de la d&eacute;polarisation retard&eacute;e de l'atrium gauche).",
              },
              answer: false,
            },
            {
              id: 106912010,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement: 'bloc de branche gauche complet',
                state: 'F',
                correction:
                  "La proposition est doublement fausse car le bloc de branche n'est ni gauche (aspect QS en V1 et R en V6), ni complet (dur&eacute;e &lt; 120 ms).",
              },
              answer: false,
            },
            {
              id: 106912007,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement: 'bloc de branche droite incomplet',
                state: 'V',
                correction:
                  "Le bloc de branche ici est :<br />\r\n<ul>\r\n<li>droit : aspect RSR' en V1 et Rs en V6</li>\r\n<li>incomplet : les QRS sont &lt; 120 ms</li>\r\n</ul>",
              },
              answer: false,
            },
            {
              id: 106912009,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement: 'hypertrophie ventriculaire droite',
                state: 'F',
                correction:
                  "Il semble improbable d'en avoir une chez ce patient.<br /><strong>Les signes &eacute;lectriques de l'HVD sont les suivants :</strong> axe droit; R &gt; S en V1, onde T invers&eacute;e en V1-V3, RV1 + SV5 &gt; 10,5 mm et onde S profonde en V6.",
              },
              answer: false,
            },
            {
              id: 106912008,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement: 'h&eacute;mibloc ant&eacute;rieur gauche',
                state: 'F',
                correction:
                  "Il n'y a ni d&eacute;viation axiale gauche des QRS, ni d'onde S plus profonde en DIII qu'en DII (S3 &gt; S2).",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035295,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 7,
            statement:
              'L&rsquo;&eacute;chocardiographie confirme le diagnostic de CIA ostium secundum dont la fermeture percutan&eacute;e est r&eacute;alis&eacute;e avec succ&egrave;s quelques semaines plus tard.<br />Quelques mois plus tard, le patient se rend aux urgences pour un acc&egrave;s de tachycardie &eacute;voluant depuis une heure. A l&rsquo;interrogatoire, il est conscient et orient&eacute;,&nbsp; les palpitations persistent. Il d&eacute;crit une g&ecirc;ne thoracique irradiant dans le cou. La pression art&eacute;rielle est &agrave; 90/50 mm Hg aux deux bras, la fr&eacute;quence cardiaque est &agrave; 215 battements par minute. Les extr&eacute;mit&eacute;s sont chaudes, il n&rsquo;y a pas de marbrures. La SpO2 est &agrave; 92 %. Un &eacute;lectrocardiogramme est enregistr&eacute;.<br />Quel(s) est (sont) le (les) signe(s) de mauvaise tol&eacute;rance de cette tachycardie ?',
            item: '237',
            knowledge: {
              knowledge: 'N237N02',
              rank: 'A',
              type: 'Identifier une urgence',
              label:
                'Connaître les signes de gravité et diagnostiques ECG urgents',
              item: {
                item: 237,
                short_title: 'Palpitations',
                title: 'Palpitations.',
                tag_and_title: 'N237: Palpitations.',
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['SEM'],
            correction:
              "Nouvel &eacute;nonc&eacute; de description clinique. On se trouve quelques mois apr&egrave;s la fermeture de la CIA, avec cette fois-ci une tachycardie en cours mal tol&eacute;r&eacute;e, complication fr&eacute;quente d'une CIA.",
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            coefficient: 1,
            lessons: ['palpitations_gravite'],
            similar_questions: [],

            sources: [],
            books: [],
          },
          saved: true,
          num: 7,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912013,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement: 'douleur thoracique',
                state: 'V',
                correction:
                  'La "g&ecirc;ne thoracique irradiant dans le cou" &eacute;voque un angor fonctionnel qui est un signe de mauvaise tol&eacute;rance, puisque t&eacute;moin d\'une souffrance myocardique d\'origine isch&eacute;mique en raison de la tachycardie tr&egrave;s rapide.',
              },
              answer: false,
            },
            {
              id: 106912014,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement: '&eacute;tat de choc',
                state: 'F',
                correction:
                  "Une hypotension art&eacute;rielle n'est pas synonyme de choc. L'&eacute;nonc&eacute; indique que les extr&eacute;mit&eacute;s sont chaudes et qu'il n'y a pas de marbrures, ce qui indique l'absence d'hypoperfusion p&eacute;riph&eacute;rique par vasoconstriction, et donc l'absence d'un &eacute;tat de choc.",
              },
              answer: false,
            },
            {
              id: 106912015,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement: 'hypotension art&eacute;rielle',
                state: 'V',
                correction:
                  "La PAS est limite et la PAD est inf&eacute;rieure &agrave; 60 mmHg. Il s'agit d'un signe de mauvaise tol&eacute;rance h&eacute;modynamique de cette tachycardie, t&eacute;moin d'une alt&eacute;ration de la systole et donc de l'&eacute;jection ventriculaire gauche.",
              },
              answer: false,
            },
            {
              id: 106912011,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement: 'dur&eacute;e de la tachycardie',
                state: 'F',
                correction:
                  "Il ne s'agit pas d'un crit&egrave;re de mauvaise tol&eacute;rance d'une tachycardie.",
              },
              answer: false,
            },
            {
              id: 106912012,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement: 'aucun',
                state: 'F',
                correction: 'Les propositions A et C sont correctes.',
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035296,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 8,
            statement:
              '<div style="text-align: center;"><span id="docs-internal-guid-93645235-7fff-7586-808d-d0e120817b9b" style="font-size: 11pt; font-family: Arial; color: #000000; background-color: transparent; font-weight: 400; font-style: normal; font-variant: normal; text-decoration: none; vertical-align: baseline; white-space: pre-wrap;"><span style="border: none; display: inline-block; overflow: hidden; width: 593px; height: 323px;"><img style="margin-left: 0px; margin-top: 0px;" src="https://asclepia.io/img/ecni_2016/DP1_Q8_enonce_250.png" width="592" height="322" /></span></span></div>\r\n<br />Parmi les hypoth&egrave;ses diagnostiques suivantes relatives &agrave; l&rsquo;interpr&eacute;tation de l&rsquo;&eacute;lectrocardiogramme, laquelle (lesquelles) est (sont) possible(s) ?',
            item: '231',
            knowledge: {
              knowledge: 'N231N16',
              rank: 'A',
              type: 'Diagnostic positif',
              label: 'Identifier les tachycardies jonctionnelles',
              item: {
                item: 231,
                short_title: 'ECG',
                title: 'Électrocardiogramme : indications et interprétations.',
                tag_and_title:
                  'N231: Électrocardiogramme : indications et interprétations.',
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['ECG'],
            correction:
              "ECG tr&egrave;s caract&eacute;ristique de la tachycardie jonctionnelle paroxystique dans un contexte de mauvaise tol&eacute;rance clinique. Le terrain jeune, avec une CIA r&eacute;cemment ferm&eacute;e, oriente d'embl&eacute;e vers une tachycardie supraventriculaire.<br />La tachycardie jonctionnelle paroxystique est le premier diagnostic &agrave; &eacute;voquer devant une tachycardie aussi rapide (&gt; 200/min) chez un jeune.<br /><br /><strong>Analyse de l'ECG :</strong><br />\r\n<ul>\r\n<li>Fr&eacute;quence : 210/min ;</li>\r\n<li>Rythme r&eacute;gulier, non sinusal, absence d'onde P visible</li>\r\n<li>Les QRS sont fins et d'axe normal</li>\r\n</ul>\r\nIl s'agit d'une tachycardie r&eacute;guli&egrave;re &agrave; QRS fins associ&eacute;e &agrave; une FC autour de 200/min.",

            coefficient: 1,
            lessons: [
              'diag_tachycardie_atriale',
              'diag_tachycardie_jonctionnelle',
              'lecture_ecg',
              'diag_flutter',
              'diag_fibrillation_ventriculaire',
            ],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 8,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912017,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement: 'tachycardie sinusale',
                state: 'F',
                correction:
                  "L'activit&eacute; atriale n'est pas visible (absence d'onde P). D'autre part, la fr&eacute;quence est trop &eacute;lev&eacute;e pour qu'elle soit le fait d'un rythme sinusal.",
              },
              answer: false,
            },
            {
              id: 106912018,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement: 'tachycardie atriale',
                state: 'V',
                correction:
                  "Bien que la rapidit&eacute; et l'absence d'onde P soient en d&eacute;faveur, il est possible que cette tachycardie soit atriale puisqu'elle est r&eacute;guli&egrave;re &agrave; QRS fins sur un terrain de CIA.",
              },
              answer: false,
            },
            {
              id: 106912016,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement: 'tachycardie ventriculaire',
                state: 'F',
                correction:
                  "Les QRS d'une tachycardie ventriculaire sont larges, ce qui &eacute;limine celle-ci (attention, des QRS larges n'&eacute;liminent pas une tachycardie supraventriculaire).",
              },
              answer: false,
            },
            {
              id: 106912020,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement: 'fibrillation atriale',
                state: 'F',
                correction:
                  "La r&eacute;gularit&eacute; et l'absence d'onde F ne permettent pas d'&eacute;voquer une FA, qui est rare sur ce terrain. Aux ECNi, une FA appara&icirc;trait irr&eacute;guli&egrave;re (cf. ECNi 2020 DP1), sauf en cas de BAV 3 mais on ne se placerait pas dans le contexte de tachycardie.",
              },
              answer: false,
            },
            {
              id: 106912019,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement: 'tachycardie jonctionnelle',
                state: 'V',
                correction:
                  "Il s'agit d'une tachycardie r&eacute;guli&egrave;re &agrave; QRS fins &gt; 200/min sur un terrain de CIA r&eacute;cemment ferm&eacute;e. Il faut &eacute;voquer la TJP en premier.",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035297,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 9,
            statement:
              'Comment confirmez-vous le diagnostic de tachycardie jonctionnelle ?',
            item: '231',
            knowledge: {
              knowledge: 'N231N17',
              rank: 'B',
              type: 'Diagnostic positif',
              label:
                "Connaître l'intérêt des manœuvres vagales dans les troubles du rythme",
              item: {
                item: 231,
                short_title: 'ECG',
                title: 'Électrocardiogramme : indications et interprétations.',
                tag_and_title:
                  'N231: Électrocardiogramme : indications et interprétations.',
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: [],
            correction:
              "Devant une tachycardie jonctionnelle paroxystique (TJP), <em>a fortiori</em> mal tol&eacute;r&eacute;e, la cardioversion est de mise. Le principe est de freiner le NAV par une man&oelig;uvre vagale afin de diminuer la fr&eacute;quence jusqu'&agrave; ce que le NS supprime la TJ par entra&icirc;nement rapide.<br /><br />Les man&oelig;uvres vagales non pharmacologiques sont tent&eacute;es en premi&egrave;re intention (man&oelig;uvre de Valsalva, massage sino-carotidien, avaler un grand verre d'eau froide, r&eacute;flexe naus&eacute;eux, r&eacute;flexe oculo-cardiaque). En seconde intention, la cardioversion sera m&eacute;dicamenteuse : ad&eacute;nosine (Krenosin&reg;, agoniste purinergique) ou ATP (Striadyne&reg;, agoniste purinergique).",

            coefficient: 1,
            lessons: ['tachycardie_jonctionnelle_wpw'],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 9,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912022,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement:
                  'en injectant de l&rsquo;atropine pour r&eacute;tablir le rythme sinusal',
                state: 'F',
                correction:
                  "L'atropine est un antagoniste aux r&eacute;cepteurs muscariniques &agrave; l'ac&eacute;tylcholine et va ainsi bloquer l'influx parasympathique freinateur du n&oelig;ud sinusal. Cela aura pour effet une tachycardie sinusale, ce qui n'est pas recherch&eacute; ici.",
              },
              answer: false,
            },
            {
              id: 106912023,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement:
                  'en injectant de l&rsquo;ad&eacute;nosine (Striadyne<sup>&reg;</sup>) pour r&eacute;tablir le rythme sinusal',
                state: 'V',
                correction:
                  "L'ad&eacute;nosine (agoniste purinergique) est une mol&eacute;cule qui va avoir pour effet de diminuer la conduction myocardique ainsi que de freiner le rythme myocardique au niveau du n&oelig;ud atrioventriculaire, via les r&eacute;cepteurs A1 et A2. Elle permet de diagnostiquer une tachycardie jonctionnelle paroxystique en cas de retour au rythme sinusal.<br />Les noms commerciaux ne sont pas &agrave; retenir pour les ECNi, mais il s'agit du Krenosin<sup>&reg;</sup> et non de la Striadyne<sup>&reg;</sup>.",
              },
              answer: false,
            },
            {
              id: 106912025,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement:
                  'en r&eacute;tablissant le rythme sinusal par la man&oelig;uvre de Valsalva',
                state: 'V',
                correction:
                  "La man&oelig;uvre de Valsalva, qui consiste &agrave; augmenter la pression intra-thoracique, va stimuler des baror&eacute;cepteurs situ&eacute;s dans l'aorte. En cons&eacute;quence, l'activit&eacute; du nerf vague (parasympathique) augmentera et l'activit&eacute; des nerfs cardiaques (sympathiques) va diminuer. Cette man&oelig;uvre vagale va ainsi ralentir l'activit&eacute; au niveau des n&oelig;uds sinusal et atrioventriculaire. Un retour au rythme sinusal permettra de diagnostiquer une tachycardie jonctionnelle paroxystique.",
              },
              answer: false,
            },
            {
              id: 106912021,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement:
                  'en recherchant une dissociation atrio-ventriculaire',
                state: 'F',
                correction:
                  "Elle est d&eacute;finie par l'absence de lien entre les ondes P et les complexes QRS, lorsqu'il y a un BAV complet. Le rythme d'&eacute;chappement sera plus ou moins bas situ&eacute; selon la position du bloc. Cela n'a rien avoir avec le diagnostic de TJP.",
              },
              answer: false,
            },
            {
              id: 106912024,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement:
                  'en attendant une r&eacute;duction spontan&eacute;e en d&eacute;cubitus',
                state: 'F',
                correction:
                  "D'une part, il ne faut pas attendre une r&eacute;duction spontan&eacute;e au vu de la mauvaise tol&eacute;rance. D'autre part, une r&eacute;duction spontan&eacute;e ne permettra pas de discriminer entre les diff&eacute;rentes causes de tachycardie &agrave; QRS fins.",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035298,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 10,
            statement:
              'Il s&rsquo;agit d&rsquo;une tachycardie jonctionnelle dont la r&eacute;duction est obtenue par une injection d&rsquo;ad&eacute;nosine. Il quitte les urgences avec une prescription m&eacute;dicamenteuse en attente d&rsquo;un traitement radical percutan&eacute; (ablation par radiofr&eacute;quence).<br />Vous pensez introduire un traitement par at&eacute;nolol. Parmi les propositions suivantes relatives &agrave; ce traitement, laquelle (lesquelles) est (sont) exacte(s) ?',
            item: '330',
            knowledge: {
              knowledge: 'N330N03',
              rank: 'A',
              type: 'Prise en charge',
              label:
                "Bêta-bloquants : connaître les mécanismes d'action, indications, effets secondaires interactions médicamenteuses, modalités de surveillance et principales causes d'échec",
              item: {
                item: 330,
                short_title: 'Classes médicamenteuses les plus courantes',
                title:
                  "Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                tag_and_title:
                  "N330: Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['TTT'],
            correction:
              "Question assez int&eacute;ressante sur un traitement c&eacute;l&egrave;bre en cardiologie qu'il convient de ma&icirc;triser sur le bout des doigts : les &beta;-bloquants. Il fallait simplement savoir que l'at&eacute;nolol &eacute;tait un &beta;-bloquant pour pouvoir r&eacute;pondre. Pour information, c'est un &beta;-bloquant cardios&eacute;lectif contre-indiqu&eacute; pour une FE &lt; 35%.",

            coefficient: 1,
            lessons: [],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 10,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912027,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement:
                  'il est contre-indiqu&eacute; par l&rsquo;ant&eacute;c&eacute;dent d&rsquo;hypotension pendant la crise de tachycardie',
                state: 'F',
                correction:
                  "Les &beta;-bloquants sont certes hypotenseurs art&eacute;riels, mais un ant&eacute;c&eacute;dent d'hypotension dans un contexte de TJP n'est pas une contre-indication.",
              },
              answer: false,
            },
            {
              id: 106912028,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement:
                  'il est contre-indiqu&eacute; par le bloc de branche droite incomplet',
                state: 'F',
                correction:
                  "Les &beta;-bloquants ne sont pas contre-indiqu&eacute;s en cas de bloc de branche (droit, gauche, complet ou incomplet). Cependant, ils sont contre-indiqu&eacute;s dans les bloc sino-atriaux 2 ou 3 (dysfonction sinusale) et les BAV 2 ou 3 non appareill&eacute;s en raison du risque d'aggravation par leurs effets chronotrope ou dromotrope (conduction) n&eacute;gatifs.",
              },
              answer: false,
            },
            {
              id: 106912026,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement: 'il ralentit la conduction atrio-ventriculaire',
                state: 'V',
                correction:
                  'Il est inotrope n&eacute;gatif (contractilit&eacute;), chronotrope n&eacute;gatif (ralentit la fr&eacute;quence cardiaque), bathmotrope n&eacute;gatif (excitabilit&eacute;) et dromotrope n&eacute;gatif (conduction). La conduction intramyocardique est ainsi ralentie.',
              },
              answer: false,
            },
            {
              id: 106912029,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement: 'il ralentit le noeud atrial',
                state: 'V',
                correction:
                  "En bloquant les r&eacute;cepteurs &beta;1 myocardiques, l'at&eacute;nolol inhibe le tonus sympathique et ralentit ainsi le n&oelig;ud sinusal, pouvant ainsi provoquer une bradycardie sinusale. Bien que le terme \"n&oelig;ud atrial\" n'existe pas <em>stricto sensu</em>, nul doute qu'il fait r&eacute;f&eacute;rence au n&oelig;ud sinusal ou sino-atrial.",
              },
              answer: false,
            },
            {
              id: 106912030,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement:
                  'il est contre-indiqu&eacute; par les ant&eacute;c&eacute;dents de migraine',
                state: 'F',
                correction:
                  "Les &beta;-bloquants non cardios&eacute;lectifs, de par leur activit&eacute; vasoconstrictrice, diminuent les c&eacute;phal&eacute;es et notamment les migraines et peuvent en &ecirc;tre un traitement de fond. L'at&eacute;nolol &eacute;tant cardios&eacute;lectif, il n'est pas utilis&eacute; pour le traitement de fond.",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035299,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 11,
            statement:
              'Dix ans plus tard, le patient consulte &agrave; nouveau. Il a stopp&eacute; compl&egrave;tement le sport mais ne fume plus. Il exerce la profession de commercial. Il p&egrave;se 100 kg avec 107 cm de p&eacute;rim&egrave;tre abdominal car dit-il &laquo; il est oblig&eacute; de d&eacute;jeuner au restaurant avec ses clients tous les jours &raquo;. Il est asymptomatique et ne prend pas de m&eacute;dicament. L&rsquo;examen clinique est normal. La pression art&eacute;rielle v&eacute;rifi&eacute;e &agrave; plusieurs reprises en d&eacute;cubitus depuis plus de 5 minutes est &agrave; 170/100 mmHg aux deux bras.<br />Parmi les propositions suivantes relatives &agrave; la prise en charge, laquelle (lesquelles) est (sont) exacte(s) ?',
            item: '224',
            knowledge: {
              knowledge: 'N224N04',
              rank: 'A',
              type: 'Diagnostic positif',
              label: 'Savoir mesurer la pression artérielle',
              item: {
                item: 224,
                short_title: 'Hypertension artérielle',
                title: "Hypertension artérielle de l'adulte et de l’enfant.",
                tag_and_title:
                  "N224: Hypertension artérielle de l'adulte et de l’enfant.",
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: [],
            correction:
              "Le diagnostic d'hypertension art&eacute;rielle est une connaissance &agrave; ma&icirc;triser imp&eacute;rativement devant son importance en pratique clinique et aux ECNi, puisque cette pathologie peut &ecirc;tre trait&eacute;e par un grand nombre de sp&eacute;cialit&eacute;s (cardiologie, n&eacute;phrologie, g&eacute;riatrie, ...).",
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            coefficient: 1,
            lessons: ['diagnostic_hta'],
            similar_questions: [
              {
                id: 11,
                group: {
                  id: 1,
                  composition_unit: undefined,
                  collection: 'ECNi 2016',
                  is_free: false,
                  type: 'DP',
                  num: 1,
                  median: 10.6,
                  denom: 15,
                  thumbnail:
                    "Palpitations découvertes dans le cadre d'une consultation pour certificat de non contre-indication à la pratique sportive d'un homme de 20 ans. Exploration aboutissant au diagnostic de communication inter-atriale qui sera fermée. Diagnostic et prise en charge d'une tachycardie jonctionnelle paroxystique, puis d'une HTA à distance.",
                  number_of_questions: 15,
                  specialty1: 'MCV',
                  specialty2: null,
                  last_training: 1736448,
                  last_training_date: '2024-02-09T12:28:11.285664+01:00',
                  grade: 0,
                  total_coefficient: 15,
                },
                type: 'QRM',
                num: 11,
                statement:
                  'Dix ans plus tard, le patient consulte &agrave; nouveau. Il a stopp&eacute; compl&egrave;tement le sport mais ne fume plus. Il exerce la profession de commercial. Il p&egrave;se 100 kg avec 107 cm de p&eacute;rim&egrave;tre abdominal car dit-il &laquo; il est oblig&eacute; de d&eacute;jeuner au restaurant avec ses clients tous les jours &raquo;. Il est asymptomatique et ne prend pas de m&eacute;dicament. L&rsquo;examen clinique est normal. La pression art&eacute;rielle v&eacute;rifi&eacute;e &agrave; plusieurs reprises en d&eacute;cubitus depuis plus de 5 minutes est &agrave; 170/100 mmHg aux deux bras.<br />Parmi les propositions suivantes relatives &agrave; la prise en charge, laquelle (lesquelles) est (sont) exacte(s) ?',
                item: '224',
                knowledge: {
                  knowledge: 'N224N04',
                  rank: 'A',
                  type: 'Diagnostic positif',
                  label: 'Savoir mesurer la pression artérielle',
                  item: {
                    item: 224,
                    short_title: 'Hypertension artérielle',
                    title:
                      "Hypertension artérielle de l'adulte et de l’enfant.",
                    tag_and_title:
                      "N224: Hypertension artérielle de l'adulte et de l’enfant.",
                  },
                  see_also_items: [],
                },
                specialty1: 'MCV',
                specialty2: null,
                categories: [],
                items_qcm: [
                  {
                    num: 2,
                    statement:
                      'on confirmera le diagnostic d&rsquo;hypertension art&eacute;rielle (HTA) par une nouvelle consultation dans un an',
                  },
                  {
                    num: 3,
                    statement:
                      'on r&eacute;cuse le diagnostic d&rsquo;hypertension art&eacute;rielle (HTA) en raison de l&rsquo;&acirc;ge',
                  },
                  {
                    num: 4,
                    statement:
                      'il peut s&rsquo;agir d&rsquo;un effet &laquo; blouse-blanche &raquo;',
                  },
                  {
                    num: 1,
                    statement:
                      'on peut confirmer le diagnostic d&rsquo;hypertension art&eacute;rielle (HTA) par un monitoring ambulatoire de pression art&eacute;rielle (MAPA)',
                  },
                  {
                    num: 5,
                    statement:
                      'on peut confirmer le diagnostic d&rsquo;hypertension art&eacute;rielle (HTA) par une auto-mesure &agrave; domicile',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 33,
                group: {
                  id: 3,
                  composition_unit: undefined,
                  collection: 'ECNi 2017',
                  is_free: false,
                  type: 'DP',
                  num: 2,
                  median: 10.2,
                  denom: 15,
                  thumbnail:
                    "Scapulalgies occasionnelles à l'effort découvertes dans le cadre d'une consultation pré-anesthésique chez une femme de 68 ans. Diagnostic et prise en charge d'un angor stable, découverte d'un bloc de branche gauche et d'une HTA. Syncope à distance dans le cadre d'un bloc atrio-ventriculaire et appareillage par stimulateur cardiaque.",
                  number_of_questions: 15,
                  specialty1: 'MCV',
                  specialty2: null,
                  last_training: 1736005,
                  last_training_date: '2023-11-22T08:33:37.410911+01:00',
                  grade: 0.2,
                  total_coefficient: 15,
                },
                type: 'QRM',
                num: 3,
                statement:
                  "L'examen clinique est sans particularit&eacute;, la pression art&eacute;rielle est &agrave; 180/ 90 mmHg, fr&eacute;quence cardiaque 80 bpm. Parmi les propositions suivantes, lequel (lesquels) permet(tent) de retenir le diagnostic d&rsquo;hypertension art&eacute;rielle ?",
                item: '224',
                knowledge: {
                  knowledge: 'N224N04',
                  rank: 'A',
                  type: 'Diagnostic positif',
                  label: 'Savoir mesurer la pression artérielle',
                  item: {
                    item: 224,
                    short_title: 'Hypertension artérielle',
                    title:
                      "Hypertension artérielle de l'adulte et de l’enfant.",
                    tag_and_title:
                      "N224: Hypertension artérielle de l'adulte et de l’enfant.",
                  },
                  see_also_items: [],
                },
                specialty1: 'MCV',
                specialty2: null,
                categories: [],
                items_qcm: [
                  {
                    num: 2,
                    statement:
                      'la confirmation des valeurs &eacute;lev&eacute;es de pression art&eacute;rielle par la mesure ambulatoire de pression art&eacute;rielle',
                  },
                  {
                    num: 1,
                    statement:
                      'la confirmation d&rsquo;une consommation &eacute;lev&eacute;e de sodium par la mesure de la natriur&egrave;se des 24 heures',
                  },
                  {
                    num: 3,
                    statement:
                      'la confirmation des valeurs &eacute;lev&eacute;es de pression art&eacute;rielle apr&egrave;s un test de marche de 6 minutes',
                  },
                  {
                    num: 4,
                    statement:
                      'la confirmation des valeurs &eacute;lev&eacute;es de pression art&eacute;rielle par l&rsquo;auto-mesure tensionnelle',
                  },
                  {
                    num: 5,
                    statement:
                      "la confirmation des valeurs &eacute;lev&eacute;es de pression art&eacute;rielle apr&egrave;s mise en d&eacute;cubitus 10 minutes au cabinet lors d'une seule consultation",
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
            ],

            sources: [],
            books: [],
          },
          saved: true,
          num: 11,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912034,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement:
                  'on peut confirmer le diagnostic d&rsquo;hypertension art&eacute;rielle (HTA) par un monitoring ambulatoire de pression art&eacute;rielle (MAPA)',
                state: 'V',
                correction:
                  "La MAPA constitue une m&eacute;thode fiable et reproductible pour faire le diagnostic d'HTA, gr&acirc;ce &agrave; des mesures r&eacute;p&eacute;t&eacute;es toutes les 15 &agrave; 30 minutes sur 24h, permettant notamment d'obtenir la PA nocturne qui est mieux corr&eacute;l&eacute;e &agrave; l'atteinte d'organes.",
              },
              answer: false,
            },
            {
              id: 106912031,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement:
                  'on confirmera le diagnostic d&rsquo;hypertension art&eacute;rielle (HTA) par une nouvelle consultation dans un an',
                state: 'F',
                correction:
                  "Le diagnostic d'HTA sur des mesures en consultation n&eacute;cessite au moins 2 consultations, d'espacement d'autant plus faible qu'elle est s&eacute;v&egrave;re. L'intervalle se compte en semaines. Pour d&eacute;buter un traitement, il faudra n&eacute;anmoins obligatoirement une MAPA ou une automesure tensionnelle.",
              },
              answer: false,
            },
            {
              id: 106912032,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement:
                  'on r&eacute;cuse le diagnostic d&rsquo;hypertension art&eacute;rielle (HTA) en raison de l&rsquo;&acirc;ge',
                state: 'F',
                correction:
                  "L'&acirc;ge n'est pas un crit&egrave;re diagnostique de l'HTA.",
              },
              answer: false,
            },
            {
              id: 106912033,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement:
                  'il peut s&rsquo;agir d&rsquo;un effet &laquo; blouse-blanche &raquo;',
                state: 'V',
                correction:
                  "Une pression art&eacute;rielle &eacute;lev&eacute;e en consultation peut tout &agrave; fait &ecirc;tre due &agrave; l'effet blouse blanche, qui est responsable d'une &eacute;l&eacute;vation de la PA uniquement en consultation et non pas en dehors. Il ne s'agit donc pas d'une r&eacute;elle HTA.",
              },
              answer: false,
            },
            {
              id: 106912035,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement:
                  'on peut confirmer le diagnostic d&rsquo;hypertension art&eacute;rielle (HTA) par une auto-mesure &agrave; domicile',
                state: 'V',
                correction:
                  "L'auto-mesure tensionnelle est l'examen de r&eacute;f&eacute;rence en France pour faire le diagnostic d'HTA et repose sur 3 mesures le matin et 3 mesures le soir pendant 3 jours de suite. On retient le diagnostic si PAS moyenne d&eacute;passe 135 ou la PAD moyenne 85 mmHg.",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035300,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 12,
            statement:
              'Le diagnostic d&rsquo;HTA est confirm&eacute; par une MAPA.<br />Quel(s) facteur(s) a (ont) pu favoriser l&rsquo;HTA chez ce patient ?',
            item: '224',
            knowledge: {
              knowledge: 'N224N03',
              rank: 'B',
              type: 'Physiopathologie',
              label: "Connaître la physiopathologie de l'HTA",
              item: {
                item: 224,
                short_title: 'Hypertension artérielle',
                title: "Hypertension artérielle de l'adulte et de l’enfant.",
                tag_and_title:
                  "N224: Hypertension artérielle de l'adulte et de l’enfant.",
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['EPI'],
            correction:
              "Il est important de conna&icirc;tre les facteurs favorisants d'une hypertension art&eacute;rielle car faisant l'objet de questions aux ECNi tr&egrave;s faciles &agrave; poser et sans ambigu&iuml;t&eacute;.",

            coefficient: 1,
            lessons: [],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 12,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912036,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement: 'consommation excessive d&rsquo;alcool',
                state: 'V',
                correction:
                  "L'alcool est une cause d'HTA par &eacute;l&eacute;vation de la synth&egrave;se d'angiotensinog&egrave;ne par le foie (m&ecirc;me m&eacute;canisme que les &oelig;strog&egrave;nes).",
              },
              answer: false,
            },
            {
              id: 106912039,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement: 'consommation excessive de sel',
                state: 'V',
                correction:
                  "Une consommation excessive de sel est responsable d'une augmentation de la vol&eacute;mie efficace, qui d&eacute;pend de la quantit&eacute; de Na<sup>+</sup>, ce qui a pour effet d'augmenter la pression art&eacute;rielle en cas de d&eacute;faut d'&eacute;limination r&eacute;nale de l'exc&egrave;s de volume. Les syst&egrave;mes r&eacute;gulateurs sont le syst&egrave;me sympathique, le syst&egrave;me r&eacute;nine-angiotensine-aldost&eacute;rone, les peptides natriur&eacute;tiques et le ph&eacute;nom&egrave;ne de natriur&egrave;se de pression.",
              },
              answer: false,
            },
            {
              id: 106912037,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement: 'pass&eacute; de sportif de bon niveau',
                state: 'F',
                correction:
                  'Le sport diminue la fr&eacute;quence cardiaque et la tension art&eacute;rielle de repos.',
              },
              answer: false,
            },
            {
              id: 106912040,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement: 'facteurs g&eacute;n&eacute;tiques',
                state: 'V',
                correction:
                  "L'hypertension art&eacute;rielle est souvent de transmission polyg&eacute;nique dans une famille.<br />Les &eacute;tudes montrent que c'est une maladie familiale chez 15% des patients souffrant d'HTA.",
              },
              answer: false,
            },
            {
              id: 106912038,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement:
                  'ant&eacute;c&eacute;dent de communication inter-auriculaire',
                state: 'F',
                correction:
                  "Un ant&eacute;c&eacute;dent de CIA n'est pas responsable d'une hypertension art&eacute;rielle.",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035301,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 13,
            statement:
              "L'HTA est confirm&eacute;e. Parmi les examens paracliniques suivants, lequel (lesquels) devez-vous prescrire obligatoirement &agrave; ce stade de la prise en charge ?",
            item: '224',
            knowledge: {
              knowledge: 'N224N05',
              rank: 'A',
              type: 'Diagnostic positif',
              label:
                "Connaître l'évaluation initiale d'un patient atteint d'HTA",
              item: {
                item: 224,
                short_title: 'Hypertension artérielle',
                title: "Hypertension artérielle de l'adulte et de l’enfant.",
                tag_and_title:
                  "N224: Hypertension artérielle de l'adulte et de l’enfant.",
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: [],
            correction:
              "Question classique sur le bilan initial syst&eacute;matique d'une HTA.",

            coefficient: 1,
            lessons: ['bilan_hta'],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 13,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912042,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement:
                  '&eacute;chographie Doppler des art&egrave;res r&eacute;nales',
                state: 'F',
                correction:
                  "L'&eacute;chographie Doppler des art&egrave;res r&eacute;nales n'est pas syst&eacute;matique dans le bilan initial d'une HTA d'une part. D'autre part, elle est seulement indiqu&eacute; en cas de suspicion d'HTA r&eacute;novasculaire, ce qui est le cas pour :<br />\r\n<ul>\r\n<li>une HTA avant 30 ans notamment chez la jeune femme (dysplasie fibromusculaire) ;</li>\r\n<li>une HTA r&eacute;sistante ;</li>\r\n<li>une HTA associ&eacute;e &agrave; une hypokali&eacute;mie d'origine r&eacute;nale (avec kaliur&egrave;se sup&eacute;rieure &agrave; 30 mmol/24 h) ;</li>\r\n<li>un contexte ath&eacute;romateux symptomatique (carotide, coronaire, membres inf&eacute;rieurs) et/ou un souffle abdominal ;</li>\r\n<li>un OAP r&eacute;cidivant malgr&eacute; FEVG pr&eacute;serv&eacute;e (&laquo; OAP flash  &raquo;) ;</li>\r\n<li>une insuffisance r&eacute;nale chronique m&ecirc;me mod&eacute;r&eacute;e</li>\r\n<li>une insuffisance r&eacute;nale aigu&euml; pr&eacute;cipit&eacute;e par les IEC/ARA 2</li>\r\n</ul>",
              },
              answer: false,
            },
            {
              id: 106912041,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement:
                  '&eacute;chographie Doppler des troncs supra-aortiques',
                state: 'F',
                correction:
                  "L'&eacute;chographie Doppler des troncs supra-aortiques n'est pas syst&eacute;matique et est indiqu&eacute;e en cas de sympt&ocirc;mes &eacute;vocateurs (AIT, AVC) ou de terrain ath&eacute;romateux, pour un bilan g&eacute;n&eacute;ral.",
              },
              answer: false,
            },
            {
              id: 106912044,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement:
                  'bilan lipidique (cholest&eacute;rol total, HDL, LDL, triglyc&eacute;rides)',
                state: 'V',
                correction:
                  'Le bilan lipidique fait partie du bilan de base et permet de rechercher les facteurs de risque cardiovasculaire dans une d&eacute;marche de pr&eacute;vention primaire de la maladie ath&eacute;romateuse.',
              },
              answer: false,
            },
            {
              id: 106912045,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement:
                  'bandelette urinaire (h&eacute;maturie, prot&eacute;inurie)',
                state: 'V',
                correction:
                  "La recherche d'une h&eacute;maturie et d'une prot&eacute;inurie permet de rechercher une HTA d'origine glom&eacute;rulaire (diab&egrave;te, maladie de Berger, ...).",
              },
              answer: false,
            },
            {
              id: 106912043,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement: 'scanner surr&eacute;nalien',
                state: 'F',
                correction:
                  "Le scanner surr&eacute;nalien est indiqu&eacute; en cas d'HTA avec hypokali&eacute;mie et d'&eacute;l&eacute;vation de l'aldost&eacute;rone avec une r&eacute;nine normale, ce qui sugg&egrave;re un hyperaldost&eacute;ronisme primitif (premi&egrave;re cause d'HTA secondaire).<br />Ce scanner permet ainsi de rechercher une hyperplasie surr&eacute;nalienne ou un ad&eacute;nome de Conn.",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035302,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 14,
            statement:
              "Le bilan initial de l'HTA &eacute;tant normal &agrave; part une &eacute;l&eacute;vation isol&eacute;e du LDL-cholest&eacute;rol &agrave; 1,8 g/L, le diagnostic d&rsquo;HTA<br />essentielle est pour l&rsquo;instant retenu.<br />Apr&egrave;s 3 mois de r&egrave;gles hygi&eacute;no-di&eacute;t&eacute;tiques correctement suivies, il conserve une HTA de grade 2 d&rsquo;apr&egrave;s ses relev&eacute;s d&rsquo;auto-mesure. Quelles sont les possibilit&eacute;s th&eacute;rapeutiques recommand&eacute;es ?",
            item: '224',
            knowledge: {
              knowledge: 'N224N14',
              rank: 'A',
              type: 'Prise en charge',
              label:
                "Connaître la stratégie du traitement médicamenteux de l'HTA",
              item: {
                item: 224,
                short_title: 'Hypertension artérielle',
                title: "Hypertension artérielle de l'adulte et de l’enfant.",
                tag_and_title:
                  "N224: Hypertension artérielle de l'adulte et de l’enfant.",
              },
              see_also_items: [
                {
                  item: 330,
                  short_title: 'Classes médicamenteuses les plus courantes',
                  title:
                    "Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                  tag_and_title:
                    "N330: Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                },
              ],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['TTT'],
            correction:
              "La r&eacute;ponse se base sur le r&eacute;f&eacute;rentiel de m&eacute;decine cardiovasculaire qui tire ses informations des recommandations de la HAS (2016) et de l'ESC (2018).<br />La prise en charge de l'hypertension art&eacute;rielle est importante &agrave; conna&icirc;tre : diagnostic, bilan initial, prise en charge non m&eacute;dicamenteuse et m&eacute;dicamenteuse.<br />Ici, le patient reste hypertendu malgr&eacute; 3 mois de r&egrave;gles hygi&eacute;no-di&eacute;t&eacute;tiques correctement suivies.<br /><br />Il faut donc d&eacute;buter un traitement m&eacute;dicamenteux de l'HTA.<br /><br />Les 5 classes th&eacute;rapeutiques possibles en premi&egrave;re intention sont les inhibiteurs calciques, les IEC, ARA2, les &beta;-bloquants et les diur&eacute;tiques thiazidiques (DTh).<br /><br />Selon les donn&eacute;es de la litt&eacute;rature, les b&ecirc;tabloquants apparaissent moins efficaces que les 4 autres classes pour la pr&eacute;vention des AVC et on ne les utilise plus en premi&egrave;re intention, surtout en cas de facteurs de risque m&eacute;taboliques (ob&eacute;sit&eacute; abdominale, glyc&eacute;mie &agrave; jeun limite), tout comme pour les thiazidiques. N&eacute;anmoins, le patient n'a pas de syndrome m&eacute;tabolique car hormis l'HTA, le reste du bilan (sauf le LDL qui ne fait pas partie de la d&eacute;finition) est normal.",

            coefficient: 1,
            lessons: ['ttt_hta'],
            similar_questions: [
              {
                id: 5670,
                group: {
                  id: 226,
                  composition_unit: undefined,
                  collection: 'CCB 2016',
                  is_free: false,
                  type: 'DP',
                  num: 4,
                  median: null,
                  denom: null,
                  thumbnail:
                    "Diagnostic et prise en charge des complications d'une obésité morbide chez une femme de 38 ans (dyslipidémie, hypertension artérielle, apnées obstructives du sommeil), modifications thérapeutiques du mode de vie, chirurgie bariatrique et suivi.",
                  number_of_questions: 15,
                  specialty1: 'EDN',
                  specialty2: null,
                  last_training: 1683783,
                  last_training_date: '2023-10-11T11:06:54.647323+02:00',
                  grade: 11.5,
                  total_coefficient: 15,
                },
                type: 'QRM',
                num: 2,
                statement:
                  'Quelle(s) est (sont) la (les) modification(s) th&eacute;rapeutique(s) m&eacute;dicamenteuses la (les) plus adapt&eacute;e(s) pour traiter l&rsquo;HTA de cette patiente ?',
                item: '224',
                knowledge: {
                  knowledge: 'N224N14',
                  rank: 'A',
                  type: 'Prise en charge',
                  label:
                    "Connaître la stratégie du traitement médicamenteux de l'HTA",
                  item: {
                    item: 224,
                    short_title: 'Hypertension artérielle',
                    title:
                      "Hypertension artérielle de l'adulte et de l’enfant.",
                    tag_and_title:
                      "N224: Hypertension artérielle de l'adulte et de l’enfant.",
                  },
                  see_also_items: [
                    {
                      item: 330,
                      short_title: 'Classes médicamenteuses les plus courantes',
                      title:
                        "Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                      tag_and_title:
                        "N330: Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                    },
                  ],
                },
                specialty1: 'EDN',
                specialty2: null,
                categories: ['TTT'],
                items_qcm: [
                  {
                    num: 4,
                    statement:
                      'Associer un antialdost&eacute;rone au valsartan',
                  },
                  {
                    num: 2,
                    statement: 'Associer un anticalcique au valsartan',
                  },
                  {
                    num: 5,
                    statement: 'Remplacer le valsartan par un IEC',
                  },
                  {
                    num: 1,
                    statement:
                      'Associer un diur&eacute;tique thiazidique au valsartan',
                  },
                  {
                    num: 3,
                    statement: 'Associer un b&ecirc;ta-bloquant au valsartan',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 14,
                group: {
                  id: 1,
                  composition_unit: undefined,
                  collection: 'ECNi 2016',
                  is_free: false,
                  type: 'DP',
                  num: 1,
                  median: 10.6,
                  denom: 15,
                  thumbnail:
                    "Palpitations découvertes dans le cadre d'une consultation pour certificat de non contre-indication à la pratique sportive d'un homme de 20 ans. Exploration aboutissant au diagnostic de communication inter-atriale qui sera fermée. Diagnostic et prise en charge d'une tachycardie jonctionnelle paroxystique, puis d'une HTA à distance.",
                  number_of_questions: 15,
                  specialty1: 'MCV',
                  specialty2: null,
                  last_training: 1736448,
                  last_training_date: '2024-02-09T12:28:11.285664+01:00',
                  grade: 0,
                  total_coefficient: 15,
                },
                type: 'QRM',
                num: 14,
                statement:
                  "Le bilan initial de l'HTA &eacute;tant normal &agrave; part une &eacute;l&eacute;vation isol&eacute;e du LDL-cholest&eacute;rol &agrave; 1,8 g/L, le diagnostic d&rsquo;HTA<br />essentielle est pour l&rsquo;instant retenu.<br />Apr&egrave;s 3 mois de r&egrave;gles hygi&eacute;no-di&eacute;t&eacute;tiques correctement suivies, il conserve une HTA de grade 2 d&rsquo;apr&egrave;s ses relev&eacute;s d&rsquo;auto-mesure. Quelles sont les possibilit&eacute;s th&eacute;rapeutiques recommand&eacute;es ?",
                item: '224',
                knowledge: {
                  knowledge: 'N224N14',
                  rank: 'A',
                  type: 'Prise en charge',
                  label:
                    "Connaître la stratégie du traitement médicamenteux de l'HTA",
                  item: {
                    item: 224,
                    short_title: 'Hypertension artérielle',
                    title:
                      "Hypertension artérielle de l'adulte et de l’enfant.",
                    tag_and_title:
                      "N224: Hypertension artérielle de l'adulte et de l’enfant.",
                  },
                  see_also_items: [
                    {
                      item: 330,
                      short_title: 'Classes médicamenteuses les plus courantes',
                      title:
                        "Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                      tag_and_title:
                        "N330: Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                    },
                  ],
                },
                specialty1: 'MCV',
                specialty2: null,
                categories: ['TTT'],
                items_qcm: [
                  {
                    num: 1,
                    statement:
                      'poursuite des r&egrave;gles hygi&eacute;no-di&eacute;t&eacute;tiques seules',
                  },
                  {
                    num: 2,
                    statement:
                      'monoth&eacute;rapie par antihypertenseur central',
                  },
                  {
                    num: 3,
                    statement: 'monoth&eacute;rapie par alpha-bloquants',
                  },
                  {
                    num: 4,
                    statement:
                      'monoth&eacute;rapie par diur&eacute;tique thiazidique',
                  },
                  {
                    num: 5,
                    statement:
                      'monoth&eacute;rapie par antagoniste des r&eacute;cepteurs &agrave; l&rsquo;angiotensine 2 (ARA2)',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 5228,
                group: {
                  id: 195,
                  composition_unit: undefined,
                  collection: 'CCB 2017',
                  is_free: false,
                  type: 'DP',
                  num: 1,
                  median: null,
                  denom: null,
                  thumbnail:
                    "D&eacute;marche diagnostique devant une dyspn&eacute;e d'effort chez un patient de 36 ans conduisant au diagnostic de broncho-pneumopathie chronique obstructive. Epid&eacute;miologie &eacute;tiologique, prise en charge th&eacute;rapeutique de la broncho-pneumopathie chronique obstructive et de ses complications. D&eacute;finition du pneumothorax et prise en charge th&eacute;rapeutique.",
                  number_of_questions: 15,
                  specialty1: 'PNE',
                  specialty2: null,
                  last_training: 1736046,
                  last_training_date: '2023-11-23T23:14:59.314289+01:00',
                  grade: 0,
                  total_coefficient: 15,
                },
                type: 'QRM',
                num: 12,
                statement:
                  "Votre patient pr&eacute;sente une hypertension art&eacute;rielle. Parmi les classes th&eacute;rapeutiques suivantes, laquelle (lesquelles) sont des traitements de premi&egrave;re intention de l'HTA chez ce patient ?",
                item: '224',
                knowledge: {
                  knowledge: 'N224N14',
                  rank: 'A',
                  type: 'Prise en charge',
                  label:
                    "Connaître la stratégie du traitement médicamenteux de l'HTA",
                  item: {
                    item: 224,
                    short_title: 'Hypertension artérielle',
                    title:
                      "Hypertension artérielle de l'adulte et de l’enfant.",
                    tag_and_title:
                      "N224: Hypertension artérielle de l'adulte et de l’enfant.",
                  },
                  see_also_items: [
                    {
                      item: 330,
                      short_title: 'Classes médicamenteuses les plus courantes',
                      title:
                        "Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                      tag_and_title:
                        "N330: Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                    },
                  ],
                },
                specialty1: 'PNE',
                specialty2: null,
                categories: ['TTT'],
                items_qcm: [
                  {
                    num: 2,
                    statement: 'inhibiteurs calciques',
                  },
                  {
                    num: 4,
                    statement: 'inhibiteurs de l&rsquo;enzyme de conversion',
                  },
                  {
                    num: 5,
                    statement: "diur&eacute;tiques de l'anse",
                  },
                  {
                    num: 1,
                    statement: 'b&eacute;ta-bloquants',
                  },
                  {
                    num: 3,
                    statement: 'diur&eacute;tiques thiazidiques',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 2629,
                group: {
                  id: 135,
                  composition_unit: undefined,
                  collection: 'ECNi 2020',
                  is_free: false,
                  type: 'DP',
                  num: 9,
                  median: 9.7,
                  denom: 17,
                  thumbnail:
                    "Exploration d'anomalies du bilan urinaire chez un homme de 58 ans n&eacute;o-diab&eacute;tique r&eacute;v&eacute;lant une tumeur v&eacute;sicale et une n&eacute;phropathie diab&eacute;tique. &Eacute;quilibration du diab&egrave;te, de l'HTA et prise en charge de l'insuffisance r&eacute;nale chronique avec d&eacute;compensation aigu&euml; intercurrente.",
                  number_of_questions: 17,
                  specialty1: 'NEP',
                  specialty2: null,
                  last_training: 1680500,
                  last_training_date: '2023-10-10T21:21:13.446566+02:00',
                  grade: 8.899999999999999,
                  total_coefficient: 17,
                },
                type: 'QRM',
                num: 11,
                statement:
                  'Parmi les mol&eacute;cules suivantes, lesquelles peuvent &ecirc;tre prescrites en premi&egrave;re intention chez ce patient pour son hypertension art&eacute;rielle ? (une ou plusieurs r&eacute;ponses exactes)',
                item: '224',
                knowledge: {
                  knowledge: 'N224N16',
                  rank: 'B',
                  type: 'Prise en charge',
                  label:
                    'Connaître les situations cliniques particulières pouvant orienter le choix du traitement anti-hypertenseur',
                  item: {
                    item: 224,
                    short_title: 'Hypertension artérielle',
                    title:
                      "Hypertension artérielle de l'adulte et de l’enfant.",
                    tag_and_title:
                      "N224: Hypertension artérielle de l'adulte et de l’enfant.",
                  },
                  see_also_items: [],
                },
                specialty1: 'NEP',
                specialty2: null,
                categories: ['TTT'],
                items_qcm: [
                  {
                    num: 1,
                    statement: "inhibiteur de l'enzyme de conversion",
                  },
                  {
                    num: 2,
                    statement:
                      "antagoniste des r&eacute;cepteurs de l'angiotensine II",
                  },
                  {
                    num: 5,
                    statement: 'b&ecirc;ta-bloquant',
                  },
                  {
                    num: 4,
                    statement: 'thiazidique',
                  },
                  {
                    num: 3,
                    statement:
                      "association antagoniste des r&eacute;cepteurs de l'angiotensine II-thiazidique",
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 7830,
                group: {
                  id: 354,
                  composition_unit: undefined,
                  collection: 'ECNi 2022',
                  is_free: false,
                  type: 'DP',
                  num: 6,
                  median: 9.5,
                  denom: 14,
                  thumbnail:
                    "Syndrome d'apnées obstructives du sommeil chez un patient de 48 ans. Bilan étiologique devant un hypogonadisme révélant une acromégalie. Découverte de diabète de type 2. Gestion d'un traitement anti-diabétique chez un travailleur à risque. Modification du traitement antihypertenseur.",
                  number_of_questions: 14,
                  specialty1: 'EDN',
                  specialty2: null,
                  last_training: 1735979,
                  last_training_date: '2023-11-21T21:06:29.331584+01:00',
                  grade: 0,
                  total_coefficient: 14,
                },
                type: 'QRM',
                num: 14,
                statement:
                  "Il se plaint d'oedèmes des membres inférieurs qu'il attribue à l'amlodipine. Il souhaite arrêter ce traitement.<br />Quelles classes thérapeutiques peuvent-être indiquées chez lui en première intention ? (une ou plusieurs réponses exactes)",
                item: '224',
                knowledge: {
                  knowledge: 'N224N16',
                  rank: 'B',
                  type: 'Prise en charge',
                  label:
                    'Connaître les situations cliniques particulières pouvant orienter le choix du traitement anti-hypertenseur',
                  item: {
                    item: 224,
                    short_title: 'Hypertension artérielle',
                    title:
                      "Hypertension artérielle de l'adulte et de l’enfant.",
                    tag_and_title:
                      "N224: Hypertension artérielle de l'adulte et de l’enfant.",
                  },
                  see_also_items: [],
                },
                specialty1: 'EDN',
                specialty2: null,
                categories: ['TTT'],
                items_qcm: [
                  {
                    num: 1,
                    statement: 'antihypertenseur central',
                  },
                  {
                    num: 2,
                    statement: "inhibiteur de l'enzyme de conversion",
                  },
                  {
                    num: 4,
                    statement: 'beta-bloquant',
                  },
                  {
                    num: 3,
                    statement: 'sartan',
                  },
                  {
                    num: 5,
                    statement: 'diurétique thiazidique',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
            ],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 14,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912046,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement:
                  'poursuite des r&egrave;gles hygi&eacute;no-di&eacute;t&eacute;tiques seules',
                state: 'F',
                correction:
                  "Elles n'ont pas suffit pendant 3 mois, il n'y a pas de cause secondaire suspect&eacute;e. On ne peut pas poursuivre cette prise en charge.",
              },
              answer: false,
            },
            {
              id: 106912047,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement: 'monoth&eacute;rapie par antihypertenseur central',
                state: 'F',
                correction:
                  "Ce n'est pas un traitement utilis&eacute; dans l'HTA sauf chez la femme enceinte.",
              },
              answer: false,
            },
            {
              id: 106912048,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement: 'monoth&eacute;rapie par alpha-bloquants',
                state: 'F',
                correction:
                  "Les alpha-bloquants ne sont pas un traitement de fond de l'hypertension art&eacute;rielle mais peuvent &ecirc;tre utilis&eacute;s en premi&egrave;re intention dans les urgences hypertensives.",
              },
              answer: false,
            },
            {
              id: 106912049,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement:
                  'monoth&eacute;rapie par diur&eacute;tique thiazidique',
                state: 'V',
                correction:
                  "Cette proposition est probablement vraie pour deux raisons : la question est au pluriel et elle interroge sur les possibilit&eacute;s th&eacute;rapeutiques. Les thiazidiques font partie de l'arsenal th&eacute;rapeutique en premi&egrave;re intention, et le patient ne pr&eacute;sente pas de contre-indication non plus bien qu'il soit pr&eacute;f&eacute;rable de choisir une autre mol&eacute;cule au vu de son &eacute;tat m&eacute;tabolique.",
              },
              answer: false,
            },
            {
              id: 106912050,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement:
                  'monoth&eacute;rapie par antagoniste des r&eacute;cepteurs &agrave; l&rsquo;angiotensine 2 (ARA2)',
                state: 'V',
                correction:
                  "Il s'agit d'un traitement de premi&egrave;re ligne de l'hypertension art&eacute;rielle.",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035303,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 15,
            statement:
              'Un an plus tard, le patient re&ccedil;oit une association de cand&eacute;sartan, hydrochlorothiazide et amlodipine. La pression art&eacute;rielle moyenne des 24 heures mesur&eacute;e &agrave; la MAPA est &agrave; 145/95 mm Hg.<br />Que faut-il rechercher ?',
            item: '224',
            knowledge: {
              knowledge: 'N224N09',
              rank: 'A',
              type: 'Étiologies',
              label: "Connaître les principales causes d'HTA secondaire",
              item: {
                item: 224,
                short_title: 'Hypertension artérielle',
                title: "Hypertension artérielle de l'adulte et de l’enfant.",
                tag_and_title:
                  "N224: Hypertension artérielle de l'adulte et de l’enfant.",
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: [],
            correction:
              "Il faut comprendre dans l'&eacute;nonc&eacute; que l'on se trouve dans une situation d'HTA r&eacute;sistante, c'est-&agrave;-dire de persistance de chiffres anormaux (ici, en MAPA) malgr&eacute; une trith&eacute;rapie incluant un diur&eacute;tique thiazidique. Il faut ainsi rechercher une cause secondaire.",

            coefficient: 1,
            lessons: ['hta_secondaire'],
            similar_questions: [
              {
                id: 15,
                group: {
                  id: 1,
                  composition_unit: undefined,
                  collection: 'ECNi 2016',
                  is_free: false,
                  type: 'DP',
                  num: 1,
                  median: 10.6,
                  denom: 15,
                  thumbnail:
                    "Palpitations découvertes dans le cadre d'une consultation pour certificat de non contre-indication à la pratique sportive d'un homme de 20 ans. Exploration aboutissant au diagnostic de communication inter-atriale qui sera fermée. Diagnostic et prise en charge d'une tachycardie jonctionnelle paroxystique, puis d'une HTA à distance.",
                  number_of_questions: 15,
                  specialty1: 'MCV',
                  specialty2: null,
                  last_training: 1736448,
                  last_training_date: '2024-02-09T12:28:11.285664+01:00',
                  grade: 0,
                  total_coefficient: 15,
                },
                type: 'QRM',
                num: 15,
                statement:
                  'Un an plus tard, le patient re&ccedil;oit une association de cand&eacute;sartan, hydrochlorothiazide et amlodipine. La pression art&eacute;rielle moyenne des 24 heures mesur&eacute;e &agrave; la MAPA est &agrave; 145/95 mm Hg.<br />Que faut-il rechercher ?',
                item: '224',
                knowledge: {
                  knowledge: 'N224N09',
                  rank: 'A',
                  type: 'Étiologies',
                  label: "Connaître les principales causes d'HTA secondaire",
                  item: {
                    item: 224,
                    short_title: 'Hypertension artérielle',
                    title:
                      "Hypertension artérielle de l'adulte et de l’enfant.",
                    tag_and_title:
                      "N224: Hypertension artérielle de l'adulte et de l’enfant.",
                  },
                  see_also_items: [],
                },
                specialty1: 'MCV',
                specialty2: null,
                categories: [],
                items_qcm: [
                  {
                    num: 1,
                    statement: 'un syndrome d&rsquo;apn&eacute;e du sommeil',
                  },
                  {
                    num: 2,
                    statement: 'une hyperthyro&iuml;die',
                  },
                  {
                    num: 3,
                    statement: "une maladie d'Addison",
                  },
                  {
                    num: 4,
                    statement: 'une hypercalc&eacute;mie',
                  },
                  {
                    num: 5,
                    statement: 'un hyperaldost&eacute;ronisme secondaire',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 7603,
                group: {
                  id: 339,
                  composition_unit: undefined,
                  collection: 'ECNi 2022',
                  is_free: false,
                  type: 'DP',
                  num: 4,
                  median: 9,
                  denom: 13,
                  thumbnail:
                    "Claudication intermittente du membre inférieur droit chez un homme de 72 ans aux multiples facteurs de risque cardiovasculaires conduisant au diagnostic d'artériopathie oblitérante des membres inférieurs. Prise en charge et suivi du risque cardiovasculaire et particulièrement de l'hypertension artérielle du patient.",
                  number_of_questions: 13,
                  specialty1: 'MCV',
                  specialty2: null,
                  last_training: 1736412,
                  last_training_date: '2023-12-21T09:22:21.328293+01:00',
                  grade: 0,
                  total_coefficient: 13,
                },
                type: 'QRM',
                num: 12,
                statement:
                  "Le patient est actuellement traité par inhibiteur de l'enzyme de conversion et inhibiteur calcique à visée antihypertensive. L'automesure met en évidence une pression artérielle en moyenne à 158/72 mmHg.<br />Que recherchez-vous à l'origine de cette inefficacité thérapeutique chez ce patient ? (une ou plusieurs réponses exactes)",
                item: '224',
                knowledge: {
                  knowledge: 'N224N12',
                  rank: 'B',
                  type: 'Définition',
                  label: "Connaître la définition d'une HTA résistante",
                  item: {
                    item: 224,
                    short_title: 'Hypertension artérielle',
                    title:
                      "Hypertension artérielle de l'adulte et de l’enfant.",
                    tag_and_title:
                      "N224: Hypertension artérielle de l'adulte et de l’enfant.",
                  },
                  see_also_items: [],
                },
                specialty1: 'MCV',
                specialty2: null,
                categories: [],
                items_qcm: [
                  {
                    num: 4,
                    statement: "un syndrome d'apnée du sommeil",
                  },
                  {
                    num: 1,
                    statement:
                      'une dysplasie fibromusculaire des artères rénales',
                  },
                  {
                    num: 3,
                    statement: 'une inobservance thérapeutique',
                  },
                  {
                    num: 5,
                    statement:
                      "une mauvaise utilisation de l'appareil à automesure tensionnelle",
                  },
                  {
                    num: 2,
                    statement: "un abus d'alcool",
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
            ],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 15,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912051,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement: 'un syndrome d&rsquo;apn&eacute;e du sommeil',
                state: 'V',
                correction:
                  'Le SAOS est une cause fr&eacute;quente d\'HTA r&eacute;sistante. Il est responsable du profil dit "non dipper" en MAPA (PA &ge; 120/70 mmHg pendant le sommeil).',
              },
              answer: false,
            },
            {
              id: 106912052,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement: 'une hyperthyro&iuml;die',
                state: 'V',
                correction:
                  "L'hyperthyro&iuml;die est responsable d'une HTA systolique, tandis que l'hypothyro&iuml;die est responsable d'une HTA diastolique (cette derni&egrave;re information a &eacute;t&eacute; retir&eacute;e du CEEDMM en 2019).",
              },
              answer: false,
            },
            {
              id: 106912053,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement: "une maladie d'Addison",
                state: 'F',
                correction:
                  "La maladie d'Addison se traduit par une hypotension orthostatique puis une hypotension art&eacute;rielle par hypoaldost&eacute;ronisme, responsable ainsi d'une perte r&eacute;nale de sel.",
              },
              answer: false,
            },
            {
              id: 106912054,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement: 'une hypercalc&eacute;mie',
                state: 'V',
                correction:
                  "Il s'agit d'une cause d'HTA notamment par vasoconstriction, le calcium favorisant la d&eacute;polarisation au niveau des fibres musculaires lisses. Le r&eacute;f&eacute;rentiel d'endocrinologie cite bien l'HTA comme manifestation d'une hypercalc&eacute;mie, et l'hyperparathyro&iuml;die primitive comme cause d'HTA.",
              },
              answer: false,
            },
            {
              id: 106912055,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement: 'un hyperaldost&eacute;ronisme secondaire',
                state: 'V',
                correction:
                  "L'hyperaldost&eacute;ronisme secondaire peut donner une HTA en cas de tumeur s&eacute;cr&eacute;tant de la r&eacute;nine, de st&eacute;nose art&eacute;rielle r&eacute;nale ou de petit rein endocrine.",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
      ],
      grade: 15,
      total_coefficient: 15,
    },
    {
      id: 1736447,
      group: {
        description: '',
        abstract: '',
        article: '',
        composition_unit: undefined,
        collection: 'ECNi 2016',
        type: 'DP',
        num: 18,
        specialty1: 'MCV',
        specialty2: null,
        statement:
          'Un homme âgé de 77 ans est hospitalisé pour la mise en place d’une prothèse de hanche gauche pour coxarthrose. Il a pour antécédents une hypertension artérielle traitée par amlodipine, une hypercholestérolémie traitée par simvastatine, un adénome prostatique, des varices des deux membres inférieurs et une fracture de clavicule 20 ans auparavant. Poids = 73 kg, Taille = 1,72 m, PA = 140/78 mmHg. Le bilan biologique post-opératoire montre : hémoglobine 10,5 g/dl, globules blancs 11 G/L, plaquettes 400 G/L, sodium 136 mml/L, potassium 3,6 mml/L, créatinine 79 µml/L.',
        thumbnail:
          'Risque thrombo-embolique et thrombose veineuse profonde post-opératoire chez un homme de 77 ans, thérapeutique et insuffisance veineuse sur syndrome post-thrombotique séquellaire.',
        analysis:
          "Dossier de médecine vasculaire qui semble parfois être une succession de questions isolées, bien que certaines vignettes cliniques doivent être interprétées et que certaines modalités thérapeutiques nécessitent une bonne compréhension du contexte clinique.<br />Contrairement aux dossiers de cardiologie en 2016 et 2017 ou de médecine cardiovasculaire en 2019 et 2020 faisant la part belle à la sémiologie, ce dossier est très riche en questions de thérapeutiques, probablement en raison du consensus autour de la prise en charge de la maladie thrombo-embolique veineuse (MTEV).<br /><br />Le dossier nécessite d'être vraiment au clair sur le maniement des anticoagulants, qui est un grand classique aux ECNi.",
        median: undefined,
        denom: undefined,
        sources: [],
        books: ['MCA', 'MVA'],
      },
      custom_qi: false,
      num: 1,
      finished: true,
      correction_questions: [
        {
          id: 22035304,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 1,
            statement:
              'Vous &eacute;valuez son risque de maladie thrombo-embolique veineuse. Quel(s) facteur(s) de risque de maladie thromboembolique identifiez-vous chez lui ?',
            item: '226',
            knowledge: {
              knowledge: 'N226N02',
              rank: 'A',
              type: 'Étiologies',
              label:
                'Connaître les situations qui favorisent la maladie thromboembolique veineuse (MTEV) : circonstances de survenue, facteurs favorisants temporaires et persistants',
              item: {
                item: 226,
                short_title: 'Maladie thrombo-embolique veineuse ',
                title:
                  'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                tag_and_title:
                  'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['EPI'],
            correction:
              "Question somme toute classique sur les facteurs de risque de MTEV.<br /><br />Il existe des l&eacute;gers d&eacute;saccords entre les r&eacute;f&eacute;rentiels des ECNi (m&eacute;decine vasculaire et pneumologie d&rsquo;un c&ocirc;t&eacute;, m&eacute;decine cardiovasculaire de l&rsquo;autre) qui tirent leurs informations de sources diff&eacute;rentes. Il est peu vraisemblable d'&ecirc;tre interrog&eacute; sur ces discordances aux ECNi qui font appel &agrave; des choses plus consensuelles.<br /><br />On peut voir ici que les r&eacute;dacteurs ont notamment utilis&eacute; des leurres classiques et simples &agrave; d&eacute;jouer, &agrave; savoir les FRCV (<em>e.g.</em> HTA, dyslipid&eacute;mie) qui ne sont pas des facteurs de risque thromboembolique veineux (RTEV).",

            coefficient: 1,
            lessons: ['fdr_mtev'],
            similar_questions: [
              {
                id: 16,
                group: {
                  id: 2,
                  composition_unit: undefined,
                  collection: 'ECNi 2016',
                  is_free: false,
                  type: 'DP',
                  num: 18,
                  median: null,
                  denom: null,
                  thumbnail:
                    'Risque thrombo-embolique et thrombose veineuse profonde post-opératoire chez un homme de 77 ans, thérapeutique et insuffisance veineuse sur syndrome post-thrombotique séquellaire.',
                  number_of_questions: 15,
                  specialty1: 'MCV',
                  specialty2: null,
                  last_training: 1736447,
                  last_training_date: '2024-02-09T12:28:11.284004+01:00',
                  grade: 0,
                  total_coefficient: 15,
                },
                type: 'QRM',
                num: 1,
                statement:
                  'Vous &eacute;valuez son risque de maladie thrombo-embolique veineuse. Quel(s) facteur(s) de risque de maladie thromboembolique identifiez-vous chez lui ?',
                item: '226',
                knowledge: {
                  knowledge: 'N226N02',
                  rank: 'A',
                  type: 'Étiologies',
                  label:
                    'Connaître les situations qui favorisent la maladie thromboembolique veineuse (MTEV) : circonstances de survenue, facteurs favorisants temporaires et persistants',
                  item: {
                    item: 226,
                    short_title: 'Maladie thrombo-embolique veineuse ',
                    title:
                      'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                    tag_and_title:
                      'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                  },
                  see_also_items: [],
                },
                specialty1: 'MCV',
                specialty2: null,
                categories: ['EPI'],
                items_qcm: [
                  {
                    num: 4,
                    statement: '&acirc;ge',
                  },
                  {
                    num: 1,
                    statement: 'hypertension art&eacute;rielle',
                  },
                  {
                    num: 2,
                    statement: 'ant&eacute;c&eacute;dent de fracture',
                  },
                  {
                    num: 3,
                    statement: 'ad&eacute;nome prostatique',
                  },
                  {
                    num: 5,
                    statement: 'hypercholest&eacute;rol&eacute;mie',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 2326,
                group: {
                  id: 122,
                  composition_unit: undefined,
                  collection: 'ECNi 2019',
                  is_free: false,
                  type: 'DP',
                  num: 15,
                  median: 10.9,
                  denom: 15,
                  thumbnail:
                    "Exploration d'une hémoglobinémie supra-normale découverte chez un homme de 45 ans à l'occasion d'un don du sang, révélant une polyglobulie de Vaquez à prendre en charge. Diagnostic d'une complication par TVP puis prise en charge anticoagulante et enfin transformation en leucémie aiguë à distance.",
                  number_of_questions: 15,
                  specialty1: 'HEM',
                  specialty2: null,
                  last_training: 1670577,
                  last_training_date: '2023-10-10T01:44:07.422907+02:00',
                  grade: 11.4,
                  total_coefficient: 15,
                },
                type: 'QRM',
                num: 9,
                statement:
                  'Quels sont chez ce patient les facteurs de risque de phl&eacute;bite? (une ou plusieurs r&eacute;ponses exactes)',
                item: '226',
                knowledge: {
                  knowledge: 'N226N02',
                  rank: 'A',
                  type: 'Étiologies',
                  label:
                    'Connaître les situations qui favorisent la maladie thromboembolique veineuse (MTEV) : circonstances de survenue, facteurs favorisants temporaires et persistants',
                  item: {
                    item: 226,
                    short_title: 'Maladie thrombo-embolique veineuse ',
                    title:
                      'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                    tag_and_title:
                      'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                  },
                  see_also_items: [],
                },
                specialty1: 'HEM',
                specialty2: null,
                categories: ['EPI'],
                items_qcm: [
                  {
                    num: 2,
                    statement: 'antiagr&eacute;gant plaquettaire au long cours',
                  },
                  {
                    num: 3,
                    statement: 'HTA',
                  },
                  {
                    num: 5,
                    statement: 'voyage en avion',
                  },
                  {
                    num: 1,
                    statement: 'ob&eacute;sit&eacute;',
                  },
                  {
                    num: 4,
                    statement: 'hypercholest&eacute;rol&eacute;mie',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 4492,
                group: {
                  id: 6,
                  composition_unit: undefined,
                  collection: 'ECNi 2020',
                  is_free: false,
                  type: 'QI',
                  num: 1,
                  median: 75,
                  denom: 120,
                  thumbnail: '',
                  number_of_questions: 120,
                  specialty1: null,
                  specialty2: null,
                  last_training: null,
                  last_training_date: null,
                  grade: null,
                  total_coefficient: null,
                },
                type: 'QRM',
                num: 66,
                statement:
                  'Chez une patiente de 40 ans ayant fait une thrombose veineuse profonde, quels facteurs de risques de thrombose veineuse cherchez-vous ? (une ou plusieurs r&eacute;ponses possibles)',
                item: '226',
                knowledge: {
                  knowledge: 'N226N02',
                  rank: 'A',
                  type: 'Étiologies',
                  label:
                    'Connaître les situations qui favorisent la maladie thromboembolique veineuse (MTEV) : circonstances de survenue, facteurs favorisants temporaires et persistants',
                  item: {
                    item: 226,
                    short_title: 'Maladie thrombo-embolique veineuse ',
                    title:
                      'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                    tag_and_title:
                      'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                  },
                  see_also_items: [],
                },
                specialty1: 'MCV',
                specialty2: null,
                categories: ['EPI'],
                items_qcm: [
                  {
                    num: 2,
                    statement: 'Hypertension art&eacute;rielle',
                  },
                  {
                    num: 4,
                    statement: 'Dyslipid&eacute;mie',
                  },
                  {
                    num: 3,
                    statement: 'Voyage en avion de plus de 6 heures',
                  },
                  {
                    num: 5,
                    statement: 'Traitement oestroprogestatif',
                  },
                  {
                    num: 1,
                    statement: 'Tabagisme',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
            ],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 1,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912057,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 1,
                statement: 'hypertension art&eacute;rielle',
                state: 'F',
                correction:
                  "Il s'agit d'un facteur de risque cardiovasculaire reconnu mais sans lien avec la maladie thrombo-embolique veineuse.<br />Par ailleurs, il s'agit d'un facteur de risque cardioembolig&egrave;ne dans le score de CHA<sub>2</sub>DS<sub>2</sub>-VASc, utilis&eacute; pour la fibrillation atriale.",
              },
              answer: false,
            },
            {
              id: 106912058,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement: 'ant&eacute;c&eacute;dent de fracture',
                state: 'F',
                correction:
                  "D'une part, sa fracture est trop ancienne pour constituer un facteur de risque car elle date de 20 ans, le <em>cut-off</em> &eacute;tant consensuellement plac&eacute; &agrave; 3 mois. D'autre part, une fracture de clavicule n'est pas consid&eacute;r&eacute;e comme &agrave; risque majeur contrairement &agrave; une fracture du membre inf&eacute;rieur.",
              },
              answer: false,
            },
            {
              id: 106912059,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement: 'ad&eacute;nome prostatique',
                state: 'F',
                correction:
                  "Probablement un leurre qui ne dupe personne. Si un cancer actif (ou certaines tumeurs hormonos&eacute;cr&eacute;tantes) accro&icirc;t effectivement de mani&egrave;re significative le risque, ce n'est pas le cas de l'hypertrophie b&eacute;nigne de prostate (HBP) qui n'est pas un facteur de risque thrombo-embolique veineux.",
              },
              answer: false,
            },
            {
              id: 106912056,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement: '&acirc;ge',
                state: 'V',
                correction:
                  'L&rsquo;&acirc;ge est un facteur de risque thrombo-embolique veineux reconnu, et le cut-off cit&eacute; par le r&eacute;f&eacute;rentiel de pneumologie est &agrave; d&eacute;sormais plac&eacute; &agrave; 65 ans (pas d&rsquo;information dans les 2 autres r&eacute;f&eacute;rentiels). Il faudra tr&egrave;s certainement cocher chez un sujet de plus de 65 ans comme ici.',
              },
              answer: false,
            },
            {
              id: 106912060,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement: 'hypercholest&eacute;rol&eacute;mie',
                state: 'F',
                correction:
                  "L'hypercholest&eacute;rol&eacute;mie est un facteur de risque cardiovasculaire, mais pas un facteur de risque thrombo-embolique veineux.",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035305,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 2,
            statement:
              'Quelle(s) mesure(s) envisagez-vous chez lui pour la pr&eacute;vention de la maladie thrombo-embolique veineuse au d&eacute;cours de son intervention pour proth&egrave;se de hanche ?',
            item: '226',
            knowledge: {
              knowledge: 'N226N13',
              rank: 'A',
              type: 'Prise en charge',
              label:
                'Connaître les situations nécessitant une prévention de la MTEV',
              item: {
                item: 226,
                short_title: 'Maladie thrombo-embolique veineuse ',
                title:
                  'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                tag_and_title:
                  'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['TTT'],
            correction:
              "La prise en charge pr&eacute;ventive de la maladie thrombo-embolique veineuse en situation m&eacute;dicale ou chirurgicale est tr&egrave;s peu d&eacute;taill&eacute;e dans les r&eacute;f&eacute;rentiels des ECNi. Il est ainsi peu probable d'avoir des questions plus pouss&eacute;es &agrave; ce sujet que celle-ci ou que la question 3 ax&eacute;e sur la prise en charge m&eacute;dicamenteuse.",

            coefficient: 1,
            lessons: ['prevention_mtev'],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 2,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912063,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement: 'bas anti-thrombose',
                state: 'V',
                correction:
                  "Ils sont facilement indiqu&eacute;s. La classe de compression n'est probablement pas &agrave; conna&icirc;tre, d'autant qu'il existe 2 classifications (fran&ccedil;aise et internationale).",
              },
              answer: false,
            },
            {
              id: 106912064,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement: 'lever pr&eacute;coce',
                state: 'V',
                correction:
                  "Il faut retenir pour les ECNi que la mise en place d'une proth&egrave;se de membre inf&eacute;rieur va impliquer une remise en charge pr&eacute;coce contrairement &agrave; une ost&eacute;osynth&egrave;se qui n&eacute;cessitera une immobilisation. Cette pr&eacute;cocit&eacute; &agrave; un but fonctionnel mais aussi pr&eacute;ventif pour toutes les complications du d&eacute;cubitus (MTEV ou escarres pour ne citer que les deux plus importantes).",
              },
              answer: false,
            },
            {
              id: 106912065,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement: 'intervention sur les varices',
                state: 'F',
                correction:
                  "L'objectif d'une cure de varice n'est pas la prophylaxie de la MTEV mais avant tout la r&eacute;duction des sympt&ocirc;mes de l'insuffisance veineuse chronique (IVC) et de la g&ecirc;ne esth&eacute;tique. Une intervention sur les varices pourrait &ecirc;tre probl&eacute;matique dans les situations &agrave; risque de TVP, car on perdrait une voie de circulation du sang veineux en cas d'obstruction.",
              },
              answer: false,
            },
            {
              id: 106912062,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement:
                  '&eacute;chographie-doppler veineuse avant la sortie du patient de l&rsquo;h&ocirc;pital',
                state: 'F',
                correction:
                  "Ce n'est pas un examen &agrave; r&eacute;aliser en l'absence de point d'appel clinique. L'&eacute;cho-Doppler des membres inf&eacute;rieurs a une place bien d&eacute;termin&eacute;e dans l'arbre diagnostic de la TVP et n'est n&eacute;cessaire qu'en cas de suspicion clinique (cf. \"Question 7\"), ainsi qu'&agrave; la fin du traitement anticoagulant.",
              },
              answer: false,
            },
            {
              id: 106912061,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement: 'arr&ecirc;t de l&rsquo;amlodipine',
                state: 'F',
                correction:
                  "Leurre de mauvaise qualit&eacute;, puisqu'ils faisaient peut &ecirc;tre r&eacute;f&eacute;rence &agrave; un c&eacute;l&egrave;bre effet ind&eacute;sirable non relat&eacute; ici des dihydropyridines que sont les &oelig;d&egrave;mes des membres inf&eacute;rieurs. Ce n'est pas un facteur de risque de thrombose.",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035306,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 3,
            statement:
              'Quelle(s) mesure(s) de pr&eacute;vention pharmacologique de la maladie thrombo-embolique veineuse sont adapt&eacute;es &agrave; ce patient ?',
            item: '330',
            knowledge: {
              knowledge: 'N330N07',
              rank: 'A',
              type: 'Prise en charge',
              label:
                "Héparines : connaître les mécanismes d'action, indications, effets secondaires interactions médicamenteuses, modalités de surveillance et principales causes d'échec",
              item: {
                item: 330,
                short_title: 'Classes médicamenteuses les plus courantes',
                title:
                  "Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                tag_and_title:
                  "N330: Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['TTT'],
            correction:
              "Il faut bien lire l'&eacute;nonc&eacute; et se r&eacute;f&eacute;rer au contexte clinique de ce patient qui ne pr&eacute;sente pas d'insuffisance r&eacute;nale chronique. On demande ici les th&eacute;rapeutiques m&eacute;dicamenteuses <strong>adapt&eacute;es</strong> &agrave; ce patient pour la pr&eacute;vention de la MTEV.",

            coefficient: 1,
            lessons: ['prevention_mtev'],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 3,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912066,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement: 'aspirine',
                state: 'F',
                correction:
                  "L'aspirine n'a aucune place dans la pr&eacute;vention de la MTEV.",
              },
              answer: false,
            },
            {
              id: 106912068,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement: '&eacute;noxaparine',
                state: 'V',
                correction:
                  "L'enoxaparine, tout comme la dalt&eacute;parine, &agrave; l'AMM en tant qu'HBPM pour la pr&eacute;vention de la MTEV (contre-indication en cas de clairance de la cr&eacute;atinine &lt; 30 mL/min). Les doses ne sont pas &agrave; conna&icirc;tre en prophylaxie. La fonction r&eacute;nale de ce patient &eacute;tant normale, ce traitement est tout &agrave; fait indiqu&eacute;.",
              },
              answer: false,
            },
            {
              id: 106912069,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement: 'warfarine avec INR cible entre 2 et 3',
                state: 'F',
                correction:
                  "Les AVK n'ont pas leur place en traitement pr&eacute;ventif de la MTEV. La cible d'INR cit&eacute;e est par ailleurs une cible pour un traitement curatif.",
              },
              answer: false,
            },
            {
              id: 106912070,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement: 'h&eacute;parine calcique',
                state: 'V',
                correction:
                  "L'h&eacute;parine calcique&nbsp;est une h&eacute;parine non fractionn&eacute;e administr&eacute;e en sous-cutan&eacute;e. La fonction r&eacute;nale de ce patient &eacute;tant normale, on &eacute;vitera de prescrire des HNF en raison du risque de thrombop&eacute;nie induite par l'h&eacute;parine (TIH).",
              },
              answer: false,
            },
            {
              id: 106912067,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement: 'fondaparinux',
                state: 'V',
                correction:
                  'Le fondaparinux fait tout &agrave; fait partie des options de premi&egrave;re intention pour la prophylaxie de la MTEV (2,5 mg/jour en SC pour information).',
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035307,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 4,
            statement:
              'Une pr&eacute;vention par &eacute;noxaparine est prescrite. Quelle surveillance faut-il instaurer ?',
            item: '330',
            knowledge: {
              knowledge: 'N330N07',
              rank: 'A',
              type: 'Prise en charge',
              label:
                "Héparines : connaître les mécanismes d'action, indications, effets secondaires interactions médicamenteuses, modalités de surveillance et principales causes d'échec",
              item: {
                item: 330,
                short_title: 'Classes médicamenteuses les plus courantes',
                title:
                  "Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                tag_and_title:
                  "N330: Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['TTT'],
            correction:
              "La surveillance d'un traitement anticoagulant est &agrave; ma&icirc;triser sur le bout des doigts devant sa transversalit&eacute; dans les dossiers aux ECNi (ou les QI). Elle est double : d'une part la tol&eacute;rance, et d'autre part l'efficacit&eacute;. Bien que l'&eacute;nonc&eacute; soit au singulier, il ne ferme pas la porte au fait d'avoir au moins deux r&eacute;ponses (<span style=\"text-decoration: underline;\">la</span> surveillance peut comporter plusieurs &eacute;l&eacute;ments, tout comme <span style=\"text-decoration: underline;\">un</span> bilan peut comporter plusieurs examens).",

            coefficient: 1,
            lessons: ['surveillance_heparines'],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 4,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912072,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement: 'surveillance du TCA',
                state: 'F',
                correction:
                  "Proposition doublement fausse. D'une part, la surveillance de l'efficacit&eacute; d'un traitement antithrombotique n'est jamais indiqu&eacute;e &agrave; dose pr&eacute;ventive. D'autre part, compte tenu de leur faible action anti-IIa, les HBPM &agrave; doses curatives sont surveill&eacute;es uniquement par h&eacute;parin&eacute;mie/activit&eacute; anti-Xa si n&eacute;cessaire (contrairement aux HNF).",
              },
              answer: false,
            },
            {
              id: 106912071,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement: "surveillance de l'activit&eacute; anti Xa",
                state: 'F',
                correction:
                  "La surveillance de l'efficacit&eacute; d'un traitement antithrombotique n'est jamais indiqu&eacute;e &agrave; dose pr&eacute;ventive.",
              },
              answer: false,
            },
            {
              id: 106912073,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement: "surveilance de l'INR",
                state: 'F',
                correction:
                  "L'INR a &eacute;t&eacute; cr&eacute;&eacute; et est r&eacute;serv&eacute; au suivi de l'efficacit&eacute; des AVK. Il s'agit d'une valeur d&eacute;riv&eacute;e du TP (taux de prothrombine) d&eacute;riv&eacute; lui-m&ecirc;me du TQ (temps de Quick).",
              },
              answer: false,
            },
            {
              id: 106912074,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement: 'surveillance de la num&eacute;ration plaquettaire',
                state: 'V',
                correction:
                  "La num&eacute;ration plaquettaire est &agrave; surveiller ici car il s'agit d'un traitement par HBPM dans un contexte de chirurgie orthop&eacute;dique. On r&eacute;alisera une NFS avant le traitement, deux fois par semaine pendant 3 semaines puis de fa&ccedil;on hebdomadaire.",
              },
              answer: false,
            },
            {
              id: 106912075,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement: 'surveillance du saignement dans les drains',
                state: 'V',
                correction:
                  "Le recours au drain syst&eacute;matique dans la chirurgie de PTH et PTG est discut&eacute;, mais ce n'est probablement pas la question ici. L'introduction d'un traitement anticoagulant doit toujours faire surveiller au minimum cliniquement la survenue d'un saignement (ou en l'occurrence d'un saignement anormal par son abondance).",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035308,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 5,
            statement:
              "Dans les suites de l'intervention, la cuisse gauche est sensible et oed&eacute;mati&eacute;e. Huit jours apr&egrave;s le d&eacute;but du traitement sous &eacute;noxaparine 40 mg/jour, l'oed&egrave;me s'&eacute;tend jusqu&rsquo;au pied avec prise du godet. La douleur est accentu&eacute;e, pr&eacute;sente au repos mais accentu&eacute;e par la marche. La temp&eacute;rature est &agrave; 37&deg;C.<br />Quel(s) diagnostic(s) &eacute;voquez-vous en priorit&eacute; ?",
            item: '226',
            knowledge: {
              knowledge: 'N226N04',
              rank: 'A',
              type: 'Diagnostic positif',
              label:
                "Connaître les modalités du diagnostic d'une MTEV TVP, EP : signes cliniques, stratégie diagnostique incluant les scores, signes paracliniques, principaux diagnostics différentiels",
              item: {
                item: 226,
                short_title: 'Maladie thrombo-embolique veineuse ',
                title:
                  'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                tag_and_title:
                  'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['SEM'],
            correction:
              "Question assez d&eacute;licate d'interpr&eacute;tation d'un &eacute;nonc&eacute; clinique dans un contexte post-op&eacute;ratoire, qui emp&ecirc;che donc probablement de proposer une correction s&ucirc;re &agrave; 100%. L'orientation diagnostique devant une grosse jambe rouge douloureuse est un grand classique aux ECNi (m&eacute;decine cardiovasculaire, dermatologie, infectiologie, ...).",

            coefficient: 1,
            lessons: [],
            similar_questions: [
              {
                id: 643,
                group: {
                  id: 37,
                  composition_unit: undefined,
                  collection: 'ECNi 2016',
                  is_free: false,
                  type: 'DP',
                  num: 7,
                  median: null,
                  denom: null,
                  thumbnail:
                    "Évaluation et prise en charge d'une morsure de chien chez une femme de 32 ans, diagnostic de pasteurellose, discussion du risque rabique. Bilan de toxicomanie révélant une infection par le VHB et le VHC à prendre en charge.",
                  number_of_questions: 15,
                  specialty1: 'MIT',
                  specialty2: null,
                  last_training: 1683536,
                  last_training_date: '2023-10-11T10:38:08.765334+02:00',
                  grade: 6.6000000000000005,
                  total_coefficient: 15,
                },
                type: 'QRM',
                num: 3,
                statement:
                  "L'examen clinique r&eacute;v&egrave;le une plaie douloureuse, &eacute;ryth&eacute;mateuse et oed&eacute;mati&eacute;e. Il existe un &eacute;coulement s&eacute;reux au niveau de deux effractions cutan&eacute;es correspondant aux dents de l'animal. La patiente est, par ailleurs, apyr&eacute;tique. Parmi les diagnostics suivants, indiquez celui(ceux) que vous &eacute;voquez en priorit&eacute;.",
                item: '173',
                knowledge: {
                  knowledge: 'N173N10',
                  rank: 'A',
                  type: 'Diagnostic positif',
                  label:
                    'Connaître les éléments du diagnostic clinique et biologique de la pasteurellose',
                  item: {
                    item: 173,
                    short_title: 'Zoonoses',
                    title: 'Zoonoses.',
                    tag_and_title: 'N173: Zoonoses.',
                  },
                  see_also_items: [],
                },
                specialty1: 'MIT',
                specialty2: null,
                categories: ['SEM'],
                items_qcm: [
                  {
                    num: 2,
                    statement: 'Tular&eacute;mie',
                  },
                  {
                    num: 1,
                    statement: 'Erysip&egrave;le',
                  },
                  {
                    num: 3,
                    statement:
                      'Fi&egrave;vre boutonneuse m&eacute;diterran&eacute;enne',
                  },
                  {
                    num: 4,
                    statement: 'Pasteurellose',
                  },
                  {
                    num: 5,
                    statement:
                      'Dermo-hypodermite bact&eacute;rienne n&eacute;crosante',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 20,
                group: {
                  id: 2,
                  composition_unit: undefined,
                  collection: 'ECNi 2016',
                  is_free: false,
                  type: 'DP',
                  num: 18,
                  median: null,
                  denom: null,
                  thumbnail:
                    'Risque thrombo-embolique et thrombose veineuse profonde post-opératoire chez un homme de 77 ans, thérapeutique et insuffisance veineuse sur syndrome post-thrombotique séquellaire.',
                  number_of_questions: 15,
                  specialty1: 'MCV',
                  specialty2: null,
                  last_training: 1736447,
                  last_training_date: '2024-02-09T12:28:11.284004+01:00',
                  grade: 0,
                  total_coefficient: 15,
                },
                type: 'QRM',
                num: 5,
                statement:
                  "Dans les suites de l'intervention, la cuisse gauche est sensible et oed&eacute;mati&eacute;e. Huit jours apr&egrave;s le d&eacute;but du traitement sous &eacute;noxaparine 40 mg/jour, l'oed&egrave;me s'&eacute;tend jusqu&rsquo;au pied avec prise du godet. La douleur est accentu&eacute;e, pr&eacute;sente au repos mais accentu&eacute;e par la marche. La temp&eacute;rature est &agrave; 37&deg;C.<br />Quel(s) diagnostic(s) &eacute;voquez-vous en priorit&eacute; ?",
                item: '226',
                knowledge: {
                  knowledge: 'N226N04',
                  rank: 'A',
                  type: 'Diagnostic positif',
                  label:
                    "Connaître les modalités du diagnostic d'une MTEV TVP, EP : signes cliniques, stratégie diagnostique incluant les scores, signes paracliniques, principaux diagnostics différentiels",
                  item: {
                    item: 226,
                    short_title: 'Maladie thrombo-embolique veineuse ',
                    title:
                      'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                    tag_and_title:
                      'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                  },
                  see_also_items: [],
                },
                specialty1: 'MCV',
                specialty2: null,
                categories: ['SEM'],
                items_qcm: [
                  {
                    num: 1,
                    statement: '&eacute;rysip&egrave;le',
                  },
                  {
                    num: 2,
                    statement: 'oed&egrave;me post-op&eacute;ratoire',
                  },
                  {
                    num: 3,
                    statement: 'lymphangite',
                  },
                  {
                    num: 5,
                    statement: 'thrombose veineuse profonde',
                  },
                  {
                    num: 4,
                    statement: 'infection de la proth&egrave;se de hanche',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 1370,
                group: {
                  id: 72,
                  composition_unit: undefined,
                  collection: 'ECNi 2017',
                  is_free: false,
                  type: 'DP',
                  num: 3,
                  median: 8.9,
                  denom: 15,
                  thumbnail:
                    "Grosse jambe rouge aigu&euml; f&eacute;brile chez une femme de 75 ans diagnostiqu&eacute;e dermo-hypodermite n&eacute;crosante &agrave; prendre en charge. Complication en choc septique avec syndrome de d&eacute;tresse respiratoire aigu&euml; puis d'une pustulose exanth&eacute;matique aigu&euml; g&eacute;n&eacute;ralis&eacute;e. &Eacute;vocation du risque t&eacute;tanique.",
                  number_of_questions: 15,
                  specialty1: 'MIT',
                  specialty2: null,
                  last_training: 1682421,
                  last_training_date: '2023-10-11T09:04:56.593185+02:00',
                  grade: 13.5,
                  total_coefficient: 15,
                },
                type: 'QRM',
                num: 2,
                statement:
                  'Parmi les diagnostics suivants, lequel &eacute;voquez-vous en priorit&eacute; ?',
                item: '350',
                knowledge: {
                  knowledge: 'N350N03',
                  rank: 'A',
                  type: 'Étiologies',
                  label:
                    'Connaître la principale cause de grosse jambe rouge aiguë fébrile',
                  item: {
                    item: 350,
                    short_title: 'Grosse jambe rouge aiguë',
                    title: 'Grosse jambe rouge aiguë.',
                    tag_and_title: 'N350: Grosse jambe rouge aiguë.',
                  },
                  see_also_items: [],
                },
                specialty1: 'MIT',
                specialty2: null,
                categories: ['SEM'],
                items_qcm: [
                  {
                    num: 1,
                    statement: 'Eryth&egrave;me noueux',
                  },
                  {
                    num: 2,
                    statement: 'Erysip&egrave;le',
                  },
                  {
                    num: 4,
                    statement: 'Thrombophl&eacute;bite',
                  },
                  {
                    num: 5,
                    statement: 'Isch&eacute;mie critique',
                  },
                  {
                    num: 3,
                    statement:
                      'Dermo-hypodermite bact&eacute;rienne n&eacute;crosante',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 1399,
                group: {
                  id: 74,
                  composition_unit: undefined,
                  collection: 'ECNi 2017',
                  is_free: false,
                  type: 'DP',
                  num: 5,
                  median: 10.2,
                  denom: 15,
                  thumbnail:
                    "Fracture bifocale de la mandibule chez un homme de 35 ans ayant subi des coups, prise en charge, cal vicieux puis syndrome algo-dysfonctionnel de l'appareil manducateur séquellaire.",
                  number_of_questions: 15,
                  specialty1: 'CMF',
                  specialty2: null,
                  last_training: 1417089,
                  last_training_date: '2023-08-25T13:59:01.328878+02:00',
                  grade: 9,
                  total_coefficient: 15,
                },
                type: 'QRM',
                num: 1,
                statement:
                  'Parmi les fractures suivantes, laquelle (lesquelles) peut (peuvent) entra&icirc;ner un trouble de l&rsquo;articul&eacute; dentaire ?',
                item: '335',
                knowledge: {
                  knowledge: 'N335N02',
                  rank: 'A',
                  type: 'Diagnostic positif',
                  label:
                    "Connaître les éléments de l'interrogatoire et de l'examen clinique à réaliser dans le cadre d'un traumatisme facial",
                  item: {
                    item: 335,
                    short_title: 'Traumatisme cranio-facial',
                    title:
                      'Orientation diagnostique et conduite à tenir devant un traumatisme crânio-facial et oculaire.',
                    tag_and_title:
                      'N335: Orientation diagnostique et conduite à tenir devant un traumatisme crânio-facial et oculaire.',
                  },
                  see_also_items: [],
                },
                specialty1: 'CMF',
                specialty2: null,
                categories: ['SEM'],
                items_qcm: [
                  {
                    num: 1,
                    statement: 'Fracture du corps de la mandibule',
                  },
                  {
                    num: 2,
                    statement:
                      'Fracture des os nasaux (ou fracture des os propres du nez, FOPN)',
                  },
                  {
                    num: 3,
                    statement: "Fracture de l'os zygomatique",
                  },
                  {
                    num: 4,
                    statement:
                      'Fracture cr&acirc;nio-faciale de type Le Fort II',
                  },
                  {
                    num: 5,
                    statement: 'Embarrure fronto-pari&eacute;tale',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 2543,
                group: {
                  id: 130,
                  composition_unit: undefined,
                  collection: 'ECNi 2020',
                  is_free: false,
                  type: 'DP',
                  num: 4,
                  median: 11.4,
                  denom: 15,
                  thumbnail:
                    "Grosse jambe rouge aiguë chez une femme de 53 ans menant au diagnostic de dermo-hypodermite bactérienne non nécrosante sur intertrigo inter-orteils à prendre en charge. Vaccinations et chimioprophylaxie antipaludéenne en vue d'un voyage, diagnostic et thérapeutique d'une gale à son retour.",
                  number_of_questions: 15,
                  specialty1: 'MIT',
                  specialty2: null,
                  last_training: 1684102,
                  last_training_date: '2023-10-11T11:31:50.705601+02:00',
                  grade: 12.899999999999999,
                  total_coefficient: 15,
                },
                type: 'QRM',
                num: 2,
                statement:
                  'Quelle(s) est (sont) la (les) hypoth&egrave;se(s) diagnostique(s) la (les) plus vraisemblable(s) devant ce tableau clinique ?',
                item: '350',
                knowledge: {
                  knowledge: 'N335N02',
                  rank: 'A',
                  type: 'Diagnostic positif',
                  label:
                    "Connaître les éléments de l'interrogatoire et de l'examen clinique à réaliser dans le cadre d'un traumatisme facial",
                  item: {
                    item: 335,
                    short_title: 'Traumatisme cranio-facial',
                    title:
                      'Orientation diagnostique et conduite à tenir devant un traumatisme crânio-facial et oculaire.',
                    tag_and_title:
                      'N335: Orientation diagnostique et conduite à tenir devant un traumatisme crânio-facial et oculaire.',
                  },
                  see_also_items: [],
                },
                specialty1: 'MIT',
                specialty2: null,
                categories: ['SEM'],
                items_qcm: [
                  {
                    num: 1,
                    statement: 'Dermohypodermite de stase',
                  },
                  {
                    num: 2,
                    statement: 'Dermohypodermite bact&eacute;rienne',
                  },
                  {
                    num: 3,
                    statement: 'Fasciite n&eacute;crosante',
                  },
                  {
                    num: 4,
                    statement: 'Lymphangite',
                  },
                  {
                    num: 5,
                    statement: 'Eryth&egrave;me noueux',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 9203,
                group: {
                  id: 533,
                  composition_unit: 1,
                  collection: 'ECNi 2023',
                  is_free: false,
                  type: 'DP',
                  num: 20,
                  median: null,
                  denom: null,
                  thumbnail:
                    "<p>Diagnostic et prise en charge d'une dermo-hypodermite bactérienne non nécrosante chez une femme de 65 ans.</p>",
                  number_of_questions: 3,
                  specialty1: 'DER',
                  specialty2: null,
                  last_training: 1736530,
                  last_training_date: '2024-03-11T14:45:58.941904+01:00',
                  grade: 0,
                  total_coefficient: 3,
                },
                type: 'QRU',
                num: 1,
                statement: 'Quel diagnostic suspectez-vous ?',
                item: '155',
                knowledge: {
                  knowledge: 'N155N17',
                  rank: 'A',
                  type: 'Contenu multimédia',
                  label:
                    "Dermohypodermite bactérienne non nécrosante (photographies d'un érysipéle adulte, d'une dermohypodermite non nécrosante enfant)",
                  item: {
                    item: 155,
                    short_title: 'Infections cutanéo-muqueuses',
                    title:
                      "Infections cutanéo-muqueuses et des phanères, bactériennes et mycosiques de l'adulte et de l'enfant.",
                    tag_and_title:
                      "N155: Infections cutanéo-muqueuses et des phanères, bactériennes et mycosiques de l'adulte et de l'enfant.",
                  },
                  see_also_items: [],
                },
                specialty1: 'DER',
                specialty2: null,
                categories: ['SEM'],
                items_qcm: [
                  {
                    num: 1,
                    statement:
                      'Récidive de l’eczéma de contact par réaction croisée',
                  },
                  {
                    num: 2,
                    statement:
                      'Dermohypodermite inflammatoire sur insuffisance veineuse',
                  },
                  {
                    num: 3,
                    statement:
                      'Dermohypodermite d’origine bactérienne non nécrosante',
                  },
                  {
                    num: 4,
                    statement: 'Fasciite nécrosante',
                  },
                  {
                    num: 5,
                    statement: 'Thrombose veineuse profonde',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 9556,
                group: {
                  id: 590,
                  composition_unit: 1,
                  collection: 'ECNi 2023',
                  is_free: false,
                  type: 'KFP',
                  num: 20,
                  median: null,
                  denom: null,
                  thumbnail: '',
                  number_of_questions: 3,
                  specialty1: 'DER',
                  specialty2: null,
                  last_training: 1736374,
                  last_training_date: '2023-11-29T14:41:54.300040+01:00',
                  grade: 0,
                  total_coefficient: 3,
                },
                type: 'QRU',
                num: 1,
                statement: '',
                item: '155',
                knowledge: {
                  knowledge: 'N155N17',
                  rank: 'A',
                  type: 'Contenu multimédia',
                  label:
                    "Dermohypodermite bactérienne non nécrosante (photographies d'un érysipéle adulte, d'une dermohypodermite non nécrosante enfant)",
                  item: {
                    item: 155,
                    short_title: 'Infections cutanéo-muqueuses',
                    title:
                      "Infections cutanéo-muqueuses et des phanères, bactériennes et mycosiques de l'adulte et de l'enfant.",
                    tag_and_title:
                      "N155: Infections cutanéo-muqueuses et des phanères, bactériennes et mycosiques de l'adulte et de l'enfant.",
                  },
                  see_also_items: [],
                },
                specialty1: 'DER',
                specialty2: null,
                categories: ['SEM'],
                items_qcm: [],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
            ],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 5,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912076,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement: '&eacute;rysip&egrave;le',
                state: 'F',
                correction:
                  "Ce diagnostic est assez invraisemblable en l'absence de fi&egrave;vre, d'autant qu'un &oelig;d&egrave;me prenant le godet n'est pas un signe associ&eacute;.",
              },
              answer: false,
            },
            {
              id: 106912077,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement: 'oed&egrave;me post-op&eacute;ratoire',
                state: 'F',
                correction:
                  "Il est probable que cette proposition colle &agrave; la premi&egrave;re phrase de l'&eacute;nonc&eacute;, avec une cuisse sensible et &oelig;d&eacute;mati&eacute;e en postop&eacute;ratoire imm&eacute;diat. Cependant, la majoration de l'&oelig;d&egrave;me qui s'&eacute;tend au pied avec prise de godet n'en fait certainement pas un diagnostic &agrave; &eacute;voquer &laquo; en priorit&eacute; &raquo;.",
              },
              answer: false,
            },
            {
              id: 106912078,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement: 'lymphangite',
                state: 'F',
                correction:
                  "Une lymphangite est une inflammation d'un vaisseau lymphatique, correspondant classiquement &agrave; une manifestation loco-r&eacute;gionale d'une infection. Bien qu'elle puisse &ecirc;tre apyr&eacute;tique, elle est cens&eacute;e entra&icirc;ner une &eacute;ryth&egrave;me lin&eacute;aire raccord&eacute; &agrave; une ad&eacute;nopathie inflammatoire ce que l'on a pas ici, sans entra&icirc;ner d'oed&egrave;me de la totalit&eacute; du membre.",
              },
              answer: false,
            },
            {
              id: 106912080,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement: 'infection de la proth&egrave;se de hanche',
                state: 'F',
                correction:
                  "L'infection de proth&egrave;se doit effectivement toujours &ecirc;tre &eacute;voqu&eacute;e de principe devant un &oelig;d&egrave;me et une douleur du membre inf&eacute;rieur en contexte post-op&eacute;ratoire, de par sa fr&eacute;quence et sa gravit&eacute; (urgence th&eacute;rapeutique). N&eacute;anmoins, il n'y a ici pas d'argument clinique franc mentionn&eacute; qui nous orienterait vers ce diagnostic : pas de cicatrice inflammatoire, pas de d&eacute;sunion, pas d'&eacute;coulement purulent/suspect, pas d'impotence fonctionnelle, pas de fi&egrave;vre (si l'apyrexie n'&eacute;limine pas le diagnostic, elle est g&eacute;n&eacute;ralement pr&eacute;sente en post-op&eacute;ratoire imm&eacute;diat). La prise du godet &eacute;tendu &agrave; tout le membre inf&eacute;rieur n'est pas en faveur d'une infection non plus. On s'oriente ici plus volontiers vers une TVP.",
              },
              answer: false,
            },
            {
              id: 106912079,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement: 'thrombose veineuse profonde',
                state: 'V',
                correction:
                  "Le tableau est tout &agrave; fait &eacute;vocateur du diagnostic, d'une part sur le plan clinique et d'autre part sur le contexte post-op&eacute;ratoire d'une PTH (chirurgie &agrave; haut risque de TVP).<br />Par ailleurs, on doit &eacute;galement suspecter une TIH puisque le patient est &agrave; J8 de l'introduction d'un traitement par HBPM.",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035309,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 6,
            statement:
              'Quel(s) &eacute;l&eacute;ment(s) clinique(s) est(sont) int&eacute;ressants pour le calcul de la probabilit&eacute; diagnostique de thrombose veineuse profonde ?',
            item: '226',
            knowledge: {
              knowledge: 'N226N04',
              rank: 'A',
              type: 'Diagnostic positif',
              label:
                "Connaître les modalités du diagnostic d'une MTEV TVP, EP : signes cliniques, stratégie diagnostique incluant les scores, signes paracliniques, principaux diagnostics différentiels",
              item: {
                item: 226,
                short_title: 'Maladie thrombo-embolique veineuse ',
                title:
                  'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                tag_and_title:
                  'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['SEM'],
            correction:
              "Question de s&eacute;miologie, qui, sans le citer, fait r&eacute;f&eacute;rence &agrave; un score (r&eacute;current dans cette discipline). Celui-ci est probablement le score de probabilit&eacute; clinique de Wells simplifi&eacute; pour la TVP, cit&eacute; par les r&eacute;f&eacute;rentiels de cardiologie et de pneumologie. Il n'est pas attendu de savoir calculer le score (<em>i.e.</em> conna&icirc;tre les points), mais il faut conna&icirc;tre la s&eacute;miologie &eacute;vocatrice de la TVP, qui est utilis&eacute;e dans celui-ci.<br /><br />Les trois principaux signes cliniques &eacute;vocateurs selon le r&eacute;f&eacute;rentiel de m&eacute;decine vasculaire sont douleur, &oelig;d&egrave;me et dilatation veineuse non variqueuse.<br /><br /><em>N.B.</em> : cet &eacute;nonc&eacute; est par ailleurs en faveur de la correction propos&eacute;e &agrave; la question pr&eacute;c&eacute;dente, &agrave; savoir uniquement la r&eacute;ponse E vraie.",

            coefficient: 1,
            lessons: ['score_wells_tvp'],
            similar_questions: [
              {
                id: 21,
                group: {
                  id: 2,
                  composition_unit: undefined,
                  collection: 'ECNi 2016',
                  is_free: false,
                  type: 'DP',
                  num: 18,
                  median: null,
                  denom: null,
                  thumbnail:
                    'Risque thrombo-embolique et thrombose veineuse profonde post-opératoire chez un homme de 77 ans, thérapeutique et insuffisance veineuse sur syndrome post-thrombotique séquellaire.',
                  number_of_questions: 15,
                  specialty1: 'MCV',
                  specialty2: null,
                  last_training: 1736447,
                  last_training_date: '2024-02-09T12:28:11.284004+01:00',
                  grade: 0,
                  total_coefficient: 15,
                },
                type: 'QRM',
                num: 6,
                statement:
                  'Quel(s) &eacute;l&eacute;ment(s) clinique(s) est(sont) int&eacute;ressants pour le calcul de la probabilit&eacute; diagnostique de thrombose veineuse profonde ?',
                item: '226',
                knowledge: {
                  knowledge: 'N226N04',
                  rank: 'A',
                  type: 'Diagnostic positif',
                  label:
                    "Connaître les modalités du diagnostic d'une MTEV TVP, EP : signes cliniques, stratégie diagnostique incluant les scores, signes paracliniques, principaux diagnostics différentiels",
                  item: {
                    item: 226,
                    short_title: 'Maladie thrombo-embolique veineuse ',
                    title:
                      'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                    tag_and_title:
                      'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                  },
                  see_also_items: [],
                },
                specialty1: 'MCV',
                specialty2: null,
                categories: ['SEM'],
                items_qcm: [
                  {
                    num: 1,
                    statement:
                      'augmentation unilat&eacute;rale de volume du membre inf&eacute;rieur',
                  },
                  {
                    num: 3,
                    statement: 'varices',
                  },
                  {
                    num: 5,
                    statement: 'le signe de Giordano',
                  },
                  {
                    num: 2,
                    statement: 'douleur du membre inf&eacute;rieur',
                  },
                  {
                    num: 4,
                    statement:
                      'chirurgie proth&eacute;tique de hanche r&eacute;cente',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 2325,
                group: {
                  id: 122,
                  composition_unit: undefined,
                  collection: 'ECNi 2019',
                  is_free: false,
                  type: 'DP',
                  num: 15,
                  median: 10.9,
                  denom: 15,
                  thumbnail:
                    "Exploration d'une hémoglobinémie supra-normale découverte chez un homme de 45 ans à l'occasion d'un don du sang, révélant une polyglobulie de Vaquez à prendre en charge. Diagnostic d'une complication par TVP puis prise en charge anticoagulante et enfin transformation en leucémie aiguë à distance.",
                  number_of_questions: 15,
                  specialty1: 'HEM',
                  specialty2: null,
                  last_training: 1670577,
                  last_training_date: '2023-10-10T01:44:07.422907+02:00',
                  grade: 11.4,
                  total_coefficient: 15,
                },
                type: 'QRM',
                num: 8,
                statement:
                  'Le patient a eu des saign&eacute;es initiales puis est actuellement sous antiagr&eacute;gants. Trois mois plus tard, votre patient vient vous voir 48 heures apr&egrave;s son retour de vacances des Antilles. Il a un mollet gauche douloureux et gonfl&eacute; depuis 24 heures. Quels &eacute;l&eacute;ments cliniques vont conforter votre suspicion de phl&eacute;bite ? (une ou plusieurs r&eacute;ponses possibles)',
                item: '226',
                knowledge: {
                  knowledge: 'N226N04',
                  rank: 'A',
                  type: 'Diagnostic positif',
                  label:
                    "Connaître les modalités du diagnostic d'une MTEV TVP, EP : signes cliniques, stratégie diagnostique incluant les scores, signes paracliniques, principaux diagnostics différentiels",
                  item: {
                    item: 226,
                    short_title: 'Maladie thrombo-embolique veineuse ',
                    title:
                      'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                    tag_and_title:
                      'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                  },
                  see_also_items: [],
                },
                specialty1: 'HEM',
                specialty2: null,
                categories: ['SEM'],
                items_qcm: [
                  {
                    num: 1,
                    statement:
                      'Douleur provoqu&eacute;e &agrave; la pression du mollet',
                  },
                  {
                    num: 2,
                    statement:
                      'Augmentation de la circonf&eacute;rence du mollet',
                  },
                  {
                    num: 3,
                    statement: 'Contracture du mollet',
                  },
                  {
                    num: 4,
                    statement: 'Diminution du pouls p&eacute;dieux',
                  },
                  {
                    num: 5,
                    statement: 'Dilatation des veines superficielles',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 3759,
                group: {
                  id: 125,
                  composition_unit: undefined,
                  collection: 'ECNi 2019',
                  is_free: false,
                  type: 'QI',
                  num: 1,
                  median: 72.3,
                  denom: 120,
                  thumbnail: '',
                  number_of_questions: 120,
                  specialty1: null,
                  specialty2: null,
                  last_training: null,
                  last_training_date: null,
                  grade: null,
                  total_coefficient: null,
                },
                type: 'QRM',
                num: 17,
                statement:
                  "Mme M &acirc;g&eacute;e de 35 ans se pr&eacute;sente pour une douleur sourde du mollet droit existant depuis la veille au soir.<br />A l&rsquo;interrogatoire vous retrouvez l&rsquo;existence d&rsquo;une art&eacute;riopathie des membres inf&eacute;rieurs chez le p&egrave;re de cette patiente, ayant conduit &agrave; une amputation de jambe &agrave; l&rsquo;&acirc;ge de 78 ans.<br />Malgr&eacute; cet ant&eacute;c&eacute;dent h&eacute;r&eacute;ditaire, Madame M signale un tabagisme quantifi&eacute; &agrave; &frac12; paquet de cigarettes par jour depuis l&rsquo;&acirc;ge de 18 ans. Vous trouvez, en outre, un syndrome de Raynaud chez la m&egrave;re de la patiente. Mme M n'a aucun traitement et vient de pr&eacute;senter une fausse couche spontan&eacute;e pour laquelle elle est rest&eacute;e hospitalis&eacute;e 24 heures .<br />L&rsquo;examen clinique r&eacute;v&egrave;le une augmentation de la circonf&eacute;rence &oelig;d&egrave;me du mollet droit de 1 cm par rapport au mollet controlat&eacute;ral. On note &eacute;galement au niveau du membre inf&eacute;rieur droit une augmentation de chaleur cutan&eacute;e ainsi qu&rsquo;une augmentation de la visibilit&eacute; des veines superficielles et une douleur &agrave; la palpation profonde du mollet .<br />Chez cette patiente, quel(s) &eacute;l&eacute;ment(s) doi(ven)t &ecirc;tre pris en compte pour &eacute;tablir le score de probabilit&eacute; clinique ?",
                item: '226',
                knowledge: {
                  knowledge: 'N226N04',
                  rank: 'A',
                  type: 'Diagnostic positif',
                  label:
                    "Connaître les modalités du diagnostic d'une MTEV TVP, EP : signes cliniques, stratégie diagnostique incluant les scores, signes paracliniques, principaux diagnostics différentiels",
                  item: {
                    item: 226,
                    short_title: 'Maladie thrombo-embolique veineuse ',
                    title:
                      'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                    tag_and_title:
                      'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                  },
                  see_also_items: [],
                },
                specialty1: 'MCV',
                specialty2: null,
                categories: ['SEM'],
                items_qcm: [
                  {
                    num: 5,
                    statement: 'hospitalisation r&eacute;cente de 24 heures',
                  },
                  {
                    num: 1,
                    statement:
                      'ant&eacute;c&eacute;dents familiaux de pathologie vasculaire',
                  },
                  {
                    num: 2,
                    statement:
                      'augmentation de la circonf&eacute;rence du mollet droit de 1 cm',
                  },
                  {
                    num: 3,
                    statement: 'douleur &agrave; la palpation profonde',
                  },
                  {
                    num: 4,
                    statement:
                      'augmentation de la visibilit&eacute; des veines superficielles',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
              {
                id: 9406,
                group: {
                  id: 554,
                  composition_unit: 3,
                  collection: 'ECNi 2023',
                  is_free: false,
                  type: 'QI',
                  num: 1,
                  median: null,
                  denom: null,
                  thumbnail: '',
                  number_of_questions: 35,
                  specialty1: null,
                  specialty2: null,
                  last_training: null,
                  last_training_date: null,
                  grade: null,
                  total_coefficient: null,
                },
                type: 'QRM',
                num: 15,
                statement:
                  'Quelles circonstances doivent vous inciter à demander un dosage de D-dimères dans le cadre d’une suspicion de thrombose veineuse profonde ? (une ou plusieurs réponses exactes)',
                item: '226',
                knowledge: {
                  knowledge: 'N226N04',
                  rank: 'A',
                  type: 'Diagnostic positif',
                  label:
                    "Connaître les modalités du diagnostic d'une MTEV TVP, EP : signes cliniques, stratégie diagnostique incluant les scores, signes paracliniques, principaux diagnostics différentiels",
                  item: {
                    item: 226,
                    short_title: 'Maladie thrombo-embolique veineuse ',
                    title:
                      'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                    tag_and_title:
                      'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                  },
                  see_also_items: [],
                },
                specialty1: 'MCV',
                specialty2: null,
                categories: [],
                items_qcm: [
                  {
                    num: 5,
                    statement:
                      'la mise en évidence d’une thrombose veineuse superficielle',
                  },
                  {
                    num: 1,
                    statement:
                      'la présence d’une probabilité clinique de thrombose veineuse profonde faible',
                  },
                  {
                    num: 2,
                    statement: 'la présence d’un cancer en phase active',
                  },
                  {
                    num: 3,
                    statement:
                      'la présence d’un diagnostic alternatif à celui de thrombose veineuse profonde',
                  },
                  {
                    num: 4,
                    statement:
                      'le contexte post-opératoire en chirurgie orthopédique',
                  },
                ],
                items_qrpl: [],
                items_tcs: [],
                item_qzp: null,
                tcs_header: null,
              },
            ],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 6,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912081,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 1,
                statement:
                  'augmentation unilat&eacute;rale de volume du membre inf&eacute;rieur',
                state: 'V',
                correction:
                  'Cette augmentation de volume ainsi d&eacute;crite est due au blocage du drainage veineux du c&ocirc;t&eacute; de la TVP. Le score de Wells en tient compte pour une augmentation &gt; 3 cm par rapport au membre controlat&eacute;ral (pour information, mesure r&eacute;alis&eacute;e &agrave; 10 cm sous la tub&eacute;rosit&eacute; tibiale ant&eacute;rieure).',
              },
              answer: false,
            },
            {
              id: 106912084,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 2,
                statement: 'douleur du membre inf&eacute;rieur',
                state: 'V',
                correction:
                  'La douleur est spontan&eacute;e et est major&eacute;e par la palpation.',
              },
              answer: false,
            },
            {
              id: 106912082,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 3,
                statement: 'varices',
                state: 'F',
                correction:
                  "Attention, ce pi&egrave;ge est r&eacute;current.<br />Par d&eacute;finition, les varices sont des dilatations permanentes visibles d'une ou de plusieurs veines superficielles. Elles sont associ&eacute;es &agrave; un reflux en orthostatisme en raison d'une anomalie pari&eacute;tale responsable d'une incontinence valvulaire et d'un aspect tortueux et dilat&eacute;.<br /><br />Il s'agit donc d'une chose tr&egrave;s diff&eacute;rente de la constitution d'une circulation collat&eacute;rale superficielle en raison de l'obstruction profonde, responsable d'une dilatation veineuse.",
              },
              answer: false,
            },
            {
              id: 106912085,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 4,
                statement:
                  'chirurgie proth&eacute;tique de hanche r&eacute;cente',
                state: 'V',
                correction:
                  "Une chirurgie proth&eacute;tique de hanche r&eacute;cente est un facteur de risque transitoire majeur de TVP ou d'EP, pris en compte dans les scores de probabilit&eacute; clinique.",
              },
              answer: false,
            },
            {
              id: 106912083,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],
                num: 5,
                statement: 'le signe de Giordano',
                state: 'F',
                correction:
                  'Il s\'agit d\'une douleur &agrave; la percussion lombaire orientant vers une PNA ou une colique n&eacute;phr&eacute;tique (cf. <a href="https://www.urofrance.org/base-bibliographique/prise-en-charge-des-pyelonephrites-aigues">ce lien</a>).',
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035310,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 7,
            statement:
              "Vous estimez que la probabilit&eacute; clinique de thrombose veineuse profonde est &eacute;lev&eacute;e. Quelle(s) mesure(s) prenez- vous dans l&rsquo;imm&eacute;diat sachant qu'une &eacute;chographie-doppler ne sera obtenue que le lendemain matin ?",
            item: '226',
            knowledge: {
              knowledge: 'N226N10',
              rank: 'A',
              type: 'Prise en charge',
              label:
                "Connaître les principes de traitement d'une TVP/EP non grave à la phase initiale",
              item: {
                item: 226,
                short_title: 'Maladie thrombo-embolique veineuse ',
                title:
                  'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                tag_and_title:
                  'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
              },
              see_also_items: [
                {
                  item: 330,
                  short_title: 'Classes médicamenteuses les plus courantes',
                  title:
                    "Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                  tag_and_title:
                    "N330: Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                },
              ],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['TTT'],
            correction:
              "Question qui n&eacute;cessite d'&ecirc;tre au clair sur la strat&eacute;gie diagnostique dans un contexte de TVP, puisque la prise en charge &agrave; la fois diagnostique et th&eacute;rapeutique d&eacute;coule directement de la probabilit&eacute; clinique dont il faut savoir qu'elle est class&eacute;e en trois niveaux.<br /><br />Il faut r&eacute;pondre &agrave; la question \"Quelle est la prise en charge d'un patient avec une probabilit&eacute; &eacute;lev&eacute;e de TVP ?\". Si l'&eacute;chographie-Doppler doit &ecirc;tre faite dans les 24-48h, l'instauration d'un traitement se fait sans d&eacute;lai.",

            coefficient: 1,
            lessons: ['strategie_diag_tvp'],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 7,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912086,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 1,
                statement:
                  "prescription d'une h&eacute;parine de bas poids mol&eacute;culaire &agrave; dose pr&eacute;ventive",
                state: 'F',
                correction:
                  "Il faut instaurer un traitement par HBPM ou Fondaparinux &agrave; doses curatives, ainsi qu'une contention veineuse.",
              },
              answer: false,
            },
            {
              id: 106912088,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 2,
                statement:
                  'prescription d&rsquo;un anticoagulant &agrave; d&eacute;buter apr&egrave;s l&rsquo;&eacute;chographie-doppler',
                state: 'F',
                correction:
                  "Le traitement anticoagulant est instaur&eacute; avant l'&eacute;chographie-Doppler en cas de probabilit&eacute; clinique interm&eacute;diaire ou &eacute;lev&eacute;e, car c'est une urgence th&eacute;rapeutique. On peut attendre les r&eacute;sultats du Doppler dans le cas o&ugrave; la probabilit&eacute; clinique est faible.",
              },
              answer: false,
            },
            {
              id: 106912090,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 3,
                statement:
                  'injection imm&eacute;diate d&rsquo;une h&eacute;parine de bas poids mol&eacute;culaire &agrave; dose curative',
                state: 'V',
                correction:
                  'En cas de probabilit&eacute; clinique interm&eacute;diaire ou &eacute;lev&eacute;e, on injecte imm&eacute;diatement soit une HBPM, soit du fondaparinux &agrave; doses curatives.',
              },
              answer: false,
            },
            {
              id: 106912089,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 4,
                statement: "mise en route d'un traitement par antivitamine K",
                state: 'F',
                correction:
                  "L'introduction des AVK doit &ecirc;tre pr&eacute;coce, mais uniquement une fois le diagnostic confirm&eacute; par l'&eacute;chographie-Doppler car &agrave; ce stade, la balance B/R est en d&eacute;faveur d'une double anticoagulation (m&ecirc;me pour un relais).",
              },
              answer: false,
            },
            {
              id: 106912087,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 5,
                statement:
                  'injection imm&eacute;diate d&rsquo;une h&eacute;parine de bas poids mol&eacute;culaire &agrave; dose pr&eacute;ventive',
                state: 'F',
                correction: 'La dose est curative dans ce contexte.',
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035311,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 8,
            statement:
              'Le doppler veineux confirme une thrombose veineuse proximale. Quelle(s) explication(s) physiopathologique(s) est(sont) coh&eacute;rente(s) avec le cas de ce patient ?',
            item: '226',
            knowledge: {
              knowledge: 'N226N03',
              rank: 'B',
              type: 'Physiopathologie',
              label:
                'Connaître la physiopathologie de la MTEV y compris les formes familiales',
              item: {
                item: 226,
                short_title: 'Maladie thrombo-embolique veineuse ',
                title:
                  'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                tag_and_title:
                  'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['PHY'],
            correction:
              "L'habituelle question de physiopathologie des dossiers de m&eacute;decine cardiovasculaire. Notons qu'elles sont peu fr&eacute;quentes dans les autres disciplines aux ECNi. Elle n&eacute;cessite d'avoir bien compris le m&eacute;canisme global de formation d'une thrombose veineuse.<br /><br />Il faut r&eacute;pondre selon le contexte clinique actuel (\"le cas de ce patient\").",

            coefficient: 1,
            lessons: ['tih_type_2'],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 8,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912092,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 1,
                statement:
                  "activation de la coagulation par l'acte chirurgical",
                state: 'V',
                correction:
                  "Une chirurgie entra&icirc;ne de mani&egrave;re transitoire une activation de la coagulation responsable d'un risque thromboembolique syst&eacute;mique accru (et non pas uniquement au niveau du site op&eacute;ratoire). C'est pour cela que les D-Dim&egrave;res sont d'embl&eacute;e augment&eacute;s dans le mois suivant une chirurgie. A noter qu'une AG de plus de 30 minutes et/ou une chirurgie orthop&eacute;dique, carcinologique ou abdominale lourde augmentent largement le risque (facteurs majeurs).",
              },
              answer: false,
            },
            {
              id: 106912091,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 2,
                statement: 'syndrome des antiphospholipides',
                state: 'F',
                correction:
                  "Ce patient ne pr&eacute;sente aucun argument en faveur d'un SAPL.",
              },
              answer: false,
            },
            {
              id: 106912093,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 3,
                statement: 'alitement initial',
                state: 'V',
                correction:
                  "Il faut revenir &agrave; la triade de Virchow (stase sanguine, hypercoagulabilit&eacute;, alt&eacute;ration pari&eacute;tale). L'alitement entra&icirc;ne une stase veineuse en raison de l'absence de pression plantaire et de contraction de la pompe musculaire du mollet.",
              },
              answer: false,
            },
            {
              id: 106912094,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 4,
                statement:
                  "thrombop&eacute;nie induite par l'h&eacute;parine de type II",
                state: 'V',
                correction:
                  "Une TIH de type 2 est tout &agrave; fait probable dans ce contexte clinique puisqu'un traitement par HBPM a &eacute;t&eacute; d&eacute;but&eacute; il y a 8 jours dans un contexte de chirurgie orthop&eacute;dique. Celle-ci survient g&eacute;n&eacute;ralement 5-10 jours apr&egrave;s l'instauration et peut &ecirc;tre responsable de thromboses veineuses et art&eacute;rielles (cf. question 5).",
              },
              answer: false,
            },
            {
              id: 106912095,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 5,
                statement: 'syndrome de Cockett',
                state: 'F',
                correction:
                  "Il est question ici des explications coh&eacute;rentes avec ce patient.<br />Le syndrome de Cockett est une affection rare, caract&eacute;ris&eacute;e par une TVP iliof&eacute;morale gauche par compression de cette veine entre l'art&egrave;re iliaque primitive droite et la cinqui&egrave;me vert&egrave;bre lombaire. Bien qu'il s'agisse du bon c&ocirc;t&eacute; et de la bonne hauteur ici, l'absence d'argument de r&eacute;cidive, la survenue tardive (77 ans) et l'apparition fortuite 8 jours apr&egrave;s une chirurgie orthop&eacute;dique argumentent clairement en d&eacute;faveur de cocher.<br /><br />De plus, il existe g&eacute;n&eacute;ralement des signes compressifs chroniques : &oelig;d&egrave;me chronique intermittent du membre inf&eacute;rieur gauche, varices pelviennes avec syndrome de congestion pelvienne, varices atypiques ou r&eacute;cidivantes aux membres inf&eacute;rieurs. (cf. <a href=\"https://www.em-consulte.com/en/article/282472)%20https://www.em-consulte.com/en/article/282472\">Le syndrome de Cockett, mythe ou r&eacute;alit&eacute;</a>)",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035312,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 9,
            statement:
              "A l'&eacute;chographie-doppler le p&ocirc;le sup&eacute;rieur du caillot est iliaque externe gauche.<br />Quelles sont les deux principales complications &agrave; redouter ?",
            item: '226',
            knowledge: {
              knowledge: 'N226N16',
              rank: 'B',
              type: 'Suivi et/ou pronostic',
              label:
                'Savoir évoquer les complications à long terme de la MTEV (syndrome post-thrombotique, HTAP)',
              item: {
                item: 226,
                short_title: 'Maladie thrombo-embolique veineuse ',
                title:
                  'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                tag_and_title:
                  'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: [],
            correction:
              'Question sans difficult&eacute; qui demande de choisir deux propositions comme &eacute;tant les principales complications &agrave; redouter devant une TVP proximale.',

            coefficient: 1,
            lessons: [],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 9,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912098,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 1,
                statement: 'isch&eacute;mie aigu&euml; de jambe',
                state: 'F',
                correction:
                  "L'isch&eacute;mie aigu&euml; de jambe est un &eacute;v&eacute;nement de m&eacute;canisme art&eacute;riel (thrombotique ou embolique).<br /><br />Cependant, il existe une forme de TVP isch&eacute;mique dite phl&eacute;bite bleue ou <em>phlegmatia caerulea</em>, celle-ci &eacute;tant due &agrave; une obstruction s&eacute;v&egrave;re et &eacute;tendue responsable d'un &oelig;d&egrave;me compressif et d'un vasospasme. On y retrouve des signes d'isch&eacute;mie associ&eacute;s, voire une cyanose et parfois un &eacute;tat de choc. Il ne s'agit n&eacute;anmoins pas d'une isch&eacute;mie aigu&euml; de membre &agrave; proprement parler et le tableau est d'embl&eacute;e s&eacute;v&egrave;re.",
              },
              answer: false,
            },
            {
              id: 106912096,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 2,
                statement: 'embolie pulmonaire',
                state: 'V',
                correction:
                  "La migration d'un caillot sanguin vers la veine cave inf&eacute;rieure puis vers le c&oelig;ur et la circulation pulmonaire est une complication majeure &agrave; redouter.",
              },
              answer: false,
            },
            {
              id: 106912097,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 3,
                statement: 'accident vasculaire c&eacute;r&eacute;bral',
                state: 'F',
                correction:
                  "Un caillot veineux embolise dans la circulation pulmonaire. Cependant, il est tout &agrave; fait possible de retrouver une embolisation syst&eacute;mique en cas de foramen oval perm&eacute;able (ouverture entre les deux atria, permettant un passage de l'AD vers l'AG). Il ne s'agit &eacute;videmment pas d'une des deux principales complications ici.",
              },
              answer: false,
            },
            {
              id: 106912099,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 4,
                statement: 'syndrome post-thrombotique veineux',
                state: 'V',
                correction:
                  "Il survient en raison d'une destruction valvulaire ou d'une obstruction s&eacute;quellaire (SPT occlusif), quelques mois ou ann&eacute;es apr&egrave;s.",
              },
              answer: false,
            },
            {
              id: 106912100,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 5,
                statement: 'infarctus du myocarde',
                state: 'F',
                correction:
                  "Cette complication survient uniquement en pr&eacute;sence d'un foramen oval perm&eacute;able et restera beaucoup moins fr&eacute;quente que l'AVC le cas &eacute;ch&eacute;ant (cf. proposition C).",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035313,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 10,
            statement:
              'Quelle(s) modalit&eacute;(s) de traitement antithrombotique peut(peuvent) &ecirc;tre utilis&eacute;e(s) ici ?',
            item: '226',
            knowledge: {
              knowledge: 'N226N10',
              rank: 'A',
              type: 'Prise en charge',
              label:
                "Connaître les principes de traitement d'une TVP/EP non grave à la phase initiale",
              item: {
                item: 226,
                short_title: 'Maladie thrombo-embolique veineuse ',
                title:
                  'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                tag_and_title:
                  'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
              },
              see_also_items: [
                {
                  item: 330,
                  short_title: 'Classes médicamenteuses les plus courantes',
                  title:
                    "Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                  tag_and_title:
                    "N330: Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                },
              ],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['TTT'],
            correction:
              "Pour r&eacute;pondre correctement &agrave; cette question, il faut r&eacute;capituler le contexte clinique.<br />On se trouve dans le cas d'une TVP confirm&eacute;e au niveau iliaque externe gauche, sans embolie pulmonaire (EP) et sans signe de gravit&eacute; dans un contexte post-op&eacute;ratoire pr&eacute;coce.<br />Il existe ainsi plusieurs options de traitement, le but &eacute;tant d'anticoaguler en urgence &agrave; dose curative.<br />A noter que l'HNF n'est pas indiqu&eacute;e ici en l'absence d'insuffisance r&eacute;nale s&eacute;v&egrave;re, d'instabilit&eacute; h&eacute;modynamique, d'intervention pr&eacute;vue n&eacute;cessitant un arr&ecirc;t temporaire du traitement ou de thrombolyse.",

            coefficient: 1,
            lessons: [],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 10,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912102,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 1,
                statement: 'thrombolyse par voie g&eacute;n&eacute;rale',
                state: 'F',
                correction:
                  "La thrombolyse ne se con&ccedil;oit qu'en cas d'EP &agrave; haut risque (EP grave) en l'absence de contre-indication. Elle est &eacute;galement envisageable dans la TVP isch&eacute;mique d&eacute;crite &agrave; la question 9 (proposition A).",
              },
              answer: false,
            },
            {
              id: 106912104,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 2,
                statement:
                  'traitement par &eacute;noxaparine &agrave; dose curative avec relais pr&eacute;coce par AVK',
                state: 'V',
                correction:
                  "L'&eacute;noxaparine est l'une des deux HBPM ayant l'AMM avec la tinzaparine. Le relais initial par AVK est recommand&eacute; ainsi d&egrave;s le premier jour de traitement parent&eacute;ral (HNF, HBPM ou Fondaparinux).",
              },
              answer: false,
            },
            {
              id: 106912101,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 3,
                statement:
                  'traitement d&rsquo;embl&eacute;e par rivaroxaban sans traitement injectable',
                state: 'V',
                correction:
                  "Les anticoagulants oraux directs (AOD) sont un traitement de premi&egrave;re ligne dans la TVP et l'EP &agrave; risque faible ou interm&eacute;diaire-faible.",
              },
              answer: false,
            },
            {
              id: 106912103,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 4,
                statement:
                  'traitement par &eacute;noxaparine de 5 jours avec arr&ecirc;t &agrave; 5 jours et d&eacute;but de la warfarine le sixi&egrave;me jour',
                state: 'F',
                correction:
                  "Les AVK sont &agrave; introduire d'embl&eacute;e pour pouvoir r&eacute;aliser un relais. Ce dernier n&eacute;cessite au moins 5 jours de chevauchement et deux INR entre 2 et 3 &agrave; 24h d'intervalle.",
              },
              answer: false,
            },
            {
              id: 106912105,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 5,
                statement:
                  'traitement d&rsquo;embl&eacute;e par fondaparinux (Arixtra<sup>&reg;</sup>) &agrave; dose curative',
                state: 'V',
                correction:
                  'Le fondaparinux &agrave; doses curatives est un traitement de premi&egrave;re ligne de la maladie thrombo-embolique veineuse.',
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035314,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 11,
            statement:
              'Quelle(s) mesures doit (doivent) &ecirc;tre associ&eacute;e(s) au traitement anticoagulant ?',
            item: '226',
            knowledge: {
              knowledge: 'N226N10',
              rank: 'A',
              type: 'Prise en charge',
              label:
                "Connaître les principes de traitement d'une TVP/EP non grave à la phase initiale",
              item: {
                item: 226,
                short_title: 'Maladie thrombo-embolique veineuse ',
                title:
                  'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                tag_and_title:
                  'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
              },
              see_also_items: [
                {
                  item: 330,
                  short_title: 'Classes médicamenteuses les plus courantes',
                  title:
                    "Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                  tag_and_title:
                    "N330: Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                },
              ],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['TTT'],
            correction:
              'La prise en charge th&eacute;rapeutique non m&eacute;dicamenteuse de la maladie thrombo-embolique veineuse fait &eacute;cho &agrave; la prise en charge pr&eacute;ventive.',

            coefficient: 1,
            lessons: [],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 11,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912107,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 1,
                statement: 'alitement strict d&rsquo;au moins 48 heures',
                state: 'F',
                correction:
                  "Ce pi&egrave;ge est assez classique. Il faut retenir que le lever est propos&eacute; d&egrave;s que possible dans la thrombose veineuse profonde ou 1h apr&egrave;s d&eacute;but de l'anticoagulant efficace dans l'embolie pulmonaire &agrave; risque faible ou interm&eacute;diaire-faible (cf. r&eacute;f&eacute;rentiel de pneumologie).<br />❗️ Un repos au lit d'au moins 24 heures est par contre indiqu&eacute; dans les embolies pulmonaires &agrave; risque interm&eacute;diaire-&eacute;lev&eacute; et &eacute;lev&eacute;.",
              },
              answer: false,
            },
            {
              id: 106912106,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 2,
                statement: 'aspirine 75 mg',
                state: 'F',
                correction:
                  "Comme indiqu&eacute; plus haut, l'aspirine n'a aucune place dans la prise en charge de la maladie thrombo-embolique veineuse.",
              },
              answer: false,
            },
            {
              id: 106912108,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 3,
                statement: 'compression &eacute;lastique classe III',
                state: 'V',
                correction:
                  "Pour toute thrombose veineuse profonde, la compression &eacute;lastique doit &ecirc;tre maintenue au moins 2 ans ou plus si persistance de sympt&ocirc;mes (NB : curieusement, il est &eacute;voqu&eacute; 6 mois minimum page 351 du r&eacute;f&eacute;rentiel de m&eacute;decine cardiovasculaire et 2 ans &agrave; la page 353 du m&ecirc;me r&eacute;f&eacute;rentiel, tout comme dans le r&eacute;f&eacute;rentiel du CEMV ; il faut probablement retenir les 2 ans). L'objectif attendu est de r&eacute;duire le risque de SPT. La classe III (30-40 mmHg) est effectivement la classe pr&eacute;cis&eacute;e dans le r&eacute;f&eacute;rentiel du CNEC.<br />Elle n'est pas indiqu&eacute;e dans l'embolie pulmonaire sans thrombose veineuse profonde.",
              },
              answer: false,
            },
            {
              id: 106912109,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 4,
                statement:
                  'surveillance par &eacute;chographie-doppler veineuse &agrave; 8 jours',
                state: 'F',
                correction:
                  "Il n'y a pas d'indication au suivi de l'efficacit&eacute; du traitement par &eacute;chographie. Il est seulement recommand&eacute; d'&eacute;valuer les s&eacute;quelles (syndrome post-thrombotique) &agrave; l'issue du traitement, qui durera 3 mois ici.",
              },
              answer: false,
            },
            {
              id: 106912110,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 5,
                statement:
                  'activit&eacute; physique encourag&eacute;e d&egrave;s que possible',
                state: 'V',
                correction:
                  "L'activit&eacute; physique est un concept large, la marche &eacute;tant consid&eacute;r&eacute;e comme une activit&eacute; physique.<br />La reprise du sport est vivement recommand&eacute;e apr&egrave;s la pose d'une proth&egrave;se totale de hanche, les sports &agrave; faible contact peuvent &ecirc;tre repris sans d&eacute;lai. Cette proposition semble tr&egrave;s peu contraignante, il faut cocher.",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035315,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 12,
            statement:
              'Quelle(s) est (sont) la (les) dur&eacute;e(s) pr&eacute;visible(s) du traitement anticoagulant ?',
            item: '226',
            knowledge: {
              knowledge: 'N226N14',
              rank: 'B',
              type: 'Prise en charge',
              label:
                'Savoir déterminer la durée du traitement anticoagulant (TVP proximale et EP)',
              item: {
                item: 226,
                short_title: 'Maladie thrombo-embolique veineuse ',
                title:
                  'Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
                tag_and_title:
                  'N226: Thrombose veineuse profonde et embolie pulmonaire (voir item 330).',
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['TTT'],
            correction:
              'La dur&eacute;e du traitement anticoagulant apr&egrave;s un &eacute;pisode thrombo-embolique veineux est assez bien codifi&eacute;e. Les 4 facteurs influant sur la dur&eacute;e sont les facteurs transitoires majeurs, les facteurs persistants, le risque h&eacute;morragique et la pr&eacute;f&eacute;rence du patient.',

            coefficient: 1,
            lessons: ['duree_ttt_mtev'],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 12,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912111,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 1,
                statement: '6 semaines',
                state: 'F',
                correction:
                  "Cette dur&eacute;e de traitement est celle d'une thrombose veineuse profonde distale avec facteur d&eacute;clenchant &eacute;vident et en l'absence de facteurs de risques persistants.",
              },
              answer: false,
            },
            {
              id: 106912113,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 2,
                statement: '3 mois',
                state: 'V',
                correction:
                  "Il s'agit de la dur&eacute;e de choix ici car il s'agit d'une thrombose veineuse profonde proximale avec facteur transitoire majeur (chirurgie orthop&eacute;dique pour proth&egrave;se de hanche).",
              },
              answer: false,
            },
            {
              id: 106912114,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 3,
                statement: '6 mois',
                state: 'F',
                correction:
                  "S'il n'y avait pas de facteur transitoire majeur pour ce premier &eacute;pisode de thrombose veineuse profonde proximale (ou d'embolie pulmonaire), la dur&eacute;e aurait &eacute;t&eacute; de 6 mois (TVP idiopathique).",
              },
              answer: false,
            },
            {
              id: 106912112,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 4,
                statement: '1 an',
                state: 'F',
                correction:
                  "Il ne s'agit pas d'une dur&eacute;e codifi&eacute;e dans le traitement de la maladie thrombo-embolique veineuse (6 semaines, 3 mois, 6 mois, dur&eacute;e ind&eacute;termin&eacute;e).",
              },
              answer: false,
            },
            {
              id: 106912115,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 5,
                statement:
                  "jusqu'&agrave; disparition du thrombus en &eacute;cho-Doppler",
                state: 'F',
                correction:
                  "L'&eacute;chographie n'est pas un des crit&egrave;res utilis&eacute;s pour d&eacute;finir la dur&eacute;e d'un traitement anticoagulant. On notera que ce dernier n'agit pas directement sur un caillot d&eacute;j&agrave; form&eacute; puisqu'il n'active pas la thrombolyse, mais qu'il emp&ecirc;che son extension et la formation d'autres thrombi.",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035316,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 13,
            statement:
              'Le patient est trait&eacute; par AVK. Quelle(s) proposition(s) est (sont) exacte(s) concernant la surveillance du patient ?',
            item: '330',
            knowledge: {
              knowledge: 'N330N08',
              rank: 'A',
              type: 'Prise en charge',
              label:
                "Anticoagulants oraux (anti-vitamine K (AVK) et anticoagulants oraux directs (AOD)) : connaître les mécanismes d'action, indications, effets secondaires interactions médicamenteuses, modalités de surveillance et principales causes d'échec",
              item: {
                item: 330,
                short_title: 'Classes médicamenteuses les plus courantes',
                title:
                  "Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
                tag_and_title:
                  "N330: Prescription et surveillance des classes de médicaments les plus courantes chez l'adulte et chez l'enfant, hors anti-infectieux (voir item 177). Connaître le bon usage des principales classes thérapeutiques.",
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['TTT'],
            correction:
              'Le maniement des AVK est une connaissance fondamentale exig&eacute;e chaque ann&eacute;e aux ECNi par tous les r&eacute;dacteurs, allant des propri&eacute;t&eacute;s pharmacologiques &agrave; la prise en charge des &eacute;v&eacute;nements ind&eacute;sirables (TIH, h&eacute;morragie, ...), en passant par les indications et modalit&eacute;s th&eacute;rapeutiques ou la surveillance comme ici.',

            coefficient: 1,
            lessons: [],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 13,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912117,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 1,
                statement:
                  'la cr&eacute;atinine doit &ecirc;tre dos&eacute;e r&eacute;guli&egrave;rement',
                state: 'F',
                correction:
                  "Les AVK ne sont pas contre-indiqu&eacute;s dans l'insuffisance r&eacute;nale et ne n&eacute;cessitent pas de dosage r&eacute;gulier de la cr&eacute;atinin&eacute;mie au contraire des HBPM ou du fondaparinux.<br />Le leurre est possiblement fait avec les AOD qui sugg&egrave;rent un suivi de la cr&eacute;atinin&eacute;mie non consensuel afin de d&eacute;pister une d&eacute;gradation de la CCr qui les contre-indiquerait.<br /><br />Ils sont cependant contre-indiqu&eacute;s dans l'insuffisance h&eacute;pato-cellulaire s&eacute;v&egrave;re comme tout anticoagulant devant le risque h&eacute;morragique.",
              },
              answer: false,
            },
            {
              id: 106912118,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 2,
                statement:
                  'l&rsquo;INR doit &ecirc;tre mesur&eacute; au moins 1 fois par mois une fois stabilis&eacute;',
                state: 'V',
                correction:
                  "C'est le <em>cut-off</em> &agrave; retenir. En d&eacute;but de traitement, le rythme de surveillance de l'INR n'est pas consensuel et il est souvent propos&eacute; de surveiller ainsi :<br />\r\n<ul>\r\n<li>2 fois par semaine pendant 15 jours,</li>\r\n<li>puis 1 fois par semaine pendant 15 jours,</li>\r\n<li>puis 1 fois toutes les 2 semaines pendant 1 mois</li>\r\n<li>puis tous les mois.</li>\r\n</ul>",
              },
              answer: false,
            },
            {
              id: 106912116,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 3,
                statement:
                  "le patient doit r&eacute;aliser un dosage de l'INR en cas de saignement gingival inhabituel",
                state: 'V',
                correction:
                  "Un saignement sous AVK doit faire contr&ocirc;ler l'INR en raison de sa labilit&eacute; et du risque que le patient ait &eacute;t&eacute; surdos&eacute;. Si le saignement n'est pas grave selon les crit&egrave;res HAS, il doit &ecirc;tre g&eacute;r&eacute; comme un surdosage asymptomatique.",
              },
              answer: false,
            },
            {
              id: 106912119,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 4,
                statement:
                  "la tenue d'un carnet de surveillance des INR doit &ecirc;tre encourag&eacute;e",
                state: 'V',
                correction:
                  "Il s'agit d'un grand classique de l'utilisation des AVK, permettant ainsi un monitoring pr&eacute;cis des INR en raison de la marge th&eacute;rapeutique &eacute;troite. A cela s'ajoute bien s&ucirc;r une &eacute;ducation th&eacute;rapeutique.",
              },
              answer: false,
            },
            {
              id: 106912120,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 5,
                statement:
                  'la surveillance par &eacute;chographie-doppler permet de d&eacute;cider, en fonction de la reperm&eacute;abilisation, du moment de l&rsquo;arr&ecirc;t du traitement anticoagulant',
                state: 'F',
                correction:
                  "Si l'&eacute;chographie-Doppler est r&eacute;alis&eacute;e &agrave; la fin du traitement de 3 mois, les r&eacute;sultats n'influent en aucun cas sur la dur&eacute;e de traitement (cf. question 12).",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035317,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 14,
            statement:
              'Deux ans plus tard, le patient pr&eacute;sente un &oelig;d&egrave;me de la jambe gauche fluctuant, aggrav&eacute; par l&rsquo;effort. Il existe une dermite ocre et une couronne de t&eacute;langiectasies de l&rsquo;arche plantaire. Les varices sont toujours pr&eacute;sentes. A gauche une &eacute;chographie-doppler montre les &eacute;l&eacute;ments suivants : obstruction compl&egrave;te persistante de la veine iliaque externe, s&eacute;quelles obstructives pari&eacute;tales de la veine f&eacute;morale, reflux poplit&eacute;, incontinence ostio-tronculaire compl&egrave;te de la grande saph&egrave;ne gauche qui est dilat&eacute;e &agrave; 10 mm. Vous &eacute;voquez un syndrome post-thrombotique veineux.<br />Quelle(s) proposition(s) th&eacute;rapeutique(s) vous para&icirc;t (paraissent) pertinente(s) ?',
            item: '227',
            knowledge: {
              knowledge: 'N227N10',
              rank: 'B',
              type: 'Prise en charge',
              label:
                "Connaître les principes de prise en charge thérapeutiques de l'IVC",
              item: {
                item: 227,
                short_title: 'Insuffisance veineuse chronique',
                title: 'Insuffisance veineuse chronique. Varices.',
                tag_and_title:
                  'N227: Insuffisance veineuse chronique. Varices.',
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: ['TTT'],
            correction:
              "Il est d&eacute;sormais question de l'insuffisance veineuse chronique pour terminer ce DP, celle-ci &eacute;tant trait&eacute; d&eacute;sormais exclusivement dans le r&eacute;f&eacute;rentiel de m&eacute;decine vasculaire. Il s'agit d'une insuffisance veineuse chronique (IVC) sur syndrome post-thrombotique occlusif donc secondaire, et cette notion doit &ecirc;tre tr&egrave;s bien ma&icirc;tris&eacute;e, &agrave; l'instar de l'IVC primitive.",

            coefficient: 1,
            lessons: [
              'insuffisance_veineuse_compression',
              'classification_ceap',
            ],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 14,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912121,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 1,
                statement: 'drainage lymphatique manuel',
                state: 'F',
                correction:
                  "Le drainage lymphatique manuel est un traitement du lymphoed&egrave;me (curage ganglionnaire, filariose, ...). Il n'a pas sa place ici.",
              },
              answer: false,
            },
            {
              id: 106912123,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 2,
                statement:
                  'compression &eacute;lastique du membre inf&eacute;rieur gauche de classe III au long cours',
                state: 'V',
                correction:
                  "La compression &eacute;lastique est un traitement de l'IVC. Devant l'&oelig;d&egrave;me chronique signant un stade C3 de la classification CEAP, on proposera une compression &eacute;lastique de classe III (20-36 mmHg) au long cours.",
              },
              answer: false,
            },
            {
              id: 106912122,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 3,
                statement:
                  'traitement endo-veineux thermique de la veine grande saph&egrave;ne',
                state: 'F',
                correction:
                  "Attention, on se place ici dans un syndrome post-thrombotique, c'est-&agrave;-dire que le r&eacute;seau veineux profond est alt&eacute;r&eacute;. Il faut imp&eacute;rativement respecter les veines superficielles, donc les veines saph&egrave;nes, sous peine d'aggraver s&eacute;rieusement l'IVC.",
              },
              answer: false,
            },
            {
              id: 106912124,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 4,
                statement: 'reprise du traitement anticoagulant',
                state: 'F',
                correction:
                  "Le traitement anticoagulant n'est d'aucune utilit&eacute; dans le SPT puisqu'il s'agit d'une alt&eacute;ration pari&eacute;tale vasculaire et non pas d'un processus thrombotique.",
              },
              answer: false,
            },
            {
              id: 106912125,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 5,
                statement: 'prescription de furos&eacute;mide',
                state: 'F',
                correction:
                  "Le furos&eacute;mide n'aurait aucun int&eacute;r&ecirc;t dans ce contexte puisque l'&oelig;d&egrave;me de jambe gauche est li&eacute; &agrave; un d&eacute;faut de drainage veineux d'origine m&eacute;canique (alt&eacute;ration de la paroi vasculaire des veines profondes), et non pas &agrave; une surcharge hydrosod&eacute;e.",
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
        {
          id: 22035318,
          question: {
            composition_unit: undefined,
            collection: 'ECNi 2016',
            type: 'QRM',
            num: 15,
            statement:
              'Quel(s) est (sont) le(s) risque(s) &eacute;volutif(s) possible(s) de ce syndrome post-thrombotique veineux ?',
            item: '227',
            knowledge: {
              knowledge: 'N227N03',
              rank: 'A',
              type: 'Définition',
              label: "Connaître la définition d'un syndrome post-thrombotique",
              item: {
                item: 227,
                short_title: 'Insuffisance veineuse chronique',
                title: 'Insuffisance veineuse chronique. Varices.',
                tag_and_title:
                  'N227: Insuffisance veineuse chronique. Varices.',
              },
              see_also_items: [],
            },
            specialty1: 'MCV',
            specialty2: null,
            categories: [],
            correction:
              "Question assez simple sur les complications d'un syndrome post-thrombotique (SPT).",

            coefficient: 1,
            lessons: ['classification_ceap'],
            similar_questions: [],
            description: '',
            quotes: [{ quote: '', paragraph: '' }],
            footer: '',
            count_qrp: 0,
            sources: [],
            books: [],
          },
          saved: true,
          num: 15,
          grade: 0,
          correction_items_qcm: [
            {
              id: 106912126,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 1,
                statement: 'embolie pulmonaire',
                state: 'F',
                correction:
                  "Le SPT n'est pas cit&eacute; par les r&eacute;f&eacute;rences comme &eacute;tant un facteur de risque thrombo-embolique veineux.",
              },
              answer: false,
            },
            {
              id: 106912127,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 2,
                statement: 'ulc&egrave;re mall&eacute;olaire',
                state: 'V',
                correction:
                  "L'ulc&egrave;re est le stade ultime de l'IVC (stades C5 et C6).",
              },
              answer: false,
            },
            {
              id: 106912128,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 3,
                statement: 'n&eacute;crose d&rsquo;orteil',
                state: 'F',
                correction:
                  "La n&eacute;crose d'orteil est g&eacute;n&eacute;ralement due &agrave; une isch&eacute;mie d'origine art&eacute;rielle (AOMI, emboles de cholest&eacute;rol...).",
              },
              answer: false,
            },
            {
              id: 106912130,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 4,
                statement: 'hypodermite avec ankylose de cheville',
                state: 'V',
                correction:
                  "La dermo-hypodermite de stase ou lipodermatoscl&eacute;rose (stade C4b) est &agrave; risque d'ankylose de cheville en raison de la fibrose engainante et r&eacute;tractile se constituant au tiers inf&eacute;rieur de jambe, apr&egrave;s l'aspect initial inflammatoire avec rougeur cutan&eacute;e et douleurs. C'est un tournant &eacute;volutif de l'IVC car elle signe une alt&eacute;ration d&eacute;finitive du retour veineux.",
              },
              answer: false,
            },
            {
              id: 106912129,
              question_item: {
                quotes: [{ quote: '', paragraph: '' }],

                num: 5,
                statement: 'anasarque',
                state: 'F',
                correction:
                  'Une anasarque d&eacute;signe un syndrome oed&eacute;mateux syst&eacute;mique par hyperhydratation extra-cellulaire. Ici, le risque &oelig;d&eacute;mateux est uniquement local.',
              },
              answer: false,
            },
          ],
          correction_items_qrpl: [],
          correction_item_qroc: null,
          correction_item_qzp: null,
          correction_items_tcs: [],
          tcs_header: null,
        },
      ],
      grade: 0,
      total_coefficient: 15,
    },
  ],
};
