import { FC } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'routing/constants';
import styled from 'styled-components/macro';

interface Props {}

export const Toast403: FC<Props> = () => {
  return (
    <Container>
      <span>
        Vous ne pouvez plus accéder à cette ressource, vous êtes-vous connecté
        sur un autre appareil ?
      </span>
      <NavigationRow>
        <Link
          to={{
            pathname: routes.LOGOUT,
          }}
        >
          Me reconnecter
        </Link>
      </NavigationRow>
    </Container>
  );
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const NavigationRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
