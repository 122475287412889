import { getAPI, postAPI } from 'auth';
import {
  CardsParams,
  FlashDeck,
  FlashCard,
  DoFQCardType,
} from 'types/flash.type';

export const getFQDeckCardsFromAPI = async (params: CardsParams) => {
  const response = await getAPI<FlashDeck>('/flash/deck', { params });
  return response.data;
};

export const getFQCardToDo = async (params: CardsParams) => {
  const response = await getAPI<FlashCard>('/flash/get_card_todo', { params });

  return response.data;
};

export const doFQCard = async (card_id: number) => {
  const response = await postAPI<DoFQCardType>('/flash/do_card/', {
    card_id,
  });

  return response.data;
};
