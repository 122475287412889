import { useGetNumberQueryParams } from 'hooks/useGetNumberQueryParam';
import { useGetCorrectionTask } from 'http/correction/get_task/connector';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routing/constants';
import { Correction } from './Correction';

export const TaskCorrectionPage: FC = () => {
  const taskId = useGetNumberQueryParams('id');
  const groupId = useGetNumberQueryParams('groupid');
  const navigate = useNavigate();

  if (!taskId) {
    navigate(routes.START);
  }

  const { data: taskCorrection } = useGetCorrectionTask(taskId!);

  return (
    <Correction
      correction={taskCorrection}
      initiallySelectedGroupId={groupId}
    />
  );
};
