import { ThemeColors } from './types';

export const lightColors: ThemeColors = {
  primary: {
    light: '#CDE0F6',
    main: '#A5C7EF',
    lighter: '#E1ECFA',
    dark: '#021427',
    contrastText: '#FFFFFF',
  },
  secondary: {
    light: '#D09795',
    main: '#A22F2B',
    dark: '#730F0B',
    contrastText: '#FFFFFF',
  },
  error: {
    light: '#DF8D70',
    main: '#E7531D',
    dark: '#C23705',
    contrastText: '#FFFFFF',
  },
  warning: {
    light: '#FFE0A7',
    main: '#F6B949',
    dark: '#ED9900',
    contrastText: '#FFFFFF',
  },
  success: {
    light: '#CDCC75',
    main: '#4C8701',
    dark: '#2D5100',
    contrastText: '#FFFFFF',
  },
  link: {
    main: '#0000EE',
  },
  background: {
    paper: '#FFFFFF',
    main: '#FFFFFF',
    contrastText: '#01162D',
    modalOverlay: 'rgba(0,0,0,0.5)',
  },
  correctionCard: {
    successMain: '#CBE5E4',
    successComment: '#E5F1F1',
    failedMain: '#F9D5D6',
    failedComment: '#FCEAEB',
  },
  easyDispoSquares: {
    green: '#c7f0cf',
    orange: '#ffeb9d',
    red: '#fec7ce',
  },
  flashQuestions: {
    blueLightTranslucent: '#D9E5F3',
    redLightTranslucent: '#D0979580',
    greenLightTranslucent: '#CDCC7580',
  },
  inactive: '#576068',
  compass: {
    light: '#E1E1E1',
    dark: '#373737',
    selected: '#585858',
    lightSelected: '#D9D9D9',
  },
  button: {
    primary: '#021427',
    primaryHover: '#1C3147',
    primaryDisabled: '#576068',
    secondary: '',
    secondaryHover: '#E1ECFA',
    secondaryDisabled: '#576068',
    activated: '#E1ECFA',
    activatedHover: '#CDE0F6',
    activatedActivated: '#A5C7EF',
    activatedDisabled: '#E1ECFA',
  },
  advertisement: '#193049',
};
