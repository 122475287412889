import { useMutation, useQueryClient } from 'react-query';
import { deleteAPI } from 'auth';
import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';
import { toast } from 'react-toastify';

export interface DeleteFQDeckRequest {
  label: string;
}

// request
const deleteFQDeck = async ({ label }: DeleteFQDeckRequest) => {
  const response = await deleteAPI<void>('/flash/deck/', { label });

  return response.data;
};

// Hook
export const useDeleteFQDeck = () => {
  const client = useQueryClient();

  const mutation = useMutation<void, unknown, DeleteFQDeckRequest>(
    deleteFQDeck,
    {
      onError: (error) => {
        toast.error('Erreur lors de la suppression du paquet.');
        reportErrorToSentry(error);
      },
      onSuccess: () => {
        toast.success('Paquet supprimé');
        client.invalidateQueries('fqDecksData');
      },
    }
  );

  return mutation;
};
