import { useMemo, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { useGetCategoriesList } from 'http/selection/categories-list/connector';
import { updateCategoriesFilter } from 'store/slices/selectionFiltersSlice';

import { GenericSortFilterHeader } from './GenericSortFilterHeader';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';

interface Props {
  isSub?: boolean;
}

export const CategoriesHeader: FC<Props> = ({ isSub }) => {
  const { data } = useGetCategoriesList();
  const filterOptions = useMemo(() => data?.options || [], [data]);

  const isUserFree = useIsUserFree();

  const dispatch = useDispatch();

  const selectedFilters = useSelector(
    (state: RootState) => state.selectionFilters.categories
  );

  const setSelectedFilters = (categories: string[]) => {
    dispatch(updateCategoriesFilter(categories));
  };

  return (
    <GenericSortFilterHeader
      columnName="Cat."
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      sortColumn="categories"
      filterOptions={filterOptions}
      isSub={isSub}
      disabled={isUserFree}
    />
  );
};
