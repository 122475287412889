import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

interface ConfigState {
  isDevMode: boolean;
}

const initialState: ConfigState = {
  isDevMode: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsDevMode: (state, action: PayloadAction<boolean>) => {
      state.isDevMode = action.payload;
    },

    reset: () => initialState,
  },
});

export const selectDevMode = createSelector(
  (root: RootState) => root.app,
  (app) => app.isDevMode
);

export const { reset, setIsDevMode } = appSlice.actions;
export const { reducer: appReducer } = appSlice;
export default appSlice;
