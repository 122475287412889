import { setTags } from '@sentry/browser';

// Sadly the typing of the setTags function is
// kinda messed up so for ease of use the tags
// are typed as any.
// Link to doc: https://getsentry.github.io/sentry-javascript/modules/minimal.html#settags
// You just basically just pass an object with
// all tags as properties
export const setSentryTags = (tags: any) => {
  setTags(tags);
};
