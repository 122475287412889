import { LessonPage } from 'types/lesson.type';
import { LessonPageParams } from '../domain/entities/LessonPageParams';
import { getLessonsHttp } from './api';
import { LessonsPageParams } from 'types/lesson.type';
import { Lesson } from 'types/lesson.type';

export const getLessonsRepository = async (
  params: LessonPageParams
): Promise<LessonPage> => {
  const adapatedParams = adaptParamsToHttpParams(params);
  const lessons = await getLessonsHttp(adapatedParams);
  const formattedLesson: Lesson[] = lessons.results;

  const lessonPage: LessonPage = {
    currentPage: params.page,
    numberOfPages:
      Math.floor(lessons.count / params.pageSize) +
      Math.min(lessons.count % params.pageSize, 1),
    lessons: formattedLesson,
  };
  return lessonPage;
};

const adaptParamsToHttpParams = (
  domainParams: LessonPageParams
): LessonsPageParams => ({
  item: domainParams.itemIds,
  rank: domainParams.knowledgesRank || [],
  page: domainParams.page + 1,
  page_size: domainParams.pageSize,
  search: domainParams.searchTerm ?? null,
  sort_ascending: domainParams.sortOrder === 'ASCENDING' ? true : false,
  sort_by_item: true,
});
