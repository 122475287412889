import { createContext, FC, useContext, useState } from 'react';

interface ClickedQuestionContext {
  clickedQuestion?: number;
  setClickedQuestion: (question: number) => void;
  blockToggle?: boolean;
  setBlockToggle: (toggleValue: boolean) => void;
  isPointPerQuestion: boolean;
  setIsPointPerQuestion: (isPointPerQuestion: boolean) => void;
  handleIsPointSwitch: () => void;
}

const contextDefaultValue: ClickedQuestionContext = {
  clickedQuestion: undefined,
  setClickedQuestion: () => {},
  blockToggle: undefined,
  setBlockToggle: () => {},
  isPointPerQuestion: false,
  setIsPointPerQuestion: () => {},
  handleIsPointSwitch: () => {},
};

const clickedQuestionContext = createContext(contextDefaultValue);

export const ClickedQuestionProvider: FC = ({ children }) => {
  const [clickedQuestion, setClickedQuestion] = useState<number>();
  const [blockToggle, setBlockToggle] = useState<boolean>();
  const [isPointPerQuestion, setIsPointPerQuestion] = useState<boolean>(false);

  const handleIsPointSwitch = () => {
    setIsPointPerQuestion(!isPointPerQuestion);
  };

  return (
    <clickedQuestionContext.Provider
      value={{
        clickedQuestion,
        setClickedQuestion,
        blockToggle,
        setBlockToggle,
        isPointPerQuestion,
        setIsPointPerQuestion,
        handleIsPointSwitch,
      }}
    >
      {children}
    </clickedQuestionContext.Provider>
  );
};

export const useClickedQuestionContext = () =>
  useContext(clickedQuestionContext);

export const STATEMENT_ID = -1;
