import { createContext, FC, useContext, useState } from 'react';

interface OpenedRowContext {
  openedRowCode: string | undefined;
  updateOpenedRowCode: (toggledCode: string) => void;
}

const contextDefaultValue: OpenedRowContext = {
  openedRowCode: '',
  updateOpenedRowCode: () => {},
};

const openedRowContext = createContext(contextDefaultValue);

export const OpenedRowProvider: FC = ({ children }) => {
  const [openedRowCode, setOpenedRowCode] = useState<string | undefined>();

  const updateOpenedRowCode = (toggledCode: string) => {
    if (toggledCode === openedRowCode) {
      setOpenedRowCode(undefined);
    } else {
      setOpenedRowCode(toggledCode);
    }
  };

  return (
    <openedRowContext.Provider value={{ openedRowCode, updateOpenedRowCode }}>
      {children}
    </openedRowContext.Provider>
  );
};

export const useOpenedRowContext = () => useContext(openedRowContext);
