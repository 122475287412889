import styled from 'styled-components/macro';

export const NumberInput = styled.input`
  border: 1px solid ${({ theme }) => theme.colors.primary.dark};
  padding: 4px 16px;
  width: 100px;
  border-radius: 8px;

  ${({ theme }) => theme.typography.body}
`;
