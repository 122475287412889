import { useDispatch, useSelector } from 'react-redux';

import { Checkbox } from 'ui/Checkbox';
import { ColumnHeaderWrapper } from 'components/Filters/ColumnHeaderWrapper';
import { FC, useEffect, useMemo, useState } from 'react';
import { RootState } from 'store';
import { useTableConfigContext } from '../tableConfigContext';
import { ItemInfos } from 'store/types';
import {
  checkIfAllItemsAreSelected,
  getItemsNotAlreadyInOtherItems,
} from './utils';
import { useCanAddToCart } from '../hooks/useCanAddToCart';
import { Menu, MenuItem } from '@szhsin/react-menu';
import { useShowSelectedItemsContext } from '../showSelectedItemsContext';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';

interface Props {
  pageItemInfos: ItemInfos[];
}

export const CheckboxHeader: FC<Props> = ({ pageItemInfos }) => {
  const dispatch = useDispatch();
  const canAddToCart = useCanAddToCart();
  const { tableStateSelector, updateSelectionCreator, tableType } =
    useTableConfigContext();

  const selectedItemsInfos = useSelector(
    (state: RootState) => tableStateSelector(state).selectedItemsInfos
  );

  const [isSelected, setIsSelected] = useState<boolean>(false);

  const areAllCurrentItemsSelected: boolean = useMemo(
    () => checkIfAllItemsAreSelected(pageItemInfos, selectedItemsInfos),
    [pageItemInfos, selectedItemsInfos]
  );

  const isUserFree = useIsUserFree();

  useEffect(() => {
    if (pageItemInfos.length === 0) {
      setIsSelected(false);
    } else {
      setIsSelected(areAllCurrentItemsSelected ? true : false);
    }
  }, [pageItemInfos, selectedItemsInfos, areAllCurrentItemsSelected]);

  const handleCheckboxClick = () => {
    if (areAllCurrentItemsSelected) {
      const selectedItemsWithoutPageItems = getItemsNotAlreadyInOtherItems(
        selectedItemsInfos,
        pageItemInfos
      );
      dispatch(updateSelectionCreator(selectedItemsWithoutPageItems));
    } else {
      const pageItemsWithoutAlreadySelected = getItemsNotAlreadyInOtherItems(
        pageItemInfos,
        selectedItemsInfos
      );

      const numberOfItemsToAddToCart = pageItemsWithoutAlreadySelected.length;
      // TODO fix to include number of questions
      const pageItemsWithoutAlreadySelectedSorted =
        pageItemsWithoutAlreadySelected.reduce(
          (accumulator, currentValue) => {
            if (
              currentValue &&
              currentValue.number_of_questions &&
              currentValue.number_of_questions >= 13
            ) {
              accumulator.long += 1;
            } else if (
              currentValue &&
              currentValue.number_of_questions &&
              currentValue.number_of_questions < 13
            ) {
              accumulator.short += currentValue.number_of_questions;
            }
            return accumulator;
          },
          { short: 0, long: 0 }
        );
      const canAdd = canAddToCart(
        tableType,
        numberOfItemsToAddToCart,
        pageItemsWithoutAlreadySelectedSorted
      );
      if (!canAdd) return;

      const newItemsWithAllCurrent = [
        ...selectedItemsInfos,
        ...pageItemsWithoutAlreadySelected,
      ];
      dispatch(updateSelectionCreator(newItemsWithAllCurrent));
    }
  };

  const { toggleShouldShowSelectedItems, shouldShowSelectedItems } =
    useShowSelectedItemsContext();

  return (
    <Menu
      menuButton={
        <ColumnHeaderWrapper isCheckbox>
          <Checkbox
            checked={isSelected}
            onChange={handleCheckboxClick}
            disabled={isUserFree}
          />
        </ColumnHeaderWrapper>
      }
      transition
    >
      <MenuItem
        onClick={(e) => {
          toggleShouldShowSelectedItems();
        }}
      >
        {shouldShowSelectedItems ? 'Montrer tout' : 'Afficher la sélection'}
      </MenuItem>
    </Menu>
  );
};
