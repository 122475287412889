import { getAPI } from 'auth/authentifiedHttpCalls';
import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';
import { toast } from 'react-toastify';
import { StaticPageLabel, StaticPagesResponse } from './types';

export const getStaticPage = async (page: StaticPageLabel) => {
  try {
    const response = await getAPI<StaticPagesResponse>('/common/static_page', {
      params: {
        label: page,
      },
    });
    return response.data;
  } catch (e) {
    toast.error(
      "Nous n'avons pas réussi à récupérer le contenu de la page veuillez réessayer plus tard"
    );
    reportErrorToSentry(e);
  }
};
