import { QI } from '../../shared/entities/QI';
import { QuestionGroup } from '../../shared/entities/QuestionGroup';
import {
  PreviousGroupTraining,
  PreviousQuestionTraining,
} from '../../shared/entities/PreviousTraining';

import { SessionDetailedProgress } from '../domain/entities/SessionDetailedProgress';

import {
  DetailedGroupHttp,
  DetailedQIHttp,
  GroupTrainingHttp,
  ProgressDetailedSessionResponseHttp,
  QITrainingHttp,
} from './types';

export const adaptSessionDetailedProgress = (
  progressHttp: ProgressDetailedSessionResponseHttp
): SessionDetailedProgress => {
  try {
    return {
      dps: progressHttp.dps.map((dp) => adaptQuestionGroup(dp)),
      qis: progressHttp.qis.map((qi) => adaptQi(qi)),
      lcas: progressHttp.lcas.map((lca) => adaptQuestionGroup(lca)),
      qiMedian: progressHttp.qi_median ?? undefined,
      qiDenom: 120,
    };
  } catch (e) {
    throw new Error(
      "Error in detailed progress by session mapping, unexpected response from '/progress/detailed/session':\n " +
        e
    );
  }
};

function adaptQuestionGroup(dp: DetailedGroupHttp): QuestionGroup {
  return {
    num: dp.num,
    composition_unit: dp.composition_unit,
    specialtyTrigram1: dp.specialty1 ?? undefined,
    specialtyTrigram2: dp.specialty2 ?? undefined,
    denom: dp.denom ?? dp.total_coefficient,
    lastGrade: dp.last_grade ?? undefined,
    median: dp.median,
    thumbnail: dp.thumbnail,
    number_of_questions: dp.number_of_questions,
    previousTrainings: dp.trainings.map((training) =>
      adaptPreviousGroupTraining(training, dp.id)
    ),
    isFree: dp.is_free,
    id: dp.id,
  };
}

function adaptPreviousGroupTraining(
  training: GroupTrainingHttp,
  groupId: number
): PreviousGroupTraining {
  return {
    id: groupId,
    date: new Date(training.last_training_date),
    denom: training.total_coefficient,
    grade: training.grade ?? undefined,
    groupCorrectionId: training.id,
  };
}

function adaptQi(qi: DetailedQIHttp): QI {
  return {
    gradeDenom: qi.coefficient,
    lastGrade: qi.last_grade ?? undefined,
    num: qi.num,
    composition_unit: qi.composition_unit,
    previousTrainings: qi.trainings.map((training) =>
      adaptPreviousQuestionTraining(training, qi.id)
    ),
    specialtyTrigram: qi.specialty1 ?? undefined,
    isFree: qi.is_free,
    mode: qi.mode,
    id: qi.id,
    item: qi.item ?? undefined,
    knowledge: qi.knowledge,
  };
}

function adaptPreviousQuestionTraining(
  training: QITrainingHttp,
  qiId: number
): PreviousQuestionTraining {
  return {
    id: qiId,
    date: new Date(training.last_training_date),
    denom: training.coefficient,
    grade: training.grade ?? undefined,
    groupCorrectionId: training.id,
    qiIdInGroupCorrection: training.correction_question_id,
  };
}
