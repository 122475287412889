import {
  AnyAction,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from 'react-redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  PersistConfig,
} from 'redux-persist';
import { createMigrate } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import { dpTableReducer, dpTableSlice } from './slices/dpTableSlice';
import { lcaTableReducer, lcaTableSlice } from './slices/lcaTableSlice';
import { qiTableReducer, qiTableSlice } from './slices/qiTableSlice';
import {
  selectionFiltersReducer,
  selectionFiltersSlice,
} from './slices/selectionFiltersSlice';
import { selectionReducer, selectionSlice } from './slices/selectionSlice';

import { currentPersistVersion, migrations } from './migrations';
import { startCartReducer, startCartSlice } from './slices/startCartSlice';
import { progressReducer } from './slices/progressSlice';
import { decksTableReducer, decksTableSlice } from './slices/decksTableSlice';
import { fqTableReducer, fqTableSlice } from './slices/fqTableSlice';
import { fqCurrentReducer } from './slices/fqCurrentSlice';
import { fqSettingsReducer, fqSettingsSlice } from './slices/fqSettingsSlice';
import { appReducer, appSlice } from './slices/appSlice';
import { popupReducer } from './slices/popupSlice';
import { ccbReducer, ccbSlice } from './slices/ccbSlice';

const reducers = {
  app: appReducer,
  ccb: ccbReducer,
  qiTable: qiTableReducer,
  dpTable: dpTableReducer,
  lcaTable: lcaTableReducer,
  selectionFilters: selectionFiltersReducer,
  selection: selectionReducer,
  startCart: startCartReducer,
  progress: progressReducer,
  decksTable: decksTableReducer,
  fqTable: fqTableReducer,
  fqCurrent: fqCurrentReducer,
  fqSettings: fqSettingsReducer,
  popup: popupReducer,
};

/** We only use the store signature to get the RootState type */
const typingStore = configureStore({ reducer: reducers });

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof typingStore.getState>;
export type AppDispatch = typeof typingStore.dispatch;

export const rootReducer = combineReducers(reducers);

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage,
  whitelist: [
    ccbSlice.name,
    appSlice.name,
    qiTableSlice.name,
    dpTableSlice.name,
    lcaTableSlice.name,
    selectionFiltersSlice.name,
    selectionSlice.name,
    startCartSlice.name,
    decksTableSlice.name,
    fqTableSlice.name,
    fqSettingsSlice.name,
  ],
  version: currentPersistVersion,
  migrate: createMigrate(migrations, { debug: true }),
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export type AppThunk = ThunkAction<void, RootState, undefined, AnyAction>;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
