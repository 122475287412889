import { createContext, FC, useContext, useState } from 'react';

interface ShowSelectedItemsContext {
  shouldShowSelectedItems: boolean;
  toggleShouldShowSelectedItems: () => void;
}

const contextDefaultValue: ShowSelectedItemsContext = {
  shouldShowSelectedItems: false,
  toggleShouldShowSelectedItems: () => {},
};

const showSelectedItemsContext = createContext(contextDefaultValue);

export const ShowSelectedItemsProvider: FC = ({ children }) => {
  const [shouldShow, setShouldShow] = useState<boolean>(false);

  const toggle = () => setShouldShow(!shouldShow);

  return (
    <showSelectedItemsContext.Provider
      value={{
        shouldShowSelectedItems: shouldShow,
        toggleShouldShowSelectedItems: toggle,
      }}
    >
      {children}
    </showSelectedItemsContext.Provider>
  );
};

export const useShowSelectedItemsContext = () =>
  useContext(showSelectedItemsContext);
