import { FC } from 'react';
import styled from 'styled-components/macro';

import { QIBasicInformations } from 'modules/progress/general/qi/domain/entities/QIBasicInformations';

import { PastCorrectionButton } from '../../components/PastCorrectionButton';
import { usePreviewModalContext } from '../contexts/previewModalContext';
import { useCorrectionModalContext } from '../contexts/correctionModalContext';
import { ChartCheckbox } from '../components/CartCheckbox';
import { SpeIcon } from '../../components/SpeIcon';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';

interface Props {
  question: QIBasicInformations;
}

export const QIProgressRowBySession: FC<Props> = ({ question }) => {
  const { openModal: openPreviewModal } = usePreviewModalContext();
  const { openModal: openCorrectionModal } = useCorrectionModalContext();

  const { isUserPremium } = useIsUserPremium();
  const shouldShowPremiumInfos = isUserPremium || question.isFree;

  return (
    <>
      <Row
        isHighlighted={question.hasBeenDone}
        onClick={() => openPreviewModal(question.id)}
      >
        <NumberCell>{question.num}</NumberCell>
        <Cell>
          <Centered>
            <SpeIcon
              speTrigram={question.speTrigram}
              isFree={question.isFree}
            />
          </Centered>
        </Cell>
        <Cell>{shouldShowPremiumInfos ? question.tags : ''}</Cell>
        <Cell>
          {question.hasBeenDone && (
            <PastCorrectionButton
              isFree={question.isFree}
              onClick={(e) => {
                e.stopPropagation();
                openCorrectionModal({
                  qiId: question.id,
                  correctionId: question.lastGroupCorrectionIdIncludingQI!,
                  qiIdInGroupCorrection: question.qiIdInLastGroupCorrection!,
                });
              }}
            />
          )}
        </Cell>
        <Cell>
          <ChartCheckbox question={question} />
        </Cell>
      </Row>
    </>
  );
};

const Row = styled.tr<{ isHighlighted: boolean }>`
  td {
    background-color: ${({ theme, isHighlighted }) =>
      isHighlighted ? theme.colors.primary.light : 'transparent'};
  }

  &:hover {
    cursor: pointer;

    --border-color: ${({ theme }) => theme.colors.primary.main};

    /*
      To draw the borders we use box-shadows because regular borders
      modify the row height and make everything move
    */
    td {
      box-shadow: inset 0 1px 0 var(--border-color),
        inset 0 -1px 0 var(--border-color);
    }

    td:first-child {
      box-shadow: inset 0 1px 0 var(--border-color),
        inset 0 -1px 0 var(--border-color), inset 1px 0 0 var(--border-color);
    }

    td:last-child {
      box-shadow: inset 0 1px 0 var(--border-color),
        inset 0 -1px 0 var(--border-color), inset -1px 0 0 var(--border-color);
    }
  }
`;

const Cell = styled.td`
  padding: 4px 12px;
  text-align: center;
  vertical-align: middle;
`;

const NumberCell = styled(Cell)`
  ${({ theme }) => theme.typography.label}
`;

const Centered = styled.div`
  display: grid;
  place-items: center;
`;
