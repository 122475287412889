import { PageContent } from './components/PageContent';
import { DisplayOptionsMenu } from './ui/DisplayOptionsMenu';
import { TopPageContent } from './components/TopPageContent';
import { HomeItemsFiltersProvider } from './context/homeItemsFiltersContext';
import { HomeSpecialiesFiltersProvider } from './context/homeSpecialtiesFiltersContext';
import { HomeSearchProvider } from './context/homeSearchContext';
import { ScrollToTop } from 'helpers/scroll/scrollToTop';
import styled from 'styled-components';
import { useHomeInfoContext } from './context/homeInfoContext';

export const WholePageContent = () => {
  const { shownPage } = useHomeInfoContext();
  return (
    <>
      <TitleContainer>
        <h1>Accueil</h1>
        <DisplayOptionsMenu />
      </TitleContainer>
      <HomeItemsFiltersProvider>
        <HomeSpecialiesFiltersProvider>
          <HomeSearchProvider>
            <ScrollToTop trigger={shownPage}>
              <TopPageContent />
              <PageContent />
            </ScrollToTop>
          </HomeSearchProvider>
        </HomeSpecialiesFiltersProvider>
      </HomeItemsFiltersProvider>
    </>
  );
};

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
