import styled from 'styled-components';
import { Star } from 'ui/icons/Star';
import { StudyTours } from './StudyTours';
import BlueCard from 'ui/Cards/BlueCard';
import { useUpdateItemCard, useUpdateSpeCard } from 'http/new-home/connector';
import { useEffect, useState } from 'react';
import { useHomeInfoContext } from '../context/homeInfoContext';

interface CardProps {
  children: React.ReactNode;
  onCardClick: () => void;
  activeTourNumber: number;
  cardId: string | number;
  cardType: 'specialty' | 'item';
  isFav: boolean;
}

export const RevisionCard: React.FC<CardProps> = ({
  children,
  activeTourNumber,
  onCardClick,
  cardId,
  cardType,
  isFav,
}) => {
  const [isFavorite, setIsFavorite] = useState<boolean>(isFav);
  const [clickedTour, setClickedTour] = useState<number>(0);
  const { updateSpeCard } = useUpdateSpeCard();
  const { updateItemCard } = useUpdateItemCard();
  const { activeTab } = useHomeInfoContext();

  useEffect(() => {
    setIsFavorite(isFav);
    setClickedTour(activeTourNumber);
  }, [isFav, activeTourNumber]);

  const handleClickFav = () => {
    setIsFavorite(!isFavorite);
    if (cardType === 'specialty') {
      updateSpeCard({
        specialty: cardId as string,
        favorite: !isFavorite,
      });
    } else {
      updateItemCard({
        item: cardId as number,
        favorite: !isFavorite,
      });
    }
  };

  const handleTourClick = (newTour: number) => {
    setClickedTour(newTour);
    if (activeTab === 'specialties' && cardId) {
      updateSpeCard({
        specialty: cardId as string,
        round: newTour,
      });
    }
    if (activeTab === 'items' && cardId) {
      updateItemCard({
        item: cardId as number,
        round: newTour,
      });
    }
  };

  return (
    <StyledBlueCard onClick={onCardClick}>
      {children}
      <CardFooter onClick={(e) => e.stopPropagation()}>
        <Star
          isSelected={isFavorite}
          onClick={(e) => {
            e.stopPropagation();
            handleClickFav();
          }}
        />
        <StudyTours
          activeTourNumber={clickedTour}
          handleClick={handleTourClick}
        />
      </CardFooter>
    </StyledBlueCard>
  );
};

const StyledBlueCard = styled(BlueCard)`
  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
  }
`;

const CardFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
