import { FormattedDispo } from 'http/rank-info/easy-dispo/connector';
import { YearData } from 'http/rank-info/easy-dispo/types';
import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import {
  getCellContent,
  getStyle,
  SpecialtyDataType,
} from '../helpers/dispoTableHelpers';
import { useEmptyTable } from 'http/rank-info/easy-dispo/connector';

interface Props {
  dispo: FormattedDispo | undefined;
  className?: string;
  ranks?: number[];
  numOfCandidates?: number[];
}

export interface CHUandSpeInfo {
  speLabel?: string;
  chuLabel?: string;
  speCondensedLabel?: string;
  chuCondensedLabel?: string;
}

const getToolTipContent = (data: SpecialtyDataType) => {
  const toolTipContent = Object.keys(data)
    .filter((keyValue) => {
      if (keyValue === 'total_accepted' || keyValue === 'label') {
        return false;
      }
      const typedYear = data[keyValue] as YearData;
      return !(typedYear.n_positions === 0);
    })
    .map((year, index) => {
      const typedYear = data[year] as YearData;
      return (
        <YearDataText key={year + '_' + index}>
          {!typedYear.accepted
            ? year + ' : Non Disponible'
            : year +
              ' : ' +
              typedYear.rank +
              ' / ' +
              typedYear.n_positions +
              (typedYear.CESP_only ? ' CESP uniquement' : '')}
        </YearDataText>
      );
    });

  return toolTipContent.length > 0 ? (
    <ToolTip className="easy-dispo-tooltip">
      <ToolTipLayout>{toolTipContent}</ToolTipLayout>
    </ToolTip>
  ) : null;
};

export const DispoTable: FC<Props> = ({ dispo, className }) => {
  const theme = useTheme();
  const { defaultChuOptions, defaultSpecialtyOptions } = useEmptyTable();

  return (
    <Container className={className}>
      <Table>
        <Thead>
          <Tr>
            <Th></Th>
            {dispo
              ? dispo[0].specialties.map((specialty, index) => (
                  <Th key={specialty.condensedLabel + '_' + index}>
                    <span>{specialty.condensedLabel}</span>
                    <ToolTip className="easy-dispo-tooltip">
                      <ToolTipLayout>{specialty.label}</ToolTipLayout>
                    </ToolTip>
                  </Th>
                ))
              : defaultSpecialtyOptions.map((speciality, index) => (
                  <Th key={speciality.label + '_' + index}>
                    <span>{speciality.label}</span>
                    <ToolTip className="easy-dispo-tooltip">
                      <ToolTipLayout>{speciality.value}</ToolTipLayout>
                    </ToolTip>
                  </Th>
                ))}
          </Tr>
        </Thead>
        <Tbody>
          {dispo
            ? dispo.map((chu, index) => (
                <Tr key={chu.condensedLabel + '_' + index}>
                  <Td>
                    <span>{chu.condensedLabel}</span>
                    <ToolTipBottom className="easy-dispo-tooltip">
                      <ToolTipLayout>{chu.label}</ToolTipLayout>
                    </ToolTipBottom>
                  </Td>
                  {chu.specialties.map((specialty) => (
                    <Td
                      key={chu.condensedLabel + '_' + specialty.label}
                      style={getStyle(specialty.data, theme)}
                      data-tip
                      data-for={chu + '_' + specialty.label}
                    >
                      {getCellContent(specialty.data)}
                      {getToolTipContent(specialty.data)}
                    </Td>
                  ))}
                </Tr>
              ))
            : defaultChuOptions.map((chu, index) => (
                <Tr key={chu.label + '_' + index}>
                  <Td>
                    {chu.label}
                    <ToolTipBottom className="easy-dispo-tooltip">
                      <ToolTipLayout>{chu.value}</ToolTipLayout>
                    </ToolTipBottom>
                  </Td>
                </Tr>
              ))}
        </Tbody>
      </Table>
      {!dispo && (
        <MsgContainer>
          <Message>
            Simulez votre rang et validez pour afficher la simulation des postes
            possibles
          </Message>
        </MsgContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  margin-right: 8px;
  width: 100%;
`;

const Table = styled.table`
  ${({ theme }) => theme.typography.textBody2}
  border-collapse: collapse;
  border-radius: 8px;
  table-layout: fixed;

  tr:first-child th {
    border-top: 0;
  }
  tr:last-child td {
    border-bottom: 0;
  }
  tr td:first-child,
  tr th:first-child {
    border-left: 0;
  }
  tr td:last-child,
  tr th:last-child {
    border-right: 0;
  }
`;

const Thead = styled.thead`
  position: sticky;
  top: 0;
  z-index: 1;

  th {
    position: sticky;
    z-index: 1;
  }

  tr:first-child th:first-child {
    border-top-left-radius: 8px;
    position: sticky;
    left: 0;
    z-index: 2;
  }
  tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }
`;

const Tr = styled.tr``;

const Th = styled.th`
  position: relative;
  padding: 14px 2px;
  background: ${({ theme }) => theme.colors.primary.light};
  border: 1px solid ${({ theme }) => theme.colors.primary.main};

  &:hover {
    .easy-dispo-tooltip {
      display: block;
    }
  }
`;
const Tbody = styled.tbody`
  th {
    position: relative;
  }

  tr:last-child th:first-child {
    border-bottom-left-radius: 8px;
  }
  tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
  tr:last-child th:first-child {
    border-bottom: 0;
  }
`;

const Td = styled.td`
  position: relative;
  padding: 10px;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.primary.main};

  &:first-child {
    position: sticky;
    left: 0;
    background: ${({ theme }) => theme.colors.primary.light};
    color: ${({ theme }) => theme.colors.background.contrastText};
    z-index: 2;
  }
  &:hover {
    .easy-dispo-tooltip {
      display: block;
      z-index: 3;
    }
  }
  color: #01162d;
`;

const ToolTip = styled.div`
  position: absolute;
  z-index: 10;
  top: 100%;
  display: none;
  background: ${({ theme }) => theme.colors.background.contrastText};
  opacity: 80%;
  color: ${({ theme }) => theme.colors.primary.contrastText};
  text-align: center;
  padding: 8px;
  left: 50%;
  margin-top: 5px;
  transform: translateX(-50%);
  min-width: 0;
  min-height: 0;
  transition: min-width 1s, min-height 1s;
  &::after {
    content: ' ';
    position: absolute;
    bottom: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`;

const ToolTipBottom = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 100%;
  display: none;
  background: ${({ theme }) => theme.colors.background.contrastText};
  opacity: 80%;
  color: ${({ theme }) => theme.colors.primary.contrastText};
  text-align: center;
  padding: 8px;
  left: 50%;
  margin-top: 5px;
  transform: translateX(-50%);
  min-width: 0;
  min-height: 0;
  transition: min-width 1s, min-height 1s;
  &::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the top of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
  }
`;

const MsgContainer = styled.div`
  position: absolute;
  top: 20%;
  left: 60%;
  transform: translate(-50%, -50%);
  width: fit-content;
  color: ${({ theme }) => theme.colors.inactive};
  min-width: 50%;
`;

const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.typography.h5}
`;

const ToolTipLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 5px;
  transition: opacity 1s;
`;

const YearDataText = styled.span`
  white-space: nowrap;
`;
