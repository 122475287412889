import { FC } from 'react';
import styled from 'styled-components/macro';

import { SpecialtyIcon } from 'ui/icons/SpecialtyIcon/SpecialtyIcon';
import { useGetSpecialityInfos } from 'http/selection/specialities-list/connectors';
import { Tooltip } from 'components/Tooltips/Tooltip';

interface Props {
  trigram: string;
  forceSmall?: boolean;
}

export const Specialty: FC<Props> = ({ trigram, forceSmall }) => {
  const spe = useGetSpecialityInfos(trigram);
  const longName = spe?.name;

  return (
    <Tooltip content={longName} arrow={false} placement="right">
      <Container>
        <Icon trigram={trigram} />
      </Container>
    </Tooltip>
  );
};

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  font-size: inherit;
  font-weight: inherit;
`;

const Icon = styled(SpecialtyIcon)`
  height: 24px;
  width: 24px;
`;
