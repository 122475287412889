import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { createContext, useContext } from 'react';

import { RootState } from 'store';
import {
  sortBy,
  setPageSize,
  setPage,
  DecksTableState,
} from 'store/slices/decksTableSlice';
import { SortByPayload } from 'store/types';

interface DecksTableConfigContext {
  sortByCreator: ActionCreatorWithPayload<SortByPayload, string>;
  tableStateSelector: (state: RootState) => DecksTableState;
  setPageSizeCreator: ActionCreatorWithPayload<number, string>;
  setPageCreator: ActionCreatorWithPayload<number, string>;
}

const tableStateSelector = (state: RootState) => state.decksTable;

const contextDefaultValue: DecksTableConfigContext = {
  sortByCreator: sortBy,
  tableStateSelector: tableStateSelector,
  setPageSizeCreator: setPageSize,
  setPageCreator: setPage,
};

const decksTableConfigContext = createContext(contextDefaultValue);

export const DecksTableConfigContextProvider = decksTableConfigContext.Provider;

export const useDecksTableConfigContext = () =>
  useContext(decksTableConfigContext);
