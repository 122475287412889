import { useQuery } from 'react-query';
import { getLessonsQuestions } from './api';
import { SimilarQuestion } from 'types/correction.type';

export const useGetLessonRelatedQuestion = (lessonId: string) => {
  const { data } = useQuery<SimilarQuestion[]>(
    ['getsQuestions', lessonId],
    () => {
      return getLessonsQuestions(lessonId);
    },
    {
      enabled: !!lessonId,
    }
  );
  if (!data) return { data: [] };
  return { data };
};
