import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { useGetUserOptions } from 'modules/user-options/hooks/useGetUserOptions';
import { usePatchUserOptions } from 'modules/user-options/hooks/usePatchUserOptions';

export const useAccount = () => {
  const { data: userOptions, isError: hasFetchFailed } = useGetUserOptions();
  const { mutate: updateUserOptions, isError: hasUpdateFailed } =
    usePatchUserOptions();

  useEffect(() => {
    if (hasUpdateFailed) {
      toast.error(
        'Impossible de mettre à jour votre profil pour le moment, veuillez réessayer plus tard'
      );
    }
  }, [hasUpdateFailed]);

  useEffect(() => {
    if (hasFetchFailed) {
      toast.error(
        'Impossible de récupérer les informations de votre profil pour le moment, veuillez réessayer plus tard'
      );
    }
  }, [hasFetchFailed]);

  return { userOptions, updateUserOptions };
};
