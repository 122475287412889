import { MS_IN_AN_HOUR } from 'constants/date';
import { getBooksHttp } from './api';
import { useQuery } from 'react-query';

export const useGetBooks = (codes: string[]) => {
  const getBooks = async (codes: string[]) => {
    if (!codes || !codes.length) return [];
    const query = await getBooksHttp(codes);
    return query;
  };

  const { data: booksInfos } = useQuery(
    ['getBooks', codes],
    () => getBooks(codes),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }
  );

  return booksInfos;
};
