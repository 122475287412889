import { isLoggedIn } from 'auth/loginFunctions';
import { FC } from 'react';
import { RouteProps, useLocation, Navigate, Outlet } from 'react-router-dom';
import { routes } from './constants';
import { ProtectedRouteRoutingState } from './types';

// Copied from : https://stackoverflow.com/a/69592617/14707543
export const ProtectedRoute: FC<RouteProps> = ({ children }) => {
  const location = useLocation();

  if (!isLoggedIn()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    const routingState: ProtectedRouteRoutingState = {
      from: location.pathname,
    };
    return <Navigate to={routes.LOGIN} state={routingState} />;
  }

  return <Outlet />;
};
