import { FC } from 'react';
import styled from 'styled-components/macro';

interface Props {}

export const LcaProgressHeader: FC<Props> = () => {
  return (
    <Row>
      <td>Session</td>
      <td>Num</td>
      <td>Spécialité</td>
      <td>Article</td>
      <td>Dernière note</td>
      <td>Médiane nationale</td>
      <td></td>
      <td></td>
    </Row>
  );
};

const Row = styled.tr`
  --row-border-radius: 8px;

  td:first-child {
    border-top-left-radius: var(--row-border-radius);
    border-bottom-left-radius: var(--row-border-radius);
  }

  td:last-child {
    border-top-right-radius: var(--row-border-radius);
    border-bottom-right-radius: var(--row-border-radius);
  }

  td {
    padding: 8px 16px;
    ${({ theme }) => theme.typography.label}
    background-color: ${({ theme }) => theme.colors.primary.main};
  }
`;
