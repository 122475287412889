import { FC } from 'react';
import styled from 'styled-components/macro';
import Tippy from '@tippyjs/react';
import { useClickedQuestionContext } from '../../clickedQuestionContext';
import {
  computeGradeForARankOnTrainingOnTwenty,
  isMoreThanFourteenOutOfTwenty,
} from './points-formatter';
import { CorrectionTaskResponse } from 'types/correction.type';
import RankLetter from 'ui/Misc/RankLetter';
import { formatGrade } from 'helpers/grades/utils';

interface Props {
  correctionData: CorrectionTaskResponse;
}

export const CorrectionHeader: FC<Props> = ({ correctionData }) => {
  const { isPointPerQuestion } = useClickedQuestionContext();
  const isThereARankQuestionAmongTraining = true;

  return (
    <div id="correction-header">
      <HeaderTable>
        <tbody>
          <GradeRow>
            <HeaderRowCell>Ma session</HeaderRowCell>
            <HeaderRowCell id="correction-global-grade">
              {'Note globale '}
              {isPointPerQuestion
                ? `${formatGrade(correctionData.grade)} /${
                    correctionData.total_coefficient
                  }`
                : `${formatGrade(
                    (correctionData.grade / correctionData.total_coefficient) *
                      20
                  )}/20`}
            </HeaderRowCell>
            <HeaderRowCell id="correction-rank-grade">
              <RankAContainer>
                <GradeInfosContainer>
                  <Tippy
                    content={
                      <>
                        Il faut obtenir une note ≥ 14/20 sur les questions dites
                        “fermées” (QRU, QRP, QROC, QZP) portant sur une
                        connaissance de rang A.
                      </>
                    }
                  >
                    <RankLetter letter={'A'}>A</RankLetter>
                  </Tippy>
                  {isThereARankQuestionAmongTraining ? (
                    <>
                      <AGradeContainer>
                        {isPointPerQuestion
                          ? `${formatGrade(correctionData.rank_A_grade)}/${
                              correctionData.rank_A_coefficient
                            }`
                          : `${formatGrade(
                              (correctionData.rank_A_grade /
                                correctionData.rank_A_coefficient) *
                                20
                            )}/20`}
                      </AGradeContainer>
                      {!isMoreThanFourteenOutOfTwenty(
                        computeGradeForARankOnTrainingOnTwenty(correctionData)
                      ) && (
                        <Tippy
                          content={
                            'Note inférieure au seuil fixé par le concours (<14/20)'
                          }
                        >
                          <WarningSign>!</WarningSign>
                        </Tippy>
                      )}
                    </>
                  ) : (
                    <AGradeContainer>--/--</AGradeContainer>
                  )}
                </GradeInfosContainer>
              </RankAContainer>
            </HeaderRowCell>
          </GradeRow>
        </tbody>
      </HeaderTable>
    </div>
  );
};

const HeaderTable = styled.table`
  margin-right: 8px;
  border-collapse: collapse;
  width: calc(100% - 16px);
`;

const HeaderRowCell = styled.td`
  padding: 12px 8px;
  font-size: 20px;
  white-space: nowrap;
  padding: 12px 28px;
  size: ${({ theme }) => theme.typography.h2};
`;

const Row = styled.tr`
  ${HeaderRowCell}:first-child {
    padding-left: 28px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    width: 346px;
  }

  ${HeaderRowCell}:last-child {
    padding-right: 28px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const GradeRow = styled(Row)`
  background-color: ${({ theme }) => theme.colors.primary.light};
  ${HeaderRowCell} {
    ${({ theme }) => theme.typography.label}
  }
`;

const RankAContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
`;

const GradeInfosContainer = styled.div`
  display: flex;
  align-items: center;
`;

// const RankLetter = styled.div`
//   display: flex;
//   justify-content: center;
//   align-content: center;
//   background-color: ${({ theme }) => theme.colors.secondary.main};
//   color: ${({ theme }) => theme.colors.primary.contrastText};
//   border-radius: 50%;
//   width: 24px;
//   height: 24px;
// `;

const WarningSign = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.error.main};
  color: ${({ theme }) => theme.colors.error.main};
`;

const AGradeContainer = styled.div`
  margin: 0 16px;
`;
