import { buttonReset } from 'helpers/css/button-reset';
import { SelectionGroupResult } from 'http/selection-group/types';
import { useGetGroupThumbnail } from 'http/selection/group_thumbnail/connector';
import { FC, useState } from 'react';
import styled from 'styled-components/macro';
import parse from 'html-react-parser';

import { Modal, ModalHeader } from 'ui/Modal';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';
import { PremiumLockedRessource } from 'components/PremiumLockedRessource';
import { StyledMathJax } from 'ui/MathJax';

interface Props {
  groupData: SelectionGroupResult;
}

export const GroupThumbnail: FC<Props> = ({ groupData }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isUserPremium } = useIsUserPremium();

  const isRessourceAvailableToUser = isUserPremium || groupData.is_free;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const { data, isLoading } = useGetGroupThumbnail({
    group_id: groupData.id,
  });

  return (
    <>
      <Chip onClick={isRessourceAvailableToUser ? openModal : () => {}}>
        <PremiumLockedRessource
          ressourceName="Vignette"
          isFree={groupData.is_free}
        >
          Vignette
        </PremiumLockedRessource>
      </Chip>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalHeader onCloseButtonClick={closeModal}>
          <h3>
            {groupData?.collection} - {groupData?.type} {groupData?.num}
          </h3>
        </ModalHeader>

        <ContentContainer>
          <h4>Vignette</h4>

          <div>
            {isLoading && 'Chargement...'}
            {data && (
              <JustifiedText>
                <StyledMathJax inline>{parse(data.thumbnail)}</StyledMathJax>
              </JustifiedText>
            )}
          </div>
        </ContentContainer>
      </Modal>
    </>
  );
};

const Chip = styled.button`
  ${buttonReset}
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.primary.dark};
  color: ${({ theme }) => theme.colors.primary.contrastText};
  border-radius: 4px;
`;

const ContentContainer = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const JustifiedText = styled.p`
  text-align: justify;
`;
