import { FC } from 'react';
import styled from 'styled-components/macro';
import { PreviewBlockProps } from '../../types';
import { formatQZPImageUrl } from 'pages/new_training/components/QZP/SingleAnswer';

export const QZPPreviewBlock: FC<PreviewBlockProps> = ({ questionData }) => {
  return (
    <Container>
      <ImageContainer>
        <Image src={formatQZPImageUrl(questionData.item_qzp?.image ?? '')} />
      </ImageContainer>

      <h5>Placez les labels suivants sur l'image :</h5>
      <PropositionsContainer>
        {questionData.item_qzp?.labels.map((label) => (
          <Proposition key={label}>{label}</Proposition>
        ))}
      </PropositionsContainer>
    </Container>
  );
};

export const Container = styled.div`
  margin: 0 28px;
  padding: 16px;

  display: flex;
  flex-direction: column;
`;

const ImageContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: start;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
`;

const PropositionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 8px;
`;

const Proposition = styled.div`
  border-radius: 8px;
  padding: 8px 16px;
  width: 100%;

  border: 2px solid ${({ theme }) => theme.colors.primary.light};
  background-color: ${({ theme }) => theme.colors.background.paper};
`;
