import { APIError, getAPI } from 'auth/authentifiedHttpCalls';
import { toast } from 'react-toastify';
import { PriceInfos, PromoResponse } from './types';

export const getPrices = async () => {
  try {
    const response = await getAPI<PriceInfos[]>('/payments/products/');
    return response.data;
  } catch (err) {
    if (err instanceof Error) {
      const e = err as APIError;
      toast.error(e.errorMessages);
    }

    throw err;
  }
};

export const verifyCoupon = async (coupon: string, product_id: number) => {
  try {
    const response = await getAPI<PromoResponse>(`/payments/verify_coupon/`, {
      params: { coupon, product_id },
    });
    return response.data;
  } catch (err) {
    if (err instanceof Error) {
      const e = err as APIError;
      toast.error(e.errorMessages);
    }

    throw err;
  }
};
