import { useEffect, useState } from 'react';
import { useStopwatch, StopwatchResult } from 'react-timer-hook';
import { getTimerFromAPI } from './timer-functions/getTimerfromAPI';
import { pauseTimerFromAPI } from './timer-functions/pauseTimerFromAPI';
import { startTimerFromAPI } from './timer-functions/startTimerFromAPI';

type BaseTimerType = Omit<
  StopwatchResult,
  'days' | 'reset' | 'start' | 'pause'
>;

export interface TimerType extends BaseTimerType {
  startTimer: (taskId: number) => void;
  pauseTimer: (taskId: number) => void;
}

export const useTrainingTimer = (
  totalTimeInSeconds: number,
  offsetTimeInSeconds: number,
  onTimerEnd?: () => void
) => {
  const offsetDate = new Date();
  const [totalTime, setTotalTime] = useState<number>(totalTimeInSeconds);
  offsetDate.setSeconds(offsetDate.getSeconds() + offsetTimeInSeconds);
  const { seconds, minutes, hours, days, isRunning, start, pause, reset } =
    useStopwatch({ autoStart: false, offsetTimestamp: offsetDate });

  useEffect(() => {
    if (isRunning && hours * 3600 + minutes * 60 + seconds > totalTime) {
      onTimerEnd && onTimerEnd();
    }
    // eslint-disable-next-line
  }, [hours, minutes, seconds, totalTime]);

  const resetTimer = async (taskId: number) => {
    if (taskId) {
      const timerParams = await getTimerFromAPI(taskId);
      if (timerParams) {
        const newoffsetDate = new Date();
        newoffsetDate.setSeconds(
          newoffsetDate.getSeconds() +
            timerParams.total_time -
            timerParams.remaining_time
        );
        setTotalTime(timerParams.total_time);
        reset(newoffsetDate, false);
        startTimer(taskId);
      }
    }
  };

  const pauseTimer = (taskId: number) => {
    pause(); // Used for the user comfort, to avoid waiting for the api call
    pauseTimerFromAPI(taskId, pause, start);
  };

  const startTimer = (taskId: number) => {
    start(); // Used for the user comfort, to avoid waiting for the api call
    startTimerFromAPI(taskId, start, pause);
  };

  return {
    hours: hours + days * 24,
    minutes: minutes,
    seconds,
    isRunning,
    startTimer,
    pauseTimer,
    resetTimer,
  };
};
