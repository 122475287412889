import { FC, useMemo } from 'react';

import { SelectionQIResult } from 'http/selection-qi/types';
import { Tr, Td } from 'ui/TableElements';
import { CheckboxCell } from 'pages/selection/components/CheckboxCell';
import { Specialities } from 'pages/selection/components/Specialties';
import { KnowledgeRank } from 'components/Knowledge/KnowledgeRank';
import { PremiumLockedRessource } from 'components/PremiumLockedRessource';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';
import { FormattedDate } from 'pages/selection/components/FormattedDate';
import { Item } from 'components/Item/Item';

interface Props {
  rowData: SelectionQIResult;
  index: number;
}

export const QIRow: FC<Props> = ({ rowData, index }) => {
  const isOdd = useMemo(() => index % 2 === 1, [index]);
  const hasItemBeenDone = !!rowData.last_training;
  const { isUserPremium } = useIsUserPremium();

  return (
    <>
      <Tr isOdd={isOdd}>
        <Td isCheckbox>
          <CheckboxCell itemInfos={rowData} isFree={rowData.is_free} />
        </Td>
        <Td>{rowData.collection}</Td>
        <Td>
          {rowData.composition_unit && `UC${rowData.composition_unit} `}Q
          {rowData.num}
        </Td>
        <Td>
          <PremiumLockedRessource
            ressourceName="Contenu abonné"
            isFree={rowData.is_free}
          >
            <Specialities
              trigram1={rowData.specialty1}
              trigram2={rowData.specialty2}
              hasItemBeenDone={hasItemBeenDone}
            />
          </PremiumLockedRessource>
        </Td>
        <Td>
          {rowData.knowledge &&
            rowData.knowledge.label &&
            rowData.knowledge.rank && (
              <KnowledgeRank
                label={rowData.knowledge?.label}
                rank={rowData.knowledge?.rank}
              />
            )}
        </Td>
        <Td>
          <PremiumLockedRessource ressourceName="" isFree={rowData.is_free}>
            {rowData && rowData.knowledge && rowData.knowledge.item && (
              <Item item={rowData.knowledge.item} />
            )}{' '}
          </PremiumLockedRessource>
        </Td>
        <Td>{isUserPremium && rowData.categories}</Td>
        <Td>
          {rowData.last_training_date && (
            <>
              <FormattedDate date={new Date(rowData.last_training_date)} /> -{' '}
              {`${rowData.grade}/${rowData.coefficient}`}
            </>
          )}
        </Td>
        <Td>
          <PremiumLockedRessource ressourceName="" isFree={rowData.is_free}>
            {rowData.type}
          </PremiumLockedRessource>
        </Td>
      </Tr>
    </>
  );
};
