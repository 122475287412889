import { useGetGroupHistory } from 'modules/group-history/hooks/useGetGroupHistory';
import { FC, useState } from 'react';
import styled from 'styled-components/macro';
import { Pagination } from 'components/Pagination/Pagination';
import { GroupHistoryRow } from './GroupHistoryRow';

interface Props {}

export const GroupHistoryList: FC<Props> = () => {
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageSize, _setPageSize] = useState<number>(20);
  const setPageSize = (val: number) => {
    _setPageSize(val);
    setCurrentPage(0);
  };

  const { data: paginatedGroupHistory } = useGetGroupHistory({
    shouldRetrieveFinishedGroups: true,
    pageNumber: currentPage + 1,
    pageSize: pageSize,
  });

  const groupHistory = paginatedGroupHistory?.data;
  const totalPages = groupHistory
    ? Math.ceil(groupHistory.length / pageSize)
    : 0;

  return (
    <>
      <Container>
        <h2>Historique</h2>
        <Grid>
          {groupHistory &&
            groupHistory.map((group) => (
              <GroupHistoryRow group={group} key={group.groupCorrectionId} />
            ))}
        </Grid>
      </Container>
      <Pagination
        numberOfPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        options={[
          { label: `10`, value: 10 },
          { label: `20`, value: 20 },
        ]}
      />
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 16px;
  border: 2px solid ${({ theme }) => theme.colors.primary.dark};
  border-radius: 1rem;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr 3fr 50px;
  gap: 8px;
`;
