import { useEffect, useState } from 'react';

export const useScreenMatchesMediaQuery = (mediaQuery: string) => {
  const [isOnDesktop, setIsOnDesktop] = useState<boolean>(true);

  const checkIsOnDesktop = () => {
    const isOnDesktop =
      window.matchMedia && window.matchMedia(mediaQuery).matches;
    setIsOnDesktop(isOnDesktop);
  };

  useEffect(() => {
    window.addEventListener('resize', checkIsOnDesktop, true);
    return () => {
      window.removeEventListener('resize', checkIsOnDesktop, true);
    };
  });

  return isOnDesktop;
};
