import { CorrectionQuestion } from 'types/correction.type';
import { QROCCorrectionData } from './types';

export const adaptQROCCorrectionData = (
  questionData: CorrectionQuestion
): QROCCorrectionData => {
  const isUserRight = questionData.grade ? questionData.grade > 0 : false;
  const userInput =
    questionData.correction_item_qroc?.answer !== null
      ? questionData.correction_item_qroc?.answer
      : "Vous n'avez pas répondu à cette question";

  const expectedAnswers =
    questionData.correction_item_qroc?.question_items || [];

  return {
    isUserRight,
    userInput,
    expectedAnswers,
  };
};
