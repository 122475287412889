import { FC } from 'react';
import styled from 'styled-components';
import { Slider } from './Slider';

export interface Props {
  className?: string;
  header: {
    first_column: string;
    second_column: string;
    third_column: string;
    legend?: string;
  };
  answers: Array<{
    id: number;
    answer: number;
    question_item: {
      num: number;
      first_column: string;
      second_column: string;
    };
  }>;
  handleChange(index: number, number: number): void;
  disabled?: boolean;
  saved?: boolean;
}

export const SingleAnswer: FC<Props> = (props) => {
  return (
    <Container className={props.className}>
      <Table>
        <Thead>
          <Tr>
            <Th>{props.header?.first_column}</Th>
            <Th>{props.header?.second_column}</Th>
            <Th>{props.header?.third_column}</Th>
          </Tr>
        </Thead>
        <Tbody>
          {props.answers?.map((el, index) => (
            <Tr key={index}>
              <Td>{el.question_item.first_column}</Td>
              <Td>{el.question_item.second_column}</Td>
              <Td>
                <Slider
                  onChange={(number) => props.handleChange(index, number)}
                  value={el.answer || 0}
                  disabled={props.disabled}
                  saved={props.saved}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Container>
  );
};

const Container = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
`;

const Table = styled.table`
  font-family: 'FreeSans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 100%;
  border-collapse: collapse;
  sup {
    font-size: 0.83em;
  }
`;

const Thead = styled.thead`
  tr {
    background-color: #eaeaea;
  }
`;

const Tr = styled.tr`
  :nth-child(even) {
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
  }
`;

const Th = styled.th`
  font-family: 'FreeSans';
  font-weight: bold;
  font-size: 14px;
  background-color: transparent;
  text-align: left;
  padding: 8px;
  color: #212529;

  sup {
    font-size: 0.83em;
  }
`;

const Tbody = styled.tbody``;

const Td = styled.td`
  font-family: 'FreeSans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 4px;
  color: #212529;

  sup {
    font-size: 0.83em;
  }
`;
