import { getSpeDetailedProgressRepository } from '../../http/repository';
import { SpeDetailedProgressParams } from '../entities/SpeDetailedProgressParams';

export const getSpeDetailedProgress = async (
  params: SpeDetailedProgressParams
) => {
  const repo = getSpeDetailedProgressRepository();

  const res = await repo.getSpeDetailedProgress(params);
  res.dps.sort(
    (dp1, dp2) =>
      Number(dp2.collection.split(' ').pop) -
      Number(dp1.collection.split(' ').pop())
  );
  res.qis.sort(
    (qi1, qi2) =>
      Number(qi2.collection.split(' ').pop) -
      Number(qi1.collection.split(' ').pop())
  );

  return res;
};
