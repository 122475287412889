import { FC, SVGAttributes } from 'react';
import { useTheme } from 'styled-components/macro';

type SVGNativeProps = SVGAttributes<SVGElement>;

export const CheckFull: FC<SVGNativeProps> = (props) => {
  const theme = useTheme();

  return (
    <svg
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle
        cx={10}
        cy={10}
        r={9}
        fill={theme.colors.success.main}
        stroke={theme.colors.success.main}
        strokeWidth={2}
      />
      <path
        d="M5 10.5 9.263 15 14 6"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
