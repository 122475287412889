import { createAsyncThunk } from '@reduxjs/toolkit';
import { postParameters } from 'http/flash-questions/parameters/api';
import { FormProps } from 'pages/flash-questions/modals/FQSettingsModal';
import { toast } from 'react-toastify';
import { RootState } from 'store';

export const setFQParameters = createAsyncThunk<
  void,
  FormProps,
  { rejectValue: string; state: RootState }
>(
  'fqSettings/post_parameters',
  async (values, { rejectWithValue, getState, dispatch }) => {
    try {
      postParameters(values);
      toast.success('Paramètres enregistrés');
    } catch (error: any) {
      toast.error(
        "Erreur serveur : les paramètres n'ont pas pu être enregistrés"
      );
      return rejectWithValue(error);
    }
  }
);
