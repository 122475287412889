import { FC, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { routes } from 'routing/constants';

interface Props {}

export const PaymentSuccess: FC<Props> = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  useEffect(() => {
    toast.success('Vous êtes bien abonné.e à Asclepia !');
    queryClient.clear();
    navigate(routes.NEW_HOME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
