import styled from 'styled-components/macro';

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  td {
    white-space: nowrap;
    text-align: start;
  }
`;
