import { FC } from 'react';
import { MenuIconProps } from './types';

export const MenuStart: FC<MenuIconProps> = ({ isFilled }) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.988 11.553a.5.5 0 0 1 0 .894l-19.582 9.79a.5.5 0 0 1-.673-.664l4.252-8.771a1.5 1.5 0 0 0 .007-1.295L1.688 2.402a.5.5 0 0 1 .676-.661l19.623 9.812Z"
        stroke="currentColor"
        strokeLinejoin="round"
        {...(isFilled ? { fill: 'currentColor' } : {})}
      />
    </svg>
  );
};
