import { useForm } from 'react-hook-form';

export interface RandomSelectionInputs {
  dp: number;
  qi: number;
  lca: number;
}

export const useRandomSelectionForm = () => {
  return useForm<RandomSelectionInputs>({});
};
