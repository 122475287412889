import { useTheme } from 'styled-components';
import { getTheme } from 'theme';

export const AsclepiaLogo = (props: {
  className?: string;
  disableDarkMode?: boolean;
}) => {
  let theme = useTheme();
  if (props.disableDarkMode) {
    theme = getTheme('light');
  }

  return (
    <svg
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M35.47 19.987c0 8.508-6.9 15.408-15.409 15.408-8.404 0-15.239-6.73-15.404-15.097a12.475 12.475 0 0 1-.004-.31c0-8.496 6.874-15.385 15.364-15.409h.044c8.508 0 15.408 6.898 15.408 15.408Z"
        fill={theme.colors.primary.main}
      />
      <path
        d="M23.955 23.058a8.574 8.574 0 1 0 0-17.147 8.574 8.574 0 0 0 0 17.147Z"
        fill={theme.colors.secondary.main}
      />
      <path
        d="M34.747 13.792A16 16 0 1 0 5.253 26.208a16 16 0 0 0 29.494-12.416ZM20.052 4.934c8.164 0 14.833 6.527 15.06 14.637h-8.333a9.499 9.499 0 0 0-13.322 0H4.992c.227-8.11 6.896-14.637 15.06-14.637ZM11.5 26.346c0-4.753 3.866-8.62 8.62-8.62 4.753 0 8.617 3.867 8.617 8.62 0 4.752-3.867 8.62-8.62 8.62-4.752 0-8.617-3.869-8.617-8.62Zm-6.509-5.893h7.672a9.503 9.503 0 0 0 1.825 13.549c-5.43-2.165-9.314-7.4-9.497-13.55Zm20.913 13.43a9.503 9.503 0 0 0 1.67-13.43h7.536c-.18 6.04-3.929 11.197-9.206 13.43Z"
        fill={theme.colors.primary.dark}
      />
      <path
        d="m25.692 32.153-4.74-12.026-.943-.02-.688.02s-.155 1.563.731 2.897c.887 1.333 4.196 8.008 4.196 8.008s.293.682 1.444 1.12Z"
        fill={theme.colors.primary.dark}
      />
      <path
        d="m8.99 12.484 10.333 7.643.706-.023-.023-1.292s-.038-.501-1.474-1.213c-1.435-.712-7.73-4.866-7.73-4.866s-.597-.444-1.812-.249Z"
        fill={theme.colors.primary.dark}
      />
      <path
        d="m27.777 9.235-7.725 9.442a.282.282 0 0 0-.065.19l.04 1.26h.926s.869-.03 1.605-1.453 4.939-7.635 4.939-7.635.454-.584.28-1.804Z"
        fill={theme.colors.primary.dark}
      />
    </svg>
  );
};
