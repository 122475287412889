import { useMutation } from 'react-query';
import { useState, useEffect } from 'react';
import { DispoInputParameters, YearData, ChuNSpeInfo } from './types';
import {
  getEasyDispo as getEasyDispoFromAPI,
  getCHUInfos,
  getSpecialitiesInfos,
} from './api';
import { useMemo } from 'react';

export const USE_GET_EASY_DISPO_KEY = 'useGetEasyDispoKey';

interface SpecialtyData {
  [yearOrTotlaAccepted: string]: YearData | number | string;
  total_accepted: number;
  label: string;
}

export type FormattedDispo = Array<{
  condensedLabel: string;
  label: string;
  specialties: Array<{
    condensedLabel: string;
    label: string;
    data: SpecialtyData;
  }>;
}>;

export const useGetEasyDispo = () => {
  const mutation = useMutation(getEasyDispoFromAPI);

  const chuOptions = useMemo(
    () =>
      mutation.data
        ? Object.keys(mutation.data).map((chu) => ({
            label: chu,
            value: chu,
          }))
        : [],
    [mutation.data]
  );

  const specialtyOptions = useMemo(
    () =>
      mutation.data
        ? Object.keys(Object.values(mutation.data)[0])
            .filter((key) => key !== 'label')
            .map((specialty) => ({
              label: specialty,
              value: specialty,
            }))
        : [],
    [mutation.data]
  );

  const dispo = useMemo<FormattedDispo | undefined>(
    () =>
      mutation.data
        ? Object.keys(mutation.data).map((chu) => ({
            condensedLabel: chu,
            label: mutation.data![chu].label,
            specialties: Object.keys(mutation.data![chu])
              .filter((key) => key !== 'label')
              .map((specialty) => ({
                condensedLabel: specialty,
                // We cast to SpecialtyData type because we know that we filtered the other types, but typescript don't
                label: (mutation.data![chu][specialty] as SpecialtyData).label,
                data: mutation.data![chu][specialty] as SpecialtyData,
              })),
          }))
        : undefined,
    [mutation.data]
  );

  const getEasyDispo = (params: DispoInputParameters) => {
    return mutation.mutate(params);
  };
  return { ...mutation, dispo, chuOptions, specialtyOptions, getEasyDispo };
};

export const useEmptyTable = () => {
  const [options, setOptions] = useState<{
    chusList: ChuNSpeInfo[] | [];
    specialtiesList: ChuNSpeInfo[] | [];
  }>({
    chusList: [],
    specialtiesList: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const chusList = await getCHUInfos();
        const specialtiesList = await getSpecialitiesInfos();
        setOptions({ chusList, specialtiesList });
      } catch (error) {
        // Handle errors if needed
      }
    };
    fetchData();
  }, []);

  const defaultChuOptions = useMemo(
    () =>
      options.chusList.map((chu) => ({
        label: chu.code,
        value: chu.label,
      })),
    [options.chusList]
  );

  const ChuOptions = useMemo(
    () =>
      options.chusList.map((chu) => ({
        label: chu.code,
        value: chu.code,
      })),
    [options.chusList]
  );

  const defaultSpecialtyOptions = useMemo(
    () =>
      options.specialtiesList.map((specialty) => ({
        label: specialty.code,
        value: specialty.label,
      })),
    [options.specialtiesList]
  );

  const specialtyOptions = useMemo(
    () =>
      options.specialtiesList.map((specialty) => ({
        label: specialty.code,
        value: specialty.code,
      })),
    [options.specialtiesList]
  );

  return {
    defaultChuOptions,
    defaultSpecialtyOptions,
    ChuOptions,
    specialtyOptions,
  };
};
