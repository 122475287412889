import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { resetAllReducers } from 'store/actionsGroup/resetAllReducers';

interface Props {}

export const Fallback: FC<Props> = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    resetAllReducers(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <h3
      style={{
        display: 'grid',
        placeItems: 'center',
        height: '100vh',
        width: '100vw',
      }}
    >
      Une erreur inconnue s'est produite, tentez de rafraîchir la page
    </h3>
  );
};
