import { postAPI } from 'auth/authentifiedHttpCalls';
import { toast } from 'react-toastify';
import { ChangePasswordBody, ChangePasswordResponse } from './types';

export const requestChangePasswordConnectedUser = async (
  previousPassword: string,
  newPassword1: string,
  newPassword2: string
) => {
  const body: ChangePasswordBody = {
    old_password: previousPassword,
    new_password1: newPassword1,
    new_password2: newPassword2,
  };

  try {
    const axiosResponse = await postAPI<
      ChangePasswordResponse,
      ChangePasswordBody
    >('/auth/password_change', body);

    return axiosResponse.data;
  } catch (e) {
    if (e instanceof Error) {
      toast.error(
        'Une erreur est survenue lors du changement de votre mot de passe. Veuillez réessayer plus tard'
      );
    }

    throw e;
  }
};
