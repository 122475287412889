import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { usePaginate } from 'pages/selection/hooks/usePaginate';
import { useSortOptions } from 'pages/selection/hooks/useSortOptions';
import { useDidUpdateEffect } from 'hooks/useDidUpdate';
import { useSearchbarContext } from 'pages/selection/components/Searchbar/SearchbarContext';
import { FQCardsParams } from 'http/flash-questions/fqdeckcreation/types';
import { setPageSize } from 'store/slices/fqTableSlice';
import { useGetFQCardsFromAPI } from '../../FQDeckList/hooks/useGetFQCardsFromAPI';
import { selectDeck } from 'store/slices/fqCurrentSlice';

export function useGetFQTableValues() {
  const { currentPage, handlePageClick, setCurrentPage } = usePaginate();
  const dispatch = useDispatch();
  const deck = useSelector(selectDeck);
  const [formatedData, setFormatedData] = useState<any>([]);

  const handleNewPageSize = (newSize: number) => {
    dispatch(setPageSize(newSize));
    setCurrentPage(0);
  };

  const filters = useSelector((state: RootState) => state.selectionFilters);
  const pageSize = useSelector((state: RootState) => state.fqTable.pageSize);
  const { isAscending, sortColumn } = useSortOptions((state) => state.fqTable);

  const { debouncedSearchTerm } = useSearchbarContext();

  const [params, setParams] = useState<FQCardsParams>({
    page: 1,
    page_size: pageSize,
    collection: filters.collections,
    sort_column: sortColumn,
    sort_ascending: isAscending,
    specialty: filters.specialities,
    rank: filters.rank,
    item: filters.items,
    tag: filters.categories,
    type: filters.type,
    search: debouncedSearchTerm,
    // is_free: filters.showFree, // TODO: active this when it'll be a feature
    done: filters.showDone,
  });

  const getParams = () => ({
    ...params,
    collection: filters.collections,
    sort_column: sortColumn,
    sort_ascending: isAscending,
    specialty: filters.specialities,
    rank: filters.rank,
    item: filters.items,
    tag: filters.categories,
    type: filters.type,
    page: currentPage + 1,
    page_size: pageSize,
    search: debouncedSearchTerm,
    // is_free: filters.showFree, // TODO: active this when it'll be a feature
    done: filters.showDone,
  });

  useDidUpdateEffect(() => {
    setCurrentPage(0);
    setParams({
      ...getParams(),
      // We reset the page to the first one when the filters are updated
      page: 1,
    });
  }, [filters, isAscending, sortColumn, pageSize, debouncedSearchTerm]);

  useEffect(() => {
    setParams(getParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const { isLoading, data, isError, refetch } = useGetFQCardsFromAPI(params);

  useEffect(() => {
    if (!data) return;
    const selectedCardsIds = deck?.cards.map((card) => card.question) ?? [];

    const result = data.results.map((row) => {
      if (selectedCardsIds.includes(row.id))
        return {
          ...row,
          due: deck?.cards.find((card) => card.question === row.id)?.due,
        };
      else {
        return { ...row, due: null };
      }
    });

    setFormatedData(result);
  }, [data, deck]);

  return {
    formatedData,
    isLoading,
    isError,
    refetch,
    data,
    currentPage,
    handlePageClick,
    pageSize,
    handleNewPageSize,
  };
}
