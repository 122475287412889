import { device } from 'helpers/css/responsive';

import styled from 'styled-components';
import { Button } from 'ui/Button';
import { Modal } from 'ui/Modal';
import { useSelector } from 'store';
import parse from 'html-react-parser';
import { useDispatch } from 'react-redux';
import { fetchPopups, postSeenPopup } from 'store/actions/popupActions';
import { closePopup, selectPopupStatus } from 'store/slices/popupSlice';
import { Popup, PopupStatus } from 'http/popup/types';
import {
  persist,
  POPUP,
  remove,
  restore,
  VIEWED_POPUP_AT,
} from 'helpers/application/localStorage';
import { assert } from 'helpers/application/assert';
import { MS_IN_A_DAY } from 'constants/date';
import { useEffect, useState } from 'react';
import { Checkbox } from 'ui/Checkbox';

const BackendPopup = () => {
  const popup = useSelector(({ popup }) => popup.popup);
  const popupStatus = useSelector(selectPopupStatus);
  const [checked, setChecked] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPopups());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    if (popupStatus === PopupStatus.PERMANENT) {
      persist(POPUP, popup);
      persist(VIEWED_POPUP_AT, new Date());
    }

    if (popupStatus === PopupStatus.ACTIVE) {
      if (checked) {
        remove(POPUP);
        remove(VIEWED_POPUP_AT);
        dispatch(postSeenPopup());
      }
    }

    dispatch(closePopup());
  };

  const shouldDisplayPopup = () => {
    if (!popup) return false;
    if (!popupStatus || popupStatus === PopupStatus.INACTIVE) return false;

    if (popupStatus === PopupStatus.PERMANENT) {
      const localStorageRawPopup = restore<Popup | null>(POPUP, null);
      if (!localStorageRawPopup) return true;
      if (localStorageRawPopup.id !== popup.id) return true;

      const localStorageRawDate = restore(VIEWED_POPUP_AT, null);
      if (!localStorageRawDate) return true;

      const localStorageDate = new Date(localStorageRawDate).getTime();
      const now = new Date().getTime();
      const isOneDayPassed = now - localStorageDate > MS_IN_A_DAY;

      return isOneDayPassed;
    }

    return true;
  };

  if (!shouldDisplayPopup()) return null;
  assert(popup);

  return (
    <Modal
      isOpen={true}
      onClose={handleClose}
      shouldCloseOnOverlayClick={false}
    >
      <Container>
        <h2>{popup.title}</h2>
      </Container>

      <ContentContainer>{parse(popup.content)}</ContentContainer>

      <FooterWrapper>
        {popupStatus === PopupStatus.ACTIVE && (
          <label>
            <CheckboxContainer>
              <Checkbox
                checked={checked}
                onChange={() => setChecked((prev) => !prev)}
              />
              Ne plus afficher ce message
            </CheckboxContainer>
          </label>
        )}
        <CloseButton onClick={handleClose}>Fermer</CloseButton>
      </FooterWrapper>
    </Modal>
  );
};

export default BackendPopup;

const CheckboxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 11fr;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  @media ${device.desktop} {
    margin-top: 32px;
    gap: 16px;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.background.main};
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media ${device.desktop} {
    flex-direction: column;
  }
`;

const CloseButton = styled(Button)`
  max-width: 310px;
  text-align: center;
`;

const ContentContainer = styled.div`
  overflow: scroll;
`;
