import { FC, useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { QuestionModal } from './QuestionModal';
import { formatSimilarQuestionTitle } from './helpers';
import { SimilarQuestion } from 'types/correction.type';
import WhiteCard from 'ui/Cards/WhiteCard';
import SmallTitle from 'ui/Titles/SmallTitle';
import { CorrectionThemeProvider } from 'components/CorrectionThemeProvider';

interface Props {
  relatedQuestions: SimilarQuestion[];
}

export const RelatedQuestions: FC<Props> = ({ relatedQuestions }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shownQuestion, setShownQuestion] = useState<
    SimilarQuestion | undefined
  >();

  const currentQuestionIndex = useMemo(() => {
    const currentQuestionIndex = relatedQuestions.findIndex(
      (q) => q.id === shownQuestion?.id
    );
    if (!currentQuestionIndex) return 0;
    return currentQuestionIndex;
  }, [relatedQuestions, shownQuestion]);

  const canNextQuestion = useMemo(() => {
    return currentQuestionIndex < relatedQuestions.length - 1;
  }, [currentQuestionIndex, relatedQuestions.length]);

  const canPreviousQuestion = useMemo(() => {
    return currentQuestionIndex > 0;
  }, [currentQuestionIndex]);

  const nextQuestion = () => {
    if (canNextQuestion) {
      setShownQuestion(relatedQuestions[currentQuestionIndex + 1]);
    }
  };

  const previousQuestion = () => {
    if (canPreviousQuestion) {
      setShownQuestion(relatedQuestions[currentQuestionIndex - 1]);
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  if (relatedQuestions.length === 0) return null;

  return (
    <CorrectionThemeProvider>
      <WhiteCard>
        <SmallTitle>Questions similaires</SmallTitle>
        {relatedQuestions.map((question) => (
          <QuestionName
            key={question.statement + question.id}
            onClick={() => {
              setShownQuestion(question);
              openModal();
            }}
          >
            {formatSimilarQuestionTitle(question)}
          </QuestionName>
        ))}

        {shownQuestion && (
          <QuestionModal
            isOpen={isModalOpen}
            closeModal={closeModal}
            question={shownQuestion}
            canNextQuestion={canNextQuestion}
            canPreviousQuestion={canPreviousQuestion}
            nextQuestion={nextQuestion}
            previousQuestion={previousQuestion}
          />
        )}
      </WhiteCard>
    </CorrectionThemeProvider>
  );
};

const QuestionName = styled.span`
  color: #0000ee;
  cursor: pointer;
  font-family: 'Free-Sans', sans-serif;
  font-size: 0.9rem;
  line-height: 1.2;

  &:hover {
    text-decoration: underline;
  }
`;
