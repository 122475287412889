import { FC } from 'react';

import { PreviewBlockProps } from '../types';
import { QCMPreviewBlock } from './QCM/QCMPreviewBlock';
import { QRPLPreviewBlock } from './QRPL/QRPLPreviewBlock';
import { TCSPreviewBlock } from './TCS/TCSPreviewBlock';
import { QZPPreviewBlock } from './QZP/QZPPreviewBlock';
import { Type } from 'types/correction.type';

export const getQuestionPreviewComponent = (
  type: Type
): FC<PreviewBlockProps> => {
  switch (type) {
    case 'QRM':
      return QCMPreviewBlock;

    case 'QRU':
      return QCMPreviewBlock;

    case 'QRP':
      return QCMPreviewBlock;
    //   TODO: QRU, QRP qui sont des dérivés de QCM

    case 'QROC':
      return () => null;

    case 'QRPL':
      return QRPLPreviewBlock;

    case 'TCS':
      return TCSPreviewBlock;

    case 'QZP':
      return QZPPreviewBlock;

    default:
      return ({ questionData }: PreviewBlockProps) => (
        <div style={{ padding: 16 }}>Correction non implémenté pour {type}</div>
      );
  }
};
