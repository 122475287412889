import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { routes } from 'routing/constants';
import { Button } from 'ui/Button';
import { LockIcon } from 'ui/icons/Lock';

export const PremiumLockedPage: FC = () => {
  return (
    <Container>
      <LockContainer>
        <Lock />
        <h2>Ce contenu est réservé aux abonnés</h2>
      </LockContainer>
      <UnstyledLink to={routes.SUBSCRIBE}>
        <StyledButton>S'abonner</StyledButton>
      </UnstyledLink>
    </Container>
  );
};

const Container = styled.div<{ marginTop?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin-top: ${({ marginTop }) => marginTop};
`;

const LockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Lock = styled(LockIcon)`
  max-height: 80px;
  max-width: 150px;
  width: 100%;
  margin-bottom: 2rem;
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secondary.main};
  color: ${({ theme }) => theme.colors.secondary.contrastText};
`;

const UnstyledLink = styled(Link)`
  /* Override link default styles */
  text-decoration: none !important;
  margin-top: 2rem;
`;
