import { FC } from 'react';
import styled from 'styled-components/macro';
import { ColumnHeaderWrapper } from './ColumnHeaderWrapper';

interface Props {
  isSub?: boolean;
}

export const NewCardHeader: FC<Props> = ({ isSub }) => {
  return (
    <StyledColumnHeaderWrapper isReadOnly>
      Nouvelles cartes
    </StyledColumnHeaderWrapper>
    // <GenericSortHeader
    //   columnName="Nouvelles cartes"
    //   sortColumn="new_cards"
    //   isSub={isSub}
    //   disabled={isUserFree}
    // />
  );
};

const StyledColumnHeaderWrapper = styled(ColumnHeaderWrapper)`
  background-color: transparent;

  & > div {
    justify-content: center;
  }
`;
