import { FC } from 'react';
import { MenuIconProps } from './types';

export const MenuAccount: FC<MenuIconProps> = ({ isFilled }) => {
  return (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={12} cy={12} r={11.5} stroke="currentColor" />
      <circle
        cx={12}
        cy={9}
        r={4}
        stroke="currentColor"
        {...(isFilled ? { fill: 'currentColor' } : {})}
      />
      <path
        d="M12 13.125c-5.4 0-6.25 5.25-6 7.875.25.75 1.8 2.25 6 2.25s5.75-1.5 6-2.25c.25-2.625-.6-7.875-6-7.875Z"
        stroke="currentColor"
        {...(isFilled ? { fill: 'currentColor' } : {})}
      />
    </svg>
  );
};
