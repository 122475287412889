interface InputProps {
  className?: string;
}

export const ValidateIcon = (props: InputProps) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    {...props}
  >
    <circle cx={10} cy={10.641} r={9.5} stroke="currentColor" />
    <path
      d="m6 10.641 3.79 4 4.21-8"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
