import { NewRecapSpecialty, PaginatedResponse } from 'http/new-home/types';
import { FC } from 'react';
import { CardsContainer } from 'pages/newHome/ui/Global';
import { SpecialtyCard } from './SpecialtyCard';

interface SpecialitiesCardsProps {
  specialties: PaginatedResponse<NewRecapSpecialty>;
}

export const SpecialtiesCards: FC<SpecialitiesCardsProps> = ({
  specialties,
}) => {
  return (
    <>
      <CardsContainer>
        {specialties &&
          specialties.results.length > 0 &&
          specialties.results.map(
            (specialty: NewRecapSpecialty, index: number) => (
              <SpecialtyCard
                key={`${specialty.specialty} + ${index}`}
                specialty={specialty}
              />
            )
          )}
      </CardsContainer>
    </>
  );
};
