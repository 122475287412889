import {
  Root as ProgressRoot,
  Indicator as ProgressIndicator,
} from '@radix-ui/react-progress';
import { device } from 'helpers/css/responsive';
import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { AsclepiaLogo } from 'ui/icons/AsclepiaLogo';
import { AsclepiaLogoWithText } from 'ui/icons/AsclepiaLogoWithText';
import { StudentLogo } from 'ui/icons/StudentLogo';
import { useTrainingTimer } from '../hooks/useTrainingTimer';
import { useUser } from '../../../modules/user-options/hooks/useUser';
import { PauseButton } from './ui/PauseButton';

import { zIndexes } from 'theme/zIndex/zIndexes';
interface Props {
  totalSavedQuestions: number;
  totalNbQuestions: number;
  taskId: number;
  timerParams: {
    totalTime: number;
    timerOffset: number;
    enabled: boolean;
  };
  onTimerEnd: () => void;
  onFinish(): void;
  setTimerIsRunning(isRunning: boolean): void;
  isCCB: boolean;
}

const formatTimerTime = (hours: number, minutes: number) => {
  return (
    (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes
  );
};

const formatTrainingTime = (totalSeconds: number) => {
  const hours = Math.floor(totalSeconds / 3600);
  const remainingSeconds = totalSeconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  return (
    (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes
  );
};

const LOADING_PLACEHOLDER = 'Loading...';
const UNLIMITED_TIME_TEXT = 'Temps illimité';

export const TrainingHeader: FC<Props> = (props) => {
  const getProgress = () =>
    Math.min(
      100,
      Math.ceil((props.totalSavedQuestions / props.totalNbQuestions) * 100)
    );

  const user = useUser();

  const { resetTimer, ...timer } = useTrainingTimer(
    props.timerParams.totalTime,
    props.timerParams.timerOffset,
    props.onTimerEnd
  );

  useEffect(() => {
    if (props.timerParams.totalTime > 0 && props.timerParams.enabled) {
      resetTimer(props.taskId);
    }
    // eslint-disable-next-line
  }, [props.timerParams]);

  useEffect(() => {
    props.setTimerIsRunning(timer.isRunning);
    // eslint-disable-next-line
  }, [timer.isRunning]);

  return (
    <PageHeader>
      <AsclepiaLogoContainer>
        <StyledAsclepiaLogoWithText disableDarkMode={true} />
        <StyledAsclepiaLogo />
      </AsclepiaLogoContainer>
      <CenterContainer>
        <InformationContainer>
          <NameContainer>
            <StudentLogoContainer>
              <StyledStudentLogo />
            </StudentLogoContainer>
            <div>
              <FirstName>
                {user ? user.firstName : LOADING_PLACEHOLDER}
              </FirstName>
              <LastName>{user ? user.lastName : LOADING_PLACEHOLDER}</LastName>
            </div>
          </NameContainer>
          {/* <TrainingTimeContainer>
            <Name>Durée de l'épreuve: </Name>
            <TrainingTime>
              {props.timerParams.enabled
                ? formatTrainingTime(props.timerParams.totalTime)
                : UNLIMITED_TIME_TEXT}
            </TrainingTime>
          </TrainingTimeContainer> */}
        </InformationContainer>
        <div style={{ display: 'flex' }}>
          <ProgressContainer>
            <ProgressBarContainer>
              <StyledProgressRoot value={getProgress()}>
                <ProgressBarLabel
                  percentage={getProgress()}
                >{`Progression : ${getProgress()} %`}</ProgressBarLabel>
                <StyledProgressIndicator
                  style={{ width: `${getProgress()}%` }}
                />
              </StyledProgressRoot>
            </ProgressBarContainer>
          </ProgressContainer>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: '1rem',
            }}
          >
            <div>
              <TimerContainer>
                <RemainingTimerLabel>Durée écoulée : </RemainingTimerLabel>
                <RemainingTimerValue>
                  {props.timerParams.enabled || props.isCCB
                    ? formatTimerTime(timer.hours, timer.minutes)
                    : UNLIMITED_TIME_TEXT}
                </RemainingTimerValue>
              </TimerContainer>
              <TimerContainer style={{ marginTop: '5px' }}>
                <TotalTimerLabel>Durée totale :</TotalTimerLabel>
                <TotalTimerValue>
                  {props.timerParams.enabled || props.isCCB
                    ? formatTrainingTime(props.timerParams.totalTime)
                    : UNLIMITED_TIME_TEXT}
                </TotalTimerValue>
              </TimerContainer>
            </div>
            {!props.isCCB && (
              <StyledPauseButton
                isPaused={props.timerParams.enabled ? !timer.isRunning : false}
                onPause={() => timer.pauseTimer(props.taskId)}
                onPlay={() => resetTimer(props.taskId)}
              />
            )}
          </div>
        </div>
      </CenterContainer>
      <RightContainer onClick={props.onFinish}>
        {/* <RightFlexContainer> */}
        <FinishText>
          Terminer <br />
          l'épreuve
        </FinishText>
        {/* 
          <ValidationSliderContainer>
            <StyledValidationSlider onValidation={props.onFinish} />
          </ValidationSliderContainer> */}
        {/* </RightFlexContainer> */}
      </RightContainer>
    </PageHeader>
  );
};

const PageHeader = styled.header`
  z-index: ${zIndexes.trainingHeader};
  display: grid;
  /* grid-template-columns: 0.5fr 6fr 1fr; */
  grid-template-columns: 0.5fr 6fr 130px;

  grid-template-rows: 1fr;
  height: 96px;
  border-bottom: 3px solid #eaeaea;
  background: white;

  font-size: 15px;
  @media ${device.tablet} {
    font-size: 16px;
    grid-template-columns: 0.5fr 6fr 170px;
    grid-template-rows: 1fr;
  }
`;

const AsclepiaLogoContainer = styled.div`
  padding: 12px;
  padding-left: 0;
  grid-row: 1;
  grid-column: 1;
  height: 96px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media ${device.mobile} {
    padding-right: 0;
  }
`;

const StyledAsclepiaLogoWithText = styled(AsclepiaLogoWithText)`
  height: 100%;
  display: none;

  @media ${device.tablet} {
    width: 140px;
  }

  @media ${device.desktop} {
    display: block;
  }

  @media ${device.largeTable} {
    width: auto;
  }
`;

const StyledAsclepiaLogo = styled(AsclepiaLogo)`
  height: 100%;
  display: block;

  @media ${device.desktop} {
    display: none;
  }
`;

const CenterContainer = styled.div`
  display: flex;
  flex-basis: 1;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  border-right: 3px solid #eaeaea;
  border-left: 3px solid #eaeaea;
  height: 100%;
  padding: 0 16px;
  grid-column: 2;
  grid-row: 1;
  height: 96px;
`;

const InformationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: grid;
  /* grid-template-rows: 1fr 1fr 1fr; */
`;

const StudentLogoContainer = styled.div`
  width: 30px;
  height: 30px;
  fill: #183a78;
  display: inline;
  margin-right: 5px;
`;

const StyledStudentLogo = styled(StudentLogo)`
  margin-right: 5px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 18px;
`;

const Name = styled.div`
  font-family: 'FreeSans';
`;
const LastName = styled(Name)``;

const FirstName = styled(Name)`
  font-weight: bold;
`;

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* gap: 8px; */
  width: 100%;
  position: relative;
  /* height: 18px; */
`;

const ProgressBarContainer = styled.div`
  width: 180px;
  height: 45px;
  vertical-align: top;
  position: relative;
`;

const StyledProgressRoot = styled(ProgressRoot)`
  overflow: hidden;
  background-color: #cfe8fd;
  border-radius: 20px;
  width: 180px;
  height: 45px;
  border: 3px solid #183876;
`;

const StyledProgressIndicator = styled(ProgressIndicator)`
  background-color: #183876;
  height: 100%;
  transition: 'width 660ms cubic-bezier(0.65, 0, 0.35, 1)';
`;

const ProgressBarLabel = styled.label<{ percentage: number }>`
  background: linear-gradient(
    to right,
    white calc(${({ percentage }) => percentage}%),
    black calc(${({ percentage }) => percentage}%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: black;
  width: 180px;
  position: absolute;
  height: 100%;
  font-size: 16px;
  font-family: 'Freesans';
  line-height: 100%;
  text-align: center;
  left: 0;
  top: 0;

  font-weight: normal;
  padding: 0;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RightContainer = styled.div`
  background-color: #ef9198;
`;

const FinishText = styled.p`
  cursor: pointer;
  font-weight: bold;
  margin: 0;

  font-family: 'FreeSans';
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
`;

const TimerContainer = styled.div`
  display: flex;
`;

const TimerText = styled.div`
  white-space: nowrap;
  font-family: 'FreeSans';
`;
const RemainingTimerLabel = styled(TimerText)`
  font-weight: bold;
  color: #d17372;
`;
const RemainingTimerValue = styled(TimerText)`
  font-weight: bold;
  margin-left: 1rem;
  flex: 1;
  text-align: right;
  color: #d17372;
`;

const TotalTimerLabel = styled(TimerText)`
  color: #000;
`;
const TotalTimerValue = styled(TimerText)`
  text-align: right;
  flex: 1;
  color: #000;
`;

const StyledPauseButton = styled(PauseButton)`
  width: 32px;
  height: 33px;
  cursor: pointer;
  margin-left: 1rem;
  grid-column: 1;
  grid-row: 1;
`;
