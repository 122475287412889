import { FC } from 'react';
import { MenuIconProps } from './types';

export const MenuSubscribe: FC<MenuIconProps> = () => {
  return (
    <svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={10} cy={7.5} r={3.25} stroke="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.99 18.78c-.64.144-1.306.22-1.99.22a9 9 0 1 1 8.986-8.491c.34.298.642.638.9 1.012.075-.496.114-1.004.114-1.521 0-5.523-4.477-10-10-10S0 4.477 0 10s4.477 10 10 10a9.99 9.99 0 0 0 3.137-.502 5.423 5.423 0 0 1-1.146-.719Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.685 10.658a6.162 6.162 0 0 0-1.685-.22c-2.454 0-3.917 1.213-4.709 2.73-.771 1.477-.899 3.223-.788 4.38l.005.056.018.054c.16.482.66 1.036 1.517 1.462.872.432 2.15.755 3.957.755 1.274 0 2.284-.16 3.075-.406a5.428 5.428 0 0 1-1.155-.749c-.537.096-1.172.155-1.92.155-1.692 0-2.81-.302-3.512-.65-.653-.325-.918-.674-.996-.84-.087-1.03.045-2.526.686-3.755.636-1.219 1.776-2.193 3.822-2.193.342 0 .659.028.952.079a5.44 5.44 0 0 1 .733-.858Z"
        fill="currentColor"
      />
      <path
        d="M16.154 10.833v8.334M11.666 14.68H20"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
};
