import { ThemeVariants } from 'theme';
import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';

export const getDefaultTheme = async (): Promise<ThemeVariants> => {
  const themeFromStorage = await getThemeFromStorage();
  if (themeFromStorage) return themeFromStorage;

  return getThemeFromSystemPreferences();
};

export const getThemeFromStorage = async (): Promise<ThemeVariants> => {
  try {
    const localTheme = window.localStorage.getItem('theme') as ThemeVariants;
    return localTheme;
  } catch (error) {
    reportErrorToSentry(error);

    return 'light';
  }
};

export const getNewsletterSettingStorage = () => {
  const localTheme = window.localStorage.getItem('theme') as ThemeVariants;
  return localTheme;
};

const getThemeFromSystemPreferences = () => {
  if (
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    return 'dark';
  }

  return 'light';
};
