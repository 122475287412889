import { useMemo, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { useGetKnowledgesAsOptions } from 'http/selection/get_knowledge/connector';
import { updateRanksFilter } from 'store/slices/selectionFiltersSlice';

import { GenericSortFilterHeader } from './GenericSortFilterHeader';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';

interface Props {
  isSub?: boolean;
}

export const RanksHeader: FC<Props> = ({ isSub }) => {
  const { data } = useGetKnowledgesAsOptions();
  const filterOptions = useMemo(() => data?.options || [], [data]);
  const isUserFree = useIsUserFree();

  const dispatch = useDispatch();

  const selectedFilters = useSelector(
    (state: RootState) => state.selectionFilters.rank
  );

  const setSelectedFilters = (rank: string[]) => {
    dispatch(updateRanksFilter(rank));
  };

  return (
    <GenericSortFilterHeader
      columnName="Rang"
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      sortColumn="rank"
      filterOptions={filterOptions}
      isSub={isSub}
      disabled={isUserFree}
    />
  );
};
