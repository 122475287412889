import { useState, useEffect, useMemo } from 'react';
import { getCompassBooks } from 'http/compass/api';
import { Book, ResearchOptions } from 'http/compass/types';
import { toast } from 'react-toastify';
import { useSearchParams } from 'react-router-dom';
import { Options } from 'types/options.type';

export const useGetBooks = () => {
  const [searchOptions, setSearchOptions] = useState<ResearchOptions[]>([]);
  const [booksOptions, setBooksOptions] = useState<Options>([]);

  const [books, setBooks] = useState<Book[]>([]);
  const [book, setBook] = useState<Book | null>(null);
  const [searchParams] = useSearchParams();
  const selectedBook = searchParams.get('activeBook');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getBooks = async () => {
    setIsLoading(true);
    try {
      const booksList = await getCompassBooks();
      setBooks(booksList);
    } catch (error) {
      toast.error('Une erreur est survenue. Veuillez réessayer plus tard.');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (books.length === 0 && !isLoading) {
      getBooks();
    } else if (books.length > 0 && !isLoading) {
      const payloadBook = books.find(
        (book) => book.id.toString() === selectedBook
      );
      setBook(payloadBook || null);
    }
  }, [books, isLoading, selectedBook]);

  useMemo(() => {
    const filterBooks = async () => {
      try {
        const bookOptions = books.map((book) => {
          return {
            value: book.id,
            label: book.short_title,
            possibleInput: `${book.short_title} ${book.title}`,
          };
        });
        const sortedBooksOptions = bookOptions.sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
        setSearchOptions(sortedBooksOptions);
      } catch (error) {
        toast.error('Une erreur est survenue. Veuillez réessayer plus tard.');
      }
    };
    filterBooks();
  }, [books]);

  useMemo(() => {
    const filterBooks = async () => {
      try {
        const formatedBookOptions: Options = books.map((book) => {
          return {
            value: book.book,
            label: book.short_title,
          };
        });

        setBooksOptions(formatedBookOptions);
      } catch (error) {
        toast.error('Une erreur est survenue. Veuillez réessayer plus tard.');
      }
    };
    filterBooks();
  }, [books]);
  return { searchOptions, books, book, booksOptions };
};
