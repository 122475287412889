import styled from 'styled-components/macro';

export const OutlinedButton = styled.button<{
  bigradius?: boolean;
  bigPadding?: boolean;
}>`
  padding: ${({ bigradius }) => (bigradius ? `12px 32px` : `6px 16px`)};
  border-radius: ${({ bigradius }) => (bigradius ? `12px` : `4px`)};
  color: #01162d;
  border: 2px solid #01162d;
  background: white;
  cursor: pointer;
  font-family: 'FreeSans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
`;
