import { Options } from 'types/options.type';
import { Item } from 'types/item.type';

export const formatItemsListAsOptions = (
  itemsList: Item[] | undefined
): Options | undefined => {
  if (itemsList === undefined) {
    return undefined;
  }

  return itemsList
    .filter((item) => item.item !== 0 && item.item !== 368 && item.item !== 369)
    .map((item) => ({
      label: `${item.item} ${item.short_title}` || '',
      value: item.item?.toString() || '',
    }));
};
