import { FC, useState, useMemo } from 'react';
import styled from 'styled-components/macro';
import { CorrectionGroupRecap } from './CorrectionGroupRecap';
import { CorrectionTaskResponse, CorrectionGroup } from 'types/correction.type';
import { NewsletterCard } from 'components/Newsletter/NewsletterCard';

function orderBlocks(blocks: CorrectionGroup[]) {
  let dplcaBlocks: CorrectionGroup[] = [];
  let qiBlocks: CorrectionGroup[] = [];

  blocks.forEach((block: CorrectionGroup) => {
    if (block.group?.num) {
      dplcaBlocks.push(block);
    } else {
      qiBlocks.push(block);
    }
  });

  // Combine the sorted DP and KFP blocks
  let orderedBlocks = dplcaBlocks.sort((a, b) => a.group.num - b.group.num);

  // Add the QI block at the end
  orderedBlocks.push(...qiBlocks);
  return orderedBlocks;
}

interface Props {
  taskCorrection: CorrectionTaskResponse;
  selectedGroupId?: number;
  setSelectedGroupId: (newVal: number) => void;
}

export const CorrectionGroupsRecap: FC<Props> = ({
  taskCorrection,
  selectedGroupId,
  setSelectedGroupId,
}) => {
  const [isOpen, setIsOpen] = useState(
    selectedGroupId !== taskCorrection.correction_groups[0]?.id
  );

  const handleToggle = (groupId: number) => {
    if (groupId !== selectedGroupId) {
      setSelectedGroupId(groupId);
      setIsOpen(true);
    } else {
      setIsOpen(!isOpen);
    }
  };

  const sortedCorrectionData = useMemo(() => {
    return orderBlocks(taskCorrection.correction_groups);
  }, [taskCorrection.correction_groups]);

  return (
    <Container>
      {sortedCorrectionData.map((group) => (
        <CorrectionGroupRecap
          groupData={group}
          isOpen={isOpen && selectedGroupId === group.id}
          toggleOpen={() => handleToggle(group.id)}
          key={group.id}
        />
      ))}
      <NewsletterCard />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 4px;
`;
