import { FC, Fragment } from 'react';
import styled from 'styled-components/macro';

import { SpecialtyIcon } from 'ui/icons/SpecialtyIcon/SpecialtyIcon';
import { QIProgressBySpecialty } from 'modules/progress/general/qi/domain/entities/QIProgressBySpecialty';

import { QIProgressRowBySpecialty } from './QIProgressRowBySpecialty';
import { SessionHeader } from './SessionHeader';
import { useGetSpecialityInfos } from 'http/selection/specialities-list/connectors';
import {
  Footer,
  ProgressContainer,
  Table,
  TableScrollContainer,
} from '../../components/SectionUIElements';
import { RestrictedPremiumDataRow } from '../../components/RestrictedPremiumDataRow';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';
import { SelectAllColumnCheckbox } from '../components/SelectAllColumnCheckbox';

interface Props {
  progress: QIProgressBySpecialty;
}

export const QIProgressYearBySpecialty: FC<Props> = ({ progress }) => {
  const speInfos = useGetSpecialityInfos(progress.speTrigram);
  const isUserFree = useIsUserFree();

  return (
    <ProgressContainer>
      <HeaderContainer>
        <Header>
          <HeaderTitle>
            <Specialty trigram={progress.speTrigram} />
            {speInfos?.compressed_name}
          </HeaderTitle>
          <SelectAllColumnCheckbox progress={progress} />
        </Header>

        <Separator />
      </HeaderContainer>

      <TableScrollContainer>
        <Table>
          <tbody>
            {progress.sessions.map((session) => (
              <Fragment key={session.collection}>
                <SessionHeader progress={session} />
                {session.questions.map((question) => (
                  <QIProgressRowBySpecialty
                    question={question}
                    key={question.id}
                  />
                ))}
              </Fragment>
            ))}
            {isUserFree && <RestrictedPremiumDataRow colSpan={4} />}
          </tbody>
        </Table>
      </TableScrollContainer>
      <Footer />
    </ProgressContainer>
  );
};

const HeaderContainer = styled.div`
  ${({ theme }) => theme.typography.label}
  width: 100%;
  padding: 12px 12px 4px;
  background-color: ${({ theme }) => theme.colors.primary.main};

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Separator = styled.div`
  width: 100%;
  margin: 0 8px;
  border: 1px solid ${({ theme }) => theme.colors.primary.light};
`;

const Specialty = styled(SpecialtyIcon)`
  height: 24px;
  width: 24px;
`;
