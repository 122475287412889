import { popSlideInAnimation } from 'helpers/css/animations/popSlideIn';
import { device } from 'helpers/css/responsive';
import styled, { css } from 'styled-components/macro';

export const HeaderCell = styled.span`
  background-color: ${({ theme }) => theme.colors.primary.light};
  padding: 8px 12px;

  @media ${device.desktop} {
    padding: 16px 32px;
  }
`;

export const FirstHeaderCell = styled(HeaderCell)`
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const LastHeaderCell = styled(HeaderCell)`
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const Cell = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;

  padding: 8px 12px;

  @media ${device.desktop} {
    padding: 12px 28px;
    padding-left: 48px;
  }
`;

export const SubRow = styled.span`
  grid-column: 1/-1;
  overflow: hidden;
`;

export const SubRowContent = styled.div<{ isColored?: boolean }>`
  margin: 2px var(--content-side-margin) 0;
  padding: 16px 24px;
  border-radius: 8px;

  ${popSlideInAnimation}

  ${({ isColored }) =>
    isColored &&
    css`
      background-color: ${({ theme }) => theme.colors.primary.light};
    `}
`;

export const TableContainer = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 10fr) minmax(90px, 3fr) minmax(90px, 3fr) minmax(
      50px,
      1fr
    );
  width: 100%;

  border: 4px solid ${({ theme }) => theme.colors.primary.light};
  border-radius: 14px;
  padding-bottom: 12px;

  --content-side-margin: 8px;
  @media ${device.desktop} {
    --content-side-margin: 20px;
  }

  // Alternated row colors

  ${Cell}:nth-of-type(8n+1), ${Cell}:nth-of-type(8n+2), ${Cell}:nth-of-type(8n+3), ${Cell}:nth-of-type(8n+4) {
    background-color: ${({ theme }) => theme.colors.primary.light};
  }

  // First cell row space with header
  ${Cell}:nth-of-type(1), ${Cell}:nth-of-type(2), ${Cell}:nth-of-type(3) , ${Cell}:nth-of-type(4) {
    margin-top: 12px;
  }

  // Rows border radius
  ${Cell}:nth-of-type(4n+1) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

    margin-left: var(--content-side-margin);

    @media ${device.desktop} {
      padding-left: 28px;
    }
  }
  ${Cell}:nth-of-type(4n+4) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    margin-right: var(--content-side-margin);

    @media ${device.desktop} {
      padding-left: 14px;
    }
  }
`;
