import { FC } from 'react';

interface InputProps {
  className?: string;
}

export const EditLogo: FC<InputProps> = (props) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    viewBox="0 0 18 18"
  >
    <path
      d="M13.808 16H1"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x={-0.088}
      y={0.722}
      width={3.193}
      height={15.818}
      rx={0.5}
      transform="matrix(.59275 .80539 -.76971 .63839 15.034 .332)"
      fill="currentColor"
      stroke="currentColor"
      strokeLinejoin="round"
    />
    <path
      d="m4.137 13.182-3.105.887 1.33-3.105"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
