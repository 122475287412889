import { FC } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import { QuestionType, updateGroupType } from 'store/slices/progressSlice';
import { Radio } from 'ui/Radio';

import { useGroupType } from './useGroupType';

interface Props {}

export const GroupTypeRadio: FC<Props> = () => {
  const groupType = useGroupType();
  const dispatch = useDispatch();

  const updateType = (type: QuestionType) => {
    dispatch(updateGroupType(type));
  };

  return (
    <Container>
      <RadioContainer>
        <Radio
          checked={groupType === 'DP'}
          onChange={() => updateType('DP')}
          id="DP"
          value="DP"
        />
        <label htmlFor="DP">DP/KFP</label>
      </RadioContainer>
      <RadioContainer>
        <Radio
          checked={groupType === 'QI'}
          onChange={() => updateType('QI')}
          id="QI"
          value="QI"
        />
        <label htmlFor="QI">QI</label>
      </RadioContainer>
      <RadioContainer>
        <Radio
          checked={groupType === 'LCA'}
          onChange={() => updateType('LCA')}
          id="LCA"
          value="LCA"
        />
        <label htmlFor="LCA">LCA</label>
      </RadioContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const RadioContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
