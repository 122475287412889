import { FC } from 'react';
import styled from 'styled-components';
import { PauseIcon } from 'ui/icons/PauseIcon';
import { PlayIcon } from 'ui/icons/PlayIcon';

interface Props {
  className?: string;
  isPaused: boolean;
  onPlay?(): void;
  onPause?(): void;
}

export const PauseButton: FC<Props> = (props) => {
  const handleClick = () => {
    if (props.isPaused) {
      props.onPlay && props.onPlay();
    } else {
      props.onPause && props.onPause();
    }
  };

  return (
    <Container className={props.className} onClick={handleClick}>
      {props.isPaused ? (
        <PlayIcon fillingColor="white" />
      ) : (
        <PauseIcon fillingColor="white" />
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 32px;
  height: 33px;
  cursor: pointer;
  grid-column: 1;
  grid-row: 1;
`;
