import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';
import { getTrainingTask } from 'http/training/get-task/api';
import { TrainingGroup, TrainingTask } from 'http/training/get-task/types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useGetTask = (taskId: number | undefined, isCCB: boolean) => {
  const navigate = useNavigate();
  const [task, setTask] = useState<TrainingTask>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function getTaskFromAPI(APItaskId: number) {
    setIsLoading(true);
    try {
      const taskFromAPI = await getTrainingTask(APItaskId);
      if (taskFromAPI) {
        taskFromAPI.training = taskFromAPI.training.sort(
          (a, b) => a.order - b.order
        );

        const filteredTrainingGroups: TrainingGroup[] = [];

        taskFromAPI.training.forEach((el) => {
          filteredTrainingGroups.push({
            ...el.training_group,
            order: el.order + 1,
          });
        });

        const taskFormatted: TrainingTask = {
          id: taskFromAPI.id,
          finished: taskFromAPI.finished,
          timer: taskFromAPI.timer,
          training_groups: filteredTrainingGroups,
        };

        setTask(taskFormatted);
      }
    } catch (e) {
      toast.error(
        `Nous n'avons pas pu charger votre épreuve${
          isCCB ? ' de concours blanc' : ''
        }. Veuillez réessayer un peu plus tard.`
      );
      reportErrorToSentry(e);
      if (isCCB) {
        navigate('/concours-blanc');
      } else {
        navigate('/');
      }
    } finally {
      setIsLoading(false); // Update loading state to false after API call is completed
    }
  }

  useEffect(() => {
    if (taskId) {
      getTaskFromAPI(taskId);
    } else {
      setIsLoading(false); // Update loading state to false if no taskId
    }
    // eslint-disable-next-line
  }, []);

  return {
    task,
    isLoading,
  };
};
