import { device } from 'helpers/css/responsive';
import { FC } from 'react';
import styled from 'styled-components';
// import { Button as RawButton } from 'ui/Button';
import { Card as RawCard } from 'ui/Card';
import { useGetContactConfirmationState } from './hooks/useGetContactConfirmationState';

import { OutlinedCheck } from 'ui/icons/OutlinedCheck';
//import { useNavigate } from 'react-router-dom';
// import { routes } from 'routing/constants';

interface Props {}

export const ContactConfirmation: FC<Props> = (props) => {
  const state = useGetContactConfirmationState();
  // const navigate = useNavigate();

  // const goToContact = () => {
  //   navigate(routes.CONTACT);
  // };

  return (
    <Container>
      <InnerContainer>
        <Card>
          <Icon>
            <OutlinedCheck />
          </Icon>
          <Title>{state?.title}</Title>
          <SubTitle>{state?.subtitle}</SubTitle>

          {/* <Button type="button" onClick={goToContact}>
            Retour à la rédaction de message
          </Button> */}
        </Card>
      </InnerContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border: 2px solid black;
`;

const InnerContainer = styled.div`
  padding: 12px;
`;

const Card = styled(RawCard)`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.primary.light};
`;

const Icon = styled.div`
  height: 100px;
  width: 100px;

  color: ${({ theme }) => theme.colors.success.main};

  @media ${device.desktop} {
    height: 144px;
    width: 144px;
  }
`;

const Title = styled.h1`
  margin-top: 16px;
  text-align: center;

  @media ${device.desktop} {
    margin-top: 32px;
  }
`;

const SubTitle = styled.h3`
  margin-top: 16px;
  text-align: center;

  @media ${device.desktop} {
    margin-top: 28px;
  }
`;

// const Button = styled(RawButton)`
//   margin-top: 28px;

//   @media ${device.desktop} {
//     margin-top: 32px;
//   }
// `;
