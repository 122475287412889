import styled from 'styled-components/macro';

import { GoBackBlock } from '../local-ui/GoBackBlock';
import { FQBody } from './FQBody/FQBody';
import { FQFooter } from './FQFooter/FQFooter';
import { FQHeader } from './FQHeader/FQHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
  reset,
  selectCard,
  selectCardStatus,
  setMode,
} from 'store/slices/fqCurrentSlice';
import { FQMode } from 'pages/flash-questions/types';
import { Loader } from 'components/Loader/Loader';
import { device } from 'helpers/css/responsive';
import { useEffect } from 'react';
import { getCardToDo } from 'store/actions/fqCurrentActions';
import { Status } from 'store/types';
import { toast } from 'react-toastify';
import { selectChosenDeckInfos } from 'store/slices/fqCurrentSlice';

export const FQProcessPage = () => {
  const dispatch = useDispatch();
  const cardStatus = useSelector(selectCardStatus);
  const card = useSelector(selectCard);
  const deck = useSelector(selectChosenDeckInfos);

  useEffect(() => {
    if (cardStatus === Status.IDLE) {
      dispatch(getCardToDo());
    }
  }, [dispatch, cardStatus]);

  useEffect(() => {
    if (cardStatus === Status.REJECTED) {
      toast.info("Vous n'avez plus de questions à faire aujourd'hui.", {
        toastId: 'noFQTodo',
        autoClose: false,
      });

      dispatch(setMode({ mode: FQMode.DECKLIST }));
      dispatch(reset());
    }
  }, [dispatch, cardStatus]);

  const presentLauncher = () => {
    dispatch(setMode({ mode: FQMode.DECKLAUNCH }));
  };

  if (cardStatus === Status.PENDING) {
    return (
      <Container>
        <Layout>
          <LoaderContainer>
            <Loader>Chargement de la question...</Loader>
          </LoaderContainer>
        </Layout>
      </Container>
    );
  }

  if (cardStatus === Status.RESOLVED && card !== null) {
    return (
      <Container>
        <Layout>
          <TitleRow>
            <h1>Questions flash</h1>
            <GoBackBlock goBackFunction={presentLauncher} />
          </TitleRow>
          <FQHeader card={card} deck={deck} />
          <FQBody />
          <FQFooter />
        </Layout>
      </Container>
    );
  }

  return null;
};
const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Layout = styled.div`
  width: 100%;
  max-width: 1300px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 1rem 2rem 1rem;
  @media ${device.desktop} {
    padding: 2rem 2rem 3rem 2rem;
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 3%;
  margin-bottom: 32px;
`;
