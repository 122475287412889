import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { useDecksTableConfigContext } from '../context-provider/decksTableConfigContext';

export function usePaginate() {
  const dispatch = useDispatch();
  const { tableStateSelector, setPageCreator } = useDecksTableConfigContext();

  const currentPage = useSelector(
    (state: RootState) => tableStateSelector(state).page
  );

  const setCurrentPage = (page: number) => {
    dispatch(setPageCreator(page));
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  return { handlePageClick, currentPage, setCurrentPage };
}
