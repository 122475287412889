import styled from 'styled-components';

export const StyledTooltip = styled.div`
  background-color: rgba(2, 20, 39, 1);
  padding: 0.5rem 1rem 0.75rem;
  color: white;
  border-radius: 4px;
`;

export const TooltipTitle = styled.p`
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0 0 0.5rem;
`;

export const TooltipLine = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

export const TooltipText = styled.p`
  font-size: 0.85rem;
  margin: 0;
  color: white;
  font-weight: 400;
`;
