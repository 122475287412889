import { FC } from 'react';
import { formatGradeOutOfTwenty } from 'pages/correction/components/CorrectionGroupHeader/points-formatter';

import { HistoryGroup } from 'modules/group-history/domain/entities/HistoryGroup';
import { GradeData } from 'pages/correction/components/CorrectionGroupHeader/points-formatter';
import { FormattedDate } from 'pages/selection/components/FormattedDate';
import { PastCorrectionButton } from 'pages/progress/GeneralProgress/components/PastCorrectionButton';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routing/constants';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';

interface Props {
  group: HistoryGroup;
}

export const GroupHistoryRow: FC<Props> = ({ group }) => {
  const { isUserPremium } = useIsUserPremium();
  const groupGradeData: GradeData = {
    grade: group.grade,
    totalCoeff: group.totalCoefficient,
  };

  const navigate = useNavigate();

  const goToCorrection = () => {
    if (group.taskCorrectionId) {
      navigate({
        pathname: routes.TASK_CORRECTION,
        search:
          '?id=' +
          group.taskCorrectionId +
          '&groupid=' +
          group.groupCorrectionId,
      });
    }
  };

  return (
    <>
      <div>{group.type}</div>
      <FormattedDate date={group.date} />
      <div>{formatGradeOutOfTwenty(groupGradeData)}</div>
      <div>
        {isUserPremium && <PastCorrectionButton onClick={goToCorrection} />}
      </div>
    </>
  );
};
