import { FC, Fragment } from 'react';
import styled from 'styled-components/macro';
import { PreviewBlockProps } from '../../types';

export const TCSPreviewBlock: FC<PreviewBlockProps> = ({ questionData }) => {
  return (
    <Container>
      <FirstHeader>{questionData.tcs_header?.first_column}</FirstHeader>
      <Header>{questionData.tcs_header?.second_column}</Header>
      <LastHeader>{questionData.tcs_header?.third_column}</LastHeader>
      {questionData.items_tcs.map((question) => (
        <Fragment key={question.num}>
          <Cell>{question.first_column}</Cell>
          <Cell>{question.second_column}</Cell>
          <Separator />
        </Fragment>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 3fr 5fr 3fr;
  padding: 0 24px;
  width: 100%;
`;

const Header = styled.div`
  padding: 24px 16px;
  background: ${({ theme }) => theme.colors.primary.light};

  display: grid;
  place-items: center;
`;

const FirstHeader = styled(Header)`
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

const LastHeader = styled(Header)`
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const Cell = styled.div`
  display: grid;
  place-items: center;
  min-height: 110px;
  text-align: center;
`;

const Separator = styled.div`
  grid-column: 1/-1;
  padding: 0 12px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light};
`;
