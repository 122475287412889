import { FC } from 'react';
import styled from 'styled-components/macro';
import ReactSelect, {
  Props as SelectProps,
  GroupBase,
  DropdownIndicatorProps,
  components,
  MenuProps,
} from 'react-select';

import { DownArrow } from 'pages/home/ui/DownArrow';
import { IsMulti } from 'ui/Dropdown/dropdownStyles';

import { useStyles } from './useStyles';

export interface Option {
  label: string;
  value: string;
}

interface Props extends SelectProps<Option, IsMulti, GroupBase<Option>> {
  className?: string;
  placeholder?: string;
}

export const ViewDropdown: FC<Props> = ({ className, ...restOfProps }) => {
  const selectStyles = useStyles();

  return (
    <Container className={className}>
      <ReactSelect<Option>
        components={{
          DropdownIndicator: DropdownIndicator,
          Menu: Menu,
        }}
        styles={selectStyles}
        {...restOfProps}
        id="view-dropdown"
        inputId="view-dropdown-inpu"
      />
    </Container>
  );
};

const Menu: FC<MenuProps<Option, IsMulti, GroupBase<Option>>> = (props) => {
  return (
    <div id="progress-selector-menu">
      <components.Menu {...props}>{props.children}</components.Menu>
    </div>
  );
};

const DropdownIndicator: FC<DropdownIndicatorProps<Option>> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <StyledDownArrow />
    </components.DropdownIndicator>
  );
};

const Container = styled.div``;

const StyledDownArrow = styled(DownArrow)`
  width: 8px;
  height: 8px;
`;
