import {
  SubmsissionButton as OKButton,
  trainingModalStyles,
} from 'pages/new_training/styles/modal';
import { FC, useState } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components/macro';
import '../../styles/react-modal.css';
import { ModalHeader } from '../ui/ModalHeader';
import { ModalCheckbox } from '../ui/ModalCheckbox';

import parse from 'html-react-parser';
import { OutlinedButton } from 'ui/Buttons/OutlinedButton';

interface Props {
  isOpen: boolean;
  onAccept(): void;
  onCancel(): void;
  title: string;
  text: string;
  confirmationButtonColor?: string;
  confirmationButtonHoverColor?: string;
}

export const NoAnswerModal: FC<Props> = (props) => {
  ReactModal.setAppElement('body');

  const [checked, setChecked] = useState(false);

  const handleCheck = () => {
    setChecked(!checked);
  };

  return (
    <ReactModal
      isOpen={props.isOpen}
      style={trainingModalStyles}
      closeTimeoutMS={300} // Time to allow the animation to play
      onRequestClose={props.onCancel}
      overlayClassName={'TrainingModal__Overlay'}
    >
      <ModalHeader onCrossClick={props.onCancel} title={props.title} />

      <Container>
        <TextContainer>{parse(props.text)}</TextContainer>
        <ModalCheckbox>
          <label className="container">
            <input type="checkbox" checked={checked} onChange={handleCheck} />
            <span className="checkmark"></span>
          </label>
          Je confirme l'absence de réponse à cette question
        </ModalCheckbox>

        <ButtonsContainer>
          <OutlinedButton onClick={props.onCancel}>Annuler</OutlinedButton>
          <OKButton
            onClick={props.onAccept}
            isChecked={checked}
            disabled={!checked}
          >
            OK
          </OKButton>
        </ButtonsContainer>
      </Container>
    </ReactModal>
  );
};

const ReactModal = styled(Modal)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 600px;
  max-width: 600px;
  background-color: #fff;
  border-radius: 0 0 0.3rem 0.3rem;
`;

const TextContainer = styled.div`
  padding: 1rem;
  font-family: 'Freesans';
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #212529;
`;

const ButtonsContainer = styled.div`
  padding: 1rem;
  display: flex;
  justify-content: center;

  > button:first-child {
    margin-right: 3.5rem;
  }
`;
