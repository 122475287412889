import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getSessionList } from './api';

import { SessionOption } from 'pages/home/components/TrainingStats/SessionDropdown';
import { useGetUserOptions } from 'modules/user-options/hooks/useGetUserOptions';
import { MS_IN_AN_HOUR } from 'constants/date';

export const useGetSessionsList = () => {
  const query = useQuery('selectionSessionList', getSessionList, {
    staleTime: MS_IN_AN_HOUR,
    cacheTime: MS_IN_AN_HOUR,
  });
  const mappedOptions = useMemo(
    () =>
      query.data?.map((session) => ({
        label: session.display_name,
        value: session.collection,
      })),
    [query.data]
  );

  const csmSessions = useMemo(
    () => query.data?.filter((session) => session.csm),
    [query.data]
  );

  if (query.data === undefined) return query;

  return {
    ...query,
    data: {
      options: mappedOptions,
      csmSessions,
    },
  };
};

export const useGetSessionsListExtendedValue = () => {
  const query = useQuery('selectionSessionList', getSessionList, {
    staleTime: MS_IN_AN_HOUR,
    cacheTime: MS_IN_AN_HOUR,
  });

  const mappedOptions = useMemo(
    () =>
      query.data?.map((session) => ({
        label: session.display_name,
        value: {
          collection: session.collection,
          completed: session.completed,
        },
      })),
    [query.data]
  );

  if (query.data === undefined) return query;

  return {
    ...query,
    data: {
      options: mappedOptions,
    },
  };
};

export const useGetSessionListRecap = (includeAnnalsOption = true) => {
  const query = useQuery('selectionSessionList', getSessionList, {
    staleTime: MS_IN_AN_HOUR,
    cacheTime: MS_IN_AN_HOUR,
  });
  const { data: userOptions } = useGetUserOptions();

  const [selectSessionList, setSelectSessionList] =
    useState<Array<SessionOption>>();

  const annals: SessionOption = {
    label: 'Annales',
    value: {
      collection: 'Annales',
      completed: false,
    },
  };

  useEffect(() => {
    if (query.data && userOptions) {
      const newSelectSessionList = query.data
        .filter((session) => {
          if (
            query.data.findIndex(
              (el) => session !== el && el.collection === session.collection
            ) > -1
          ) {
            return false;
          }
          return true;
        })
        .map((session) => ({
          label: session.display_name,
          value: {
            collection: session.collection,
            completed: session.completed,
          },
        }));

      if (includeAnnalsOption) {
        setSelectSessionList([annals, ...newSelectSessionList]);
      } else {
        setSelectSessionList(newSelectSessionList);
      }
    }
    //eslint-disable-next-line
  }, [query.data, userOptions]);

  return {
    ...query,
    selectSessionList,
  };
};
