import { MathJax } from 'better-react-mathjax';
import styled from 'styled-components/macro';

export const StyledMathJax = styled(MathJax)`
  display: flex;
  font-size: 14px;
  font-family: 'FreeSans', sans-serif;

  p {
    font-family: 'FreeSans', sans-serif;
  }
`;
