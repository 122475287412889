import { FC } from 'react';
import styled from 'styled-components/macro';
import { formatGradeOutOfTwenty } from 'pages/correction/components/CorrectionGroupHeader/points-formatter';

interface Props {
  grade?: number | null;
  totalCoeff: number;
  groupType: 'DP' | 'LCA';
}

export const Median: FC<Props> = ({ grade, totalCoeff, groupType }) => {
  return (
    <Container>
      {formatGradeOutOfTwenty(
        {
          grade: grade !== undefined ? grade : null,
          totalCoeff,
        }
        // groupType
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 14px;
  justify-content: center;
`;
