import axios from 'axios';
import { buildApiUrl } from 'http/utils';
import {
  VerifyEmailResponse,
  VerifyEmailBody,
  ResendVerificationEmailBody,
  ResendVerificationEmailResponse,
} from './types';

export const requestVerifyEmail = async (token: string) => {
  const route = buildApiUrl('/auth/signup/verify-email/');
  const body: VerifyEmailBody = {
    key: token,
  };

  const axiosResponse = await axios.post<VerifyEmailResponse>(route, body);

  return axiosResponse.data;
};

export const requestResendVerificationEmail = async (email: string) => {
  const route = buildApiUrl('/auth/signup/resent_verification/');
  const body: ResendVerificationEmailBody = {
    email,
  };

  const axiosResponse = await axios.post<ResendVerificationEmailResponse>(
    route,
    body
  );

  return axiosResponse.data;
};
