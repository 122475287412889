import { FC } from 'react';
import styled from 'styled-components';

import { Loader } from 'components/Loader/Loader';
import { FullWidthRow } from './FullWidthRow';

interface Props {
  colNumber: number;
}

export const TableLoading: FC<Props> = ({ colNumber }) => (
  <FullWidthRow colNumber={colNumber}>
    <LoadingContainer>
      <Loader />
      <h2>Chargement...</h2>
    </LoadingContainer>
  </FullWidthRow>
);

const LoadingContainer = styled.div`
  width: 100%;
  min-height: 500px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;
