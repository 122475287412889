import { createContext, FC, useContext, useMemo } from 'react';
import { CorrectionGroup } from 'types/correction.type';

interface ActiveCorrectionContext {
  totalCoefficient: number;
  isCustomQI: boolean;
}

const contextDefaultValue: ActiveCorrectionContext = {
  totalCoefficient: 1,
  isCustomQI: false,
};

const activeCorrectionContext = createContext(contextDefaultValue);

interface ProviderProps {
  groupData: CorrectionGroup;
}

export const ActiveCorrectionProvider: FC<ProviderProps> = ({
  children,
  groupData,
}) => {
  const totalCoefficient = useMemo(
    () => groupData.total_coefficient ?? 1,
    [groupData]
  );

  const isCustomQI = useMemo(() => groupData.custom_qi, [groupData]);

  return (
    <activeCorrectionContext.Provider value={{ totalCoefficient, isCustomQI }}>
      {children}
    </activeCorrectionContext.Provider>
  );
};

export const useActiveCorrectionContext = () =>
  useContext(activeCorrectionContext);
