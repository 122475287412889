export const PasswordEyeClosed = () => {
  return (
    <svg viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.082 13.952C5.802 12.81 5.079 12.36 2 9.902c3.079-3.514 7.937-7.378 13.684-7.378 1.14 0 3.801.228 5.322 1.143"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M21.006 3.667C25.187 6.333 25.947 7.477 28 9.9c-2.053 2.46-7.39 6.043-12.316 6.323-1.393.005-4.865-.443-7.602-2.272"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <ellipse
        cx={15.456}
        cy={8.238}
        rx={4.942}
        ry={4.952}
        fill="currentColor"
      />
      <path
        d="M15.456 13.19a4.947 4.947 0 004.942-4.952c0-1.828-.862-2.794-1.293-3.048l-7.602 5.715c.608 1.828 2.889 2.286 3.953 2.286z"
        fill="currentColor"
      />
      <path
        d="M24.047 1L4.28 17"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
};
