import { FC } from 'react';
import styled, { css } from 'styled-components/macro';

interface Props {
  isSelected: boolean;
  label: string;
  onClick: () => void;
  isSmall?: boolean;
}

export const RadioText: FC<Props> = ({
  isSelected,
  label,
  onClick,
  isSmall,
}) => {
  return (
    <Container
      isSmall={isSmall}
      isSelected={isSelected}
      type="button"
      onClick={onClick}
    >
      <Label isSelected={isSelected} isSmall={isSmall}>
        {label}
      </Label>
    </Container>
  );
};

const Container = styled.button<{ isSelected: boolean; isSmall?: boolean }>`
  all: unset;

  padding: 8px;
  border-radius: 12px;
  cursor: pointer;

  transition-property: background-color, color;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  ${({ theme, isSelected, isSmall }) =>
    isSelected &&
    (isSmall
      ? css`
          background-color: ${({ theme }) => theme.colors.primary.light};
        `
      : css`
          background-color: ${theme.colors.primary.dark};
          color: ${theme.colors.primary.contrastText};
        `)}

  ${({ isSmall }) =>
    isSmall &&
    css`
      padding: 2px 7px;
      border-radius: 4px;
    `}
`;

const Label = styled.label<{ isSelected: boolean; isSmall?: boolean }>`
  cursor: inherit;
  white-space: nowrap;

  ${({ isSelected, isSmall }) => !isSmall && !isSelected && 'font-weight: 400;'}

  ${({ isSmall }) =>
    isSmall &&
    css`
      font-weight: normal;
      font-size: 16px;
      line-height: 20px;
    `}
`;
