import debounce from 'lodash.debounce';
import {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Options } from 'types/options.type';

interface HomeItemsFiltersContextProps {
  itemPageSize: number;
  setItemPageSize: (itemPageSize: number) => void;
  currentItemPage: number;
  setCurrentItemPage: (itemPageNumber: number) => void;
  itemsSearchedBooks: Options;
  setItemsSearchedBooks: (itemsSearchedBooks: Options) => void;
  itemsSearchedSpe: Options;
  setItemsSearchedSpe: (itemsSearchedSpe: Options) => void;
  itemStudyTours: Options;
  setItemStudyTours: (itemStudyTours: Options) => void;
  handleResetItemsFilters: () => void;
  itemSearchTerm: string;
  setItemSearchTerm: (itemSearchTerm: string) => void;
  debouncedSearchTerm: string;
  isStudyTourLoading: boolean;
  setIsStudyTourLoading: (isStudyTourLoading: boolean) => void;
}

const contextDefaultValue: HomeItemsFiltersContextProps = {
  currentItemPage: 0,
  setCurrentItemPage: () => {},
  itemPageSize: 10,
  setItemPageSize: () => {},
  itemsSearchedBooks: [],
  setItemsSearchedBooks: () => {},
  itemsSearchedSpe: [],
  setItemsSearchedSpe: () => {},
  itemStudyTours: [],
  setItemStudyTours: () => {},
  handleResetItemsFilters: () => {},
  itemSearchTerm: '',
  setItemSearchTerm: () => {},
  debouncedSearchTerm: '',
  isStudyTourLoading: false,
  setIsStudyTourLoading: () => {},
};

const HomeItemsFiltersContext = createContext(contextDefaultValue);

export const HomeItemsFiltersProvider: FC = ({ children }) => {
  const [itemPageSize, setItemPageSize] = useState<number>(10);
  const [currentItemPage, setCurrentItemPage] = useState<number>(0);

  const [itemsSearchedBooks, setItemsSearchedBooks] = useState<Options | []>(
    []
  );
  const [itemStudyTours, setItemStudyTours] = useState<Options>([]);
  const [itemsSearchedSpe, setItemsSearchedSpe] = useState<Options | []>([]);

  const [itemSearchTerm, setItemSearchTerm] = useState<string>('');
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');

  const [isStudyTourLoading, setIsStudyTourLoading] = useState<boolean>(false);

  const handleNewSearchterm = useMemo(
    () => debounce(setDebouncedSearchTerm, 500),
    [setDebouncedSearchTerm]
  );

  useEffect(() => {
    handleNewSearchterm(itemSearchTerm);
  }, [handleNewSearchterm, itemSearchTerm]);

  const handleResetItemsFilters = () => {
    setItemPageSize(10);
    setCurrentItemPage(0);
    setItemsSearchedBooks([]);
    setItemsSearchedSpe([]);
    setItemStudyTours([]);
    setItemSearchTerm('');
    setDebouncedSearchTerm('');
  };

  return (
    <HomeItemsFiltersContext.Provider
      value={{
        itemPageSize,
        setItemPageSize,
        currentItemPage,
        setCurrentItemPage,
        itemsSearchedBooks,
        setItemsSearchedBooks,
        itemsSearchedSpe,
        setItemsSearchedSpe,
        itemStudyTours,
        setItemStudyTours,
        handleResetItemsFilters,
        itemSearchTerm,
        setItemSearchTerm,
        debouncedSearchTerm,
        isStudyTourLoading,
        setIsStudyTourLoading,
      }}
    >
      {children}
    </HomeItemsFiltersContext.Provider>
  );
};

export const useHomeItemsFiltersContext = () =>
  useContext(HomeItemsFiltersContext);

export const STATEMENT_ID = -1;
