import { forwardRef, ReactNode } from 'react';
import styled from 'styled-components/macro';

interface Props {
  title: string;
  children: ReactNode;
}

export const InfoSection = forwardRef<HTMLDivElement, Props>(
  ({ title, children }, ref) => {
    return (
      <TextSection ref={ref}>
        <h2>{title}</h2>
        <div>{children}</div>
      </TextSection>
    );
  }
);

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 12px var(--horizontal-padding);

  ul {
    margin: 0;
  }
`;
