import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { device } from 'helpers/css/responsive';
import { routes } from 'routing/constants';

import { Card } from 'ui/Card';
import { LoginRelatedBackground } from 'components/LoginRelatedBackground';

import { LoginForm } from './components/LoginForm';
import { LinkToLostPassword } from './components/LinkToLostPassword';

export const Login = () => {
  return (
    <LoginRelatedBackground>
      <Container>
        <ContainerCard>
          <h1>Connexion</h1>

          <LoginForm />

          <LostPasswordLinkMobile>
            <LinkToLostPassword />
          </LostPasswordLinkMobile>

          <NeedAccountLink>
            Besoin d'un compte ? <Link to={routes.SIGN_UP}>S'inscrire</Link>
          </NeedAccountLink>
        </ContainerCard>
      </Container>
    </LoginRelatedBackground>
  );
};

const Container = styled.div`
  height: 100%;
  min-height: 100vh;

  display: grid;
  place-items: center;

  padding: 16px;
`;

const ContainerCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LostPasswordLinkMobile = styled.span`
  margin-top: 28px;

  @media ${device.desktop} {
    display: none;
  }
`;

const NeedAccountLink = styled.span`
  margin-top: 8px;

  @media ${device.desktop} {
    margin-top: 32px;
  }
`;
