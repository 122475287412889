import { FC } from 'react';
import { MenuIconProps } from './types';

export const MenuLessons: FC<MenuIconProps> = ({ isFilled }) => {
  return (
    <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 7.676 5.588 1v6.676H1Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={isFilled ? 2 : 1}
      />
      <path
        d="M1 7.676V17h15.772V1H5.588"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={isFilled ? 2 : 1}
      />
      <path
        d="M17.125 4.385h3.75v16H6.25v-3.077"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={isFilled ? 2 : 1}
      />
      <path
        d="M21.25 9.308H25V25H10v-4"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={isFilled ? 2 : 1}
      />
    </svg>
  );
};
