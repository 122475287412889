import { FC } from 'react';
import { Menu, MenuItem, FocusableItem } from '@szhsin/react-menu';
import styled from 'styled-components/macro';

import { Options, useTableFilters } from 'hooks/useTableFilter';
import { ColumnHeaderWrapper } from 'components/Filters/ColumnHeaderWrapper';
import { FilterInput } from 'components/Filters/FilterInput';

interface Props {
  columnName: string;
  filterOptions: Options[];
  selectedFilters: string[];
  setSelectedFilters: (items: string[]) => void;
  isSub?: boolean;
  disabled?: boolean;
  hasResearch?: boolean;
}

export const MutlipleOptionSearchFilter: FC<Props> = ({
  columnName,
  filterOptions,
  selectedFilters,
  setSelectedFilters,
  isSub,
  disabled,
  hasResearch,
}) => {
  const {
    filteredOptions,
    isFilterSelected,
    searchInput,
    setSearchInput,
    toggleFilter,
  } = useTableFilters(filterOptions ?? [], selectedFilters, setSelectedFilters);

  if (disabled) {
    return (
      <ColumnHeaderWrapper isSub={isSub} isReadOnly isBig>
        {columnName}
      </ColumnHeaderWrapper>
    );
  }

  return (
    <Menu
      menuButton={
        <ColumnHeaderWrapper isSub={true} filters={selectedFilters.length}>
          {columnName}
        </ColumnHeaderWrapper>
      }
      transition
      menuStyles={{
        maxHeight: '500px',
        maxWidth: '350px',
        position: 'absolute',
        zIndex: 1000,
      }}
    >
      {hasResearch && (
        <StickyFocusableItem>
          {({ ref }) => (
            <FullWidthInput
              ref={ref}
              type="text"
              placeholder="Rechercher"
              value={searchInput}
              onChange={(e) => setSearchInput(e.currentTarget.value)}
            />
          )}
        </StickyFocusableItem>
      )}
      <MenuItemsContainer>
        {filteredOptions.map((option) => (
          <MenuItem
            type="checkbox"
            value={option.value}
            key={option.value}
            checked={isFilterSelected(option.value)}
            onClick={(e) => {
              e.keepOpen = true;
              toggleFilter(option.value);
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </MenuItemsContainer>
    </Menu>
  );
};

const StickyFocusableItem = styled(FocusableItem)`
  position: sticky;
  top: 0px;
  z-index: 1;
`;

const FullWidthInput = styled(FilterInput)`
  width: 100%;
`;

const MenuItemsContainer = styled.div`
  max-height: 450px;
  overflow-y: auto;
  z-index: 0;
  position: relative;
`;
