import styled, { css } from 'styled-components/macro';
import { device } from 'helpers/css/responsive';
import { useDispatch, useSelector } from 'react-redux';
import { selectDeck, selectDeckStatus } from 'store/slices/fqCurrentSlice';
import { Status } from 'store/types';
import { useEffect } from 'react';
import { getDeck } from 'store/actions/fqCurrentActions';
import { FlashDeck } from 'types/flash.type';

export const FQGeneralStatus = () => {
  const deck = useSelector(selectDeck);
  const deckStatus = useSelector(selectDeckStatus);
  const dispatch = useDispatch();

  useEffect(() => {
    if (deckStatus === Status.IDLE) {
      dispatch(getDeck());
    }
  }, [dispatch, deckStatus]);

  const renderText = (index: keyof FlashDeck) => {
    if (deckStatus === Status.REJECTED) return <p>Error</p>;
    if (deckStatus === Status.RESOLVED && deck !== null) {
      return <p>{deck[index]}</p>;
    }
  };

  if (deckStatus === Status.PENDING) {
    return (
      <Container>
        <SkeletonFQStatusInfo className="skeleton" />
        <SkeletonFQStatusInfo className="skeleton" />
        <SkeletonFQStatusInfo className="skeleton" />
      </Container>
    );
  }
  return (
    <Container>
      <FQStatusInfo>
        Nouvelles : &nbsp; {renderText('day_new_cards_count')}
      </FQStatusInfo>
      <FQStatusInfo>
        À revoir : &nbsp; {renderText('day_to_redo_cards_count')}
      </FQStatusInfo>
      <FQStatusInfo>
        À réviser : &nbsp; {renderText('day_to_review_cards_count')}
      </FQStatusInfo>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  @media ${device.desktop} {
    flex-direction: row;
  }
  justify-content: space-between;
`;

const FQStatusInfo = styled.div`
  display: flex;
  align-items: center;
  @media ${device.desktop} {
    margin-right: 4vw;
    //margin-left: 2em;
  }
  white-space: nowrap;
  ${({ theme }) =>
    css`
      color: ${theme.colors.secondary.dark};
    `};
`;

const SkeletonFQStatusInfo = styled(FQStatusInfo)`
  min-width: 100%;
  border-radius: 5px;
  min-height: 30px;
  height: 100%;
  width: 100%;
`;
