import { useState, useMemo } from 'react';
import styled, { useTheme } from 'styled-components';
import BlueCard from 'ui/Cards/BlueCard';
import { Section, SubTitle } from '../CCBStatsAndGrades';
import { BlackButtonSwitch } from 'ui/switches/BlackButtonSwitch';
import { useSelector } from 'react-redux';
import { Dot, SubTitleText } from '../RepartitionLca/RepartitionLca';
import { RepartitionGradesUC } from './RepartitionGradesUC';
import { GradeCount } from 'http/ccb/type';
import { Legend, Legends } from 'ui/Graph/Legends';

export const UCGrades = () => {
  const theme = useTheme();
  const { ccbStats } = useSelector((state: any) => state.ccb);

  const periodData = useMemo(() => {
    return ccbStats?.period_grades;
  }, [ccbStats]);
  const GeneralData = useMemo(() => {
    return ccbStats?.total_grades;
  }, [ccbStats]);

  const [activeDataSet, setActiveDataSet] = useState<GradeCount[]>(
    ccbStats?.period_grades
  );

  const handleDataSwitch = (clickedOption: string) => {
    if (clickedOption === 'period') {
      setActiveDataSet(periodData);
    }
    if (clickedOption === 'general') {
      setActiveDataSet(GeneralData);
    }
  };

  return (
    <BlueCard $bigPadding fullWidth maxWidth="50%">
      <Section vertical>
        <TopContainer>
          <SubTitle>Répartitions des notes des participants par UC</SubTitle>
          <BlackButtonSwitch
            option1Text="Sur la période"
            onclickOption1={() => handleDataSwitch('period')}
            option2Text="Global"
            onclickOption2={() => handleDataSwitch('general')}
          />
        </TopContainer>
        <StyledLegends>
          <StyledLegend>
            <Dot fillColor={theme.colors.warning.dark} />
            <SubTitleText>UC 1</SubTitleText>
          </StyledLegend>
          <StyledLegend>
            <Dot fillColor={'#EF9198'} />
            <SubTitleText>UC 2</SubTitleText>
          </StyledLegend>
          <StyledLegend>
            <Dot fillColor={theme.colors.success.main} />
            <SubTitleText>UC 3</SubTitleText>
          </StyledLegend>
          <StyledLegend>
            <Dot fillColor={theme.colors.secondary.main} />
            <SubTitleText>Mes notes</SubTitleText>
          </StyledLegend>
        </StyledLegends>
        <RepartitionGradesUC
          data={activeDataSet}
          gradeUC1={ccbStats?.user_UC1_grade}
          gradeUC2={ccbStats?.user_UC2_grade}
          gradeUC3={ccbStats?.user_UC3_grade}
        />
      </Section>
    </BlueCard>
  );
};

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

const StyledLegends = styled(Legends)`
  justify-content: space-around;
`;

const StyledLegend = styled(Legend)`
  width: auto;
`;
