import { Options } from 'types/options.type';
const typeFilterOptions: Options = [
  {
    label: 'QRPL',
    value: 'QRPL',
  },
  {
    label: 'QROC',
    value: 'QROC',
  },
  {
    label: 'QRM',
    value: 'QRM',
  },
  {
    label: 'QRU',
    value: 'QRU',
  },
  {
    label: 'QRP',
    value: 'QRP',
  },
  {
    label: 'TCS',
    value: 'TCS',
  },
  {
    label: 'Zone à pointer',
    value: 'QZP',
  },
];

export default typeFilterOptions;

export const studyTourOptions: Options = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
];
