import { getLessonsRepository } from 'modules/lessons/http/repository';
import { LessonPage } from 'types/lesson.type';
import { LessonPageParams } from '../entities/LessonPageParams';

export const getRawLessonPage = async (
  params: LessonPageParams
): Promise<LessonPage> => {
  const rawLessonPage = await getLessonsRepository(params);
  return rawLessonPage;
};
