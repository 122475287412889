import { RepartitionBarData } from 'components/StatsElements/RepartitionBar';
import {
  CardsContainerHeader,
  FlexCenter,
  SectionTitle,
} from 'pages/newHome/ui/Global';
import BlueCard from 'ui/Cards/BlueCard';
import { IconContainer } from 'ui/icons/IconContainer';
import { MenuStart } from 'ui/icons/MenuIcons/MenuStart';
import { GlobalAvancement } from './GlobalAvancement';
import { TrainingQuestions } from './TrainingQuestions';
import { FC } from 'react';
import { useHomeInfoContext } from 'pages/newHome/context/homeInfoContext';
import { GenerateRandomSelectionButton } from 'pages/selection/components/GenerateRandomSelectionButton';
import { HomeStatsGrades } from '../HomeStats';

interface HomeTrainingsProps {
  overallData: RepartitionBarData;
  DPData?: RepartitionBarData;
  QIData?: RepartitionBarData;
  grades?: HomeStatsGrades;
}

export const HomeTrainings: FC<HomeTrainingsProps> = ({
  overallData,
  DPData,
  QIData,
  grades,
}) => {
  const { activeSpecialty } = useHomeInfoContext();

  return (
    <BlueCard $bigPadding fullWidth gap="2rem">
      <CardsContainerHeader>
        <IconContainer big>
          <MenuStart isFilled />
        </IconContainer>
        <SectionTitle bold>Entraînements</SectionTitle>
      </CardsContainerHeader>
      <GlobalAvancement
        progressionBarData={overallData}
        grade={grades?.overall}
      />
      {activeSpecialty && (
        <TrainingQuestions
          dpData={DPData}
          qiData={QIData}
          dpGrade={grades?.dp}
          qiGrade={grades?.qi}
        />
      )}
      <FlexCenter>
        <GenerateRandomSelectionButton />
      </FlexCenter>
    </BlueCard>
  );
};
