import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { queryParamsSerizalizer } from 'helpers/http/queryParams';
import { getSession } from './authCore';
import './axiosInterceptors';

export interface APIError extends AxiosError {
  errorMessages?: Array<string>;
}

const getAxiosConfig = (jwt_token: string, session_token: string) => ({
  headers: {
    Authorization: 'JWT '
      .concat(jwt_token)
      .concat(',SESSION ')
      .concat(session_token),
  },
});

export async function postAPI<TResponse, TBody = any>(
  route: string,
  body: TBody
) {
  const URL = buildURL(route);
  const { jwt_token, session_token, expiry } = getSession();
  if (jwt_token && session_token && expiry) {
    const axiosConfig: AxiosRequestConfig = getAxiosConfig(
      jwt_token,
      session_token
    );
    const response = await axios.post<TResponse>(URL, body, axiosConfig);

    return response;
  } else {
    return await axios.post<TResponse>(URL, body);
  }
}

export async function getAPI<TResponse>(
  route: string,
  axiosConfig?: AxiosRequestConfig
) {
  const URL = buildURL(route);
  const { jwt_token, session_token, expiry } = getSession();
  axiosConfig = {
    ...queryParamsSerizalizer,
    ...axiosConfig,
  };

  if (jwt_token && session_token && expiry) {
    const loginAxiosConfig: AxiosRequestConfig = getAxiosConfig(
      jwt_token,
      session_token
    );
    const response = await axios.get<TResponse>(URL, {
      ...axiosConfig,
      ...loginAxiosConfig,
    });

    return response;
  } else {
    return await axios.get<TResponse>(URL, axiosConfig);
  }
}

export async function putAPI<TResponse, TBody = any>(
  route: string,
  body: TBody
) {
  const URL = buildURL(route);
  const { jwt_token, session_token, expiry } = getSession();
  if (jwt_token && session_token && expiry) {
    const axiosConfig: AxiosRequestConfig = getAxiosConfig(
      jwt_token,
      session_token
    );
    const response = await axios.put<TResponse>(URL, body, axiosConfig);

    return response;
  } else {
    return await axios.post<TResponse>(URL, body);
  }
}

export async function patchAPI<TResponse, TBody = any>(
  route: string,
  body: TBody
) {
  const URL = buildURL(route);
  const { jwt_token, session_token, expiry } = getSession();
  if (jwt_token && session_token && expiry) {
    const axiosConfig: AxiosRequestConfig = getAxiosConfig(
      jwt_token,
      session_token
    );
    const response = await axios.patch<TResponse>(URL, body, axiosConfig);

    return response;
  } else {
    return await axios.post<TResponse>(URL, body);
  }
}

export async function deleteAPI<TResponse, TBody = any>(
  route: string,
  body?: TBody
) {
  const URL = buildURL(route);
  const { jwt_token, session_token, expiry } = getSession();
  if (jwt_token && session_token && expiry) {
    const loginAxiosConfig: AxiosRequestConfig = getAxiosConfig(
      jwt_token,
      session_token
    );
    const response = await axios.delete(URL, {
      ...loginAxiosConfig,
      data: body,
    });

    return response;
  } else {
    return await axios.delete<TResponse>(URL);
  }
}

const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export function buildURL(route: string): string {
  if (!BASE_URL) {
    throw Error('API Base Url is not defined');
  }
  if (route && route[0] !== '/') {
    route = '/'.concat(route);
  } else if (route && route[route.length - 1] !== '/') {
    route = route.concat('/');
  }
  return BASE_URL.concat(route);
}
