import { useEffect, useState } from 'react';
import { ThemeVariants } from 'theme';
import { getDefaultTheme, getThemeFromStorage } from './helpers';

const THEME_UPDATED_IN_STORAGE_EVENT = 'theme_toggled';

export const useThemeVariants = () => {
  const [themeVariant, setThemeVariant] = useState<ThemeVariants>('light'); // default initial value

  useEffect(() => {
    const fetchDefaultTheme = async () => {
      const defaultTheme = await getDefaultTheme();
      setThemeVariant(defaultTheme);
    };

    fetchDefaultTheme();

    document.addEventListener(
      THEME_UPDATED_IN_STORAGE_EVENT,
      setThemeFromStorage
    );

    return () => {
      document.removeEventListener(
        THEME_UPDATED_IN_STORAGE_EVENT,
        setThemeFromStorage
      );
    };
  }, []);

  const setThemeFromStorage = async () => {
    const localTheme = await getThemeFromStorage();
    setThemeVariant(localTheme);
  };

  const themeToggler = () => {
    themeVariant === 'light'
      ? setVariantInStorage('dark')
      : setVariantInStorage('light');
  };

  const setVariantInStorage = (themeVariant: ThemeVariants) => {
    window.localStorage.setItem('theme', themeVariant);
    const event = new Event(THEME_UPDATED_IN_STORAGE_EVENT);
    document.dispatchEvent(event);
  };

  return { themeVariant, themeToggler };
};
