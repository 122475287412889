import styled from 'styled-components';
import { device } from 'helpers/css/responsive';

export const PageTitle = styled.div`
  display: flex;
  ${({ theme }) => theme.typography.h1};
  font-weight: 700;
  line-height: 2.5rem;
  width: fit-content;
  @media ${device.desktop} {
    line-height: 4.5rem;
    font-size: 48px;
  }
`;
