import { FC } from 'react';
import styled from 'styled-components/macro';

import { DPProgressBySession } from 'modules/progress/general/dp/domain/entities/DPProgressBySession';

interface Props {
  progress: DPProgressBySession;
}

const colNumber = 4;

export const SessionHeader: FC<Props> = ({ progress }) => {
  return (
    <tr>
      <HeaderCell colSpan={colNumber}>{progress.collection}</HeaderCell>
    </tr>
  );
};

const HeaderCell = styled.td`
  background-color: ${({ theme }) => theme.colors.primary.main};

  padding: 4px 12px;
  vertical-align: middle;
  text-align: start;

  ${({ theme }) => theme.typography.label}
`;
