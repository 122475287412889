import styled from 'styled-components/macro';
import { LeftChevron } from 'ui/icons/LeftChevron';
import { device } from 'helpers/css/responsive';
import { FC } from 'react';

interface Props {
  goBackFunction(): void;
}

export const GoBackBlock: FC<Props> = ({ goBackFunction }) => {
  return (
    <ChevronBloc onClick={goBackFunction}>
      <Chevron />
      <ChevronText>Retour</ChevronText>
    </ChevronBloc>
  );
};

const Chevron = styled(LeftChevron)`
  @media ${device.desktop} {
    display: inline;
  }
  height: 16px;
`;

const ChevronBloc = styled.div`
  color: ${({ theme }) => theme.colors.secondary.dark};
  display: flex;
  margin-left: 2%;
  margin-top: 5px;
  gap: 16px;
  align-items: center;
  cursor: pointer;
`;

const ChevronText = styled.div`
  @media ${device.desktop} {
    display: inline;
  }
  font-weight: 600;
`;
