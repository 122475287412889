import { postAPI } from 'auth/authentifiedHttpCalls';
import { ChangeEmailBody, ChangeEmailResponse } from './types';
import { toast } from 'react-toastify';

export const requestChangeEmail = async (body: ChangeEmailBody) => {
  try {
    const response = await postAPI<ChangeEmailResponse, ChangeEmailBody>(
      '/auth/email/change_email',
      body
    );
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      toast.error(
        'Une erreur est survenue lors du traitement de votre demande, veuillez réessayer plus tard'
      );
    }

    throw e;
  }
};
