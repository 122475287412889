interface InputProps {
  className?: string;
}

export const DownloadIcon = (props: InputProps) => (
  <svg
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.2597 13.6972L21.2598 19.1472C21.2598 19.3734 21.1413 19.5903 20.9305 19.7502C20.7197 19.9101 20.4337 20 20.1356 20H2.14842C1.85027 20 1.56432 19.9101 1.3535 19.7502C1.14267 19.5903 1.35338 13.9233 1.35338 13.6971"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5188 -1.25549e-07L10.2069 -2.40809e-07L10.0006 -2.51097e-07C10.0006 0.267053 10.0006 0.902272 10.0006 2.90353L10.0006 11.6141L7.48227 11.6141L10.6662 14.305C10.9936 14.5881 11.5249 14.5881 11.8532 14.305L15.0371 11.6141L12.5188 11.6141L12.5188 2.90353C12.5188 -0.298337 12.5188 0.153637 12.5188 -1.25549e-07Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5188 -1.25549e-07L10.2069 -2.40809e-07L10.0006 -2.51097e-07C10.0006 0.267053 10.0006 0.902272 10.0006 2.90353L10.0006 11.6141L7.48227 11.6141L10.6662 14.305C10.9936 14.5881 11.5249 14.5881 11.8532 14.305L15.0371 11.6141L12.5188 11.6141L12.5188 2.90353C12.5188 -0.298337 12.5188 0.153637 12.5188 -1.25549e-07Z"
      fill="black"
      fillOpacity="0.2"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5188 -1.25549e-07L10.2069 -2.40809e-07L10.0006 -2.51097e-07C10.0006 0.267053 10.0006 0.902272 10.0006 2.90353L10.0006 11.6141L7.48227 11.6141L10.6662 14.305C10.9936 14.5881 11.5249 14.5881 11.8532 14.305L15.0371 11.6141L12.5188 11.6141L12.5188 2.90353C12.5188 -0.298337 12.5188 0.153637 12.5188 -1.25549e-07Z"
      fill="black"
      fillOpacity="0.2"
    />
  </svg>
);
