import { FC, useState } from 'react';
import styled from 'styled-components/macro';
import {
  CHUandSpeInfo,
  useEasyDispoContext,
} from '../../context/easydispoContext';
import { ClosingCross } from '../icons/ClosingCross';
import { SingleDropdown } from './SingleDropdown';
import { useEmptyTable } from 'http/rank-info/easy-dispo/connector';
import { SingleValue } from 'react-select';
import { Option } from '.';

interface SingleFilterLineProps {
  removeLineAtIndex: (index: number) => void;
  index: number;
  controlledValue: CHUandSpeInfo;
}

export const SingleFilterLine: FC<SingleFilterLineProps> = ({
  removeLineAtIndex,
  index,
  controlledValue,
}) => {
  const { setAffectationFilters } = useEasyDispoContext();
  const { defaultChuOptions, defaultSpecialtyOptions } = useEmptyTable();
  const [chus, setChus] = useState<SingleValue<Option> | undefined>();
  const [specialties, setSpecialties] = useState<
    SingleValue<Option> | undefined
  >();

  const handleChusChange = (newValue: SingleValue<Option>) => {
    setChus(newValue);
    setAffectationFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[index] = {
        ...updatedFilters[index],
        chuLabel: newValue?.value,
        chuCondensedLabel: newValue?.label,
      };
      return updatedFilters;
    });
  };

  const handleSpecialtiesChange = (newValue: SingleValue<Option>) => {
    setSpecialties(newValue);
    setAffectationFilters((prevFilters) => {
      const updatedFilters = [...prevFilters];
      updatedFilters[index] = {
        ...updatedFilters[index],
        speLabel: newValue?.value,
        speCondensedLabel: newValue?.label,
      };
      return updatedFilters;
    });
  };

  const handleClose = () => {
    removeLineAtIndex(index);
  };

  return (
    <FilterLine>
      <FilterContainer>
        <StyledDropdownSingle
          placeholder="DES"
          options={defaultSpecialtyOptions}
          onChange={handleSpecialtiesChange}
          value={
            specialties ?? {
              label: controlledValue.speCondensedLabel,
              value: controlledValue.speCondensedLabel,
            }
          }
        />
        <StyledDropdownSingle
          placeholder="Subdivision"
          options={defaultChuOptions}
          value={
            chus ?? {
              label: controlledValue.chuCondensedLabel, // map complete data find full name
              value: controlledValue.chuCondensedLabel,
            }
          }
          onChange={handleChusChange}
        />
      </FilterContainer>
      <CrossContainer onClick={handleClose}>
        <ClosingCross />
      </CrossContainer>
    </FilterLine>
  );
};

const FilterLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
`;

const StyledDropdownSingle = styled(SingleDropdown)`
  width: 50%;
  max-width: 400px;
`;

const FilterContainer = styled.div`
  width: calc(100%-40px);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
`;

const CrossContainer = styled.div`
  min-height: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.inactive};
`;
