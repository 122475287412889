export interface AuthConfirmationRoutingState {
  title: string;
  subtitle: string;
}

export const isAuthConfirmationRoutingState = (
  state: unknown
): state is AuthConfirmationRoutingState => {
  return (
    typeof (state as AuthConfirmationRoutingState)?.title === 'string' &&
    typeof (state as AuthConfirmationRoutingState)?.subtitle === 'string'
  );
};
