import styled from 'styled-components';
import { QuoteType } from 'types/correction.type';
import parse from 'html-react-parser';
import { MathJax } from 'better-react-mathjax';
import { StyledMathJax } from 'ui/MathJax';

export const Quote = (quote: QuoteType) => {
  return (
    <QuoteContainer>
      <StyledQuoteMathJax inline>"{parse(quote.quote)}"</StyledQuoteMathJax>
      <Left>
        <StyledMathJax inline>{parse(quote.paragraph)}</StyledMathJax>
      </Left>
    </QuoteContainer>
  );
};

const QuoteContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Left = styled.div`
  display: flex;
  justify-content: end;
`;

export const StyledQuoteMathJax = styled(MathJax)`
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-family: 'PT', serif !important;
  font-style: italic !important;
`;
