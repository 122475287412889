import React, { FC, HTMLAttributes, useEffect, useState } from 'react';
import styled from 'styled-components';

interface Props extends Omit<HTMLAttributes<HTMLLabelElement>, 'onChange'> {
  checked?: boolean;
  onChange?(isChecked: boolean): void;
  disabled?: boolean;
  children?: React.ReactNode;
}

export const Checkbox: FC<Props> = ({
  checked,
  children,
  onChange,
  disabled,
  ...rest
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(checked || false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // setIsChecked(e.currentTarget.checked);
    if (onChange && !disabled) {
      onChange(e.currentTarget.checked);
    }
  };

  useEffect(() => {
    setIsChecked(!!checked);
  }, [checked]);

  return (
    <Label onClick={(e) => e.stopPropagation()} {...rest}>
      <Input
        checked={isChecked}
        type="checkbox"
        aria-disabled={disabled}
        onChange={handleChange}
      />
      {children}
    </Label>
  );
};

const Label = styled.label`
  display: flex;
  span {
    margin-left: 10px;
  }
`;

const Input = styled.input`
  width: 20px;
  margin: 0;
`;
