import { FC } from 'react';
import styled from 'styled-components';
import { KnowledgeCompass } from 'http/compass/types';
import { SectionTitle } from 'pages/newHome/ui/Global';
import { KnowledgeBlock } from './KnowledgeBlock';

interface RelatedKnowledgesProps {
  knowledges: KnowledgeCompass[] | undefined;
}

export const RelatedKnowledges: FC<RelatedKnowledgesProps> = ({
  knowledges,
}) => {
  return (
    <KnowledgesInfoContainer id="astrolabe-knowledges-container">
      <StyledSectionTitle>Connaissances</StyledSectionTitle>
      {knowledges?.map((knowledge) => (
        <KnowledgeBlock knowledgedata={knowledge} key={knowledge.knowledge} />
      ))}
    </KnowledgesInfoContainer>
  );
};

const KnowledgesInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const StyledSectionTitle = styled(SectionTitle)`
  margin: 0 0 20px 0;
`;
