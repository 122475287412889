import styled, { css } from 'styled-components/macro';
import { device } from 'helpers/css/responsive';
import { useAccountNavigationContext } from './context/accountNavigationContext';

export const AccountMenu = () => {
  const { activeTab, setActiveTab, menuContent } =
    useAccountNavigationContext();

  const handleClick = (tabTitle: string) => {
    setActiveTab(tabTitle);
  };

  return (
    <Container>
      {menuContent.map((el) => {
        const IconComponent = el.icon;
        return (
          <MenuButton
            key={el.title}
            isActive={activeTab === el.title ?? false}
            onClick={() => handleClick(el.title)}
          >
            <IconContainter>{<IconComponent />}</IconContainter>
            {el.title}
          </MenuButton>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const MenuButton = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 12px 48px;
  border-radius: 16px;
  width: 100%;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: grey;
    color: ${({ theme }) => theme.colors.primary.contrastText};
  }
  ${({ isActive }) => css`
    ${isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.primary.dark};
      color: ${({ theme }) => theme.colors.primary.contrastText};
    `}
  `}
`;

const IconContainter = styled.div`
  height: 16px;
  width: 16px;
  margin-right: 40px;

  @media ${device.desktop} {
    height: 20px;
    width: 20px;
    margin-right: 48px;
  }
`;
