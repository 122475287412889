import debounce from 'lodash.debounce';
import {
  createContext,
  FC,
  useContext,
  useState,
  ReactNode,
  useMemo,
  useEffect,
} from 'react';
import { Options } from 'types/options.type';
interface HomeSpecialtiesFiltersContextProps {
  specialtyPageSize: number;
  setCurrentSpecialtyPage: (setSpecialtyPageSize: number) => void;
  currentSpecialtyPage: number;
  setSpecialtyPageSize: (specialtyPageNumber: number) => void;
  specialtiesSearchedItems: Options;
  setSpecialtiesSearchedItems: (specialtiesSearchedSpe: Options) => void;
  specialtyStudyTours: Options;
  setSpecialtyStudyTours: (specialtyStudyTours: Options) => void;
  handleResetSpecialtiesFilters: () => void;
  specialtySearchTerm: string;
  setSpecialtySearchTerm: (specialtySearchTerm: string) => void;
  debouncedSearchTerm: string;
}

const contextDefaultValue: HomeSpecialtiesFiltersContextProps = {
  currentSpecialtyPage: 0,
  setSpecialtyPageSize: () => {},
  specialtyPageSize: 10,
  setCurrentSpecialtyPage: () => {},
  specialtiesSearchedItems: [],
  setSpecialtiesSearchedItems: () => {},
  specialtyStudyTours: [],
  setSpecialtyStudyTours: () => {},
  handleResetSpecialtiesFilters: () => {},
  specialtySearchTerm: '',
  setSpecialtySearchTerm: () => {},
  debouncedSearchTerm: '',
};

const HomeSpecialtiesFiltersContext = createContext(contextDefaultValue);

interface HomeSpecialiesFiltersProviderProps {
  children: ReactNode;
}

export const HomeSpecialiesFiltersProvider: FC<HomeSpecialiesFiltersProviderProps> =
  ({ children }) => {
    const [specialtyPageSize, setSpecialtyPageSize] = useState<number>(50);
    const [currentSpecialtyPage, setCurrentSpecialtyPage] = useState<number>(0);

    const [specialtiesSearchedItems, setSpecialtiesSearchedItems] = useState<
      Options | []
    >([]);
    const [specialtyStudyTours, setSpecialtyStudyTours] = useState<Options>([]);

    const handleResetSpecialtiesFilters = () => {
      // setSpecialtyPageSize();
      // setCurrentSpecialtyPage();
      setSpecialtiesSearchedItems([]);
      setSpecialtyStudyTours([]);
      setSpecialtySearchTerm('');
      setDebouncedSearchTerm('');
    };

    const [specialtySearchTerm, setSpecialtySearchTerm] = useState<string>('');

    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>('');

    const handleNewSearchterm = useMemo(
      () => debounce(setDebouncedSearchTerm, 500),
      [setDebouncedSearchTerm]
    );

    useEffect(() => {
      handleNewSearchterm(specialtySearchTerm);
    }, [handleNewSearchterm, specialtySearchTerm]);

    return (
      <HomeSpecialtiesFiltersContext.Provider
        value={{
          specialtyPageSize,
          setSpecialtyPageSize,
          currentSpecialtyPage,
          setCurrentSpecialtyPage,
          specialtiesSearchedItems,
          setSpecialtiesSearchedItems,
          specialtyStudyTours,
          setSpecialtyStudyTours,
          handleResetSpecialtiesFilters,
          specialtySearchTerm,
          setSpecialtySearchTerm,
          debouncedSearchTerm,
        }}
      >
        {children}
      </HomeSpecialtiesFiltersContext.Provider>
    );
  };

export const useHomeSpecialtiesFiltersContext = () =>
  useContext(HomeSpecialtiesFiltersContext);

export const STATEMENT_ID = -1;
