import { useEffect, useState } from 'react';

import { getUniversities } from 'http/universities-list/api';
import { Option } from 'ui/Dropdown';

export const useGetUniversities = () => {
  const [universities, setUniversities] = useState<Option[]>([]);

  useEffect(() => {
    async function getUniversitiesFromAPI() {
      const univ = await getUniversities();
      if (univ) {
        setUniversities(
          univ.map((el) => ({
            label: el.name,
            value: el.code,
          }))
        );
      }
    }

    getUniversitiesFromAPI();
  }, []);

  return universities;
};
