import { FC } from 'react';
import { useQueries } from 'react-query';

import { useAddLocalCartToGlobalCart } from './useAddLocalCartToGlobalCart';
import { useAddToLocalCartContext } from '../../contexts/addToLocalCartContext';
import { USE_GET_GROUP_DETAIL_KEY } from 'http/progress/detail/group/connector';
import { getGroupDetail } from 'http/progress/detail/group/api';

import { AddLocalSelectionToCartGenericButton } from 'pages/progress/GeneralProgress/components/AddLocalSelectionToCartGenericButton';
import { MS_IN_AN_HOUR } from 'constants/date';

interface Props {}

export const AddLocalSelectionToCartButton: FC<Props> = () => {
  const { localCart } = useAddToLocalCartContext();
  const addLocalCartToGlobalCart = useAddLocalCartToGlobalCart();

  const results = useQueries(
    localCart.map((dpId) => ({
      queryKey: [USE_GET_GROUP_DETAIL_KEY, dpId],
      queryFn: () => getGroupDetail({ group_id: dpId }),
      cacheTime: MS_IN_AN_HOUR,
      staleTime: MS_IN_AN_HOUR,
    }))
  );

  const handleClick = () => {
    if (results.every((query) => query.data !== undefined)) {
      addLocalCartToGlobalCart(results.map((query) => query.data!));
    }
  };

  return (
    <AddLocalSelectionToCartGenericButton
      handleClick={handleClick}
      results={results}
    />
  );
};
