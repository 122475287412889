import { ShouldShowItems } from '../domain/entities/UserOptions';
import { useGetUserOptions } from './useGetUserOptions';

export const useShouldShowItems = (hasItemBeenDone: boolean | undefined) => {
  const { data: userOptions } = useGetUserOptions();
  const shouldShowItems: ShouldShowItems =
    userOptions?.shouldShowItems ?? 'never';

  const shouldShowSpecialty =
    shouldShowItems === 'always' ||
    (shouldShowItems === 'whenDone' && hasItemBeenDone);

  return shouldShowSpecialty;
};
