import { DPProgressBySession } from '../domain/entities/DPProgressBySession';
import { mapGeneralProgressDPHttpToDomain } from './adapter';
import { getProgressGeneralDPHttp } from './api';

export const getProgressGeneralDPRepository: () => Promise<
  DPProgressBySession[]
> = async () => {
  const httpProgressGeneralDP = await getProgressGeneralDPHttp();
  httpProgressGeneralDP.reverse();
  return mapGeneralProgressDPHttpToDomain(httpProgressGeneralDP);
};
