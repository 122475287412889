import { FC } from 'react';
import styled from 'styled-components/macro';
import { useKnowledgeInfoFromId } from 'http/selection/get_knowledge/connector';
import Tippy from '@tippyjs/react';
import RankLetter from 'ui/Misc/RankLetter';

interface Props {
  knowledgeId: string;
}

export const KnowledgeRankFromId: FC<Props> = ({ knowledgeId }) => {
  const { rank, label } = useKnowledgeInfoFromId(knowledgeId);
  if (knowledgeId === undefined) {
    return null;
  }

  return (
    <Container>
      <Tippy content={label} arrow={false} placement="right" disabled={!label}>
        {rank === 'AB' ? (
          <RankContainer>
            <RankLetter letter={'A'}>A</RankLetter>
            <RankLetter letter={'B'}>B</RankLetter>
          </RankContainer>
        ) : (
          <RankLetter letter={rank}>{rank}</RankLetter>
        )}
      </Tippy>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 0.25rem;
`;

const RankContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
`;
