import { FC, useMemo } from 'react';
import styled from 'styled-components/macro';
import { KnowledgeRepartitionBar } from 'components/StatsElements/KnowledgeRepartitionBar';
import { Knowledge } from 'types/knowledge.type';

interface Props {
  knowledges: Knowledge[];
}

export const KnowledgeRankPercentageBar: FC<Props> = ({ knowledges }) => {
  const rankRepartition = useMemo(() => {
    const knowledgeRepartion = knowledges.reduce(
      (acc, knowledge) => {
        if (knowledge.rank === 'A') {
          acc.A += 1;
        }
        if (knowledge.rank === 'B') {
          acc.B += 1;
        }
        if (knowledge.rank === 'C') {
          acc.C += 1;
        }

        return acc;
      },
      { A: 0, B: 0, C: 0 }
    );

    return knowledgeRepartion;
  }, [knowledges]);

  return (
    <Container>
      <Title>Répartion rang :</Title>
      <KnowledgeRepartitionBar
        repartition={rankRepartition}
        total={knowledges.length}
      />
    </Container>
  );
};

const Container = styled.div<{ $noBorder?: boolean }>`
  width: 100%;
  padding: 16px 16px;
  padding: ${({ theme, $noBorder }) => ($noBorder ? '0px' : '16px 16px')};
  border: ${({ theme, $noBorder }) =>
    $noBorder ? 'none' : '1px solid' + theme.colors.primary.light};
  border-radius: 8px;
`;

const Title = styled.div`
  ${({ theme }) => theme.typography.h5}
  margin-bottom: 16px;
`;
