import styled, { css } from 'styled-components/macro';
import { Range as LibRange } from 'react-range';
import { FC, useMemo } from 'react';

const formatIndex = (index: number) => {
  if (index > 0) {
    return '+' + index;
  } else {
    return index;
  }
};

interface Props {
  userAnswer: number | null;
  answerPoints: { [answer: number]: number };
}

export const Range: FC<Props> = ({ userAnswer, answerPoints }) => {
  const maxPoints = useMemo(() => {
    const possiblePoints = Object.keys(answerPoints)
      .map(Number)
      .map((answer) => {
        return answerPoints[answer];
      });

    return Math.max(...possiblePoints);
  }, [answerPoints]);

  return (
    <Container>
      <LibRange
        step={1}
        min={-2}
        max={2}
        values={userAnswer || userAnswer === 0 ? [userAnswer] : []}
        onChange={() => {}}
        renderMark={({ props, index }) => {
          return (
            <div
              key={props.key}
              ref={props.ref}
              style={{
                ...props.style,
                height: '10px',
                width: '2px',
                backgroundColor: '#CDE0F6',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <PointsLabel isMax={userAnswer === index - 2}>
                {answerPoints[index - 2]}pt
              </PointsLabel>
              <GraduationLabel isMax={answerPoints[index - 2] === maxPoints}>
                {formatIndex(index - 2)}
              </GraduationLabel>
            </div>
          );
        }}
        renderTrack={({ props, children }) => (
          <div
            style={{
              ...props.style,
              height: '2px',
              display: 'flex',
              width: '100%',
              backgroundColor: '#CDE0F6',
              cursor: 'default',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '20px',
                width: '100%',
                borderRadius: '4px',
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '22px',
              width: '42px',
              borderRadius: '4px',
              backgroundColor: 'transparent',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'default',
            }}
          >
            <Triangle />
          </div>
        )}
      />
    </Container>
  );
};

const Container = styled.div`
  height: 80px;
  display: grid;
  place-items: center;
  padding: 8px 24px;
`;

const GraduationLabel = styled.div<{ isMax: boolean }>`
  font-size: 20px;
  line-height: 24px;
  position: relative;
  bottom: 75px;
  ${({ theme }) => theme.typography.body}
  color: ${({ theme }) => theme.colors.inactive};
  font-weight: 400;

  ${({ isMax }) =>
    isMax &&
    css`
      color: ${({ theme }) => theme.colors.primary.dark};
      font-weight: bold;
    `}
`;

const PointsLabel = styled.div<{ isMax: boolean }>`
  margin-top: 24px;
  ${({ theme }) => theme.typography.label}
  color: ${({ theme }) => theme.colors.inactive};
  font-weight: 400;

  ${({ isMax }) =>
    isMax &&
    css`
      color: ${({ theme }) => theme.colors.primary.dark};
      font-weight: bold;
    `}
`;

const Triangle = styled.div`
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 8px solid ${({ theme }) => theme.colors.primary.dark};
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-radius: 1px;
  transform: translateX(1px) !important;
`;
