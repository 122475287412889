import { FC } from 'react';

import { Checkbox } from 'ui/Checkbox';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';

type CheckboxProps = Parameters<typeof Checkbox>[0];

interface Props extends CheckboxProps {
  isFree: boolean;
}

export const GenericCartCheckbox: FC<Props> = ({ isFree, ...props }) => {
  const isUserFree = useIsUserFree();
  const isRessourceLocked = isUserFree && !isFree;

  if (isRessourceLocked) {
    return <div />;
  }

  return <Checkbox {...props} />;
};
