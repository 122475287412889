import { ColumnHeaderWrapper } from 'components/Filters/ColumnHeaderWrapper';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Table, Thead, Tr, Th, Tbody } from 'ui/TableElements';
import { FQTableRow } from './FQTableRows/FQTableRow';
import { CheckboxHeader } from './TableHeaders/CheckboxHeader';

export const ReadonlyFQTable = () => {
  const rows = useSelector(
    (state: RootState) => state.fqTable.selectedItemsInfos
  );

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>
            <CheckboxHeader pageItemInfos={rows} />
          </Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>Session</ColumnHeaderWrapper>
          </Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>N°</ColumnHeaderWrapper>
          </Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>Spé.</ColumnHeaderWrapper>
          </Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>Items</ColumnHeaderWrapper>
          </Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>Cat.</ColumnHeaderWrapper>
          </Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>
              Dernier entraînement
            </ColumnHeaderWrapper>
          </Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>Type</ColumnHeaderWrapper>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {rows.map((rowData, index) => (
          <FQTableRow rowData={rowData} index={index} key={rowData.id} />
        ))}
      </Tbody>
    </Table>
  );
};
