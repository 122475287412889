import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { updateShowDone } from 'store/slices/selectionFiltersSlice';
import styled from 'styled-components/macro';

import { Radio } from 'ui/Radio';

interface Props {}

export const ShowDoneFilter: FC<Props> = () => {
  const showDone = useSelector(
    (state: RootState) => state.selectionFilters.showDone
  );

  const dispatch = useDispatch();

  const setShowDone = (val?: boolean) => {
    dispatch(updateShowDone(val));
  };

  return (
    <OptionsContainer>
      <h5>Afficher :</h5>
      <Option>
        <Radio
          checked={showDone === true}
          onChange={() => setShowDone(true)}
          id="done"
        />
        <label htmlFor="done">Fait</label>
      </Option>
      <Option>
        <Radio
          checked={showDone === false}
          onChange={() => setShowDone(false)}
          id="notdone"
        />
        <label htmlFor="notdone">Non fait</label>
      </Option>
      <Option>
        <Radio
          checked={showDone === undefined}
          onChange={() => setShowDone(undefined)}
          id="all"
        />
        <label htmlFor="all">Tout</label>
      </Option>
    </OptionsContainer>
  );
};

const OptionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  padding: 0 30px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
