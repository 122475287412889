import { DailyCardsInfo } from 'http/flash-questions/decks/types';
import { FC } from 'react';
import styled, { useTheme } from 'styled-components';

interface Props {
  data: DailyCardsInfo;
}
export const DailyCards: FC<Props> = ({ data }) => {
  const {
    colors: { primary, flashQuestions },
  } = useTheme();

  return (
    <Container>
      <InfoBlock bg={flashQuestions.blueLightTranslucent} color={primary.dark}>
        {data.newCards}
      </InfoBlock>
      <InfoBlock bg={flashQuestions.redLightTranslucent} color={primary.dark}>
        {data.toRedoCards}
      </InfoBlock>
      <InfoBlock bg={flashQuestions.greenLightTranslucent} color={primary.dark}>
        {data.toReviewCards}
      </InfoBlock>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

export const InfoBlock = styled.div<{ bg: string; color: string }>`
  background-color: ${({ bg }) => bg};
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  padding: 0.2em 0.5em;
  text-align: center;
  justify-content: center;
  font-weight: bold;
  border-radius: 0.3em;
`;
