import styled, { css } from 'styled-components/macro';

import { device } from 'helpers/css/responsive';

import { menuOpenTransitionConfig } from '../../cssTransitions';

const height = 16;
const width = 8;

export const MenuItemBookmarkTip = styled.div<{
  isActive: boolean;
  isMenuExpanded: boolean;
}>`
  display: none;

  @media ${device.desktop} {
    display: block;
  }

  cursor: default;

  ${({ isActive, isMenuExpanded }) =>
    css`
      position: absolute;
      top: 48px;
      left: 44px;
      background-color: var(--bookmarkTipColor);

      height: ${height}px;
      width: ${isMenuExpanded || !isActive ? 0 : width}px;

      opacity: ${isMenuExpanded || !isActive ? 0 : 1};

      transition-property: width, opacity;
      ${menuOpenTransitionConfig};
    `}

  &:after {
    content: '';
    background-color: var(--menuBackgroundColor);
    height: ${height}px;
    width: ${width}px;
    position: absolute;
    border-top-right-radius: 64px;
    cursor: default;
  }
`;
