import { FC, useState } from 'react';
import styled from 'styled-components';
import { Modal } from 'ui/Modal';
import { ButtonBase } from 'ui/Buttons/ButtonBase';
import { Radio } from 'ui/Radio';
import { useSelector } from 'react-redux';

interface Props {
  isOpen: boolean;
  handleClick: (selectedPromotion: string | undefined) => void;
}

export const PromotionModal: FC<Props> = ({ isOpen, handleClick }) => {
  const { promotions } = useSelector((state: any) => state.ccb);

  const [selectedPromotion, setSelectedPromotion] = useState<
    string | undefined
  >(undefined);

  return (
    <Modal isOpen={isOpen}>
      <ScrollableContainer>
        <h2>Veuillez confirmer votre promotion</h2>
        <p>
          Ainsi, vous pourrez consulter des résultats précis et adaptés à votre
          promotion après le concours.
        </p>
        <PromotionChoice>
          {promotions?.map((promotion: { code: string; name: string }) => (
            <Container key={promotion.code}>
              <Radio
                checked={promotion?.code === selectedPromotion}
                onChange={() => setSelectedPromotion(promotion?.code)}
                id={promotion?.code}
                value={promotion?.code}
              />
              <label htmlFor={promotion.code}>{promotion?.name}</label>
            </Container>
          ))}
        </PromotionChoice>
        <Center>
          <ButtonBase
            styled={'primary'}
            size={'large'}
            disabled={!selectedPromotion}
            iconOnly={false}
            onClick={() => handleClick(selectedPromotion)}
            hasPadding={true}
          >
            Confirmer
          </ButtonBase>
        </Center>
      </ScrollableContainer>
    </Modal>
  );
};

const ScrollableContainer = styled.div`
  overflow: auto;
  padding-right: 4px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;

const PromotionChoice = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
