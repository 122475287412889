import { FC } from 'react';
import { ArrowSquareOut } from 'ui/icons/ArrowSquareOut';
import styled from 'styled-components/macro';

interface BookProps {
  title: string;
  isBlue: boolean;
  handleClick: () => void;
}

export const BookCard: FC<BookProps> = ({ title, handleClick, isBlue }) => {
  return (
    <BookContainer isBlue={isBlue} onClick={handleClick}>
      {title}
      <IconContainer>
        <ArrowSquareOut />
      </IconContainer>
    </BookContainer>
  );
};

const BookContainer = styled.div<{ isBlue: boolean }>`
  background-color: ${({ theme, isBlue }) =>
    isBlue ? theme.colors.primary.lighter : theme.colors.background.paper};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  max-width: 330px;
  cursor: pointer;
  ${({ theme }) => theme.typography.label};
  border: 1px solid ${({ theme }) => theme.colors.primary.light};
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.lighter};
  }
`;

const IconContainer = styled.div`
  height: 20px;
  width: 20px;
  flex-shrink: 0;
`;
