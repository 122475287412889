import { SimilarQuestion } from 'types/correction.type';

export const formatSimilarQuestionTitle = (question: SimilarQuestion) => {
  if (question.group.type === 'QI') {
    return `${question.group.collection} ${
      question.group.composition_unit
        ? 'UC' + question.group.composition_unit
        : ''
    } - ${question.group.type} n°${question.num} (${question.type})`;
  }
  return `${question.group.collection} ${
    question.group.composition_unit
      ? 'UC' + question.group.composition_unit
      : ''
  } - ${question.group.type}${question.group.num} Q${question.num} (${
    question.type
  })`;
};
