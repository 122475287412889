import { FC } from 'react';
import styled from 'styled-components/macro';
import { FormattedDate } from 'pages/selection/components/FormattedDate';
import { formatGradeOutOfTwenty } from 'pages/correction/components/CorrectionGroupHeader/points-formatter';

interface Props {
  dateToFormat: string;
  grade?: number | null;
  totalCoeff: number;
  groupType: 'DP' | 'LCA';
}

export const LastTraining: FC<Props> = ({
  dateToFormat,
  grade,
  totalCoeff,
  groupType,
}) => {
  return (
    <Container>
      <FormattedDate date={new Date(dateToFormat)} /> -{' '}
      {formatGradeOutOfTwenty({
        grade: grade !== undefined ? grade : null,
        totalCoeff,
      })}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 14px;
  justify-content: center;
`;
