import { device } from 'helpers/css/responsive';
import { StylesConfig } from 'react-select';
import { DefaultTheme } from 'styled-components/macro';

/** Type to specify if we can have multiple inputs in the select.
The type 'StylesConfig' needs it to change the type of the function 'selectStyles' that allows styling the react-select. */
export type IsMulti = false;

/**Type of the options passed to the react-select */
export interface Option {
  label: string | undefined;
  value: string | undefined;
}

export const getDropdownStyle = (theme: DefaultTheme) => {
  const selectStyles: StylesConfig<Option, IsMulti> = {
    option: (provided, state) => {
      const style = {
        ...provided,
        color: theme.colors.primary.dark,
        backgroundColor: 'transparent',
        padding: '8px',
        '&:hover': {
          backgroundColor: theme.colors.primary.light,
        },
      };
      if (state.isSelected) {
        style.backgroundColor = theme.colors.primary.main;
      }
      if (state.isFocused) {
        style.backgroundColor = theme.colors.primary.light;
      }
      return style;
    },
    indicatorsContainer: (provided, _) => {
      const style = {
        ...provided,
        color: theme.colors.primary.dark,
      };
      return style;
    },
    indicatorSeparator: (_, __) => ({}),
    control: (provided, state) => {
      const style = {
        ...provided,
        height: '100%',
        border: 'none',
        borderBottom: '2px solid ' + theme.colors.primary.dark,
        borderRadius: 'none',
        boxShadow: state.isFocused ? 'none' : 'none',
        '&:hover': {},
        padding: '0px',
        backgroundColor: 'transparent',
      };
      if (window.matchMedia && window.matchMedia(device.desktop).matches) {
      }

      return style;
    },
    dropdownIndicator: (provided, state) => {
      const style = {
        ...provided,
        transition: 'all .2s ease',
        marginRight: '4px',
        marginLeft: '4px',
      };
      if (state.selectProps.menuIsOpen) {
        style.transform = 'rotate(180deg)';
      }
      if (window.matchMedia && window.matchMedia(device.desktop).matches) {
        style.marginRight = '5px';
        style.marginLeft = '15px';
      }

      return style;
    },
    menu: (provided, state) => {
      const style = {
        ...provided,
        border: 'none',
        backgroundColor: theme.colors.background.paper,
      };
      return style;
    },
    container: (provided, _) => {
      const style = {
        ...provided,
        height: '100%',
      };
      return style;
    },
    placeholder: (provided, _) => {
      const style = {
        ...provided,
        fontStyle: 'italic',
        fontWeight: 300,
      };
      return style;
    },
    singleValue: (provided, _) => ({
      ...provided,
      color: theme.colors.primary.dark,
    }),
    valueContainer: (provided, _) => ({
      ...provided,
      padding: '1px 2px',
    }),
  };

  return selectStyles;
};
