import { getAPI } from 'auth';
import { GroupHistoryHttpParams, GroupHistoryHttpResponse } from './types';

export const getGroupHistoryHttp = async (params: GroupHistoryHttpParams) => {
  const response = await getAPI<GroupHistoryHttpResponse>(
    '/training/group_history/',
    {
      params,
    }
  );
  return response.data;
};
