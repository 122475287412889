import { DetailQIResponse } from 'http/progress/detail/qi/types';
import { SelectionQIResult } from 'http/selection-qi/types';
import { useCanAddToCart } from 'pages/selection/hooks/useCanAddToCart';
import { useDispatch } from 'react-redux';
import { toggleItemSelection as toggleQIItem } from 'store/slices/qiTableSlice';

export const useToggleQIInCart = () => {
  const dispatch = useDispatch();
  const canAddToCart = useCanAddToCart();

  return function addSimilarQuestionToCart(question: DetailQIResponse) {
    if (!canAddToCart('QI', 1)) {
      return;
    }

    const adaptedQuestion = adaptDetailQIToQIQuestion(question);
    dispatch(toggleQIItem(adaptedQuestion));
  };
};

export const adaptDetailQIToQIQuestion = (
  qi: DetailQIResponse
): SelectionQIResult => ({
  id: qi.id,
  categories: qi.categories,
  knowledge: qi.knowledge,
  item: qi.item,
  num: qi.num.toString(),
  composition_unit: qi.composition_unit,
  specialty1: qi.specialty1,
  specialty2: qi.specialty2,
  type: 'QI',
  coefficient: 1,
  collection: qi.collection,
  grade: undefined,
  last_training: undefined,
  is_free: false,
});
