import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';

import { useSortOptions } from 'pages/selection/hooks/useSortOptions';
import { usePaginate } from 'pages/selection/hooks/usePaginate';
import { getSelectionGroup } from 'http/selection-group/api';
import { SelectionGroupParams } from 'http/selection-group/types';
import { useTableConfigContext } from 'pages/selection/tableConfigContext';
import { useSearchbarContext } from 'pages/selection/components/Searchbar/SearchbarContext';
import { MS_IN_AN_HOUR } from 'constants/date';

export function useGetQuestionGroupTableValues() {
  const { tableStateSelector, setPageSizeCreator, tableType } =
    useTableConfigContext();

  const { currentPage, handlePageClick, setCurrentPage } = usePaginate();

  const dispatch = useDispatch();
  const updatePageSize = (newSize: number) =>
    dispatch(setPageSizeCreator(newSize));
  const handleNewPageSize = (newSize: number) => {
    updatePageSize(newSize);
    setCurrentPage(0);
  };

  const filters = useSelector((state: RootState) => state.selectionFilters);
  const pageSize = useSelector(
    (state: RootState) => tableStateSelector(state).pageSize
  );
  const { isAscending, sortColumn } = useSortOptions((state) =>
    tableStateSelector(state)
  );

  const { debouncedSearchTerm } = useSearchbarContext();

  const [params, setParams] = useState<SelectionGroupParams>({
    group_type: tableType === 'DP' ? ['DP', 'KFP'] : ['LCA'],
    sort_column: sortColumn,
    sort_ascending: isAscending,
    search: debouncedSearchTerm,
    ...filters,
    is_free: filters.showFree === true ? true : undefined,
    page_size: pageSize,
    page: 1,
  });

  const getParams = () => ({
    ...params,
    collection: filters.collections,
    sort_column: sortColumn,
    sort_ascending: isAscending,
    search: debouncedSearchTerm,
    specialty: filters.specialities,
    tag: filters.categories,
    rank: filters.rank,
    item: filters.items,
    type: filters.type,
    done: filters.showDone,
    is_free: filters.showFree === true ? true : undefined,
    page_size: pageSize,
    page: currentPage + 1,
  });

  useEffect(() => {
    setCurrentPage(0);
    setParams({
      ...getParams(),
      // We reset the page to the first one when the filters are updated
      page: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, isAscending, sortColumn, pageSize, debouncedSearchTerm]);

  useEffect(() => {
    setParams(getParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const { data, isLoading, isError, refetch } = useQuery(
    ['selectionGroup', params],
    () => getSelectionGroup(params),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }
  );

  return {
    data,
    isLoading,
    isError,
    refetch,
    currentPage,
    handlePageClick,
    pageSize,
    handleNewPageSize,
  };
}
