import { FC, useState } from 'react';
import styled from 'styled-components';
import { Range } from 'react-range';

interface Props {
  onChange(number: number): void;
  value: number;
  disabled?: boolean;
  saved?: boolean;
}

const min = -2;
const max = 2;
const step = 1;

const formatIndex = (index: number) => {
  if (index > 0) {
    return '+' + index;
  } else {
    return index;
  }
};

export const Slider: FC<Props> = (sliderProps) => {
  const [hasChanged, setHasChanged] = useState(false);

  return (
    <Container>
      <Range
        step={step}
        min={min}
        max={max}
        values={[sliderProps.value]}
        disabled={sliderProps.disabled}
        onFinalChange={(values) => {
          if (values[0] === 0 && !hasChanged) {
            setHasChanged(true);
            sliderProps.onChange(values[0]);
          }
        }}
        renderMark={({ props, index }) => {
          return (
            <div
              key={props.key}
              ref={props.ref}
              style={{
                ...props.style,
                height: '10px',
                width: '2px',
                backgroundColor: '#CDE0F6',
              }}
            >
              <MarkLabel index={index}>{formatIndex(index - 2)}</MarkLabel>
            </div>
          );
        }}
        onChange={(values) => {
          if (!hasChanged) {
            setHasChanged(true);
          }
          sliderProps.onChange(values[0]);
        }}
        renderTrack={({ props, children, isDragged }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '2px',
              display: 'flex',
              width: '100%',
              backgroundColor: '#CDE0F6',
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '20px',
                width: '100%',
                borderRadius: '4px',
                alignSelf: 'center',
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '50px',
              width: '42px',
              borderRadius: '4px',
              backgroundColor: 'transparent',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              opacity: hasChanged || sliderProps.saved ? 1 : 0,
              cursor: !hasChanged ? 'pointer' : 'inherit',
            }}
          >
            <Triangle />
          </div>
        )}
      />
    </Container>
  );
};

const Container = styled.div`
  padding-bottom: 20px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
`;

const Triangle = styled.div`
  display: inline-block;
  height: 0;
  width: 0;
  border-top: 10px solid #01162d;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  transform: translateX(1px) !important;
`;

const MarkLabel = styled.div<{ index?: number }>`
  margin-top: 10px;
  margin-left: ${({ index }) =>
    index === 4 ? '-10px' : index === 3 ? '-5px' : '-2px'};
  font-family: 'FreeSans';
  font-weight: 700;
  font-size: 14px;
`;
