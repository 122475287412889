import { DispoInputParameters } from 'http/rank-info/easy-dispo/types';
import { useGetSimulatedRanks } from 'http/rank-info/rang-limier/connector';
import { SessionInfo } from 'http/rank-info/session/connector';
import { useGetMultipleSessionRecap } from 'modules/recap/hooks/useGetMultipleSessionRecap';
import { ProgressBarStat } from 'components/StatsElements/ProgressBarStats';
import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { Button } from 'ui/Button';
import { ArrowFullToRight } from '../components/icons/ArrowFullToRight';
import Tippy from '@tippyjs/react';

interface Props {
  initialRank?: number;
  initialYear?: number;
  handleClick(easyDispoParams: DispoInputParameters): void;
  isLoading: boolean;
  rankInfoSession?: SessionInfo[];
  ranks?: number[];
  setRanks(ranks: number[]): void;
}

const LOADING_PLACEHOLDER = 'Loading...';

const calculateRank = (
  currentRank: number,
  currentNbCandidates: number,
  targetNbCandidates: number
) => {
  return Math.round(
    1 +
      ((currentRank - 1) / (currentNbCandidates - 1)) * (targetNbCandidates - 1)
  );
};

const isSessionCompleted = (session: SessionInfo) => {
  return session.numerator === session.denom;
};

export const RankCalculator: FC<Props> = (props) => {
  const theme = useTheme();

  // Used to get the grade of the user to be able to calculate the simulated rank
  const sessionMultipleRecaps = useGetMultipleSessionRecap(
    // props.rankInfoSession
    //   ? props.rankInfoSession.map((infos) => ({
    //       collection: infos.collection.split(' ').join('_'),
    //     }))

    props.rankInfoSession
      ? props.rankInfoSession.map((infos) => ({
          collection: infos.collection.split(' ').join('_'),
        }))
      : []
  );

  // Retrieve the simulated rank
  const simulatedRanks = useGetSimulatedRanks(
    sessionMultipleRecaps.every((el) => el.data) && props.rankInfoSession
      ? sessionMultipleRecaps.map((session, index) => ({
          grade: session.data!.grade,
          year: parseInt(props.rankInfoSession![index].year.toString()),
        }))
      : []
  );

  const onChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    rankIndex: number
  ) => {
    let newRank = parseInt(e.target.value);

    if (newRank < 1) {
      newRank = 1;
    }

    if (
      !props.rankInfoSession ||
      newRank > props.rankInfoSession[rankIndex].nbCandidates
    ) {
      // If the rank is incorrect, we don't take into accoutn the user input change
      return;
    }

    const newRanks = props.ranks!.map((_, index) => {
      return index === rankIndex
        ? newRank
        : calculateRank(
            newRank,
            props.rankInfoSession![rankIndex].nbCandidates,
            props.rankInfoSession![index].nbCandidates
          );
    });

    props.setRanks(newRanks);
  };

  const fillRankInput = (rank: number, rankIndex: number) => {
    const newRanks = props.ranks!.map((_, index) => {
      return index === rankIndex
        ? rank
        : calculateRank(
            rank,
            props.rankInfoSession![rankIndex].nbCandidates,
            props.rankInfoSession![index].nbCandidates
          );
    });

    props.setRanks(newRanks);
  };

  return (
    <Container>
      <Table>
        <Thead>
          <HeadRow>
            <Title>Complétez les sessions pour obtenir votre rang simulé</Title>
            <Title>Simuler mon rang</Title>
          </HeadRow>
          <HeadRow></HeadRow>
        </Thead>
        <Tbody>
          {props.rankInfoSession && simulatedRanks.length > 0 ? (
            props.rankInfoSession.map((session, index) => (
              <SessionRow key={session.collection}>
                <RankInfoContainer>
                  <SessionNameFirst>{session.collection}</SessionNameFirst>
                  {isSessionCompleted(session) ? (
                    <RankContainer
                      onClick={() =>
                        simulatedRanks[index].data
                          ? fillRankInput(
                              simulatedRanks[index].data!.rank,
                              index
                            )
                          : null
                      }
                    >
                      <RankInfo>
                        {simulatedRanks[index].data
                          ? simulatedRanks[index].data?.rank +
                            ' / ' +
                            session.nbCandidates
                          : LOADING_PLACEHOLDER}
                      </RankInfo>
                      <Tippy
                        content={'Reporter le rang'}
                        arrow={false}
                        placement="right-start"
                      >
                        <IconContainer>
                          <ArrowFullToRight isFilled={true} />
                        </IconContainer>
                      </Tippy>
                    </RankContainer>
                  ) : (
                    <Tippy
                      content={
                        'complétez la session pour obtenir votre rang simulé'
                      }
                      arrow={false}
                      placement="right-start"
                    >
                      <ProgressBarContainer>
                        <StyledProgressBar
                          fillingColor={theme.colors.secondary.main}
                          percentage={(session.numerator / session.denom) * 100}
                        />
                        <ProgressionText>
                          {((session.numerator / session.denom) * 100).toFixed(
                            0
                          ) + ' %'}
                        </ProgressionText>
                      </ProgressBarContainer>
                    </Tippy>
                  )}
                </RankInfoContainer>
                <RankInputContainer>
                  <CellContainer>
                    <SessionName>{session.collection}</SessionName>
                    <InputContainer>
                      <RankInput
                        type={'number'}
                        maxLength={5}
                        min={2}
                        value={props.ranks![index]?.toFixed(0) || ''}
                        onChange={(e) => onChange(e, index)}
                      />
                      <NumberOfCandidates>
                        {' / ' + session.nbCandidates}
                      </NumberOfCandidates>
                    </InputContainer>
                  </CellContainer>
                </RankInputContainer>
              </SessionRow>
            ))
          ) : (
            <tr>
              <PageLoadingPlaceholder>
                {LOADING_PLACEHOLDER}
              </PageLoadingPlaceholder>
            </tr>
          )}
        </Tbody>
      </Table>
      <ButtonContainer>
        <Button
          onClick={() =>
            props.ranks!.length > 0 &&
            props.rankInfoSession &&
            props.rankInfoSession.length > 0
              ? props.handleClick({
                  rank: Math.round(props.ranks![0]),
                  year: props.rankInfoSession[0].year,
                })
              : null
          }
          isLoading={props.isLoading}
        >
          Valider le rang
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 1rem;
  background-color: ${({ theme }) => theme.colors.primary.light};
  border-radius: 16px;
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0 12px;
  tr {
    td:first-child {
      padding-right: 12px;
    }
    td:last-child {
      padding-left: 12px;
    }
  }
`;

const Thead = styled.thead``;

const HeadRow = styled.tr``;

const Title = styled.td`
  ${({ theme }) => theme.typography.h5}
`;

const Tbody = styled.tbody``;

const SessionRow = styled.tr``;

const SessionNameFirst = styled.span`
  white-space: nowrap;
  margin-right: 16px;
  min-width: 100px;
`;

const SessionName = styled.span`
  white-space: nowrap;
  margin-right: 40px;
  min-width: 100px;
`;

const RankInfoContainer = styled.td`
  display: flex;
`;

const RankInfo = styled.span`
  padding: 4px 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.primary.dark};
  white-space: nowrap;
  width: fit-content;
  justify-self: center;
  cursor: pointer;
`;

const RankContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const ProgressBarContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 12px;
`;

const StyledProgressBar = styled(ProgressBarStat)`
  width: 100%;
  min-width: 10em;
  display: inline-block;
`;

const ProgressionText = styled.span`
  width: 3em;
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const RankInputContainer = styled.td`
  white-space: nowrap;
`;

const CellContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const RankInput = styled.input`
  ${({ theme }) => theme.typography.body}
  padding: 2px 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.primary.dark};
  color: ${({ theme }) => theme.colors.primary.dark};
  background: none;
  width: 100px;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

const NumberOfCandidates = styled.span`
  margin-left: 12px;
`;

const PageLoadingPlaceholder = styled.td``;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  margin: 0px 48px 8px 0;
`;
