import { useQuery } from 'react-query';

import { getDPProgressBySession } from '../domain/useCases/getLCAProgress';
import { MS_IN_AN_HOUR } from 'constants/date';

export const USE_GET_LCA_PROGRESS_KEY = 'useGetLCAProgress';

export const useGetLCAProgress = () => {
  return useQuery([USE_GET_LCA_PROGRESS_KEY], () => getDPProgressBySession(), {
    cacheTime: MS_IN_AN_HOUR,
    staleTime: MS_IN_AN_HOUR,
  });
};
