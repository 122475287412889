import styled from 'styled-components';
import { FC } from 'react';

interface Props {
  isSmall?: boolean;
}

export const Knowmore: FC<Props> = ({ isSmall }) => {
  return (
    <Container isSmall={isSmall ?? false}>
      <h1>En savoir plus</h1>
      <Chapter>
        <SubTitles>Dates</SubTitles>
        <div>
          <Paragraph>
            Le concours blanc peut-être fait gratuitement et à tout moment entre
            <BoldText>
              {' '}
              le lundi 12 août 09:00 et le vendredi 23 août 23:59
            </BoldText>
            , pour permettre au plus grand nombre d’étudiants de le passer et de
            se classer. Au-delà, seuls les utilisateurs premium pourront le
            réaliser.
          </Paragraph>
        </div>
      </Chapter>
      <Chapter>
        <SubTitles>Durée et déroulement des sessions </SubTitles>
        <div>
          <Paragraph>
            Comme pour le jour J, les sessions doivent être complétées en une
            seule fois sur une période de 3 heures sans interruption et dans
            l'ordre spécifié (UC1, UC2, UC3 puis la LCA).
          </Paragraph>
        </div>
      </Chapter>
      <Chapter>
        <SubTitles>Nous proposerons deux types de classement</SubTitles>
        <Paragraph>
          <ul>
            <li>
              Le premier qui inclura uniquement les participants de la période
              du 12 au <BoldText>25 août 2024 </BoldText>
            </li>
            <li>
              Un classement général qui inclura tous les participants y compris
              ceux qui le composeront au-delà du{' '}
              <BoldText>23 août 2024 </BoldText>
            </li>
          </ul>
          La note de rang A et les 13 classements par groupe de spécialités et
          de nombreuses statistiques seront disponibles.
        </Paragraph>
      </Chapter>
    </Container>
  );
};

const Container = styled.div<{ isSmall: boolean }>`
  display: flex;
  gap: ${({ isSmall }) => (isSmall ? '1rem' : '2rem')};
  flex-direction: column;
`;

const Chapter = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`;

const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  display: inline;
  ul {
    margin-top: -2px;
  }
`;

const SubTitles = styled.h4`
  font-weight: 700;
  display: inline;
`;

const BoldText = styled.span`
  font-weight: 600;
`;
