import { MS_IN_AN_HOUR } from 'constants/date';
import { getFQCardsFromAPI } from 'http/flash-questions/fqdeckcreation/api';
import { useQuery } from 'react-query';
import { FQCardsParams } from 'http/flash-questions/fqdeckcreation/types';

export const useGetFQCardsFromAPI = (params: FQCardsParams) => {
  return useQuery(['fqData', params], () => getFQCardsFromAPI(params), {
    staleTime: MS_IN_AN_HOUR,
    cacheTime: MS_IN_AN_HOUR,
  });
};
