import React from 'react';
import { useGetItemByIds } from 'http/selection/get_item/connector';
import { Item } from './Item';

interface Props {
  itemId: string;
}

const ItemFromId: React.FC<Props> = ({ itemId }) => {
  const res = useGetItemByIds([itemId]);

  if (res === undefined || res.length === 0) {
    return null;
  }

  return <Item item={res[0]} />;
};

export default ItemFromId;
