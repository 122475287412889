import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';

import { postCreateTask } from 'http/training/create-task/api';
import { routes } from 'routing/constants';
import { RootState } from 'store';
import { useResetSelection } from 'hooks/useResetSelection';
import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';

export const useStartLcaTask = () => {
  const navigate = useNavigate();
  const { resetLCA } = useResetSelection();

  const lcaSelectedItems = useSelector(
    (state: RootState) => state.lcaTable.selectedItemsInfos
  );

  const mutation = useMutation(() => postCreateTask(lcaSelectedItems, []), {
    onSuccess: (task) => {
      resetLCA();
      navigate({ pathname: routes.TRAINING, search: `?id=${task.id}` });
    },
    onError: (e) => {
      toast.error(
        "Nous n'avons pu créer votre entraînement, veuillez réessayer plus tard"
      );
      reportErrorToSentry(e);
    },
  });

  const startLCATask = async () => {
    const hasNoSelection = lcaSelectedItems.length === 0;
    if (hasNoSelection) {
      toast.error('Vous devez sélectionner des items');
      return;
    }

    mutation.mutate();
  };

  return { startLCATask, isLoading: mutation.isLoading };
};
