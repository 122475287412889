import { DPBasicInformations } from '../entities/DPBasicInformations';
import { DPProgressBySession } from '../entities/DPProgressBySession';
import {
  CompositionUnits,
  DPProgressByCompositionUnits,
} from '../entities/DPProgressByCompositionUnits';

export const getDPProgressByCompositionUnits = (
  progressBySession: DPProgressBySession[] | undefined,
  isUserPremium: boolean
): DPProgressByCompositionUnits[] | undefined => {
  if (progressBySession === undefined) {
    return undefined;
  }

  const payload = progressBySession.map((session) => {
    const compositionUnits: CompositionUnits = session.groups.reduce(
      (acc, curr) => {
        const composition_unit = curr.compositionUnit ?? 0;
        const item: DPBasicInformations = {
          ...curr,
          hasBeenDone: Boolean(curr.lastCorrectionId),
        };

        if (acc[composition_unit]) {
          acc[composition_unit].push(item);
        } else {
          acc[composition_unit] = [item];
        }
        return acc;
      },
      {} as { [key: number]: DPBasicInformations[] }
    );

    return {
      collection: session.collection,
      compositionUnits,
    };
  });

  return payload;
};
