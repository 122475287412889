import { FC } from 'react';
import { getQuestionPreviewComponent } from './getPreviewComponent';
import { PreviewBlockProps } from '../types';

export const QuestionPreviewBlock: FC<PreviewBlockProps> = ({
  questionData,
}) => {
  const PreviewComponent = getQuestionPreviewComponent(questionData.type);

  return <PreviewComponent questionData={questionData} />;
};
