import { Loader } from 'components/Loader/Loader';
import { device } from 'helpers/css/responsive';
import { customLabelLine } from 'pages/concours-blanc/components/StatisticsPage/Stats/GlobalGrades/StatPieChart';
import React from 'react';
import { ResponsiveContainer, Pie, Cell, Tooltip, PieChart } from 'recharts';
import styled, { useTheme } from 'styled-components';
import {
  StyledTooltip,
  TooltipLine,
  TooltipText,
} from 'ui/Graph/CustomTooltip';

export type SpeStatPieData = {
  name: string;
  value: number;
  color: string;
  order?: number;
};

export interface PieChartProps {
  data: SpeStatPieData[];
  nbQuestions: string;
}

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <StyledTooltip>
        <TooltipLine>
          <TooltipText>{`${payload[0].payload.name} : ${payload[0].payload.value} questions`}</TooltipText>
        </TooltipLine>
      </StyledTooltip>
    );
  }
  return null;
};

export const customQFLabel = (props: any) => {
  if (props.payload.payload.value === 0) {
    return null;
  }

  const leftCoef = props.direction === 'left' ? -1 : 1;

  const RADIAN = Math.PI / 180;
  const radius =
    12 + props.innerRadius + (props.outerRadius - props.innerRadius);
  let x = props.cx + radius * Math.cos(-props.midAngle * RADIAN) * leftCoef;
  const y = props.cy + radius * Math.sin(-props.midAngle * RADIAN);

  x = x < props.cx ? x - 25 : y < props.cy ? x + 35 : x + 25;

  const textA = x > props.cx ? 'start' : 'end';

  return (
    <>
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={textA}
        dominantBaseline="central"
      >
        <SubTitleText>
          {props.value} ({Math.round(props.percent * 100)}%)
        </SubTitleText>
      </text>
    </>
  );
};

export const QFPieChart: React.FC<PieChartProps> = ({ data, nbQuestions }) => {
  const theme = useTheme();
  if (!data) {
    return <Loader />;
  }

  return (
    <PieSection>
      <ResponsiveContainer width="100%">
        <PieChart id="tag-pie-chart">
          <text
            x={'50%'}
            y={'47.5%'}
            textAnchor="middle"
            dominantBaseline="middle"
          >
            <TitleText fill={theme.colors.primary.dark}>
              {nbQuestions}
            </TitleText>
          </text>
          <text
            x={'50%'}
            y={'52.5%'}
            textAnchor="middle"
            dominantBaseline="middle"
          >
            <TitleText fill={theme.colors.primary.dark}>questions</TitleText>
          </text>
          <Pie
            data={data}
            dataKey="value"
            cx="50%"
            cy="50%"
            outerRadius={'52%'}
            innerRadius={'34%'}
            label={customQFLabel}
            labelLine={customLabelLine}
            stroke="none"
            startAngle={180}
            endAngle={-180}
          >
            {data.map((entry, index) => (
              <Cell key={index} fill={entry.color} />
            ))}
          </Pie>

          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </PieSection>
  );
};
const PieSection = styled.div`
  width: 100%;
  aspect-ratio: 1;
  margin: -5rem auto;
`;

const TitleText = styled.tspan`
  font-family: 'Urbanist';
  font-weight: 600;
  font-size: 0.75;
  @media ${device.desktop} {
    font-size: 1.4rem;
  }
`;

const SubTitleText = styled.tspan`
  font-family: 'Urbanist';
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.primary.dark};
`;
