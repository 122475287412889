import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import PaymentMethodCard from './PaymentMethodCard/PaymentMethodCard';
import { PaymentMethodType, PaymentProvider } from './PaymentMethod.type';
import paypal_icon from 'assets/images/paypal_icon.png';
import stripe_icon from 'assets/images/stripe_icon.png';

const availablePaymentMethods: PaymentMethodType[] = [
  {
    label: 'Paiement sécurisé en 1 fois',
    value: 'stripe',
    icon: stripe_icon,
    description:
      'Après avoir cliqué sur "Continuer" vous serez redirigé vers la page de paiement Stripe afin de finaliser votre achat en toute sécurité.',
  },
  {
    label: 'Paiement sécurisé en 4 fois sans frais',
    value: 'paypal',
    icon: paypal_icon,
    description:
      'Après avoir cliqué sur "4X Paypal" vous serez redirigé vers la page de paiement Paypal afin de finaliser votre achat en toute sécurité.',
    payInFourTimes: true,
  },
];

interface PaymentMethodProps {
  onChange: (value: PaymentProvider) => void;
  selectedPaymentMethod: PaymentProvider;
  setSelectedPaymentMethod: (value: PaymentProvider) => void;
  amount: number;
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({
  onChange,
  setSelectedPaymentMethod,
  selectedPaymentMethod,
  amount,
}) => {
  const onChangePaymentMethod = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedPaymentMethod(e.target?.value as PaymentProvider);
    onChange(e.target?.value as PaymentProvider);
  };

  useEffect(() => {
    setSelectedPaymentMethod(selectedPaymentMethod);
    onChange(selectedPaymentMethod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentMethod]);

  return (
    <Container>
      <Title>Mon mode de paiement</Title>

      {availablePaymentMethods.map((method) => (
        <PaymentMethodCard
          {...method}
          amount={amount}
          key={method.value}
          isSelected={selectedPaymentMethod === method.value}
          onChange={(e) => onChangePaymentMethod(e)}
          name={method.value}
        />
      ))}
    </Container>
  );
};

const Title = styled.h2`
  ${({ theme }) => theme.typography.h2};
  margin-bottom: 2rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
`;

export default PaymentMethod;
