import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { toast } from 'react-toastify';
import { PriceInfos, PromoResponse } from 'http/payments/getPrices/types';
import { UserFacturationInfos } from '../FacturationPage.type';

interface PayPalIntegrationProps {
  clientId: string;
  createCheckout: (
    id: string,
    params: UserFacturationInfos,
    coupon?: string
  ) => Promise<string>;
  onApprove: (data: any) => Promise<void>;
  isValid: boolean;
  amount: number;
  getValues: () => Record<string, any>;
  priceInfos: PriceInfos;
  coupon?: PromoResponse;
}

const PayPalIntegration: React.FC<PayPalIntegrationProps> = ({
  clientId,
  createCheckout,
  onApprove,
  isValid,
  amount,
  getValues,
  priceInfos,
  coupon,
}) => {
  const paypalConfig = {
    clientId,
    currency: 'EUR',
    components: 'buttons,funding-eligibility',
    'enable-funding': 'paylater',
    'disable-funding': 'credit,card',
    'data-sdk-integration-source': 'button-factory',
  } as const;

  useEffect(() => {
    const checkAdBlocker = async (): Promise<void> => {
      try {
        await fetch('https://www.paypal.com/sdk/js');
      } catch (error) {
        toast.warning(
          'Un bloqueur de publicités semble actif. Désactivez-le pour utiliser PayPal.',
          { autoClose: 8000 }
        );
      }
    };
    checkAdBlocker();
  }, []);

  const handleCreateOrder = async (): Promise<string> => {
    try {
      return await createCheckout(
        priceInfos.id.toString(),
        getValues() as UserFacturationInfos,
        coupon?.coupon
      );
    } catch (error) {
      toast.error('Erreur lors de la création de la commande');
      throw error;
    }
  };

  const handleError = (err: Record<string, unknown>): void => {
    toast.error(
      "Une erreur s'est produite avec PayPal. Veuillez réessayer ou choisir un autre moyen de paiement."
    );
  };

  return (
    <Container>
      <PayPalScriptProvider options={paypalConfig}>
        <PayPalButtons
          style={{
            layout: 'vertical',
            color: 'black',
            shape: 'pill',
            label: 'paypal',
          }}
          createOrder={handleCreateOrder}
          onApprove={onApprove}
          onError={handleError}
          onCancel={() => toast.info('Paiement annulé')}
          disabled={!isValid}
          forceReRender={[amount]}
        />
      </PayPalScriptProvider>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export default PayPalIntegration;
