import styled from 'styled-components/macro';
import { useHomeInfoContext } from '../context/homeInfoContext';
import { FavoritesTab } from '../ui/FavoritesTab';
import { AllElementsPaginatedTab } from '../ui/AllElementsPaginatedTab';
import { ItemsView } from '../Itemsview/ItemsView';
import { SpecialitiesView } from '../SpecialtiesView/SpecialtiesView';
import { SingleSpecialityView } from '../SpecialtiesView/SingleSpecialtyView/SingleSpecialtyView';
import { SingleItemView } from '../Itemsview/SingleItemView/SingleItemView';

export const PageContent = () => {
  const { activeTab, shownPage, activeItem, activeSpecialtyId } =
    useHomeInfoContext();

  return (
    <PageContentContainer>
      {shownPage === 'main' ? (
        <>
          <FavoritesTab />
          <AllElementsPaginatedTab>
            {activeTab === 'specialties' ? <SpecialitiesView /> : <ItemsView />}
          </AllElementsPaginatedTab>
        </>
      ) : activeTab === 'specialties' ? (
        <SingleSpecialityView trigram={activeSpecialtyId} />
      ) : (
        <SingleItemView itemId={activeItem?.item.item} />
      )}
    </PageContentContainer>
  );
};

const PageContentContainer = styled.div`
  width: 100%;
`;
