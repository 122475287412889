import styled from 'styled-components';

interface Props {
  isSelected: boolean;
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

export const Star = (props: Props) => {
  return (
    <MemoStar
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill={props.isSelected ? 'black' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
    >
      <path
        d="M7.5331 1.71273C7.73314 1.38767 8.20894 1.39756 8.39528 1.73066L9.98487 4.57201C10.1837 4.92743 10.5185 5.18672 10.9124 5.29031L14.2634 6.1717C14.6281 6.26764 14.7594 6.7175 14.5035 6.99454L11.9145 9.79698C11.5946 10.1433 11.4561 10.6198 11.5405 11.0837L12.0531 13.8979C12.1305 14.3224 11.6699 14.6389 11.3013 14.4146L8.77991 12.8799C8.30086 12.5883 7.69915 12.5883 7.22009 12.8799L4.58579 14.4833C4.22656 14.702 3.77428 14.4065 3.83027 13.9897L4.22977 11.0157C4.28694 10.5901 4.15893 10.1603 3.87816 9.83537L1.41186 6.98079C1.17183 6.70298 1.30307 6.26985 1.65693 6.17199L4.86275 5.28541C5.22814 5.18436 5.54172 4.9487 5.74041 4.62583L7.5331 1.71273Z"
        stroke="currentColor"
      />
      <path
        d="M7.5331 1.71273C7.73314 1.38767 8.20894 1.39756 8.39528 1.73066L9.98487 4.57201C10.1837 4.92743 10.5185 5.18672 10.9124 5.29031L14.2634 6.1717C14.6281 6.26764 14.7594 6.7175 14.5035 6.99454L11.9145 9.79698C11.5946 10.1433 11.4561 10.6198 11.5405 11.0837L12.0531 13.8979C12.1305 14.3224 11.6699 14.6389 11.3013 14.4146L8.77991 12.8799C8.30086 12.5883 7.69915 12.5883 7.22009 12.8799L4.58579 14.4833C4.22656 14.702 3.77428 14.4065 3.83027 13.9897L4.22977 11.0157C4.28694 10.5901 4.15893 10.1603 3.87816 9.83537L1.41186 6.98079C1.17183 6.70298 1.30307 6.26985 1.65693 6.17199L4.86275 5.28541C5.22814 5.18436 5.54172 4.9487 5.74041 4.62583L7.5331 1.71273Z"
        stroke="currentColor"
        strokeOpacity="0.2"
      />
      <path
        d="M7.5331 1.71273C7.73314 1.38767 8.20894 1.39756 8.39528 1.73066L9.98487 4.57201C10.1837 4.92743 10.5185 5.18672 10.9124 5.29031L14.2634 6.1717C14.6281 6.26764 14.7594 6.7175 14.5035 6.99454L11.9145 9.79698C11.5946 10.1433 11.4561 10.6198 11.5405 11.0837L12.0531 13.8979C12.1305 14.3224 11.6699 14.6389 11.3013 14.4146L8.77991 12.8799C8.30086 12.5883 7.69915 12.5883 7.22009 12.8799L4.58579 14.4833C4.22656 14.702 3.77428 14.4065 3.83027 13.9897L4.22977 11.0157C4.28694 10.5901 4.15893 10.1603 3.87816 9.83537L1.41186 6.98079C1.17183 6.70298 1.30307 6.26985 1.65693 6.17199L4.86275 5.28541C5.22814 5.18436 5.54172 4.9487 5.74041 4.62583L7.5331 1.71273Z"
        stroke="currentColor"
        strokeOpacity="0.2"
      />
    </MemoStar>
  );
};

const MemoStar = styled('svg')`
  cursor: pointer;
`;
