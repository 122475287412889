import { verifyCoupon } from 'http/payments/getPrices/api';
import { PromoResponse } from 'http/payments/getPrices/types';
import React from 'react';
import styled from 'styled-components/macro';
import { Button } from 'ui/Button';
import { TextInput } from 'ui/TextInput';
import SmallText from 'ui/Texts/SmallText';

interface Props {
  productId: number;
  addCoupon: (coupon: PromoResponse) => void;
}

const PromoSection: React.FC<Props> = ({ productId, addCoupon }) => {
  const [promoCode, setPromoCode] = React.useState('');
  const [isPromoShown, setIsPromoShown] = React.useState(false);
  const [isPromoValid, setIsPromoValid] = React.useState(false);
  const [isPromoApplied, setIsPromoApplied] = React.useState(false);

  const verifyPromo = async () => {
    if (!promoCode || !productId) return;
    const response = await verifyCoupon(promoCode, productId);
    setIsPromoApplied(true);
    if (response.valid) {
      setIsPromoValid(true);
      addCoupon(response);
      setPromoCode('');
    } else {
      setIsPromoValid(false);
    }
  };

  return (
    <Container>
      {!isPromoShown ? (
        <SmallText
          onClick={() => setIsPromoShown(true)}
          text="Avez-vous un code promo ?"
        />
      ) : (
        <PromoContainer>
          <TextInput
            label="Saisissez votre code promo"
            light
            value={promoCode || ''}
            bottomErrorMessage={
              isPromoApplied && !isPromoValid
                ? 'Ce code promotionnel n’est pas valide'
                : ''
            }
            bottomSuccessMessage={
              isPromoApplied && isPromoValid
                ? 'Le code promotionnel a été appliqué avec succès'
                : ''
            }
            onChange={(e) => setPromoCode(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                verifyPromo();
              }
            }}
            endButton={
              <Button
                variant="outlined"
                small
                style={{ height: '2.25rem', fontWeight: 600 }}
                onClick={verifyPromo}
              >
                Appliquer
              </Button>
            }
          />
        </PromoContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  width: 100%;
`;

const PromoContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  width: 100%;
`;

export default PromoSection;
