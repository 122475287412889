import { device } from 'helpers/css/responsive';
import { forwardRef, InputHTMLAttributes, LabelHTMLAttributes } from 'react';
import { FieldError } from 'react-hook-form';
import styled from 'styled-components/macro';

import { TextBody2 } from 'ui/TextBody2';

type NativeInputProps = InputHTMLAttributes<HTMLInputElement>;
type NativeLabelProps = LabelHTMLAttributes<HTMLLabelElement>;
export interface TextInputProps extends NativeInputProps {
  label?: string;
  labelProps?: NativeLabelProps;
  errorMessage?: string;
  bottomErrorMessage?: string;
  bottomSuccessMessage?: string;
  paddingInnerRight?: number;
  light?: boolean;
  endButton?: JSX.Element;
  isMandatory?: boolean;
  $hasError?: boolean;
  error?: FieldError;
}

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      label,
      labelProps,
      errorMessage,
      bottomErrorMessage,
      bottomSuccessMessage,
      light,
      endButton,
      /** Add res asterix to field label */
      isMandatory,
      /**
       * Add padding when you add icons inside the input
       */
      paddingInnerRight,
      $hasError,
      /**
       * These childrens are meant to be icons or anything
       * absolutely positionned
       */
      children,
      className,
      ...nativeInputProps
    },
    ref
  ) => {
    return (
      <Container className={className}>
        <LabelLine>
          {label && (
            <label
              htmlFor={nativeInputProps?.id || `input_${label}`}
              {...labelProps}
              style={{
                fontWeight: light ? 400 : 'inherit',
              }}
            >
              <LabelSubLine>
                {label}
                {isMandatory && (
                  <div
                    style={{
                      color: 'red',
                    }}
                  >
                    *
                  </div>
                )}
              </LabelSubLine>
            </label>
          )}
          <ErrorMessageDesktop>{errorMessage}</ErrorMessageDesktop>
        </LabelLine>
        <InputContainer>
          <Input
            light={light}
            type="text"
            paddingRight={paddingInnerRight}
            ref={ref}
            hasError={$hasError || !!errorMessage || !!bottomErrorMessage}
            id={nativeInputProps?.id || `input_${label}`}
            {...nativeInputProps}
          />
          {children}
          {endButton}
        </InputContainer>
        {bottomErrorMessage && (
          <ErrorMessageDesktop>{bottomErrorMessage}</ErrorMessageDesktop>
        )}
        {bottomSuccessMessage && (
          <SuccessMessageDesktop>{bottomSuccessMessage}</SuccessMessageDesktop>
        )}
        {errorMessage && (
          <ErrorMessageMobile>{errorMessage}</ErrorMessageMobile>
        )}
      </Container>
    );
  }
);

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const LabelLine = styled.div`
  padding: 0 16px;

  @media ${device.desktop} {
    padding: 0 24px;
  }

  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const ErrorMessageDesktop = styled(TextBody2)`
  display: none;

  @media ${device.desktop} {
    color: ${({ theme }) => theme.colors.error.main};
    display: inline;
  }
`;

const SuccessMessageDesktop = styled(TextBody2)`
  display: none;

  @media ${device.desktop} {
    color: ${({ theme }) => theme.colors.success.main};
    display: inline;
  }
`;

const ErrorMessageMobile = styled(TextBody2)`
  display: block;
  margin-left: auto;
  margin-right: 16px;
  padding-left: 16px;
  color: ${({ theme }) => theme.colors.error.main};

  @media ${device.desktop} {
    display: none;
  }
`;

const InputContainer = styled.div`
  // Allows us to place items such as icon in the input
  position: relative;
  display: flex;
  gap: 1rem;
`;

const Input = styled.input<{
  paddingRight?: number;
  hasError?: boolean;
  light?: boolean;
  disabled?: boolean;
}>`
  // CSS Resets for input
  border: none;
  background-image: none;
  background-color: transparent;
  box-shadow: none;

  color: ${({ theme }) => theme.colors.primary.dark};

  // Needed for browser password autocompletion not to be an ugly rectangle
  overflow: hidden;

  border: ${({ light }) => (light ? '1px' : '2px')} solid;
  border-color: ${({ theme, hasError }) =>
    hasError ? theme.colors.error.main : 'currentColor'};

  height: ${({ light }) => (light ? '2.25rem' : '3rem')};
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.cta.mobile};

  font-weight: normal;
  font-size: 16px;
  line-height: 19px;

  &[type='password'] {
    // Urbanist font has way too small dots for the password
    // inputs so we specify another one
    font-family: 'unicode';
  }

  padding: 8px 16px;
  ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}px;`}

  @media ${device.desktop} {
    font-size: 20px;
    line-height: 24px;
    border-radius: ${({ theme, light }) =>
      light ? '8px' : theme.borderRadius.cta.desktop};

    padding: 12px 24px;
    ${({ paddingRight }) => paddingRight && `padding-right: ${paddingRight}px;`}
  }

  &:focus-visible,
  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${({ theme }) => theme.colors.inactive};
    border-color: ${({ theme }) => theme.colors.inactive};
    cursor: not-allowed;
  }
`;

const LabelSubLine = styled.div`
  display: flex;
  flex-direction: row;
`;
