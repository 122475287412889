import styled from 'styled-components';
import { FC } from 'react';
import BlueCard from 'ui/Cards/BlueCard';
import { MenuAccount } from 'ui/icons/MenuIcons/MenuAccount';
import { device } from 'helpers/css/responsive';

interface Props {
  grade: string;
  text: string;
}

export const ParticipantsCard: FC<Props> = ({ grade, text }) => {
  return (
    <BlueCard>
      <Container>
        <IconContainer>
          <MenuAccount />
        </IconContainer>
        <h5>{grade}</h5>
        <Styledsmall>{text}</Styledsmall>
      </Container>
    </BlueCard>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 180px;
  @media ${device.mobile} {
    max-width: 129px;
  }
`;

const IconContainer = styled.div`
  height: 32px;
  width: 32px;
  flex-shrink: 0;
`;

const Styledsmall = styled.small`
  color: ${({ theme }) => theme.colors.inactive};
`;
