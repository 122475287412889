import { patchAPI } from 'auth';
import { SaveQuestionQROCRequestType } from './types';

export const saveQuestionQROC = async (params: SaveQuestionQROCRequestType) => {
  const response = await patchAPI<void, SaveQuestionQROCRequestType>(
    '/training/save_question_qroc',
    params
  );

  return response.data;
};
