import styled from 'styled-components';
import { PageTitle } from 'ui/Titles/PageTitle';
import { device } from 'helpers/css/responsive';
import DoneCCB from 'assets/images/DoneCCB.png';
import MiddleTraining from 'assets/images/MiddleTraining.png';
import { CCBDoneTable } from '../TrainingTable/CCBDoneTable';
import { ButtonBase } from 'ui/Buttons/ButtonBase';
import { useSelector, useDispatch } from 'react-redux';
import { updateLastTask, updateScreenToDisplay } from 'store/slices/ccbSlice';
import { useSubscribedNewsletter } from 'modules/user-options/hooks/useSubscribedNewsletter';
import BlueCard from 'ui/Cards/BlueCard';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';
import { SubscribeButton } from 'ui/Buttons/SubscribeButton';
import WhiteCard from 'ui/Cards/WhiteCard';
import { NewsletterButton } from 'components/Newsletter/NewsletterButton';

export const CompletedTraining = () => {
  const dispatch = useDispatch();
  const newsletter = useSubscribedNewsletter();
  const { isUserPremium } = useIsUserPremium();

  const { alldone, collection } = useSelector((state: any) => state.ccb);

  const handleClick = () => {
    dispatch(updateLastTask(undefined));
    dispatch(updateScreenToDisplay('CCBLauchScreen'));
  };

  const handleSurveyClick = () => {
    window.open(
      'https://forms.gle/BwH5rApXrjTt4vUw7',
      '_blank',
      'noopener,noreferrer'
    );
  };

  return (
    <Container>
      <TopContainer>
        <ImageContainer>
          <StyledImage
            src={alldone ? DoneCCB : MiddleTraining}
            alt={'Concours blanc'}
          />
        </ImageContainer>
        <Right>
          <div>
            <CommentContainer>{collection.label}</CommentContainer>
            <PageTitle>
              {alldone
                ? 'Félicitations, toutes vos épreuves ont bien été soumises !'
                : 'Félicitation !'}
            </PageTitle>
          </div>
          {alldone ? (
            <p>
              Les épreuves sont actuellement en cours de correction (relecture
              des QROC et identification des questions ambiguës ou des erreurs
              de coches). Le classement sera publié d’ici la fin du mois, dès
              que cette étape sera terminée. <br />
            </p>
          ) : (
            <p>
              Votre épreuve a bien été soumise. Vous pouvez revenir à la liste
              des épreuves.
            </p>
          )}
          {!alldone && (
            <ButtonBase
              size={'large'}
              disabled={alldone}
              styled={'primary'}
              iconOnly={false}
              onClick={handleClick}
              hasPadding={true}
            >
              Revenir aux épreuves
            </ButtonBase>
          )}
        </Right>
      </TopContainer>
      <UnderContainer>
        <BlockContainer>
          {alldone && (
            <StyledWhiteCard color={'black'}>
              <ContentContainer>
                <TextContainer>
                  <MediumTitle>
                    Votre avis est précieux ! Aidez-nous à améliorer ce concours
                    blanc en partageant vos remarques anonymes (1 minute).
                  </MediumTitle>
                </TextContainer>
                <ButtonBase
                  onClick={handleSurveyClick}
                  size="large"
                  disabled={false}
                  styled={'primary'}
                  iconOnly={false}
                  hasPadding={true}
                >
                  Répondre au questionnaire
                </ButtonBase>
              </ContentContainer>
            </StyledWhiteCard>
          )}
          {!isUserPremium && alldone && (
            <BlueCard>
              <ContentContainer>
                <TextContainer>
                  <BigTitle>Débloquez l'accès complet à Asclepia</BigTitle>
                  Abonnez vous pour débloquer plus d'annales, des rappels de
                  cours complets, et des fonctionnalités telles que l'Astrolabe
                  ou les Questions flash. Optimisez votre préparation dès
                  maintenant !
                </TextContainer>
                <SubscribeButton />
              </ContentContainer>
            </BlueCard>
          )}
          {alldone && !newsletter && (
            <StyledWhiteCard color={'black'}>
              <ContentContainer>
                <TextContainer>
                  <MediumTitle>
                    Ne manquez aucune nouveauté : Inscrivez-vous à notre
                    newsletter !
                  </MediumTitle>
                  <LegalText>
                    Les informations recueillies font l'objet d'un traitement
                    informatique destiné à vous adresser la newsletters. Pour en
                    savoir plus sur la collecte, nous vous invitons à lire notre
                    politique de{' '}
                    <a href={'https://asclepia.io/confidentiality'}>
                      confidentialité de données
                    </a>
                  </LegalText>
                </TextContainer>
                <NewsletterButton
                  text={"S'inscrire à la newsletter"}
                  color={'primary'}
                />
              </ContentContainer>
            </StyledWhiteCard>
          )}
        </BlockContainer>
        <h3>
          {alldone
            ? 'Liste des épreuves de la session'
            : "Détail de l'épreuve déjà réalisée"}
        </h3>
        <CCBDoneTable all={alldone} />
      </UnderContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;
  margin-top: -1rem;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  height: 100%;
  gap: 40px;
  @media ${device.mobile} {
    flex-direction: column;
    min-width: 100%;
  }
`;

const UnderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;

  min-width: 100%;
  height: 100%;
  gap: 40px;
  @media ${device.mobile} {
    flex-direction: column;
    min-width: 100%;
  }
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 60%;
  gap: 1.5rem;

  @media ${device.mobile} {
    min-width: 100%;
  }
`;

const CommentContainer = styled.div`
  color: ${({ theme }) => theme.colors.secondary.main};
  margin-bottom: 1rem;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  height: 100%;

  @media ${device.mobile} {
    display: none;
    width: 0%;
    height: 0%;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  height: auto;
  display: block;
`;

const BlockContainer = styled.div`
  display: 'flex';
  flex-direction: 'column';
  gap: 14px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  @media ${device.desktop} {
    flex-direction: row;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BigTitle = styled.h4`
  font-weight: 700;
  margin-bottom: 1rem;
`;

const MediumTitle = styled.h4`
  font-weight: 600;
`;

const StyledWhiteCard = styled(WhiteCard)`
  margin: 14px 0;
`;

const LegalText = styled.div`
  margin-top: 1rem;
  color: ${({ theme }) => theme.colors.inactive};
  ${({ theme }) => theme.typography.small}
`;
