import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type StartQuestionsCart = 'dpqi' | 'lca';

export interface StartCartState {
  selectedCart: StartQuestionsCart;
}

const initialState: StartCartState = {
  selectedCart: 'dpqi',
};

export const startCartSlice = createSlice({
  name: 'startCart',
  initialState,
  reducers: {
    updateSelectedCart(state, action: PayloadAction<StartQuestionsCart>) {
      state.selectedCart = action.payload;
    },
    reset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { updateSelectedCart, reset } = startCartSlice.actions;

export const startCartReducer = startCartSlice.reducer;
