import { FC } from 'react';
import styled from 'styled-components/macro';
import parse from 'html-react-parser';

import { ModalHeader, Modal } from 'ui/Modal';
import { Loader } from 'components/Loader/Loader';

import { QuestionPreviewBlock } from 'pages/correction/active-correction-group/questions-correction-blocks/components/RelatedQuestions/QuestionPreview/QuestionPreviewBlock';
import { useGetQIDetail } from 'http/progress/detail/qi/connector';
import { AddQIToSelectionButton } from './AddQIToSelectionButton';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';
import { GoToSubscribeButton } from '../../components/GoToSubscribeButton';
import { CorrectionThemeProvider } from 'components/CorrectionThemeProvider';
import { StyledMathJax } from 'ui/MathJax';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  qiId: number;
}

export const PreviewModal: FC<Props> = ({ isModalOpen, closeModal, qiId }) => {
  const { data: question } = useGetQIDetail(qiId);

  const { isUserPremium } = useIsUserPremium();
  const shouldShowPreview = isUserPremium || question?.is_free;

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      {question ? (
        <>
          <ModalHeader onCloseButtonClick={closeModal}>
            <h4>{`${question.collection} - QI n°${question.num} (${question.type})`}</h4>
          </ModalHeader>
          <ModalContent>
            {shouldShowPreview ? (
              <>
                <ScrollableContainer>
                  <CorrectionThemeProvider>
                    <Statement>
                      <StyledMathJax inline>
                        {parse(question?.statement ?? '')}
                      </StyledMathJax>
                    </Statement>
                    {question && (
                      <QuestionPreviewBlock questionData={question} />
                    )}
                  </CorrectionThemeProvider>
                </ScrollableContainer>

                <AddQIToSelectionButton qiId={qiId} />
              </>
            ) : (
              <>
                <p>Vous devez vous abonner pour accéder à l'aperçu de ce QI.</p>
                <GoToSubscribeButton />
              </>
            )}
          </ModalContent>
        </>
      ) : (
        <>
          <ModalHeader onCloseButtonClick={closeModal}>
            Chargement...
          </ModalHeader>
          <ModalContent>
            <Loader />
          </ModalContent>
        </>
      )}
    </Modal>
  );
};

const ModalContent = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const Statement = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

const ScrollableContainer = styled.div`
  overflow: auto;
  padding-right: 4px;
  width: 100%;
  img {
    max-width: 100%;
  }
`;
