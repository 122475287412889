import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SelectionFiltersState {
  collections: string[];
  specialities: string[];
  dueDate: string[];
  rank: string[];
  items: string[];
  categories: string[];
  type: string[];
  showDone?: boolean;
  showFree: boolean;
}

export const initialState: SelectionFiltersState = {
  collections: [],
  specialities: [],
  dueDate: [],
  rank: [],
  items: [],
  categories: [],
  type: [],
  showDone: undefined,
  showFree: false,
};

export const selectionFiltersSlice = createSlice({
  name: 'selectionFilters',
  initialState,
  reducers: {
    updateSessionsFilter: (state, action: PayloadAction<string[]>) => {
      state.collections = action.payload;
    },
    updateSpecialitiesFilter: (state, action: PayloadAction<string[]>) => {
      state.specialities = action.payload;
    },
    updateDueDateFilter: (state, action: PayloadAction<string[]>) => {
      state.specialities = action.payload;
    },
    updateRanksFilter: (state, action: PayloadAction<string[]>) => {
      state.rank = action.payload;
    },
    updateItemsFilter: (state, action: PayloadAction<string[]>) => {
      state.items = action.payload;
    },
    updateCategoriesFilter: (state, action: PayloadAction<string[]>) => {
      state.categories = action.payload;
    },
    updateTypeFilter: (state, action: PayloadAction<string[]>) => {
      state.type = action.payload;
    },
    updateShowDone: (state, action: PayloadAction<boolean | undefined>) => {
      state.showDone = action.payload;
    },
    updateShowFree: (state, action: PayloadAction<boolean>) => {
      state.showFree = action.payload;
    },
    resetFilters: (state) => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateSessionsFilter,
  updateSpecialitiesFilter,
  updateDueDateFilter,
  updateRanksFilter,
  updateItemsFilter,
  updateCategoriesFilter,
  updateTypeFilter,
  resetFilters,
  updateShowDone,
  updateShowFree,
} = selectionFiltersSlice.actions;

export const selectionFiltersReducer = selectionFiltersSlice.reducer;
