import {
  PendingTableContainer,
  FirstHeaderCell,
  HeaderCell,
  HeaderCellText,
  LastHeaderCell,
  Cell,
} from './uiElements';
import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormatedCCBSession, LCAArticles } from 'http/ccb/type';
import { useCCBLcaArticles } from 'http/ccb/connector';
import styled from 'styled-components';
import { DownloadIcon } from 'ui/icons/DownloadIcon';

interface Props {
  showAll: boolean;
}

const Rows = (
  showAll: boolean,
  sessions: FormatedCCBSession[],
  sessionsLeftToDo: FormatedCCBSession[],
  articles?: LCAArticles
): JSX.Element | null => {
  if (showAll) {
    return (
      <>
        {sessions
          .filter(
            (session: FormatedCCBSession) =>
              session.composition_unit !== sessionsLeftToDo[0].composition_unit
          )
          .map((session) => (
            <React.Fragment key={session.composition_unit}>
              <Cell>{session.composition_unit}</Cell>
              <Cell>{session.start_date}</Cell>
              <Cell>{sessionsLeftToDo[0].end_date}</Cell>
              <Cell>{session.duration}</Cell>
              <Cell>{session.timeRange}</Cell>
            </React.Fragment>
          ))}
      </>
    );
  } else if (sessionsLeftToDo.length > 0 && !sessionsLeftToDo[0].finished) {
    return (
      <>
        <Cell>{sessionsLeftToDo[0].composition_unit}</Cell>
        <Cell>{sessionsLeftToDo[0].start_date}</Cell>
        <Cell>{sessionsLeftToDo[0].end_date}</Cell>
        <Cell>{sessionsLeftToDo[0].duration}</Cell>
        <Cell>{sessionsLeftToDo[0].timeRange}</Cell>
        {sessionsLeftToDo[0].composition_unit === 'LCA' && (
          <Cell>
            <ArticlesDiv>
              <ArticleButton download target="_blank" href={articles?.article1}>
                <IconContainer>
                  <DownloadIcon />
                </IconContainer>
              </ArticleButton>
              <ArticleButton download target="_blank" href={articles?.article2}>
                <IconContainer>
                  <DownloadIcon />
                </IconContainer>
              </ArticleButton>
            </ArticlesDiv>
          </Cell>
        )}
      </>
    );
  } else {
    return null;
  }
};

export const CCBPendingTable: FC<Props> = ({ showAll }) => {
  const [showArticle, setShowArticle] = React.useState(false);
  const { sessionsLeftToDo, sessions } = useSelector((state: any) => state.ccb);
  const { data: lcaArticles } = useCCBLcaArticles(sessions[0]?.ccbId);

  useEffect(() => {
    if (!showAll && sessionsLeftToDo[0]?.composition_unit === 'LCA') {
      setShowArticle(true);
    } else {
      setShowArticle(false);
    }
  }, [sessionsLeftToDo, showAll, showArticle]);

  if (showAll && !sessions) return null;
  if (!showAll && !sessionsLeftToDo) return null;

  return (
    <PendingTableContainer rowSup={showArticle}>
      <FirstHeaderCell>
        <HeaderCellText>Épreuve</HeaderCellText>
      </FirstHeaderCell>
      <HeaderCell>
        <HeaderCellText>Date de début</HeaderCellText>
      </HeaderCell>
      <HeaderCell>
        <HeaderCellText>Date de fin</HeaderCellText>
      </HeaderCell>
      <HeaderCell>
        <HeaderCellText>Durée</HeaderCellText>
      </HeaderCell>
      {showArticle ? (
        <>
          <HeaderCell>
            <HeaderCellText>Tier temps</HeaderCellText>
          </HeaderCell>
          <LastHeaderCell>
            <HeaderCellText>Articles</HeaderCellText>
          </LastHeaderCell>
        </>
      ) : (
        <LastHeaderCell>
          <HeaderCellText>Tier temps</HeaderCellText>
        </LastHeaderCell>
      )}
      {sessions &&
        sessionsLeftToDo &&
        Rows(showAll, sessions, sessionsLeftToDo, lcaArticles)}
    </PendingTableContainer>
  );
};

const IconContainer = styled.div`
  height: 1.25rem;
  width: 1.25rem;
  color: #021427;
`;

const ArticlesDiv = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const ArticleButton = styled.a`
  background-color: ${({ theme }) => theme.colors.primary.light};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem 0.5rem;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.main};
  }
`;
