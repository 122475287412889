import {
  FormattedDispo,
  useGetEasyDispo,
} from 'http/rank-info/easy-dispo/connector';
import { useGetStaticPage } from 'http/static-pages/connector';
import { FC, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import styled, { css } from 'styled-components/macro';
import { Option } from './components/Dropdown';
import { DispoTable } from './components/DispoTable';
import { MultiValue } from 'react-select';
import parse from 'html-react-parser';
import { device } from 'helpers/css/responsive';
import { RankCalculator } from './components/RankCalculator';
import { DispoInputParameters } from 'http/rank-info/easy-dispo/types';
import { useGetRankInfoSession } from 'http/rank-info/session/connector';
import { StyledMathJax } from 'ui/MathJax';
import { GraphContainer } from './components/Graph/GraphContainer';
import { EasyDispoProvider } from './context/easydispoContext';
import { thinScrollbarStyle } from 'helpers/css/thin-scrollbar';
import { MultiDropdown } from './components/Dropdown/MultiDropdown.tsx';
import { SingleFiltersContainer } from './components/Dropdown/SingleFiltersContainer';
// import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';
// import { PremiumLockedPage } from 'components/PremiumLockedPage';

export const MAX_CANDIDATES = 10000; //Borne max des candidats (arbitraire)

const legendArray = [
  { description: "Poste n'ayant jamais été accessible", color: '#DF8D70' },
  {
    description:
      'Poste ayant été disponible au moins une fois, mais pas tous les ans',
    color: '#FFE0A7',
  },
  { description: 'Poste ayant été disponible tous les ans', color: '#CDCC75' },
  { description: "Poste n'ayant jamais été proposé", color: '#01162D' },
];

interface Props {}

export const EasyDispo: FC<Props> = (props) => {
  const { data: staticContent } = useGetStaticPage('Easy-Dispo');
  const { data: staticContentFree } = useGetStaticPage('Easy-Dispo-FREE');

  const [chus, setChus] = useState<Array<string>>();
  const [specialties, setSpecialties] = useState<Array<string>>();
  // const { isUserPremium } = useIsUserPremium();

  const { dispo, chuOptions, specialtyOptions, getEasyDispo, isLoading } =
    useGetEasyDispo();
  const [filteredDispo, setFilteredDispo] = useState<
    FormattedDispo | undefined
  >(dispo);
  const [domainCentered, setDomainCentered] = useState(true);

  const handleClick = ({ rank, year }: DispoInputParameters) => {
    if (rank && year) {
      rank = Math.round(rank);
      getEasyDispo({ rank, year });
    } else {
      toast.error('Veuillez entrer un rang valide');
    }
  };

  const handleChusChange = (newValues: MultiValue<Option>) => {
    setChus(newValues.filter((chu) => chu.value).map((chu) => chu.value!));
  };

  const handleSpecialtiesChange = (newValues: MultiValue<Option>) => {
    setSpecialties(
      newValues
        .filter((specialty) => specialty.value)
        .map((specialty) => specialty.value!)
    );
  };

  useEffect(() => {
    let newFilteredDispo = dispo;
    if (dispo && chus && chus.length > 0) {
      newFilteredDispo = newFilteredDispo!.filter((chu) =>
        chus.includes(chu.condensedLabel)
      );
    }
    if (dispo && specialties && specialties.length > 0) {
      newFilteredDispo = newFilteredDispo!.map((chu) => {
        const newChu = { ...chu };
        newChu.specialties = chu.specialties.filter((specialty) =>
          specialties.includes(specialty.condensedLabel)
        );
        return newChu;
      });
    }
    setFilteredDispo(newFilteredDispo);
  }, [chus, dispo, specialties]);

  //RANK INFO
  // Used to get informations about all the year sessions, notably the nb of candidates, the year and the done qi, lca and dp
  const { data: rankInfoSession } = useGetRankInfoSession();
  //Get the rank State Up to use it in the Table as well (in the graph)
  const [ranks, setRanks] = useState<number[]>(new Array(1).fill(undefined));

  useEffect(() => {
    // Remove 2023 stats from back (REMOVE LATER)
    // rankInfoSession?.pop();
    // Add 2023 case (REMOVE LATER)
    // rankInfoSession?.push({
    //   year: 2023,
    //   numerator: 0,
    //   denom: 9727,
    //   nbCandidates: 9727,
    //   collection: 'ECNi_2023',
    // });
    setRanks(new Array(rankInfoSession?.length).fill(undefined));
  }, [rankInfoSession]);

  //Num of candidates
  const numOfCandidates = useMemo(() => {
    return rankInfoSession?.map((yearData) => {
      return yearData.nbCandidates;
    });
  }, [rankInfoSession]);

  if (!staticContent) return null;
  if (!staticContentFree) return null;

  return (
    <EasyDispoProvider>
      <Container>
        <Title>Easy-Dispo</Title>
        {/* {isUserPremium ? ( */}
        <>
          <SideContainer>
            <div>
              <StaticContentContainer>
                ⚠ Ces informations sont données à titre indicatif et ne peuvent
                constituer une quelconque garantie pour les procédures de choix
                à venir. Toutes les données utilisées par cet outil proviennent
                du site du CNG et de son application CELINE. Pour toute
                information officielle, veuillez vous référer à ces derniers.
              </StaticContentContainer>
            </div>
            <FlexContainer>
              <StyledRankCalculator
                isLoading={isLoading}
                handleClick={handleClick}
                rankInfoSession={rankInfoSession}
                setRanks={setRanks}
                ranks={ranks}
              />

              <KnowMore>
                <StyledMathJax inline>
                  {parse(staticContent.page)}
                </StyledMathJax>
              </KnowMore>
            </FlexContainer>
          </SideContainer>

          <SideContainer marginTop="2rem">
            <Left>
              <SubTitle>Postes accessibles en fonction du rang simulé</SubTitle>
              <FiltersContainer>
                <SubFiltersContainer>
                  <FilterName>Filtres : </FilterName>
                  <StyledDropdownMulti
                    placeholder="Subdivision"
                    options={chuOptions}
                    value={chus?.map((chu) => ({ label: chu, value: chu }))}
                    onChange={handleChusChange}
                  />
                  <StyledSecondDropMulti
                    placeholder="DES"
                    options={specialtyOptions}
                    value={specialties?.map((specialty) => ({
                      label: specialty,
                      value: specialty,
                    }))}
                    onChange={handleSpecialtiesChange}
                  />
                </SubFiltersContainer>
                <ColorCodeContainer>
                  {legendArray.map((legend) => (
                    <LegendLine key={legend.description}>
                      <LegendColor color={legend.color} />
                      <LegendDescription>
                        {legend.description}
                      </LegendDescription>
                    </LegendLine>
                  ))}
                </ColorCodeContainer>
              </FiltersContainer>

              <DispoTableContainer>
                <ScrollContainer>
                  <StyledDispoTable
                    dispo={filteredDispo}
                    ranks={ranks}
                    numOfCandidates={numOfCandidates}
                  />
                </ScrollContainer>
              </DispoTableContainer>
            </Left>
            <Right>
              <SubTitle>Affectations</SubTitle>
              <FiltersContainer>
                <SubFiltersContainer>
                  <FilterLine>
                    <FilterName>Filtres : </FilterName>
                    <SwitchContainer>
                      Domaine
                      <DomainSwitch>
                        <DomainOptions
                          onClick={() => setDomainCentered((prev) => !prev)}
                          selected={domainCentered}
                        >
                          Centré
                        </DomainOptions>
                        <DomainOptions
                          onClick={() => setDomainCentered((prev) => !prev)}
                          selected={!domainCentered}
                        >
                          Entier
                        </DomainOptions>
                      </DomainSwitch>
                    </SwitchContainer>
                  </FilterLine>
                  <SingleFiltersContainer />
                </SubFiltersContainer>
              </FiltersContainer>

              <GraphContainer
                ranks={ranks}
                numOfCandidates={numOfCandidates}
                domainCentered={domainCentered}
              />
            </Right>
          </SideContainer>
        </>
        {/* ) : (
        //   <SideContainer marginTop="4rem">
        //     <StyledMathJax inline>
        //       {parse(staticContentFree.page)}
        //     </StyledMathJax>
        //     <PremiumLockedPage />
        //   </SideContainer>
        // ) } */}
      </Container>
    </EasyDispoProvider>
  );
};

const Container = styled.div`
  padding: 2rem;
  width: 100%;
  margin: 3rem 0;
`;

const Title = styled.h1``;

const SubTitle = styled.h5`
  margin-bottom: 1.5rem;
`;

const StaticContentContainer = styled.div`
  padding-top: 2rem;
  width: 100%;
  margin-bottom: 3rem;
`;

const KnowMore = styled.div`
  width: fit-content;
  display: flex;
  padding: 2rem 1.5rem;
  margin-left: 1rem;
  margin-bottom: 3rem;
  border: 1px solid ${({ theme }) => theme.colors.primary.light};
  border-radius: 8px;
`;

const SideContainer = styled.div<{ marginTop?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 40%;
  margin-bottom: 8px;
  margin-top: ${({ marginTop }) => marginTop};
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  justify-content: space-between;
  @media ${device.mobile} {
    flex-direction: row;
  } ;
`;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 8px;
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
`;

const FiltersContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: top;
  gap: 8px;
  padding-bottom: 16px;
  justify-content: start;
`;

const ColorCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  width: 100%;
  ${({ theme }) => theme.typography.small}
  justify-content: end;
`;

const LegendLine = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;

const LegendDescription = styled.div``;

const LegendColor = styled.div<{ color: string }>`
  border: 1px solid ${({ theme }) => theme.colors.background.contrastText};
  background: ${({ color }) => color};
  min-width: 16px;
  min-height: 16px;
  margin: 6px;
  border-radius: 4px;
`;

const FilterName = styled.h4`
  white-space: nowrap;
`;

const StyledRankCalculator = styled(RankCalculator)`
  min-width: fit-content;
`;

const SubFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 16px;
  position: relative;
  width: 500px;
`;

const StyledDropdownMulti = styled(MultiDropdown)`
  max-width: 400px;
  width: 100%;
  z-index: 5;
`;

const StyledSecondDropMulti = styled(MultiDropdown)`
  max-width: 400px;
  width: 100%;
  z-index: 4;
`;

const DispoTableContainer = styled.div`
  padding: 14px 0 48px 0;
  display: flex;
  width: 100%;
  max-height: 500px;
  gap: 8px;
  overflow: hidden;
  ${thinScrollbarStyle}
`;
const ScrollContainer = styled.div`
  overflow-y: auto;
  overflow-x: auto;
  padding-right: 4px;
  flex-grow: 1;
`;

const StyledDispoTable = styled(DispoTable)`
  width: 100%;
`;

const FilterLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
`;

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const DomainSwitch = styled.div`
  display: flex;
  height: 24px;
  border-radius: 16px;
  font-size: ${({ theme }) => theme.typography.small};
  border: 1px solid ${({ theme }) => theme.colors.primary.dark};
  padding: 1px;
  gap: 2px;
`;

const DomainOptions = styled.div<{ selected?: boolean }>`
  ${({ theme }) => theme.typography.small}
  padding: 1px 16px;
  border-radius: 16px;
  ${({ selected }) =>
    selected
      ? css`
          background: ${({ theme }) => theme.colors.primary.dark};
          color: ${({ theme }) => theme.colors.primary.contrastText};
        `
      : css`
          background: none;
          color: ${({ theme }) => theme.colors.primary.dark};
        `}
  cursor: pointer;
`;
