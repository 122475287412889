import { APIError, getAPI } from 'auth/authentifiedHttpCalls';
import { ListOfValues } from 'http/shared/types';
import { toast } from 'react-toastify';

export const getPromotions = async () => {
  try {
    const response = await getAPI<ListOfValues[]>('/common/promotion');
    return response.data;
  } catch (e) {
    if (e instanceof Error) {
      const err = e as APIError;
      toast.error(err.errorMessages);
    }

    throw e;
  }
};
