import { CSSProperties } from 'react';

export const reactModalContentStyle: CSSProperties = {
  bottom: 'auto',
  left: '50%',
  position: 'fixed',
  right: 'auto',
  top: '50%',
  transform: 'translate(-50%,-50%)',
  backgroundColor: 'transparent',
  border: 'none',
  padding: 32,
  maxWidth: 1000,
  width: '100%',
  maxHeight: '100%',
  overflow: 'hidden',
  boxSizing: 'content-box',
};
