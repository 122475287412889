import { FC, Fragment, useState } from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { routes } from 'routing/constants';
import { buttonReset } from 'helpers/css/button-reset';
import { Modal, ModalHeader } from 'ui/Modal';
import { formatGradeOutOfTwenty } from 'pages/correction/components/CorrectionGroupHeader/points-formatter';
import { SelectionGroupResult } from 'http/selection-group/types';
import { useGetGroupLastTrainings } from 'http/selection/group-trainings/connector';
import { FormattedDate } from 'pages/selection/components/FormattedDate';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';

interface Props {
  group: SelectionGroupResult;
}

export const GroupLastCorrections: FC<Props> = ({ group }) => {
  const userFree = useIsUserFree();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const { data: lastTrainings, isLoading } = useGetGroupLastTrainings(group.id);

  if (userFree && !group.is_free) {
    return null;
  }

  return (
    <>
      <Chip onClick={openModal}>Anciennes copies</Chip>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalHeader onCloseButtonClick={closeModal}>
          <h3>
            {group?.collection} - {group?.type}
            {group?.num}
          </h3>
        </ModalHeader>

        <ContentContainer>
          <h4>Historique des copies</h4>

          <Body>
            {isLoading && 'Chargement...'}
            {lastTrainings && (
              <LastTrainingsContainer>
                {lastTrainings.map((training) => (
                  <Fragment key={training.id}>
                    <FormattedDate
                      date={new Date(training.last_training_date)}
                    />

                    <span>
                      {formatGradeOutOfTwenty({
                        grade: training.grade,
                        totalCoeff: training.total_coefficient,
                      })}
                    </span>

                    <Link
                      to={{
                        pathname: routes.GROUP_CORRECTION,
                        search: '?id=' + training.id,
                      }}
                    >
                      Copie
                    </Link>
                  </Fragment>
                ))}
              </LastTrainingsContainer>
            )}
          </Body>
        </ContentContainer>
      </Modal>
    </>
  );
};

const Chip = styled.button`
  ${buttonReset}
  padding: 4px 8px;
  background-color: ${({ theme }) => theme.colors.inactive};
  color: ${({ theme }) => theme.colors.primary.contrastText};
  border-radius: 4px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  overflow: hidden;
`;

const LastTrainingsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  height: 100%;
  overflow-y: auto;
`;
