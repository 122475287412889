import { FC } from 'react';
import { MenuIconProps } from './types';

export const MenuCCB: FC<MenuIconProps> = ({ isFilled }) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2107 1.047H5.97657V13.1955C5.97657 16.6384 19.2107 16.5821 19.2107 13.1955V1.047Z"
        fill={isFilled ? 'currentColor' : 'none'}
        stroke="currentColor"
      />
      <path
        d="M5.97656 4.39427C5.97656 4.39427 0.562606 1.47414 0.562606 4.39427C0.562606 7.31441 3.86228 9.44105 5.97656 12.6748"
        stroke="currentColor"
      />
      <path
        d="M19.1484 4.39427C19.1484 4.39427 24.5624 1.47414 24.5624 4.39427C24.5624 7.31441 21.2627 9.44105 19.1484 12.6748"
        stroke="currentColor"
      />
      <path
        d="M8.38257 22.2567V25.047H16.8043L16.8043 22.2567C16.8043 19.5416 8.38257 19.6419 8.38257 22.2567Z"
        fill={isFilled ? 'currentColor' : 'none'}
        stroke="currentColor"
      />
      <path
        d="M10.8516 20.2578V16.1528H11.2156H14.3355V20.2578H10.8516Z"
        fill={isFilled ? 'currentColor' : 'none'}
        stroke="currentColor"
      />
    </svg>
  );
};
