import { FC } from 'react';

import { useActiveCorrectionContext } from '../../activeCorrectionContext';
import { AdaptedCorrectionQuestion } from './dataAdapter';

interface Props {
  question: AdaptedCorrectionQuestion;
}

export const QuestionName: FC<Props> = ({ question }) => {
  const { isCustomQI } = useActiveCorrectionContext();

  if (isCustomQI) {
    return (
      <div>
        {question.collection} - QI n°
        {question.num}
      </div>
    );
  }

  return <div>Question n°{question.num}</div>;
};
