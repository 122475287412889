import { createAsyncThunk } from '@reduxjs/toolkit';
import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';
import { getPopups, postPopup } from 'http/popup/api';
import { Popup } from 'http/popup/types';
import { toast } from 'react-toastify';
import { RootState } from 'store';

export const fetchPopups = createAsyncThunk<
  Popup[],
  void,
  { rejectValue: string; state: RootState }
>('popup/get_popups', async (_, { rejectWithValue }) => {
  try {
    const response = await getPopups();

    if (response) {
      return response;
    }

    toast.error(
      "Erreur serveur : Les popups n'ont pu être récupérées. Veuillez réessayer."
    );
    return rejectWithValue('UNKNOWN_ERROR');
  } catch (error) {
    reportErrorToSentry(error);
    return rejectWithValue('UNKNOWN_ERROR');
  }
});

export const postSeenPopup = createAsyncThunk<
  void,
  void,
  { rejectValue: string; state: RootState }
>('popup/post_popup', async (_, { rejectWithValue, getState }) => {
  const state = getState();
  try {
    if (state.popup.popup) {
      await postPopup(state.popup.popup.id);
    } else {
      return rejectWithValue('Popup not found');
    }
  } catch (error) {
    reportErrorToSentry(error);
    return rejectWithValue('UNKNOWN_ERROR');
  }
});
