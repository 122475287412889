import { getAPI } from 'auth';
import { Source } from 'types/source.type';

export const getSourcesHttp = async (codes: string[]) => {
  const response = await getAPI<Source[]>('/common/sources/', {
    params: {
      code: codes,
    },
  });
  return response.data;
};
