import { useMutation } from 'react-query';
import { addCardToDeck, createDeck, deleteCardsFromDeck } from './api';
import { FQDeckName } from './types';

export const useCreateDeck = () => {
  const mutation = useMutation(createDeck);

  const createDeckFromName = (
    params: FQDeckName,
    onSuccess: () => void,
    onError: (err: unknown) => void
  ) => {
    return mutation.mutate(params, { onSuccess, onError });
  };

  return { ...mutation, createDeckFromName };
};

export const useFillDeck = () => {
  const mutation = useMutation(addCardToDeck);

  const addCardsToDeck = (deckName: string, questionIds: number[]) => {
    mutation.mutate({ label: deckName, question_ids: questionIds });
  };

  return { addCardsToDeck, mutation };
};

export const useDeleteCards = () => {
  const mutation = useMutation(deleteCardsFromDeck);

  const deleteCards = (deckName: string, questionIds: number[]) => {
    mutation.mutate({ label: deckName, question_ids: questionIds });
  };

  return { deleteCards, mutation };
};
