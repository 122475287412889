import { useQuery } from 'react-query';
import { getProgressGeneralQIRepository } from '../http/repository';
import { MS_IN_AN_HOUR } from 'constants/date';

export const USE_GET_QI_PROGRESS_BY_SESSION_KEY =
  'useGetQiProgressBySessionKey';

export const useGetQIProgressBySession = () => {
  return useQuery(
    [USE_GET_QI_PROGRESS_BY_SESSION_KEY],
    () => getProgressGeneralQIRepository(),

    {
      cacheTime: MS_IN_AN_HOUR,
      staleTime: MS_IN_AN_HOUR,
    }
  );
};
