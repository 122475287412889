import { MenuItem } from '@szhsin/react-menu';

interface MenuOption {
  value: string;
  label: string;
}

/**
 * We need to use this approach instead of a wrapped component
 * because of how the library is made sadly.
 *
 * More infos on this issue: https://github.com/szhsin/react-menu/issues/368
 */
export const renderRadios = (options: MenuOption[]) => {
  return options.map((option) => (
    <MenuItem value={option.value} key={option.value}>
      {option.label}
    </MenuItem>
  ));
};
