import { areItemInfosEqual } from 'pages/selection/TableHeaders/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ItemInfos } from 'store/types';
import { SimilarQuestion } from 'types/correction.type';

export const useIsSimilarQuestionAlreadySelected = (
  question: SimilarQuestion
) => {
  const questionInfos: ItemInfos =
    question.group.type === 'QI'
      ? {
          id: question.id,
          type: question.group.type,
          number_of_questions: 1,
        }
      : {
          id: question.group.id,
          type: question.group.type,
          number_of_questions: question.group.number_of_questions,
        };

  const selectedItems = useSelector((state: RootState) => {
    switch (question.group.type) {
      case 'QI':
        return state.qiTable.selectedItemsInfos;
      case 'DP':
        return state.dpTable.selectedItemsInfos;
      case 'LCA':
        return state.lcaTable.selectedItemsInfos;
      default:
        return state.qiTable.selectedItemsInfos;
    }
  });

  const isSelected = selectedItems.some((selectedItem) =>
    areItemInfosEqual(selectedItem, questionInfos)
  );

  return isSelected;
};
