import { FC } from 'react';

export const FullWidthRow: FC<{ colNumber: number }> = ({
  children,
  colNumber,
}) => (
  <tr>
    <td colSpan={colNumber}>{children}</td>
  </tr>
);
