import { postAPI } from 'auth';
import { FormProps } from 'pages/flash-questions/modals/FQSettingsModal';

export const postParameters = async (values: FormProps) => {
  const response = await postAPI<void>('/flash/set_parameters', {
    max_new: values.newCardsPerDay,
    max_review: values.reviewCardsPerDay,
  });

  return response.data;
};
