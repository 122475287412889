import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';
import { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { updateShowFree } from 'store/slices/selectionFiltersSlice';
import styled from 'styled-components/macro';
import { Radio } from 'ui/Radio';

interface Props {}

export const ShowFreeFilter: FC<Props> = () => {
  const showDone = useSelector(
    (state: RootState) => state.selectionFilters.showFree
  );

  const dispatch = useDispatch();

  const setShowDone = (val: boolean) => {
    dispatch(updateShowFree(val));
  };

  const { isUserPremium } = useIsUserPremium({ isOptimist: true });

  if (isUserPremium) {
    return null;
  }

  return (
    <OptionsContainer>
      <Option>
        <Radio
          checked={showDone === true}
          onChange={() => setShowDone(true)}
          id="free"
        />
        <label htmlFor="free">Contenu gratuit</label>
      </Option>
      <Option>
        <Radio
          checked={showDone === false}
          onChange={() => setShowDone(false)}
          id="all"
        />
        <label htmlFor="all">Tout</label>
      </Option>
    </OptionsContainer>
  );
};

const OptionsContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  padding: 0 30px;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
