import { createContext, FC, useContext, useState } from 'react';
import { MENUCONTENT } from '../AccountMenuElements';

interface AccountNavigationContextProps {
  activeTab: string;
  setActiveTab: (activeTab: string) => void;
  menuContent: Array<{ icon: any; title: string; infoToDisplay: any }>;
}

const contextDefaultValue: AccountNavigationContextProps = {
  activeTab: 'Mes informations',
  setActiveTab: () => {},
  menuContent: MENUCONTENT,
};

const AccountNavigationContext = createContext(contextDefaultValue);

export const AccountNavigationProvider: FC = ({ children }) => {
  const [activeTab, setActiveTab] = useState<string>('Mes informations');
  const menuContent = MENUCONTENT;

  return (
    <AccountNavigationContext.Provider
      value={{
        activeTab,
        setActiveTab,
        menuContent,
      }}
    >
      {children}
    </AccountNavigationContext.Provider>
  );
};

export const useAccountNavigationContext = () =>
  useContext(AccountNavigationContext);

export const STATEMENT_ID = -1;
