import { FC, useMemo } from 'react';
import styled from 'styled-components/macro';
import parse from 'html-react-parser';

import { ItemFromKnowledge } from 'components/Item/ItemFromKnowledge';
import { SpecialtyIcon } from 'ui/icons/SpecialtyIcon/SpecialtyIcon';
import { useActiveCorrectionContext } from '../../activeCorrectionContext';
import { computeQuestionTotalPoints } from '../../helpers/computeQuestionTotalPoints';
import { useAdaptedCorrectionQuestion } from './dataAdapter';
import { QuestionName } from './QuestionName';
import { StyledMathJax } from 'ui/MathJax';
import { toFixedWithoutZeros } from 'helpers/application/toFixedWithoutZero';
import { KnowledgeRankFromId } from 'components/Knowledge/KnowledgeRankFromId';
import { CorrectionQuestion } from 'types/correction.type';
import { GreyCard } from 'ui/Cards/GreyCard';
import { DotSeparator } from 'ui/DotSeparator';
import { Quotes } from 'components/Quote/Quotes';

interface Props {
  questionData: CorrectionQuestion;
}

export const QuestionHeader: FC<Props> = ({ questionData }) => {
  const question = useAdaptedCorrectionQuestion(questionData);
  const { totalCoefficient: groupTotalCoeff, isCustomQI } =
    useActiveCorrectionContext();
  // eslint-disable-next-line
  const questionTotalPoints = useMemo(
    () =>
      isCustomQI
        ? question.grade * 18
        : computeQuestionTotalPoints({
            questionGrade: question.grade,
            questionCoefficient: question.coefficient,
            totalCoefficient: groupTotalCoeff,
          }),
    [groupTotalCoeff, isCustomQI, question.coefficient, question.grade]
  );

  return (
    <QuestionContainer>
      <Header>
        <QuestionInfos>
          <QuestionName question={question} />
          <DotSeparator />
          <div>{question.type}</div>
          <DotSeparator />
          <SpeIcon trigram={question.spe1Trigram} />
          <div>{question.specialty1}</div>
          {question.specialty2 && (
            <>
              <SpeIcon trigram={question.spe2Trigram} />
              {question.specialty2}
            </>
          )}
          {question.knowledgeId && (
            <>
              <DotSeparator />
              <ItemFromKnowledge knowledgeId={question.knowledgeId} />
              <DotSeparator />
              <KnowledgeRankFromId knowledgeId={question.knowledgeId} />
            </>
          )}
          {question.category && (
            <>
              <DotSeparator />
              <div>{question.category}</div>
            </>
          )}
        </QuestionInfos>

        <QuestionMarking>
          {question.coefficient > 1 && <h2>Coeff {question.coefficient}</h2>}
          <h2>
            {toFixedWithoutZeros(question.grade, 2)}/{question.coefficient}
          </h2>
        </QuestionMarking>
      </Header>
      <QuestionStatement>
        <StyledMathJax inline>{parse(question.statement)}</StyledMathJax>
      </QuestionStatement>
      {question.correction && (
        <CardContainer>
          <GreyCard>
            <StyledMathJax inline>{parse(question.correction)}</StyledMathJax>
          </GreyCard>
          {question?.quotes.length !== 0 && <Quotes quotes={question.quotes} />}
        </CardContainer>
      )}
    </QuestionContainer>
  );
};

const QuestionContainer = styled.div``;

const Header = styled.div`
  padding: 0 var(--horizontal-padding);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const QuestionInfos = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.typography.h2}
`;

const QuestionMarking = styled.div`
  display: flex;
  gap: 36px;
  ${({ theme }) => theme.typography.h2};
  font-size: 700;
`;

const QuestionStatement = styled.div`
  margin-top: 16px;
  padding: 0 var(--horizontal-padding);

  img {
    max-width: 100%;
    height: auto;
  }
`;

const SpeIcon = styled(SpecialtyIcon)`
  height: 16px;
  width: 16px;
  margin-left: 16px;
  display: block;
  margin: auto;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 8px var(--horizontal-padding) 0;
`;
