// import { Pagination } from 'pages/newHome/components/Pagination';
import { useGetHomeSpecialties } from '../hooks/useGetSpecialties';
// import { useHomeSpecialtiesFiltersContext } from '../context/homeSpecialtiesFiltersContext';
import { LoaderWithText } from 'components/Loader/LoaderWithText';
import { SpecialtiesCards } from './SpecialtiesCards';

export const SpecialitiesView = () => {
  const { data, isLoading } = useGetHomeSpecialties();
  // const {
  //   specialtyPageSize,
  //   currentSpecialtyPage,
  //   setCurrentSpecialtyPage,
  //   setSpecialtyPageSize,
  // } = useHomeSpecialtiesFiltersContext();

  if (isLoading || !data) return <LoaderWithText />;

  return (
    <>
      <SpecialtiesCards specialties={data} />
      {/* <Pagination
        numberOfPages={Math.ceil(data?.count / specialtyPageSize) ?? 0}
        currentPage={currentSpecialtyPage}
        setCurrentPage={setCurrentSpecialtyPage}
        pageSize={specialtyPageSize}
        setPageSize={setSpecialtyPageSize}
        options={[
          { label: '20', value: 20 },
          { label: '30', value: 20 },
        ]}
      /> */}
    </>
  );
};
