import { getAPI, postAPI } from 'auth';
import { DeckFromAPI } from '../decks/types';
import {
  FQCardsParams,
  FQCardToAdd,
  FQCardToDelete,
  FQDeckName,
  FQFromAPI,
} from './types';

export const getFQCardsFromAPI = async (params: FQCardsParams) => {
  const response = await getAPI<FQFromAPI>('/flash/selection', {
    params,
  });
  return response.data;
};

export const createDeck = async (params: FQDeckName) => {
  const response = await postAPI<DeckFromAPI>('/flash/create_deck', params);
  return response.data;
};

export const addCardToDeck = async (params: FQCardToAdd) => {
  const response = await postAPI<DeckFromAPI>('/flash/add', params);
  return response.data;
};

export const deleteCardsFromDeck = async (params: FQCardToDelete) => {
  const response = await postAPI<DeckFromAPI>('/flash/remove', params);
  return response.data;
};
