import { FC } from 'react';
import styled from 'styled-components/macro';
import { thinScrollbarStyle } from 'helpers/css/thin-scrollbar';
import { Item as ItemType } from 'types/item.type';
import { Item } from '../Item';

interface ItemsListProps {
  items: ItemType[];
  noLabel?: boolean;
}

export const ItemsList: FC<ItemsListProps> = ({ items, noLabel }) => {
  return (
    <Container $noBorder={noLabel}>
      {!noLabel && <Title>Items abordés :</Title>}
      <ChipContainer>
        {items.map((item) => (
          <Item item={item} key={item.item} />
        ))}
      </ChipContainer>
    </Container>
  );
};

const Container = styled.div<{ $noBorder?: boolean }>`
  width: 100%;
  padding: 16px 16px;
  padding: ${({ $noBorder }) => ($noBorder ? '0px' : '16px 16px')};
  border: ${({ theme, $noBorder }) =>
    $noBorder ? 'none' : '1px solid' + theme.colors.primary.light};
  border-radius: 8px;
  ${thinScrollbarStyle}
`;

const ChipContainer = styled.div`
  overflow: auto;
  padding: 6px 4px;
  display: flex;
  gap: 16px;
  width: 100%;
`;

const Title = styled.div`
  ${({ theme }) => theme.typography.h5}
  margin-bottom: 16px;
`;
