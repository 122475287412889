import { FC, useEffect, useState } from 'react';

import { ContainedLoader } from 'components/ContainedLoader';

import { DPProgressYearBySession } from './DPProgressYearBySession';
import { GenericErrorPlaceholder } from 'components/GenericErrorPlaceholder';
import { useGetDPProgressByCompositionUnits } from 'modules/progress/general/dp/hooks/useGetDPProgressByCompositionUnits';
import { useTour } from '@reactour/tour';
import { DPProgressByCompositionUnits } from 'modules/progress/general/dp/domain/entities/DPProgressByCompositionUnits';
import { progressMocks } from '../mocks/progressMocks';

export const DPProgressBySession: FC = () => {
  const [shownData, setShownData] = useState<
    DPProgressByCompositionUnits[] | undefined
  >([]);
  const { data, isLoading, error } = useGetDPProgressByCompositionUnits();
  const { isOpen } = useTour();

  useEffect(() => {
    if (isOpen) {
      setShownData(progressMocks);
    } else {
      setShownData(data);
    }
  }, [isOpen, data]);

  if (isLoading) {
    return <ContainedLoader loadingText="Veuillez patienter..." />;
  }

  if (error) {
    return <GenericErrorPlaceholder />;
  }

  if (shownData) {
    return (
      <>
        {shownData.map((progress) => {
          if (Object.keys(progress.compositionUnits).length === 0) {
            return null;
          }
          return (
            <DPProgressYearBySession
              progress={progress}
              key={progress.collection}
            />
          );
        })}
      </>
    );
  }

  return <div>Un problème est survenu</div>;
};
