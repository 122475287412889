import { FC } from 'react';
import { ArrowSquareOut } from 'ui/icons/ArrowSquareOut';
import { useNavigate } from 'react-router';
import styled from 'styled-components/macro';
import { Book as BookType } from 'http/compass/types';

interface BookProps {
  book: BookType;
  isBlue: boolean;
}

export const Book: FC<BookProps> = ({ book, isBlue }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/?selected=specialties&activeSpeciality=${book.book}`);
  };

  return (
    <BookContainer isBlue={isBlue} key={book.short_title} onClick={handleClick}>
      {book.title}
      <IconContainer>
        <ArrowSquareOut />
      </IconContainer>
    </BookContainer>
  );
};

const BookContainer = styled.div<{ isBlue: boolean }>`
  background-color: ${({ theme, isBlue }) =>
    isBlue ? theme.colors.primary.lighter : theme.colors.background.paper};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: 8px;
  max-width: 330px;
  cursor: pointer;
  ${({ theme }) => theme.typography.label};
  border: 1px solid ${({ theme }) => theme.colors.primary.light};
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${({ theme, isBlue }) =>
      isBlue ? theme.colors.primary.lighter : theme.colors.primary.light};
  }
`;

const IconContainer = styled.div`
  height: 20px;
  width: 20px;
  flex-shrink: 0;
`;
