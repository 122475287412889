import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

interface CountdownProps {
  seconds: number;
  callback?: () => void;
}

export const Countdown: React.FC<CountdownProps> = ({ seconds, callback }) => {
  const [timeLeft, setTimeLeft] = useState<number>(seconds);
  const [timerIsDone, setTimerIsDone] = useState<boolean>(false);

  useEffect(() => {
    if (timeLeft <= 0) return;

    const timerId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, [timeLeft]);

  useEffect(() => {
    if (timeLeft === 0 && !timerIsDone) {
      setTimerIsDone(true);
    }
  }, [timeLeft, timerIsDone]);

  useEffect(() => {
    if (timerIsDone && callback) {
      callback();
    }
  }, [timerIsDone, callback]);

  return (
    <div>
      <Count>{timeLeft}</Count>
    </div>
  );
};

export default Countdown;

const Count = styled.h4`
  color: ${({ theme }) => theme.colors.error.dark};
  font-weight: bold;
`;
