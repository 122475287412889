import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const GROUP_TYPE_QUERY_PARAM = 'groupType';

export const useGroupType = () => {
  const groupType = useSelector((state: RootState) => state.progress.groupType);

  return groupType;
};
