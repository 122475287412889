import { FC } from 'react';
import styled from 'styled-components/macro';
import Tippy from '@tippyjs/react';

export type RepartitionBarData = {
  id: string;
  name: string;
  value: number;
  color: string;
}[];

interface RepartitionBarProps {
  repartition: RepartitionBarData;
  big?: boolean;
  rounded?: boolean;
}

export const RepartitionBar: FC<RepartitionBarProps> = ({
  repartition,
  big = true,
  rounded = true,
}) => {
  const total = repartition.reduce((acc, cur) => acc + cur.value, 0);

  return (
    <Container rounded={rounded}>
      {repartition.map(
        (element) =>
          element.value !== 0 && (
            <Tippy
              content={
                <LineReturnContainer>{element.name}</LineReturnContainer>
              }
              arrow={false}
              key={element.name}
              placement="bottom"
            >
              <Bar
                key={element.name}
                color={element.color}
                sizePercentage={(element.value / total) * 100}
                rounded
                big={big}
              >
                {!big ?? element}
              </Bar>
            </Tippy>
          )
      )}
    </Container>
  );
};

const Container = styled.div<{ rounded?: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 60px;
  & > :first-child {
    border-top-left-radius: ${({ rounded }) => (rounded ? '12px' : '0')};
    border-bottom-left-radius: ${({ rounded }) => (rounded ? '12px' : '0')};
  }

  & > :last-child {
    border-top-right-radius: ${({ rounded }) => (rounded ? '12px' : '0')};
    border-bottom-right-radius: ${({ rounded }) => (rounded ? '12px' : '0')};
  }
`;

const Bar = styled.div<{
  sizePercentage: number;
  big?: boolean;
  rounded?: boolean;
  color: string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.primary.dark};
  background-color: ${({ color }) => color};
  height: ${({ big }) => (big ? '1rem' : '4px')};
  width: ${({ sizePercentage }) => sizePercentage}%;
`;

const LineReturnContainer = styled.div`
  white-space: pre-line;
`;
