import { KeyIdDoesntExistOnObject } from './Errors/KeyIdDoesntExistOnObject';

export function createMapIdToObject<TObject extends Record<string, any>>(
  objects: TObject[] | undefined,
  idKey: keyof TObject
): { [key: string]: TObject } {
  if (objects === undefined) {
    return {};
  }

  if (objects.length === 0) {
    return {};
  }

  if (!Object.keys(objects[0]).includes(idKey.toString())) {
    throw new KeyIdDoesntExistOnObject();
  }

  return objects.reduce<{ [key: string]: TObject }>(
    (prev, object) => ({
      ...prev,
      [object[idKey]]: object,
    }),
    {}
  );
}
