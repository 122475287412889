import { getAPI } from 'auth';
import { FQCardCorrection, FQCorrectionParams } from 'types/correction.type';

export const getFQCorrectionFromAPI = async (params: FQCorrectionParams) => {
  const response = await getAPI<FQCardCorrection>('/flash/correct_card/', {
    params,
  });

  return response.data;
};
