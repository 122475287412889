import styled from 'styled-components/macro';

import { useMenuContext } from 'components/MainLayout/menuContext';

export const CloseMenuOverlay: React.FC = () => {
  const { isMenuExpanded, closeMenu } = useMenuContext();

  return isMenuExpanded ? <Overlay onClick={closeMenu} /> : null;
};

const Overlay = styled.div`
  position: fixed;
  --size: 100%;
  height: var(--size);
  width: var(--size);
  z-index: ${({ theme }) => theme.zIndex.menu - 1};

  background-color: rgba(24, 24, 24, 0.2);
`;
