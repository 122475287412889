import { useMemo } from 'react';

import { getDPProgressByCompositionUnits } from '../domain/useCases/getDPProgressByCompositionUnits';
import { useGetDPProgressBySession } from './useGetDPProgressBySession';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';

export const useGetDPProgressByCompositionUnits = () => {
  const progressBySessionQuery = useGetDPProgressBySession();
  const { isUserPremium } = useIsUserPremium();

  const progressByCompositionUnit = useMemo(() => {
    return getDPProgressByCompositionUnits(
      progressBySessionQuery.data,
      isUserPremium
    );
  }, [isUserPremium, progressBySessionQuery.data]);

  return {
    ...progressBySessionQuery,
    data: progressByCompositionUnit,
  };
};
