import { FC } from 'react';
import styled from 'styled-components/macro';
import { CorrectionThemeProvider } from 'components/CorrectionThemeProvider';
import { useGetLessonsByIds } from 'http/lessons/connector';
import { CorrectionRelatedLesson } from 'components/RelatedLesson/CorrectionRelatedLesson';

interface Props {
  lessonIds: string[];
}

export const RelatedLessons: FC<Props> = ({ lessonIds }) => {
  const lessons = useGetLessonsByIds(lessonIds);

  if (!lessons) return null;

  return (
    <CorrectionThemeProvider>
      <LessonsContainer>
        {lessons.map((lesson) => (
          <CorrectionRelatedLesson lesson={lesson} key={lesson.lesson} />
        ))}
      </LessonsContainer>
    </CorrectionThemeProvider>
  );
};

const LessonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
