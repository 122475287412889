import { FC } from 'react';
import styled from 'styled-components/macro';

import { ModalHeader, Modal } from 'ui/Modal';
import { Loader } from 'components/Loader/Loader';

import { useGetQICorrection } from 'http/correction/get_group/connector';
import { QuestionCorrectionBlock } from 'pages/correction/active-correction-group/questions-correction-blocks/QuestionCorrectionBlock';
import { AddQIToSelectionButton } from './AddQIToSelectionButton';
import { CorrectionThemeProvider } from 'components/CorrectionThemeProvider';
import { ActiveCorrectionProvider } from 'pages/correction/active-correction-group/questions-correction-blocks/activeCorrectionContext';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  correctionGroupId: number;
  qiIdInCorrection: number;
  qiId: number;
}

export const CorrectionModal: FC<Props> = ({
  isModalOpen,
  closeModal,
  correctionGroupId,
  qiIdInCorrection,
  qiId,
}) => {
  const { data: question } = useGetQICorrection(
    correctionGroupId,
    qiIdInCorrection
  );

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      {question ? (
        <>
          <ModalHeader onCloseButtonClick={closeModal}>
            <h4>{`${question.question.collection} - QI n°${question.question.num} (${question.question.type})`}</h4>
          </ModalHeader>
          <ModalContent>
            <ScrollableContainer>
              <CorrectionThemeProvider>
                <ActiveCorrectionProvider
                  // its ok to type as any since we only access
                  // the custom_qi property in the CorrectionBlock
                  groupData={{ custom_qi: true } as any}
                >
                  {question && (
                    <QuestionCorrectionBlock questionData={question} />
                  )}
                </ActiveCorrectionProvider>
              </CorrectionThemeProvider>
            </ScrollableContainer>

            <AddQIToSelectionButton qiId={qiId} />
          </ModalContent>
        </>
      ) : (
        <>
          <ModalContent>
            <Loader />
          </ModalContent>
        </>
      )}
    </Modal>
  );
};

const ModalContent = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

const ScrollableContainer = styled.div`
  overflow: auto;
  padding-right: 4px;
  width: 100%;
`;
