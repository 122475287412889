import { Indicator, Root } from '@radix-ui/react-progress';
import { FC, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

interface Props {
  fillingColor: string;
  backgroundFillingColor?: string;
  percentage?: number;
  className?: string;
  height?: number;
}

export const ProgressBarStat: FC<Props> = (props) => {
  // We use the state in order to make the animation start from 0, just like the piechart above.
  // We could use directly the props, but in this case, we couldn't use the animation since it would automatically start from the props value.
  const [percentage, setPercentage] = useState<number>(0);

  const theme = useTheme();

  useEffect(() => {
    setPercentage(props.percentage ?? 0);
  }, [props.percentage]);

  return (
    <Container className={props.className}>
      <StyledRoot
        value={props.percentage || 0}
        style={{
          height: props.height ?? 20,
          backgroundColor:
            props.backgroundFillingColor ?? theme.colors.inactive,
        }}
      >
        <StyledIndicator
          style={{
            width: `${percentage}%`,
            backgroundColor: props.fillingColor,
          }}
        />
      </StyledRoot>
    </Container>
  );
};

const Container = styled.div``;

const StyledRoot = styled(Root)`
  overflow: hidden;
  width: 100%;
  border-radius: 8px;
`;

const StyledIndicator = styled(Indicator)`
  height: 100%;
  border-radius: 8px;
  transition: width 1s ease;
`;
