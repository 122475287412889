import { getAPI } from 'auth';
import {
  SessionRequestType,
  SessionResponseType,
  SpecialtyRequestType,
  SpecialtyResponseType,
} from './types';

export const getSessionRecapHttp = async (body: SessionRequestType) => {
  const response = await getAPI<SessionResponseType>('/recap/session', {
    params: body,
  });

  return response.data;
};

export const getSpecialtyRecapHttp = async (body: SpecialtyRequestType) => {
  const response = await getAPI<SpecialtyResponseType>('/recap/specialty', {
    params: body,
  });

  return response.data;
};

export const getAnnalsSessionRecapHttp = async () => {
  const response = await getAPI<SessionResponseType>('/recap/session/annals');

  return response.data;
};
