import { useGetItemsListAsOptions } from 'modules/items/hooks/useGetItemsListAsOptions';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';
import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { updateItemsFilter } from 'store/slices/selectionFiltersSlice';

import { GenericSortFilterHeader } from './GenericSortFilterHeader';

interface Props {
  isSub?: boolean;
}

export const ItemsHeader: FC<Props> = ({ isSub }) => {
  const { data } = useGetItemsListAsOptions();
  const filterOptions = useMemo(() => data || [], [data]);

  const isUserFree = useIsUserFree();

  const dispatch = useDispatch();

  const selectedFilters = useSelector(
    (state: RootState) => state.selectionFilters.items
  );

  const setSelectedFilters = (items: string[]) => {
    dispatch(updateItemsFilter(items));
  };

  return (
    <GenericSortFilterHeader
      columnName="Item"
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      sortColumn="item"
      filterOptions={filterOptions}
      isSub={isSub}
      disabled={isUserFree}
    />
  );
};
