import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { updateSpecialitiesFilter } from 'store/slices/selectionFiltersSlice';
import { useGetSpecialitiesList } from 'http/selection/specialities-list/connectors';

import { GenericSortFilterHeader } from './GenericSortFilterHeader';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';

interface Props {
  isSub?: boolean;
}

export const SpecialitiesHeader: FC<Props> = ({ isSub }) => {
  const { data } = useGetSpecialitiesList();
  const filterOptions = useMemo(() => data?.options || [], [data]);
  const isUserFree = useIsUserFree();

  const dispatch = useDispatch();

  const selectedFilters = useSelector(
    (state: RootState) => state.selectionFilters.specialities
  );

  const setSelectedFilters = (items: string[]) => {
    dispatch(updateSpecialitiesFilter(items));
  };

  return (
    <GenericSortFilterHeader
      columnName="Spé."
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      sortColumn="specialty"
      filterOptions={filterOptions}
      isSub={isSub}
      disabled={isUserFree}
    />
  );
};
