export interface ProtectedRouteRoutingState {
  from: string;
}

export const isProtectedRouteRoutingState = (
  state: unknown
): state is ProtectedRouteRoutingState => {
  return !!(
    typeof state === 'object' &&
    typeof (state as ProtectedRouteRoutingState)?.from === 'string'
  );
};
