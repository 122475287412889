import { getAPI } from 'auth';
import { CorrectionGroup } from 'types/correction.type';

export const getCorrectionGroup = async (groupCorrectionId: number) => {
  const response = await getAPI<CorrectionGroup>('/correction/get_group', {
    params: {
      group_id: groupCorrectionId,
    },
  });
  return response.data;
};
