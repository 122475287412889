import { useState } from 'react';
import { toast } from 'react-toastify';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { createCheckoutSession } from 'http/payments/createCheckoutSession/api';
import { getStripePublicKey } from 'http/payments/stripePublicKey/api';
import { UserFacturationInfos } from 'pages/subscribe/FacturationPage/FacturationPage.type';

/*
 * Reference : https://vercel.com/guides/getting-started-with-nextjs-typescript-stripe
 */

// Stripe singleton
let stripePromise: Promise<Stripe | null>;
const getStripe = async () => {
  if (!stripePromise) {
    const stripePublicKey = await (await getStripePublicKey()).public_key;
    stripePromise = loadStripe(stripePublicKey);
  }
  return stripePromise;
};

export const useStripeCheckout = () => {
  const [isLoading, setIsLoading] = useState(false);

  async function createCheckout(id: string, params: UserFacturationInfos) {
    setIsLoading(true);
    try {
      const stripe = await getStripe();

      if (!stripe) {
        toast.error(
          "Nous n'arrivons pas à récupérer les infos de paiement, veuillez réessayer plus tard"
        );
        return;
      }
      if (!id) {
        return;
      }
      const checkoutSession = await createCheckoutSession({
        product_id: id,
        coupon: params?.coupon,
        email: params?.email,
        first_name: params?.firstName,
        last_name: params?.lastName,
        zip_code: params?.zipCode,
        address: params?.address,
        city: params?.city,
        country: params?.country,
        terms_of_use_chekbox: params?.termsOfUseChekbox,
        renounce_retraction_checkbox: params?.retractionRenounce,
      });

      if (!checkoutSession) {
        return;
      }

      const { error } = await stripe.redirectToCheckout({
        sessionId: checkoutSession.sessionId,
      });

      if (error) {
        toast.error(error.message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  return { isLoading, createCheckout };
};
