import { FC } from 'react';
import styled from 'styled-components';
import { NewsletterButton } from 'components/Newsletter/NewsletterButton';
import { useGetUserOptions } from 'modules/user-options/hooks/useGetUserOptions';

export const NewsletterBlock: FC = () => {
  const { data: userOptions } = useGetUserOptions();

  return (
    <>
      {!userOptions?.newsletter && userOptions?.needs_newsletter_prompt && (
        <>
          <NewsletterButton
            text={"S'inscrire à la newsletter"}
            color={'primary'}
          />
          <StyledSmall>
            Les informations recueillies font l'objet d'un traitement
            informatique destiné à vous adresser la newsletters. Pour en savoir
            plus sur la collecte, nous vous invitons à lire{' '}
            <a
              href={'https://asclepia.io/confidentiality'}
              target="_blank"
              rel="noreferrer"
            >
              {' '}
              notre politique de confidentialité des données
            </a>
            .
          </StyledSmall>
        </>
      )}
    </>
  );
};

const StyledSmall = styled.small`
  margin-top: 16px;
`;
