interface Props {
  className?: string;
  fillingColor?: string;
}

export const PauseIcon = (props: Props) => (
  <svg
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 33"
    className={props.className}
  >
    <rect width={32} height={33} rx={16} fill="currentColor" />
    <path
      fill={props.fillingColor || 'white'}
      d="M10 9h4v14h-4zM18 9h4v14h-4z"
    />
  </svg>
);
