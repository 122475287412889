import { FC } from 'react';
import styled from 'styled-components/macro';

import { CheckDefault } from '../components/icons/CheckDefault';
import { CrossDefault } from '../components/icons/CrossDefault';

interface Props {
  userGrade: number | null;
  maxGrade: number;
}

export const EvalIcon: FC<Props> = ({ userGrade, maxGrade }) => {
  return (
    <IconContainer>
      {userGrade === maxGrade ? <CheckDefault /> : <CrossDefault />}
    </IconContainer>
  );
};

const IconContainer = styled.div`
  height: 20px;
  width: 20px;
`;
