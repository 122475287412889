import { FC, InputHTMLAttributes } from 'react';
import styled from 'styled-components';

interface Props {
  isCurrent: boolean;
  isFilled: boolean;
  className?: string;
  labelProps?: InputHTMLAttributes<HTMLLabelElement>;
  children?: React.ReactNode;
  isBeingModified: boolean | null;
  isQI: boolean;
  isFirstQuestion: boolean;
  shouldColorInRose: boolean;
}

export const QuestionListItem: FC<Props> = ({
  labelProps,
  isCurrent,
  isFilled,
  isBeingModified,
  className,
  children,
  shouldColorInRose,
  isQI,
  isFirstQuestion,
}) => {
  const getBackgroundColor = () => {
    if (isCurrent && shouldColorInRose) {
      return '#EF9198';
    }

    if (isFilled && !isCurrent) {
      return '#183876';
    }

    return 'white';
  };

  const getTextColor = () => {
    if (isFilled && !isCurrent) {
      return 'white';
    }

    if (isFilled && isCurrent && !shouldColorInRose) {
      return 'black';
    }

    if (isBeingModified) {
      return 'white';
    }

    if (shouldColorInRose && isCurrent) {
      return 'white';
    }

    return 'black';
  };

  return (
    <Container
      className={className}
      style={{
        backgroundColor: getBackgroundColor(),
      }}
    >
      <FilledMark>
        <ModificationMark />
        <Question {...labelProps} style={{ color: getTextColor() }}>
          {children}
        </Question>
      </FilledMark>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  vertical-align: center;
  width: 100%;
  cursor: pointer;
`;

const FilledMark = styled.div`
  position: relative;
  width: 100%;
  height: 41px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModificationMark = styled.div`
  height: 5px;
  position: absolute;
  top: 5px;
  left: 0px;
  background: #f5d803;
`;

const Question = styled.label`
  line-height: 100%;
  text-align: center;
  padding: 0 8px;
  font-family: 'FreeSans';
  font-size: 14px;
  cursor: pointer;
`;
