import { FC } from 'react';
import { MenuIconProps } from './types';

export const MenuInterface: FC<MenuIconProps> = ({ isFilled }) => {
  return (
    <svg viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 21V1h20v20H1Z" stroke="currentColor" strokeLinejoin="round" />
      <path
        d="M3 10V3h16v7H3ZM3 19v-7h6v7H3ZM11 19v-7h8v7h-8Z"
        stroke="currentColor"
        strokeLinejoin="round"
        fill={isFilled ? 'currentColor' : undefined}
      />
    </svg>
  );
};
