import { useClickedQuestionContext } from '../clickedQuestionContext';
import styled, { css } from 'styled-components/macro';

export const GradeFormatSwitch = () => {
  const { isPointPerQuestion, handleIsPointSwitch } =
    useClickedQuestionContext();

  return (
    <PointToTwentySwitch>
      <GradeInPoints
        onClick={handleIsPointSwitch}
        isPointPerQuestion={isPointPerQuestion}
      >
        Notes sur points
      </GradeInPoints>
      <GradeOnTwenty
        onClick={handleIsPointSwitch}
        isPointPerQuestion={isPointPerQuestion}
      >
        Notes sur 20
      </GradeOnTwenty>
    </PointToTwentySwitch>
  );
};

const PointToTwentySwitch = styled.div`
  display: flex;
  margin-left: 40px;
  height: 24px;
  border-radius: 16px;
  font-size: ${({ theme }) => theme.typography.small};
  border: 1px solid ${({ theme }) => theme.colors.primary.dark};
  padding: 1px;
  gap: 2px;
`;

const GradeOnTwenty = styled.div<{ isPointPerQuestion: boolean }>`
  padding: 1px 16px;
  border-radius: 16px;
  ${({ isPointPerQuestion }) => css`
    color: ${isPointPerQuestion
      ? ({ theme }) => theme.colors.primary.dark
      : ({ theme }) => theme.colors.primary.light};
    background-color: ${isPointPerQuestion
      ? ({ theme }) => theme.colors.background.paper
      : ({ theme }) => theme.colors.primary.dark};
  `};
`;

const GradeInPoints = styled.div<{ isPointPerQuestion: boolean }>`
  padding: 1px 16px;
  border-radius: 16px;
  ${({ isPointPerQuestion }) => css`
    color: ${isPointPerQuestion
      ? ({ theme }) => theme.colors.primary.light
      : ({ theme }) => theme.colors.primary.dark};
    background-color: ${isPointPerQuestion
      ? ({ theme }) => theme.colors.primary.dark
      : ({ theme }) => theme.colors.background.paper};
  `}
`;
