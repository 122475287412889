import { useAccountNavigationContext } from './context/accountNavigationContext';

export const InfoDisplay = () => {
  const { activeTab, menuContent } = useAccountNavigationContext();

  const ComponentToDisplay = menuContent.find(
    (el) => el.title === activeTab
  )?.infoToDisplay;

  return <>{ComponentToDisplay && <ComponentToDisplay />}</>;
};
