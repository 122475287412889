interface InputProps {
  className?: string;
}

export const ValidateIconContained = (props: InputProps) => (
  <svg
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="10.1182"
      cy="10.2271"
      r="9"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="2"
    />
    <path
      d="M5.11816 10.7271L9.38132 15.2271L14.1182 6.22705"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
