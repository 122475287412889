import { QIProgressBySession } from '../domain/entities/QIProgressBySession';
import { ProgressGeneralQIResponseHttp } from './types';

export const mapGeneralProgressQIHttpToDomain = (
  progress: ProgressGeneralQIResponseHttp[]
): QIProgressBySession[] => {
  try {
    const adapted: QIProgressBySession[] = progress.map((session) => ({
      collection: session.collection,
      questions: session.questions.map((rawQuestion) => ({
        id: rawQuestion.id,
        num: rawQuestion.num,
        tags: rawQuestion.categories,
        compositionUnit: rawQuestion.composition_unit,
        speTrigram: rawQuestion.specialty1 ?? undefined,
        lastGroupCorrectionIdIncludingQI:
          rawQuestion.last_training ?? undefined,
        qiIdInLastGroupCorrection:
          rawQuestion.last_training_question_id ?? undefined,
        hasBeenDone: Boolean(rawQuestion.last_training),
        isFree: rawQuestion.is_free,
      })),
    }));

    return adapted;
  } catch {
    throw new Error(
      "Error in general progress qi mapping, unexpected response from '/progress/general/qi'"
    );
  }
};
