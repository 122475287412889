import { FC } from 'react';
import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';

import { ResponsiveText } from 'pages/selection/components/ResponsiveText';
import { LCABasicInformations } from 'modules/progress/general/lca/domain/entities/LCABasicInformations';
import { useGetSpecialityInfos } from 'http/selection/specialities-list/connectors';
import { routes } from 'routing/constants';
import { PremiumLockedRessource } from 'components/PremiumLockedRessource';

import { formatGradePointsCoeff } from 'pages/correction/components/CorrectionGroupHeader/points-formatter';
import { PastCorrectionButton } from 'pages/progress/GeneralProgress/components/PastCorrectionButton';

import { CartCheckbox } from '../CartCheckbox';

interface Props {
  lca: LCABasicInformations;
}

export const LcaProgressRow: FC<Props> = ({ lca }) => {
  const spe = useGetSpecialityInfos(lca.speTrigram);

  const navigate = useNavigate();
  const goToCorrection = () => {
    if (lca.lastCorrectionId) {
      navigate({
        pathname: routes.GROUP_CORRECTION,
        search: `?id=${lca.lastCorrectionId}`,
      });
    }
  };

  return (
    <Row isHighlighted={lca.hasBeenDone}>
      <td>{lca.collection}</td>
      <td>LCA{lca.num}</td>
      <td>
        <PremiumLockedRessource ressourceName="Spécialité" isFree={lca.isFree}>
          <ResponsiveText text={spe?.name} shortText={spe?.compressed_name} />
        </PremiumLockedRessource>
      </td>
      <td>{lca.articleTitle}</td>
      <td>
        {formatGradePointsCoeff({
          grade: lca.lastGrade ?? null,
          totalCoeff: lca.denom ?? 1,
        })}
      </td>
      <td>
        <PremiumLockedRessource
          ressourceName="Médiane nationale"
          isFree={lca.isFree}
        >
          {formatGradePointsCoeff({
            grade: lca.median ?? null,
            totalCoeff: lca.denom ?? 1,
          })}
        </PremiumLockedRessource>
      </td>
      <td>
        {lca.hasBeenDone && <PastCorrectionButton onClick={goToCorrection} />}
      </td>
      <td>
        <CartCheckbox group={lca} />
      </td>
    </Row>
  );
};

const Row = styled.tr<{ isHighlighted: boolean }>`
  --row-border-radius: 8px;

  td:first-child {
    border-top-left-radius: var(--row-border-radius);
    border-bottom-left-radius: var(--row-border-radius);
  }

  td:last-child {
    border-top-right-radius: var(--row-border-radius);
    border-bottom-right-radius: var(--row-border-radius);
  }

  td {
    background-color: ${({ theme, isHighlighted }) =>
      isHighlighted ? theme.colors.primary.light : 'transparent'};

    padding: 8px 16px;
    ${({ theme }) => theme.typography.body}
  }
`;
