import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FQDecksInfo } from 'http/flash-questions/decks/types';
import { FQMode } from 'pages/flash-questions/types';
import { RootState } from 'store';
import {
  getDeck,
  getCardToDo,
  getCorrection,
} from 'store/actions/fqCurrentActions';
import { Status } from 'store/types';
import { FQCardCorrection } from 'types/correction.type';
import { FlashCard, FlashDeck, DoFQCardType } from 'types/flash.type';
import { SaveQuestionQZPRequestType } from 'http/training/save-question-qzp/types';
export const HTTP_KEYS = ['card', 'deck', 'correction'] as const;

export interface FqCurrentState {
  selectedAnswers: Record<number, boolean>;
  singleAnswer: {
    id: number;
    answer: string;
  };
  qzpAnswer: SaveQuestionQZPRequestType | null;
  isCorrectionRevealed: boolean;
  mode: FQMode;
  chosenDeckInfos: FQDecksInfo | null;
  card: (FlashCard & DoFQCardType) | null;
  deck: FlashDeck | null;
  correction: FQCardCorrection | null;
  status: Record<typeof HTTP_KEYS[number], Status>;
  errors: Record<typeof HTTP_KEYS[number], string>;
}

const initialState: FqCurrentState = {
  selectedAnswers: {},
  singleAnswer: { id: 0, answer: '' },
  qzpAnswer: null,
  isCorrectionRevealed: false,
  mode: FQMode.DECKLIST,
  chosenDeckInfos: null,
  card: null,
  deck: null,
  correction: null,
  status: {
    card: Status.IDLE,
    correction: Status.IDLE,
    deck: Status.IDLE,
  },
  errors: {
    card: '',
    correction: '',
    deck: '',
  },
};

export const fqCurrentSlice = createSlice({
  name: 'fqCurrent',
  initialState,
  reducers: {
    toggleAnswer(state, action: PayloadAction<{ id: number }>) {
      state.selectedAnswers[action.payload.id] =
        !state.selectedAnswers[action.payload.id];
    },
    setSingleAnswer(
      state,
      action: PayloadAction<{ id: number; answer: string }>
    ) {
      state.singleAnswer = {
        ...state.singleAnswer,
        id: action.payload.id,
        answer: action.payload.answer,
      };
    },
    setQZPAnswer(state, action: PayloadAction<SaveQuestionQZPRequestType>) {
      state.qzpAnswer = {
        ...state.qzpAnswer,
        id: action.payload.id,
        answers: action.payload.answers,
      };
    },
    revealCorrection(state) {
      state.isCorrectionRevealed = true;
    },
    hideCorrection(state) {
      state.isCorrectionRevealed = false;
    },
    setMode(state, action: PayloadAction<{ mode: FQMode }>) {
      state.mode = action.payload.mode;
    },
    setChosenDeckInfos(state, action: PayloadAction<{ infos: FQDecksInfo }>) {
      state.chosenDeckInfos = action.payload.infos;
    },
    resetStep1(state) {
      state.selectedAnswers = {};
      state.isCorrectionRevealed = false;
    },
    resetForNext(state) {
      state.selectedAnswers = {};
      state.isCorrectionRevealed = false;
      state.card = null;
      state.correction = null;
      state.status.card = Status.IDLE;
      state.status.correction = Status.IDLE;
      state.status.deck = Status.IDLE;
      state.errors.card = '';
      state.errors.correction = '';
      state.errors.deck = '';
    },
    reset: () => initialState,
  },
  extraReducers(builder) {
    builder.addCase('fqCurrent/reset', () => initialState);
    builder.addCase(getCardToDo.pending, (state) => {
      state.status.card = Status.PENDING;
    });
    builder.addCase(getCardToDo.fulfilled, (state, action) => {
      state.status.card = Status.RESOLVED;
      state.card = action.payload;
    });
    builder.addCase(getCardToDo.rejected, (state) => {
      state.status.card = Status.REJECTED;
    });
    builder.addCase(getDeck.pending, (state) => {
      state.status.deck = Status.PENDING;
    });
    builder.addCase(getDeck.fulfilled, (state, action) => {
      state.status.deck = Status.RESOLVED;
      state.deck = action.payload;
    });
    builder.addCase(getDeck.rejected, (state) => {
      state.status.deck = Status.REJECTED;
    });
    builder.addCase(getCorrection.pending, (state) => {
      state.status.correction = Status.PENDING;
    });
    builder.addCase(getCorrection.fulfilled, (state, action) => {
      state.status.correction = Status.RESOLVED;
      state.correction = action.payload;
      state.isCorrectionRevealed = true;
    });
    builder.addCase(getCorrection.rejected, (state) => {
      state.status.correction = Status.REJECTED;
    });
  },
});

// SELECTORS
const selectFqCurrent = (state: RootState) => state.fqCurrent;

export const selectIsCorrectionRevealed = createSelector(
  selectFqCurrent,
  (fqCurrent) => fqCurrent.isCorrectionRevealed
);

export const selectChosenDeckInfos = createSelector(
  selectFqCurrent,
  (fqCurrent) => fqCurrent.chosenDeckInfos
);

export const selectCardStatus = createSelector(
  selectFqCurrent,
  ({ status }) => status.card
);

export const selectDeckStatus = createSelector(
  selectFqCurrent,
  ({ status }) => status.deck
);

export const selectCorrectionStatus = createSelector(
  selectFqCurrent,
  ({ status }) => status.correction
);

export const selectSelectedAnswers = createSelector(
  selectFqCurrent,
  (fqCurrent) => fqCurrent.selectedAnswers
);

export const selectMode = createSelector(selectFqCurrent, ({ mode }) => mode);
export const selectCard = createSelector(selectFqCurrent, ({ card }) => card);
export const selectDeck = createSelector(selectFqCurrent, ({ deck }) => deck);
export const selectCorrection = createSelector(
  selectFqCurrent,
  ({ correction }) => correction
);
export const selectSelectedAnswerById = createSelector(
  [
    (state: FqCurrentState) => state,
    (_: FqCurrentState, args: { id: number }) => args,
  ],
  (fqCurrent, args) => {
    const { id } = args;
    if (fqCurrent.selectedAnswers[id]) {
      return fqCurrent.selectedAnswers[id];
    }
    return undefined;
  }
);

// Action creators are generated for each case reducer function
export const {
  revealCorrection,
  toggleAnswer,
  setSingleAnswer,
  setQZPAnswer,
  reset,
  setMode,
  setChosenDeckInfos,
  hideCorrection,
  resetStep1,
  resetForNext,
} = fqCurrentSlice.actions;

export const fqCurrentReducer = fqCurrentSlice.reducer;
