import { ProgressBySpecialty } from '../entities/ProgressBySpecialty';

export interface SpeRankInfo {
  trigram: string;
  rank: number;
}

export const sortProgressBySpecialty = <TProgress extends ProgressBySpecialty>(
  progressesBySpecialty: TProgress[] | undefined,
  speInfos: SpeRankInfo[]
): TProgress[] | undefined => {
  if (progressesBySpecialty === undefined) {
    return undefined;
  }

  return progressesBySpecialty.sort((spe1, spe2) => {
    const spe1Rank =
      speInfos.find((spe) => spe.trigram === spe1.speTrigram)?.rank ?? Infinity;
    const spe2Rank =
      speInfos.find((spe) => spe.trigram === spe2.speTrigram)?.rank ?? Infinity;

    return spe1Rank - spe2Rank;
  });
};
