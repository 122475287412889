import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';

import { routes } from 'routing/constants';
import { resetAllReducers } from 'store/actionsGroup/resetAllReducers';
import { logout } from 'auth/loginFunctions';

export const Logout = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  useEffect(() => {
    queryClient.clear();
    resetAllReducers(dispatch);
    logout();
    toast.success('Vous avez bien été déconnecté.');
  }, [dispatch, queryClient]);

  return <Navigate to={routes.LOGIN} />;
};
