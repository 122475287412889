import { FC } from 'react';
import styled from 'styled-components/macro';

import { device } from 'helpers/css/responsive';

interface Props {
  text?: string;
  shortText?: string;
}

export const ResponsiveText: FC<Props> = ({ text, shortText }) => {
  return (
    <>
      <LargeTableOnly>{text}</LargeTableOnly>
      <SmallerScreensOnly>{shortText}</SmallerScreensOnly>
    </>
  );
};

const Text = styled.span`
  font-size: inherit;
  font-weight: inherit;
`;

const LargeTableOnly = styled(Text)`
  display: none;

  @media ${device.largeTable} {
    display: inline;
  }
`;

const SmallerScreensOnly = styled(Text)`
  @media ${device.largeTable} {
    display: none;
  }
`;
