import { FC } from 'react';

import { Switch } from 'ui/Switch';

import { OptionLayout } from './OptionLayout';
import { Props as OptionLayoutProps } from './OptionLayout';
import { UserOptions } from 'modules/user-options/domain/entities/UserOptions';

interface Props extends OptionLayoutProps {
  preferences: UserOptions;
  field: keyof UserOptions;
  updateUserPreference: (modifiedOption: Partial<UserOptions>) => void;
}

export const SwitchOption: FC<Props> = ({
  preferences,
  field,
  updateUserPreference,
  ...optionLayoutProps
}) => {
  return (
    <OptionLayout {...optionLayoutProps} canWrap={false}>
      <Switch
        checked={preferences[field] as boolean}
        onChange={(checked) => updateUserPreference({ [field]: checked })}
      />
    </OptionLayout>
  );
};
