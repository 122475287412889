import { useQueries, useQuery } from 'react-query';

import { SimulatedRankParams } from './types';
import { getSimulatedRank } from './api';

export const USE_GET_RANG_LIMIER_KEY = 'useGetRangLimierKey';

export const useGetSimulatedRank = (
  params: SimulatedRankParams,
  enabled: boolean
) => {
  return useQuery(
    [USE_GET_RANG_LIMIER_KEY, params],
    () => getSimulatedRank(params),
    {
      enabled,
    }
  );
};

export const useGetSimulatedRanks = (params: SimulatedRankParams[]) => {
  return useQueries(
    params.map((body) => {
      return {
        queryKey: [USE_GET_RANG_LIMIER_KEY + '_' + body.year, body],
        queryFn: () => getSimulatedRank(body),
        enabled: !!body,
      };
    })
  );
};
