import { ShouldShowMedian } from '../domain/entities/UserOptions';
import { useGetUserOptions } from './useGetUserOptions';

export const useShouldShowMedian = (hasItemBeenDone: boolean): boolean => {
  const { data: userOptions } = useGetUserOptions();
  const shouldShowMedianPreference: ShouldShowMedian =
    userOptions?.shouldShowMedian ?? 'never';

  const shouldShowMedian =
    shouldShowMedianPreference === 'always' ||
    (shouldShowMedianPreference === 'whenDone' && hasItemBeenDone);

  return shouldShowMedian;
};
