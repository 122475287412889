import { FC, useMemo } from 'react';
import styled from 'styled-components/macro';

import { adaptQROCCorrectionData } from './qrocDataAdapter';
import { CheckDefault } from 'pages/correction/components/icons/CheckDefault';
import { CrossDefault } from 'pages/correction/components/icons/CrossDefault';
import { CheckFull } from 'pages/correction/components/icons/CheckFull';
import { QrocAnswerContainer } from '../components/QrocAnswerContainer';
import { CorrectionQuestion } from 'types/correction.type';

interface Props {
  questionData: CorrectionQuestion;
}

export const QROCCorrectionBlock: FC<Props> = ({ questionData }) => {
  const correctionData = useMemo(
    () => adaptQROCCorrectionData(questionData),
    [questionData]
  );

  const tableData: { headers: number[]; rows: string[][] } = useMemo(() => {
    const sortedHeaders: number[] = [1, 0.5];
    const sortedRows: string[][] = [[], []];

    correctionData.expectedAnswers.forEach((currentValue) => {
      const keywords = currentValue.keywords.join(' ');
      const point = currentValue.point;
      const index = sortedHeaders.indexOf(point);

      sortedRows[index].push(keywords);
    });

    return { headers: sortedHeaders, rows: sortedRows };
  }, [correctionData.expectedAnswers]);

  return (
    <Container>
      <Row>
        <IconContainer>
          {correctionData.isUserRight ? <CheckDefault /> : <CrossDefault />}
        </IconContainer>
        <RightSide>
          <PropositionContainer>
            {correctionData.userInput}
          </PropositionContainer>
        </RightSide>
      </Row>
      <ExpectedAnswersContainer>
        <Row>
          <IconContainer>
            <CheckFull />
          </IconContainer>
          <RightSide>
            <RightSideTitle>Réponses possibles attendues : </RightSideTitle>
          </RightSide>
        </Row>
        <AnswerRow>
          {tableData.headers.map((header, i) => {
            if (tableData.rows[i].length === 0) return null;
            return (
              <Column key={header}>
                <QrocAnswerContainer
                  header={header}
                  rows={tableData.rows}
                  index={i}
                />
              </Column>
            );
          })}
        </AnswerRow>
      </ExpectedAnswersContainer>
    </Container>
  );
};

const Container = styled.div`
  margin-right: 26px;
  margin-left: 2px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
`;

const IconContainer = styled.div`
  height: 24px;
  width: 24px;
  margin: 12px 8px;
  flex-shrink: 0;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex-grow: 1;
`;

const RightSideTitle = styled.div`
  ${({ theme }) => theme.typography.h2};
  margin: auto 0px;
`;

const PropositionContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.light};
  border-radius: 8px;
  padding: 12px 16px;
  min-height: 48px;
`;

const ExpectedAnswersContainer = styled.div`
  margin-top: 18px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 8px;
`;

const AnswerRow = styled.div`
  display: flex;
  margin-left: 40px;
  width: auto;
  gap: 16px;
`;
