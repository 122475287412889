import { ColumnHeaderWrapper } from 'components/Filters/ColumnHeaderWrapper';
import { CheckboxHeader } from 'pages/selection/TableHeaders/CheckboxHeader';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { Table, Thead, Tr, Th, Tbody } from 'ui/TableElements';
import { QIRow } from './QIRow';

export const ReadonlyQITable = () => {
  const rows = useSelector(
    (state: RootState) => state.qiTable.selectedItemsInfos
  );

  return (
    <Table>
      <Thead>
        <Tr>
          <Th>
            <CheckboxHeader pageItemInfos={rows} />
          </Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>Session</ColumnHeaderWrapper>
          </Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>N°</ColumnHeaderWrapper>
          </Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>Spécialités</ColumnHeaderWrapper>
          </Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>Items</ColumnHeaderWrapper>
          </Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>Catégories</ColumnHeaderWrapper>
          </Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>
              Dernier entraînement
            </ColumnHeaderWrapper>
          </Th>
          <Th>
            <ColumnHeaderWrapper isReadOnly>Type R2C</ColumnHeaderWrapper>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {rows.map((rowData, index) => (
          <QIRow rowData={rowData} index={index} key={rowData.id} />
        ))}
      </Tbody>
    </Table>
  );
};
