import styled from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { device } from 'helpers/css/responsive';
import { RootState } from 'store';
import { routes } from 'routing/constants';
import { updateSelectedTable } from 'store/slices/selectionSlice';
import { Button } from 'ui/Button';

import { DPTable } from './tables/DP/DPTable';
import { QITable } from './tables/QI/QITable';
import { LCATable } from './tables/LCA/LCATable';
import { ShowSelectedItemsProvider } from './showSelectedItemsContext';
import { useGetCartEstimatedDuration } from './hooks/useGetCartEstimatedDuration';
import { SearchbarProvider } from './components/Searchbar/SearchbarContext';
import { Searchbar } from './components/Searchbar';
import { ResetSelectionButton } from './components/ResetSelectionButton';
import { ResetFiltersButton } from './components/ResetFiltersButton';
// import { GenerateRandomSelectionButton } from './components/GenerateRandomSelectionButton';
import { START_QUERY_PARAM } from 'pages/start/Start';
import { ShowDoneFilter } from './components/ShowDoneFilter';
import { ShowFreeFilter } from './components/ShowFreeFilter';
import { SelectionType } from './components/SelectionType';

export type SelectionTables = 'DP' | 'QI' | 'LCA';
// test notion

export const Selection = () => {
  const dispatch = useDispatch();

  const selectedTable = useSelector(
    (state: RootState) => state.selection.selectedTable
  );

  const setSelectedTable = (table: SelectionTables) => {
    dispatch(updateSelectedTable(table));
  };

  const getCartEstimatedDuration = useGetCartEstimatedDuration();

  return (
    <SearchbarProvider>
      <Container>
        <PageLayout>
          <TitleContainer>
            <h1>Sélection</h1>

            <Searchbar />
          </TitleContainer>
          <Header>
            <LeftOptions>
              <SelectionType
                selectedTable={selectedTable}
                setSelectedTable={setSelectedTable}
              />

              <ResetButtonsContainer>
                <div />
                <ResetSelectionButton />
                <ResetFiltersButton />
              </ResetButtonsContainer>
            </LeftOptions>

            <div>Temps estimé : {getCartEstimatedDuration(selectedTable)}</div>
            {/* TODO : Réactiver après refonte design */}
            {/* <GenerateRandomSelectionButton /> */}
          </Header>

          <ShowFreeFilter />
          <ShowDoneFilter />

          <ShowSelectedItemsProvider>
            {selectedTable === 'DP' && <DPTable />}

            {selectedTable === 'QI' && <QITable />}

            {selectedTable === 'LCA' && <LCATable />}
          </ShowSelectedItemsProvider>

          <ActionsRow>
            <UnstyledLink
              to={{
                pathname: routes.START,
                search: `?${START_QUERY_PARAM}=${
                  selectedTable === 'LCA' ? 'LCA' : 'DPQI'
                }`,
              }}
            >
              <ValidateButton type="button">
                Valider la sélection
              </ValidateButton>
            </UnstyledLink>
          </ActionsRow>
        </PageLayout>
      </Container>
    </SearchbarProvider>
  );
};

const Container = styled.div`
  padding: 12px;
  display: flex;
  justify-content: center;

  @media ${device.largeTable} {
    padding: 32px 38px;
  }
`;

const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1400px;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media ${device.desktop} {
    padding-right: 30px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 28px;
  margin-bottom: 32px;
  padding: 0 30px;
`;

const ActionsRow = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;

  position: sticky;
  bottom: 1.5rem;

  @media ${device.largeTable} {
    bottom: 32px;
  }
`;

const ValidateButton = styled(Button)`
  --shadow-color: 0deg 0% 63%;
  --shadow-elevation-medium: 0.3px 0.5px 0.7px hsl(var(--shadow-color) / 0.36),
    0.8px 1.6px 2px -0.8px hsl(var(--shadow-color) / 0.36),
    2.1px 4.1px 5.2px -1.7px hsl(var(--shadow-color) / 0.36),
    5px 10px 12.6px -2.5px hsl(var(--shadow-color) / 0.36);
  box-shadow: var(--shadow-elevation-medium);
`;

const UnstyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;

const ResetButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 12px;
`;

const LeftOptions = styled.div`
  display: flex;
  gap: 12px;

  @media ${device.desktop} {
    gap: 40px;
  }
`;
