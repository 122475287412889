import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';
import { useCanAddToCart } from 'pages/selection/hooks/useCanAddToCart';
import { RootState } from 'store';
import { SelectionQIResult } from 'http/selection-qi/types';
import { toggleItemSelection } from 'store/slices/qiTableSlice';
import { Checkbox } from 'ui/Checkbox';
import { SpeQI } from 'modules/progress/detailed/spe/domain/entities/SpeQI';

interface Props {
  question: SpeQI;
}

export const CheckboxCell: FC<Props> = ({ question }) => {
  const dispatch = useDispatch();
  const canAddToCart = useCanAddToCart();
  const isUserFree = useIsUserFree();

  const selectedItems = useSelector(
    (state: RootState) => state.qiTable.selectedItemsInfos
  );

  const [isSelected, setIsSelected] = useState<boolean>(
    selectedItems.map((item) => item.id).includes(question.id)
  );

  useEffect(() => {
    setIsSelected(selectedItems.map((item) => item.id).includes(question.id));
  }, [question, selectedItems]);

  const toggleSelection = () => {
    if (!isSelected) {
      const canAdd = canAddToCart('QI');
      if (!canAdd) return;
    }
    dispatch(toggleItemSelection(adaptQIToSelectionQIResult(question)));
  };

  return (
    <Checkbox
      checked={isSelected}
      onChange={toggleSelection}
      disabled={!question.isFree && isUserFree}
    />
  );
};

export const adaptQIToSelectionQIResult = (qi: SpeQI): SelectionQIResult => {
  return {
    categories: [],
    coefficient: 1,
    id: qi.id,
    is_free: qi.isFree,
    knowledge: qi.knowledge,
    item: qi.item ?? null,
    num: qi.num.toString(),
    composition_unit: qi.composition_unit,
    number_of_questions: 1,
    collection: qi.collection,
    specialty1: qi.specialtyTrigram ?? null,
    specialty2: null,
    type: 'QI',
    grade: qi.lastGrade,
    last_training: qi.previousTrainings?.[0]?.groupCorrectionId ?? null,
    last_training_date: qi.previousTrainings?.[0]?.date.toISOString() ?? null,
  };
};
