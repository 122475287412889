export const ThemeSwitchSun = () => (
  <svg viewBox="0 0 33 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x={11.269}
      y={11.254}
      width={13.231}
      height={13.737}
      rx={6.615}
      stroke="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.096 23.625A5.891 5.891 0 0 0 20.183 18a5.891 5.891 0 0 0-4.087-5.625 5.785 5.785 0 0 1 1.752-.27c3.224 0 5.838 2.64 5.838 5.895 0 3.256-2.614 5.895-5.838 5.895-.61 0-1.199-.095-1.752-.27Z"
      fill="currentColor"
    />
    <path
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      d="M17.942 31v-3.895M17.942 8.895V5"
    />
    <path
      transform="scale(.98238 1.01732) rotate(45 -5.783 14.3)"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      d="M1-1h3.794"
    />
    <path
      transform="scale(.98238 1.01732) rotate(-45 39.044 3.244)"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      d="M1-1h3.794"
    />
    <path
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      d="M8.692 19H5"
    />
  </svg>
);
