import { useQuery } from 'react-query';
import { GroupHistoryParams } from '../domain/entities/GroupHistoryParams';
import { getGroupHistory } from '../domain/useCases/getGroupHistory';

export const USE_GET_GROUP_HISTORY_KEY = 'useGetGroupHistoryKey';

export const useGetGroupHistory = (params: GroupHistoryParams) => {
  return useQuery([USE_GET_GROUP_HISTORY_KEY, params], () =>
    getGroupHistory(params)
  );
};
