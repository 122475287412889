import { FC, useState } from 'react';
import { ArrowUp } from 'pages/correction/components/icons/ArrowUp';
import { ArrowDown } from 'pages/correction/components/icons/ArrowDown';
import {
  CardsContainerHeader,
  GlobalContainer,
  SmallSectionTitle,
} from './Global';
import styled from 'styled-components';
import { useHomeInfoContext } from '../context/homeInfoContext';
import { SpecialtiesCards } from '../SpecialtiesView/SpecialtiesCards';
import { useGetHomeFavoriteItems } from '../hooks/useGetItems';
import { LoaderWithText } from 'components/Loader/LoaderWithText';
import { useGetHomeFavoriteSpecialties } from '../hooks/useGetSpecialties';
import { ItemsCards } from '../Itemsview/ItemsCards';

export const FavoritesTab: FC = () => {
  const { activeTab } = useHomeInfoContext();
  const [isOpen, setIsOpen] = useState(true);
  const { data: specialities, isLoading: specialitiesLoading } =
    useGetHomeFavoriteSpecialties();
  const { data: items, isLoading: itemsLoading } = useGetHomeFavoriteItems();

  return (
    <GlobalContainer>
      <CardsContainerHeader
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {isOpen ? <ArrowUp /> : <ArrowDown />}
        <SmallSectionTitle>Mes favoris</SmallSectionTitle>
      </CardsContainerHeader>
      {isOpen && (
        <>
          <>
            {activeTab === 'specialties' ? (
              specialities && !specialitiesLoading ? (
                <SpecialtiesCards specialties={specialities} />
              ) : (
                <LoaderWithText />
              )
            ) : items && !itemsLoading ? (
              <ItemsCards items={items} />
            ) : (
              <LoaderWithText />
            )}
          </>
          {(activeTab === 'specialties' &&
            (!specialities || specialities.results.length === 0)) ||
          (activeTab !== 'specialties' &&
            (!items || items.results.length === 0)) ? (
            <NoDataMessage>
              {activeTab === 'specialties'
                ? "Vous n'avez encore ajouté aucune spécialité à vos favoris. Explorez toutes les spécialités et cliquez sur l'icône d'étoile pour ajouter vos préférées."
                : "Vous n'avez encore ajouté aucun item à vos favoris. Explorez tous les items et cliquez sur l'icône d'étoile pour ajouter vos préférés."}
            </NoDataMessage>
          ) : null}
        </>
      )}
    </GlobalContainer>
  );
};

const NoDataMessage = styled.div`
  grid-column: 1 / -1;
  color: ${({ theme }) => theme.colors.inactive};
`;
