import { FC } from 'react';
import { MenuIconProps } from './types';

export const MenuCompass: FC<MenuIconProps> = ({ isFilled }) => {
  return isFilled ? (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={12} cy={12} r={11} stroke="currentColor" strokeWidth={2} />
      <path
        d="m10.418 10.933 5.603-5.268-2.785 7.169L7.633 18.1l2.785-7.168Z"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M12 3v2M12 19v2M19 12h2M3 12h2"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="m13.5 12.75-3-1.875-2.625 6.75L13.5 12.75Z"
        fill="currentColor"
      />
    </svg>
  ) : (
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={12} cy={12} r={11.5} stroke="currentColor" />
      <path
        d="m10.418 10.933 5.603-5.268-2.785 7.169L7.633 18.1l2.785-7.168Z"
        stroke="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M12 2.25v1.5M12 20.25v1.5M20.25 12h1.5M2.25 12h1.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="m13.5 12.75-3-1.875-2.625 6.75L13.5 12.75Z"
        fill="currentColor"
      />
    </svg>
  );
};
