import { stringify } from 'qs';

/**
 * Spread this param in your GET request options when you
 * query parameters.
 *
 * Tt is necesssary when the query params
 * has arrays in it.
 */
export const queryParamsSerizalizer = {
  paramsSerializer: (params: any) => {
    return stringify(params, { arrayFormat: 'repeat' });
  },
} as const;
