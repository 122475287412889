import { getAPI, patchAPI } from 'auth';
import { setSentryTags } from 'helpers/application/setSentryTags';
import { UserAccountHttpPatchBody, UserAccountHttpResponse } from './types';

export const getUserAccountPreferencesHttp = async () => {
  const response = await getAPI<UserAccountHttpResponse>('/auth/user');
  setSentryTags(response.data);
  return response.data;
};

export const patchUserAccountPreferencesHttp = async (
  body: UserAccountHttpPatchBody
) => {
  const response = await patchAPI<UserAccountHttpResponse>('/auth/user', body);
  setSentryTags(response.data);
  return response.data;
};
