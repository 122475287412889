import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { routes } from 'routing/constants';

interface Props {}

export const PaymentFailure: FC<Props> = () => {
  const navigate = useNavigate();

  useEffect(() => {
    toast.warning(
      'Le paiement a échoué, vous ne vous êtes pas abonné.e à Asclepia'
    );
    navigate(routes.NEW_HOME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};
