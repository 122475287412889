import {
  CardsContainerHeader,
  // FlexCenter,
  SectionTitle,
} from 'pages/newHome/ui/Global';
import { FC, useMemo } from 'react';
// import { Button } from 'ui/Button';
import BlueCard from 'ui/Cards/BlueCard';
import { IconContainer } from 'ui/icons/IconContainer';
import { MenuStart } from 'ui/icons/MenuIcons/MenuStart';
import { QFPieChart, SpeStatPieData } from './QFPieChart';
import styled from 'styled-components';
import { Dot } from 'pages/concours-blanc/components/StatisticsPage/Stats/RepartitionLca/RepartitionLca';

interface HomeQFsProps {
  data: SpeStatPieData[];
}
export const HomeQFs: FC<HomeQFsProps> = ({ data }) => {
  const totalQuestions = useMemo(() => {
    return data.reduce((acc, { value }) => acc + value, 0);
  }, [data]);

  const labels: SpeStatPieData[] = [...data];

  return (
    <BlueCard $bigPadding fullWidth gap="1.5rem" justify="space-between">
      <CardsContainerHeader>
        <IconContainer big>
          <MenuStart isFilled />
        </IconContainer>
        <SectionTitle bold>Questions flash</SectionTitle>
      </CardsContainerHeader>
      <QFPieChart data={data} nbQuestions={`${totalQuestions}`} />
      <LabelSection>
        {labels
          .sort((a, b) => (a?.order && b?.order ? a?.order - b?.order : 0))
          .map(({ name, color }) => (
            <FlexDiv key={name}>
              <Dot fillColor={color} />
              <Label>{name}</Label>
            </FlexDiv>
          ))}
      </LabelSection>
      {/* TODO REACTIVER  QUAND REVISION ECRAN QF TERMINÉ ET PAQUETS DISPOS PAR SPÉ / ITEMS */}
      {/* <FlexCenter>
        <Button>Jouer le paquet de questions flash</Button>
      </FlexCenter> */}
    </BlueCard>
  );
};

const FlexDiv = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;

const LabelSection = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 500px;
  margin: 0 auto 1rem;
`;

const Label = styled.p`
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.inactive};
  margin: 0;
`;
