import styled from 'styled-components';
import { useEffect, useState } from 'react';
import {
  QuestionsList,
  QuestionGroupType,
} from 'pages/new_training/components/QuestionsList/QuestionsList';
import { LeftChevron as LeftChevronIcon } from 'ui/icons/LeftChevron';
import {
  TrainingBody,
  TrainingType,
} from 'pages/new_training/components/TrainingBody';
import { TrainingHeader } from 'pages/new_training/components/TrainingHeader';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routing/constants';
import { SubmissionModal } from 'pages/new_training/components/SubmissionModal/SubmissionModal';
import { toast } from 'react-toastify';
import { useSubmitTask } from 'pages/new_training/hooks/saveHooks/useSubmitTask';
import { pauseTimer } from 'http/training/pause-timer/api';
import { useResetProgressCache } from 'modules/progress/shared/hooks/useResetProgressCache';
import { useGetUserOptions } from 'modules/user-options/hooks/useGetUserOptions';
import { useDispatch, useSelector } from 'react-redux';
import { useAtom, useAtomValue } from 'jotai';
import {
  updateSessionsLeftToDo,
  updateScreenToDisplay,
  updateAllDone,
  updateNextTask,
  fetchFormatedSessionsStatus,
} from 'store/slices/ccbSlice';
import { useGetNumberQueryParams } from 'hooks/useGetNumberQueryParam';
import { FormatedCCBSession } from 'http/ccb/type';
import {
  greyScaleAtom,
  panelRightAtom,
  sidebarAtom,
} from 'pages/new_training/atoms';
import { useGetEstimatedDurations } from 'modules/user-options/hooks/useGetEstimatedDurations';
import { useGetTask } from 'pages/new_training/hooks/useGetTask';

export const CCBTraining = () => {
  const navigate = useNavigate();
  const resetProgressCache = useResetProgressCache();
  const dispatch = useDispatch();
  const estimatedDurations = useGetEstimatedDurations();
  const taskId = useGetNumberQueryParams('id');

  const ccbtaskid = useGetNumberQueryParams('id');
  const { data: userOptions } = useGetUserOptions();
  const [timerIsRunning, setTimerIsRunning] = useState<boolean>(false);
  const { sessionsLeftToDo, sessions } = useSelector((state: any) => state.ccb);

  const handleTaskSubmission = async () => {
    if (ccbtaskid && userOptions) {
      dispatch(updateSessionsLeftToDo(sessionsLeftToDo));
      dispatch(updateScreenToDisplay('PostCCB'));
      dispatch(updateNextTask(undefined));
      dispatch(
        fetchFormatedSessionsStatus({
          trainingDuration: userOptions.trainingDuration,
          ucDuration: estimatedDurations.ucDuration,
        })
      );

      setSubmissionModalOpen(false);
      submitTask(ccbtaskid, () => {
        resetProgressCache();
        pauseTimer(ccbtaskid);
      });
      dispatch(updateSessionsLeftToDo(sessionsLeftToDo));
      dispatch(
        fetchFormatedSessionsStatus({
          trainingDuration: userOptions?.trainingDuration,
          ucDuration: estimatedDurations?.ucDuration,
        })
      );

      const isdone =
        sessions.filter(
          (session: FormatedCCBSession) => session.finished === true
        ).length === sessions.length;
      dispatch(updateAllDone(isdone));

      dispatch(updateScreenToDisplay('PostCCB'));
      navigate({ pathname: routes.CONCOURS_BLANC });
    } else {
      toast.error("Aucune épreuve de concours blanc n'est en cours.");
    }
  };

  //if no ccbtaskID error and redirect
  useEffect(() => {
    if (!ccbtaskid) {
      toast.error("Nous n'avons pas trouvé votre épreuve de concours blanc");
      navigate(routes.CONCOURS_BLANC);
    }
  }, [navigate, ccbtaskid]);

  const { task } = useGetTask(taskId, true);
  const submitTask = useSubmitTask();

  const [timerParams, setTimerParams] = useState<{
    totalTime: number;
    timerOffset: number;
    enabled: boolean;
  }>({ totalTime: 0, timerOffset: 0, enabled: true });
  const panelRight = useAtomValue(panelRightAtom); // Is the panel on the right or on the left
  const [sidebar, setSidebar] = useAtom(sidebarAtom); // Is the sidebar open or closed
  const [questionGroups, setQuestionGroups] =
    useState<Array<QuestionGroupType>>();
  const [questions, setQuestions] =
    useState<Array<Omit<TrainingType, 'onSave' | 'onModification'>>>();
  const [currentQuestionGroup, setCurrentQuestionGroup] = useState<number>(0);
  const [focusedQuestions, setFocusedQuestions] = useState<Array<number>>();
  const [totalNbQuestions, setTotalNbQuestions] = useState<number>(1);
  const [totalSavedQuestions, setTotalSavedQuestions] = useState<number>(0);
  const [submissionModalOpen, setSubmissionModalOpen] =
    useState<boolean>(false);
  const greyScale = useAtomValue(greyScaleAtom);

  // setup Task
  useEffect(() => {
    if (task?.id && userOptions) {
      if (task.finished || task.timer.remaining_time <= 0) {
        toast.info("L'épreuve de concours blanc demandée est déjà terminée");
        dispatch(updateScreenToDisplay('PostCCB'));
        dispatch(updateSessionsLeftToDo(sessions));

        dispatch(
          fetchFormatedSessionsStatus({
            trainingDuration: userOptions.trainingDuration,
            ucDuration: estimatedDurations.ucDuration,
          })
        );
        navigate({ pathname: routes.CONCOURS_BLANC });
      }

      setQuestionGroups(
        task.training_groups.map((el) => {
          if (el.custom_qi) {
            return {
              name: `QI${el.order}`,
              nbQuestions: el.training_questions.length,
              isQI: true,
              currentQuestion: el.training_questions
                .map((question) => question.saved)
                .filter((question) => question).length,
              savedQuestions: el.training_questions.map(
                (question) => question.saved
              ),
              modifiedQuestionIndex: null,
            };
          } else {
            return {
              name: el.group.type + el.order,
              nbQuestions: el.training_questions.length,
              currentQuestion: el.training_questions
                .map((question) => question.saved)
                .filter((question) => question).length,
              isQI: false,
              savedQuestions: el.training_questions.map(
                (question) => question.saved
              ),
              modifiedQuestionIndex: null,
            };
          }
        })
      );

      setTotalSavedQuestions(
        task.training_groups.reduce(
          (total, questionGroup) =>
            (total += questionGroup.training_questions
              .map((question) => question.saved)
              .filter((el) => el).length),
          0
        )
      );

      calculateInitialProgress();

      setFocusedQuestions(
        task.training_groups.map((el) => (el.custom_qi ? 0 : -1))
      );

      const getQuestionName = (
        el: typeof task.training_groups[0],
        index: number
      ) => {
        if (el.custom_qi) {
          return 'QI' + el.order;
        }

        // if (userOptions.shouldRandomizeTrainingOrder) {
        //   return el.group.type + ' ' + currentQuestionGroupNumber++;
        // }

        return el.group.type + ' ' + el.group.num;
      };

      setQuestions(
        task.training_groups.map((el, index) => {
          return {
            name: getQuestionName(el, index),
            isQI: el.custom_qi,
            statement: el.custom_qi ? '' : el.group.statement,
            questions: el.training_questions.map((question) => ({
              id: question.id,
              saved: question.saved,
              type: question.question.type,
              collection: question.question.collection,
              questionStatement: question.question.statement,
              count_qrp: question.question.count_qrp || 0,
              training_items_qcm: question.training_items_qcm || [],
              training_items_qrpl: question.training_items_qrpl || [],
              training_item_qroc: question.training_item_qroc,
              training_items_tcs: question.training_items_tcs,
              training_item_qzp: question.training_item_qzp,
              tcs_header: question.tcs_header,
            })),
          };
        })
      );

      setCurrentQuestionGroup(0);
      setTimerParams({
        totalTime: task.timer.total_time,
        timerOffset: task.timer.total_time - task.timer.remaining_time,
        enabled: true,
      });
    }
    // eslint-disable-next-line
  }, [task, userOptions]);

  const calculateInitialProgress = () => {
    if (task) {
      const nbQuestions = task?.training_groups.reduce(
        (previousState, currentValue) =>
          previousState + currentValue.training_questions.length,
        0
      );
      setTotalNbQuestions(nbQuestions);

      const nbSaved = task?.training_groups.reduce(
        (previousState, currentValue) =>
          previousState +
          currentValue.training_questions
            .map((el) => el.saved)
            .filter((el) => el).length,
        0
      );

      return Math.min(Math.ceil((nbSaved / nbQuestions) * 100), 100);
    } else {
      return 0;
    }
  };

  const setFocusedQuestionGroup = (questionGroupIndex: number) => {
    setCurrentQuestionGroup(questionGroupIndex);
  };

  const onSave = (
    questionGroupIndex: number,
    questionGroupSavedQuestions: Array<boolean>
  ) => {
    const lastQuestionSavedIndex =
      questionGroupSavedQuestions.lastIndexOf(true);
    if (questionGroups) {
      const newQuestionGroups = [...questionGroups];
      newQuestionGroups[questionGroupIndex] = {
        ...newQuestionGroups[questionGroupIndex],
        currentQuestion: lastQuestionSavedIndex + 1,
        modifiedQuestionIndex: null,
        savedQuestions: questionGroupSavedQuestions,
      };
      setQuestionGroups(newQuestionGroups);
      setTotalSavedQuestions(
        newQuestionGroups.reduce(
          (total, questions) =>
            (total += questions.savedQuestions.filter((el) => el).length),
          0
        )
      );
    }
  };

  const handleQuestionChange = (questionIndex: number) => {
    if (focusedQuestions) {
      const newFocusedQuestions = [...focusedQuestions];
      newFocusedQuestions[currentQuestionGroup] = questionIndex;
      setFocusedQuestions(newFocusedQuestions);
    }
  };

  const handleQuestionModification = (questionIndex: number) => {
    if (questionGroups) {
      const newQuestionGroups = [...questionGroups];
      newQuestionGroups[currentQuestionGroup].currentQuestion = questionIndex;
      // newQuestionGroups[currentQuestionGroup].modifiedQuestionIndex = questionIndex;
      setQuestionGroups(newQuestionGroups);
    }
  };

  const handleSidebar = () => {
    setSidebar(!sidebar);
  };

  const untreatedQuestions = totalNbQuestions - totalSavedQuestions;
  const current = questions?.[currentQuestionGroup];

  return (
    // TODO check if we can remove the props
    <Page isQZPModalOpen={false} isGreyScaleEnabled={greyScale}>
      <StyledTrainingHeader
        totalNbQuestions={totalNbQuestions}
        totalSavedQuestions={totalSavedQuestions}
        timerParams={timerParams}
        taskId={ccbtaskid!}
        onTimerEnd={handleTaskSubmission}
        onFinish={() => setSubmissionModalOpen(true)}
        setTimerIsRunning={setTimerIsRunning}
        isCCB={true}
      />
      <PageBody right={panelRight}>
        <div
          style={{
            position: 'relative',
            height: '100%',
            display: 'flex',
          }}
        >
          {panelRight && (
            // TODO fix conflict in sidebar type to solve console issue
            <ChevronContainer onClick={handleSidebar}>
              <RightChevronWrapper sidebar={sidebar}>
                <RightChevron sidebar={sidebar} />
              </RightChevronWrapper>
            </ChevronContainer>
          )}

          <QuestionsListContainer
            style={{
              width: sidebar ? '270px' : 0,
              transition: 'all 0.5s ease',
            }}
          >
            <QuestionsList
              selectedQuestionGroup={currentQuestionGroup}
              questionGroups={questionGroups}
              onQuestionChange={handleQuestionChange}
              onQuestionGroupChange={setFocusedQuestionGroup}
              question={current}
            />
          </QuestionsListContainer>

          {!panelRight && (
            <ChevronContainer
              onClick={handleSidebar}
              style={{ transition: 'all 0.5s ease' }}
            >
              <LeftChevronWrapper sidebar={sidebar}>
                <LeftChevron sidebar={sidebar} />
              </LeftChevronWrapper>
            </ChevronContainer>
          )}
        </div>
        {task && questions ? (
          questions.map((el, index) => {
            return (
              <TrainingBody
                show={index === currentQuestionGroup}
                key={index}
                statement={el.statement}
                name={el.name}
                questions={el.questions}
                onSave={(el) => onSave(index, el)}
                focusedQuestionIndex={
                  focusedQuestions ? focusedQuestions[index] : 0
                }
                isQI={el.isQI}
                onModification={handleQuestionModification}
                visible={timerParams.enabled ? timerIsRunning : true}
              />
            );
          })
        ) : (
          <TrainingBody
            show={true}
            statement=""
            name="Loading..."
            questions={[]}
            isQI={false}
          />
        )}
      </PageBody>
      <SubmissionModal
        isOpen={submissionModalOpen}
        onCancel={() => setSubmissionModalOpen(false)}
        onAccept={handleTaskSubmission}
        title="Soumission définitive"
        text={
          'En soumettant cette épreuve, <br /> vous la rendez de manière <b style="color:#F3002C; font-family:\'freesans\';">définitive</b>. <br /><br />' +
          '<b style="font-family:\'freesans\';">Il reste ' +
          untreatedQuestions +
          `${
            untreatedQuestions > 1
              ? ' questions non traitées.'
              : ' question non traitée.'
          }` +
          '</b>'
        }
        confirmationButtonColor="#ffc107"
        confirmationButtonHoverColor="#a37b01"
      />
    </Page>
  );
};

const Page = styled.div<{
  isQZPModalOpen: boolean;
  isGreyScaleEnabled: boolean;
}>`
  height: 100%;
  width: 100%;
  margin: 0;
  box-sizing: border-box;
  color: #212529;
  background-color: #ffffff;
  overflow-x: auto;
  filter: ${({ isGreyScaleEnabled }) =>
    isGreyScaleEnabled ? 'grayscale(1)' : 'none'};

  // Used to avoid scroll anywhere else than inside the questionGroup components
  position: ${({ isQZPModalOpen }) => (isQZPModalOpen ? 'auto' : 'fixed')};
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
`;
const CHEVRON_SIZE = '28px';

const LeftChevronWrapper = styled.div<{ sidebar: boolean }>`
  background: #183876;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding: 6px;
  padding-left: ${({ sidebar }) => (sidebar ? '0px' : '6px')};
  padding-right: ${({ sidebar }) => (sidebar ? '6px' : '0px')};
`;

const RightChevronWrapper = styled.div<{ sidebar: boolean }>`
  background: #183876;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  padding: 6px;
  padding-right: ${({ sidebar }) => (sidebar ? '0px' : '6px')};
  padding-left: ${({ sidebar }) => (sidebar ? '6px' : '0px')};
`;

const ChevronContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTrainingHeader = styled(TrainingHeader)``;

const LeftChevron = styled(LeftChevronIcon)<{ sidebar: boolean }>`
  height: 32px;
  width: ${CHEVRON_SIZE};
  transform: ${({ sidebar }) => (sidebar ? '' : 'rotate(180deg)')};
  color: white;
`;

const RightChevron = styled(LeftChevronIcon)<{ sidebar: boolean }>`
  height: 32px;
  width: ${CHEVRON_SIZE};
  transform: ${({ sidebar }) => (sidebar ? 'rotate(180deg)' : '')};
  color: white;
`;

const PageBody = styled.div<{ right?: boolean }>`
  position: static;
  overflow-y: scroll;
  width: 100%;
  display: flex;
  flex-direction: ${({ right }) => (right ? 'row-reverse' : 'row')};
  justify-content: center;
`;

const QuestionsListContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;
