import { FC } from 'react';
import styled from 'styled-components/macro';

import { Table, Thead, Th, Tbody, Tr } from 'ui/TableElements';

import { TableLoading } from 'pages/selection/components/TableLoading';
import { TableNoResults } from 'pages/selection/components/TableNoResults';
import { ExpandHeader } from './components/ExpandHeader';
import { QuestionGroupRow } from './QuestionGroupRow';
import { RanksHeader } from 'pages/selection/TableHeaders/RanksHeader';
import { ItemsHeader } from 'pages/selection/TableHeaders/ItemsHeader';
import { CategoriesHeader } from 'pages/selection/TableHeaders/CategoriesHeader';
import { CheckboxHeader } from '../../TableHeaders/CheckboxHeader';
import { SessionHeader } from 'pages/selection/TableHeaders/SessionHeader';
import { SpecialitiesHeader } from 'pages/selection/TableHeaders/SpecialitiesHeader';
import { GenericSortHeader } from 'pages/selection/TableHeaders/GenericSortHeader';
import { useGetQuestionGroupTableValues } from './useGetQuestionGroupTableValues';
import { TablePagination } from 'pages/selection/components/TablePagination';
import { ReadonlyGroupTable } from './ReadonlyGroupTable';
import { useShowSelectedItemsContext } from 'pages/selection/showSelectedItemsContext';
import { LastTrainingHeader } from 'pages/selection/TableHeaders/LastTrainingHeader';
import { TypeHeader } from 'pages/selection/TableHeaders/TypeHeader';
import { TableError } from 'pages/selection/components/TableError';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';

const colNumber = 8;

/**
 * Generic table for both LCA & DP tables which are exactly the same
 */
export const QuestionGroupTable: FC = () => {
  const {
    data,
    isLoading,
    isError,
    refetch,
    currentPage,
    handlePageClick,
    pageSize,
    handleNewPageSize,
  } = useGetQuestionGroupTableValues();
  const { shouldShowSelectedItems } = useShowSelectedItemsContext();

  const isUserFree = useIsUserFree();

  return (
    <Container>
      <TableContainer>
        {shouldShowSelectedItems ? (
          <ReadonlyGroupTable />
        ) : (
          <Table>
            <Thead>
              <Tr id="selection-header">
                <Th>
                  <CheckboxHeader pageItemInfos={data?.results ?? []} />
                </Th>
                <ExpandHeader />
                <Th>
                  <SessionHeader />
                </Th>
                <Th>
                  <GenericSortHeader columnName="N°" sortColumn="num" />
                </Th>
                <Th>
                  <SpecialitiesHeader />
                </Th>
                <Th></Th>
                <Th></Th>
                <Th>
                  <LastTrainingHeader />
                </Th>
                <Th>
                  <GenericSortHeader
                    columnName="Médiane"
                    sortColumn="median"
                    disabled={isUserFree}
                  />
                </Th>
              </Tr>
              <SubTr id="selection-subheader">
                <th colSpan={5} />
                <SubTh>
                  <RanksHeader isSub />
                </SubTh>
                <SubTh>
                  <ItemsHeader isSub />
                </SubTh>
                <SubTh>
                  <CategoriesHeader isSub />
                </SubTh>
                <SubTh>
                  <TypeHeader isSub />
                </SubTh>
              </SubTr>
            </Thead>
            <Tbody>
              {isLoading && <TableLoading colNumber={colNumber} />}
              {data &&
                data.results.length > 0 &&
                data.results.map((rowData, index) => (
                  <QuestionGroupRow
                    rowData={rowData}
                    index={index}
                    key={rowData.id}
                  />
                ))}
              {data && data.results.length === 0 && (
                <TableNoResults colNumber={colNumber} />
              )}
              {isError && <TableError colNumber={colNumber} retry={refetch} />}
            </Tbody>
          </Table>
        )}
      </TableContainer>

      {!shouldShowSelectedItems && (
        <TablePagination
          totalCount={data?.count ?? 0}
          currentPage={currentPage}
          handlePageClick={handlePageClick}
          pageSize={pageSize}
          handleNewPageSize={handleNewPageSize}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1400px;

  // WARNING: Do not add 'overflow-x: auto' to add
  // responsiveness to the table because it will cause issues
  // with the menus that will also overflow with a scroll !
  // Our issue happens when you filter the data and there are no results
  // then the header menus will be bigger than the table and overflow
  // Setting 'overflow-x: auto' also sets 'overflow-y' to auto
  // per the W3C spec : https://stackoverflow.com/a/6433475
`;

const TableContainer = styled.div`
  border: ${({ theme }) => theme.colors.primary.main} 2px solid;
  border-radius: 12px;
  width: 100%;
`;

export const SubTr = styled(Tr)`
  th {
    border-bottom: ${({ theme }) => theme.colors.primary.main} 2px solid;
  }
`;

export const SubTh = styled(Th)`
  background-color: ${({ theme }) => theme.colors.background.paper};
`;
