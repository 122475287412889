import { useQueryClient } from 'react-query';

import { USE_GET_DP_PROGRESS_BY_SESSION_KEY } from 'modules/progress/general/dp/hooks/useGetDPProgressBySession';
import { USE_GET_LCA_PROGRESS_KEY } from 'modules/progress/general/lca/hooks/useGetLCAProgress';
import { USE_GET_QI_PROGRESS_BY_SESSION_KEY } from 'modules/progress/general/qi/hooks/useGetQIProgressBySession';

export const useResetProgressCache = () => {
  const queryClient = useQueryClient();

  return () => {
    queryClient.removeQueries(USE_GET_LCA_PROGRESS_KEY);
    queryClient.removeQueries(USE_GET_DP_PROGRESS_BY_SESSION_KEY);
    queryClient.removeQueries(USE_GET_QI_PROGRESS_BY_SESSION_KEY);
  };
};
