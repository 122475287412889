import { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components/macro';

import { buttonReset } from 'helpers/css/button-reset';
import { FileIcon } from 'ui/icons/File';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';

type NativeButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

interface Props extends NativeButtonProps {
  isFree?: boolean;
}

export const PastCorrectionButton: FC<Props> = (buttonProps) => {
  const isUserFree = useIsUserFree();

  const isDataLocked =
    isUserFree && buttonProps.isFree !== undefined && !buttonProps.isFree;

  if (isDataLocked) {
    return null;
  }

  return (
    <Button {...buttonProps}>
      <FileIcon />
    </Button>
  );
};

const Button = styled.button`
  ${buttonReset};
  padding: 4px;
  height: 20px;
  width: 20px;
  display: grid;
  place-items: center;

  border-radius: 4px;
  transition: background-color 100ms ease-in-out;
  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.main};
  }
`;
