// import { useHomeInfoContext } from 'pages/newHome/context/homeInfoContext';
import {
  GlobalContainer,
  Section,
  SectionTitle,
} from 'pages/newHome/ui/Global';
import { AboutTheItem } from '../../components/AboutTheItem';
import { BookInfosContainer } from './BooksInfosContainer';
import { SpecialtyProgress } from './SpecialtyProgress/SpecialtyProgress';
import SpecialtyStats from './SpecialtyStats';

interface Props {
  trigram?: string;
}

export const SingleSpecialityView: React.FC<Props> = ({ trigram }) => {
  return (
    <GlobalContainer maxWidth="1300px" gap="3rem" centered>
      <Section>
        <SectionTitle>Mes statistiques asclepia</SectionTitle>
        <SpecialtyStats />
      </Section>

      <BookInfosContainer />
      <Section>
        <SectionTitle>Mon avancement</SectionTitle>
        <SpecialtyProgress />
      </Section>
      <Section>
        <SectionTitle>À propos de la spécialité</SectionTitle>
        <AboutTheItem />
      </Section>
    </GlobalContainer>
  );
};
