import { useMemo } from 'react';
import { LessonPageParams } from '../domain/entities/LessonPageParams';
import { getLessonPage } from '../domain/useCases/getLessonPage';
import { useGetRawLessons } from './useGetRawLessons';
import { LessonPage } from 'types/lesson.type';

export const useGetLessons = (params: LessonPageParams) => {
  const {
    data: rawLessonPageQuery,
    isLoading,
    isError,
  } = useGetRawLessons(params);

  const lessonPage = useMemo(() => {
    return getLessonPage(rawLessonPageQuery as LessonPage);
  }, [rawLessonPageQuery]);

  return {
    isLoading: isLoading,
    isError: isError,
    data: lessonPage,
  };
};
