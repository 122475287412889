import styled from 'styled-components';
import { CCBPendingTable } from '../../TrainingTable/CCBPendingTable';
import { CCBDoneTable } from '../../TrainingTable/CCBDoneTable';
import { useSelector } from 'react-redux';

export const TrainingList = () => {
  const { sessionsLeftToDo, sessions } = useSelector((state: any) => state.ccb);

  return (
    <Container>
      <h3>Liste des épreuves de la session</h3>
      {sessionsLeftToDo?.length === sessions?.length ? (
        <CCBPendingTable showAll={true} />
      ) : (
        <CCBDoneTable all={true} />
      )}
      <GreyText>
        Les articles de LCA seront accessibles dès que tous les UC auront été
        finalisés, afin d'être consultés avant le début de l'épreuve.
      </GreyText>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const GreyText = styled.p`
  font-weight: 400;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.inactive};
  margin-top: 0;
`;
