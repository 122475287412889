import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { passwordRegex } from 'pages/sign-up/passwordRegex';
import * as yup from 'yup';

import { useGoToAuthConfirmation } from 'pages/auth-confirmation/hooks/useGoToAuthConfirmation';
import { requestChangePasswordConnectedUser } from 'http/change-password-connected-user/api';

interface FormInputs {
  previousPassword: string;
  newPassword1: string;
  newPassword2: string;
}

const formSchema = yup.object({
  previousPassword: yup.string().required(),
  newPassword1: yup
    .string()
    .required('Veuillez renseigner un mot de passe')
    .min(8, 'Le mot de passe doit faire plus de 8 charactères')
    .matches(
      passwordRegex,
      'Le mot de passe doit contenir au moins un chiffre, une lettre majuscule et une lettre minuscule'
    ),
  newPassword2: yup
    .string()
    .required('Veuillez confirmer votre mot de passe')
    .oneOf(
      [yup.ref('newPassword1')],
      'Les deux mots de passe ne sont pas identiques'
    ),
});

export const useChangePasswordConnectedUser = () => {
  const rhf = useForm<FormInputs>({
    resolver: yupResolver(formSchema),
  });

  return {
    ...rhf,
  };
};

export const useChangePasswordConnectedUserSubmit = ({
  onFetch,
  afterFetch,
}: {
  onFetch: () => void;
  afterFetch: () => void;
}) => {
  const goToAuthConfirmation = useGoToAuthConfirmation();

  const onChangeEmailFormSubmit: SubmitHandler<FormInputs> = async (data) => {
    try {
      onFetch();
      await requestChangePasswordConnectedUser(
        data.previousPassword,
        data.newPassword1,
        data.newPassword2
      );
      goToAuthConfirmation({
        title: 'Votre mote de passe a bien été changé',
        subtitle:
          'Quelques minutes peuvent être nécessaire avant que les changements soient appliqués',
      });
    } finally {
      afterFetch();
    }
  };

  return onChangeEmailFormSubmit;
};
