import { SessionRecap } from 'modules/recap/domain/entities/entity';

export const getSessionProgress = (sessionRecap: SessionRecap | undefined) => {
  if (sessionRecap === undefined) {
    return undefined;
  }

  const percent = (sessionRecap.numerator / sessionRecap.denom) * 100;

  return Math.floor(percent);
};
