import { useQuery } from 'react-query';

import { getStaticPage } from './api';
import { StaticPageLabel } from './types';
import { MS_IN_AN_HOUR } from 'constants/date';

export const useGetStaticPage = (params: StaticPageLabel) => {
  const query = useQuery(['staticPage', params], () => getStaticPage(params), {
    staleTime: MS_IN_AN_HOUR,
    cacheTime: MS_IN_AN_HOUR,
  });

  return query;
};
