import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { device } from 'helpers/css/responsive';
import { Options, Option } from 'types/options.type';
import { UnlockIcon } from 'ui/icons/UnlockIcon';
import { LockIcon } from 'ui/icons/Lock';
import Tippy from '@tippyjs/react';

interface TabSwitchProps<T extends string> {
  tabOptions: Options;
  activeTab: T;
  handleTabClick: (tabName: T) => void;
  updatePreferredTab?: (tab: T) => void;
  enableUnlock?: boolean;
  userPreferredTab?: T;
}

export const UnderlinedTabSwitch = <T extends string>({
  tabOptions,
  activeTab,
  handleTabClick,
  updatePreferredTab,
  enableUnlock = false,
  userPreferredTab,
}: TabSwitchProps<T>) => {
  const [hoveredTab, setHoveredTab] = useState<T | null>(null);

  const handleUnlock = (tab: T, event: React.MouseEvent) => {
    event.stopPropagation();
    updatePreferredTab?.(tab);
  };

  const renderLockIcon = (tab: T) => (
    <LockIconWrapper>
      <StyledLockIcon />
    </LockIconWrapper>
  );

  const renderUnlockIcon = (tab: T) => (
    <Tippy
      content={
        'Astuce : Verrouille cette vue pour un accès direct lors de ta prochaine visite'
      }
    >
      <UnlockIconWrapper onClick={(e) => handleUnlock(tab, e)}>
        <StyledUnlockIcon />
      </UnlockIconWrapper>
    </Tippy>
  );

  return (
    <SwitchContainer id="switcher">
      {tabOptions.map((tabOption: Option) => (
        <SwitchButton
          key={tabOption.value}
          isActive={activeTab === tabOption.value}
          onClick={() => handleTabClick(tabOption.value as T)}
          onMouseEnter={() => setHoveredTab(tabOption.value as T)}
          onMouseLeave={() => setHoveredTab(null)}
        >
          <TabContent>
            <Text>{tabOption.label}</Text>
            {enableUnlock &&
              (userPreferredTab === tabOption.value
                ? renderLockIcon(tabOption.value as T)
                : hoveredTab === tabOption.value &&
                  renderUnlockIcon(tabOption.value as T))}
          </TabContent>
          <Underline isActive={activeTab === tabOption.value} />
        </SwitchButton>
      ))}
    </SwitchContainer>
  );
};

const SwitchContainer = styled.div`
  display: flex;
  white-space: nowrap;
`;

const SwitchButton = styled.div<{ isActive: boolean }>`
  border-radius: 6px;
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.secondary.main : theme.colors.inactive};
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  position: relative;

  &:hover {
    color: ${({ isActive, theme }) =>
      isActive ? theme.colors.secondary.main : theme.colors.inactive};
  }
`;

const TabContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1.5rem;
  gap: 1rem;
`;

const Text = styled.h4`
  font-weight: 700;
`;

const Underline = styled.div<{ isActive: boolean }>`
  height: ${({ isActive }) => (isActive ? '2px' : '1px')};
  position: relative;
  z-index: ${({ isActive }) => (isActive ? '1' : '0')};
  margin-top: ${({ isActive }) => (isActive ? '0.5rem' : 'calc(0.5rem + 1px)')};
  width: 100%;
  min-width: calc(100% + 16px);
  border-radius: 2px;
  border: ${({ isActive, theme }) =>
    isActive
      ? `2px solid ${theme.colors.secondary.main}`
      : `1px solid ${theme.colors.inactive}`};
  &:hover {
    color: ${({ isActive, theme }) =>
      isActive ? theme.colors.secondary.main : theme.colors.inactive};
  }
  @media ${device.mobile} {
    min-width: 100%;
  }
`;

const UnlockIconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const LockIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledUnlockIcon = styled(UnlockIcon)`
  width: 20px;
  height: 20px;
`;

const StyledLockIcon = styled(LockIcon)`
  width: 20px;
  height: 20px;
`;
