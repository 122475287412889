import { getSessionRecapRepository } from 'modules/recap/http/respository';
import { SessionRequestType } from 'modules/recap/http/types';
import { SessionRecap } from '../entities/entity';

export const getSessionRecap = (
  body: SessionRequestType
): Promise<SessionRecap> => {
  const recapRepository = getSessionRecapRepository();
  return recapRepository.getSessionrecap(body);
};
