import {
  SpecialityProgressCollectionDPs,
  SpecialityProgressCollectionQIs,
} from 'modules/progress/detailed/session/http/types';
import { SpecialtyProgressCard } from './SpecialtyProgressCard';
import { useHomeInfoContext } from 'pages/newHome/context/homeInfoContext';
import { useGetSpeDetailedProgress } from 'modules/progress/detailed/spe/hooks/useGetSpeDetailedProgress';
import { useMemo } from 'react';
import { LoaderWithText } from 'components/Loader/LoaderWithText';
import { CardsContainer } from 'pages/newHome/ui/Global';

export const SpecialtyProgress = () => {
  const { activeSpecialtyId } = useHomeInfoContext();

  const { data: sessionDetailedProgress, isLoading } =
    useGetSpeDetailedProgress(
      activeSpecialtyId !== undefined
        ? {
            specialty: activeSpecialtyId,
          }
        : undefined
    );

  const formattedDataDP = useMemo(() => {
    if (!sessionDetailedProgress?.dps) return [];
    const collections: Record<string, SpecialityProgressCollectionDPs> = {};
    sessionDetailedProgress.dps.forEach((dp) => {
      if (!collections[dp.collection]) {
        collections[dp.collection] = {
          collection: dp.collection,
          questions: [],
        };
      }
      collections[dp.collection].questions.push(dp);
    });
    // sort back to original order
    return Object.values(collections).reverse();
  }, [sessionDetailedProgress?.dps]);

  const formattedDataQI = useMemo(() => {
    if (!sessionDetailedProgress?.qis) return [];
    const collections: Record<string, SpecialityProgressCollectionQIs> = {};
    sessionDetailedProgress.qis.forEach((qi) => {
      if (!collections[qi.collection]) {
        collections[qi.collection] = {
          collection: qi.collection,
          questions: [],
        };
      }
      collections[qi.collection].questions.push(qi);
    });
    return Object.values(collections).reverse();
  }, [sessionDetailedProgress?.qis]);

  if (isLoading) {
    return <LoaderWithText />;
  }

  return (
    <CardsContainer fullWidth nbCards={2} noPadding>
      <SpecialtyProgressCard
        type="DP"
        data={formattedDataDP}
        title="Dossiers progressifs"
      />
      <SpecialtyProgressCard
        type="QI"
        data={formattedDataQI}
        title="Questions isolées"
      />
    </CardsContainer>
  );
};
