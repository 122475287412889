import { ContainedLoader } from 'components/ContainedLoader';
import { FC } from 'react';
import styled from 'styled-components/macro';

interface Props {}

export const Loading: FC<Props> = () => {
  return (
    <FullWidthRow>
      <ContainedLoader loadingText="Chargement..." />
    </FullWidthRow>
  );
};

const FullWidthRow = styled.span`
  grid-column: 1/-1;
  padding: 12px 0;
`;
