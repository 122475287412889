import { useQuery } from 'react-query';
import { getUserOptions } from '../domain/useCases/getUserOptions';
import { MS_IN_AN_HOUR } from 'constants/date';

export const USE_GET_USER_OPTIONS_KEY = 'userGetUserOptionsKey';

export const useGetUserOptions = () => {
  return useQuery(USE_GET_USER_OPTIONS_KEY, getUserOptions, {
    cacheTime: MS_IN_AN_HOUR,
    staleTime: MS_IN_AN_HOUR,
  });
};
