import { device } from 'helpers/css/responsive';
import { forwardRef, InputHTMLAttributes } from 'react';
import styled, { css } from 'styled-components/macro';

// Inspired by https://www.w3schools.com/howto/howto_css_custom_checkbox.asp

type NativeInputProps = InputHTMLAttributes<HTMLInputElement>;
interface Props extends Omit<NativeInputProps, 'type'> {
  hasError?: boolean;
  rounded?: boolean;
}

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  (
    { hasError, readOnly, disabled, rounded, name, ...nativeInputProps },
    ref
  ) => {
    return (
      <Label
        onClick={(e) => e.stopPropagation()}
        isReadOnly={readOnly}
        disabled={disabled}
      >
        <Input
          hasError={hasError}
          type="checkbox"
          ref={ref}
          readOnly={readOnly}
          disabled={disabled}
          rounded={rounded}
          name={name}
          {...nativeInputProps}
        />
        <Checkmark hasError={hasError} rounded={rounded} />
      </Label>
    );
  }
);

/**
 * The label acts as the container.
 * We use the label instead of the proper input because we can't
 * put childrens in an input.
 *
 * In this custom checkbox component we exploit the fact
 * that clicking on a label associated to an input is equivalent
 * to clicking on the input itself.
 */
const Label = styled.label<{ isReadOnly?: boolean; disabled?: boolean }>`
  display: grid;
  place-items: center;
  position: relative;

  cursor: ${({ disabled }) => (disabled ? 'normal' : 'pointer')};
  ${({ isReadOnly }) =>
    isReadOnly &&
    css`
      cursor: default;
    `}

  height: 16px;
  width: 16px;

  @media ${device.desktop} {
    height: 20px;
    width: 20px;
    min-width: 20px;
  }
`;

const Input = styled.input<{ hasError?: boolean; rounded?: boolean }>`
  all: unset;
  position: absolute;
  width: 100%;
  height: 100%;
  border: 1px solid
    ${({ theme, hasError }) =>
      hasError ? theme.colors.error.main : theme.colors.primary.dark};
  box-sizing: border-box;

  border-radius: ${({ rounded }) => (rounded ? '50%' : '4px')};

  &:focus-visible {
    outline-offset: 3px;
    outline: 2px solid black;
  }

  &:disabled {
    border: 1px solid ${({ theme }) => theme.colors.inactive};
  }
`;

const Checkmark = styled.span<{ hasError?: boolean; rounded?: boolean }>`
  // Defines the checkmark container, the checkmark
  // itself will the :after pseudo element
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &:after {
    content: '';
    position: absolute;
    display: none;
    // Center the check mark in the containing label
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: ${({ rounded }) => (rounded ? '50%' : '4px')};
  }

  ${Input}:checked ~ &:after {
    display: block;
  }

  // Styles for the actual check marker
  &:after {
    height: 10px;
    width: 10px;
    border-radius: 3px;
    background-color: ${({ theme, hasError }) =>
      hasError ? theme.colors.error.main : theme.colors.primary.dark};
    border-radius: ${({ rounded }) => (rounded ? '50%' : '4px')};

    @media ${device.desktop} {
      height: 12px;
      width: 12px;
    }
  }

  ${Input}:disabled ~ &:after {
    background-color: ${({ theme }) => theme.colors.inactive};
  }
`;
