import { FC } from 'react';
import styled from 'styled-components/macro';
import { GroupHistoryList } from './GroupHistoryList/GroupHistoryList';

import { device } from 'helpers/css/responsive';

interface Props {}

export const GroupHistory: FC<Props> = () => {
  return (
    <Section>
      <GroupHistoryList />
    </Section>
  );
};

const Section = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media ${device.desktop} {
    flex-basis: 760px;
    padding: 32px;
    border-radius: 16px;
    gap: 16px;
  }
`;
