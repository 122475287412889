import { FC, SVGAttributes } from 'react';

type SVGNativeProps = SVGAttributes<SVGElement>;

export const ClockIcon: FC<SVGNativeProps> = (props) => {
  return (
    <svg
      width="31"
      height="32"
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5002 12.125C15.5002 11.7825 15.6362 11.4539 15.8785 11.2117C16.1207 10.9695 16.4493 10.8334 16.7918 10.8334C17.1344 10.8334 17.4629 10.9695 17.7052 11.2117C17.9474 11.4539 18.0835 11.7825 18.0835 12.125V17.2917C18.0835 17.6343 17.9474 17.9628 17.7052 18.2051C17.4629 18.4473 17.1344 18.5834 16.7918 18.5834H11.6252C11.2826 18.5834 10.9541 18.4473 10.7118 18.2051C10.4696 17.9628 10.3335 17.6343 10.3335 17.2917C10.3335 16.9491 10.4696 16.6206 10.7118 16.3784C10.9541 16.1361 11.2826 16 11.6252 16H15.5002V12.125Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4168 16C28.4168 23.1339 22.634 28.9167 15.5002 28.9167C8.36629 28.9167 2.5835 23.1339 2.5835 16C2.5835 8.86617 8.36629 3.08337 15.5002 3.08337C22.634 3.08337 28.4168 8.86617 28.4168 16ZM25.8335 16C25.8335 18.7406 24.7448 21.3689 22.8069 23.3068C20.8691 25.2447 18.2407 26.3334 15.5002 26.3334C12.7596 26.3334 10.1313 25.2447 8.19339 23.3068C6.25552 21.3689 5.16683 18.7406 5.16683 16C5.16683 13.2595 6.25552 10.6311 8.19339 8.69327C10.1313 6.7554 12.7596 5.66671 15.5002 5.66671C18.2407 5.66671 20.8691 6.7554 22.8069 8.69327C24.7448 10.6311 25.8335 13.2595 25.8335 16Z"
        fill="currentColor"
      />
    </svg>
  );
};
