import { FC, useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import parse from 'html-react-parser';
import { Books } from 'components/Book/Books';
import { Sources } from 'components/Source/Sources';
import { InfoSection } from './InfoSection';
import {
  STATEMENT_ID,
  useClickedQuestionContext,
} from 'pages/correction/clickedQuestionContext';
import { StyledMathJax } from 'ui/MathJax';
import { CorrectionGroup } from 'types/correction.type';
import { useGetBooks } from 'http/common/books/connector';
import { useGetSources } from 'http/common/sources/connector';
import { ButtonBase } from 'ui/Buttons/ButtonBase';

interface Props {
  groupData: CorrectionGroup;
}

export const CorrectionGroupInfos: FC<Props> = ({ groupData }) => {
  const statementRef = useRef<HTMLDivElement>(null);
  const books = useGetBooks(groupData?.group?.books || []);
  const sources = useGetSources(groupData?.group?.sources || []);
  const { clickedQuestion } = useClickedQuestionContext();

  useEffect(() => {
    if (clickedQuestion === STATEMENT_ID) {
      statementRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [clickedQuestion]);

  if (groupData.custom_qi) return null;

  return (
    <>
      {groupData.group.type === 'LCA' && (
        <StyledButtonBase
          size={'small'}
          disabled={false}
          styled={'secondary'}
          iconOnly={false}
          hasPadding={true}
        >
          <a href={groupData.group.article} target="_blank" rel="noreferrer">
            Lire l'article
          </a>
        </StyledButtonBase>
      )}
      {groupData.group?.thumbnail && (
        <InfoSection title="Vignette">
          <StyledMathJax inline>
            {parse(groupData.group?.thumbnail ?? '')}
          </StyledMathJax>
        </InfoSection>
      )}
      {groupData.group.abstract && (
        <InfoSection title="Résumé de l'article">
          <StyledMathJax inline>
            {parse(groupData.group.abstract ?? '')}
          </StyledMathJax>
        </InfoSection>
      )}
      {groupData.group?.analysis && (
        <InfoSection title="Analyse du dossier">
          <StyledMathJax inline>
            {parse(groupData.group?.analysis ?? '')}
          </StyledMathJax>
        </InfoSection>
      )}
      {(groupData.group?.books?.length > 0 ||
        groupData.group?.sources?.length > 0) && (
        <InfoSection title="Livres">
          {/* <FooterSection title="Footer"> */}
          {groupData.group?.books?.length > 0 && (
            <Books books={books} isBlue={true} />
          )}
          {groupData.group?.sources?.length > 0 && (
            <Sources sources={sources} />
          )}
          {/* </FooterSection> */}
        </InfoSection>
      )}
      <Divider />
      {groupData.group?.statement && (
        <InfoSection title="Énoncé" ref={statementRef}>
          <StyledMathJax inline>
            {parse(groupData.group?.statement ?? '')}
          </StyledMathJax>
        </InfoSection>
      )}
    </>
  );
};

const Divider = styled.hr`
  margin: 12px 44px;
  border: 0;
  border-top: 2px solid ${({ theme }) => theme.colors.primary.light};
`;

const StyledButtonBase = styled(ButtonBase)`
  margin: 12px var(--horizontal-padding);

  &:hover {
    a {
      text-decoration: none;
    }
  }

  a {
    color: inherit !important;
  }
`;
