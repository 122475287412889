import { getItemHttp } from './api';

import { useQuery } from 'react-query';
import { MS_IN_AN_HOUR } from 'constants/date';

export const useGetItemByIds = (itemIds: string[]) => {
  const getItem = async () => {
    const query = await getItemHttp(itemIds);
    return query;
  };

  const { data: item } = useQuery(['getItem', itemIds], getItem, {
    staleTime: MS_IN_AN_HOUR,
    cacheTime: MS_IN_AN_HOUR,
  });

  return item;
};
