import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { getSelectionQI } from 'http/selection-qi/api';
import { SelectionQIParams } from 'http/selection-qi/types';
import { setPageSize } from 'store/slices/qiTableSlice';
import { usePaginate } from 'pages/selection/hooks/usePaginate';
import { useSortOptions } from 'pages/selection/hooks/useSortOptions';
import { useDidUpdateEffect } from 'hooks/useDidUpdate';
import { useSearchbarContext } from 'pages/selection/components/Searchbar/SearchbarContext';
import { MS_IN_AN_HOUR } from 'constants/date';

export function useGetQITableValues() {
  const { currentPage, handlePageClick, setCurrentPage } = usePaginate();

  const dispatch = useDispatch();
  const updatePageSize = (newSize: number) => dispatch(setPageSize(newSize));
  const handleNewPageSize = (newSize: number) => {
    updatePageSize(newSize);
    setCurrentPage(0);
  };

  const filters = useSelector((state: RootState) => state.selectionFilters);
  const pageSize = useSelector((state: RootState) => state.qiTable.pageSize);
  const { isAscending, sortColumn } = useSortOptions((state) => state.qiTable);

  const { debouncedSearchTerm } = useSearchbarContext();

  const [params, setParams] = useState<SelectionQIParams>({
    page: 1,
    page_size: pageSize,
    collection: filters.collections,
    sort_column: sortColumn,
    sort_ascending: isAscending,
    specialty: filters.specialities,
    knowledge: filters.rank,
    item: filters.items,
    tag: filters.categories,
    type: filters.type,
    search: debouncedSearchTerm,
    is_free: filters.showFree === true ? true : undefined,
    done: filters.showDone,
  });

  const getParams = () => ({
    ...params,
    collection: filters.collections,
    sort_column: sortColumn,
    sort_ascending: isAscending,
    specialty: filters.specialities,
    rank: filters.rank,
    item: filters.items,
    tag: filters.categories,
    type: filters.type,
    page: currentPage + 1,
    page_size: pageSize,
    search: debouncedSearchTerm,
    is_free: filters.showFree === true ? true : undefined,
    done: filters.showDone,
  });

  useDidUpdateEffect(() => {
    setCurrentPage(0);
    setParams({
      ...getParams(),
      // We reset the page to the first one when the filters are updated
      page: 1,
    });
  }, [filters, isAscending, sortColumn, pageSize, debouncedSearchTerm]);

  useEffect(() => {
    setParams(getParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return {
    ...useQuery(['qiData', params], () => getSelectionQI(params), {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }),
    currentPage,
    handlePageClick,
    pageSize,
    handleNewPageSize,
  };
}
