import { FC, Fragment } from 'react';
import styled from 'styled-components/macro';

import { SpecialtyIcon } from 'ui/icons/SpecialtyIcon/SpecialtyIcon';
import { DPProgressBySpecialty } from 'modules/progress/general/dp/domain/entities/DPProgressBySpecialty';

import { DPProgressRowBySpecialty } from './DPProgressRowBySpecialty';
import { SessionHeader } from './SessionHeader';
import { useGetSpecialityInfos } from 'http/selection/specialities-list/connectors';
import {
  Footer,
  ProgressContainer,
  Table,
  TableScrollContainer,
} from '../../components/SectionUIElements';
import { RestrictedPremiumDataRow } from '../../components/RestrictedPremiumDataRow';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';

interface Props {
  progress: DPProgressBySpecialty;
}

export const DPProgressYearBySpecialty: FC<Props> = ({ progress }) => {
  const speInfos = useGetSpecialityInfos(progress.speTrigram);
  const isUserFree = useIsUserFree();

  return (
    <ProgressContainer>
      <Header>
        <HeaderTitle>
          <Specialty trigram={progress.speTrigram} />
          {speInfos?.compressed_name}
        </HeaderTitle>
        <Separator />
      </Header>
      <TableScrollContainer>
        <Table>
          <tbody>
            {progress.sessions.map((session) => (
              <Fragment key={session.collection}>
                <SessionHeader progress={session} />
                {session.groups.map((group) => (
                  <DPProgressRowBySpecialty group={group} key={group.id} />
                ))}
              </Fragment>
            ))}
            {isUserFree && <RestrictedPremiumDataRow colSpan={3} />}
          </tbody>
        </Table>
      </TableScrollContainer>
      <Footer />
    </ProgressContainer>
  );
};

const Header = styled.div`
  ${({ theme }) => theme.typography.label}
  padding: 12px 12px 4px;
  background-color: ${({ theme }) => theme.colors.primary.main};

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
`;

const Separator = styled.div`
  width: 100%;
  margin: 0 8px;
  border: 1px solid ${({ theme }) => theme.colors.primary.light};
`;

const Specialty = styled(SpecialtyIcon)`
  height: 24px;
  width: 24px;
`;
