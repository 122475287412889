import { FC, SVGAttributes } from 'react';

type SVGNativeProps = SVGAttributes<SVGElement>;

export const FilterIcon: FC<SVGNativeProps> = (props) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.46663 3H19.5484L11.9925 12.45L4.46663 3ZM0.319137 2.415C3.36566 6.3 8.99118 13.5 8.99118 13.5V22.5C8.99118 23.325 9.66986 24 10.4994 24H13.5157C14.3452 24 15.0239 23.325 15.0239 22.5V13.5C15.0239 13.5 20.6343 6.3 23.6809 2.415C24.45 1.425 23.7412 0 22.4894 0H1.5106C0.25881 0 -0.450035 1.425 0.319137 2.415Z"
        fill="black"
      />
    </svg>
  );
};
