import { useState } from 'react';
import {
  getPaypalClientId,
  createOrder,
  completeOrder,
} from 'http/payments/paypal/api';
import {
  PaypalCompleteOrderParams,
  UserFacturationInfos,
} from 'pages/subscribe/FacturationPage/FacturationPage.type';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { routes } from 'routing/constants';

export const usePaypalCheckout = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { data: clientId } = useQuery('paypalClientId', getPaypalClientId);

  async function createCheckout(
    id: string,
    params: UserFacturationInfos,
    coupon?: string
  ) {
    setIsLoading(true);
    if (!id) {
      return '';
    }

    const payload = {
      product_id: id,
      coupon: coupon,
      first_name: params?.firstName,
      last_name: params?.lastName,
      email: params?.email,
      address: params?.address,
      zip_code: params?.zipCode,
      city: params?.city,
      country: params?.country,
      terms_of_use_chekbox: params?.termsOfUseChekbox,
      renounce_retraction_checkbox: params?.retractionRenounce,
    };

    try {
      const checkoutSession = await createOrder(payload);

      if (!checkoutSession) {
        return '';
      }

      return checkoutSession?.id;
    } catch (error) {
      toast.error(
        'Une erreur est survenue lors de redirection vers la page de paiement'
      );
      return '';
    } finally {
      setIsLoading(false);
    }
  }

  const onApprove = async (params: PaypalCompleteOrderParams) => {
    try {
      const orderCompleted = await completeOrder(params);
      if (!orderCompleted) {
        toast.error(
          'Une erreur est survenue lors de la validation de votre commande'
        );
      }
      if (orderCompleted?.status === 'COMPLETED') {
        window.location.href = routes.PAYMENT_SUCCESS;
      }
    } catch (error) {
      toast.error(
        'Une erreur est survenue lors de la validation de votre commande'
      );
    }
  };

  return {
    isLoading,
    createCheckout,
    clientId: clientId || '',
    onApprove,
  };
};
