import { RootState } from 'store';

import {
  openRow,
  setPage,
  setPageSize,
  sortBy,
  toggleItemSelection,
  updateItemSelection,
} from 'store/slices/lcaTableSlice';

import { TableConfigContextProvider } from 'pages/selection/tableConfigContext';
import { QuestionGroupTable } from '../QuestionGroupTable/QuestionGroupTable';

const tableStateSelector = (state: RootState) => state.lcaTable;

export const LCATable = () => {
  return (
    <TableConfigContextProvider
      value={{
        sortByCreator: sortBy,
        tableStateSelector: tableStateSelector,
        updateSelectionCreator: updateItemSelection,
        toggleSelectionCreator: toggleItemSelection,
        openRowCreator: openRow,
        setPageSizeCreator: setPageSize,
        tableType: 'LCA',
        setPageCreator: setPage,
      }}
    >
      <QuestionGroupTable />
    </TableConfigContextProvider>
  );
};
