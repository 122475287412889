import { getAPI } from 'auth';
import { LessonId } from './types';
import { SimilarQuestion } from 'types/correction.type';

export const getLessonsQuestions = async (lessonId: LessonId) => {
  const response = await getAPI<SimilarQuestion[]>('/common/lesson_question/', {
    params: {
      lesson: lessonId,
    },
  });
  return response.data;
};
