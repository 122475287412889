const sizes = {
  mobile: '576px',
  tablet: '768px',
  desktop: '830px',
  largeTable: '1300px',
};

export const device = {
  desktop: `(min-width: ${sizes.desktop})`,
  tablet: `(min-width: ${sizes.tablet})`,
  largeTable: `(min-width: ${sizes.largeTable})`,
  mobile: `(max-width: ${sizes.mobile})`,
};
