import { FC } from 'react';
import styled from 'styled-components/macro';
import { device } from 'helpers/css/responsive';
import { popSlideInAnimation } from 'helpers/css/animations/popSlideIn';
import { SpecialtyIcon } from 'ui/icons/SpecialtyIcon/SpecialtyIcon';
import Tippy from '@tippyjs/react';
import { KnowledgeRank } from '../../../components/Knowledge/KnowledgeRank';
import { useClickedQuestionContext } from '../clickedQuestionContext';
import { EvalIcon } from './EvalIcon';
import { CorrectionQuestion } from 'types/correction.type';
import { Item } from 'components/Item/Item';

interface Props {
  questions: CorrectionQuestion[];
  isQI: boolean;
}

export const RecapQuestionsList: FC<Props> = ({ questions, isQI }) => {
  const { setClickedQuestion } = useClickedQuestionContext();

  return (
    <HideContainer>
      <QuestionsContainer>
        <Table>
          <tbody>
            {questions.map((question) => (
              <QuestionRow
                onClick={() => setClickedQuestion(question.id)}
                key={question.id}
                id={`question-row-${question.id}`}
              >
                {isQI ? (
                  <td>
                    {question.question.collection} QI n°
                    {question.question.num}
                  </td>
                ) : (
                  <td>Question n°{question.question.num}</td>
                )}
                <td>
                  {question.question.specialty1 && (
                    <Tippy
                      disabled={!question.question.categories.length}
                      content={question.question.categories}
                    >
                      <div>
                        <SpeIcon trigram={question.question.specialty1} />
                      </div>
                    </Tippy>
                  )}
                </td>
                <td>
                  <KnowledgeRank
                    label={question.question.knowledge?.label}
                    rank={question.question.knowledge?.rank}
                  />
                </td>
                <td>
                  {question.question.knowledge?.item && (
                    <Item item={question.question.knowledge?.item} />
                  )}
                </td>
                <td>
                  <EvalIcon
                    userGrade={question.grade}
                    maxGrade={question.question.coefficient}
                  />
                </td>
              </QuestionRow>
            ))}
          </tbody>
        </Table>
      </QuestionsContainer>
    </HideContainer>
  );
};

const HideContainer = styled.div`
  overflow: hidden;
`;

const QuestionsContainer = styled.div`
  @media ${device.largeTable} {
    padding: 0 16px;
  }

  ${popSlideInAnimation}
`;

const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0 8px;
  td {
    white-space: nowrap;
    ${({ theme }) => theme.typography.textBody2}
    text-align: center;
  }
`;

const QuestionRow = styled.tr`
  td {
    padding: 8px 4px;
    @media ${device.largeTable} {
      padding: 8px;
    }
  }

  td:first-child {
    padding-left: 8px;
    @media ${device.largeTable} {
      padding-left: 16px;
    }
  }

  td:last-child {
    padding-right: 8px;
    @media ${device.largeTable} {
      padding-right: 16px;
    }
  }

  &:hover {
    cursor: pointer;

    --border-color: ${({ theme }) => theme.colors.primary.light};
    --radius: 8px;

    td:first-child {
      border-top-left-radius: var(--radius);
      border-bottom-left-radius: var(--radius);
    }

    td:last-child {
      border-top-right-radius: var(--radius);
      border-bottom-right-radius: var(--radius);
    }

    /*
      To draw the borders we use box-shadows because regular borders
      modify the row height and make everything move
    */
    td {
      box-shadow: 0 1px 0 var(--border-color), 0 -1px 0 var(--border-color);
    }

    td:first-child {
      box-shadow: 0 1px 0 var(--border-color), 0 -1px 0 var(--border-color),
        -1px 0 0 var(--border-color);
    }

    td:last-child {
      box-shadow: 0 1px 0 var(--border-color), 0 -1px 0 var(--border-color),
        1px 0 0 var(--border-color);
    }
  }
`;

const SpeIcon = styled(SpecialtyIcon)`
  height: 16px;
  width: 16px;
  display: block;
  margin: auto;
`;
