import { FC } from 'react';

import { RootState } from 'store';
import {
  openRow,
  setPage,
  setPageSize,
  sortBy,
  toggleItemSelection,
  updateItemSelection,
} from 'store/slices/dpTableSlice';

import { TableConfigContextProvider } from '../../tableConfigContext';
import { QuestionGroupTable } from '../QuestionGroupTable/QuestionGroupTable';

const tableStateSelector = (state: RootState) => state.dpTable;

export const DPTable: FC = () => {
  return (
    <TableConfigContextProvider
      value={{
        sortByCreator: sortBy,
        tableStateSelector: tableStateSelector,
        updateSelectionCreator: updateItemSelection,
        toggleSelectionCreator: toggleItemSelection,
        openRowCreator: openRow,
        setPageSizeCreator: setPageSize,
        tableType: 'DP',
        setPageCreator: setPage,
      }}
    >
      <QuestionGroupTable />
    </TableConfigContextProvider>
  );
};
