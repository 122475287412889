import { useState, useEffect, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { setPageSize } from 'store/slices/decksTableSlice';
import { useSortOptions } from 'pages/selection/hooks/useSortOptions';
import { useDidUpdateEffect } from 'hooks/useDidUpdate';
import { usePaginate } from './usePaginate';
import { getFQDecks } from 'http/flash-questions/decks/api';
import { DecksParams } from 'http/flash-questions/decks/types';
import { MS_IN_AN_HOUR } from 'constants/date';

export const useGetFQDecksTableValues = () => {
  const { currentPage, handlePageClick, setCurrentPage } = usePaginate();

  const dispatch = useDispatch();
  const updatePageSize = (newSize: number) => dispatch(setPageSize(newSize));
  const handleNewPageSize = (newSize: number) => {
    updatePageSize(newSize);
    setCurrentPage(0);
  };

  const filters = useSelector((state: RootState) => state.selectionFilters);
  const pageSize = useSelector((state: RootState) => state.decksTable.pageSize);
  const { isAscending, sortColumn } = useSortOptions(
    (state) => state.decksTable
  );

  const [params, setParams] = useState<DecksParams>({
    page: 1,
    page_size: pageSize,
    sort_column: sortColumn,
    sort_ascending: isAscending,
  });

  const getParams = () => ({
    ...params,
    page: currentPage + 1,
    page_size: pageSize,
    sort_column: sortColumn,
    sort_ascending: isAscending,
  });

  // TODO check if it has to be updated more often
  const { data, ...rest } = useQuery(
    ['fqDecksData', params],
    () => getFQDecks(params),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }
  );

  const deckList = useMemo(() => {
    return data?.results.map((deck) => {
      return {
        name: deck.label,
        newCards: deck.new_cards_count,
        toStudyCards: deck.to_review_cards_count,
        dailyCards: {
          newCards: deck.day_new_cards_count,
          toRedoCards: deck.day_to_redo_cards_count,
          toReviewCards: deck.day_to_review_cards_count,
        },
      };
    });
  }, [data]);

  useDidUpdateEffect(() => {
    setCurrentPage(0);
    setParams({
      ...getParams(),
      // We reset the page to the first one when the filters are updated
      page: 1,
    });
  }, [filters, isAscending, sortColumn, pageSize]);

  useEffect(() => {
    setParams(getParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return {
    ...rest,
    count: data?.count,
    deckList,
    currentPage,
    handlePageClick,
    pageSize,
    handleNewPageSize,
    setCurrentPage,
  };
};
