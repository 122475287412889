import { useMemo, FC } from 'react';
import { useSelector } from 'react-redux';
import Tippy from '@tippyjs/react';

import {
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Radar,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import styled, { useTheme } from 'styled-components';
import { SpecialtyIcon } from 'ui/icons/SpecialtyIcon/SpecialtyIcon';
import { UserSpecialityResponses } from 'http/ccb/type';
import { Dot } from '../RepartitionLca/RepartitionLca';
import {
  StyledTooltip,
  TooltipLine,
  TooltipText,
  TooltipTitle,
} from 'ui/Graph/CustomTooltip';

interface GraphTippyProps {
  spe: string;
}

const GraphTippy: FC<GraphTippyProps> = ({ spe }) => {
  return (
    <Tippy
      content={spe}
      placement="top"
      interactive={true}
      delay={100}
      theme="light"
      arrow={false}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <SpecialtyIcon trigram={spe} />
      </div>
    </Tippy>
  );
};

export const StatRadarChart = () => {
  const theme = useTheme();
  const { ccbStats } = useSelector((state: any) => state.ccb);

  const formatedData = useMemo(() => {
    const formatedData = ccbStats
      ? ccbStats.user_specialty_responses
          .filter(
            (response: UserSpecialityResponses) =>
              response.partial_pass !== 0 || response.fail !== 0
          )
          .map((response: UserSpecialityResponses) => {
            const total =
              response.total_pass + response.partial_pass + response.fail;
            if (total && total !== 0) {
              return {
                subject: response.specialty.trigram,
                succeeded: (response.total_pass * 100) / total,
                partially_succeeded:
                  ((response.partial_pass + response.total_pass) * 100) / total,
                failed:
                  ((response.fail +
                    response.partial_pass +
                    response.total_pass) *
                    100) /
                  total,
                full_grade: 100,
                succeeded_question: response.total_pass,
                partially_succeeded_question: response.partial_pass,
                failed_question: response.fail,
                number_of_questions: total,
              };
            } else return null;
          })
          .filter((response: any) => response !== null)
      : [];
    return formatedData;
  }, [ccbStats]);

  const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <StyledTooltip>
          <TooltipTitle>{`${payload[0].payload.name} (${payload[0].payload.number_of_questions} questions)`}</TooltipTitle>
          <TooltipLine>
            <Dot fillColor={theme.colors.success.main} />
            <TooltipText>
              {`Pleinement réussie - ${payload[0].payload.succeeded_question} question(s)`}
            </TooltipText>
          </TooltipLine>
          <TooltipLine>
            <Dot fillColor={theme.colors.warning.dark} />
            <TooltipText>
              {`En partie réussie - ${payload[0].payload.partially_succeeded_question} question(s)`}
            </TooltipText>
          </TooltipLine>
          <TooltipLine>
            <Dot fillColor={theme.colors.error.dark} />
            <TooltipText>
              {`Non maitrisée - ${payload[0].payload.failed_question} question(s)`}
            </TooltipText>
          </TooltipLine>
        </StyledTooltip>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer>
      <RadarChart outerRadius={'80%'} data={formatedData}>
        <PolarGrid stroke="rgba(127, 138, 149, 0.5)" />
        <PolarAngleAxis
          angleAxisId={0}
          dataKey="subject"
          tick={(props) => {
            let x = props.x - 20;
            let y = props.y - 18;

            // Adding a small gap between tick and graph :

            const ratioX = ((x - props.radius) / 100) * 8;
            const ratioY = ((y - props.radius) / 100) * 8;
            x += ratioX;
            y += ratioY;

            return (
              <foreignObject
                style={{ overflow: 'visible' }}
                width="40"
                height="40"
                x={x}
                spacing={100}
                y={y}
              >
                <div>
                  <GraphTippy spe={formatedData[props.index].subject}>
                    <StyledSpecialtyIcon
                      trigram={formatedData[props.index].subject}
                    />
                  </GraphTippy>
                </div>
              </foreignObject>
            );
          }}
        />
        <PolarRadiusAxis angle={90} domain={[0, 100]} axisLine={false} />

        <Radar
          strokeWidth={2}
          dataKey="failed"
          stroke={theme.colors.error.dark}
          fill={theme.colors.error.dark}
          fillOpacity={0.3}
        />

        <Radar
          strokeWidth={2}
          dataKey="partially_succeeded"
          stroke={theme.colors.warning.dark}
          fill={theme.colors.warning.dark}
          fillOpacity={0.3}
        />
        <Radar
          strokeWidth={2}
          dataKey="succeeded"
          stroke={theme.colors.success.main}
          fill={theme.colors.success.main}
          fillOpacity={0.3}
        />
        <Tooltip content={<CustomTooltip />} />
      </RadarChart>
    </ResponsiveContainer>
  );
};

export default StatRadarChart;
const StyledSpecialtyIcon = styled(SpecialtyIcon)`
  z-index: 2;
`;
