import { FC } from 'react';
import { Control, Controller } from 'react-hook-form';

import { Dropdown, Option } from 'ui/Dropdown';

interface Props {
  control: Control<any, object>;
  inputName: string;
  listOfValues: Option[];
  label: string;
  errorMessage?: string;
  className?: string;
  isMandatory?: boolean;
}

export const RHFDropdown: FC<Props> = ({
  control,
  inputName,
  listOfValues,
  label,
  errorMessage,
  className,
  isMandatory,
}) => {
  return (
    <Controller
      control={control}
      name={inputName}
      render={({ field: { onChange, onBlur, value } }) => {
        const fieldValue = listOfValues.find(
          (el: Option) => el.value === value
        );

        return (
          <Dropdown
            isMandatory={isMandatory}
            label={label}
            onChange={(object) => onChange(object ? object.value : '')}
            onBlur={onBlur}
            options={listOfValues}
            value={fieldValue}
            errorMessage={errorMessage}
            className={className}
            name={inputName}
          />
        );
      }}
    />
  );
};
