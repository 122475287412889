import { FC, SVGAttributes } from 'react';

type SVGNativeProps = SVGAttributes<SVGElement>;

export const SearchIcon: FC<SVGNativeProps> = (props) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 5.21c0 1.111-.97 2.21-2.418 2.21-1.447 0-2.417-1.099-2.417-2.21S9.135 3 10.582 3C12.03 3 13 4.099 13 5.21Zm3 0c0 2.878-2.426 5.21-5.418 5.21a5.562 5.562 0 0 1-2.631-.654L2.608 15.5c-.511.549-1.436.663-2.065.256-.63-.408-.726-1.183-.214-1.732l5.559-5.966c.03-.033.062-.064.095-.093a5.04 5.04 0 0 1-.818-2.755C5.165 2.333 7.59 0 10.582 0S16 2.333 16 5.21Z"
        fill="currentColor"
      />
    </svg>
  );
};
