import { EyeClosed } from 'ui/icons/EyeClosed';
import { EyeOpen } from 'ui/icons/EyeOpen';
import { CardsContainerHeader } from './Global';
import { useAccount } from 'pages/account/useAccount';
import styled from 'styled-components';
import { device } from 'helpers/css/responsive';

export const DisplayOptionsMenu = () => {
  const { userOptions, updateUserOptions } = useAccount();
  return (
    <Container>
      <CardsContainerHeader
        onClick={() => {
          updateUserOptions({
            ...userOptions,
            home_see_grades: !userOptions?.home_see_grades,
          });
        }}
      >
        {userOptions?.home_see_grades ? 'Masquer' : 'Voir'} les notes
        {userOptions?.home_see_grades ? <EyeOpen /> : <EyeClosed />}
      </CardsContainerHeader>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 1rem;
  @media ${device.mobile} {
    flex-direction: column;
    width: 70%;
  }
`;
