import { FC, SVGAttributes } from 'react';

type SVGNativeProps = SVGAttributes<SVGElement>;

export const LeftChevron: FC<SVGNativeProps> = (props) => {
  return (
    <svg
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 1 2 9l8 8"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
      />
    </svg>
  );
};
