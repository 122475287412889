import { SessionRecap } from 'modules/recap/domain/entities/entity';

export const isSessionCompleted = (
  sessionRecap: SessionRecap | undefined
): boolean | undefined => {
  if (sessionRecap === undefined) {
    return undefined;
  }

  return (
    sessionRecap.doneDp === sessionRecap.totalDp &&
    sessionRecap.doneQi === sessionRecap.totalQi &&
    sessionRecap.doneLca === sessionRecap.totalLca
  );
};
