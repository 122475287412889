import { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import parse from 'html-react-parser';
import { Button } from 'ui/Button';
import { useGetStaticPage } from 'http/static-pages/connector';
import { ContainedLoader } from 'components/ContainedLoader';
import { StyledMathJax } from 'ui/MathJax';
import { useGetPrice } from '../components/useGetPrices';
import { CheckDefaultBlack } from '../components/icons/CheckDefaultBlack';
import { CrossDefaultBlack } from '../components/icons/CrossDefaultGrey';
import { PriceInfos } from 'http/payments/getPrices/types';
import FadeIn from 'ui/Animations/FadeIn';
import PriceByMonth from '../components/PriceByMonth';
import PriceByYear from '../components/PriceByYear';
import DateOfValidity from '../components/DateOfValidity';
import SmallText from 'ui/Texts/SmallText';

interface Props {
  handleChoosePrice: (priceId: PriceInfos) => void;
}

const PricingPage: FC<Props> = ({ handleChoosePrice }) => {
  const prices = useGetPrice();

  const { data: page, isLoading: isLoadingPage } =
    useGetStaticPage("S'abonner");

  if (isLoadingPage) {
    <CheckDefaultBlack />;

    return (
      <LoadingContainer>
        <ContainedLoader />
      </LoadingContainer>
    );
  }

  if (page) {
    return (
      <FadeIn>
        <Container>
          <StyledMathJax inline>{parse(page.page)}</StyledMathJax>
          <Form>
            <div>
              <OffersContainer>
                {prices.map((el) => (
                  <OfferInfos key={el.id} onClick={() => handleChoosePrice(el)}>
                    <CardHeader>
                      <label>{el.label}</label>
                    </CardHeader>
                    <h2>
                      <PriceByYear amount={el.amount} />
                    </h2>
                    <PriceByYearText>
                      Soit l'équivalent de{' '}
                      <PriceByMonth
                        amount={el.amount}
                        endSubscription={el.end_subscription}
                        onlyText
                      />
                    </PriceByYearText>
                    <SessionsContainer>
                      {Object.keys(el.session).map((sessionYear: string) => (
                        <SessionRow key={sessionYear}>
                          <IconContainer>
                            {el.session[sessionYear] === 1 ? (
                              <CheckDefaultBlack />
                            ) : (
                              <CrossDefaultBlack />
                            )}
                          </IconContainer>
                          <SessionYearContainer
                            isIncluded={el.session[sessionYear]}
                          >
                            {sessionYear}
                          </SessionYearContainer>
                        </SessionRow>
                      ))}
                    </SessionsContainer>
                    <DateOfValidity
                      end_subscription={el.end_subscription}
                      margin="2rem 0"
                    />
                    <Button type="submit" fullWidth variant="outlined">
                      S'abonner
                    </Button>
                    <SmallText
                      text="Paiements sécurisés jusqu'à 4x sans frais"
                      textAlign="center"
                      marginTop=".5rem"
                    />
                  </OfferInfos>
                ))}
              </OffersContainer>
            </div>
          </Form>
        </Container>
      </FadeIn>
    );
  }

  return <Container>Une erreur est survenue</Container>;
};

const LoadingContainer = styled.div`
  height: 100%;
  display: grid;
  place-items: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-top: 1rem;
`;

const Form = styled.form`
  display: flex;
  gap: 24px;
  flex-direction: column;
`;

const OffersContainer = styled.div`
  display: flex;
  min-width: 100%;
  margin-bottom: 32px;
  overflow-x: auto;
  padding-bottom: 24px;
  & :first-child {
    margin-left: 0;
  }
  & :last-child {
    margin-right: 0;
  }
`;
const OfferInfos = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.lighter};
  border-radius: 16px;
  margin: 0 13px;
  padding: 24px;
  min-width: 400px;
  border: 1px solid ${({ theme }) => theme.colors.primary.lighter};
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.primary.main};
    background-color: ${({ theme }) => theme.colors.primary.light};
  }
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const SessionsContainer = styled.div`
  margin-top: 32px;
`;

const SessionYearContainer = styled.div<{ isIncluded: number }>`
  ${({ isIncluded }) =>
    isIncluded === 0 &&
    css`
      color: grey;
    `}
`;

const SessionRow = styled.div`
  display: flex;
  margin-top: 20px;
`;

const IconContainer = styled.div`
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  color: var(--textColor);
  margin-right: 12px;
`;

const PriceByYearText = styled.h5`
  font-weight: 400;
  margin-top: 0.25rem;
`;

export default PricingPage;
