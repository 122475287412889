import { useQuery } from 'react-query';

import { getRankInfoSession } from './api';

import { useMemo } from 'react';
import { MS_IN_AN_HOUR } from 'constants/date';

export const USE_GET_RANG_LIMIER_KEY = 'useGetRankInfoSession';

export interface SessionInfo {
  year: number;
  collection: string;
  numerator: number;
  denom: number;
  nbCandidates: number;
}

export const useGetRankInfoSession = () => {
  const query = useQuery(
    [USE_GET_RANG_LIMIER_KEY],
    () => getRankInfoSession(),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }
  );

  const rankInfoSession = useMemo<SessionInfo[] | undefined>(
    () =>
      query.data?.map((infos) => ({
        year: Number(infos.collection.split(' ')[1]),
        collection: infos.collection,
        numerator:
          infos.done_dp * 420 + infos.done_lca * 540 + infos.done_qi * 18,
        denom:
          infos.total_dp * 420 + infos.total_lca * 540 + infos.total_qi * 18,
        nbCandidates: infos.n_candidate,
      })),
    [query.data]
  );

  if (query.data === undefined) return query;
  return {
    ...query,
    data: rankInfoSession,
  };
};
