import { FC } from 'react';
import styled from 'styled-components/macro';
import { QIProgressRowBySession } from './QIProgressRowBySession';
import {
  Footer,
  ProgressContainer,
  Table,
  TableScrollContainer,
} from '../../components/SectionUIElements';
import { SelectAllColumnCheckbox } from '../components/SelectAllColumnCheckbox';
import { QIProgressByCompositionUnits } from 'modules/progress/general/qi/domain/entities/QIProgressByCompositionUnits';
import React from 'react';
import { QIBasicInformations } from 'modules/progress/general/qi/domain/entities/QIBasicInformations';

interface Props {
  progress: QIProgressByCompositionUnits;
}

export const QIProgressYearBySession: FC<Props> = ({ progress }) => {
  return (
    <ProgressContainer>
      <HeaderContainer>
        <Header>
          {progress.name}
          <SelectAllColumnCheckbox progress={progress} />
        </Header>
      </HeaderContainer>
      <TableScrollContainer>
        <Table>
          <tbody>
            {Object.keys(progress.compositionUnits).map((compositionUnit) => {
              return (
                <React.Fragment key={progress.collection + compositionUnit}>
                  {/* {compositionUnit !== '0' && (
                    <tr>
                      <HeaderCell colSpan={5}>UC{compositionUnit}</HeaderCell>
                    </tr>
                  )} */}
                  {
                    // @ts-ignore
                    progress.compositionUnits[parseInt(compositionUnit)].map(
                      (group: QIBasicInformations) => (
                        <QIProgressRowBySession
                          question={group}
                          key={group.id}
                        />
                      )
                    )
                  }
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </TableScrollContainer>
      <Footer />
    </ProgressContainer>
  );
};

const HeaderContainer = styled.div`
  ${({ theme }) => theme.typography.label}
  width: 100%;
  padding: 12px 12px 4px;
  background-color: ${({ theme }) => theme.colors.primary.main};

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: space-between;
`;

// const HeaderCell = styled.td`
//   background-color: ${({ theme }) => theme.colors.primary.main};
//   width: 100%;

//   padding: 4px 12px;
//   vertical-align: middle;
//   text-align: start;

//   ${({ theme }) => theme.typography.label}
// `;
