import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import {
  selectCard,
  selectCardStatus,
  selectIsCorrectionRevealed,
} from 'store/slices/fqCurrentSlice';
import { Status } from 'store/types';
import { TextBody2 } from 'ui/TextBody2';
import { FQuestion } from './FQQuestion';

const { PENDING, REJECTED, RESOLVED } = Status;

export const FQBody = () => {
  const isCorrectionRevealed = useSelector(selectIsCorrectionRevealed);
  const cardStatus = useSelector(selectCardStatus);
  const card = useSelector(selectCard);

  if (cardStatus === PENDING) {
    return <p>Chargement de la question</p>;
  }

  if (cardStatus === REJECTED) {
    return <p>Erreur lors du chargement de la question</p>;
  }

  if (cardStatus === RESOLVED && card === null) {
    return <p>Il n'y a plus de question à faire</p>;
  }

  if (cardStatus === RESOLVED && card !== null) {
    return (
      <FQuestion card={card} isCorrectionRevealed={isCorrectionRevealed} />
    );
  }
  return (
    <ErrorMessage>Une erreur inconnue est arrivée à l'affichage</ErrorMessage>
  );
};

const ErrorMessage = styled(TextBody2)`
  text-align: center;
  margin: 1rem;
  color: ${({ theme }) => theme.colors.error.main};
  display: inline;
`;
