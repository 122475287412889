import { FC } from 'react';
import styled from 'styled-components/macro';

import { Table, Thead, Th, Tbody, Tr } from 'ui/TableElements';

import { TableLoading } from 'pages/selection/components/TableLoading';
import { TableNoResults } from 'pages/selection/components/TableNoResults';
import { QIRow } from './QIRow';
import { SessionHeader } from '../../TableHeaders/SessionHeader';
import { useGetQITableValues } from './useGetQITableValues';
import { GenericSortHeader } from '../../TableHeaders/GenericSortHeader';
import { SpecialitiesHeader } from '../../TableHeaders/SpecialitiesHeader';
import { RanksHeader } from 'pages/selection/TableHeaders/RanksHeader';
import { ItemsHeader } from '../../TableHeaders/ItemsHeader';
import { CheckboxHeader } from '../../TableHeaders/CheckboxHeader';
import { TablePagination } from 'pages/selection/components/TablePagination';
import { CategoriesHeader } from 'pages/selection/TableHeaders/CategoriesHeader';
import { TableConfigContextProvider } from '../../tableConfigContext';
import {
  openRow,
  sortBy,
  toggleItemSelection,
  updateItemSelection,
  setPageSize,
  setPage,
} from 'store/slices/qiTableSlice';
import { RootState } from 'store';
import { useShowSelectedItemsContext } from 'pages/selection/showSelectedItemsContext';
import { ReadonlyQITable } from './ReadonlyQITable';
import { TypeHeader } from 'pages/selection/TableHeaders/TypeHeader';
import { TableError } from 'pages/selection/components/TableError';
import { LastTrainingHeader } from 'pages/selection/TableHeaders/LastTrainingHeader';

const colNumber = 8;

const tableStateSelector = (state: RootState) => state.qiTable;

export const QITable: FC = () => {
  const {
    data,
    isLoading,
    isError,
    refetch,
    currentPage,
    handlePageClick,
    pageSize,
    handleNewPageSize,
  } = useGetQITableValues();

  const { shouldShowSelectedItems } = useShowSelectedItemsContext();

  return (
    <TableConfigContextProvider
      value={{
        sortByCreator: sortBy,
        tableStateSelector: tableStateSelector,
        updateSelectionCreator: updateItemSelection,
        toggleSelectionCreator: toggleItemSelection,
        openRowCreator: openRow,
        setPageSizeCreator: setPageSize,
        setPageCreator: setPage,
      }}
    >
      <Container>
        <TableContainer>
          {shouldShowSelectedItems ? (
            <ReadonlyQITable />
          ) : (
            <Table>
              <Thead>
                <Tr>
                  <Th>
                    <CheckboxHeader pageItemInfos={data?.results ?? []} />
                  </Th>
                  <Th>
                    <SessionHeader />
                  </Th>
                  <Th>
                    <GenericSortHeader columnName="N°" sortColumn="num" />
                  </Th>
                  <Th>
                    <SpecialitiesHeader />
                  </Th>
                  <Th>
                    <RanksHeader />
                  </Th>
                  <Th>
                    <ItemsHeader />
                  </Th>
                  <Th>
                    <CategoriesHeader />
                  </Th>
                  <Th>
                    <LastTrainingHeader />
                  </Th>
                  <Th>
                    <TypeHeader />
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {isLoading && <TableLoading colNumber={colNumber} />}
                {data &&
                  data.results.length > 0 &&
                  data.results.map((rowData, index) => (
                    <QIRow rowData={rowData} index={index} key={rowData.id} />
                  ))}
                {data && data.results.length === 0 && (
                  <TableNoResults colNumber={colNumber} />
                )}
                {isError && (
                  <TableError colNumber={colNumber} retry={refetch} />
                )}
              </Tbody>
            </Table>
          )}
        </TableContainer>

        {!shouldShowSelectedItems && (
          <TablePagination
            totalCount={data?.count ?? 0}
            currentPage={currentPage}
            handlePageClick={handlePageClick}
            pageSize={pageSize}
            handleNewPageSize={handleNewPageSize}
          />
        )}
      </Container>
    </TableConfigContextProvider>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1400px;

  // WARNING: Do not add 'overflow-x: auto' to add
  // responsiveness to the table because it will cause issues
  // with the menus that will also overflow with a scroll !
  // Our issue happens when you filter the data and there are no results
  // then the header menus will be bigger than the table and overflow
  // Setting 'overflow-x: auto' also sets 'overflow-y' to auto
  // per the W3C spec : https://stackoverflow.com/a/6433475
`;

const TableContainer = styled.div`
  border: ${({ theme }) => theme.colors.primary.main} 2px solid;
  border-radius: 12px;
  width: 100%;
`;
