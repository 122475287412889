export interface Popup {
  id: number;
  title: string;
  content: string;
  status: PopupStatus;
}

export interface AdModal {
  title: string;
  content: string;
  status: PopupStatus;
  viewed_date: Date;
}

export enum PopupStatus {
  ACTIVE = 'active', // 'everytime untill the user disable it by clicking on the button'
  PERMANENT = 'permanent', // '1 per day, no possibility to disable it'
  INACTIVE = 'inactive', // 'not displayed'
}
