import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  SessionOption,
  SpecialtyOption,
} from 'pages/home/components/TrainingStats/SessionDropdown';

export const generalView = {
  label: 'Général',
  value: 'general',
};

export const detailBySessionView = {
  label: 'Détail par session',
  value: 'sessionDetail',
};

export const detailBySpeView = {
  label: 'Détail par spécialité',
  value: 'speDetail',
};

export const progressViewOptions = [generalView, detailBySessionView];

export type ProgressViewType =
  | typeof generalView
  | typeof detailBySessionView
  | typeof detailBySpeView;
export type SessionOrSpe = 'collection' | 'specialty';
export type QuestionType = 'DP' | 'QI' | 'LCA';

export interface ProgressState {
  view: ProgressViewType;
  sessionOrSpe: SessionOrSpe;
  groupType: QuestionType;
  selectedSession?: SessionOption;
  selectedSpe?: SpecialtyOption;
}

const initialState: ProgressState = {
  view: generalView,
  sessionOrSpe: 'collection',
  groupType: 'DP',
  selectedSession: undefined,
  selectedSpe: undefined,
};

export const ProgressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    updateView: (state, action: PayloadAction<ProgressViewType>) => {
      state.view = action.payload;
    },
    updateSessionsOrSpe: (state, action: PayloadAction<SessionOrSpe>) => {
      state.sessionOrSpe = action.payload;
    },
    toggleSessionsOrSpe: (state) => {
      state.sessionOrSpe =
        state.sessionOrSpe === 'collection' ? 'specialty' : 'collection';
    },
    updateGroupType: (state, action: PayloadAction<QuestionType>) => {
      state.groupType = action.payload;
    },
    updateSelectedSession: (state, action: PayloadAction<SessionOption>) => {
      state.selectedSession = action.payload;
    },
    updateSelectedSpe: (state, action: PayloadAction<SpecialtyOption>) => {
      state.selectedSpe = action.payload;
    },
    reset: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  updateGroupType,
  updateSessionsOrSpe,
  updateView,
  toggleSessionsOrSpe,
  updateSelectedSession,
  updateSelectedSpe,
  reset,
} = ProgressSlice.actions;

export const progressReducer = ProgressSlice.reducer;
