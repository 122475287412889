import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';
import { useCanAddToCart } from 'pages/selection/hooks/useCanAddToCart';
import { RootState } from 'store';
import { useSessionContext } from 'pages/progress/DetailBySession/sessionContext';
import { SessionOption } from 'pages/home/components/TrainingStats/SessionDropdown';
import { QuestionGroup } from 'modules/progress/detailed/shared/entities/QuestionGroup';
import { SelectionGroupResult } from 'http/selection-group/types';
import { toggleItemSelection as toggleItemSelectionDP } from 'store/slices/dpTableSlice';
import { toggleItemSelection as toggleItemSelectionLCA } from 'store/slices/lcaTableSlice';
import { Checkbox } from 'ui/Checkbox';
import { GroupType } from 'modules/shared/entities/GroupType';

interface Props {
  group: QuestionGroup;
  groupType: GroupType;
}

export const CheckboxCell: FC<Props> = ({ group, groupType }) => {
  const dispatch = useDispatch();
  const canAddToCart = useCanAddToCart();
  const isUserFree = useIsUserFree();

  const { selectedSession } = useSessionContext();

  const selectedItems = useSelector((state: RootState) =>
    groupType === 'LCA'
      ? state.lcaTable.selectedItemsInfos
      : state.dpTable.selectedItemsInfos
  );

  const [isSelected, setIsSelected] = useState<boolean>(
    selectedItems.map((item) => item.id).includes(group.id)
  );

  useEffect(() => {
    setIsSelected(selectedItems.map((item) => item.id).includes(group.id));
    const toAdd =
      group.number_of_questions >= 13
        ? { short: 0, long: 1 }
        : { short: 1, long: 0 };
    canAddToCart(groupType, 1, toAdd);
  }, [group, selectedItems, canAddToCart, groupType]);

  const toggleSelection = () => {
    if (!isSelected) {
      const canAdd = canAddToCart(groupType);
      if (!canAdd) return;
    }
    if (selectedSession) {
      dispatch(
        groupType === 'LCA'
          ? toggleItemSelectionLCA(
              adaptGroupToSelectionGroupResult(
                group,
                selectedSession,
                groupType
              )
            )
          : toggleItemSelectionDP(
              adaptGroupToSelectionGroupResult(
                group,
                selectedSession,
                groupType
              )
            )
      );
    }
  };

  return (
    <Checkbox
      checked={isSelected}
      onChange={toggleSelection}
      disabled={!group.isFree && isUserFree}
    />
  );
};

const adaptGroupToSelectionGroupResult = (
  group: QuestionGroup,
  selectedSession: SessionOption,
  type: GroupType
): SelectionGroupResult => {
  return {
    grade: group.lastGrade ?? null,
    id: group.id,
    is_free: group.isFree,
    last_training: group.previousTrainings?.[0]?.groupCorrectionId ?? null,
    last_training_date:
      group.previousTrainings?.[0]?.date.toISOString() ?? null,
    num: group.num,
    composition_unit: group.composition_unit,
    thumbnail: group.thumbnail,
    number_of_questions: group.number_of_questions,
    collection: selectedSession.value.collection,
    specialty1: group.specialtyTrigram1 ?? null,
    specialty2: group.specialtyTrigram2 ?? null,
    total_coefficient: 1,
    type: type,
    denom: group.denom,
    median: group.median,
  };
};
