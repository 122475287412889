import { FC } from 'react';
import { RouteProps, Outlet } from 'react-router-dom';

import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';
import { PremiumLockedPage } from 'components/PremiumLockedPage';

export const PremiumRoute: FC<RouteProps> = () => {
  const { isUserPremium } = useIsUserPremium();

  if (isUserPremium) {
    return <Outlet />;
  }

  return <PremiumLockedPage />;
};
