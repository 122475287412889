import styled from 'styled-components/macro';

import { Th } from 'ui/TableElements';
import { SmallDownArrow } from 'ui/icons/SmallDownArrow';

export const ExpandHeader = () => {
  return (
    <ExpandTh>
      <ExpandContainer>
        <Arrow />
      </ExpandContainer>
    </ExpandTh>
  );
};

const ExpandTh = styled(Th)`
  padding: 16px 4px;
  max-width: 16px;
`;

const ExpandContainer = styled.div`
  display: grid;
  place-items: center;
`;

const Arrow = styled(SmallDownArrow)`
  height: 12px;
  width: 12px;
  color: ${({ theme }) => theme.colors.primary.dark};
`;
