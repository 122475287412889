import { MS_IN_AN_HOUR } from 'constants/date';
import { getSourcesHttp } from './api';
import { useQuery } from 'react-query';

export const useGetSources = (codes: string[]) => {
  const getSources = async (codes: string[]) => {
    if (!codes || !codes.length) return [];
    const query = await getSourcesHttp(codes);
    return query;
  };

  const { data: sourcesInfos } = useQuery(
    ['getSources', codes],
    () => getSources(codes),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }
  );

  return sourcesInfos;
};
