import { FC } from 'react';
import styled from 'styled-components/macro';

interface Props {}

export const NoResults: FC<Props> = () => {
  return (
    <FullWidthRow>
      <NoResultsContainer>
        Aucun résultat ne correspond à votre recherche, essayez de retirer des
        items du filtre ou de rechercher avec de nouveaux mots clés
      </NoResultsContainer>
    </FullWidthRow>
  );
};

const FullWidthRow = styled.span`
  grid-column: 1/-1;
`;

const NoResultsContainer = styled.div`
  padding: 16px;
  text-align: center;
`;
