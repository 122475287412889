import { getAPI } from 'auth/authentifiedHttpCalls';
import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';
import { toast } from 'react-toastify';
import { TaskHistoryParams, TaskHistoryResponse } from './types';

export const getTaskHistory = async (params: TaskHistoryParams) => {
  try {
    const response = await getAPI<TaskHistoryResponse>(
      '/training/task_history',
      {
        params,
      }
    );
    return response.data;
  } catch (e) {
    toast.error(
      "Nous n'avons pas réussi à récupérer vos anciennes copies veuillez réessayer plus tard"
    );
    reportErrorToSentry(e);
  }
};
