import styled from 'styled-components/macro';

import { device } from 'helpers/css/responsive';

import LessonsTable from './components/LessonsTable/LessonsTable';
import { OpenedRowProvider } from './contexts/OpenedRowContext';
import { LessonsQueryParamsProvider } from './contexts/LessonsQueryParamsContext';
import { Searchbar } from './components/Searchbar';

export const Lessons = () => {
  return (
    <Container>
      <ContentContainer>
        <LessonsQueryParamsProvider>
          <OpenedRowProvider>
            <TitleRow>
              <h1>Tiroir de cours</h1>
              <Searchbar />
            </TitleRow>
            <LessonsTable />
          </OpenedRowProvider>
        </LessonsQueryParamsProvider>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.desktop} {
    padding: 32px;
  }
`;

const ContentContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media ${device.desktop} {
    gap: 32px;
  }
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
