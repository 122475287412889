import { CardsContainer } from 'pages/newHome/ui/Global';
import { HomeTrainings } from './Trainings/HomeTrainings';
import { HomeQFs } from './QuestionFlash/HomeQFs';
import { RepartitionBarData } from 'components/StatsElements/RepartitionBar';
import { SpeStatPieData } from './Trainings/HomePieChart';

interface HomeStatsProps {
  progressionQFData: SpeStatPieData[];
  progressionOverallData: RepartitionBarData;
  progressionDPData?: RepartitionBarData;
  progressionQIData?: RepartitionBarData;
  grades?: HomeStatsGrades;
}

export type HomeStatsGrades = {
  overall: number;
  dp: number;
  qi: number;
};

export const HomeStats: React.FC<HomeStatsProps> = ({
  progressionQFData,
  progressionOverallData,
  progressionDPData,
  progressionQIData,
  grades,
}) => {
  return (
    <CardsContainer nbCards={2} noPadding>
      <HomeTrainings
        overallData={progressionOverallData}
        DPData={progressionDPData}
        QIData={progressionQIData}
        grades={grades}
      />
      <HomeQFs data={progressionQFData} />
    </CardsContainer>
  );
};
