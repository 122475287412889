import { Sessions, SessionData, PriceInfos } from './types';

export function transformSessionData(sessionData: Sessions): SessionData[] {
  return Object.entries(sessionData).map(([exam, isInPackage]) => ({
    exam,
    isInPackage,
  }));
}

export function sortDataArray(data: PriceInfos[]): PriceInfos[] {
  return data.sort((a, b) => a.id - b.id);
}

export function sortDataObject(data: { [key: string]: number }): {
  [key: string]: number;
} {
  const entries = Object.entries(data);

  const sortedEntries = entries.sort(([keyA, valueA], [keyB, valueB]) => {
    return valueB - valueA; // Sort in descending order based on values
  });

  const reorderedData = sortedEntries.reduce((result, [key, value]) => {
    result[key] = value;
    return result;
  }, {} as { [key: string]: number });

  return reorderedData;
}
