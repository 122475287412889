import { useEffect } from 'react';

export const usePreventRightClick = () => {
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const preventRightClick = (event: MouseEvent) => event.preventDefault();
      document.addEventListener('contextmenu', preventRightClick);
      return () =>
        document.removeEventListener('contextmenu', preventRightClick);
    }
  }, []);
};
