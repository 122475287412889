import { FC } from 'react';
import { MenuIconProps } from './types';

export const MenuProgress: FC<MenuIconProps> = ({ isFilled }) => {
  return (
    <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 1v24h24"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={isFilled ? 2 : 1}
      />
      <path
        d="M1.375 25 8.5 19l1.5 1.5 5.25-9 2.625 3.75 6-12.75"
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth={isFilled ? 2 : 1}
      />
    </svg>
  );
};
