import { FC } from 'react';
import styled, { css } from 'styled-components/macro';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { SelectionGroupResult } from 'http/selection-group/types';
import { useGetQuestionGroup } from 'http/selection/get_question/connector';
import { Tr, Td } from 'ui/TableElements';

import { CheckboxCell } from 'pages/selection/components/CheckboxCell';
import { ExpandCell } from './components/ExpandCell';
import { useTableConfigContext } from '../../tableConfigContext';
import { Specialities } from 'pages/selection/components/Specialties';
import { LastTraining } from 'pages/selection/components/LastTraining';
import { Median } from 'pages/selection/components/Median';

import { KnowledgeRank } from 'components/Knowledge/KnowledgeRank';
import { device } from 'helpers/css/responsive';
import { GroupThumbnail } from './components/GroupThumbnail';
import { Item } from 'components/Item/Item';
import { GroupLastCorrections } from './components/GroupLastCorrections';
import { PremiumLockedRessource } from 'components/PremiumLockedRessource';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';
import { useShouldShowMedian } from 'modules/user-options/hooks/useShouldShowMedian';

interface Props {
  index: number;
  rowData: SelectionGroupResult;
}

export const QuestionGroupRow: FC<Props> = ({ index, rowData }) => {
  const isOdd = index % 2 === 1;
  const { isUserPremium } = useIsUserPremium();

  const dispatch = useDispatch();
  const { openRowCreator, tableStateSelector } = useTableConfigContext();

  const openedRowId = useSelector(
    (state: RootState) => tableStateSelector(state).openedRowId
  );

  const isOpen = openedRowId === rowData.id;

  const setIsOpen = (newIsOpen: boolean) => {
    if (newIsOpen) {
      dispatch(openRowCreator(rowData.id));
    } else {
      dispatch(openRowCreator(undefined));
    }
  };

  const { data, isLoading } = useGetQuestionGroup(
    { group_id: rowData.id },
    isOpen
  );

  const hasItemBeenDone = !!rowData.last_training;
  const shouldShowMedian = useShouldShowMedian(hasItemBeenDone);

  const totalCoeff = rowData.total_coefficient ?? rowData.denom ?? 1;
  const groupType = rowData.type === 'LCA' ? 'LCA' : 'DP';

  return (
    <>
      <StyledTr isOdd={isOdd} isOpen={isOpen}>
        <Td isCheckbox>
          <CheckboxCell itemInfos={rowData} isFree={rowData.is_free} />
        </Td>
        <ExpandCell isOpen={isOpen} setIsOpen={setIsOpen} />
        <Td>{rowData.collection}</Td>
        <Td>
          {rowData.composition_unit && `UC${rowData.composition_unit} `}
          {rowData.type}
          {rowData.num}
        </Td>
        <Td>
          <PremiumLockedRessource
            ressourceName="Contenu abonné"
            isFree={rowData.is_free}
          >
            <Specialities
              trigram1={rowData.specialty1}
              trigram2={rowData.specialty2}
              hasItemBeenDone={!!rowData.last_training}
            />
          </PremiumLockedRessource>
        </Td>
        <Td></Td>
        <Td></Td>
        <Td>
          {rowData.last_training_date && (
            <LastTraining
              dateToFormat={rowData.last_training_date}
              grade={rowData.grade}
              totalCoeff={totalCoeff}
              groupType={groupType}
            />
          )}
        </Td>
        <Td>
          <PremiumLockedRessource ressourceName="" isFree={rowData.is_free}>
            {rowData.median && shouldShowMedian && (
              <Median
                grade={rowData.median}
                totalCoeff={totalCoeff}
                groupType={groupType}
              />
            )}
          </PremiumLockedRessource>
        </Td>
      </StyledTr>

      {isOpen && (
        <>
          <TrSpacer />
          {isLoading && (
            <SubTr isFirst isLast isOdd={isOdd} isOpen={isOpen}>
              <td colSpan={10} style={{ padding: '4px 12px' }}>
                Chargement...
              </td>
            </SubTr>
          )}
          {data &&
            data.map((row, rowIndex) => (
              <SubTr
                isFirst={rowIndex === 0}
                isLast={rowIndex === data.length - 1}
                isOdd={isOdd}
                isOpen={isOpen}
                key={row.id}
              >
                {rowIndex === 0 && (
                  <td
                    colSpan={3}
                    rowSpan={data.length}
                    style={{ verticalAlign: 'top' }}
                  >
                    <SideInfosCell>
                      <GroupThumbnail groupData={rowData} />
                      {rowData.last_training && (
                        <GroupLastCorrections group={rowData} />
                      )}
                    </SideInfosCell>
                  </td>
                )}
                <SubTd>Q{rowIndex + 1}</SubTd>
                <SubTd>
                  <PremiumLockedRessource
                    ressourceName="Contenu abonné"
                    isFree={rowData.is_free}
                  >
                    <Specialities
                      trigram1={row.specialty1}
                      trigram2={row.specialty2}
                      hasItemBeenDone={hasItemBeenDone}
                    />
                  </PremiumLockedRessource>
                </SubTd>
                <SubTd>
                  {row.knowledge && (
                    <KnowledgeRank
                      label={row.knowledge.label}
                      rank={row.knowledge.rank}
                    />
                  )}
                </SubTd>
                <SubTd>
                  {row.knowledge && (
                    <PremiumLockedRessource
                      ressourceName=""
                      isFree={rowData.is_free}
                    >
                      <Item item={row.knowledge.item} />
                    </PremiumLockedRessource>
                  )}
                </SubTd>
                <SubTd>{isUserPremium && row.categories}</SubTd>
                <SubTd>
                  <PremiumLockedRessource
                    ressourceName=""
                    isFree={rowData.is_free}
                  >
                    {row.type}
                  </PremiumLockedRessource>
                </SubTd>
              </SubTr>
            ))}
          <TrSpacer />
        </>
      )}
    </>
  );
};

const TrSpacer = () => (
  <tr>
    <td colSpan={8} />
  </tr>
);

const StyledTr = styled(Tr)<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    isOpen &&
    css`
      td {
        font-weight: 700;
      }
    `}
`;

const SubTd = styled(Td)`
  text-align: center;
  vertical-align: middle;
`;

const SubTr = styled(StyledTr)<{
  isFirst?: boolean;
  isLast?: boolean;
  isOdd: boolean;
  isOpen: boolean;
}>`
  td:first-child {
    border-radius: 0;
  }
  td:last-child {
    border-radius: 0;
  }

  td {
    ${({ isOdd, theme }) =>
      css`
        background-color: ${isOdd
          ? theme.colors.primary.light
          : theme.colors.background.paper};
      `}
  }

  ${SubTd} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary.main};
    ${({ theme }) => theme.typography.textBody2};
    ${({ isOpen }) =>
      isOpen &&
      css`
        font-weight: 700;
      `}
  }

  ${({ isFirst }) =>
    isFirst &&
    css`
      td:first-child {
        border-top-left-radius: var(--row-border-radius);
        border-bottom-left-radius: var(--row-border-radius);
      }
      td:last-child {
        border-top-right-radius: var(--row-border-radius);
      }
    `}

  ${({ isLast }) =>
    isLast &&
    css`
      td:last-child {
        border-bottom-right-radius: var(--row-border-radius);
      }

      td,
      ${SubTd} {
        border-bottom: none;
      }
    `}
`;

const SideInfosCell = styled.div`
  padding: 8px 12px;
  height: 100%;
  width: max-content;

  @media ${device.largeTable} {
    padding: 16px 24px;
  }

  display: flex;
  flex-direction: column;
  gap: 8px;
`;
