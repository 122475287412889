import { getScatterPlotData } from 'http/rank-info/easy-dispo/api';
import { ScatterPlotDataType } from 'http/rank-info/easy-dispo/types';
import { FC, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ScatterPlot } from './ScatterPlot';
import { MAX_CANDIDATES } from 'pages/easy-dispo/EasyDispo';
import {
  useEasyDispoContext,
  CHUandSpeInfo,
} from '../../context/easydispoContext';

export interface DataToPlot {
  name: string;
  worstRank?: number;
  yourRank?: number;
}

export interface LooseObject {
  [key: string]: any;
}

interface Props {
  ranks?: number[];
  numOfCandidates?: number[];
  domainCentered: boolean;
}

export const GraphContainer: FC<Props> = ({
  ranks,
  numOfCandidates,
  domainCentered,
}) => {
  const [plotData, setPlotData] = useState<ScatterPlotDataType[] | undefined>(
    undefined
  );
  const [show, setShow] = useState<boolean>(false);
  const { affectationFilters } = useEasyDispoContext();
  const [concatData, setContatData] = useState<CHUandSpeInfo[]>([]);

  useEffect(() => {
    const data = affectationFilters.filter(
      (combinaison) =>
        combinaison.speCondensedLabel && combinaison.chuCondensedLabel
    );

    setContatData(data);

    const queryParams: { specialty: string; chu: string }[] = data.map(
      (combinaison) => {
        return {
          chu: combinaison.chuCondensedLabel!,
          specialty: combinaison.speCondensedLabel!,
        };
      }
    );

    const fetchData = async () => {
      const tempData = await getScatterPlotData({
        filters: queryParams,
      });

      setPlotData(tempData);
      setShow(true);
    };

    fetchData();
  }, [affectationFilters]);

  //Number of ranks max sent
  const [numOfRanks, setNumOfRanks] = useState(0);

  const translatedScatterData = useMemo(() => {
    const newData = plotData?.map((yearData, index) => {
      let res: LooseObject = {
        name: yearData.year?.toString() ?? '',
        worstRank: yearData.limit_rank === 0 ? undefined : yearData.limit_rank,
        yourRank: ranks && index < ranks.length ? ranks[index] : undefined,
        numOfCandidates:
          numOfCandidates && index < numOfCandidates.length
            ? MAX_CANDIDATES - numOfCandidates[index]
            : undefined, //Just for the correct display on the graph
      };
      if (!yearData || !yearData.ranks) return res;

      var numOfRanks = 0;
      for (let i = 0; i < yearData.ranks.length; i++) {
        res[`s${i}`] = yearData.ranks[i];
        numOfRanks++;
      }

      setNumOfRanks((maxRank) => Math.max(maxRank, numOfRanks));

      return res;
    });
    return newData ?? [];
  }, [plotData, ranks, numOfCandidates]);

  return (
    <GraphComponent id="graphComponent">
      {show && numOfRanks !== 0 ? (
        <MainBlock>
          <ScatterContainer>
            {plotData && (
              <ScatterPlot
                scatterData={translatedScatterData}
                numOfRanks={numOfRanks}
                centered={domainCentered}
              />
            )}
          </ScatterContainer>
          <Title>
            Affectations en{' '}
            {concatData.map((el, index) => (
              <div key={`${el.chuCondensedLabel} ${el.chuCondensedLabel}`}>
                {el.speLabel}
                {el.chuLabel?.slice(0, 3) === 'CHU' ? ' au ' : " à l'"}
                {el.chuLabel}
                {concatData.length === index + 1 ? '' : ' et'}
              </div>
            ))}
          </Title>
        </MainBlock>
      ) : (
        <MsgContainer>
          <Message>
            Ajoutez au moins un filtre pour afficher les affectations sous forme
            de graphique
          </Message>
        </MsgContainer>
      )}
    </GraphComponent>
  );
};

const GraphComponent = styled.div`
  position: relative;
  bottom: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.background.main};
  flex-direction: column;
  height: 500px;
  width: 95%;
  margin-bottom: 100px;
`;

const Title = styled.div`
  min-width: 100%;
  text-align: center;
  margin: 0 1em 0 1em;
  font-size: 1.2em;
  line-height: 1em;
`;

const MainBlock = styled.div`
  height: 100%;
`;

const ScatterContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const MsgContainer = styled.div`
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  color: ${({ theme }) => theme.colors.inactive};
  min-width: 50%;
`;

const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ theme }) => theme.typography.h5}
`;
