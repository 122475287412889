import { FC } from 'react';
import { ThemeProvider } from 'styled-components/macro';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider as ReduxProvider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import 'tippy.js/dist/tippy.css';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

import { store, persistor } from 'store';
import { getTheme } from 'theme';
import { Router } from 'routing/Router';
import { GlobalStyle } from 'globalStyles';
import { useThemeVariants } from 'hooks/useThemeVariants';
import { usePreventRightClick } from 'hooks/usePreventRightClick';
import { useRefreshToken } from 'auth/hooks/useRefreshToken';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { MathJaxContext } from 'better-react-mathjax';
import { TourProvider } from '@reactour/tour';
import { homeSteps } from 'utils/tourSteps';

const App: FC = () => {
  const { themeVariant } = useThemeVariants();
  const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
  });

  useRefreshToken();
  usePreventRightClick();
  return (
    <MathJaxContext version={3}>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundary>
            <QueryClientProvider client={queryClient}>
              <TourProvider steps={homeSteps}>
                <ThemeProvider theme={getTheme(themeVariant)}>
                  <GlobalStyle />
                  <Router />
                </ThemeProvider>
              </TourProvider>
            </QueryClientProvider>
          </ErrorBoundary>
        </PersistGate>
      </ReduxProvider>
    </MathJaxContext>
  );
};

export default App;
