import { RepartitionBarData } from 'components/StatsElements/RepartitionBar';
import { useGetHomeItem } from 'http/new-home/connector';
import { useHomeInfoContext } from 'pages/newHome/context/homeInfoContext';
import { HomeStats } from 'pages/newHome/components/HomeStats/HomeStats';
import { SpeStatPieData } from 'pages/newHome/components/HomeStats/Trainings/HomePieChart';
import React, { useMemo } from 'react';
import { useTheme } from 'styled-components';

const ItemStats = () => {
  const { activeItemId } = useHomeInfoContext();
  const { data } = useGetHomeItem(activeItemId?.toString());
  const theme = useTheme();

  const progressionBarDataOverall: RepartitionBarData = useMemo(() => {
    return [
      {
        id: 'correct',
        name: 'Correctes',
        value: data?.overall.success || 0,
        color: theme.colors.success.main,
      },
      {
        id: 'incorrect',
        name: 'Erronées',
        value: data?.overall.fail || 0,
        color: theme.colors.error.main,
      },
      {
        id: 'not_done',
        name: 'Non réalisées',
        value: data?.overall.not_done || 0,
        color: theme.colors.primary.main,
      },
    ];
  }, [theme, data]);

  const progressionBarDataQF: SpeStatPieData[] = useMemo(() => {
    return [
      {
        id: 'new',
        name: 'Nouvelles',
        value: data?.flash.new || 0,
        color: theme.colors.primary.main,
        order: 1,
      },
      {
        id: 'review',
        name: 'À réviser',
        value: data?.flash.review || 0,
        color: '#F5CC7F',
        order: 2,
      },
      {
        id: 'learned',
        name: 'Acquises',
        value: data?.flash.learned || 0,
        color: theme.colors.success.main,
        order: 4,
      },
      {
        id: 'ongoing',
        name: 'À revoir',
        value: data?.flash.ongoing || 0,
        color: '#325397',
        order: 3,
      },
    ];
  }, [theme, data]);

  return (
    <HomeStats
      progressionOverallData={progressionBarDataOverall}
      progressionQFData={progressionBarDataQF}
      grades={{
        overall: data?.overall.grade || 0,
        dp: data?.dp.grade || 0,
        qi: data?.qi.grade || 0,
      }}
    />
  );
};

export default ItemStats;
