import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routing/constants';
import { useGetNumberQueryParams } from 'hooks/useGetNumberQueryParam';
import { useGetCorrectionGroup } from 'http/correction/get_group/connector';
import { Correction } from './Correction';

export const GroupCorrectionPage: FC = () => {
  const groupCorrectionId = useGetNumberQueryParams('id');
  const navigate = useNavigate();

  if (!groupCorrectionId) {
    navigate(routes.START);
  }

  const { data: groupCorrection } = useGetCorrectionGroup(groupCorrectionId!);

  return (
    <Correction
      correction={
        groupCorrection
          ? {
              correction_groups: [groupCorrection],
              finished: true,
              id: 0,
              timer: { paused: false, remaining_time: 0, total_time: 0 },
              grade: 0,
              total_coefficient: 0,
              rank_A_grade: 0,
              rank_A_coefficient: 0,
            }
          : undefined
      }
    />
  );
};
