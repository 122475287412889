import { getAPI } from 'auth';
import { SessionDetailedProgressParams } from '../domain/entities/SessionDetailedProgressParams';
import { ProgressDetailedSessionResponseHttp } from './types';

export const getSessionDetailedProgressHttp = async (
  params: SessionDetailedProgressParams
) => {
  const response = await getAPI<ProgressDetailedSessionResponseHttp>(
    '/progress/detailed/session',
    {
      params,
    }
  );
  return response.data;
};
