function capitalizeFirstLetter(name: string): string {
  name = name?.toLowerCase();

  const words = name.split(/-|\s/);

  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  const capitalizedName = capitalizedWords
    .join('-')
    .replace(/(?:^|\s)\S/g, (char) => char.toUpperCase());

  return capitalizedName;
}

export default capitalizeFirstLetter;
