const ECNI_TOTAL_POINTS = 420;

export function computeQuestionTotalPoints({
  totalCoefficient,
  questionCoefficient,
  questionGrade,
}: {
  totalCoefficient: number;
  questionCoefficient: number;
  questionGrade: number;
}): string {
  const scaledGrade = (questionGrade / totalCoefficient) * ECNI_TOTAL_POINTS;
  return scaledGrade.toLocaleString('fr-FR', { maximumFractionDigits: 2 });
}
