import styled from 'styled-components/macro';

import { device } from 'helpers/css/responsive';

export const MenuGroup = styled.div`
  @media ${device.desktop} {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
`;
