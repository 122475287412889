import { FC } from 'react';
import styled from 'styled-components/macro';
import Tippy from '@tippyjs/react';

import { HelpTooltip } from 'ui/icons/HelpTooltip';
import { device } from 'helpers/css/responsive';

export interface Props {
  label: string;
  tooltip?: string;
  canWrap?: boolean;
}

export const OptionLayout: FC<Props> = ({
  children,
  label,
  tooltip,
  canWrap,
}) => {
  return (
    <Row canWrap={canWrap}>
      <Name>
        <label>{label}</label>
        {tooltip && (
          <Tippy content={tooltip}>
            <IconContainer>
              <HelpTooltip />
            </IconContainer>
          </Tippy>
        )}
      </Name>
      {children}
    </Row>
  );
};

const Row = styled.div<{ canWrap?: boolean }>`
  display: flex;
  column-gap: 33px;
  row-gap: 8px;
  align-items: center;
  flex-wrap: ${({ canWrap }) => (canWrap ? 'wrap' : 'nowrap')};

  @media ${device.desktop} {
    column-gap: 38px;
  }
`;

const Name = styled.div`
  display: inline-block;
  flex-basis: 284px;
  flex-grow: 0;
`;

const IconContainer = styled.div`
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-left: 8px;
`;
