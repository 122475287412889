import { patchAPI } from 'auth';
import { SaveQuestionQRPLRequestType } from './types';

export const saveQuestionQRPL = async (params: SaveQuestionQRPLRequestType) => {
  const response = await patchAPI<void, SaveQuestionQRPLRequestType>(
    '/training/save_question_qrpl',
    params
  );

  return response.data;
};
