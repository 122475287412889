import { getAPI } from 'auth';
import { SpeDetailedProgressParams } from '../domain/entities/SpeDetailedProgressParams';
import { ProgressDetailedSpeResponseHttp } from './types';

export const getSpeDetailedProgressHttp = async (
  params: SpeDetailedProgressParams
) => {
  const response = await getAPI<ProgressDetailedSpeResponseHttp>(
    '/progress/detailed/specialty',
    {
      params,
    }
  );
  return response.data;
};
