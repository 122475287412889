import { Item } from 'types/item.type';
import { SpecialtyChartData } from '../components/DPQIView/SpecialitiesBarChart';

export const itemsMocks: Item[] = [
  {
    item: 3,
    short_title: 'Raisonnement et décision médicale, EBM',
    title:
      'Le raisonnement et la décision en médecine. La médecine fondée sur les preuves (Evidence Based Medicine, EBM). La décision médicale partagée. La controverse.',
    tag_and_title:
      'N3: Le raisonnement et la décision en médecine. La médecine fondée sur les preuves (Evidence Based Medicine, EBM). La décision médicale partagée. La controverse.',
  },
  {
    item: 267,
    short_title: 'Troubles hydro-électrolytiques et acido-basiques',
    title:
      "Troubles de l'équilibre acido-basique et désordres hydro-électrolytiques.",
    tag_and_title:
      "N267: Troubles de l'équilibre acido-basique et désordres hydro-électrolytiques.",
  },
  {
    item: 93,
    short_title:
      'Compression médullaire non traumatique et syndrome de la queue de cheval',
    title:
      'Compression médullaire non traumatique et syndrome de la queue de cheval.',
    tag_and_title:
      'N93: Compression médullaire non traumatique et syndrome de la queue de cheval.',
  },
];

export const chartDataMocks: SpecialtyChartData[] = [
  {
    trigram: 'NEU',
    name: 'Neurologie',
    color: '#6D9EEB',
    count: 3,
  },
  {
    trigram: 'NEP',
    name: 'Néphrologie',
    color: '#6AA84F',
    count: 3,
  },
  {
    trigram: 'DEP',
    name: 'Essai de dépistage',
    color: '#8E7CC3',
    count: 1,
  },
  {
    trigram: 'MII',
    name: 'Médecine interne et immunologie',
    color: '#8E7CC3',
    count: 1,
  },
];
