import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { passwordRegex } from './passwordRegex';
import { APIError, postAPI } from 'auth/authentifiedHttpCalls';
import { useGoToAuthConfirmation } from 'pages/auth-confirmation/hooks/useGoToAuthConfirmation';
import { toast } from 'react-toastify';
import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';

interface SignupFormInputs {
  first_name: string;
  last_name: string;
  promotion: string;
  university: string;
  email: string;
  password1: string;
  password2: string;
  checkbox: boolean;
  newsletter: boolean;
}

const signupFormSchema = yup.object({
  first_name: yup.string().required('Veuillez renseigner votre prénom'),
  last_name: yup.string().required('Veuillez renseigner votre nom'),
  promotion: yup.string().required('Veuillez renseigner votre promotion'),
  university: yup.string().required('Veuillez renseigner votre faculté'),
  email: yup
    .string()
    .email('Veuillez renseigner un email valide')
    .required('Veuillez renseigner votre adresse email'),
  password1: yup
    .string()
    .required('Veuillez renseigner un mot de passe')
    .min(8, 'Le mot de passe doit faire plus de 8 caractères')
    .matches(
      passwordRegex,
      'Le mot de passe doit contenir au moins un chiffre, une lettre majuscule et une lettre minuscule'
    ),
  password2: yup
    .string()
    .required('Veuillez confirmer votre mot de passe')
    .oneOf(
      [yup.ref('password1')],
      'Les deux mots de passe ne sont pas identiques'
    ),
  checkbox: yup
    .bool()
    .oneOf([true], "Veuillez accepter les conditions d'utilisations")
    .required("Veuillez accepter les conditions d'utilisations"),
  newsletter: yup.bool(),
});

export const useSignupForm = () => {
  return useForm<SignupFormInputs>({
    resolver: yupResolver(signupFormSchema),
  });
};

interface SignupType {
  username: string;
  email: string;
  password1: string;
  password2: string;
  first_name: string;
  last_name: string;
  promotion: string;
  university: string;
  newsletter: boolean;
}

export const useOnSignupSubmit = () => {
  const gotToAuthConfirmation = useGoToAuthConfirmation();

  const onSignupSubmit: SubmitHandler<SignupFormInputs> = async (data) => {
    const requestBody = formatRequestBody(data);

    try {
      await postAPI<void, SignupType>('/auth/signup/', requestBody);
      gotToAuthConfirmation({
        title: 'Votre compte a bien été créé',
        subtitle: 'Un email de vérification vous a été envoyé',
      });
    } catch (e) {
      if (e instanceof Error) {
        const err = e as APIError;
        const errorMessages = err.response?.data;
        if (typeof errorMessages === 'object') {
          try {
            Object.keys(errorMessages).forEach((key: string) => {
              errorMessages[key].map((el: string) => toast.error(el));
            });
          } catch (e) {
            toast.error(
              "Une erreur inconnue s'est produite, veuillez réessayer plus tard"
            );
            reportErrorToSentry(e);
          }
        } else {
          toast.error(
            "Une erreur inconnue s'est produite, veuillez réessayer plus tard"
          );
        }
      }
      reportErrorToSentry(e);
    }
  };

  return onSignupSubmit;
};

const formatRequestBody = (data: SignupFormInputs): SignupType => {
  const body = {
    username: data.email,
    email: data.email,
    password1: data.password1,
    password2: data.password2,
    first_name: data.first_name,
    last_name: data.last_name,
    promotion: data.promotion,
    university: data.university,
    newsletter: data.newsletter,
  };

  return body;
};
