import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  answer: string;
  onChange(answer: string): void;
  disabled: boolean;
}

export const SingleAnswer: FC<Props> = (props) => {
  return (
    <Container>
      <InputHeader>Réponse</InputHeader>
      <Input
        type={'text'}
        value={props.answer}
        placeholder="Tapez votre réponse ici"
        onChange={(e) => props.onChange(e.target.value)}
        disabled={props.disabled}
      ></Input>
    </Container>
  );
};

const InputHeader = styled.div`
  border-radius: 3px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 8px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  font-family: 'Freesans';
  background-color: #183876;
  min-height: 36px;
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;
const Container = styled.div`
  box-sizing: border-box;
  border-radius: 4px;
  line-height: 0;
`;

const Input = styled.input`
  width: calc(100% - 3px);
  margin-left: 2px;

  background: white;
  border-radius: 3px;
  border: none;
  width: 100%;
  font-family: 'FreeSans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 12px 8px;
  /* box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) -1px 2px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset; */
  border: 2px solid #99989a;
  border-top: none;
`;
