import { PieChartData } from '../components/DPQIView/TagsTypePieCharts';
import { StringCounter } from '../hooks/useStartChartsData/types';

export const adaptCounterToTypeChartData = (
  typeCounter: StringCounter
): PieChartData[] => {
  const types = Object.keys(typeCounter);

  return types.map((type) => ({
    name: type,
    value: typeCounter[type],
  }));
};
