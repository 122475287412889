import { useGetUserOptions } from './useGetUserOptions';

export const useIsUserPremium = (
  {
    isOptimist,
  }: {
    isOptimist?: boolean;
  } = { isOptimist: false }
): { isUserPremium: boolean; isUserPremiumUnderAMonth: boolean } => {
  const { data: userOptions } = useGetUserOptions();
  const currentDate = new Date();

  const isUserPremium = userOptions?.isUserPremium ?? isOptimist ? true : false;

  const isUserPremiumUnderAMonth =
    isUserPremium && userOptions?.endPremium
      ? new Date(userOptions.endPremium).getTime() - currentDate.getTime() >
        30 * 24 * 60 * 60 * 1000
        ? false
        : true
      : false;
  return { isUserPremium, isUserPremiumUnderAMonth };
};

export const useIsUserFree = (): boolean => {
  const { data: userOptions } = useGetUserOptions();
  const isUserPremium = userOptions?.isUserPremium ? false : true;

  return isUserPremium;
};
