import { useGetEstimatedDurations } from 'modules/user-options/hooks/useGetEstimatedDurations';
import { useGetUserOptions } from 'modules/user-options/hooks/useGetUserOptions';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchFormatedSessionsStatus,
  fetchFormatedCCBStats,
} from 'store/slices/ccbSlice';
import { DisplayedPage } from './DisplayedPage';

export const ConcoursBlanc = () => {
  const estimatedDurations = useGetEstimatedDurations();
  const { data: userOptions } = useGetUserOptions();
  const { statsActivePromotion } = useSelector((state: any) => state.ccb);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userOptions && estimatedDurations) {
      dispatch(
        fetchFormatedSessionsStatus({
          trainingDuration: userOptions.trainingDuration,
          ucDuration: estimatedDurations.ucDuration,
        })
      );
    }
  }, [dispatch, userOptions, estimatedDurations]);

  useEffect(() => {
    dispatch(
      fetchFormatedCCBStats({
        promotion: statsActivePromotion?.code,
      })
    );
  }, [dispatch, statsActivePromotion]);

  return <DisplayedPage />;
};
