import styled, { useTheme } from 'styled-components';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from 'recharts';

import BlueCard from 'ui/Cards/BlueCard';
import {
  Dot,
  SubTitleText,
} from 'pages/concours-blanc/components/StatisticsPage/Stats/RepartitionLca/RepartitionLca';
import { SubTitle } from 'pages/concours-blanc/components/StatisticsPage/Stats/CCBStatsAndGrades';
import { Legend, Legends } from 'ui/Graph/Legends';
import { Section } from '../ui/Global';
import { useHomeInfoContext } from '../context/homeInfoContext';
import { useGetHomeItem, useGetHomeSpecialty } from 'http/new-home/connector';
import { useMemo } from 'react';

interface CustomTickProps {
  x: number;
  y: number;
  payload: {
    value: string;
  };
}

const CustomXTick: React.FC<CustomTickProps> = ({ x, y, payload }) => {
  const parts = payload.value.split(' ');
  return (
    <g transform={`translate(${x},${y + 12})`}>
      <text
        x={0}
        y={0}
        dy={16}
        dominantBaseline="middle"
        textAnchor="middle"
        fill="#666"
        fontWeight={400}
        fontSize={14}
        style={{ fontFamily: 'inherit' }}
      >
        {parts.map((part, index) => (
          <tspan key={index} x="0" dy={index === 0 ? 0 : 15}>
            {part}
          </tspan>
        ))}
      </text>
    </g>
  );
};

export const AboutTheItem = () => {
  const theme = useTheme();
  const { activeItemId, activeSpecialtyId, activeTab } = useHomeInfoContext();
  // const itemInfo = useItemInfo(itemId)
  const { data: speData } = useGetHomeSpecialty(activeSpecialtyId || '');

  const { data: itemData } = useGetHomeItem(activeItemId?.toString());

  const data = useMemo(() => {
    if (activeTab === 'specialties' && speData && speData.collections) {
      return speData.collections.map((session) => ({
        name: session.label,
        dp: session.dp,
        qi: session.qi,
      }));
    } else if (activeTab === 'items' && itemData && itemData.collections) {
      return itemData.collections.map((session) => ({
        name: session.label,
        dp: session.dp,
        qi: session.qi,
      }));
    }
    return [];
  }, [activeTab, speData, itemData]);

  const maxValue = useMemo(() => {
    const allValues = data.flatMap((item) => [item.dp, item.qi]);
    const max = Math.max(...allValues);
    return Math.ceil(max / 5) * 5; // Round up to nearest multiple of 5
  }, [data]);

  const generateYAxisTicks = useMemo(() => {
    const ticks = [];
    for (let i = 0; i <= maxValue; i += 5) {
      ticks.push(i);
    }
    return ticks;
  }, [maxValue]);

  if (data.length === 0) return null;

  return (
    <BlueCard $bigPadding fullWidth maxWidth="100%">
      <Section>
        <TopContainer>
          <SubTitle>Répartition du nombre de questions par session</SubTitle>
        </TopContainer>
        <Legends>
          <StyledLegend>
            <Dot fillColor={theme.colors.warning.dark} />
            <SubTitleText>DP & KFP</SubTitleText>
          </StyledLegend>
          <StyledLegend>
            <Dot fillColor={'#EF9198'} />
            <SubTitleText>QI</SubTitleText>
          </StyledLegend>
        </Legends>
        <Container width="100%" height={400}>
          <BarChart width={730} height={250} data={data} barCategoryGap={'50%'}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              axisLine={false}
              tickLine={false}
              dataKey="name"
              interval={0}
              height={60}
              tick={(props) => <CustomXTick {...props} />}
            />
            <YAxis
              axisLine={false}
              width={16}
              tickLine={false}
              fontSize={14}
              ticks={generateYAxisTicks}
              domain={[0, maxValue]}
            />{' '}
            <Tooltip
              cursor={{ fill: 'rgba(0, 0, 0, 0.1)' }}
              contentStyle={{
                background: 'white',
                border: 'none',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              }}
            />
            <Legend />
            <Bar barSize={16} dataKey="dp" fill={theme.colors.warning.dark} />
            <Bar barSize={16} dataKey="qi" fill="#EF9198" />
          </BarChart>
        </Container>
      </Section>
    </BlueCard>
  );
};

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
`;

export const StyledLegends = styled(Legends)`
  gap: 1rem;
  justify-content: start;
`;

const StyledLegend = styled(Legend)`
  width: auto;
`;

const Container = styled(ResponsiveContainer)`
  margin-top: 3rem;
  margin-left: 0;
  height: 100%;
  width: 100%;
`;
