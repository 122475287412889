import { StatCard } from 'pages/newHome/ui/StatCard';
import { FC } from 'react';
import { NewRecapItem } from 'http/new-home/types';
import { useAccount } from 'pages/account/useAccount';
import { useNavigate } from 'react-router-dom';
import { formatDate } from 'helpers/dates/utils';

interface Props {
  item: NewRecapItem;
}

export const ItemCard: FC<Props> = ({ item }) => {
  const { userOptions } = useAccount();
  const navigate = useNavigate();

  const DoneRepartitionDP = [
    {
      id: 'correct',
      name: 'correctes',
      value: item.group_success,
      color: 'green',
    },
    {
      id: 'incorrect',
      name: 'erronées',
      value: item.group_fail,
      color: '#A22F2B',
    },
    {
      id: 'not_done',
      name: 'non réalisées',
      value: item.group_not_done,
      color: '#A5C7EF',
    },
  ];
  const DoneRepartitionQF = [
    {
      id: 'new',
      name: 'Done',
      value: item.flash_done,
      color: 'black',
    },
    {
      id: 'review',
      name: 'Not done',
      value: item.flash_not_done,
      color: '#A5C7EF',
    },
  ];
  const DoneRepartitionQI = [
    {
      id: 'correct',
      name: 'Done',
      value: item.qi_fail + item.qi_success,
      color: 'black',
    },
    {
      id: 'incorrect',
      name: 'Not done',
      value: item.qi_not_done,
      color: '#A5C7EF',
    },
  ];

  return (
    <StatCard
      onCardClick={() => {
        navigate(`/?selected=items&activeItem=${item.item.item}`);
      }}
      cardType="item"
      title={`${item.item.item} - ${item.item.short_title}`}
      id={item.item.item}
      seeGrade={userOptions?.home_see_grades ?? true}
      grade={item.grade}
      DoneRepartitionDP={DoneRepartitionDP}
      DoneRepartitionQI={DoneRepartitionQI}
      DoneRepartitionQF={DoneRepartitionQF}
      numBooks={item.num_books}
      numLessons={item.num_lessons}
      numDP={item.num_groups}
      numQI={item.num_qis}
      numQF={item.num_flash}
      lastRevisionDate={item.last_try ? formatDate(item.last_try) : ''}
      isFav={item.favorite}
      studyTour={item.round}
      booksTitles={item.books?.map((book) => book.short_title)}
    />
  );
};
