import { getAPI } from 'auth';
import { Lesson } from 'types/lesson.type';

export const getLessonsHttp = async () => {
  const response = await getAPI<Lesson[]>('/common/lessons');
  return response.data;
};

export const getLessonHttp = async (lessonIds: string[]) => {
  const response = await getAPI<Lesson[]>('/common/lessons', {
    params: { lesson: lessonIds },
  });
  return response.data;
};
