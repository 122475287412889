import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { Button } from 'ui/Button';

import { GoBackBlock } from '../local-ui/GoBackBlock';
import { FQStats } from './FQStats/FQStats';
import { useDispatch } from 'react-redux';
import {
  reset,
  selectChosenDeckInfos,
  selectDeck,
  selectDeckStatus,
  setMode,
} from 'store/slices/fqCurrentSlice';
import { FQMode } from 'pages/flash-questions/types';
import { useEffect } from 'react';
import { getDeck } from 'store/actions/fqCurrentActions';
import { Status } from 'store/types';
import { Loader } from 'components/Loader/Loader';
import { useQueryClient } from 'react-query';

const { PENDING, REJECTED, RESOLVED } = Status;
// TODO it can be a good idea to create a route for this page
// TODO: fetch from backend
export const FQLaunchPage = () => {
  const dispatch = useDispatch();
  const deck = useSelector(selectDeck);
  const deckStatus = useSelector(selectDeckStatus);
  const client = useQueryClient();
  const chosenDeckInfos = useSelector(selectChosenDeckInfos);

  useEffect(() => {
    if (deckStatus === Status.IDLE) {
      dispatch(getDeck());
    }
  }, [dispatch, chosenDeckInfos?.name, deckStatus]);

  const presentListing = () => {
    client.invalidateQueries('fqDecksData');
    dispatch(setMode({ mode: FQMode.DECKLIST }));
    dispatch(reset());
  };

  const startTraining = () => {
    dispatch(setMode({ mode: FQMode.DECKSTARTED }));
  };

  return (
    <Container>
      <Layout>
        <TitleRow>
          {deckStatus === PENDING && <p>Chargement du titre...</p>}
          {deckStatus === REJECTED && <p>Erreur lors du chargement du deck</p>}
          {deckStatus === RESOLVED && deck && <h1>{deck.label}</h1>}
          <GoBackBlock goBackFunction={presentListing} />
        </TitleRow>

        {deckStatus === PENDING && <Loader Container={LoaderContainer} />}
        {deckStatus === REJECTED && <p>Erreur lors du chargement du deck</p>}
        {deckStatus === RESOLVED && deck && <FQStats deck={deck} />}

        <ButtonContainer>
          <SButton
            disabled={deckStatus === PENDING || deckStatus === REJECTED}
            onClick={startTraining}
          >
            Lancer mon entraînement
          </SButton>
        </ButtonContainer>
      </Layout>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 32px;
  overflow: hidden;
`;

const Layout = styled.div`
  width: 100%;
  max-width: 1300px;

  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const TitleRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 3%;
`;

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ButtonContainer = styled.div`
  margin-left: 0.5em;
  margin-right: 0.5em;
  align-self: center;
  align-items: center;
  justify-content: center;
`;

const SButton = styled(Button)`
  width: 100%;
`;
