import axios from 'axios';
import { buildApiUrl } from 'http/utils';
import { PasswordResetResponse, PasswordResetBody } from './types';

export const requestEmailPasswordReset = async (email: string) => {
  const route = buildApiUrl('/auth/password_reset/');
  const body: PasswordResetBody = {
    email,
  };

  const axiosResponse = await axios.post<PasswordResetResponse>(route, body);

  return axiosResponse.data;
};
