import { FC } from 'react';
import styled from 'styled-components';
import { Modal, ModalHeader } from 'ui/Modal';
import { Knowmore } from '../InfoPage/InfoBlocks/Knowmore';
import { WhyParticipate } from '../InfoPage/InfoBlocks/WhyParticipateInfo';
import { ButtonBase } from 'ui/Buttons/ButtonBase';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
}

export const InfoModal: FC<Props> = ({ isOpen, closeModal }) => {
  return (
    <Modal isOpen={isOpen} onClose={closeModal}>
      <ModalHeader onCloseButtonClick={closeModal} />
      <ScrollableContainer>
        <Knowmore isSmall={true} />
        <WhyParticipate />
        <Center>
          <ButtonBase
            styled={'primary'}
            size={'large'}
            disabled={false}
            iconOnly={false}
            onClick={closeModal}
            hasPadding={true}
          >
            Fermer
          </ButtonBase>
        </Center>
      </ScrollableContainer>
    </Modal>
  );
};

const ScrollableContainer = styled.div`
  overflow: auto;
  padding-right: 4px;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
`;
