import { FC } from 'react';
import { useTableFilters } from 'hooks/useTableFilter';
import { useLessonsQueryParamsContext } from 'pages/lessons/contexts/LessonsQueryParamsContext';
import FilterLayout from '../FilterLayout';

const RankFilterHeader: FC = () => {
  const { knowledgesRank, setKnowledgesRank, sortOrder, setSortOrder } =
    useLessonsQueryParamsContext();

  const rankOptions = [
    {
      label: 'A',
      value: 'A',
    },
    {
      label: 'B',
      value: 'B',
    },
    {
      label: 'C',
      value: 'C',
    },
  ];

  const {
    searchInput,
    setSearchInput,
    filteredOptions,
    toggleFilter,
    isFilterSelected,
    isFiltering,
  } = useTableFilters(rankOptions, knowledgesRank, setKnowledgesRank);

  return (
    <FilterLayout
      title={'Rang'}
      searchInput={searchInput}
      setSearchInput={setSearchInput}
      filteredOptions={filteredOptions}
      toggleFilter={toggleFilter}
      isFilterSelected={isFilterSelected}
      isFiltering={isFiltering}
      sortOrder={sortOrder}
      setSortOrder={setSortOrder}
    />
  );
};

export default RankFilterHeader;
