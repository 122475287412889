import { FC, useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Knowledge } from 'types/knowledge.type';
import RankLetter from 'ui/Misc/RankLetter';
import { Rank } from 'types/knowledge.type';
import { Tooltip } from 'components/Tooltips/Tooltip';

interface Props {
  knowledges: Knowledge[];
}

export const MultipleKnowledgeRank: FC<Props> = ({ knowledges }) => {
  const [concentratedKnowledges, setConcentratedKnowledges] = useState<
    { rank: Rank; labels: string[] }[] | undefined
  >(undefined);

  useEffect(() => {
    const concentrateKnowledge = (
      knowledges: Knowledge[]
    ): { rank: Rank; labels: string[] }[] | undefined => {
      if (knowledges.length > 0) {
        const result = knowledges.reduce(
          (
            accumulator: { rank: Rank; labels: string[] }[],
            currentValue: Knowledge
          ) => {
            const existingEntry = accumulator.find(
              (entry: any) => entry.rank === currentValue.rank
            );

            if (existingEntry) {
              existingEntry.labels.push(currentValue.label);
            } else {
              accumulator.push({
                rank: currentValue.rank,
                labels: [currentValue.label],
              });
            }

            return accumulator;
          },
          []
        );

        return result;
      } else {
        return undefined;
      }
    };
    const formatedKnowledge = concentrateKnowledge(knowledges);
    setConcentratedKnowledges(formatedKnowledge);
  }, [knowledges]);

  if (!knowledges) {
    return <></>;
  }

  return (
    <Container>
      {concentratedKnowledges?.map((concentratedKnowledge, index) => (
        <Tooltip
          content={concentratedKnowledge?.labels}
          arrow={false}
          placement="bottom"
          key={`concentratedKnowledge${index}`}
          tooltipType={'list'}
        >
          <RankLetter letter={concentratedKnowledge.rank}>
            {concentratedKnowledge.rank}
          </RankLetter>
        </Tooltip>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 4px;
`;
