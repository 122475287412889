import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { RootState } from 'store';

export const LCASelectionCount = () => {
  const lcaSelectedItems = useSelector(
    (state: RootState) => state.lcaTable.selectedItemsInfos.length
  );

  return <Container>Ma sélection : {lcaSelectedItems} LCA</Container>;
};

const Container = styled.h2`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 16px;
`;
