import styled, { css, useTheme } from 'styled-components/macro';
import { device } from 'helpers/css/responsive';
import { ButtonHTMLAttributes, FC } from 'react';
import { Oval } from 'react-loader-spinner';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  small?: boolean;
  fullWidth?: boolean;
  variant?: 'outlined' | 'contained';
}

export const Button: FC<Props> = ({
  isLoading,
  children,
  small,
  fullWidth,
  variant = 'contained',
  ...rest
}) => {
  const isOnDesktop =
    window.matchMedia && window.matchMedia(device.desktop).matches;
  const theme = useTheme();

  const spinnerSize = small ? (isOnDesktop ? 14 : 8) : isOnDesktop ? 24 : 19;

  return (
    <RawButton
      small={small}
      fullWidth={fullWidth}
      variant={variant}
      disabled={isLoading}
      {...rest}
    >
      {children}
      {isLoading && (
        <Oval
          height={`${spinnerSize}px`}
          width={`${spinnerSize}px`}
          color={theme.colors.primary.contrastText}
          wrapperStyle={{
            display: 'inline-block',
            width: `${spinnerSize}px`,
            height: `${spinnerSize}px`,
          }}
        />
      )}
    </RawButton>
  );
};

const RawButton = styled.button<{
  small?: boolean;
  fullWidth?: boolean;
  variant: 'outlined' | 'contained';
}>`
  background: ${({ theme, variant }) =>
    variant === 'outlined' ? 'transparent' : theme.colors.primary.dark};
  cursor: pointer;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  &:disabled {
    cursor: default;
    background: ${({ theme }) => theme.colors.inactive};
  }

  font-size: 16px;
  line-height: 19px;
  color: ${({ theme, variant }) =>
    variant === 'outlined'
      ? theme.colors.primary.dark
      : theme.colors.primary.contrastText};
  border: ${({ theme, variant }) =>
    variant === 'outlined' ? `2px solid ${theme.colors.primary.dark}` : 'none'};

  padding: 0.5rem 1.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.cta.mobile};
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  ${({ small }) =>
    small &&
    css`
      padding: 0.4rem 0.25rem !important;
      border-radius: 8px !important;
    `}

  @media ${device.desktop} {
    font-size: 20px;
    line-height: 24px;

    padding: 0.75rem 2.25rem;
    border-radius: ${({ theme }) => theme.borderRadius.cta.desktop};
  }

  &:hover {
    background: ${({ theme }) => theme.colors.primary.dark};
    color: ${({ theme, variant }) =>
      variant === 'outlined'
        ? theme.colors.primary.contrastText
        : theme.colors.primary.contrastText};
  }
`;
