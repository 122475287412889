import { FC } from 'react';
import parse from 'html-react-parser';
import styled from 'styled-components';

import { Checkbox } from '../ui/Checkbox';
import { StyledMathJax } from 'ui/MathJax';

interface Props {
  title: string;
  text: string;
  className?: string;
  checked?: boolean;
  onChange?(e: boolean): void;
  disabled?: boolean;
}

export const SingleAnswer: FC<Props> = (props) => (
  <Container className={props.className}>
    <AnswerTitleContainer checked={!!props.checked}>
      <AnswerTitle
        onChange={props.onChange}
        checked={props.checked}
        disabled={props.disabled}
      >
        <StyledMathJax inline>{parse(props.title)}</StyledMathJax>
      </AnswerTitle>
    </AnswerTitleContainer>
    <FullWidthContainer>
      <AnswerText>
        <StyledMathJax inline>{parse(props.text)}</StyledMathJax>
      </AnswerText>
    </FullWidthContainer>
  </Container>
);

const FullWidthContainer = styled.div`
  width: 100%;
  padding: 12px 8px;

  /* box-shadow: rgba(6, 24, 44, 0.4) 0px 0px 0px 2px,
    rgba(6, 24, 44, 0.65) -1px 2px 6px -1px,
    rgba(255, 255, 255, 0.08) 0px 1px 0px inset; */
  border: 2px solid #99989a;
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
`;
const Container = styled.div`
  width: 100%;

  border-radius: 3px;
  margin-bottom: 1rem;
  /* border: 1px solid #d7d7d7; */
`;

const AnswerTitleContainer = styled.div<{ checked: boolean }>`
  background-color: ${({ checked }) => (checked ? '#183876' : '#cfe8fd;')};
  border-radius: 3px;
  padding: 8px;

  span {
    color: ${({ checked }) => (checked ? '#fff' : '#222529;')};
    font-size: 16px;
    font-weight: bold;
    font-family: 'Freesans';
  }
`;

const AnswerTitle = styled(Checkbox)``;
AnswerTitle.displayName = 'AnswerTitle';

const AnswerText = styled.label`
  font-family: 'FreeSans';
  font-size: 14px;
  font-weight: normal;
`;
