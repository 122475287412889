import { FC } from 'react';
import styled from 'styled-components/macro';
import { PreviewBlockProps } from '../../types';

export const QRPLPreviewBlock: FC<PreviewBlockProps> = ({ questionData }) => {
  return (
    <PropositionsContainer>
      {questionData.items_qrpl?.map((item) => (
        <Proposition key={item.text}>
          <span>{item.text}</span>
        </Proposition>
      ))}
    </PropositionsContainer>
  );
};

const PropositionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  margin-top: 32px;
`;

const Proposition = styled.div`
  padding-bottom: 10px;
  width: 100%;

  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.light};
`;
