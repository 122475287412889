import { FC } from 'react';
import {
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  Label,
  DotProps,
} from 'recharts';
import styled, { useTheme } from 'styled-components';
import { roundGrade } from 'helpers/grades/utils';
import {
  StyledTooltip,
  TooltipLine,
  TooltipText,
} from 'ui/Graph/CustomTooltip';

interface RepartitionGradesUCProps {
  data: any;
  gradeUC1: number;
  gradeUC2: number;
  gradeUC3: number;
}

interface DataPoint {
  grade: number;
  count_lca: number;
  count_lca1: number;
  count_lca2: number;
}

const CustomTooltip: React.FC<any> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <StyledTooltip>
        <TooltipLine>
          <TooltipText>{`${payload[0].payload.grade}/20`}</TooltipText>
        </TooltipLine>
        <TooltipLine>
          <TooltipText>{`UC1 : ${payload[0].payload.count_uc1} participants`}</TooltipText>
        </TooltipLine>
        <TooltipLine>
          <TooltipText>{`UC2 : ${payload[0].payload.count_uc2} participants`}</TooltipText>
        </TooltipLine>
        <TooltipLine>
          <TooltipText>{`UC3 : ${payload[0].payload.count_uc3} participants`}</TooltipText>
        </TooltipLine>
      </StyledTooltip>
    );
  }
  return null;
};

export const RepartitionGradesUC: FC<RepartitionGradesUCProps> = ({
  data,
  gradeUC1,
  gradeUC2,
  gradeUC3,
}) => {
  const theme = useTheme();
  const CustomDot: FC<
    DotProps & { gradeToMatch: number; payload?: DataPoint }
  > = (props) => {
    const { cx, cy, gradeToMatch } = props;
    // Only render the dot if it's at the predefined grade value
    if (props.payload && props.payload.grade === gradeToMatch) {
      return (
        <circle cx={cx} cy={cy} r={4} fill={theme.colors.secondary.main} />
      );
    }
    return null;
  };

  return (
    <Container width="100%" height={400}>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 50,
        }}
      >
        <CartesianGrid horizontal={false} stroke="#576068" />
        <XAxis
          dataKey="grade"
          axisLine={false}
          tickLine={false}
          tickMargin={15}
          stroke={theme.colors.inactive}
          fontWeight={400}
          fontSize={14}
        >
          <Label
            value="Note"
            position="bottom"
            fontSize={16}
            fontWeight={400}
            style={{ textAnchor: 'middle' }}
            fill="black"
            offset={20}
          />
        </XAxis>
        <YAxis
          axisLine={false}
          tickLine={false}
          tickMargin={15}
          stroke={theme.colors.inactive}
          fontWeight={400}
          fontSize={14}
          range={[0, 20]}
        >
          <Label
            value="Nombre de participants"
            angle={-90}
            position="insideLeft"
            fontSize={16}
            fontWeight={400}
            style={{ textAnchor: 'middle' }}
            fill="black"
            offset={-5}
          />
        </YAxis>
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="monotone"
          dataKey="count_uc1"
          stroke={theme.colors.warning.dark}
          strokeWidth={2}
          dot={<CustomDot gradeToMatch={roundGrade(gradeUC1)} />}
        />
        <Line
          type="monotone"
          dataKey="count_uc2"
          stroke={'#EF9198'}
          strokeWidth={2}
          dot={<CustomDot gradeToMatch={roundGrade(gradeUC2)} />}
        />
        <Line
          type="monotone"
          dataKey="count_uc3"
          stroke={theme.colors.success.main}
          strokeWidth={2}
          dot={<CustomDot gradeToMatch={roundGrade(gradeUC3)} />}
        />
      </LineChart>
    </Container>
  );
};

const Container = styled(ResponsiveContainer)`
  margin-top: 3rem;
`;
