import styled from 'styled-components/macro';

import { Table, Thead, Th, Tbody, Tr } from 'ui/TableElements';

import { TableLoading } from 'pages/selection/components/TableLoading';

import { sortBy, setPageSize, setPage } from 'store/slices/decksTableSlice';
import { RootState } from 'store';

import { TableError } from 'pages/selection/components/TableError';
import { DeckHeader } from '../table-headers/DeckHeader';
import { NewCardHeader } from '../table-headers/NewCardHeader';
import { DailyCardsHeader } from '../table-headers/DailyCardsHeader';
import { Actions } from '../table-headers/Actions';
import { DecksTableConfigContextProvider } from '../context-provider/decksTableConfigContext';
import { FQDecksRow } from '../components/FQRow/FQDecksRow';
import { useGetFQDecksTableValues } from '../hooks/useGetFQDecksTableValues';
import { TablePagination } from '../components/TablePagination';

const COL_NUMBER = 5;

const tableStateSelector = (state: RootState) => state.decksTable;

export const FQDecksTable = () => {
  const {
    deckList,
    isLoading,
    isFetching,
    isError,
    refetch,
    currentPage,
    handlePageClick,
    pageSize,
    handleNewPageSize,
    count,
  } = useGetFQDecksTableValues();

  return (
    <DecksTableConfigContextProvider
      value={{
        sortByCreator: sortBy,
        tableStateSelector: tableStateSelector,
        setPageSizeCreator: setPageSize,
        setPageCreator: setPage,
      }}
    >
      <Container>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>
                  <DeckHeader />
                </Th>
                <Th>
                  <NewCardHeader />
                </Th>
                <Th />
                <Th>
                  <DailyCardsHeader />
                </Th>
                <Th>
                  <Actions />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {isLoading || isFetching ? (
                <TableLoading colNumber={COL_NUMBER} />
              ) : (
                deckList &&
                deckList.map((deck, index) => {
                  return (
                    <FQDecksRow
                      key={deck.name}
                      rowData={deck}
                      index={index}
                      isGeneral={index === 0}
                    />
                  );
                })
              )}
              {isError && <TableError colNumber={COL_NUMBER} retry={refetch} />}
            </Tbody>
          </Table>
        </TableContainer>

        <TablePagination
          totalCount={Number(count)}
          {...{
            currentPage,
            handlePageClick,
            pageSize,
            handleNewPageSize,
          }}
        />
      </Container>
    </DecksTableConfigContextProvider>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1400px;

  overflow-x: auto;
`;

const TableContainer = styled.div`
  border: ${({ theme }) => theme.colors.primary.main} 2px solid;
  border-radius: 12px;
  width: 100%;

  border-left: ${({ theme }) => theme.colors.primary.main} 2px solid;
  /* select the last tr element and apply a border to his children */
  & tr:last-child td {
    border-bottom: ${({ theme }) => theme.colors.primary.main} 2px solid;
  }

  /* select the last td element of each tr */
  & tr td:last-child {
    border-right: ${({ theme }) => theme.colors.primary.main} 2px solid;
    border-radius: 0;
  }

  & th {
    border-top: ${({ theme }) => theme.colors.primary.main} 2px solid;
  }
`;
