import { getUserAccountPreferencesHttp } from 'modules/user-options/http/api';
import { useEffect, useState } from 'react';

interface User {
  firstName: string;
  lastName: string;
  newsletter: boolean;
}

export const useUser = () => {
  const [user, setUser] = useState<User>();
  async function getUser() {
    const userFromAPI = await getUserAccountPreferencesHttp();
    if (userFromAPI) {
      setUser({
        firstName: userFromAPI.first_name,
        lastName: userFromAPI.last_name,
        newsletter: userFromAPI.newsletter,
      });
    }
  }

  useEffect(() => {
    getUser();
  }, []);

  return user;
};
