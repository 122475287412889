import { FC } from 'react';
import { routes } from 'routing/constants';

import { MenuAccount } from '../../../ui/icons/MenuIcons/MenuAccount';
// import { MenuContact } from './components/MenuIcons/MenuContact';
import { MenuFlashQuestions } from '../../../ui/icons/MenuIcons/MenuFlashQuestions';
// import { MenuHome } from 'ui/icons/MenuIcons/MenuHome';
import { MenuLessons } from 'ui/icons/MenuIcons/MenuLessons';
import { MenuProgress } from 'ui/icons/MenuIcons/MenuProgress';
import { MenuRessources } from 'ui/icons/MenuIcons/MenuRessources';
import { MenuSelection } from 'ui/icons/MenuIcons/MenuSelection';
import { MenuStart } from 'ui/icons/MenuIcons/MenuStart';
import { MenuCompass } from 'ui/icons/MenuIcons/MenuCompass';
import { MenuSubscribe } from 'ui/icons/MenuIcons/MenuSubscribe';
import { MenuIconProps } from 'ui/icons/MenuIcons/types';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';
import { MenuInterface } from 'ui/icons/MenuIcons/MenuInterface';
import { MenuFaq } from 'ui/icons/MenuIcons/MenuFaq';
import { MenuCCB } from 'ui/icons/MenuIcons/MenuCCB';

interface MenuElement {
  icon: FC<MenuIconProps>;
  text: string;
  to: string;
  isHighlighted?: boolean;
  isNew?: boolean;
}

export const useGetMenuElements = (): MenuElement[][] => {
  const { isUserPremium, isUserPremiumUnderAMonth } = useIsUserPremium({
    isOptimist: true,
  });

  return [
    [
      {
        icon: MenuCompass,
        text: 'Accueil',
        to: routes.NEW_HOME,
        isNew: true,
      },
    ],
    [
      {
        icon: MenuCCB,
        text: 'Concours blanc',
        to: routes.CONCOURS_BLANC,
      },
      {
        icon: MenuProgress,
        text: 'Avancement',
        to: routes.PROGRESS,
      },
      {
        icon: MenuSelection,
        text: 'Sélection',
        to: routes.SELECTION,
      },
      {
        icon: MenuStart,
        text: 'Démarrer',
        to: routes.START,
      },
      {
        icon: MenuInterface,
        text: 'Interface',
        to: routes.LAST_CORRECTION,
      },
      {
        icon: MenuProgress,
        text: 'Easy-Dispo',
        to: routes.EASY_DISPO,
      },
    ],
    [
      {
        icon: MenuFlashQuestions,
        text: 'Questions flash',
        to: routes.FLASH_QUESTIONS,
      },
    ],
    [
      {
        icon: MenuLessons,
        text: 'Tiroir de cours',
        to: routes.LESSONS,
      },
      {
        icon: MenuRessources,
        text: 'Ressources',
        to: routes.RESSOURCES,
      },
    ],
    [
      {
        icon: MenuAccount,
        text: 'Mon compte',
        to: routes.ACCOUNT,
      },
      ...(isUserPremium && !isUserPremiumUnderAMonth
        ? []
        : [
            {
              icon: MenuSubscribe,
              text: "S'abonner",
              to: routes.SUBSCRIBE,
              isHighlighted: true,
            },
          ]),
      // TODO : à réactiver après la creation d'une interface de réponse adaptée
      //{
      //   icon: MenuContact,
      //   text: 'Nous contacter',
      //   to: routes.CONTACT,
      // },
      {
        icon: MenuFaq,
        text: 'Foire aux questions',
        to: routes.FAQ,
      },
    ],
  ];
};
