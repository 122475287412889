import { areItemInfosEqual } from 'pages/selection/TableHeaders/utils';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { ItemInfos } from 'store/types';

export const useIsLCAAlreadySelected = (questionInfos: ItemInfos) => {
  const selectedItems = useSelector((state: RootState) => {
    return state.lcaTable.selectedItemsInfos;
  });

  const isSelected = selectedItems.some((selectedItem) =>
    areItemInfosEqual(selectedItem, questionInfos)
  );

  return isSelected;
};
