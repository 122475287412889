import {
  createContext,
  FC,
  useContext,
  useState,
  ReactNode,
  useMemo,
} from 'react';
import { SingleValue } from 'react-select';
import { Option } from 'ui/Dropdown/dropdownStyles';
import { Options } from 'types/options.type';
import { useGetItemsOptions } from '../hooks/useGetItems';
import { useSpecialtiesSearchBarOptions } from '../hooks/useGetSpecialties';
import { useHomeInfoContext } from './homeInfoContext';
import { useHomeItemsFiltersContext } from './homeItemsFiltersContext';
import { useHomeSpecialtiesFiltersContext } from './homeSpecialtiesFiltersContext';

interface HomeSearchContextProps {
  searchedValue: SingleValue<Option>;
  setSearchedValue: (entry: SingleValue<Option>) => void;
  searchDropdownOptions: Options;
  handleSearch: (entry: SingleValue<Option>) => void;
}

const contextDefaultValue: HomeSearchContextProps = {
  searchedValue: { label: '', value: '0' },
  setSearchedValue: () => {},
  searchDropdownOptions: [],
  handleSearch: () => {},
};

const HomeSearchContext = createContext(contextDefaultValue);

interface HomeSearchProviderProps {
  children: ReactNode;
}

export const HomeSearchProvider: FC<HomeSearchProviderProps> = ({
  children,
}) => {
  const { activeTab } = useHomeInfoContext();
  const { setSpecialtySearchTerm } = useHomeSpecialtiesFiltersContext();
  const { setItemSearchTerm } = useHomeItemsFiltersContext();
  const [searchedValue, setSearchedValue] = useState<SingleValue<Option>>({
    label: '',
    value: '0',
  });
  const { itemsOptions } = useGetItemsOptions();
  const { specialtiesSearchBarOptions } = useSpecialtiesSearchBarOptions();

  const handleSearch = (entry: SingleValue<Option>) => {
    setSearchedValue(entry);
    if (activeTab === 'items') {
      setItemSearchTerm(entry?.value as string);
    } else if (activeTab === 'specialties') {
      setSpecialtySearchTerm(entry?.value as string);
    }
    const scrollableContainer = document.getElementById('scrollable-container');
    scrollableContainer?.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const searchDropdownOptions = useMemo(() => {
    return activeTab === 'specialties'
      ? specialtiesSearchBarOptions
      : itemsOptions;
  }, [activeTab, specialtiesSearchBarOptions, itemsOptions]);

  return (
    <HomeSearchContext.Provider
      value={{
        searchedValue,
        setSearchedValue,
        searchDropdownOptions,
        handleSearch,
      }}
    >
      {children}
    </HomeSearchContext.Provider>
  );
};

export const useHomeSearchContext = () => useContext(HomeSearchContext);
