import { MS_IN_AN_HOUR } from 'constants/date';
import { getDES_GroupsHttp } from './api';
import { useQuery } from 'react-query';
import { useMemo } from 'react';
import { Options } from 'types/options.type';

export const useGetDES_Groups = () => {
  const getDES_Groups = async () => {
    const query = await getDES_GroupsHttp();
    return query;
  };

  const { data: DES_Groups } = useQuery(
    ['getDES_Groups'],
    () => getDES_Groups(),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }
  );

  return DES_Groups;
};

export const useGetDES_GroupsOptions = () => {
  const DES_Groups = useGetDES_Groups();

  const des_groupsOptions: Options = useMemo(() => {
    return (
      DES_Groups?.map((DES_Group) => ({
        label: DES_Group.des_group,
        value: DES_Group.des_group,
      })) || []
    );
  }, [DES_Groups]);

  return { des_groupsOptions };
};
