import { patchAPI } from 'auth';
import { SaveQuestionQZPRequestType } from './types';

export const saveQuestionQZP = async (
  params: SaveQuestionQZPRequestType | null
) => {
  if (params === null) return;
  const response = await patchAPI<void, SaveQuestionQZPRequestType>(
    '/training/save_question_qzp',
    params
  );
  return response.data;
};
