import styled from 'styled-components';

export const Legend = styled.div`
  width: 45%;
  display: flex;
  gap: 0.5rem;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const Legends = styled.div`
  display: flex;
  gap: 2em;
  margin: 2rem 0 0;
  justify-content: start;
  flex-wrap: wrap;
`;
