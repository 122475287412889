import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { routes } from 'routing/constants';
import { ButtonBase } from 'ui/Buttons/ButtonBase';

interface SubscribeButtonProps {
  small?: boolean;
}

export const SubscribeButton: React.FC<SubscribeButtonProps> = ({ small }) => {
  return (
    <UnstyledLink to={routes.SUBSCRIBE}>
      <ButtonBase
        size="large"
        disabled={false}
        styled={'important'}
        iconOnly={false}
        hasPadding={true}
      >
        S'abonner
      </ButtonBase>
    </UnstyledLink>
  );
};

const UnstyledLink = styled(Link)`
  /* Override link default styles */
  text-decoration: none !important;
`;
