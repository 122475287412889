import { FC } from 'react';
import styled from 'styled-components/macro';
import { SpecialtyHeader } from './SpecialtyHeader';

interface Props {
  speciality1?: string | null;
  speciality2?: string | null;
}

export const SpecialitiesHeader: FC<Props> = ({ speciality1, speciality2 }) => {
  return (
    <Container>
      <SpecialtyHeader trigram={speciality1} />
      {speciality2 && <SpecialtyHeader trigram={speciality2} />}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 8px 32px;
  flex-wrap: wrap;
`;
