import axios from 'axios';
import { toast } from 'react-toastify';

import { buildApiUrl } from 'http/utils';
import { ChangePasswordBody, ChangePasswordResponse } from './types';

export const requestChangePassword = async (
  newPassword: string,
  token: string
) => {
  const route = buildApiUrl('/auth/password_reset/confirm/');
  const body: ChangePasswordBody = {
    password: newPassword,
    token,
  };

  try {
    const axiosResponse = await axios.post<ChangePasswordResponse>(route, body);

    return axiosResponse.data;
  } catch (e) {
    if (e instanceof Error) {
      toast.error(
        'Oups, il semblerait que votre lien de réinitialisation soit erroné'
      );
    }

    throw e;
  }
};
