import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routing/constants';
import { Button } from 'ui/Button';
import { Modal } from 'ui/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { useGetCCBUnfinishedTasks } from 'http/task-history/connector';
import {
  fetchFormatedSessionsStatus,
  updateAllDone,
  updateNextTask,
  updateScreenToDisplay,
  updateSessionsLeftToDo,
} from 'store/slices/ccbSlice';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';
import styled, { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import { useGetEstimatedDurations } from 'modules/user-options/hooks/useGetEstimatedDurations';
import { useGetUserOptions } from 'modules/user-options/hooks/useGetUserOptions';
import { useSubmitTask } from 'pages/new_training/hooks/saveHooks/useSubmitTask';
import { FormatedCCBSession } from 'http/ccb/type';

interface Props {}

export const OngoingCCBTraining: FC<Props> = () => {
  const dispatch = useDispatch();
  const { isUserPremium } = useIsUserPremium();
  const { data: unfinishedCCBTasks, refetch } = useGetCCBUnfinishedTasks();
  const theme = useTheme();
  const { nextTask, sessionsLeftToDo, alldone, isCCBActivatedFree, sessions } =
    useSelector((state: any) => state.ccb);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loadingCancelButton, setLoadingCancelButton] =
    useState<boolean>(false);
  const [loadingGetBackButton, setLoadingGetBackButton] =
    useState<boolean>(false);
  const { data: userOptions } = useGetUserOptions();
  const estimatedDurations = useGetEstimatedDurations();
  const submitTask = useSubmitTask();

  const navigate = useNavigate();

  useEffect(() => {
    refetch();
    if (!unfinishedCCBTasks || unfinishedCCBTasks.length === 0) {
      setIsModalOpen(false);
    }
    if (unfinishedCCBTasks && unfinishedCCBTasks.length > 0 && !alldone) {
      if (isUserPremium) {
        setIsModalOpen(true);
      } else if (!isUserPremium && isCCBActivatedFree) {
        setIsModalOpen(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    sessionsLeftToDo,
    alldone,
    isCCBActivatedFree,
    unfinishedCCBTasks,
    isUserPremium,
  ]);

  const goToLastUnfinishedTask = async () => {
    setLoadingGetBackButton(true);
    if (nextTask && !nextTask.finished) {
      const taskId = nextTask.id;
      setLoadingGetBackButton(false);
      setIsModalOpen(false);
      navigate({ pathname: routes.CCBTRAINING, search: `?id=${taskId}` });
    } else if (unfinishedCCBTasks && unfinishedCCBTasks.length > 0) {
      const lastTask = unfinishedCCBTasks[unfinishedCCBTasks.length - 1];
      if (lastTask && lastTask.id !== undefined) {
        const taskId = lastTask.id;
        dispatch(updateNextTask({ id: taskId, finished: false }));
        setLoadingGetBackButton(false);
        setIsModalOpen(false);
        navigate({ pathname: routes.CCBTRAINING, search: `?id=${taskId}` });
      } else {
        navigate({ pathname: routes.CONCOURS_BLANC });
      }
    } else {
      navigate({ pathname: routes.CONCOURS_BLANC });
    }
  };

  const handleTaskSubmission = async () => {
    setLoadingCancelButton(true);
    if (unfinishedCCBTasks?.[0] && userOptions) {
      dispatch(updateSessionsLeftToDo(sessionsLeftToDo));
      dispatch(updateScreenToDisplay('PostCCB'));
      dispatch(
        fetchFormatedSessionsStatus({
          trainingDuration: userOptions.trainingDuration,
          ucDuration: estimatedDurations.ucDuration,
        })
      );

      submitTask(unfinishedCCBTasks?.[0].id, () => {});
      dispatch(updateSessionsLeftToDo(sessionsLeftToDo));
      dispatch(
        fetchFormatedSessionsStatus({
          trainingDuration: userOptions?.trainingDuration,
          ucDuration: estimatedDurations?.ucDuration,
        })
      );

      const isdone =
        sessions.filter(
          (session: FormatedCCBSession) => session.finished === true
        ).length === sessions.length;
      dispatch(updateAllDone(isdone));

      dispatch(updateScreenToDisplay('PostCCB'));
      setIsModalOpen(false);
      setLoadingCancelButton(false);
      refetch();
      navigate({ pathname: routes.CONCOURS_BLANC });
    } else {
      toast.error("Aucune épreuve de concours blanc n'est en cours.");
    }
  };

  return (
    <Modal isOpen={isModalOpen}>
      <ModalBody>
        <h3>Vous avez une épreuve de concours blanc en cours.</h3>
        <h5>Souhaitez-vous la reprendre ?</h5>
        <ButtonContainer>
          <GetBackButton
            onClick={goToLastUnfinishedTask}
            isLoading={loadingGetBackButton}
          >
            Reprendre
          </GetBackButton>
          <CancelButton
            style={{
              backgroundColor: theme.colors.secondary.main,
            }}
            onClick={handleTaskSubmission}
            isLoading={loadingCancelButton}
          >
            Soumettre l'épreuve
          </CancelButton>
        </ButtonContainer>
      </ModalBody>
    </Modal>
  );
};

const ModalBody = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 30px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const GetBackButton = styled(Button)``;

const CancelButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secondary.main};
`;
