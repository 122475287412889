import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  doFQCard,
  getFQCardToDo,
  getFQDeckCardsFromAPI,
} from 'http/flash-questions/cards/api';
import { getFQCorrectionFromAPI } from 'http/flash-questions/correction/api';
import { saveQuestionQCM } from 'http/training/save-question-qcm/api';
import { saveQuestionQRPL } from 'http/training/save-question-qrpl/api';
import { saveQuestionQZP } from 'http/training/save-question-qzp/api';
import { saveQuestionQROC } from 'http/training/save-question-qroc/api';
// import { saveQuestionTCS } from 'http/training/save-question-tcs/api';
import { toast } from 'react-toastify';
import { RootState } from 'store';
import { FQCardCorrection } from 'types/correction.type';
import { DoFQCardType, FlashCard, FlashDeck } from 'types/flash.type';

export const getCardToDo = createAsyncThunk<
  FlashCard & DoFQCardType,
  void,
  { rejectValue: string; state: RootState }
>(
  'fqCurrent/get_card_todo',
  async (_, { rejectWithValue, getState, dispatch }) => {
    const state = getState();

    if (
      state.fqCurrent.chosenDeckInfos !== null &&
      state.fqCurrent.chosenDeckInfos.name !== ''
    ) {
      const label = state.fqCurrent.chosenDeckInfos.name;

      const response = getFQCardToDo({ label })
        .then(async (fqCardTodo) => {
          const doCardResponse = await doFQCard(fqCardTodo.id);
          return { ...fqCardTodo, ...doCardResponse };
        })

        .catch((error) => {
          return rejectWithValue(error);
        });

      if (response) {
        // dispatch(doCard({ cardId: response.id }));
        return response;
      }
    }

    toast.error(
      "Erreur serveur : la question flash à faire n'a pas pu être récupérée"
    );

    return rejectWithValue('ERROR');
  }
);

export const getDeck = createAsyncThunk<
  FlashDeck,
  void,
  { rejectValue: string; state: RootState }
>('fqCurrent/get_deck', async (_, { rejectWithValue, getState }) => {
  const { fqCurrent } = getState();

  if (
    fqCurrent.chosenDeckInfos !== null &&
    fqCurrent.chosenDeckInfos.name !== ''
  ) {
    const label = fqCurrent.chosenDeckInfos.name;

    const response = getFQDeckCardsFromAPI({ label });

    if (response) return response;
  }

  toast.error(
    "Erreur serveur : la question flash à faire n'a pas pu être récupérée"
  );

  return rejectWithValue('ERROR');
});

export const getCorrection = createAsyncThunk<
  FQCardCorrection,
  void,
  { rejectValue: string; state: RootState }
>('fqCurrent/get_correction', async (_, { rejectWithValue, getState }) => {
  const {
    fqCurrent: { card, selectedAnswers, singleAnswer, qzpAnswer },
  } = getState();

  if (card !== null) {
    const { flash_question_id } = card;

    if (card.training_question.question.type === 'QRM') {
      const answers = Object.entries(selectedAnswers).map(([key, val]) => ({
        id: Number(key),
        answer: val,
      }));

      saveQuestionQCM({
        id: Number(card.training_question.id),
        answers,
      });
    }

    if (card.training_question.question.type === 'QRPL') {
      const answers = Object.entries(selectedAnswers).map(([key, val]) => ({
        id: Number(key),
        answer: val,
      }));
      saveQuestionQRPL({
        id: Number(card.training_question.id),
        answers,
      });
    }
    if (card.training_question.question.type === 'QZP') {
      const answer = qzpAnswer;
      saveQuestionQZP(answer);
    }

    if (card.training_question.question.type === 'QROC') {
      saveQuestionQROC({
        id: Number(card.training_question.id),
        answers: singleAnswer,
      });
    }

    // TODO: TCS
    //   if (card.training_question.question.type === 'TCS') {
    //     saveQuestionTCS({
    //   id: Number(card.training_question.id),
    //   answers,
    //  });
    // }

    const response = getFQCorrectionFromAPI({ flash_question_id });

    if (response) return response;
  }

  toast.error("Erreur serveur : la correction n'a pas pu être récupérée");

  return rejectWithValue('ERROR');
});

export const saveQCMQuestion = createAsyncThunk<
  FQCardCorrection,
  void,
  { rejectValue: string; state: RootState }
>('fqCurrent/save_qcm_question', async (_, { rejectWithValue, getState }) => {
  const {
    fqCurrent: { card },
  } = getState();

  if (card !== null) {
    const { flash_question_id } = card;

    const response = getFQCorrectionFromAPI({ flash_question_id });

    if (response) return response;
  }

  toast.error("Erreur serveur : la correction n'a pas pu être récupérée");

  return rejectWithValue('ERROR');
});

export const saveQZPQuestion = createAsyncThunk<
  FQCardCorrection,
  void,
  { rejectValue: string; state: RootState }
>('fqCurrent/save_qzp_question', async (_, { rejectWithValue, getState }) => {
  const {
    fqCurrent: { card },
  } = getState();

  if (card !== null) {
    const { flash_question_id } = card;

    const response = getFQCorrectionFromAPI({ flash_question_id });

    if (response) return response;
  }

  toast.error("Erreur serveur : la correction n'a pas pu être récupérée");

  return rejectWithValue('ERROR');
});

export const saveQROCQuestion = createAsyncThunk<
  FQCardCorrection,
  void,
  { rejectValue: string; state: RootState }
>('fqCurrent/save_qroc_question', async (_, { rejectWithValue, getState }) => {
  const {
    fqCurrent: { card },
  } = getState();

  if (card !== null) {
    const { flash_question_id } = card;

    const response = getFQCorrectionFromAPI({ flash_question_id });

    if (response) return response;
  }

  toast.error("Erreur serveur : la correction n'a pas pu être récupérée");

  return rejectWithValue('ERROR');
});

export const saveQRPLQuestion = createAsyncThunk<
  FQCardCorrection,
  void,
  { rejectValue: string; state: RootState }
>('fqCurrent/save_qrpl_question', async (_, { rejectWithValue, getState }) => {
  const {
    fqCurrent: { card },
  } = getState();

  if (card !== null) {
    const { flash_question_id } = card;

    const response = getFQCorrectionFromAPI({ flash_question_id });

    if (response) return response;
  }

  toast.error("Erreur serveur : la correction n'a pas pu être récupérée");

  return rejectWithValue('ERROR');
});
