import jwt_decode from 'jwt-decode';

interface JWTPayload {
  user_id: number;
  username: string;
  email: string;
  exp: number;
}

export function setSession(jwt_token: string, session_token: string) {
  const jwt_payload = jwt_decode<JWTPayload>(jwt_token);
  const expiry = jwt_payload.exp;
  localStorage.setItem('jwt_token', jwt_token);
  localStorage.setItem('session_token', session_token);
  localStorage.setItem('expiry', expiry.toString());
}

export function getSession() {
  const jwt_token = localStorage.getItem('jwt_token');
  const session_token = localStorage.getItem('session_token');
  const expiry = localStorage.getItem('expiry');

  return { jwt_token, session_token, expiry };
}
