import { MS_IN_AN_HOUR } from 'constants/date';
import {
  getHomeItem,
  getHomeSpecialty,
  patchHomeItem,
  patchHomeSpecialty,
} from './api';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { patchHomeItemParams, patchHomeSpecialtyParams } from './types';
import { useMemo } from 'react';

export const useGetHomeSpecialty = (specialty: string | undefined) => {
  const query = useQuery(
    ['newHome', 'specialty', specialty],
    () => (specialty ? getHomeSpecialty(specialty) : null),
    {
      staleTime: 0,
      cacheTime: MS_IN_AN_HOUR,
      enabled: !!specialty,
    }
  );

  const data = useMemo(() => query.data, [query.data]);

  return {
    ...query,
    data,
  };
};

export const useGetHomeItem = (item: string | undefined) => {
  const query = useQuery(
    ['newHome', 'item', item],
    () => (item ? getHomeItem(item) : null),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
      enabled: !!item,
    }
  );

  const data = useMemo(() => query.data, [query.data]);

  return {
    ...query,
    data,
  };
};

export const useUpdateSpeCard = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (params: patchHomeSpecialtyParams) => patchHomeSpecialty(params),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries('getHomeSpecialties');

        queryClient.setQueryData(['getHomeSpecialties'], (oldData: any) => {
          if (!oldData || !oldData.results) {
            return oldData;
          }

          return {
            ...oldData,
            results: oldData.results.map((specialty: any) =>
              specialty.id === variables.specialty
                ? { ...specialty, ...data }
                : specialty
            ),
          };
        });
        queryClient.invalidateQueries('getRecapFavoriteSpecialties');

        queryClient.setQueryData(
          ['getRecapFavoriteSpecialties'],
          (oldData: any) => {
            if (!oldData || !oldData.results) {
              return oldData;
            }

            return {
              ...oldData,
              results: oldData.results.map((specialty: any) =>
                specialty.id === variables.specialty
                  ? { ...specialty, ...data }
                  : specialty
              ),
            };
          }
        );
      },
    }
  );

  const updateSpeCard = (params: patchHomeSpecialtyParams) => {
    return mutation.mutate(params);
  };

  return {
    updateSpeCard,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
  };
};

export const useUpdateItemCard = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (params: patchHomeItemParams) => patchHomeItem(params),
    {
      onSuccess: (data, variables) => {
        // Invalidate the getHomeItems query
        queryClient.invalidateQueries('getHomeItems');

        // Update the cache
        queryClient.setQueryData(['getHomeItems'], (oldData: any) => {
          if (!oldData || !oldData.results) {
            return oldData;
          }
          return {
            ...oldData,
            results: oldData.results.map((item: any) =>
              item.id === variables.item ? { ...item, ...data } : item
            ),
          };
        });

        // Invalidate the getHomeFavoriteItems query
        queryClient.invalidateQueries('getHomeFavoriteItems');

        // Update the cache
        queryClient.setQueryData(['getHomeFavoriteItems'], (oldData: any) => {
          if (!oldData || !oldData.results) {
            return oldData;
          }
          return {
            ...oldData,
            results: oldData.results.map((item: any) =>
              item.id === variables.item ? { ...item, ...data } : item
            ),
          };
        });
      },
    }
  );

  const updateItemCard = (params: patchHomeItemParams) => {
    return mutation.mutate(params);
  };

  return {
    updateItemCard,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
  };
};
