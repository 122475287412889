import { deleteAPI } from 'auth/authentifiedHttpCalls';
import { toast } from 'react-toastify';

export const requestDeleteAccount = async () => {
  try {
    const axiosResponse = await deleteAPI('/auth/user');

    return axiosResponse.data;
  } catch (e) {
    if (e instanceof Error) {
      toast.error(
        'Une erreur est survenue lors du transfert de votre demande. Veuillez réessayer plus tard'
      );
    }

    throw e;
  }
};
