import { FC } from 'react';

export interface ArrowFullToRightProps {
  isFilled?: boolean;
}

export const ArrowFullToRight: FC<ArrowFullToRightProps> = ({ isFilled }) => {
  return (
    <svg
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="path-1-outside-1_7661_14043"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="14"
        fill="black"
      >
        <rect fill="currentColor" width="22" height="14" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2581 13L21 7L13.2581 1L13.2581 4.69075H1V9.30955H13.2581L13.2581 13Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2581 13L21 7L13.2581 1L13.2581 4.69075H1V9.30955H13.2581L13.2581 13Z"
        fill="currentColor"
      />
      <path
        d="M21 7L21.6126 7.79041C21.857 7.60101 22 7.3092 22 7C22 6.6908 21.857 6.39899 21.6126 6.20958L21 7ZM13.2581 13L12.2581 13C12.2581 13.3816 12.4753 13.73 12.8179 13.8979C13.1606 14.0659 13.569 14.0242 13.8706 13.7904L13.2581 13ZM13.2581 1L13.8706 0.209585C13.569 -0.0241853 13.1606 -0.065897 12.8179 0.102066C12.4753 0.270028 12.2581 0.618379 12.2581 1L13.2581 1ZM13.2581 4.69075V5.69075C13.8103 5.69075 14.2581 5.24303 14.2581 4.69075L13.2581 4.69075ZM1 4.69075V3.69075C0.447715 3.69075 0 4.13846 0 4.69075H1ZM1 9.30955H0C0 9.86184 0.447715 10.3096 1 10.3096L1 9.30955ZM13.2581 9.30955H14.2581C14.2581 8.75727 13.8103 8.30955 13.2581 8.30955V9.30955ZM20.3874 6.20959L12.6455 12.2096L13.8706 13.7904L21.6126 7.79041L20.3874 6.20959ZM12.6455 1.79042L20.3874 7.79042L21.6126 6.20958L13.8706 0.209585L12.6455 1.79042ZM14.2581 4.69075L14.2581 1L12.2581 1L12.2581 4.69075L14.2581 4.69075ZM1 5.69075H13.2581V3.69075H1V5.69075ZM2 9.30955V4.69075H0V9.30955H2ZM13.2581 8.30955H1V10.3096H13.2581V8.30955ZM14.2581 13L14.2581 9.30955L12.2581 9.30955L12.2581 13L14.2581 13Z"
        fill="currentColor"
        mask="url(#path-1-outside-1_7661_14043)"
      />
    </svg>
  );
};
