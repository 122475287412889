import { FC } from 'react';
import styled from 'styled-components/macro';

import { useGetSpecialities } from 'http/selection/specialities-list/connectors';
import { SpecialtyIcon } from 'ui/icons/SpecialtyIcon/SpecialtyIcon';

interface Props {
  trigram?: string | null;
}

export const SpecialtyHeader: FC<Props> = ({ trigram }) => {
  const { data } = useGetSpecialities();

  const spe = data?.find((spe) => spe.trigram === trigram);
  const longName = spe?.name;

  return (
    <Container>
      <Icon trigram={trigram ?? ''} />
      <Text>{longName}</Text>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;

  font-size: inherit;
  font-weight: inherit;
`;

const Icon = styled(SpecialtyIcon)`
  height: 24px;
  width: 24px;
`;

const Text = styled.h4``;
