import { getAPI } from 'auth';

import { GetQuestionParams, GetQuestionResponse } from './types';

export const getQuestionGroup = async (params: GetQuestionParams) => {
  const response = await getAPI<GetQuestionResponse>(
    '/selection/get_question/',
    {
      params,
    }
  );
  return response.data;
};
