import { FC } from 'react';
import styled from 'styled-components/macro';
import Tippy from '@tippyjs/react';
import RankLetter from 'ui/Misc/RankLetter';
import { Rank } from 'types/knowledge.type';

interface Props {
  rank: Rank;
  label: string;
}

export const KnowledgeRank: FC<Props> = ({ rank, label }) => {
  return (
    <Container>
      <Tippy content={label} arrow={false} placement="right">
        <RankLetter letter={rank}>{rank}</RankLetter>
      </Tippy>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 14px;
  justify-content: center;
  align-items: center;
`;
