import { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { TextBody2 } from 'ui/TextBody2';

interface Props {
  className?: string;
}

export const CapsLockWarning = (props: Props) => {
  const [shouldDisplayWarning, setShouldDisplayWarning] =
    useState<boolean>(false);

  const updateWarningState = (event: KeyboardEvent) => {
    const capsLockIsOn =
      event.getModifierState && event.getModifierState('CapsLock');
    if (capsLockIsOn) {
      setShouldDisplayWarning(true);
    } else {
      setShouldDisplayWarning(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', updateWarningState);

    return function cleanup() {
      window.removeEventListener('keyup', updateWarningState);
    };
  }, []);

  return (
    <>
      {shouldDisplayWarning && (
        <WarnMessage {...props}>
          Le verrouillage majuscule est activé
        </WarnMessage>
      )}
    </>
  );
};

const WarnMessage = styled(TextBody2)`
  color: ${({ theme }) => theme.colors.warning.dark};
`;
