import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Layout } from 'ui/Layout/PageLayout';
import { InfoPage } from './components/InfoPage/InfoPage';
import { LauchingPage } from './components/LaunchPage/LaunchingPage';
import { CompletedTraining } from './components/CompletedPage/CompletedTraining';
import { StatisticsPage } from './components/StatisticsPage/StatisticsPage';
import { updateScreenToDisplay } from 'store/slices/ccbSlice';
import { useGetFastUserOptions } from 'http/account/connector';

export const DisplayedPage = () => {
  const dispatch = useDispatch();
  const user = useGetFastUserOptions();
  const {
    screenToDisplay,
    permission,
    alldone,
    ccbStatsAvailable,
    isCCBActivatedFree,
    private_users,
    nextTask,
    lastTask,
  } = useSelector((state: any) => state.ccb);

  // manage screens from user circuit
  const screenSwitch = () => {
    if (alldone) {
      if (ccbStatsAvailable) {
        dispatch(updateScreenToDisplay('Statistics'));
      } else {
        dispatch(updateScreenToDisplay('PostCCB'));
      }
    } else {
      if (isCCBActivatedFree) {
        if (nextTask) {
          dispatch(updateScreenToDisplay('CCBLauchScreen'));
        } else {
          //just finished task
          if (lastTask) {
            dispatch(updateScreenToDisplay('PostCCB'));
          } else {
            dispatch(updateScreenToDisplay('CCBLauchScreen'));
          }
        }
      } else {
        dispatch(updateScreenToDisplay('PreCCBInfo'));
      }
    }
  };

  // manage permissions
  useEffect(() => {
    // for private - if in the private user list :
    // premium user access all time
    // freemium user access between start & end dates
    // Still see the
    if (permission === 'private') {
      if (private_users?.includes(user?.pk)) {
        screenSwitch();
      } else {
        dispatch(updateScreenToDisplay('PreCCBInfo'));
      }
    }
    // for premium - premium user access all time
    // freemium user access between start & end dates but see screens with subscription button otherwise
    if (permission === 'premium') {
      screenSwitch();
    }
    // eslint-disable-next-line
  }, [permission, dispatch, private_users]);

  const PageToDisplay = () => {
    switch (screenToDisplay) {
      case 'PreCCBInfo':
        return <InfoPage />;
      case 'CCBLauchScreen':
        return <LauchingPage />;
      case 'PostCCB':
        return <CompletedTraining />;
      case 'Statistics':
        return <StatisticsPage />;
      default:
        return <LauchingPage />;
    }
  };

  return (
    <Layout>
      <PageToDisplay />
    </Layout>
  );
};
