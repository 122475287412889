import { useQuery } from 'react-query';
import { SpeDetailedProgressParams } from '../domain/entities/SpeDetailedProgressParams';

import { getSpeDetailedProgress } from '../domain/useCases/getSpeDetailedProgress';
import { MS_IN_AN_HOUR } from 'constants/date';

export const USE_GET_SPE_DETAILED_PROGRESS_KEY = 'useGetSpeDetailedProgress';

export const useGetSpeDetailedProgress = (
  params?: SpeDetailedProgressParams
) => {
  return useQuery(
    [USE_GET_SPE_DETAILED_PROGRESS_KEY, params],
    () => getSpeDetailedProgress(params!),
    {
      cacheTime: MS_IN_AN_HOUR,
      staleTime: MS_IN_AN_HOUR,
      enabled: params !== undefined,
    }
  );
};
