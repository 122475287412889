import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { device } from 'helpers/css/responsive';

import { Card } from 'ui/Card';
import { TextInput } from 'ui/TextInput';
import { PasswordInput } from 'ui/PasswordInput';
import { Button } from 'ui/Button';
import { Checkbox } from 'ui/Checkbox';
import { TextBody2 } from 'ui/TextBody2';

import { LoginRelatedBackground } from 'components/LoginRelatedBackground';
import { CapsLockWarning } from 'components/CapsLockWarning';
import { RHFDropdown } from 'components/RHFDropdown';

import { useSignupForm, useOnSignupSubmit } from './sign-upForms';
import { useGetUniversities } from './hooks/useGetUniversities';
import { useGetPromotions } from './hooks/useGetPromotions';

export const SignUp = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useSignupForm();

  const onSignupSubmit = useOnSignupSubmit();

  const promotions = useGetPromotions();
  const universities = useGetUniversities();

  return (
    <LoginRelatedBackground>
      <Form onSubmit={handleSubmit(onSignupSubmit)}>
        <ContainerCard>
          <h2>Créer un compte</h2>
          <InputsContainer>
            <TextInput
              label="Prénom"
              isMandatory={true}
              autoComplete="given-name"
              errorMessage={errors.first_name?.message}
              {...register('first_name')}
            />
            <TextInput
              label="Nom"
              isMandatory={true}
              autoComplete="family-name"
              errorMessage={errors.last_name?.message}
              {...register('last_name')}
            />
            <DropdownRow>
              <PromotionDropdown
                isMandatory={true}
                control={control}
                inputName="promotion"
                listOfValues={promotions}
                label="Promotion"
                errorMessage={errors.promotion?.message}
              />

              <UniversityDropdown
                isMandatory={true}
                control={control}
                inputName="university"
                listOfValues={universities}
                label="Faculté"
                errorMessage={errors.university?.message}
              />
            </DropdownRow>

            <TextInput
              label="Email"
              autoComplete="email"
              type="email"
              isMandatory={true}
              errorMessage={errors.email?.message}
              {...register('email')}
            />

            <PasswordInput
              label="Mot de passe"
              errorMessage={errors.password1?.message}
              {...register('password1')}
              isMandatory={true}
            />
          </InputsContainer>
          <StyledCapsLockWarning />
          <PasswordConfirmationInput
            label="Confirmation du mot de passe"
            errorMessage={errors.password2?.message}
            isMandatory={true}
            {...register('password2')}
          />

          <div>
            <CheckboxContainer>
              <Checkbox {...register('newsletter')} />
              <label>
                J’accepte de recevoir les offres, les nouveautés et autres
                informations importantes concernant le concours des EDN.
              </label>
            </CheckboxContainer>
            <CheckboxContainer>
              <Checkbox
                hasError={!!errors.checkbox?.message}
                {...register('checkbox')}
              />
              <label>
                J’accepte les{' '}
                <a
                  href={'https://asclepia.io/cgu'}
                  target="_blank"
                  rel="noreferrer"
                >
                  conditions d'utilisation{' '}
                </a>
                et je reconnais avoir pris connaissance de{' '}
                <a
                  href={'https://asclepia.io/confidentiality'}
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  notre politique de confidentialité des données
                </a>
                .<span style={{ color: 'red' }}>*</span>
              </label>
            </CheckboxContainer>
          </div>
          {errors.checkbox?.message && (
            <CheckboxError>{errors.checkbox?.message}</CheckboxError>
          )}
          <LegalText>
            Les informations recueillies font l'objet d'un traitement
            informatique. Pour en savoir plus sur la collecte, nous vous
            invitons à lire notre politique de{' '}
            <a
              href={'https://asclepia.io/confidentiality'}
              target="_blank"
              rel="noreferrer"
            >
              confidentialité de données
            </a>
            .
          </LegalText>
          <SubmitButton type="submit">S'inscrire</SubmitButton>
          <LoginLabel>
            Déjà un compte ? <Link to="/login">Se connecter</Link>
          </LoginLabel>
        </ContainerCard>
      </Form>
    </LoginRelatedBackground>
  );
};

const Form = styled.form`
  height: 100%;
  min-height: 100vh;

  display: grid;
  justify-items: center;
  align-items: center;
  padding: 16px;
  @media ${device.desktop} {
    padding: 16px;
  }
`;

const ContainerCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  @media ${device.desktop} {
    padding: 32px 64px;
  }
`;

const InputsContainer = styled.div`
  margin-top: 16px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media ${device.desktop} {
    margin-top: 22px;
    gap: 24px;
  }
`;

const PasswordConfirmationInput = styled(PasswordInput)`
  margin-top: 20px;
  @media ${device.desktop} {
    margin-top: 24px;
  }
`;

const StyledCapsLockWarning = styled(CapsLockWarning)`
  align-self: end;
  margin-right: 16px;
  @media ${device.desktop} {
    margin-right: 24px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 4px;
  @media ${device.desktop} {
    margin-top: 16px;
    gap: 16px;
  }
`;

const CheckboxError = styled(TextBody2)`
  color: ${({ theme }) => theme.colors.error.main};
  margin-top: 4px;

  @media ${device.desktop} {
    margin-top: 8px;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 16px;
  @media ${device.desktop} {
    margin-top: 24px;
  }
`;

const LoginLabel = styled.label`
  margin-top: 24px;
  @media ${device.desktop} {
    margin-top: 32px;
  }
`;

const DropdownRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;

  @media ${device.desktop} {
    flex-direction: row;
    gap: 8px;
  }
`;

const PromotionDropdown = styled(RHFDropdown)`
  @media ${device.desktop} {
    width: 50%;
  }
`;

const UniversityDropdown = styled(RHFDropdown)`
  width: 100%;
`;

const LegalText = styled.div`
  margin-top: 16px;
  ${({ theme }) => theme.typography.small}
`;
