export const BigArrowRight = () => {
  return (
    <svg
      width="22"
      height="14"
      viewBox="0 0 22 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="path-1-outside-1_13145_26040"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="22"
        height="14"
        fill="black"
      >
        <rect fill="white" width="22" height="14" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.2581 13L21 6.99998L13.2581 0.99998L13.2581 4.69073H1V9.30953H13.2581L13.2581 13Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2581 13L21 6.99998L13.2581 0.99998L13.2581 4.69073H1V9.30953H13.2581L13.2581 13Z"
        fill="currentColor"
      />
      <path
        d="M21 6.99998L21.6126 7.7904C21.857 7.60099 22 7.30918 22 6.99998C22 6.69078 21.857 6.39897 21.6126 6.20957L21 6.99998ZM13.2581 13H12.2581C12.2581 13.3816 12.4753 13.73 12.8179 13.8979C13.1606 14.0659 13.569 14.0242 13.8706 13.7904L13.2581 13ZM13.2581 0.99998L13.8706 0.209565C13.569 -0.0242049 13.1606 -0.0659165 12.8179 0.102046C12.4753 0.270009 12.2581 0.618359 12.2581 0.99998L13.2581 0.99998ZM13.2581 4.69073V5.69073C13.8103 5.69073 14.2581 5.24301 14.2581 4.69073L13.2581 4.69073ZM1 4.69073V3.69073C0.447715 3.69073 0 4.13845 0 4.69073H1ZM1 9.30953H0C0 9.86182 0.447715 10.3095 1 10.3095L1 9.30953ZM13.2581 9.30953H14.2581C14.2581 8.75725 13.8103 8.30953 13.2581 8.30953V9.30953ZM20.3874 6.20957L12.6455 12.2096L13.8706 13.7904L21.6126 7.7904L20.3874 6.20957ZM12.6455 1.7904L20.3874 7.7904L21.6126 6.20957L13.8706 0.209565L12.6455 1.7904ZM14.2581 4.69073L14.2581 0.999981L12.2581 0.99998L12.2581 4.69073L14.2581 4.69073ZM1 5.69073H13.2581V3.69073H1V5.69073ZM2 9.30953V4.69073H0V9.30953H2ZM13.2581 8.30953H1V10.3095H13.2581V8.30953ZM14.2581 13L14.2581 9.30953H12.2581L12.2581 13H14.2581Z"
        fill="currentColor"
        mask="url(#path-1-outside-1_13145_26040)"
      />
    </svg>
  );
};
