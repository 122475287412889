import { device } from 'helpers/css/responsive';
import styled from 'styled-components/macro';

export const Card = styled.div`
  background-color: ${({ theme }) => theme.colors.background.paper};
  max-width: 758px;
  width: 100%;
  padding: 16px;
  border-radius: 43px;

  @media ${device.desktop} {
    padding: 32px 64px;
    border-radius: 56px;
  }
`;
