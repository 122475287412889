import { FC } from 'react';
import styled from 'styled-components/macro';

import { SearchIcon } from 'ui/icons/Search';
import { device } from 'helpers/css/responsive';
import { useSearchbarContext } from './SearchbarContext';
import { useIsUserFree } from 'modules/user-options/hooks/useIsUserPremium';
import { LockIcon } from 'ui/icons/Lock';

interface Props {}

export const Searchbar: FC<Props> = () => {
  const { searchTerm, setSearchTerm } = useSearchbarContext();

  const isUserFree = useIsUserFree();

  return (
    <Container>
      <Input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        disabled={isUserFree}
        placeholder={
          isUserFree ? 'Fonctionnalité premium' : 'Recherche par mot clé'
        }
      />

      <IconContainer>
        {isUserFree ? <LockIcon /> : <SearchIcon />}
      </IconContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
  position: relative;
  flex-grow: 1;

  max-width: 200px;
  &:focus-within {
    max-width: 250px;
  }

  @media ${device.desktop} {
    max-width: 300px;
    &:focus-within {
      max-width: 400px;
    }
  }

  transition: max-width 100ms ease-in-out;
`;

const Input = styled.input`
  padding: 8px 36px 8px 16px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.primary.dark};

  background-color: ${({ theme }) => theme.colors.background.paper};
  color: ${({ theme }) => theme.colors.primary.dark};

  ${({ theme }) => theme.typography.textBody2}

  &:disabled {
    color: ${({ theme }) => theme.colors.inactive};
    border-color: ${({ theme }) => theme.colors.inactive};
  }
`;

const IconContainer = styled.div`
  right: 12px;
  position: absolute;
  height: 16px;
  width: 16px;
  display: grid;
  place-items: center;
`;
