import { FC, SVGAttributes } from 'react';

type SVGNativeProps = SVGAttributes<SVGElement>;

export const GreyScaleIcon: FC<SVGNativeProps> = (props) => {
  return (
    <svg
      width="59"
      height="50"
      viewBox="0 0 59 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5310_17786)">
        <path d="M58.5957 25.3671C58.729 25.1013 58.729 24.8937 58.5957 24.6278C56.2331 19.8886 53.1221 15.6937 49.2193 12.081C46.2672 9.34936 42.9589 7.14683 39.215 5.60253C34.6408 3.71898 29.8796 3.01012 24.9441 3.37468C22.2713 3.57215 19.6677 4.1038 17.141 4.98228C15.6471 5.50379 14.2171 6.13417 12.8436 6.85822L6.70368 0.792404C5.63253 -0.265823 3.89767 -0.265823 2.82651 0.792404C1.75536 1.85063 1.75536 3.56455 2.82651 4.62278L47.956 49.2076C48.4915 49.7367 49.1937 50 49.8958 50C50.598 50 51.3001 49.7367 51.8357 49.2076C52.9068 48.1493 52.9068 46.4354 51.8357 45.3772L46.5465 40.1519C46.6772 40.0506 46.8105 39.9519 46.9386 39.8481C51.9382 35.9013 55.759 31.0177 58.5983 25.3671H58.5957ZM34.4896 28.243C34.9509 27.2557 35.2097 26.157 35.2097 25C35.2097 20.7164 31.6939 17.2405 27.358 17.243C26.1869 17.243 25.0748 17.4987 24.0754 17.957L19.4781 13.4152C21.7306 11.9139 24.4444 11.0405 27.3657 11.0405C35.1662 11.0405 41.4906 17.2911 41.4906 24.9975C41.4906 27.881 40.6039 30.562 39.0869 32.7873L34.4896 28.2456V28.243Z" />
        <path d="M20.8337 44.2607L-1.35807e-06 23.9519C-0.125567 33.5342 8.73067 41.8633 20.8311 44.2607H20.8337Z" />
        <path d="M1.2941 17.9696L28.957 44.9367C31.3095 44.8734 33.585 44.5823 35.7427 44.0962L4.22055 13.3645C2.99564 14.7797 2.00649 16.324 1.2941 17.9696Z" />
      </g>
      <defs>
        <clipPath id="clip0_5310_17786">
          <rect width="58.6957" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
