export function HelpTooltip(props: { className?: string }) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      {...props}
    >
      <circle cx={8} cy={8} r={7.5} stroke="currentColor" />
      <path
        d="M5.333 5.878c0-1.06.376-2.545 2.757-2.545 2.332 0 2.997 2.44 2.332 3.605-.848 1.484-2.332 0-2.332 3.816"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <ellipse cx={8} cy={13.073} rx={0.889} ry={0.928} fill="currentColor" />
    </svg>
  );
}
