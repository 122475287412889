import { FC } from 'react';
import styled from 'styled-components/macro';

import { thinScrollbarStyleColored } from 'helpers/css/thin-scrollbar';
import { Table, Tbody } from 'ui/TableElements';
import { ContainedLoader } from 'components/ContainedLoader';
import { useGetLCAProgress } from 'modules/progress/general/lca/hooks/useGetLCAProgress';

import { LcaProgressRow } from './components/LCAProgressRow';
import { AddLocalSelectionToCartButton } from './components/AddLocalSelectionToCartButton';
import { AddToLocalCartProvider } from './contexts/addToLocalCartContext';
import { FlushLocalSelectionButton } from './components/FlushLocalSelectionButton';
import { LcaProgressHeader } from './components/LCAProgressHeader';
import { GenericErrorPlaceholder } from 'components/GenericErrorPlaceholder';
import { device } from 'helpers/css/responsive';

interface Props {}

export const LCAProgress: FC<Props> = () => {
  const { data: lcas, isLoading, isError } = useGetLCAProgress();
  return (
    <AddToLocalCartProvider>
      <Container>
        <AddLocalSelectionToCartButton />
        <FlushLocalSelectionButton />
        <Table>
          <Tbody>
            <LcaProgressHeader />

            {isError && (
              <tr>
                <td colSpan={8}>
                  <GenericErrorPlaceholder />
                </td>
              </tr>
            )}

            {isLoading && (
              <tr>
                <td colSpan={8}>
                  <ContainedLoader loadingText="Veuillez patienter..." />
                </td>
              </tr>
            )}

            {lcas &&
              lcas.map((lca) => <LcaProgressRow lca={lca} key={lca.id} />)}
          </Tbody>
        </Table>
      </Container>
    </AddToLocalCartProvider>
  );
};

const Container = styled.div`
  display: flex;
  height: 100%;
  overflow-x: auto;

  padding: 0 var(--horizontal-padding) 4px;
  @media ${device.desktop} {
    padding: 0 var(--horizontal-padding) 32px;
  }

  ${thinScrollbarStyleColored}
`;
