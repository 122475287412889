import { useQueries, useQuery, UseQueryOptions } from 'react-query';

import { getQuestionGroup } from './api';
import { GetQuestionParams, GetQuestionResponse } from './types';
import { MS_IN_AN_HOUR } from 'constants/date';

export const useGetQuestionGroup = (
  params: GetQuestionParams,
  enabled: boolean
) => {
  const query = useQuery(
    ['getQuestionGroup', params],
    () => getQuestionGroup(params),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
      enabled,
    }
  );

  return query;
};

export const useGetMultipleQuestionGroups = (params: GetQuestionParams[]) => {
  const queries: UseQueryOptions<GetQuestionResponse>[] = params.map(
    (group) => ({
      queryKey: ['getQuestionGroup', group],
      queryFn: () => getQuestionGroup(group),
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    })
  );

  return useQueries(queries);
};
