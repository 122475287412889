import { useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { getQIDetail } from './api';
import { MS_IN_AN_HOUR } from 'constants/date';

export const USE_GET_QI_DETAIL_KEY = 'progressDetailQi';

export const useGetQIDetail = (qiId: number, enabled?: boolean) => {
  return useQuery(
    [USE_GET_QI_DETAIL_KEY, qiId],
    () => getQIDetail({ qi_id: qiId }),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
      enabled: enabled,
    }
  );
};

export const usePrefetchQIDetails = () => {
  const queryClient = useQueryClient();

  return useMemo(
    () => (qiId: number) => {
      queryClient.prefetchQuery(
        ['progressDetailQi', qiId],
        () => getQIDetail({ qi_id: qiId ?? 0 }),
        {
          staleTime: MS_IN_AN_HOUR,
          cacheTime: MS_IN_AN_HOUR,
        }
      );
    },
    [queryClient]
  );
};
