import { createContext, FC, useContext, useState } from 'react';

interface MenuContext {
  isMenuExpanded: boolean;
  toggleMenu: () => void;
  closeMenu: () => void;
}

const contextDefaultValue: MenuContext = {
  isMenuExpanded: false,
  toggleMenu: () => null,
  closeMenu: () => null,
};

const menuContext = createContext(contextDefaultValue);

export const MenuContextProvider: FC = ({ children }) => {
  const [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(false);
  const toggleMenu = () => setIsMenuExpanded(!isMenuExpanded);
  const closeMenu = () => setIsMenuExpanded(false);

  return (
    <menuContext.Provider value={{ isMenuExpanded, toggleMenu, closeMenu }}>
      {children}
    </menuContext.Provider>
  );
};

export const useMenuContext = () => useContext(menuContext);
