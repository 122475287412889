import { FC } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Button } from 'ui/Button';
import { routes } from 'routing/constants';

interface Props {}

export const GoToSubscribeButton: FC<Props> = () => {
  return (
    <UnstyledLink to={routes.SUBSCRIBE}>
      <StyledButton type="button">S'abonner</StyledButton>
    </UnstyledLink>
  );
};

const UnstyledLink = styled(Link)`
  /* Override link default styles */
  text-decoration: none !important;
`;

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.secondary.main};
  color: ${({ theme }) => theme.colors.secondary.contrastText};
`;
