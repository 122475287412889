import { useMutation } from 'react-query';
import { deleteTrainingTask } from './api';

export const useDeleteTask = () => {
  const mutation = useMutation(deleteTrainingTask);

  const deleteTask = async (taskId: number) => {
    return await mutation.mutateAsync(taskId);
  };

  return deleteTask;
};
