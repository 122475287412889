import React from 'react';
import styled from 'styled-components/macro';
import { Radio } from 'ui/Radio';
import { SelectionTables } from '../Selection';

interface Props {
  selectedTable: string;
  setSelectedTable: (table: SelectionTables) => void;
}

export const SelectionType: React.FC<Props> = ({
  selectedTable,
  setSelectedTable,
}) => {
  return (
    <TableOptionsContainer id="selection-options">
      <TableOptionRow>
        <Radio
          checked={selectedTable === 'DP'}
          onChange={() => setSelectedTable('DP')}
          id="DP"
        />
        <label htmlFor="DP">DP</label>
      </TableOptionRow>
      <TableOptionRow>
        <Radio
          checked={selectedTable === 'QI'}
          onChange={() => setSelectedTable('QI')}
          id="qi"
        />
        <label htmlFor="qi">QI</label>
      </TableOptionRow>
      <TableOptionRow>
        <Radio
          checked={selectedTable === 'LCA'}
          onChange={() => setSelectedTable('LCA')}
          id="lca"
        />
        <label htmlFor="lca">LCA</label>
      </TableOptionRow>
    </TableOptionsContainer>
  );
};

const TableOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
`;

const TableOptionRow = styled.div`
  display: flex;
  gap: 8px;
`;
