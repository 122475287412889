import styled from 'styled-components/macro';
import { CloseIcon } from 'ui/icons/Close';

export const ModalHeader = ({
  onCrossClick,
  title,
}: {
  onCrossClick: () => void;
  title: string;
}) => {
  return (
    <HeaderBanner>
      <Title>{title}</Title>
      <StyledCloseIcon onClick={onCrossClick} />
    </HeaderBanner>
  );
};

const StyledCloseIcon = styled(CloseIcon)`
  width: 60px;
  height: 59px;
  color: white;
  background-color: #183876;
  padding: 15px;
  border-radius: 4px;
  border-bottom-right-radius: 0;
  margin-bottom: -2px; // to hide the border-bottom
`;
const HeaderBanner = styled.div`
  background-color: white;
  font-family: 'Freesans';
  border-bottom: 2px solid #ccc;
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 0.3rem 0.3rem 0 0;
`;

const Title = styled.h3`
  flex: 1;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 600;
  color: black;
  font-family: 'Freesans';
`;
