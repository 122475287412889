import styled from 'styled-components';
import { useHomeItemsFiltersContext } from '../context/homeItemsFiltersContext';
import { useGetBooks } from '../hooks/useGetBooks';
import { useGetDES_GroupsOptions } from 'http/common/des_groups/connector';
import { MutlipleOptionSearchFilter } from 'components/Filters/MutlipleOptionSearchFilter';
import { studyTourOptions } from 'constants/filters';

export const ItemsFilters = () => {
  const {
    itemsSearchedBooks,
    setItemsSearchedBooks,
    itemsSearchedSpe,
    setItemsSearchedSpe,
    itemStudyTours,
    setItemStudyTours,
    handleResetItemsFilters,
  } = useHomeItemsFiltersContext();
  const { booksOptions } = useGetBooks();
  const { des_groupsOptions } = useGetDES_GroupsOptions();

  const handleSpecialtyFilter = (selectedFilters: string[]) => {
    setItemsSearchedSpe(
      des_groupsOptions.filter((option) =>
        selectedFilters.includes(option.value)
      )
    );
  };

  const handleBookFilter = (selectedFilters: string[]) => {
    setItemsSearchedBooks(
      booksOptions.filter((option) => selectedFilters.includes(option.value))
    );
  };

  const handleStudyToursFilter = (selectedFilters: string[]) => {
    setItemStudyTours(
      studyTourOptions.filter((option) =>
        selectedFilters.includes(option.value)
      )
    );
  };

  return (
    <>
      <MutlipleOptionSearchFilter
        columnName={'Groupe de spécialités'}
        selectedFilters={itemsSearchedSpe.map((spe) => spe.value)}
        setSelectedFilters={handleSpecialtyFilter}
        filterOptions={des_groupsOptions}
        hasResearch
      />
      <MutlipleOptionSearchFilter
        columnName={'Livres'}
        selectedFilters={itemsSearchedBooks.map((book) => book.value)}
        setSelectedFilters={handleBookFilter}
        filterOptions={booksOptions}
        hasResearch
      />
      <MutlipleOptionSearchFilter
        columnName={'Tour de révision'}
        selectedFilters={itemStudyTours.map((tour) => tour.value)}
        setSelectedFilters={handleStudyToursFilter}
        filterOptions={studyTourOptions}
      />
      <ResetButton onClick={handleResetItemsFilters}>Effacer</ResetButton>
    </>
  );
};

const ResetButton = styled.div`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
