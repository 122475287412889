import { FC } from 'react';
import { MenuIconProps } from './types';

export const MenuRessources: FC<MenuIconProps> = ({ isFilled }) => {
  return (
    <svg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 23.326V4.907C8.974-.005 4.87 1 3.323 2.117v18.976C7.348 20.2 11.45 22.209 13 23.326ZM13 23.326V4.74C17.026-.214 21.129.8 22.677 1.926v19.147c-4.025-.901-8.129 1.126-9.677 2.253Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...(isFilled ? { fill: 'currentColor' } : {})}
      />
      <path
        d="M13 23.884c-2.194-.93-7.665-2.01-12 1.116V3.233h1.935M13 23.884c2.194-.93 7.665-2.01 12 1.116V3.233h-1.936"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
