import { FC } from 'react';
import styled from 'styled-components/macro';

import { useOpenedRowContext } from 'pages/lessons/contexts/OpenedRowContext';
import { SmallArrow } from 'components/OpeningArrow/SmallArrow';
// import { Lesson } from 'modules/lessons/domain/entities/Lesson';
import { MultipleKnowledgeRank } from 'components/Knowledge/MultipleKnowledgeRank';
// =======
import { ExtendedLesson } from 'types/lesson.type';
// import { KnowledgeRank } from '../Filters/RankFilterHeader/KnowledgeRank';

import { Cell } from './uiElements';
// import { ItemSticker } from '../ItemSticker';
import { OpenedRow } from './OpenedRow';
import { ItemsList } from 'components/Item/ItemsList';

interface Props {
  lesson: ExtendedLesson;
  index: number;
}

export const Row: FC<Props> = ({ lesson, index }) => {
  const { openedRowCode, updateOpenedRowCode } = useOpenedRowContext();

  const isOpened = !!(lesson.lesson && openedRowCode === lesson.lesson);

  const toggleRow = () => updateOpenedRowCode(lesson.lesson);

  return (
    <>
      <Cell onClick={toggleRow}>
        <h2>{lesson.title}</h2>
      </Cell>
      <Cell onClick={toggleRow}>
        <ItemsContainer>
          <ItemsList items={lesson.items} noLabel />
        </ItemsContainer>
      </Cell>
      <Cell onClick={toggleRow}>
        <KnowledgeContainer>
          {lesson.knowledges && lesson.knowledges.length > 0 && (
            <MultipleKnowledgeRank knowledges={lesson.knowledges} />
          )}
        </KnowledgeContainer>
      </Cell>
      <Cell onClick={toggleRow}>
        <SmallArrow
          $open={isOpened}
          $defaultDirection="down"
          $openedDirection="up"
        />
      </Cell>

      {isOpened && <OpenedRow index={index} lesson={lesson} />}
    </>
  );
};

const KnowledgeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;
