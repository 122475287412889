import styled from 'styled-components/macro';

import { device } from 'helpers/css/responsive';
import { useIsLoading } from 'hooks/useIsLoading';

import { Card } from 'ui/Card';
import { Button } from 'ui/Button';
import { LoginRelatedBackground } from 'components/LoginRelatedBackground';

import {
  useDeleteAccountForm,
  useDeleteAccountFormSubmit,
} from './deleteAccountform';
import { FieldValues, SubmitHandler } from 'react-hook-form';

export const DeleteAccount = () => {
  const { handleSubmit } = useDeleteAccountForm();

  const { isLoading, startLoading, stopLoading } = useIsLoading();

  const onSubmit = useDeleteAccountFormSubmit({
    onFetch: startLoading,
    afterFetch: stopLoading,
  }) as SubmitHandler<FieldValues>;

  return (
    <LoginRelatedBackground>
      <Container>
        <Card>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Title>Voulez-vous vraiment supprimer votre compte ?</Title>

            <Button type="submit" isLoading={isLoading}>
              Supprimer mon compte
            </Button>
          </Form>
        </Card>
      </Container>
    </LoginRelatedBackground>
  );
};

const Container = styled.div`
  height: 100%;
  min-height: 100vh;

  display: grid;
  place-items: center;

  padding: 16px;
`;

const Title = styled.h1`
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  gap: 16px;

  @media ${device.desktop} {
    gap: 32px;
  }
`;
