import { MS_IN_AN_HOUR } from 'constants/date';
import { getKnowledgesListHttp } from 'http/selection/get_knowledge/api';

import { useQuery } from 'react-query';

export const useGetKnowledgeList = (knowledgeIds: string[]) => {
  const { data: rawKnowledges } = useQuery(
    'useGetKnowledgeListKey_' + knowledgeIds.join('_'),
    () => {
      if (knowledgeIds.length > 0) {
        return getKnowledgesListHttp({ knowledge: knowledgeIds });
      } else {
        return [];
      }
    },
    {
      cacheTime: MS_IN_AN_HOUR,
      staleTime: MS_IN_AN_HOUR,
    }
  );

  const knowledgeList = rawKnowledges?.map((knowledge) => ({
    knowledge: knowledge.knowledge,
    rank: knowledge.rank,
    type: knowledge.type,
    label: knowledge.label,
    item: knowledge.item,
    see_also_items: knowledge.see_also_items,
  }));

  return knowledgeList;
};
