import { useQuery } from 'react-query';

import { getGroupDetail } from './api';
import { MS_IN_AN_HOUR } from 'constants/date';

export const USE_GET_GROUP_DETAIL_KEY = 'progressDetailGroup';

export const useGetGroupDetail = (groupId: number) => {
  return useQuery(
    [USE_GET_GROUP_DETAIL_KEY, groupId],
    () => getGroupDetail({ group_id: groupId }),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }
  );
};
