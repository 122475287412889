import { useNavigate } from 'react-router-dom';

import { routes } from 'routing/constants';

import { AuthConfirmationRoutingState } from '../types';

export const useGoToAuthConfirmation = () => {
  const navigate = useNavigate();

  return (message: AuthConfirmationRoutingState) =>
    navigate(routes.AUTH_CONFIRMATION, { state: message });
};
