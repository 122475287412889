import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from 'store';

import { DetailGroupResponse } from 'http/progress/detail/group/types';
import { SelectionGroupResult } from 'http/selection-group/types';
import { updateItemSelection } from 'store/slices/dpTableSlice';
import { getItemsNotAlreadyInOtherItems } from 'pages/selection/TableHeaders/utils';

import { useAddToLocalCartContext } from '../../contexts/addToLocalCartContext';
import { AddToCartSuccessToast } from 'pages/progress/GeneralProgress/components/AddToCartSuccessToast';

export const useAddLocalCartToGlobalCart = () => {
  const dispatch = useDispatch();
  const selectedItemsInfos = useSelector(
    (state: RootState) => state.dpTable.selectedItemsInfos
  );
  const { flushLocalCart } = useAddToLocalCartContext();

  return (results: DetailGroupResponse[]) => {
    const localCart = results.map((dpDetail) =>
      adaptDetailDPToGroupSelection(dpDetail)
    );

    const pageItemsWithoutAlreadySelected = getItemsNotAlreadyInOtherItems(
      localCart,
      selectedItemsInfos
    );

    const newItemsWithAllCurrent = [
      ...selectedItemsInfos,
      ...pageItemsWithoutAlreadySelected,
    ] as SelectionGroupResult[];

    dispatch(updateItemSelection(newItemsWithAllCurrent));

    if (localCart.length > 0) {
      flushLocalCart();
      toast.success(<AddToCartSuccessToast name="DPs" cartType="DPQI" />);
    } else {
      toast.info("Vous n'avez sélectionné aucun DP");
    }
  };
};

function adaptDetailDPToGroupSelection(
  dpDetail: DetailGroupResponse
): SelectionGroupResult {
  return dpDetail;
}
