import { PriceInfos, PromoResponse } from 'http/payments/getPrices/types';
import React, { useEffect, useState, ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import FadeIn from 'ui/Animations/FadeIn';
import { TextInput } from 'ui/TextInput';
import PriceByYear from '../components/PriceByYear';
import PriceByMonth from '../components/PriceByMonth';
import DateOfValidity from '../components/DateOfValidity';
import { Button } from 'ui/Button';
import PromoSection from './PromoSection/PromoSection';
import { Checkbox } from 'ui/Checkbox';
import { TextBody2 } from 'ui/TextBody2';
import { device } from 'helpers/css/responsive';
import { useSubscribeForm } from '../subscribeForm';
import { useStripeCheckout } from 'hooks/useStripeCheckout';
import { usePaypalCheckout } from 'hooks/usePaypalCheckout';
import { SubmitHandler } from 'react-hook-form';
import { UserFacturationInfos } from './FacturationPage.type';
import { CloseIcon } from 'ui/icons/Close';
import { themeColorsMap } from 'theme/colors';
import { countries } from 'constants/countries';
import { Dropdown } from 'ui/Dropdown';
import PaymentMethod from './PaymentMethod/PaymentMethod';
import { PaymentProvider } from './PaymentMethod/PaymentMethod.type';
import { getGeoCodeAdress, AdressElement } from 'http/adress/api';
import capitalizeFirstLetter from '../../../helpers/middleware/capitalize';
import PayPalIntegration from './PaypalIntegration/PaypalIntegration';

interface Props {
  handlePreviousStep: () => void;
  priceInfos: PriceInfos | undefined;
}

const FacturationPage: React.FC<Props> = ({
  handlePreviousStep,
  priceInfos,
}) => {
  const [coupon, setCoupon] = useState<PromoResponse>();
  const [amount] = useState<number>(priceInfos?.amount || 0);
  const [promoAmount, setPromoAmount] = useState<number>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    React.useState<PaymentProvider>('stripe');
  const [adressInput, setAdressInput] = useState<string>('');
  const [suggestedAddress, setSuggestedAddress] = useState<
    Array<AdressElement>
  >([]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    resetField,
    trigger,
    formState: { errors, isValid },
  } = useSubscribeForm();

  const paymentMethod = getValues('paymentMethod');

  useEffect(() => {
    if (!paymentMethod) return;
    if (paymentMethod !== selectedPaymentMethod) {
      setSelectedPaymentMethod(paymentMethod);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethod]);

  const addCoupon = (coupon: PromoResponse) => {
    setCoupon(coupon);
    setPromoAmount(amount - coupon.discount);
  };

  const removeCoupon = () => {
    setCoupon(undefined);
    setPromoAmount(undefined);
  };

  const { createCheckout: createCheckoutStripe, isLoading: isLoadingStripe } =
    useStripeCheckout();

  const {
    createCheckout: createCheckoutPaypal,
    clientId,
    onApprove,
  } = usePaypalCheckout();

  const onSubmitStripe: SubmitHandler<UserFacturationInfos> = (data) => {
    const payload = {
      ...data,
    };
    if (coupon) {
      payload.coupon = coupon.coupon;
    }

    if (!priceInfos) return;
    createCheckoutStripe(priceInfos.id.toString(), payload);
    return data;
  };

  const country: string = watch('country');

  useEffect(() => {
    let mounted = true;
    const getGeoCode = async () => {
      if (
        adressInput !== '' &&
        adressInput.length > 4 &&
        country === 'France'
      ) {
        const geoAdress = await getGeoCodeAdress(adressInput);
        if (mounted) {
          resetField('zipCode');
          resetField('city');
          setSuggestedAddress(geoAdress);
        }
      } else if (adressInput.length < 4) {
        if (mounted) {
          setSuggestedAddress([]);
          resetField('zipCode');
          resetField('city');
        }
      }
    };
    getGeoCode();

    return () => {
      mounted = false;
    };
  }, [adressInput, country, watch, resetField]);

  const handleChangeAdress = async (e: ChangeEvent<HTMLInputElement>) => {
    setValue('address', e.target.value);
    setAdressInput(e.target.value);
  };

  const handleChoseSuggestedAdress = async (adress: AdressElement) => {
    setSuggestedAddress([]);

    // Mettre à jour les champs avec setValue
    await Promise.all([
      setValue('address', adress.name, { shouldValidate: true }),
      setValue('zipCode', adress.postcode, { shouldValidate: true }),
      setValue('city', adress.city, { shouldValidate: true }),
    ]);

    // Déclencher une validation manuelle après la mise à jour des champs
    await trigger(['address', 'zipCode', 'city']);
  };

  if (!priceInfos) {
    return null;
  }

  return (
    <FadeIn>
      <Container>
        <LeftPart>
          <h2>Facturation</h2>
          <Form onSubmit={handleSubmit(onSubmitStripe)}>
            <InputContainer>
              <TextInput
                label="Prénom"
                {...register('firstName')}
                bottomErrorMessage={errors.firstName?.message}
                onChange={(e) =>
                  setValue('firstName', capitalizeFirstLetter(e.target.value))
                }
              />
              <TextInput
                label="Nom"
                {...register('lastName')}
                bottomErrorMessage={errors.lastName?.message}
                onChange={(e) =>
                  setValue('lastName', e.target.value.toUpperCase())
                }
              />
            </InputContainer>
            <TextInput
              label="Adresse email"
              {...register('email')}
              bottomErrorMessage={errors.email?.message}
            />
            <TextInput
              label="Adresse"
              {...register('address', {
                required: true,
              })}
              bottomErrorMessage={errors.address?.message}
              onChange={(e) => handleChangeAdress(e)}
            />
            {suggestedAddress && (
              <AddressSugestionContainer>
                {suggestedAddress.map((adress) => (
                  <AddressSugestion
                    key={adress.id}
                    onClick={() => handleChoseSuggestedAdress(adress)}
                  >
                    {adress.label}
                  </AddressSugestion>
                ))}
              </AddressSugestionContainer>
            )}
            <InputContainer>
              <TextInput
                label="Code postal"
                {...register('zipCode', {
                  required: true,
                })}
                bottomErrorMessage={errors.zipCode?.message}
                onChange={() => {
                  if (country === 'France') {
                    setSuggestedAddress([]);
                    resetField('address');
                    resetField('city');
                  }
                }}
              />
              <TextInput
                label="Ville"
                {...register('city', {
                  required: true,
                })}
                bottomErrorMessage={errors.city?.message}
                onChange={() => {
                  if (country === 'France') {
                    setSuggestedAddress([]);
                    resetField('address');
                    resetField('zipCode');
                  }
                }}
              />
            </InputContainer>
            <Dropdown
              label="Pays"
              options={countries}
              labelProps={{
                style: {
                  fontWeight: 400,
                },
              }}
              onChange={(value) => {
                setValue('country', value?.value || '');
              }}
              defaultValue={{
                value: 'France',
                label: 'France',
              }}
              errorMessage={errors.country?.message}
              name="country"
            />
          </Form>

          <PaymentMethod
            onChange={(value) => {
              setValue('paymentMethod', value);
            }}
            selectedPaymentMethod={selectedPaymentMethod}
            setSelectedPaymentMethod={setSelectedPaymentMethod}
            amount={promoAmount || amount}
          />

          <TermsOfUseContainer>
            <TermsOfUseLine>
              <Checkbox id="terms-of-use" {...register('termsOfUseChekbox')} />
              <TermsOfUseLabel htmlFor="terms-of-use">
                J'accepte les{' '}
                <a
                  href={'https://asclepia.io/cgv'}
                  target="_blank"
                  rel="noreferrer"
                >
                  conditions générales de vente{' '}
                </a>
                et
                <a
                  href={'https://asclepia.io/cgu'}
                  target="_blank"
                  rel="noreferrer"
                >
                  {' '}
                  les conditions générales d'utilisation
                </a>
              </TermsOfUseLabel>
            </TermsOfUseLine>
            {errors.termsOfUseChekbox?.message && (
              <CheckboxError>{errors.termsOfUseChekbox?.message}</CheckboxError>
            )}
          </TermsOfUseContainer>

          <TermsOfUseContainer noMarginTop>
            <TermsOfUseLine>
              <Checkbox id="terms-of-use" {...register('retractionRenounce')} />
              <TermsOfUseLabel htmlFor="terms-of-use">
                Je reconnais et accepte expressément que, en cochant cette case
                et en validant mon abonnement, je renonce à mon droit de
                rétractation conformément à l'article L221-28 du Code de la
                consommation.
              </TermsOfUseLabel>
            </TermsOfUseLine>
            {errors.retractionRenounce?.message && (
              <CheckboxError>
                {errors.retractionRenounce?.message}
              </CheckboxError>
            )}
          </TermsOfUseContainer>

          {paymentMethod === 'paypal' ? (
            <PayPalIntegration
              clientId={clientId}
              createCheckout={createCheckoutPaypal}
              onApprove={onApprove}
              isValid={isValid}
              amount={promoAmount || amount}
              getValues={getValues}
              priceInfos={priceInfos}
              coupon={coupon}
            />
          ) : (
            <Button
              type="submit"
              disabled={isLoadingStripe}
              isLoading={isLoadingStripe}
              onClick={handleSubmit(onSubmitStripe)}
            >
              Continuer
            </Button>
          )}
        </LeftPart>
        <RightPart>
          <RightPartTop>
            <h2>Mon abonnement</h2>
            <LinkButton onClick={handlePreviousStep}>
              Changer d'abonnement
            </LinkButton>
          </RightPartTop>
          <PricingResume>
            <PricingResumeTopContainer>
              <PricingResumeTop>
                <ColumnBox>
                  <h5>{priceInfos.label}</h5>
                  <PriceByMonth
                    amount={promoAmount || amount}
                    small
                    endSubscription={priceInfos.end_subscription}
                  />
                </ColumnBox>
                <PriceByYearText>
                  <PriceByYear amount={promoAmount || amount} />
                </PriceByYearText>
              </PricingResumeTop>
              {coupon && (
                <Coupon>
                  <ColumnBox>
                    <h5>{coupon.coupon}</h5>
                  </ColumnBox>
                  <RowBox>
                    <ColumnBox>
                      <h5>{`- ${Math.round(coupon.discount) / 100} €`}</h5>
                    </ColumnBox>
                    <CloseButton
                      className="remove-coupon"
                      onClick={removeCoupon}
                    >
                      <CloseIcon
                        style={{
                          cursor: 'pointer',
                          width: '.75rem',
                          height: '.75rem',
                          color: themeColorsMap.dark.error.main,
                        }}
                      />
                    </CloseButton>
                  </RowBox>
                </Coupon>
              )}
            </PricingResumeTopContainer>

            <PricingResumeMiddle>
              <DateOfValidity end_subscription={priceInfos.end_subscription} />
            </PricingResumeMiddle>
            <PricingResumeBottom>
              <PromoSection productId={priceInfos.id} addCoupon={addCoupon} />
            </PricingResumeBottom>
          </PricingResume>
        </RightPart>
      </Container>
    </FadeIn>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 2rem;
  justify-content: flex-start;
  padding-top: 2rem;
  padding-bottom: 2rem;
  @media ${device.tablet} {
    flex-direction: row;
    gap: 10%;
  }
`;

const LeftPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  max-width: 750px;
`;

const RightPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  max-width: 550px;
`;

const RightPartTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
`;

const LinkButton = styled.button`
  color: ${({ theme }) => theme.colors.primary.dark};
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: 0.9rem;
  background-color: transparent;
  border: none;
  padding: 0;

  &:hover {
    text-decoration: underline;
  }
`;

const Form = styled.form`
  display: flex;
  gap: 0.75rem;
  flex-direction: column;
  padding-top: 1rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1rem;
`;
const AddressSugestionContainer = styled.div`
  margin-top: -14px;
  border-top: 2px solid white;
`;

const AddressSugestion = styled.div`
  width: 100%;
  display: flex;
  padding: 0 16px;
  flex-direction: column;
  padding: 8px 16px;
  border-radius: ${({ theme }) => theme.borderRadius.cta.mobile};

  &:hover {
    background-color: ${({ theme }) => theme.colors.primary.lighter};
  }
  @media ${device.desktop} {
    padding: 12px 24px;
  }
`;

const PricingResume = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`;

const PricingResumeTopContainer = styled.div`
  padding: 1rem 0.9rem;
  background-color: ${({ theme }) => theme.colors.primary.main};
  border-bottom: 1px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 13px 13px 0 0;
`;

const PricingResumeTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const ColumnBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const RowBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const Coupon = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.primary.dark};
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
`;

const PricingResumeMiddle = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.5rem 0.9rem;
  border: 1px solid ${({ theme }) => theme.colors.primary.main};
  border-top: none;
  border-bottom: none;
`;
const PricingResumeBottom = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0.9rem;
  border: 1px solid ${({ theme }) => theme.colors.primary.main};
  border-radius: 0 0 13px 13px;
`;

const PriceByYearText = styled.h3`
  font-weight: 600;
`;

const TermsOfUseLine = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const TermsOfUseContainer = styled.div<{ noMarginTop?: boolean }>`
  margin: ${({ noMarginTop }) => (noMarginTop ? '0 0 1.5rem' : '1.5rem 0')};
`;

const TermsOfUseLabel = styled.label`
  ${({ theme }) => theme.typography.body}
  margin-bottom: 0;
  width: 100%;
`;

const CheckboxError = styled(TextBody2)`
  color: ${({ theme }) => theme.colors.error.main};
  margin-top: 0.5rem;
  display: block;
`;

const CloseButton = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
`;

export default FacturationPage;
