import { FC } from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  onClick?(): void;
  show: boolean;
  isLoading?: boolean;
}

export const ValidateButton: FC<Props> = (props) => {
  return (
    <Container show={props.show}>
      <Button onClick={props.onClick} disabled={props.isLoading}>
        {props.isLoading ? 'Chargement...' : 'Valider la réponse'}
      </Button>
    </Container>
  );
};

const Container = styled.div<{ show?: boolean }>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 20px;
`;

const Button = styled.button`
  height: 40px;
  border: none;
  font-weight: bold;
  border-radius: 4px;
  background-color: #ff8c95;
  color: white;
  cursor: pointer;
  font-family: 'FreeSans';
  font-style: normal;
  font-size: 16px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;
