import { device } from 'helpers/css/responsive';
import styled from 'styled-components';

export const CardsContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`;

export const CardsContainer = styled.div<{
  nbCards?: number;
  gap?: string;
  fullWidth?: boolean;
  noPadding?: boolean;
}>`
  padding: ${({ noPadding }) => (noPadding ? '0' : '1rem 0')};
  display: grid;
  gap: ${({ gap }) => gap || '1rem'};
  grid-template-columns: repeat(
    ${({ nbCards }) => (nbCards ? nbCards : 'auto-fill')},
    ${({ fullWidth }) => (fullWidth ? '1fr' : 'minmax(350px, 1fr)')}
  );
  @media ${device.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(${({ nbCards }) => nbCards || 1}, auto);
  }
`;

export const GlobalContainer = styled.div<{
  maxWidth?: string;
  gap?: string;
  centered?: boolean;
}>`
  padding: 1rem 0;
  display: flex;
  margin: ${({ centered }) => (centered ? 'auto' : '0')};
  flex-direction: column;
  gap: ${({ gap }) => gap || '0.5rem'};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
`;

export const SectionTitle = styled.h3<{ bold?: boolean }>`
  font-size: 1.5rem;
  font-weight: ${({ bold }) => (bold ? 700 : 400)};
  line-height: 1.75rem;
`;

export const SmallSectionTitle = styled.h4<{ blur?: boolean }>`
  filter: ${({ blur }) => (blur ? 'blur(5px)' : 'none')};
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
  transition: filter 0.2s;
`;

export const FlexCenter = styled.div`
  display: flex;
  justify-content: center;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
