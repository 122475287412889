import styled from 'styled-components';

const WhiteCard = styled.div<{
  $smallPading?: boolean;
  color?: string;
  hasMargin?: boolean;
}>`
  padding: ${({ $smallPading }) => ($smallPading ? '8px' : '16px')};
  border-radius: 8px;
  border: 1px solid
    ${({ theme, color }) => (color ? color : theme.colors.primary.light)};
  margin: ${({ hasMargin }) =>
    hasMargin ? '8px var(--horizontal-padding) 0' : 0};

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export default WhiteCard;
