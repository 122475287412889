import { useDispatch, useSelector } from 'react-redux';
import { device } from 'helpers/css/responsive';
import styled from 'styled-components/macro';
import { Button } from 'ui/Button';
import DifficultyButtons from './DifficultyButtons';
import {
  selectCard,
  selectCorrection,
  selectCorrectionStatus,
  selectIsCorrectionRevealed,
  selectSelectedAnswers,
} from 'store/slices/fqCurrentSlice';
import { getFQQCMQuality } from 'pages/flash-questions/helpers';
import { getCorrection } from 'store/actions/fqCurrentActions';
import { Status } from 'store/types';

const { PENDING } = Status;
export const FQFooter = () => {
  const isCorrectionRevealed = useSelector(selectIsCorrectionRevealed);
  const dispatch = useDispatch();

  const correction = useSelector(selectCorrection);
  const correctionStatus = useSelector(selectCorrectionStatus);
  const card = useSelector(selectCard);
  const userResponse = useSelector(selectSelectedAnswers);

  const getQuality = () => {
    if (!correction || !card) return [];

    return getFQQCMQuality({
      firstTryOfTheDay: card.first_try_of_the_day,
      userResponse,
      correctionItems: correction.correction_question.correction_items_qcm,
    });
  };

  const showCorrection = async () => {
    dispatch(getCorrection());
  };

  if (isCorrectionRevealed && card) {
    return (
      <Container>
        <DifficultyButtons
          buttonsConfig={getQuality()}
          flashQuestionId={card.flash_question_id}
        />
      </Container>
    );
  }

  return (
    <Container>
      <ButtonContainer>
        <Button
          disabled={correctionStatus === PENDING}
          onClick={showCorrection}
        >
          {correctionStatus === PENDING
            ? 'Chargement de la correction...'
            : 'Afficher la correction'}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  align-self: center;
  justify-content: center;
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 2rem;
`;

const ButtonContainer = styled.div`
  margin-bottom: 1em;
  width: 100%;

  @media ${device.mobile} {
    max-width: 400px;
    width: 100%;
  }

  & button {
    width: 100%;
    justify-content: center;
  }
`;
