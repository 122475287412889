interface Props {
  className?: string;
  fillingColor?: string;
}

export const PlayIcon = (props: Props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 33"
    fill={props.fillingColor || 'white'}
    xmlSpace="preserve"
    className={props.className}
  >
    <rect width={32} height={33} rx={16} fill="currentColor" />
    <path fill={props.fillingColor || 'white'} d="M 11,8 11,25 25,16 Z" />
  </svg>
);
