import { FC } from 'react';
import styled from 'styled-components/macro';
import { Source } from 'types/source.type';

export interface SourcesProps {
  sources: Source[] | undefined;
}

export const Sources: FC<SourcesProps> = ({ sources }) => {
  if (!sources || sources.length === 0) return null;

  return (
    <SourceContainer>
      <Title>SOURCES</Title>
      {sources.map((source: Source) => (
        <a
          key={source.title}
          href={source.url}
          target="_blank"
          rel="noreferrer"
        >
          {source.title}
        </a>
      ))}
    </SourceContainer>
  );
};

const SourceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-left: 1rem;
`;

const Title = styled.div`
  margin-bottom: 16px;
`;
