export const OutlinedCheck = () => {
  return (
    <svg viewBox="0 0 144 144" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M59.097 129.431c-33.342-6.976-54.118-37.698-48.034-70.336C17.148 26.458 48.571 4.926 81.25 11.003"
        stroke="currentColor"
        strokeWidth={8}
        strokeLinecap="round"
      />
      <path
        d="M128.456 54.878c8.585 32.072-10.487 65.022-42.599 73.597-32.112 8.574-65.103-10.474-73.689-42.546M91.838 14.019c4.494 1.282 15.504 6.347 23.593 16.35M121.69 38.063c1.123 1.443 3.563 4.713 4.333 6.252"
        stroke="currentColor"
        strokeWidth={8}
        strokeLinecap="round"
      />
      <path
        d="m36.949 72.686 29.852 32.22 36.112-63.958"
        stroke="currentColor"
        strokeWidth={8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
