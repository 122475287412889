import { FC, SVGAttributes } from 'react';

type SVGNativeProps = SVGAttributes<SVGElement>;

export const RemoveIcon: FC<SVGNativeProps> = (props) => {
  return (
    <svg
      viewBox="0 0 14 2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 1H13"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
