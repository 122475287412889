import { FC } from 'react';
import styled from 'styled-components/macro';

import { GroupTypeRadio } from './components/GroupTypeRadio/GroupTypeRadio';
import { ProgressLegend } from './components/ProgressLegend';
import { QIProgress } from './QIProgress/QIProgress';
import { DPProgress } from './DPProgress/DPProgress';
import { useGroupType } from './components/GroupTypeRadio/useGroupType';
import { LCAProgress } from './LCAProgress';
import { device } from 'helpers/css/responsive';

interface Props {}

export const ADD_LOCAL_SELECTION_TO_CART_BUTTON_ID =
  'addLocalSelectionToCartButtonId';

export const FLUSH_LOCAL_SELECTION_BUTTON_ID = 'flushLocalSelectionButtonId';

export const GeneralProgress: FC<Props> = () => {
  const groupType = useGroupType();

  return (
    <Container>
      <SecondRow>
        <OptionsRow>
          <GroupTypeRadio />
          <ProgressLegend />
        </OptionsRow>

        <ButtonsContainer>
          <div style={{ minHeight: 24 }} id={FLUSH_LOCAL_SELECTION_BUTTON_ID} />
          <div
            style={{ minHeight: 36 }}
            id={ADD_LOCAL_SELECTION_TO_CART_BUTTON_ID}
          />
        </ButtonsContainer>
      </SecondRow>

      <ProgressDataContainer>
        {groupType === 'DP' && <DPProgress />}

        {groupType === 'QI' && <QIProgress />}

        {groupType === 'LCA' && <LCAProgress />}
      </ProgressDataContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
  flex-grow: 1;
`;

const SecondRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;

  padding: 0 var(--horizontal-padding);
`;

const OptionsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;

  @media ${device.desktop} {
    gap: 50px;
  }
`;

const ProgressDataContainer = styled.div`
  overflow: hidden;
  flex-grow: 1;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;

  @media ${device.desktop} {
    align-items: flex-end;
    gap: 12px;
  }
`;
