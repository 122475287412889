export const PasswordEyeOpen = () => {
  return (
    <svg viewBox="0 0 30 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.082 12.952C5.802 11.81 5.079 11.36 2 8.902c3.079-3.514 7.937-7.378 13.684-7.378 1.14 0 3.801.228 5.322 1.143"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M21.006 2.667C25.187 5.333 25.947 6.477 28 8.9c-2.053 2.46-7.39 6.043-12.316 6.324-1.393.004-4.865-.444-7.602-2.273"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <ellipse
        cx={15.456}
        cy={7.238}
        rx={4.942}
        ry={4.952}
        fill="currentColor"
      />
      <path
        d="M15.456 12.19a4.947 4.947 0 004.942-4.952c0-1.828-.862-2.794-1.293-3.048l-7.602 5.715c.608 1.828 2.889 2.286 3.953 2.286z"
        fill="currentColor"
      />
    </svg>
  );
};
