import { useQuery } from 'react-query';

import { getGroupThumbnail } from './api';
import { GroupThumbnailParams } from './types';
import { MS_IN_AN_HOUR } from 'constants/date';

export const useGetGroupThumbnail = (params: GroupThumbnailParams) => {
  const query = useQuery(
    ['getGroupThumbnail', params],
    () => getGroupThumbnail(params),
    {
      staleTime: MS_IN_AN_HOUR,
      cacheTime: MS_IN_AN_HOUR,
    }
  );

  return query;
};
