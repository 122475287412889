import { getAPI } from 'auth';

import { CorrectionTaskResponse } from 'types/correction.type';

export const getCorrectionTask = async (taskId: number) => {
  const response = await getAPI<CorrectionTaskResponse>(
    '/correction/get_task',
    {
      params: {
        task_id: taskId,
      },
    }
  );
  return response.data;
};
