import React from 'react';
import styled from 'styled-components/macro';

interface Props {
  text: string;
  onClick?: () => void;
  textAlign?: 'left' | 'center' | 'right';
  marginTop?: string;
}

const SmallText: React.FC<Props> = ({
  text,
  onClick,
  textAlign,
  marginTop,
}) => {
  return (
    <SmallTextContainer
      onClick={onClick}
      cursorPointer={!!onClick}
      textAlign={textAlign}
      marginTop={marginTop}
    >
      {text}
    </SmallTextContainer>
  );
};

const SmallTextContainer = styled.p<{
  cursorPointer?: boolean;
  textAlign?: 'left' | 'center' | 'right';
  marginTop?: string;
}>`
  font-size: 0.9rem;
  margin: ${({ marginTop }) => marginTop || '0'} 0 0;
  text-align: ${({ textAlign }) => textAlign || 'left'};
  cursor: ${({ cursorPointer }) => (cursorPointer ? 'pointer' : 'inherit')};
`;

export default SmallText;
