import { FC } from 'react';
import styled from 'styled-components/macro';

import { GenericErrorPlaceholder } from 'components/GenericErrorPlaceholder';

interface Props {}

export const Error: FC<Props> = () => {
  return (
    <FullWidthRow>
      <GenericErrorPlaceholder />
    </FullWidthRow>
  );
};

const FullWidthRow = styled.span`
  grid-column: 1/-1;
`;
