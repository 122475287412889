import styled from 'styled-components';
import { SpecialtyIcon } from 'ui/icons/SpecialtyIcon/SpecialtyIcon';
import { MenuCompass } from 'ui/icons/MenuIcons/MenuCompass';
import { MenuBook } from 'ui/icons/MenuIcons/MenuBook';
import { MenuFlashQuestions } from 'ui/icons/MenuIcons/MenuFlashQuestions';
import { MenuStart } from 'ui/icons/MenuIcons/MenuStart';
import { SpeProgressionContainer } from '../SpecialtiesView/SingleSpecialtyView/SpeProgressionContainer';
import { RevisionCard } from './RevisionCard';
import { RepartitionBarData } from 'components/StatsElements/RepartitionBar';
import { device } from 'helpers/css/responsive';
import { Tooltip } from 'components/Tooltips/Tooltip';

export interface StatCardProps {
  id?: string | number;
  cardType: 'item' | 'specialty';
  isFav: boolean;
  studyTour: number;
  title: string;
  seeGrade: boolean;
  grade: number;
  DoneRepartitionDP: RepartitionBarData;
  DoneRepartitionQI: RepartitionBarData;
  DoneRepartitionQF: RepartitionBarData;
  lastRevisionDate?: string;
  numBooks?: number;
  booksTitles?: string[];
  numItems?: number;
  numLessons?: number;
  numDP?: number;
  numQI?: number;
  numQF?: number;
  onCardClick: () => void;
}

export const StatCard: React.FC<StatCardProps> = ({
  id,
  cardType,
  title,
  isFav,
  studyTour,
  seeGrade,
  grade,
  DoneRepartitionDP,
  DoneRepartitionQI,
  DoneRepartitionQF,
  lastRevisionDate,
  numBooks,
  booksTitles,
  numItems,
  numLessons,
  numDP,
  numQI,
  numQF,
  onCardClick,
}) => {
  return (
    <RevisionCard
      onCardClick={onCardClick}
      cardType={cardType}
      cardId={id as string | number}
      activeTourNumber={studyTour}
      isFav={isFav}
    >
      <FlexDivJustify>
        <SpeInfo>
          {cardType === 'specialty' && <SpeIcon trigram={id as string} />}
          <Title>{title}</Title>
        </SpeInfo>
        <Grade blur={!seeGrade}>{grade}/20</Grade>
      </FlexDivJustify>
      <StatsContainer>
        <SpeProgressionContainer
          icon={
            cardType === 'specialty' ? (
              <MenuCompass />
            ) : (
              <Tooltip
                content={booksTitles}
                tooltipType={'list'}
                placement="bottom"
              >
                <MenuBook />
              </Tooltip>
            )
          }
          title={cardType === 'specialty' ? 'items' : 'livres'}
          value={cardType === 'specialty' ? numItems : numBooks}
          title2={cardType === 'specialty' ? undefined : 'cours'}
          value2={cardType === 'specialty' ? undefined : numLessons}
        />
        <VerticalSeparator />
        <SpeProgressionContainer
          icon={<MenuStart />}
          title={'DP'}
          value={numDP}
          progressionBarData={DoneRepartitionDP}
        />
        <VerticalSeparator />
        <SpeProgressionContainer
          icon={<MenuStart />}
          title={'QI'}
          value={numQI}
          progressionBarData={DoneRepartitionQI}
        />
        <VerticalSeparator />
        <SpeProgressionContainer
          icon={<MenuFlashQuestions />}
          title={'Questions flash'}
          value={numQF}
          progressionBarData={DoneRepartitionQF}
        />
      </StatsContainer>

      {lastRevisionDate && (
        <DateContainer>Dernière révision : {lastRevisionDate}</DateContainer>
      )}

      <Separator />
    </RevisionCard>
  );
};

const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.75rem;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 1rem 0;
  border: 1px solid ${({ theme }) => theme.colors.primary.dark};
  border-radius: 4px;
`;

export const FlexDivJustify = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 100%;
  @media ${device.mobile} {
    flex-direction: column;
    gap: 2rem;
  }
`;

const SpeInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 16px;
  min-height: 48px;
`;

const Title = styled.h5`
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.5rem !important;
`;

export const Grade = styled.h4<{ blur: boolean }>`
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.5rem !important;
  color: ${({ theme }) => theme.colors.inactive};
  filter: ${({ blur }) => (blur ? 'blur(5px)' : 'none')};
  transition: filter 0.3s;
`;

const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.inactive};
  height: 1px;
  width: 100%;
  margin: 1.5rem 0;
`;

const VerticalSeparator = styled.div`
  background-color: ${({ theme }) => theme.colors.primary.dark};
  height: 52px;
  width: 1px;
`;

const DateContainer = styled.div`
  color: ${({ theme }) => theme.colors.inactive};
  ${({ theme }) => theme.typography.tiny}
`;

export const SpeIcon = styled(SpecialtyIcon)`
  height: 24px;
  width: 24px;
`;
