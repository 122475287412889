import { useMemo } from 'react';
import { TrainingDuration } from '../domain/entities/UserOptions';
import { useGetUserOptions } from './useGetUserOptions';

const NORMAL_DURATION = {
  CART_MAX_DURATION: 180,
  QI_DURATION: 1.5,
  DP_DURATION_LESS_13: 2,
  DP_DURATION_MORE_13: 30,
  LCA_DURATION: 90,
  UC_DURATION: 180,
};

const LONG_DURATION = {
  CART_MAX_DURATION: 240,
  QI_DURATION: 2,
  DP_DURATION_LESS_13: 2.67,
  DP_DURATION_MORE_13: 40,
  LCA_DURATION: 120,
  UC_DURATION: 240,
};

export interface EstimatedDurations {
  qi: number;
  dpLessThan13: number;
  dpMoreThan13: number;
  lca: number;
  maxCartDuration: number;
  ucDuration: number;
}

export const useGetEstimatedDurations = (): EstimatedDurations => {
  const { data: userOptions } = useGetUserOptions();
  const userTrainingDuration: TrainingDuration =
    userOptions?.trainingDuration ?? 'normal';
  const estimatedDurations: EstimatedDurations = useMemo(() => {
    if (userTrainingDuration === 'illimited') {
      return {
        qi: NORMAL_DURATION.QI_DURATION,
        dpLessThan13: NORMAL_DURATION.DP_DURATION_LESS_13,
        dpMoreThan13: NORMAL_DURATION.DP_DURATION_MORE_13,
        lca: NORMAL_DURATION.LCA_DURATION,
        maxCartDuration: NORMAL_DURATION.CART_MAX_DURATION,
        ucDuration: NORMAL_DURATION.UC_DURATION,
      };
    }

    if (userTrainingDuration === 'extraTime') {
      return {
        qi: LONG_DURATION.QI_DURATION,
        dpLessThan13: LONG_DURATION.DP_DURATION_LESS_13,
        dpMoreThan13: LONG_DURATION.DP_DURATION_MORE_13,
        lca: LONG_DURATION.LCA_DURATION,
        maxCartDuration: LONG_DURATION.CART_MAX_DURATION,
        ucDuration: LONG_DURATION.UC_DURATION,
      };
    }

    return {
      qi: NORMAL_DURATION.QI_DURATION,
      dpLessThan13: NORMAL_DURATION.DP_DURATION_LESS_13,
      dpMoreThan13: NORMAL_DURATION.DP_DURATION_MORE_13,
      lca: NORMAL_DURATION.LCA_DURATION,
      maxCartDuration: NORMAL_DURATION.CART_MAX_DURATION,
      ucDuration: NORMAL_DURATION.UC_DURATION,
    };
  }, [userTrainingDuration]);
  return estimatedDurations;
};
