import { CheckboxError } from 'pages/correction/components/icons/CheckboxError';
import { FC } from 'react';
import styled from 'styled-components/macro';

import { Checkbox } from 'ui/Checkbox';

interface Props {
  answer: boolean;
  hasUserAnswered: boolean;
}

export const QCMCheckbox: FC<Props> = ({ answer, hasUserAnswered }) => {
  if (hasUserAnswered) {
    return <Checkbox checked={answer} readOnly />;
  }

  return (
    <IconContainer>
      <CheckboxError />
    </IconContainer>
  );
};

const IconContainer = styled.div`
  width: 20px;
  height: 20px;
`;
