import { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'store';
import { updateSessionsFilter } from 'store/slices/selectionFiltersSlice';
import { useGetSessionsList } from 'http/selection/session-list/connector';

import { GenericSortFilterHeader } from './GenericSortFilterHeader';

interface Props {
  isSub?: boolean;
}

export const SessionHeader: FC<Props> = ({ isSub }) => {
  const { data } = useGetSessionsList();
  const filterOptions = useMemo(() => data?.options || [], [data]);

  const dispatch = useDispatch();

  const selectedFilters = useSelector(
    (state: RootState) => state.selectionFilters.collections
  );

  const setSelectedFilters = (sessionsToFilter: string[]) => {
    dispatch(updateSessionsFilter(sessionsToFilter));
  };

  return (
    <GenericSortFilterHeader
      columnName="Session"
      selectedFilters={selectedFilters}
      setSelectedFilters={setSelectedFilters}
      sortColumn="collection"
      filterOptions={filterOptions}
      isSub={isSub}
    />
  );
};
