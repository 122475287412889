import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { UserFacturationInfos } from './FacturationPage/FacturationPage.type';
import { useGetUserOptions } from 'modules/user-options/hooks/useGetUserOptions';
import capitalizeFirstLetter from '../../helpers/middleware/capitalize';
import * as yup from 'yup';

const subscribeFormSchema = yup.object({
  firstName: yup.string().required('Veuillez renseigner votre prénom'),
  lastName: yup.string().required('Veuillez renseigner votre nom'),
  email: yup
    .string()
    .email('Veuillez renseigner un email valide')
    .required('Veuillez renseigner votre email'),
  address: yup.string().required('Veuillez renseigner votre adresse'),
  zipCode: yup.string().required('Veuillez renseigner votre code postal'),
  city: yup.string().required('Veuillez renseigner votre ville'),
  country: yup.string().required('Veuillez renseigner votre pays'),
  termsOfUseChekbox: yup
    .bool()
    .oneOf([true], "Veuillez accepter les conditions d'utilisations")
    .required("Veuillez accepter les conditions d'utilisations"),
  retractionRenounce: yup
    .bool()
    .oneOf(
      [true],
      'Veuillez cocher la case pour renoncer à votre droit de rétractation avant de finaliser votre abonnement.'
    )
    .required(
      'Veuillez cocher la case pour renoncer à votre droit de rétractation avant de finaliser votre abonnement.'
    ),
  paymentMethod: yup
    .string()
    .required('Veuillez choisir un moyen de paiement')
    .oneOf(['stripe', 'paypal']),
});

export const useSubscribeForm = () => {
  const { data: userOptions } = useGetUserOptions();

  return useForm<UserFacturationInfos>({
    resolver: yupResolver(subscribeFormSchema),
    defaultValues: {
      firstName: userOptions?.firstName
        ? capitalizeFirstLetter(userOptions.firstName)
        : '',
      lastName: userOptions?.lastName ?? '',
      email: userOptions?.email ?? '',
      country: 'France',
      paymentMethod: 'stripe',
    },
  });
};
