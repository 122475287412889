import { FC } from 'react';

import { ResponsiveText } from './ResponsiveText';

interface Props {
  date: Date;
}

const timeWithoutSecsOptions: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
};

export const FormattedDate: FC<Props> = ({ date }) => {
  return (
    <ResponsiveText
      text={date.toLocaleDateString([], timeWithoutSecsOptions).slice(0, -5)}
      shortText={date.toLocaleDateString()}
    />
  );
};
