import { DownArrow } from 'pages/home/ui/DownArrow';
import { FC } from 'react';
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  GroupBase,
  Props as SelectProps,
  StylesConfig,
} from 'react-select';
import styled, { useTheme } from 'styled-components';

type IsMulti = false;

export interface SessionOption {
  label: string;
  value: {
    collection: string;
    completed: boolean;
  };
}

export interface SpecialtyOption {
  label: string;
  value: string;
}

interface Props
  extends SelectProps<SessionOption, IsMulti, GroupBase<SessionOption>> {
  className?: string;
  placeholder?: string;
}

const DropdownIndicator: FC<DropdownIndicatorProps<SessionOption>> = (
  props
) => {
  return (
    <components.DropdownIndicator {...props}>
      <StyledDownArrow />
    </components.DropdownIndicator>
  );
};

export const SessionDropdown: FC<Props> = ({ className, ...restOfProps }) => {
  const theme = useTheme();

  const formatOptionLabel = (option: SessionOption) => {
    const isCompleted: boolean = option.value.completed;
    return (
      <OptionStyled isCompleted={isCompleted}>{option.label}</OptionStyled>
    );
  };

  const selectStyles: StylesConfig<SessionOption, IsMulti> = {
    option: (provided, state) => {
      const style = {
        ...provided,
        color: theme.colors.primary.dark,
        backgroundColor: 'transparent',
        padding: '8px',
        '&:hover': {
          backgroundColor: theme.colors.primary.light,
        },
      };
      if (state.isSelected) {
        style.backgroundColor = theme.colors.primary.main;
      }
      if (state.isFocused) {
        style.backgroundColor = theme.colors.primary.light;
      }

      return style;
    },
    indicatorsContainer: (provided, _) => {
      const style = {
        ...provided,
        color: theme.colors.primary.dark,
      };
      return style;
    },
    indicatorSeparator: (_, __) => ({}),
    control: (provided, state) => {
      const style = {
        ...provided,
        height: '100%',
        backgroundColor: theme.colors.background.paper,
        boxShadow: state.isFocused ? 'none' : 'none',
        '&:hover': {},
        paddingLeft: '5px',
        borderRadius: '8px',
        border: 'none',
        minWidth: '150px',
      };

      return style;
    },
    dropdownIndicator: (provided, state) => {
      const style = {
        ...provided,
        transition: 'all .2s ease',
        marginRight: '8px',
        marginLeft: '8px',
      };
      if (state.selectProps.menuIsOpen) {
        style.transform = 'rotate(180deg)';
      }

      return style;
    },
    menu: (provided, state) => {
      const style = {
        ...provided,
        padding: '8px',
        backgroundColor: theme.colors.background.paper,
        borderRadius: '8px',
      };
      return style;
    },
    container: (provided, _) => {
      const style = {
        ...provided,
        height: '100%',
      };
      return style;
    },
    placeholder: (provided, _) => {
      const style = {
        ...provided,
        fontStyle: 'italic',
        fontWeight: 300,
      };
      return style;
    },
    singleValue: (provided, _) => ({
      ...provided,
      color: theme.colors.primary.dark,
    }),
  };

  return (
    <div className={className}>
      <ReactSelect<SessionOption>
        components={{ DropdownIndicator: DropdownIndicator }}
        styles={selectStyles}
        formatOptionLabel={formatOptionLabel}
        {...restOfProps}
      />
    </div>
  );
};

const StyledDownArrow = styled(DownArrow)`
  width: 8px;
  height: 8px;
`;

const OptionStyled = styled.div<{ isCompleted: boolean }>`
  color: ${({ theme }) => theme.colors.primary.dark};

  font-style: ${({ theme, isCompleted }) =>
    isCompleted ? theme.typography.boldLabel : theme.typography.label};
`;
