import { useMutation, useQueryClient } from 'react-query';
import { postAPI } from 'auth';
import { reportErrorToSentry } from 'helpers/application/reportErrorToSentry';
import { toast } from 'react-toastify';

export interface SetQualityRequest {
  flashQuestionId: number;
  quality: number | null;
}

// request
const setQuality = async ({ flashQuestionId, quality }: SetQualityRequest) => {
  const response = await postAPI<void>('/flash/set_quality/', {
    flash_question_id: flashQuestionId,
    quality,
  });

  return response.data;
};

// hook
export const useSetFQQuality = () => {
  const client = useQueryClient();

  const mutation = useMutation<void, unknown, SetQualityRequest>(setQuality, {
    onError: (error) => {
      toast.error("Erreur lors de l'attribution de la qualité.");
      reportErrorToSentry(
        `Erreur lors de l'attribution de la qualité. ${error}`
      );
    },
    onSuccess: () => {
      toast.success('Question flash mise à jour');
      client.invalidateQueries('fqDecksData');
    },
  });

  return mutation;
};
