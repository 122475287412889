import styled from 'styled-components/macro';
import { device } from 'helpers/css/responsive';

const BlueCard = styled.div<{
  $smallPading?: boolean;
  $mediumPadding?: boolean;
  $bigPadding?: boolean;
  color?: string;
  hasBorder?: boolean;
  hasMargin?: boolean;
  noMargin?: boolean;
  gap?: string;
  fullWidth?: boolean;
  maxWidth?: string;
  clickable?: boolean;
  darker?: boolean;
  justify?: string;
}>`
  display: flex;
  flex-direction: column;
  margin: ${({ hasMargin }) =>
    hasMargin ? '8px var(--horizontal-padding) 0' : 'none'};
  margin-top: ${({ noMargin }) => (!noMargin ? '0.5em' : 'none')};
  margin-bottom: ${({ noMargin }) => (!noMargin ? '0.5em' : 'none')};
  justify-content: ${({ justify }) => justify || 'inherit'};
  padding: ${({ $smallPading, $bigPadding, $mediumPadding }) =>
    $smallPading
      ? ' 0.85rem'
      : $mediumPadding
      ? '1.5rem'
      : $bigPadding
      ? '2rem'
      : '1rem'};
  ${({ theme, hasBorder }) => (hasBorder ? theme.colors.inactive : 'none')};
  border-radius: 8px;
  background-color: ${({ theme, darker }) =>
    darker ? theme.colors.primary.light : theme.colors.primary.lighter};
  color: ${({ theme }) => theme.colors.primary.dark};
  overflow: hidden;
  gap: ${({ gap }) => gap};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  max-width: ${({ maxWidth }) => maxWidth || 'none'};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'auto')};
  img {
    max-width: 100%;
    height: auto;
  }
  @media ${device.mobile} {
    max-width: 100%;
    padding: 0.85rem;
  }
`;

export default BlueCard;
