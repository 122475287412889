import { useGetCategories } from 'http/selection/categories-list/connector';
import { TagPieChartData } from 'pages/start/components/DPQIView/TagsTypePieCharts';
import { useMemo } from 'react';
import { SelectionStatistics } from './SelectionStatistics';
import { StringCounter } from './types';

export const useAdaptCounterToTagChartData = () => {
  const { data } = useGetCategories();

  const mapTagCodeToName = useMemo(() => {
    if (!data) return {};

    return data.reduce<{
      [tagCode: string]: {
        fullName: string;
        shortName: string;
      };
    }>(
      (prev, category) => ({
        ...prev,
        [category.code]: {
          fullName: category.name,
          shortName: category.code,
        },
      }),
      {}
    );
  }, [data]);

  return function (tagCounter: StringCounter): TagPieChartData[] {
    const tags = Object.keys(tagCounter);
    const tagsWithoutNoTags = tags.filter(
      (tag) => tag !== SelectionStatistics.NO_TAG
    );

    const tagsInfos = tagsWithoutNoTags.map((tag) => {
      return {
        name: mapTagCodeToName[tag]?.fullName,
        shortName: mapTagCodeToName[tag]?.shortName,
        value: tagCounter[tag],
      };
    });

    tagsInfos.push({
      name: 'Pas de tag',
      shortName: '',
      value: tagCounter[SelectionStatistics.NO_TAG],
    });

    return tagsInfos;
  };
};
