import { getAPI, postAPI } from 'auth';
import {
  CCBTask,
  CurrentCCB,
  CCBStatus,
  CCBStats,
  CCBRanking,
  LCAArticles,
  CCBCorrection,
} from './type';

export const getCurrentCCB = async () => {
  const response = await getAPI<CurrentCCB>('/ccb/get_current_ccb', {});
  return response.data;
};

export const getCCBstatus = async (CCBid: number) => {
  const response = await getAPI<CCBStatus>('/ccb/get_user_status', {
    params: {
      ccb_id: CCBid,
    },
  });
  return response.data;
};

export const ccbCreateTask = async (
  ccb_id: number,
  composition_unit: string
) => {
  const response = await postAPI<CCBTask>('/ccb/create_task', {
    ccb_id,
    composition_unit,
  });
  return response.data;
};

export const getCCBStats = async (ccb_id: number, promotion: string | null) => {
  const response = await getAPI<CCBStats>('/ccb/get_stats', {
    params: { ccb_id, promotion },
  });
  return await response.data;
};

export const getCCBRanking = async (
  ccb_id: number,
  promotion: string | null
) => {
  const response = await getAPI<CCBRanking>('/ccb/get_ranking', {
    params: { ccb_id, promotion },
  });
  return response.data;
};

export const getLCAArticles = async (CCBid: number) => {
  const response = await getAPI<LCAArticles>('/ccb/get_lca_articles', {
    params: {
      ccb_id: CCBid,
    },
  });
  return response.data;
};

export const getCCBCorrection = async (
  ccb_id: number,
  promotion: string | null
) => {
  const response = await getAPI<CCBCorrection>('/ccb/get_session_ranking/', {
    params: { ccb_id, promotion },
  });
  return response.data;
};
