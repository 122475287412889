import { FC, SVGAttributes } from 'react';

type SVGNativeProps = SVGAttributes<SVGElement>;

export const EmptyTrophee: FC<SVGNativeProps> = (props) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9407 1H6.70655V13.1485C6.70655 16.5914 19.9407 16.5351 19.9407 13.1485V1Z"
        stroke="#021427"
      />
      <path
        d="M19.9407 1H6.70655V13.1485C6.70655 16.5914 19.9407 16.5351 19.9407 13.1485V1Z"
        stroke="black"
        strokeOpacity="0.2"
      />
      <path
        d="M19.9407 1H6.70655V13.1485C6.70655 16.5914 19.9407 16.5351 19.9407 13.1485V1Z"
        stroke="black"
        strokeOpacity="0.2"
      />
      <path
        d="M6.70654 4.34728C6.70654 4.34728 1.29259 1.42714 1.29259 4.34728C1.29259 7.26741 4.59226 9.39405 6.70654 12.6278"
        stroke="#021427"
      />
      <path
        d="M6.70654 4.34728C6.70654 4.34728 1.29259 1.42714 1.29259 4.34728C1.29259 7.26741 4.59226 9.39405 6.70654 12.6278"
        stroke="black"
        strokeOpacity="0.2"
      />
      <path
        d="M6.70654 4.34728C6.70654 4.34728 1.29259 1.42714 1.29259 4.34728C1.29259 7.26741 4.59226 9.39405 6.70654 12.6278"
        stroke="black"
        strokeOpacity="0.2"
      />
      <path
        d="M19.8784 4.34728C19.8784 4.34728 25.2924 1.42714 25.2924 4.34728C25.2924 7.26741 21.9927 9.39405 19.8784 12.6278"
        stroke="#021427"
      />
      <path
        d="M19.8784 4.34728C19.8784 4.34728 25.2924 1.42714 25.2924 4.34728C25.2924 7.26741 21.9927 9.39405 19.8784 12.6278"
        stroke="black"
        strokeOpacity="0.2"
      />
      <path
        d="M19.8784 4.34728C19.8784 4.34728 25.2924 1.42714 25.2924 4.34728C25.2924 7.26741 21.9927 9.39405 19.8784 12.6278"
        stroke="black"
        strokeOpacity="0.2"
      />
      <path
        d="M9.11255 22.2097V25H17.5343L17.5343 22.2097C17.5343 19.4946 9.11255 19.5949 9.11255 22.2097Z"
        stroke="#021427"
      />
      <path
        d="M9.11255 22.2097V25H17.5343L17.5343 22.2097C17.5343 19.4946 9.11255 19.5949 9.11255 22.2097Z"
        stroke="black"
        strokeOpacity="0.2"
      />
      <path
        d="M9.11255 22.2097V25H17.5343L17.5343 22.2097C17.5343 19.4946 9.11255 19.5949 9.11255 22.2097Z"
        stroke="black"
        strokeOpacity="0.2"
      />
      <path
        d="M11.5815 16.1058V20.2108M15.0654 16.1058V20.2108"
        stroke="#021427"
      />
      <path
        d="M11.5815 16.1058V20.2108M15.0654 16.1058V20.2108"
        stroke="black"
        strokeOpacity="0.2"
      />
      <path
        d="M11.5815 16.1058V20.2108M15.0654 16.1058V20.2108"
        stroke="black"
        strokeOpacity="0.2"
      />
    </svg>
  );
};
