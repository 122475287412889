import * as Sentry from '@sentry/react';

export const enableSentryMonitoring = () => {
  if (process.env.REACT_APP_SENTRY_RELEASE) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
          networkDetailAllowUrls: [
            'https://interface.asclepia.io',
            'https://interface.asclepia.io/training/save_question_qcm/',
          ],
          networkRequestHeaders: ['X-Custom-Header'],
          networkResponseHeaders: ['X-Custom-Header'],
        }),
      ],
      environment: process.env.NODE_ENV,
      enabled: process.env.NODE_ENV === 'production',
      release: process.env.REACT_APP_SENTRY_RELEASE,
      tracesSampleRate: 0.2,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,
    });
  }
};
