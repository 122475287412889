import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { MyGradeCard } from './GradeCard';
import { ParticipantsCard } from './ParticipantsCard';
import { formatDateRange } from 'helpers/dates/utils';

export const MyStatsPeriod = () => {
  const { ccbStats, CCB_start_date, CCB_end_date } = useSelector(
    (state: any) => state.ccb
  );
  return (
    <Container>
      <CardContainer>
        <ParticipantsCard
          grade={`${ccbStats?.period_participants} participants sur la période`}
          text={`${formatDateRange(CCB_start_date, CCB_end_date, 'entre')}`}
        />
        <MyGradeCard
          ranks={['A']}
          grade={ccbStats?.period_passed_rank_A_percentage}
          text={
            'des étudiants ont validés les questions de rang A validant - sur la période'
          }
          percentage={true}
        />
        <MyGradeCard
          ranks={['A', 'B']}
          grade={ccbStats?.period_average_global_grade}
          text={
            'Note globale moyenne avec coefficient 1 pour le rang B - sur la période'
          }
        />
      </CardContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  height: fit-content;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
`;
