import { getAPI, postAPI } from 'auth/authentifiedHttpCalls';
import { PublicKeyReponse } from './types';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  PaypalCompleteOrderParams,
  PaypalCompletedOrder,
  PaypalFacturationInfos,
  PaypalOrder,
} from 'pages/subscribe/FacturationPage/FacturationPage.type';

export const getPaypalClientId = async () => {
  const response = await getAPI<PublicKeyReponse>('/payments/paypal/client_id');
  return response.data.client_id;
};

export const createOrder = async (params: PaypalFacturationInfos) => {
  try {
    const response = await postAPI<PaypalOrder>(
      '/payments/paypal/create_order',
      {
        ...params,
      }
    );
    return response.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response?.status === 406) {
        toast.info('Vous êtes déjà premium !');
        return;
      }
    }

    throw err;
  }
};

export const completeOrder = async (params: PaypalCompleteOrderParams) => {
  try {
    const response = await postAPI<PaypalCompletedOrder>(
      '/payments/paypal/complete_order',
      {
        order_id: params.orderID,
      }
    );
    return response.data;
  } catch (err) {
    if (axios.isAxiosError(err)) {
      if (err.response?.status === 406) {
        toast.info('Vous êtes déjà premium !');
        return;
      }
    }

    throw err;
  }
};
