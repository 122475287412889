import { device } from 'helpers/css/responsive';
import { useGetSessionDetailedProgress } from 'modules/progress/detailed/session/hooks/useGetSessionDetailedProgress';
import { CorrectionModalProvider } from 'pages/progress/GeneralProgress/QIProgress/contexts/correctionModalContext';
import { FC } from 'react';
import styled from 'styled-components/macro';
import { useSessionContext } from '../sessionContext';

import { GroupListBlock } from './GroupListBlock';
import { QiListBlock } from './QIListBlock';

interface Props {}

export const SessionBlocks: FC<Props> = () => {
  const { selectedSession } = useSessionContext();
  const { data: sessionDetailedProgress } = useGetSessionDetailedProgress(
    selectedSession !== undefined
      ? {
          collection: selectedSession.value.collection,
        }
      : undefined
  );

  return (
    <BlocksContainer>
      <BlockQI>
        <CorrectionModalProvider>
          <QiListBlock
            questions={sessionDetailedProgress?.qis}
            medianDenom={sessionDetailedProgress?.qiDenom ?? 1}
            median={sessionDetailedProgress?.qiMedian}
          />
        </CorrectionModalProvider>
      </BlockQI>

      <RightBlocksContainer>
        <BlockDP>
          <GroupListBlock
            name="Dossiers Progressifs"
            groupType="DP"
            groups={sessionDetailedProgress?.dps}
          />
        </BlockDP>
        <BlockLCA>
          <GroupListBlock
            name="Lecture Critique d'Article"
            groupType="LCA"
            groups={sessionDetailedProgress?.lcas}
          />
        </BlockLCA>
      </RightBlocksContainer>
    </BlocksContainer>
  );
};

const BlocksContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  overflow-y: auto;
  width: 100%;

  padding: 0 var(--horizontal-padding) 16px;
  // small strip of blank space to scroll on mobile devices
  padding-right: 24px;

  @media ${device.desktop} {
    padding: 0 var(--horizontal-padding) 16px;
    gap: 16px;
  }
`;

const BlockQI = styled.div`
  flex-grow: 1;
  flex-basis: 600px;
  flex-shrink: 1;

  max-height: 100%;
`;

const RightBlocksContainer = styled.div`
  flex-grow: 1;
  flex-basis: 600px;
  flex-shrink: 1;

  --lca-block-height: 200px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 8px;

  overflow: hidden;
  max-height: 120%;

  @media ${device.desktop} {
    --lca-block-height: 232px;
    max-height: 100%;
    gap: 16px;
  }
`;

const BlockDP = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
`;

const BlockLCA = styled.div`
  flex-basis: auto;
  flex-grow: 1;
  flex-shrink: 0;
`;
