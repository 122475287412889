import { PreviousQuestionTraining } from 'modules/progress/detailed/shared/entities/PreviousTraining';
import { FC } from 'react';
import styled from 'styled-components/macro';
import { HistoryListRow } from './HistoryListRow';

interface Props {
  history: PreviousQuestionTraining[];
}

export const HistoryList: FC<Props> = ({ history }) => {
  return (
    <Container>
      {history.map((training) => (
        <HistoryListRow training={training} key={training.groupCorrectionId} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  column-gap: 24px;
  row-gap: 12px;
  place-items: center;
  overflow-y: auto;
`;
