import { FC, useEffect, useMemo } from 'react';
import { Tr, Td } from 'ui/TableElements';
import { Specialities } from 'pages/selection/components/Specialties';
import { KnowledgeRank } from 'components/Knowledge/KnowledgeRank';
import { Item } from 'components/Item/Item';
import { PremiumLockedRessource } from 'components/PremiumLockedRessource';
import { useIsUserPremium } from 'modules/user-options/hooks/useIsUserPremium';
import { FQResultsType } from 'http/flash-questions/fqdeckcreation/types';
import { CheckboxCell } from './CheckboxCell';
import { useDispatch, useSelector } from 'react-redux';
import { useTableConfigContext } from '../contexts/tableConfigContext';
import { selectDeck } from 'store/slices/fqCurrentSlice';
import { countDaysToDeadline } from 'helpers/dates/utils';

interface Props {
  rowData: FQResultsType;
  index: number;
}

export const FQTableRow: FC<Props> = ({ rowData, index }) => {
  const isOdd = useMemo(() => index % 2 === 1, [index]);
  const hasItemBeenDone = !!rowData.last_training;
  const { isUserPremium } = useIsUserPremium();
  const dispatch = useDispatch();
  const deck = useSelector(selectDeck);
  const { toggleSelectionCreator } = useTableConfigContext();

  const alreadySelectedIds = useMemo(
    () => deck?.cards.map((card) => card.question) || [],
    [deck?.cards]
  );

  useEffect(() => {
    const isRowSelected = alreadySelectedIds.indexOf(rowData.id) !== -1;
    if (isRowSelected) {
      dispatch(toggleSelectionCreator(rowData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Tr isOdd={isOdd}>
        <Td isCheckbox>
          <CheckboxCell itemInfo={rowData} isFree={rowData.is_free} />
        </Td>
        <Td>
          {rowData.collection}{' '}
          {rowData.composition_unit ? 'UC' + rowData.composition_unit : ''}
        </Td>
        <Td>{rowData.num}</Td>
        <Td>
          <PremiumLockedRessource
            ressourceName="Contenu abonné"
            isFree={rowData.is_free}
          >
            <Specialities
              trigram1={rowData.specialty1}
              trigram2={rowData.specialty2}
              hasItemBeenDone={hasItemBeenDone}
            />
          </PremiumLockedRessource>
        </Td>

        <Td>
          <PremiumLockedRessource ressourceName="" isFree={rowData.is_free}>
            <Item item={rowData.knowledge?.item!} />
          </PremiumLockedRessource>
        </Td>
        <Td>{isUserPremium && rowData.categories}</Td>
        <Td>
          {/* <PremiumLockedRessource
            ressourceName="Contenu abonné"
            isFree={rowData.is_free}
          > */}
          <KnowledgeRank
            label={rowData.knowledge?.label!}
            rank={rowData.knowledge?.rank!}
          />
          {/* </PremiumLockedRessource> */}
        </Td>
        <Td>
          <PremiumLockedRessource ressourceName="" isFree={rowData.is_free}>
            {rowData.type}
          </PremiumLockedRessource>
        </Td>
        <Td>
          <PremiumLockedRessource ressourceName="" isFree={rowData.is_free}>
            {rowData.due ? countDaysToDeadline(rowData.due) : '-'}
          </PremiumLockedRessource>
        </Td>
      </Tr>
    </>
  );
};
